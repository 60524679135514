import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { t } from 'i18next';
import i18n from "../../i18n";
export default function SimpleSlider() {
  var topSlider = {
    dots: false,
    infinite: false,
    centerMode: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    responsive: [

      {
        breakpoint: 1273,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
    ],

  };
  const [showResults, setShowResults] = React.useState(false)
  const onClick = () => setShowResults(true)
  const onClose = () => setShowResults(false)

  const [showResults1, setShowResults1] = React.useState(false)
  const onClick1 = () => setShowResults1(true)
  const onClose1 = () => setShowResults1(false)


  const [showResults2, setShowResults2] = React.useState(false)
  const onClick2 = () => setShowResults2(true)
  const onClose2 = () => setShowResults2(false)


  const [showResults3, setShowResults3] = React.useState(false)
  const onClick3 = () => setShowResults3(true)
  const onClose3 = () => setShowResults3(false)


  const [showResults4, setShowResults4] = React.useState(false)
  const onClick4 = () => setShowResults4(true)
  const onClose4 = () => setShowResults4(false)

  return (
    <>
      <Slider {...topSlider} className="top-equipped-slider">
        <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12 h-full">
          <div className="picture-bg h-full" >
            <div className="md:pt-6 pt-0 pb-8 2xl:pb-12 h-full sm-pb-2">
              <h3 className="my-5 first:mt-0 break-words text-lg  top-equipped-title">
                {t("home.The Benefits of Camper and Motorhome Rental Your Key to Limitless Freedom")}
              </h3>
              <div className="vertical-img">
                <picture className="text-0 flex h-full">
                  <img
                    className="inline-block object-cover dark:brightness-90 rounded 2xs:rounded-lg w-full mx-auto div-img"
                    src={'images/0.gif'}
                    style={{
                      backgroundImage: `url(images/the-benefits.jpg)`
                    }}
                    alt="picture for Camper rent-long"
                  />
                </picture>
                <div className="plus-icon" onClick={onClick}>+</div>
                <div className="picture-bg-overlay rounded 2xs:rounded-lg"></div>
              </div>
            </div>

          </div>



        </div>

        <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12 h-full">
          <div className="picture-bg h-full">
            <div className="md:pt-6 pt-0 pb-8 2xl:pb-12 h-full sm-pb-2">
              <h3 className="my-5 first:mt-0 break-words text-lg  top-equipped-title">
              {t("home.Camper Rental vs. Camper Subscription Find Your Perfect Travel")}
              </h3>
              <div className="vertical-img">
                <picture className="text-0 flex h-full">
                  <img
                    className="inline-block object-cover dark:brightness-90 rounded 2xs:rounded-lg w-full mx-auto div-img"
                    src={'images/0.gif'}
                    style={{
                      backgroundImage: `url(images/camper-rental-vs-sub.jpg)`
                    }}

                    alt="service-img"

                  />
                </picture>
                <div className="plus-icon" onClick={onClick1}>+</div>
                <div className="picture-bg-overlay rounded 2xs:rounded-lg"></div>
              </div>


            </div>
          </div>


        </div>

        <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12 h-full">
          <div className="picture-bg h-full" >
            <div className="md:pt-6 pt-0 pb-8 2xl:pb-12 h-full sm-pb-2">
              <h3 className="my-5 first:mt-0 break-words text-lg top-equipped-title">
                {t("home.Cooking and more")}
              </h3>
              <div className="vertical-img">
                <picture className="text-0 flex h-full">
                  <img
                    className="inline-block object-cover dark:brightness-90 rounded 2xs:rounded-lg w-full  mx-auto div-img"
                    src={'images/0.gif'}
                    style={{
                      backgroundImage: `url(images/cooking-and-more.jpg)`
                    }}
                    alt="comfort-img"
                  />
                </picture>
                <div className="plus-icon" onClick={onClick2}>+</div>
                <div className="picture-bg-overlay rounded 2xs:rounded-lg"></div>
              </div>


            </div>
          </div>


        </div>

        <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12 h-full">
          <div className="picture-bg h-full" >
            <div className="md:pt-6 pt-0 pb-8 2xl:pb-12 h-full sm-pb-2">
              <h3 className="my-5 first:mt-0 break-words text-lg  top-equipped-title">
                {t("home.For Dog Lovers Camper rent with dog")}
              </h3>
              <div className="vertical-img">
                <picture className="text-0 flex h-full">
                  <img
                    className="inline-block object-cover dark:brightness-90 rounded 2xs:rounded-lg w-full  mx-auto div-img"
                    src={'images/0.gif'}
                    style={{
                      backgroundImage: `url(images/picture-dog.jpg)`
                    }}

                    alt="picture dog"
                  />
                </picture>
                <div className="plus-icon" onClick={onClick3}>+</div>
                <div className="picture-bg-overlay rounded 2xs:rounded-lg"></div>
              </div>


            </div>
          </div>


        </div>

        <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12 h-full">
          <div className="picture-bg h-full" >
            <div className="md:pt-6 pt-0 pb-8 2xl:pb-12 h-full sm-pb-2">
              <h3 className="my-5 first:mt-0 break-words text-lg top-equipped-title">
                {t("home.Camper Dilemma Van, Motorhome, or Caravan?")}
              </h3>
              <div className="vertical-img">
                <picture className="text-0 flex h-full">
                  <img
                    className="inline-block object-cover dark:brightness-90 rounded 2xs:rounded-lg w-full  mx-auto div-img"
                    src={'images/0.gif'}
                    style={{
                      backgroundImage: `url(images/balkan-campers.jpg)`
                    }}

                    alt="balkan-campers"
                  />
                </picture>
                <div className="plus-icon" onClick={onClick4}>+</div>
                <div className="picture-bg-overlay rounded 2xs:rounded-lg"></div>
              </div>


            </div>
          </div>


        </div>
      </Slider>

      {showResults ?
        <div className="top-equipped-modal">
          <div className="top-equipped-modal-body">
            <div className="text-right">
              <button onClick={onClose} className="close-top-eq-mdal">X</button>
            </div>
            <div className="pt-4 pb-4 text-left mx-auto max-w-3xl">
              <h3 className="my-5 first:mt-0 break-words text-lg md:text-2xl">
                {t("home.The Benefits of Camper and Motorhome Rental Your Key to Limitless Freedom")}
              </h3>
              <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
               {t("home.Experience the freedom of camping with us! Each day holds new discoveries")}.
              </div>
              <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
              <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
               {t("home.Discover the Freedom of Camping with Us! Enjoy the flexibility to stay exactly where you  like, with pick-up and drop-off times from 9 AM to 5 PM")}.
              </div>
              <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
              <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                {t("home.We have no service fees,  our campers come fully equipped, and you can personalize them to your style")}.
              </div>
              <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
              {/* <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                No service fees – our campers are fully equipped, and you can personalize them to match your style.
              </div> */}
              <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
              <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                <div className="text-center">
                  <Link
                    to={"/campers"}
                    className="group/button disabled:opacity-50 border border-transparent text-md text-white dark:text-off-white green-bg dark:bg-primary-dark hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated p-1 rounded-full mt-4 inline-block px-4"
                  >
                    <div className="flex h-full items-center justify-between">
                      <span className="flex">{t("home.Rent your camper now")}</span>
                      <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g fill="none" fillRule="evenodd">
                            <path
                              d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                              fill="var(--svg-color-secondary, #163435)"
                            ></path>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        : null}

      {showResults1 ?
        <div className="top-equipped-modal">
          <div className="top-equipped-modal-body">
            <div className="text-right">
              <button onClick={onClose1} className="close-top-eq-mdal">X</button>
            </div>
            <div className="pt-4 pb-4 text-left mx-auto max-w-3xl">
              <h3 className="my-5 first:mt-0 break-words text-lg md:text-2xl">
                {t("home.Camper Rental vs. Camper Subscription Find Your Perfect Travel")}
              </h3>
              <div className="min-h-[10px] break-words">
                <h2 className='fw-700 md:text-lg'>{t("home.Option Classic Rental with Added Value")}</h2>
              </div>
              <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                {t("home.From just 8 days of travel, benefit from our long-term discount Save up to 15% and  experience more for less")}
              </div>
              <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
              <div className="min-h-[10px] break-words">
                <h2 className='fw-700 md:text-lg'> {t("home.The Camper Subscription Flexible Travel, Easy Enjoyment")} </h2>
              </div>

              <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                {t("home.Ideal for sabbaticals, parental leave, or trying out a camper. With a fixed monthly rate,  enjoy unrestricted long-term travel")}.
              </div>
              <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
            </div>
          </div>
        </div>

        : null}
      {showResults2 ?

        <div className="top-equipped-modal">
          <div className="top-equipped-modal-body">
            <div className="text-right">
              <button onClick={onClose2} className="close-top-eq-mdal">X</button>
            </div>
            <div className="pt-4 pb-4 text-left mx-auto max-w-3xl">
              <h3 className="my-5 first:mt-0 break-words text-lg md:text-2xl">
                {t("home.Cooking and more")}
              </h3>
              <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                {t("home.From classic pop-up roof campers to spacious models with 4 sleeping spots and fully equipped kitchen and bathroom - our campers are ready for your journey")}
              </div>
              <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
              <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                {t("home.Utensils, cooking gear, camping essentials, a table, and two camping chairs are included. You can  easily add extra accessories like a bike rack, hammock, grill, and stand-up paddle boards.  Nothing stands in the way of your adventure.")}
              </div>

              <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
              <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                <div className="text-center">
                  <Link
                    to={"/campers"}
                    className="group/button disabled:opacity-50 border border-transparent text-md text-white dark:text-off-white green-bg dark:bg-primary-dark hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated p-1 rounded-full mt-4 inline-block px-4"
                  >
                    <div className="flex h-full items-center justify-between">
                      <span className="flex">{t("home.To the model overview")}</span>
                      <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g fill="none" fillRule="evenodd">
                            <path
                              d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                              fill="var(--svg-color-secondary, #163435)"
                            ></path>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        : null}

      {showResults3 ?
        <div className="top-equipped-modal">
          <div className="top-equipped-modal-body">
            <div className="text-right">
              <button onClick={onClose3} className="close-top-eq-mdal">X</button>
            </div>
            <div className="pt-4 pb-4 text-left mx-auto max-w-3xl">
              <h3 className="my-5 first:mt-0 break-words text-lg md:text-2xl">
                {t("home.For Dog Lovers Camper rent with dog")}
              </h3>
              <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                {t("home.Reserved campers for you and your four-legged friend! Choose the")}{i18n.resolvedLanguage=== 'de' ? <>"{t("home.Dog-Friendly")}"</>: <>"{t("home.Dog-Friendly")}"</>}{t("home.option  when booking. A cleaning fee starting from €85 ensures clean and safe adventures")} {t("home.Simply select the")} {i18n.resolvedLanguage=== 'de' ? <>"{t("home.Dog-Friendly")}"</> : <>"{t("home.Dog-Friendly")}"</>} {t("home.option during the booking process. With a cleaning fee starting at €85, we ensure extra cleanliness and safety so you can camp worryfree.")}

              </div>

              <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
              <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                {t("home.Experience camping with your dog!")}

              </div>

              <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
              <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                <div className="text-center">
                  <Link to={"/campers"} className="group/button disabled:opacity-50 border border-transparent text-md text-white dark:text-off-white green-bg dark:bg-primary-dark hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated p-1 rounded-full mt-4 inline-block px-4">
                    <div className="flex h-full items-center justify-between">
                      <span className="flex">{t("home.To the dog camper")}</span>
                      <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g fill="none" fillRule="evenodd">
                            <path
                              d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                              fill="var(--svg-color-secondary, #163435)"
                            ></path>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        : null}


      {showResults4 ?
        <div className="top-equipped-modal">
          <div className="top-equipped-modal-body">
            <div className="text-right">
              <button onClick={onClose4} className="close-top-eq-mdal">X</button>
            </div>
            <div className="pt-4 pb-4 text-left mx-auto max-w-3xl">
              <h3 className="my-5 first:mt-0 break-words text-lg md:text-2xl">
              {t("home.Camper Dilemma Van, Motorhome, or Caravan?")}
              </h3>
              <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                  {t("home.A campervan effortlessly takes you through narrow streets, while a motorhome offers extra comfort for longer trips, including an onboard bathroom.")}
                </div>
                <div className="min-h-[30px] break-words font-sizemd-custom 2xl:font-normal"></div>

                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                  {t("home.The travel van is the perfect combination of a camper van and a motorhome. And if you have a car with a trailer hitch, renting a caravan is a cost-effective, spacious, and convenient option")}.
                </div>
              </div>
              <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>


              <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                <div className="text-center">
                  <Link
                    to={"/campers"}
                    className="group/button disabled:opacity-50 border border-transparent text-md text-white dark:text-off-white green-bg dark:bg-primary-dark hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated p-1 rounded-full mt-4 inline-block px-4"
                  >
                    <div className="flex h-full items-center justify-between">
                      <span className="flex">{t("home.Discover all models")}
                      </span>
                      <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g fill="none" fillRule="evenodd">
                            <path
                              d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                              fill="var(--svg-color-secondary, #163435)"
                            ></path>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        : null}
    </>

  );
}