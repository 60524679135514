import clientAuth from "../clientAuth";
import client from "../client";
let lng = localStorage.getItem('lng') ? localStorage.getItem('lng'):'de';
export default {
  // get brands 


  index: () =>client.get(`camperVans/index?lng=`+lng),
  policiesindex: () =>client.get(`cancellation/index?lng=`+lng),
  subIndex: () =>client.get(`camperVans/subIndex?lng=`+lng),

  sale_index: () =>client.get('sale/index'),
  // subIndex: () =>client.get('camperVans/subIndex'),
  kilometer: () =>client.get(`camperVans/kilometer?lng=`+lng),
  //
  kilometerindex :()=>client.get(`kilometer/index?lng=`+lng),
 
  checkaccessoires: (userData) => client.post(`camperVans/checkaccessoires?lng=`+lng, { json: userData }),
  
  // b_index: () => clientAuth.get("brand/index"),
  show:(id)=>client.get(`camperVans/show/${id}?lng=`+lng), 
  checkdate: (userData) =>client.post('checkdate/index',{ json: userData }),

  camper_filter: (userData) =>client.post('camperVans/camper_filter',{ json: userData }),
  
  excludedates:(camper_id)=>client.get(`checkdate/excludedates/${camper_id}?lng=`+lng), 
  // update:(id,site_id,url,username,password,notes)=>clientAuth.post('cpanel/update/'+id,{
  //   json:{
  //       site_id: site_id,
  //       url: url,
  //       username: username,
  //       password: password,
  //       notes: notes,
  //   }
  // }),

  // destroy:(id)=>clientAuth.delete(`camper/destroy/${id}`),
};