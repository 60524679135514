import React from "react";


const Loader2 = () => {

  return (
    <>
      <div className="text-center w-full">
        <div
            className="loadingio-eclipse">
              <div className="ldio-rpinwye8j0b">
                <div>
                </div>
              </div>
            </div>
            </div>
    </>
  );
};

export default Loader2;
