import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams, generatePath, useParams, useLocation } from "react-router-dom";
import Tripplanner from '../../Api/services/Tripplanner';

import moment from 'moment';
import { addMonths, getDate, differenceInDays } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "react-bootstrap";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import Loader from "../../Components/Loader/Loader";
import DatePicker, { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import de from 'date-fns/locale/de';
registerLocale("de", de);
const TripplannerSeperated = (props) => {
  var imageUrl = process.env.REACT_APP_LARAVEL_IMAGE_URL + 'Tripplan/';
  const { i18n, t } = useTranslation();
  const params = useParams();
  const variable = useLocation();
  const navigate = useNavigate();
  const [tripplaner, setTrip] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [count, setCount] = useState(0);
  const [error, setError] = useState("")
  const [calendarIsOpen, setCalendarIsOpen] = useState(false)
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [nav2, setNav2] = useState(null);
  const [nav1, setNav1] = useState(null);
  const [loader, setLoder] = useState(true);
  // Get the current date
  const today = new Date();
  // Set the minimum selectable date to tomorrow
  const minSelectableDate = new Date(today);
  minSelectableDate.setDate(today.getDate() + 4);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const pickerRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1200);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isMobile && pickerRef.current !== null) {
      pickerRef.current.input.readOnly = true;
    }
  }, [isMobile, pickerRef]);

  useEffect(() => {
    var id = params.id;
    getsetTrip(id);
    let dateRange = searchParams.get('date')

    if (dateRange) {
      let startDate1 = dateRange.split("_");
      setStartDate(new Date(startDate1[0]));
      setEndDate(new Date(startDate1[1]))
      let result = 0;
      result = differenceInDays(new Date(startDate1[1]), new Date(startDate1[0]))
      setCount(1 + result)
    }
  }, []);
  const onChangedate = (date) => {
    const [start, end] = date;
    setStartDate(start);
    setEndDate(end);
    let result = 0;
    result = differenceInDays(end, start)


    if (start & end === null) {

    }
    else {
      var newresult = result + 1
      setCount(newresult)
    }

    if (date[1] !== null) {
      if (variable.search == '') {
        navigate(generatePath(`?date=${moment(startDate).format('YYYY-MM-DD') + '_' + moment(date[1]).format('YYYY-MM-DD')}`))
      } else if (variable.search !== '') {
        setSearchParams((searchParams) => {
          searchParams.set('date', `${moment(startDate).format('YYYY-MM-DD') + '_' + moment(date[1]).format('YYYY-MM-DD')}`);
          return searchParams;
        });
      } else {
        navigate(generatePath(variable.search + `&date=${moment(startDate).format('YYYY-MM-DD') + '_' + moment(date[1]).format('YYYY-MM-DD')}`))
      }
    }
  }
  const getsetTrip = async () => {
    try {
      let getTrip = await Tripplanner.index().json();

      setTrip(getTrip.data[0]);
      setGallery(getTrip.data[0].gallery)
      setLoder(false);
    } catch (error) {
      if (error.name === 'HTTPError') {
        const errorJson = await error.response.json();
      }
    }
  };
  const detailData = {
    location: searchParams.get('location'),
    date: searchParams.get('date'),
  }
  const handleCheckout = () => {
    let dateRange = searchParams.get('date')
    if (dateRange === null) {
      setError(t("Rent.Please Select Date!"))
    } else {

      navigate(`/tripplaner-checkout/?&date=${detailData.date}`, { state: dateRange });
      setError("")
    }
  }
  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: nav2,
  };

  const settingsThumbs = {
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: nav1,
    dots: false,

    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: "5px",
    arrows: false,
    infinite: false,
  };

  return (
    <>
      <Header />
      <main className="min-h-screen">
        <div className="mb-8 flex max-w-screen-xl lg:mx-5 lg:grid lg:grid-cols-12 xl-padded:mx-auto pt-100">
          <div className="mx-auto w-full lg:col-span-8 xl:px-12 2xl:px-0">
            <div class="md:pt-12 pt-0"></div>

            <div className="mx-5">
              {loader ? <Loader /> :
                <>
                  <div className="item voucher-detail-img planner-head-img">

                    <img class="inline-block max-w-full object-cover dark:brightness-90 aspect-square max-h-[550px] w-full group-hover:scale-110 transition-all duration-300" src={imageUrl + tripplaner.image} alt="clara" />
                  </div>

                  <div className="flex justify-center md:pt-8 lg:hidden">
                    <div className="md:mx-5 mx-0 w-full overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg mt-8 p-5 pt-7 transition-all duration-300 lg:block top-6">
                      <div className="flex max-h-[calc(100vh-80px)] flex-col">
                        <div className="flex max-h-full flex-col items-center overflow-auto text-center">
                          <h2 className="mb-1 whitespace-pre-line text-xl 2xl:text-2xl price-font fw-600">
                            <p>{tripplaner.title}</p>
                          </h2>
                          {/* <p><span className="mb-3 text-md text-primary text-orange block text-18">{tripplaner.price}€</span></p> */}
                          {/* <p><span className="mb-3 text-md text-primary text-orange block text-18">{tripplaner.price}€</span></p> */}

                          <div className="mt-2 w-full rounded bg-off-white p-5 text-left dark:bg-black md:mb-6 mb-3">

                            <div className="mb-4 flex items-center last:mb-0">
                              <div className="inline-flex items-center justify-center rounded-full border border-primary bg-white dark:border-primary-dark dark:bg-off-white mr-3 p-0">
                                <div className="inline-block align-top child:h-6 child:w-6">
                                  <svg width="32" height="32" viewBox="0 0 32 32">
                                    <g fill="none" fillRule="evenodd">
                                      <path
                                        d="m21.906 9.488-8.547 9.56-3.45-3.096a1.757 1.757 0 0 0-2.563.209c-.64.792-.547 1.98.21 2.659l4.764 4.274c.735.66 1.833.602 2.496-.13l9.73-10.869a1.954 1.954 0 0 0-.069-2.665 1.758 1.758 0 0 0-2.571.058z"
                                        fill="var(--svg-color-secondary, #163435)"
                                      ></path>
                                    </g>
                                  </svg>
                                </div>
                              </div>
                              <p className="text-md">{t("Voucher.SSL-secured checkout")}</p>
                            </div>
                            <div className="mb-4 flex items-center last:mb-0">
                              <div className="inline-flex items-center justify-center rounded-full border border-primary bg-white dark:border-primary-dark dark:bg-off-white mr-3 p-0">
                                <div className="inline-block align-top child:h-6 child:w-6">
                                  <svg width="32" height="32" viewBox="0 0 32 32">
                                    <g fill="none" fillRule="evenodd">
                                      <path
                                        d="m21.906 9.488-8.547 9.56-3.45-3.096a1.757 1.757 0 0 0-2.563.209c-.64.792-.547 1.98.21 2.659l4.764 4.274c.735.66 1.833.602 2.496-.13l9.73-10.869a1.954 1.954 0 0 0-.069-2.665 1.758 1.758 0 0 0-2.571.058z"
                                        fill="var(--svg-color-secondary, #163435)"
                                      ></path>
                                    </g>
                                  </svg>
                                </div>
                              </div>
                              <p className="text-md">{t("Voucher.Quick delivery as PDF")}</p>
                            </div>
                            <div className="mb-4 flex items-center last:mb-0">
                              <div className="inline-flex items-center justify-center rounded-full border border-primary bg-white dark:border-primary-dark dark:bg-off-white mr-3 p-0">
                                <div className="inline-block align-top child:h-6 child:w-6">
                                  <svg width="32" height="32" viewBox="0 0 32 32">
                                    <g fill="none" fillRule="evenodd">
                                      <path
                                        d="m21.906 9.488-8.547 9.56-3.45-3.096a1.757 1.757 0 0 0-2.563.209c-.64.792-.547 1.98.21 2.659l4.764 4.274c.735.66 1.833.602 2.496-.13l9.73-10.869a1.954 1.954 0 0 0-.069-2.665 1.758 1.758 0 0 0-2.571.058z"
                                        fill="var(--svg-color-secondary, #163435)"
                                      ></path>
                                    </g>
                                  </svg>
                                </div>
                              </div>
                              <p className="text-md"><Link to={"/contactus"}>{t("Voucher.Questions?")} {t("Voucher.Contact us!")}</Link></p>
                            </div>
                          </div>
                          <span className="group outline-none-focus w-full">
                            <div className="relative flex items-center px-2 h-12 border border-secondary border-opacity-disabled rounded block w-full h-12 group-focus:border-primary rounded cursor-default">
                              <div className="flex items-center justify-between w-full opacity-disabled">
                                <span className="text-ellipsis whitespace-nowrap text-md custom-calender" onClick={() => setCalendarIsOpen(!calendarIsOpen)}>
                                  <DatePicker
                                    selectsRange={true}
                                    startDate={startDate}
                                    endDate={endDate}
                                    onChange={onChangedate}
                                    minDate={minSelectableDate}
                                    maxDate={addMonths(new Date(), 12)}
                                    dateFormat="dd/MM/yyyy"
                                    locale={i18n.resolvedLanguage}
                                    monthsShown={isMobile ? 1 : 2}
                                    withPortal
                                    placeholderText={t("Rent.Whenever")}
                                    onFocus={e => e.target.blur()}
                                    ref={pickerRef}
                                  />
                                </span>
                              </div>
                            </div>

                          </span>

                          {count ? (

                            <p className="mt-3"><span className="mb-3 text-primary text-orange block text-4xl">{count * tripplaner.price}€</span></p>


                          ) :
                            <p className="mt-3"> <span className="mb-3 text-primary text-orange block text-4xl">{tripplaner.price}€</span></p>
                          }



                          {i18n.resolvedLanguage === 'en' ? <div className="text-lg font-normal fw-600">Total incl. VAT</div> : <div className="text-lg font-normal fw-600">{t("Rent.Total incl. Vat1")}</div>}
                        </div>
                      </div>
                      <Button onClick={(e) => handleCheckout(params.id)} className="md:mt-5 mt-3 grid-cols1 inline-grid grid-rows-1 text-white bg-secondary hover:bg-primary pl-5 pr-2 w-full h-12 rounded bg-primary hover:bg-secondary transition-all"> <span className="relative flex col-start-1 row-start-1 items-center justify-between w-full h-full transition-opacity duration-300">
                        <span className="transform transition-transform duration-300 translate-x-0">
                          <span>{t("Voucher.Continue to checkout")}</span>
                        </span>
                        <svg className="w-7 h-7 cssvar-secondary-to-white pointer-events-none" width="32" height="32"><svg viewBox="0 0 32 32" fill="none" id="icon-chevron-right"><path fillRule="evenodd" clipRule="evenodd" d="M22.1256 15.9999L14.479 8.35332C14.009 7.88273 13.2445 7.88213 12.7739 8.35211L12.3532 8.77342C11.8809 9.24581 11.8833 10.0073 12.3544 10.4785L17.8759 15.9999L12.3544 21.5219C11.8833 21.9925 11.8809 22.7546 12.3532 23.2264L12.7739 23.6477C13.2445 24.1182 14.009 24.117 14.479 23.6465L22.1256 15.9999Z" fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"></path></svg></svg>
                      </span></Button>
                      {error}
                    </div>
                  </div>
                </>
              }
              <div className="xl:pt-12 pt-8 xl:pb-12 pb-8 max-w-2xl m-auto">
                <div className="bg-primary flex  justify-center full-rounded rounded-lg p-4 dark:bg-black sm:flex-row sm:px-6 sm:py-6">
                  <div className="mobile-none mb-4 flex items-center last:mb-0 sm:mx-2 sm:mb-0 sm:w-1/5 sm:flex-col">
                    <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark mr-3 child:h-12 child:w-12 sm:mr-0 sm:mb-3 sm:child:h-16 sm:child:w-16">
                      <img className='white-img mh-64' src="../images/cards-entertainment-funeral-svgrepo-com.svg" />
                    </div>
                    <p className="text-white fw-500 whitespace-pre-line text-md sm:text-center green-box-text">
                      {t("trip.Cultural insights1")}
                    </p>

                  </div>
                  <div className="md:mb-4 flex items-center last:mb-0 mx-3 md:mx-2 sm:mb-0 sm:w-1/5 flex-col">
                    <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark mr-3 child:h-12 child:w-12 sm:mr-0 sm:mb-3 sm:child:h-16 sm:child:w-16">
                      <img className='white-img mh-64' src="../images/time-svgrepo-com.svg" />
                    </div>
                    <p className="text-white fw-500 whitespace-pre-line text-md sm:text-center green-box-text">
                      {t("trip.Time-saving")}
                    </p>

                  </div>
                  <div className="md:mb-4 flex items-center last:mb-0 mx-3 md:mx-2 sm:mb-0 sm:w-1/5 flex-col">
                    <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark mr-3 child:h-12 child:w-12 sm:mr-0 sm:mb-3 sm:child:h-16 sm:child:w-16">
                      <img className='white-img mh-64' src="../images/person-badge-plus-fill-svgrepo-com.svg" />
                    </div>
                    <p className="text-white fw-500 whitespace-pre-line text-md sm:text-center green-box-text">
                      {t("trip.Personalized")}
                    </p>

                  </div>
                  <div className="md:mb-4 flex items-center last:mb-0 mx-3 md:mx-2 sm:mb-0 sm:w-1/5 flex-col">
                    <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark mr-3 child:h-12 child:w-12 sm:mr-0 sm:mb-3 sm:child:h-16 sm:child:w-16">
                      <img className='white-img mh-64' src="../images/flexible-customizable-icon.svg" />
                    </div>
                    <p className="text-white fw-500 whitespace-pre-line text-md sm:text-center green-box-text">
                      {t("aboutus.Flexibility")}
                    </p>

                  </div>
                  <div className="mobile-none mb-4 flex items-center last:mb-0 mx-2 sm:mx-2 sm:mb-0 sm:w-1/5 sm:flex-col">
                    <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark mr-3 child:h-12 child:w-12 sm:mr-0 sm:mb-3 sm:child:h-16 sm:child:w-16">
                      <img className='white-img mh-64' src="../images/geography-social-studies-svgrepo-com.svg" />
                    </div>
                    <p className="text-white fw-500 whitespace-pre-line text-md sm:text-center green-box-text">
                      {t("home.Inspiration")}
                    </p>

                  </div>

                </div>
              </div>

              <div className="text-detail md:text-left text-center">
                <div className="mb-3 min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal text-20 planner-detail-text">
                  {t("trip.With the travel planner, we create a route specifically tailored for you, ranging from cultural attractions to camping spots to activities. Sit back and enjoy your vacation time without the hassle of intricate planning")}.
                </div>
                <div className="mb-3 min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal text-20 planner-detail-text">
                  {t("trip.Wondering how it works? It's actually quite simple! Add the travel planner to your camper booking for just € 17 per day or order it separately. Upon receiving your booking, we'll send you a questionnaire where you can share your travel preferences with us")}.
                </div>
                <div className="mb-3 min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal text-20 planner-detail-text">
                  {t("trip.Next, we'll take over the planning and create your customized itinerary with the best cultural attractions, local activities, and ideal camping or parking spots")}.
                </div>
                <div className="mb-3 min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal text-20 planner-detail-text">
                  {t("trip.Once your route is ready, we'll send it to you as a PDF so you can have it readily available offline as well. If you have any questions about your route, we're available anytime along the way to help and support you. Get ready for an unforgettable adventure!")}
                </div>
                <div className="md:mb-3 mb-0 min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal text-20">
                  {t("trip.Note We need 4 days to create your travel itinerary")}
                </div>



              </div>

              <div className="pt-8 2xl:pt-8">
                <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mask-image relative p-0 md:flex md:min-h-[400px] md:rounded-xl md:bg-transparent dark:md:bg-transparent justify-end">
                  <Link to={'/tripplanner#testform'} className="w-full md:flex justify-end">
                    <div className="md:hidden">
                      <div className="overflow-hidden">
                        <div className="-ml-[5%] w-[110%] overflow-hidden rounded-b-[60%20%] px-[5%]">
                          <div className="relative">
                            <picture className="text-0">
                              <img className="inline-block max-w-full object-cover dark:brightness-90 max-h-[40vh] w-full group-hover/parent:scale-110 transition-all duration-300"
                                src="images/DSC3990.jpg"
                                alt="routenservice"
                              />
                            </picture>
                          </div>
                        </div>
                      </div>
                    </div>
                    <picture className="text-0 absolute top-0 left-0 z-background hidden h-full w-full transition-all duration-300 group-hover/parent:scale-105 md:block md:group-hover/parent:scale-[103%]">
                      <img className="inline-block max-w-full object-cover dark:brightness-90 h-full w-full"
                        src="images/DSC3990.jpg"
                        alt="routenservice"
                      />
                    </picture>
                    <div className="relative -mt-10 mb-4 flex justify-center xs:mb-4 md:hidden">
                      <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark child:h-20 child:w-20">
                        <img
                          src="images/trip-planner-side-img.svg"
                          alt="selection-icon"
                        />
                      </div>
                    </div>
                    <div className="z-0 m-6 mt-0 flex flex-col text-center xs:mt-0 md:m-5 md:mt-5 md:min-h-[calc(100%-theme(spacing.5)*2)] md:w-full md:max-w-1/2 md:rounded-lg md:bg-white md:p-8 md:text-left md:shadow md:dark:bg-off-black-desat xl:max-w-[60%] 2xl:m-9 2xl:mt-9 2xl:min-h-[calc(100%-theme(spacing.9)*2)]">
                      <div className="md:mb-6 mb-2 flex items-center justify-center md:justify-start">
                        <div className="relative mr-4 hidden md:block">
                          <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark child:h-16 child:w-16">
                            <img
                              src="images/trip-planner-side-img.svg"
                              alt="selection-icon"
                            />
                          </div>
                        </div>

                        <p className="text-lg small-lg">{t("trip.Travel Route Example")}</p>
                      </div>
                      <h2 className="whitespace-pre-line break-words md:mb-4 mb-2 text-xl xs:mb-6">
                        {t("trip.Explore Your Dream Journey")}
                      </h2>
                      <div className="mb-4 md:mb-6">
                        <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                          {t("trip.We create custom travel plans with exciting activities, carefully selected campsites, and cultural highlights Just tell us which region you want to explore, and receive your sample travel itinerary directly in your inbox!")}
                        </div>
                        <div className="mobile-none min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>

                      </div>
                      <div className="group/button block disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark p-3 pl-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated w-full md:mt-auto">
                        <div className="flex h-full items-center justify-between">
                          <span className="flex">{t("trip.View an Example")}</span>
                          <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                            <svg width="32" height="32" viewBox="0 0 32 32">
                              <g fill="none" fillRule="evenodd">
                                <path
                                  d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                                  fill="var(--svg-color-secondary, #fff)"
                                ></path>
                              </g>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mask-image relative p-0 md:flex md:min-h-[400px] md:rounded-xl md:bg-transparent dark:md:bg-transparent justify-end">
                  <Link className="w-full md:flex" to={'/explanatoryvideo'}>
                    <div className="md:hidden">
                      <div className="overflow-hidden">
                        <div className="-ml-[5%] w-[110%] overflow-hidden rounded-b-[60%20%] px-[5%]">
                          <div className="relative">
                            <picture className="text-0">
                              <img
                                className="sm-h-20 inline-block max-w-full object-cover dark:brightness-90 max-h-[40vh] w-full group-hover/parent:scale-110 transition-all duration-300"
                                src="../images/video.jpg"
                                alt="video_teaser"
                              />
                            </picture>
                          </div>
                        </div>
                      </div>
                    </div>
                    <picture className="text-0 absolute top-0 left-0 z-background hidden h-full w-full transition-all duration-300 group-hover/parent:scale-105 md:block md:group-hover/parent:scale-[103%]">
                      <img
                        className="inline-block max-w-full object-cover dark:brightness-90 h-full w-full"
                        src="../images/video.jpg"
                        alt="video_teaser"
                      />
                    </picture>
                    <div className="relative -mt-10 mb-4 flex justify-center xs:mb-4 md:hidden">
                      <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark child:h-20 child:w-20">
                        <img
                          src="../images/youtube-icon.svg"
                          alt="youtube-icon"
                        />
                      </div>
                    </div>
                    <div className="z-0 m-6 mt-0 flex flex-col text-center xs:mt-0 md:m-5 md:mt-5 md:min-h-[calc(100%-theme(spacing.5)*2)] md:w-full md:max-w-1/2 md:rounded-lg md:bg-white md:p-8 md:text-left md:shadow md:dark:bg-off-black-desat xl:max-w-[60%] 2xl:m-9 2xl:mt-9 2xl:min-h-[calc(100%-theme(spacing.9)*2)]">
                      <div className="md:mb-6 mb-2 flex items-center justify-center md:justify-start">
                        <div className="relative mr-4 hidden md:block">
                          <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark child:h-16 child:w-16">
                            <img
                              src="../images/youtube-icon.svg"
                              alt="youtube-icon"
                            />
                          </div>
                        </div>
                        <p className="text-lg small-lg">{t("Contact.Camper Secrets Revealed")}</p>
                      </div>
                      <h2 className="whitespace-pre-line break-words md:mb-4 mb-2 text-xl xs:mb-6">
                        {t("Contact.Explanatory camper videos")}
                      </h2>
                      <div className="mb-4 md:mb-6">
                        <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal md:mb-0 mb-2">
                          {t("Contact.How to extend the awning, and what to do if the heater won't start? Explore valuable tips, smart tricks, and exciting highlights about our campers right here.")}
                        </div>
                      </div>
                      <div className="group/button block disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark p-3 pl-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated w-full md:mt-auto">
                        <div className="flex h-full items-center justify-between">
                          <span className="flex">{t("Contact.To the Videos")}</span>
                          <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                            <svg width="32" height="32" viewBox="0 0 32 32">
                              <g fill="none" fillRule="evenodd">
                                <path
                                  d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                                  fill="var(--svg-color-secondary, #fff)"
                                ></path>
                              </g>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>

              </div>

              <div className="xl:pb-8 pb-0 2xl:pb-16">
                <div className="overflow-hidden rounded bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg mx-auto flex max-w-3xl flex-col items-center md:p-8 2xl:p-16">
                  <div className="inline-flex items-center justify-center rounded-full primary-borber border bg-white p-1 dark:bg-off-white mb-6 border-info dark:border-info">
                    <div className="inline-block align-top child:h-8 child:w-8 2xl:child:h-10 2xl:child:w-10">
                      <svg
                        width="32px"
                        height="32px"
                        viewBox="0 0 32 32"
                        version="1.1"
                      >
                        <title>icon/actions_info</title>
                        <g
                          id="icon/actions_info"
                          stroke="none"
                          strokeWidth="1"
                          fill="EE7214"
                          fillRule="evenodd"
                        >
                          <path
                            d="M15.0627781,13.2833484 C14.2292216,13.2833484 13.4095577,13.2833484 12.5843367,13.2833484 C12.4040026,13.2849033 12.2246588,13.3085077 12.0508606,13.3535621 C11.8387141,13.3950229 11.676741,13.5559636 11.6451964,13.7566407 C11.5865132,13.9746677 11.7037133,14.2006319 11.9230486,14.2923451 C12.190053,14.4092135 12.476874,14.4813856 12.7704977,14.5055866 C13.5429267,14.5393932 13.8541211,14.8566551 13.7374232,15.6004 C13.5206985,16.9916712 13.2289538,18.3725403 12.9788868,19.7612109 C12.809397,20.6973934 12.6848928,21.2550492 12.5843367,22.2403035 C12.4530963,23.5262052 13.2109841,24.7401952 14.1368811,25.0131061 C15.0627781,25.286017 15.724825,25.286017 16.3214484,25.2794864 C17.5999796,25.2780159 18.8212793,24.7831633 19.6973522,23.9116198 C19.8564682,23.7743898 19.9759403,23.6017433 20.0446674,23.409722 C20.0782267,23.2419815 20.0359721,23.0687264 19.9279695,22.9312287 C19.7974575,22.8547704 19.6367149,22.8384803 19.4917416,22.8870201 C19.2639028,22.9572338 19.0610707,23.1028622 18.838789,23.1938799 C18.5790019,23.3073326 18.3070186,23.3945099 18.0274607,23.4539306 C17.8020089,23.5057029 17.5636393,23.4622801 17.3753928,23.335146 C17.1871463,23.2080119 17.0679843,23.0099721 17.0494211,22.7934019 C17.0026623,22.551429 16.9942363,22.3043299 17.0244144,22.0600589 C17.1188841,21.4567414 17.2522531,20.8586248 17.3689511,20.2605082 C17.6857025,18.6291236 18.0070849,16.9968722 18.3330981,15.3637539 C18.4220108,14.9164667 18.5720509,14.4769811 18.4720242,14.0062893 C18.4564927,13.79721 18.3517318,13.6027725 18.1813424,13.4667803 C18.010953,13.3307881 17.7892668,13.2646798 17.5662261,13.2833484 C16.7326696,13.2833484 15.8963346,13.2833484 15.0627781,13.2833484 Z"
                            id="Path"
                            fill="var(--svg-color-secondary, #EE7214)"
                          ></path>
                          <path
                            d="M16.5742939,6.28268183 C15.5498224,6.23775316 14.6000794,6.81738803 14.1716262,7.74904688 C13.743173,8.68070573 13.9212618,9.77901022 14.6221567,10.5275466 C15.3230516,11.276083 16.4072909,11.52591 17.3650741,11.159561 C18.3228574,10.793212 18.9636082,9.88358197 18.9860507,8.85837141 C19.0250803,7.48444098 17.9479291,6.33655519 16.5742939,6.28823887 L16.5742939,6.28268183 Z"
                            id="Path"
                            fill="var(--svg-color-secondary, #EE7214)"
                          ></path>
                        </g>
                      </svg>
                    </div>
                  </div>
                  <p className="whitespace-pre-line text-center text-lg md:text-xl">{t("aboutus.Questions? We have answers!")}</p>
                  <Link to={"/contactus"} className="w-100 group/button disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark p-3 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated pl-3 py-2 inline-block mt-4" href="https://cms.camperboys.com/pdf-files/equipment_vw_t6_1_california_ocean_editionen-1.pdf">
                    <div className="flex h-full items-center justify-between">
                      <div className="inline-block align-top mr-2 rounded-full bg-white p-1 child:h-7 child:w-7 dark:bg-off-white">
                        <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
                          <title>icon/actions_question</title>
                          <g id="icon/actions_question" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <path d="M16.5609275,17.3580413 L16.3692127,19.2467266 C15.73983,19.3562778 14.562691,19.3562778 13.8779956,19.3015022 C13.6320422,19.027624 13.6862809,15.1407023 13.6862809,15.1407023 C16.2596615,15.1407023 18.2305102,14.2369044 18.2305102,12.1838923 C18.2305102,11.1442293 17.4915762,9.66582429 15.3837884,9.66582429 C13.4951032,9.66582429 12.1536372,10.6512486 12.1536372,10.6512486 C12.1536372,10.6512486 10.9222596,8.70778776 11.003886,8.05048019 C11.6338057,7.33947102 13.3033885,6.6 15.8767691,6.6 C19.0242198,6.6 21.6523761,8.43390961 21.6523761,11.8831633 C21.6523761,15.1680901 19.2707101,17.0019997 16.5609275,17.3580413 M16.5732788,25.0115932 C15.8048089,25.7891924 14.5471175,25.7972476 13.7695183,25.0287777 C12.9913822,24.2608449 12.9833269,23.0031534 13.7517968,22.2250173 C14.5197297,21.4468811 15.7779581,21.4393629 16.5555573,22.2072957 C17.3336935,22.9757656 17.3412117,24.2334571 16.5732788,25.0115932" id="Fill-1" fill="var(--svg-color-secondary, #163435)"></path>
                          </g>
                        </svg>
                      </div>
                      <span className="flex">
                        {t("aboutus.To the helpcenter")}
                      </span>
                      <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                        <svg width="32" height="32" viewBox="0 0 32 32">
                          <g fill="none" fillRule="evenodd">
                            <path d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z" fill="var(--svg-color-secondary, #163435)"></path>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

            </div>
          </div>

          <div className="lg:col-span-4">
            <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg sticky ml-8 mt-8 hidden p-5 pt-7 transition-all duration-300 lg:block top-6">
              <div className="flex max-h-[calc(100vh-80px)] flex-col">
                <div className="flex max-h-full flex-col items-center overflow-auto text-center">
                  <h2 className="mb-1 whitespace-pre-line text-xl 2xl:text-2xl price-font fw-600">
                    <p>{tripplaner.title}</p>
                  </h2>
                  {/* <p><span className="mb-3 text-md text-primary text-orange block text-18">€{tripplaner.price}</span></p> */}
                  {/* <p className="mb-3"  dangerouslySetInnerHTML={{ __html: tripplaner.description}}></p> */}
                  <div className="mt-2 w-full rounded bg-off-white p-5 text-left dark:bg-black mb-6">

                    <div className="mb-4 flex items-center last:mb-0">
                      <div className="inline-flex items-center justify-center rounded-full border border-primary bg-white dark:border-primary-dark dark:bg-off-white mr-3 p-0">
                        <div className="inline-block align-top child:h-6 child:w-6">
                          <svg width="32" height="32" viewBox="0 0 32 32">
                            <g fill="none" fillRule="evenodd">
                              <path
                                d="m21.906 9.488-8.547 9.56-3.45-3.096a1.757 1.757 0 0 0-2.563.209c-.64.792-.547 1.98.21 2.659l4.764 4.274c.735.66 1.833.602 2.496-.13l9.73-10.869a1.954 1.954 0 0 0-.069-2.665 1.758 1.758 0 0 0-2.571.058z"
                                fill="var(--svg-color-secondary, #163435)"
                              ></path>
                            </g>
                          </svg>
                        </div>
                      </div>
                      <p className="text-md">{t("Voucher.SSL-secured checkout")}</p>
                    </div>
                    <div className="mb-4 flex items-center last:mb-0">
                      <div className="inline-flex items-center justify-center rounded-full border border-primary bg-white dark:border-primary-dark dark:bg-off-white mr-3 p-0">
                        <div className="inline-block align-top child:h-6 child:w-6">
                          <svg width="32" height="32" viewBox="0 0 32 32">
                            <g fill="none" fillRule="evenodd">
                              <path
                                d="m21.906 9.488-8.547 9.56-3.45-3.096a1.757 1.757 0 0 0-2.563.209c-.64.792-.547 1.98.21 2.659l4.764 4.274c.735.66 1.833.602 2.496-.13l9.73-10.869a1.954 1.954 0 0 0-.069-2.665 1.758 1.758 0 0 0-2.571.058z"
                                fill="var(--svg-color-secondary, #163435)"
                              ></path>
                            </g>
                          </svg>
                        </div>
                      </div>
                      <p className="text-md">{t("Voucher.Quick delivery as PDF")}</p>
                    </div>
                    <div className="mb-4 flex items-center last:mb-0">
                      <div className="inline-flex items-center justify-center rounded-full border border-primary bg-white dark:border-primary-dark dark:bg-off-white mr-3 p-0">
                        <div className="inline-block align-top child:h-6 child:w-6">
                          <svg width="32" height="32" viewBox="0 0 32 32">
                            <g fill="none" fillRule="evenodd">
                              <path
                                d="m21.906 9.488-8.547 9.56-3.45-3.096a1.757 1.757 0 0 0-2.563.209c-.64.792-.547 1.98.21 2.659l4.764 4.274c.735.66 1.833.602 2.496-.13l9.73-10.869a1.954 1.954 0 0 0-.069-2.665 1.758 1.758 0 0 0-2.571.058z"
                                fill="var(--svg-color-secondary, #163435)"
                              ></path>
                            </g>
                          </svg>
                        </div>
                      </div>
                      <p className="text-md">{t("Voucher.Questions?")} <Link className="underline" to={"/contactus"}>{t("Voucher.Contact us!")}</Link></p>
                    </div>
                  </div>
                  <span className="group outline-none-focus w-full">
                    <div className="relative flex items-center px-2 h-12 border border-secondary border-opacity-disabled rounded block w-full h-12 group-focus:border-primary rounded cursor-default">
                      <div className="flex items-center justify-between w-full opacity-disabled">
                        <span className="text-ellipsis whitespace-nowrap text-md custom-calender" onClick={() => setCalendarIsOpen(!calendarIsOpen)}>
                          <DatePicker
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={onChangedate}
                            minDate={minSelectableDate}
                            maxDate={addMonths(new Date(), 12)}
                            dateFormat="dd/MM/yyyy"
                            locale={i18n.resolvedLanguage}
                            monthsShown={isMobile ? 1 : 2}
                            withPortal
                            placeholderText={t("Voucher.Enter Date")}
                            onFocus={e => e.target.blur()}
                            ref={pickerRef}
                          />
                        </span>
                      </div>
                    </div>

                  </span>
                  {count ? (

                    <p className="mt-3"> <span className="mb-3 text-primary text-orange block text-4xl">{count * tripplaner.price}€</span></p>

                  ) :
                    <p className="mt-3"> <span className="mb-3 text-primary text-orange block text-4xl">{tripplaner.price}€</span></p>
                  }
                  {i18n.resolvedLanguage === 'en' ? <div className="text-md font-normal fw-600">Total incl. VAT</div> : <div className="text-md font-normal fw-600">{t("Rent.Total incl. Vat1")}</div>}


                </div>
              </div>
              {error ? <p style={{ color: "red" }} className="mb-2 flex w-full animate-wiggle items-center justify-between rounded bg-warning px-4 py-2 text-lg text-white">{error}</p> : ''}
              <Button onClick={(e) => handleCheckout(params.id)} className="mt-5 grid-cols1 inline-grid grid-rows-1 text-white bg-secondary hover:bg-primary pl-5 pr-2 w-full h-12 rounded bg-primary hover:bg-secondary transition-all"> <span className="relative flex col-start-1 row-start-1 items-center justify-between w-full h-full transition-opacity duration-300">
                <span className="transform transition-transform duration-300 translate-x-0">
                  <span>{t("Voucher.Continue to checkout")}</span>
                </span>
                <svg className="w-7 h-7 cssvar-secondary-to-white pointer-events-none" width="32" height="32"><svg viewBox="0 0 32 32" fill="none" id="icon-chevron-right"><path fillRule="evenodd" clipRule="evenodd" d="M22.1256 15.9999L14.479 8.35332C14.009 7.88273 13.2445 7.88213 12.7739 8.35211L12.3532 8.77342C11.8809 9.24581 11.8833 10.0073 12.3544 10.4785L17.8759 15.9999L12.3544 21.5219C11.8833 21.9925 11.8809 22.7546 12.3532 23.2264L12.7739 23.6477C13.2445 24.1182 14.009 24.117 14.479 23.6465L22.1256 15.9999Z" fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"></path></svg></svg>
              </span></Button>

            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
export default TripplannerSeperated;
