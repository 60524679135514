
import clientAuth from "../clientAuth";
import client from "../client";

export default {
 
 store: (userData) => client.post('contact/store', { json: userData }),
 salestore: (userData) => client.post('salecontact/store', { json: userData }),
  
//  index: () =>client.get('booking/index'),
//  show:(id)=>client.get(`booking/show/${id}`),
};