import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
export default function SimpleSlider() {
  var topSlider = {
    dots: false,
    infinite: false,
    centerMode: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    responsive: [

      {
        breakpoint: 1273,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
    ],

  };
  const [showResults, setShowResults] = React.useState(false)
  const onClick = () => setShowResults(true)
  const onClose = () => setShowResults(false)

  const [showResults1, setShowResults1] = React.useState(false)
  const onClick1 = () => setShowResults1(true)
  const onClose1 = () => setShowResults1(false)


  const [showResults2, setShowResults2] = React.useState(false)
  const onClick2 = () => setShowResults2(true)
  const onClose2 = () => setShowResults2(false)


  const [showResults3, setShowResults3] = React.useState(false)
  const onClick3 = () => setShowResults3(true)
  const onClose3 = () => setShowResults3(false)


  const [showResults4, setShowResults4] = React.useState(false)
  const onClick4 = () => setShowResults4(true)
  const onClose4 = () => setShowResults4(false)

  const [showResults5, setShowResults5] = React.useState(false)
  const onClick5 = () => setShowResults5(true)
  const onClose5 = () => setShowResults5(false)

  const [showResults6, setShowResults6] = React.useState(false)
  const onClick6 = () => setShowResults6(true)
  const onClose6 = () => setShowResults6(false)

  const [showResults7, setShowResults7] = React.useState(false)
  const onClick7 = () => setShowResults7(true)
  const onClose7 = () => setShowResults7(false)

  const [showResults8, setShowResults8] = React.useState(false)
  const onClick8 = () => setShowResults8(true)
  const onClose8 = () => setShowResults8(false)

  const [showResults9, setShowResults9] = React.useState(false)
  const onClick9 = () => setShowResults9(true)
  const onClose9 = () => setShowResults9(false)

  const [showResults10, setShowResults10] = React.useState(false)
  const onClick10 = () => setShowResults10(true)
  const onClose10 = () => setShowResults10(false)

  const [showResults11, setShowResults11] = React.useState(false)
  const onClick11 = () => setShowResults11(true)
  const onClose11 = () => setShowResults11(false)

  const [showResults12, setShowResults12] = React.useState(false)
  const onClick12 = () => setShowResults12(true)
  const onClose12 = () => setShowResults12(false)

  const [showResults13, setShowResults13] = React.useState(false)
  const onClick13 = () => setShowResults13(true)
  const onClose13 = () => setShowResults13(false)

  const [showResults14, setShowResults14] = React.useState(false)
  const onClick14 = () => setShowResults14(true)
  const onClose14 = () => setShowResults14(false)

  const [showResults15, setShowResults15] = React.useState(false)
  const onClick15 = () => setShowResults15(true)
  const onClose15 = () => setShowResults15(false)

  const [showResults16, setShowResults16] = React.useState(false)
  const onClick16 = () => setShowResults16(true)
  const onClose16 = () => setShowResults16(false)
  const { i18n, t } = useTranslation();
  return (
    <>
      <Slider {...topSlider} className="top-equipped-slider equipment-slider-wrap">

        <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12 h-full">
          <div className="picture-bg h-full" >
            <div className="pt-6 pb-8 2xl:pb-12 h-full">
              <h3 className="my-5 first:mt-0 break-words text-lg  top-equipped-title">
                {t("Equipment.Free kitchen equipment")}
              </h3>
              <div className="vertical-img">
                <picture className="text-0 flex h-full">
                  <img
                    className="inline-block object-cover dark:brightness-90 rounded 2xs:rounded-lg w-full mx-auto div-img"
                    src={'images/0.gif'}
                    style={{
                      backgroundImage: `url(images/kitchen-set-cover.png)`
                    }}
                    alt="picture for Camper rent-long"
                  />
                </picture>
                <div className="plus-icon" onClick={onClick}>+</div>
                <div className="picture-bg-overlay rounded 2xs:rounded-lg"></div>
              </div>
            </div>

          </div>



        </div>

        <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12 h-full">
          <div className="picture-bg h-full">
            <div className="pt-6 pb-8 2xl:pb-12 h-full">
              <h3 className="my-5 first:mt-0 break-words text-lg  top-equipped-title">
                {t("Equipment.Outdoor camping stove")}
              </h3>
              <div className="vertical-img">
                <picture className="text-0 flex h-full">
                  <img
                    className="inline-block object-cover dark:brightness-90 rounded 2xs:rounded-lg w-full mx-auto div-img"
                    src={'images/0.gif'}
                    style={{
                      backgroundImage: `url(images/outdoor-cover.png)`
                    }}

                    alt="service-img"

                  />
                </picture>
                <div className="plus-icon" onClick={onClick1}>+</div>
                <div className="picture-bg-overlay rounded 2xs:rounded-lg"></div>
              </div>


            </div>
          </div>


        </div>

        <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12 h-full">
          <div className="picture-bg h-full" >
            <div className="pt-6 pb-8 2xl:pb-12 h-full">
              <h3 className="my-5 first:mt-0 break-words text-lg top-equipped-title">
                {t("Equipment.Bike Rack")}
              </h3>
              <div className="vertical-img">
                <picture className="text-0 flex h-full">
                  <img
                    className="inline-block object-cover dark:brightness-90 rounded 2xs:rounded-lg w-full  mx-auto div-img"
                    src={'images/0.gif'}
                    style={{
                      backgroundImage: `url(images/bike-rack-cover.png)`
                    }}
                    alt="comfort-img"
                  />
                </picture>
                <div className="plus-icon" onClick={onClick2}>+</div>
                <div className="picture-bg-overlay rounded 2xs:rounded-lg"></div>
              </div>


            </div>
          </div>


        </div>

        <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12 h-full">
          <div className="picture-bg h-full" >
            <div className="pt-6 pb-8 2xl:pb-12 h-full">
              <h3 className="my-5 first:mt-0 break-words text-lg  top-equipped-title">
                {t("Equipment.Comfort Pillow & Blanket")}
              </h3>
              <div className="vertical-img">
                <picture className="text-0 flex h-full">
                  <img
                    className="inline-block object-cover dark:brightness-90 rounded 2xs:rounded-lg w-full  mx-auto div-img"
                    src={'images/0.gif'}
                    style={{
                      backgroundImage: `url(images/comfort-cover.png)`
                    }}

                    alt="picture dog"
                  />
                </picture>
                <div className="plus-icon" onClick={onClick3}>+</div>
                <div className="picture-bg-overlay rounded 2xs:rounded-lg"></div>
              </div>


            </div>
          </div>


        </div>

        <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12 h-full">
          <div className="picture-bg h-full" >
            <div className="pt-6 pb-8 2xl:pb-12 h-full">
              <h3 className="my-5 first:mt-0 break-words text-lg top-equipped-title">
                {t("Equipment.Additional camping table")}
              </h3>
              <div className="vertical-img">
                <picture className="text-0 flex h-full">
                  <img
                    className="inline-block object-cover dark:brightness-90 rounded 2xs:rounded-lg w-full  mx-auto div-img"
                    src={'images/0.gif'}
                    style={{
                      backgroundImage: `url(images/table-cover.png)`
                    }}

                    alt="balkan-campers"
                  />
                </picture>
                <div className="plus-icon" onClick={onClick4}>+</div>
                <div className="picture-bg-overlay rounded 2xs:rounded-lg"></div>
              </div>


            </div>
          </div>


        </div>

        <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12 h-full">
          <div className="picture-bg h-full" >
            <div className="pt-6 pb-8 2xl:pb-12 h-full">
              <h3 className="my-5 first:mt-0 break-words text-lg top-equipped-title">
                {t("Equipment.Stand Up Paddle Board")}
              </h3>
              <div className="vertical-img">
                <picture className="text-0 flex h-full">
                  <img
                    className="inline-block object-cover dark:brightness-90 rounded 2xs:rounded-lg w-full  mx-auto div-img"
                    src={'images/0.gif'}
                    style={{
                      backgroundImage: `url(images/stand-up-cover.jpeg)`
                    }}

                    alt="balkan-campers"
                  />
                </picture>
                <div className="plus-icon" onClick={onClick5}>+</div>
                <div className="picture-bg-overlay rounded 2xs:rounded-lg"></div>
              </div>


            </div>
          </div>


        </div>

        <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12 h-full">
          <div className="picture-bg h-full" >
            <div className="pt-6 pb-8 2xl:pb-12 h-full">
              <h3 className="my-5 first:mt-0 break-words text-lg top-equipped-title">
                {t("Equipment.Additional camping chair")}
              </h3>
              <div className="vertical-img">
                <picture className="text-0 flex h-full">
                  <img
                    className="inline-block object-cover dark:brightness-90 rounded 2xs:rounded-lg w-full  mx-auto div-img"
                    src={'images/0.gif'}
                    style={{
                      backgroundImage: `url(images/chair-cover.png)`
                    }}

                    alt="balkan-campers"
                  />
                </picture>
                <div className="plus-icon" onClick={onClick6}>+</div>
                <div className="picture-bg-overlay rounded 2xs:rounded-lg"></div>
              </div>


            </div>
          </div>


        </div>

        <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12 h-full">
          <div className="picture-bg h-full" >
            <div className="pt-6 pb-8 2xl:pb-12 h-full">
              <h3 className="my-5 first:mt-0 break-words text-lg top-equipped-title">
                {t("Equipment.Roof Top Safety Net")}
              </h3>
              <div className="vertical-img">
                <picture className="text-0 flex h-full">
                  <img
                    className="inline-block object-cover dark:brightness-90 rounded 2xs:rounded-lg w-full  mx-auto div-img"
                    src={'images/0.gif'}
                    style={{
                      backgroundImage: `url(images/net-cover.png)`
                    }}

                    alt="balkan-campers"
                  />
                </picture>
                <div className="plus-icon" onClick={onClick7}>+</div>
                <div className="picture-bg-overlay rounded 2xs:rounded-lg"></div>
              </div>


            </div>
          </div>


        </div>

        <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12 h-full">
          <div className="picture-bg h-full" >
            <div className="pt-6 pb-8 2xl:pb-12 h-full">
              <h3 className="my-5 first:mt-0 break-words text-lg top-equipped-title">
                {t("Equipment.Additional Bed")}
              </h3>
              <div className="vertical-img">
                <picture className="text-0 flex h-full">
                  <img
                    className="inline-block object-cover dark:brightness-90 rounded 2xs:rounded-lg w-full  mx-auto div-img"
                    src={'images/0.gif'}
                    style={{
                      backgroundImage: `url(images/bed-cover.png)`
                    }}

                    alt="balkan-campers"
                  />
                </picture>
                <div className="plus-icon" onClick={onClick8}>+</div>
                <div className="picture-bg-overlay rounded 2xs:rounded-lg"></div>
              </div>


            </div>
          </div>


        </div>

        <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12 h-full">
          <div className="picture-bg h-full" >
            <div className="pt-6 pb-8 2xl:pb-12 h-full">
              <h3 className="my-5 first:mt-0 break-words text-lg top-equipped-title">
                {t("Equipment.Towel")}
              </h3>
              <div className="vertical-img">
                <picture className="text-0 flex h-full">
                  <img
                    className="inline-block object-cover dark:brightness-90 rounded 2xs:rounded-lg w-full  mx-auto div-img"
                    src={'images/0.gif'}
                    style={{
                      backgroundImage: `url(images/towel-cover.jpg)`
                    }}

                    alt="balkan-campers"
                  />
                </picture>
                <div className="plus-icon" onClick={onClick9}>+</div>
                <div className="picture-bg-overlay rounded 2xs:rounded-lg"></div>
              </div>


            </div>
          </div>


        </div>

        <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12 h-full">
          <div className="picture-bg h-full" >
            <div className="pt-6 pb-8 2xl:pb-12 h-full">
              <h3 className="my-5 first:mt-0 break-words text-lg top-equipped-title">
                {t("Equipment.Hammock")}
              </h3>
              <div className="vertical-img">
                <picture className="text-0 flex h-full">
                  <img
                    className="inline-block object-cover dark:brightness-90 rounded 2xs:rounded-lg w-full  mx-auto div-img"
                    src={'images/0.gif'}
                    style={{
                      backgroundImage: `url(images/hamock-cover.jpg)`
                    }}

                    alt="balkan-campers"
                  />
                </picture>
                <div className="plus-icon" onClick={onClick10}>+</div>
                <div className="picture-bg-overlay rounded 2xs:rounded-lg"></div>
              </div>


            </div>
          </div>


        </div>

        <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12 h-full">
          <div className="picture-bg h-full" >
            <div className="pt-6 pb-8 2xl:pb-12 h-full">
              <h3 className="my-5 first:mt-0 break-words text-lg top-equipped-title">
                {t("Equipment.Level Ramp")}
              </h3>
              <div className="vertical-img">
                <picture className="text-0 flex h-full">
                  <img
                    className="inline-block object-cover dark:brightness-90 rounded 2xs:rounded-lg w-full  mx-auto div-img"
                    src={'images/0.gif'}
                    style={{
                      backgroundImage: `url(images/ramp-cover.png)`
                    }}

                    alt="balkan-campers"
                  />
                </picture>
                <div className="plus-icon" onClick={onClick11}>+</div>
                <div className="picture-bg-overlay rounded 2xs:rounded-lg"></div>
              </div>


            </div>
          </div>


        </div>

        <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12 h-full">
          <div className="picture-bg h-full" >
            <div className="pt-6 pb-8 2xl:pb-12 h-full">
              <h3 className="my-5 first:mt-0 break-words text-lg top-equipped-title">
                {t("Equipment.Warning sign")}
              </h3>
              <div className="vertical-img">
                <picture className="text-0 flex h-full">
                  <img
                    className="inline-block object-cover dark:brightness-90 rounded 2xs:rounded-lg w-full  mx-auto div-img"
                    src={'images/0.gif'}
                    style={{
                      backgroundImage: `url(images/warning-cover.jpeg)`
                    }}

                    alt="balkan-campers"
                  />
                </picture>
                <div className="plus-icon" onClick={onClick12}>+</div>
                <div className="picture-bg-overlay rounded 2xs:rounded-lg"></div>
              </div>


            </div>
          </div>


        </div>

        <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12 h-full">
          <div className="picture-bg h-full" >
            <div className="pt-6 pb-8 2xl:pb-12 h-full">
              <h3 className="my-5 first:mt-0 break-words text-lg top-equipped-title">
                {t("Equipment.Roof Tent Heater Hose")}
              </h3>
              <div className="vertical-img">
                <picture className="text-0 flex h-full">
                  <img
                    className="inline-block object-cover dark:brightness-90 rounded 2xs:rounded-lg w-full  mx-auto div-img"
                    src={'images/0.gif'}
                    style={{
                      backgroundImage: `url(images/roof-tent-cover.jpeg)`
                    }}

                    alt="balkan-campers"
                  />
                </picture>
                <div className="plus-icon" onClick={onClick13}>+</div>
                <div className="picture-bg-overlay rounded 2xs:rounded-lg"></div>
              </div>


            </div>
          </div>


        </div>

        <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12 h-full">
          <div className="picture-bg h-full" >
            <div className="pt-6 pb-8 2xl:pb-12 h-full">
              <h3 className="my-5 first:mt-0 break-words text-lg top-equipped-title">
                {t("Equipment.Isolation mats")}
              </h3>
              <div className="vertical-img">
                <picture className="text-0 flex h-full">
                  <img
                    className="inline-block object-cover dark:brightness-90 rounded 2xs:rounded-lg w-full  mx-auto div-img div-img-center"
                    src={'images/0.gif'}
                    style={{
                      backgroundImage: `url(images/Pop-Up-picture-Isolation-mats.jpg)`
                    }}

                    alt="balkan-campers"
                  />
                </picture>
                <div className="plus-icon" onClick={onClick16}>+</div>
                <div className="picture-bg-overlay rounded 2xs:rounded-lg"></div>
              </div>


            </div>
          </div>


        </div>

        <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12 h-full">
          <div className="picture-bg h-full" >
            <div className="pt-6 pb-8 2xl:pb-12 h-full">
              <h3 className="my-5 first:mt-0 break-words text-lg top-equipped-title">
                {t("Equipment.Snow chains")}
              </h3>
              <div className="vertical-img">
                <picture className="text-0 flex h-full">
                  <img
                    className="inline-block object-cover dark:brightness-90 rounded 2xs:rounded-lg w-full  mx-auto div-img"
                    src={'images/0.gif'}
                    style={{
                      backgroundImage: `url(images/snow-chain-cover.jpg)`
                    }}

                    alt="balkan-campers"
                  />
                </picture>
                <div className="plus-icon" onClick={onClick14}>+</div>
                <div className="picture-bg-overlay rounded 2xs:rounded-lg"></div>
              </div>


            </div>
          </div>


        </div>

        <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12 h-full">
          <div className="picture-bg h-full" >
            <div className="pt-6 pb-8 2xl:pb-12 h-full">
              <h3 className="my-5 first:mt-0 break-words text-lg top-equipped-title">
               {t("Equipment.Essay Mirror Caravan")}
              </h3>
              <div className="vertical-img">
                <picture className="text-0 flex h-full">
                  <img
                    className="inline-block object-cover dark:brightness-90 rounded 2xs:rounded-lg w-full  mx-auto div-img"
                    src={'images/0.gif'}
                    style={{
                      backgroundImage: `url(images/easy-mirror-cover.png)`
                    }}

                    alt="balkan-campers"
                  />
                </picture>
                <div className="plus-icon" onClick={onClick15}>+</div>
                <div className="picture-bg-overlay rounded 2xs:rounded-lg"></div>
              </div>


            </div>
          </div>


        </div>



      </Slider>

      {showResults ?
        <div className="top-equipped-modal">
          <div className="top-equipped-modal-body full-width">
            <div className="text-right">
              <button onClick={onClose} className="close-top-eq-mdal">X</button>
            </div>
            <div className="pt-4 pb-4 text-left mx-auto max-w-4xl">
              <h3 className="my-5 first:mt-0 break-words text-lg md:text-2xl">
                {t("Equipment.Free kitchen equipment")}
              </h3>
              <div className="bottom-box">
                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                  {t("Equipment.Our campers provide you with a comprehensive kitchen set, including dishes, cutlery, a frying pan, a pot, and an espresso maker.")} {t("Equipment.You're ready for culinary adventures in nature!")}
                </div>

                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>

              </div>
              <div className="image-gallery">
                <picture className="text-0 h-full">
                  <img src="images/Pop up picture-min.png" className="rounded" alt="popup-img" />
                </picture>

              </div>
            </div>
          </div>
        </div>
        : null}

      {showResults1 ?
        <div className="top-equipped-modal">
          <div className="top-equipped-modal-body full-width">
            <div className="text-right">
              <button onClick={onClose1} className="close-top-eq-mdal">X</button>
            </div>
            <div className="pt-4 pb-4 text-left mx-auto max-w-4xl">
              <h3 className="my-5 first:mt-0 break-words text-lg md:text-2xl">
                {t("Equipment.Cooking in the great outdoors?")}
              </h3>
              <div className="bottom-box">
                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                  {t("Equipment.Imagine cooking your breakfast by the sea in Portugal.")} {t("Equipment.With a camping stove, you are completely mobile and can cook anywhere you want.")} {t("Equipment.To ensure you don't run out of gas on your journey, we offer up to two gas cartridges as replacements.")}
                </div>

                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>

              </div>
              <div className="image-gallery w-full ">
                <picture className="text-0 h-full flex-full">
                  <img src="images/outdoor-Camping-stove-popup.jpg" className="rounded" alt="popup-img" />
                </picture>
                <picture className="text-0 h-full">
                  <img src="images/0.gif" className="rounded" alt="popup-img" style={{
                    backgroundImage: `url(images/outdoor-Camping-stove-popup.jpg)`
                  }} />
                </picture>

              </div>
            </div>
          </div>
        </div>
        : null}

      {showResults2 ?
        <div className="top-equipped-modal">
          <div className="top-equipped-modal-body full-width">
            <div className="text-right">
              <button onClick={onClose2} className="close-top-eq-mdal">X</button>
            </div>
            <div className="pt-4 pb-4 text-left mx-auto max-w-4xl">
              <h3 className="my-5 first:mt-0 break-words text-lg md:text-2xl">
                {t("Equipment.Camping adventures on two wheels?")}
              </h3>
              <div className="bottom-box">
                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                  {t("Equipment.No matter where you are, with a bicycle, you can leisurely explore your travel destinations.")}
                </div>

                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal mb-4">
                  {t("Equipment.Discover our bike racks for nearly all of our camper models.")} <br/>{t("Equipment.Please make sure to pay attention to the maximum weight limit.")}
                </div>

                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                  <ul>
                    <li><span className="font-bold mr-2 text-shadow">{t("Equipment.Camper Vans")}:</span>{t("Equipment.When booking your campervan, enhance it with a 3-bike rack (max. 65 kg capacity) on the tow hitch.")}</li>
                    <li><span className="font-bold mr-2 text-shadow">{t("Equipment.Travel Vans")}:</span>{t("Equipment.When booking, choose between a 2-bike rack (35 kg) on the door or a 3-bike rack (65 kg) for the tow hitch.")}</li>
                    <li><span className="font-bold mr-2 text-shadow">{t("Equipment.Motorhomes")}:</span>{t("Equipment.When you're booking a motorhome, you can also add a 3-bike rack with up to 65 kg capacity, which is mounted at the rear.")}</li>
                    <li><span className="font-bold mr-2 text-shadow">{t("Equipment.Caravans")}:</span>{t("Equipment.Need a bike rack for a caravan? Contact us at")} <a href="mailto:info@borntocamp.de">[info@borntocamp.de]</a></li>
                  </ul>
                </div>

                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>

              </div>
              <div className="image-gallery">
                <picture className="text-0 h-full">
                  <img src="images/bike-rack-popup.jpg" className="rounded" alt="popup-img" />
                </picture>
                <picture className="text-0 h-full">
                  <img src="images/bike-rack-popup-1.jpg" className="rounded" alt="popup-img" />
                </picture>

              </div>
            </div>
          </div>
        </div>
        : null}

      {showResults3 ?
        <div className="top-equipped-modal">
          <div className="top-equipped-modal-body full-width">
            <div className="text-right">
              <button onClick={onClose3} className="close-top-eq-mdal">X</button>
            </div>
            <div className="pt-4 pb-4 text-left mx-auto max-w-4xl">
              <h3 className="my-5 first:mt-0 break-words text-lg md:text-2xl">
                {t("Equipment.Pillow & Blanket just like home")}
              </h3>
              <div className="bottom-box">
                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                  {t("Equipment.When you choose our pillow and blanket set, you'll receive a freshly covered, fluffy pillow and a cozy blanket (125 cm x 190 cm) – for the comfort of home! Everything is hygienically clean and freshly washed! Fresh bed sheets are also available during the booking process.")}
                </div>

                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>

              </div>
              <div className="image-gallery">
                <picture className="text-0 h-full">
                  <img src="images/pillow-pop-up.png" className="rounded" alt="popup-img" />
                </picture>

              </div>
            </div>
          </div>
        </div>
        : null}

      {showResults4 ?
        <div className="top-equipped-modal">
          <div className="top-equipped-modal-body full-width">
            <div className="text-right">
              <button onClick={onClose4} className="close-top-eq-mdal">X</button>
            </div>
            <div className="pt-4 pb-4 text-left mx-auto max-w-4xl">
              <h3 className="my-5 first:mt-0 break-words text-lg md:text-2xl">
                {t("Equipment.More Space for Friends and Co.")}
              </h3>
              <div className="bottom-box">
                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                  {t("Equipment.If you plan on cooking in the motorhome more often")}{t("Equipment.additional space for outdoor preparation is definitely worth considering.")}{t("Equipment.Whether you want to enjoy your breakfast by the sea or have dinner in nature, our camping table is your loyal companion!")}&nbsp;{t("Equipment.One camping table is always included when you book with us.")}
                </div>

                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>

              </div>
              <div className="image-gallery">
                <picture className="text-0 h-full">
                  <img src="images/table-popup.png" className="rounded" alt="popup-img" />
                </picture>

              </div>
            </div>
          </div>
        </div>
        : null}

      {showResults5 ?
        <div className="top-equipped-modal">
          <div className="top-equipped-modal-body full-width">
            <div className="text-right">
              <button onClick={onClose5} className="close-top-eq-mdal">X</button>
            </div>
            <div className="pt-4 pb-4 text-left mx-auto max-w-4xl">
              <h3 className="my-5 first:mt-0 break-words text-lg md:text-2xl">
                {t("Equipment.Floating Above the Water")}
              </h3>
              <div className="bottom-box">
                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                  {t("Equipment.With Stand Up Paddling (SUP), you can transform your camping trip into an unforgettable adventure! Imagine yourself paddling on a calm lake in the morning, surrounded by birds and pristine nature, effortlessly gliding across the water.")} {t("Equipment.SUPs are versatile, easy to transport, and provide an effective full-body workout to stay active.")}&nbsp;{t("Equipment.You can use them to explore remote places that would be inaccessible on foot or with a larger boat.")} {t("Equipment.SUPing is easy to learn and can be quickly mastered by anyone.")} {t("Equipment.Do you want to experience these unforgettable moments? Then rent a SUP for your camper now and enrich your camping adventure in a unique way!")}
                </div>

                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>

              </div>
              <div className="image-gallery">
                <picture className="text-0 h-full">
                  <img src="images/stand-up-popup.jpg" className="rounded" alt="popup-img" />
                </picture>

              </div>
            </div>
          </div>
        </div>
        : null}

      {showResults6 ?
        <div className="top-equipped-modal">
          <div className="top-equipped-modal-body full-width">
            <div className="text-right">
              <button onClick={onClose6} className="close-top-eq-mdal">X</button>
            </div>
            <div className="pt-4 pb-4 text-left mx-auto max-w-4xl">
              <h3 className="my-5 first:mt-0 break-words text-lg md:text-2xl">
                {t("Equipment.More space for more friends")}
              </h3>
              <div className="bottom-box">
                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                  {t("Equipment.Whether you need extra chairs for friends, family, or just for relaxation - we have as many chairs as you need! Our camping chairs are set up in no time and just as quickly stored in the trunk.")} {t("Equipment.This way, you are flexible and well-prepared for your next adventure.")}
                </div>

                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>

              </div>
              <div className="image-gallery">
                <picture className="text-0 h-full">
                  <img src="images/chair-popup.png" className="rounded" alt="popup-img" />
                </picture>

              </div>
            </div>
          </div>
        </div>
        : null}

      {showResults7 ?
        <div className="top-equipped-modal">
          <div className="top-equipped-modal-body full-width">
            <div className="text-right">
              <button onClick={onClose7} className="close-top-eq-mdal">X</button>
            </div>
            <div className="pt-4 pb-4 text-left mx-auto max-w-4xl">
              <h3 className="my-5 first:mt-0 break-words text-lg md:text-2xl">
                {t("Equipment.Your safety comes first!")}
              </h3>
              <div className="bottom-box">
                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                  {t("Equipment.For the safe overnight stay of children in the rooftop tent, we offer a special child safety feature in the form of a net.")} {t("Equipment.This net encloses the opening in the floor that serves as access to the bed in the pop-up roof.")} {t("Equipment.The net can also be used by adults as a comfortable barrier above.")}
                </div>

                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>

              </div>
              <div className="image-gallery">
                <picture className="text-0 h-full">
                  <img src="images/roof-net-popup.png" className="rounded" alt="popup-img" />
                </picture>

              </div>
            </div>
          </div>
        </div>
        : null}

      {showResults8 ?
        <div className="top-equipped-modal">
          <div className="top-equipped-modal-body full-width">
            <div className="text-right">
              <button onClick={onClose8} className="close-top-eq-mdal">X</button>
            </div>
            <div className="pt-4 pb-4 text-left mx-auto max-w-4xl">
              <h3 className="my-5 first:mt-0 break-words text-lg md:text-2xl">
                {t("Equipment.More sleeping space")}
              </h3>
              <div className="bottom-box">
                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                  {t("Equipment.For the Euro Camper, Couple Camper, Adventure Camper, and Family Camper, we offer a comfortable additional bed for the seating area.")} {t("Equipment.The additional bed measures 80*190cm when set up.")} {t("Equipment.Check out our camper tutorial videos to see exactly how the additional bed is assembled.")}
                </div>

                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>

              </div>
              <div className="image-gallery">
                <picture className="text-0 h-full">
                  <img src="images/bed-pic-popup.png" className="rounded" alt="popup-img" />
                </picture>

              </div>
            </div>
          </div>
        </div>
        : null}

      {showResults9 ?
        <div className="top-equipped-modal">
          <div className="top-equipped-modal-body full-width">
            <div className="text-right">
              <button onClick={onClose9} className="close-top-eq-mdal">X</button>
            </div>
            <div className="pt-4 pb-4 text-left mx-auto max-w-4xl">
              <h3 className="my-5 first:mt-0 break-words text-lg md:text-2xl">
                {t("Equipment.Your Personal Towel")}
              </h3>
              <div className="bottom-box">
                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                  {t("Equipment.Whether you're camping, after a shower, or at the beach – a towel is essential.")} {t("Equipment.If you don't have one with you or prefer not to bring one, we have plush towels ready for you.")} {t("Equipment.They've been freshly washed and are just waiting for you to use them.")}
                </div>

                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>

              </div>
              <div className="image-gallery">
                <picture className="text-0 h-full">
                  <img src="images/towel-popup.png" className="rounded" alt="popup-img" />
                </picture>

              </div>
            </div>
          </div>
        </div>
        : null}

      {showResults10 ?
        <div className="top-equipped-modal">
          <div className="top-equipped-modal-body full-width">
            <div className="text-right">
              <button onClick={onClose10} className="close-top-eq-mdal">X</button>
            </div>
            <div className="pt-4 pb-4 text-left mx-auto max-w-4xl">
              <h3 className="my-5 first:mt-0 break-words text-lg md:text-2xl">
                {t("Equipment.Relaxation made easy!")}
              </h3>
              <div className="bottom-box">
                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal only-mobile-br">
                  {t("Equipment.Quick setup and absolutely comfortable! Our compact hammock is ready to use in no time, and the comfort leaves nothing to be desired.")}&nbsp;<br/>{t("Equipment.It is made of lightweight parachute silk material and can accommodate 1-2 people.")} {t("Equipment.Assembly is a breeze and requires no cumbersome knots.")} {t("Equipment.Relaxation has never been so simple.")}
                </div>

                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>

              </div>
              <div className="image-gallery">
                <picture className="text-0 h-full">
                  <img src="images/hamock-popup.jpg" className="rounded" alt="popup-img" />
                </picture>

              </div>
            </div>
          </div>
        </div>
        : null}

      {showResults11 ?
        <div className="top-equipped-modal">
          <div className="top-equipped-modal-body full-width">
            <div className="text-right">
              <button onClick={onClose11} className="close-top-eq-mdal">X</button>
            </div>
            <div className="pt-4 pb-4 text-left mx-auto max-w-4xl">
              <h3 className="my-5 first:mt-0 break-words text-lg md:text-2xl">
                {t("Equipment.Always stand horizontally")}
              </h3>
              <div className="bottom-box">
                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                  {t("Equipment.They are the ideal solution to level out uneven terrain and ensure a flat sleeping spot.")} {t("Equipment.Simply drive up onto them to balance the vehicle's incline, creating the perfect resting place for a relaxed time on the road in no time.")} {t("Equipment.And when you don't need them, these chocks can be stored conveniently to save space.")}
                </div>

                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>

              </div>
              <div className="image-gallery">
                <picture className="text-0 h-full">
                  <img src="images/ramp-popup.png" className="rounded" alt="popup-img" />
                </picture>

              </div>
            </div>
          </div>
        </div>
        : null}

      {showResults12 ?
        <div className="top-equipped-modal">
          <div className="top-equipped-modal-body full-width">
            <div className="text-right">
              <button onClick={onClose12} className="close-top-eq-mdal">X</button>
            </div>
            <div className="pt-4 pb-4 text-left mx-auto max-w-4xl">
              <h3 className="my-5 first:mt-0 break-words text-lg md:text-2xl">
                {t("Equipment.Bicycle tour in Italy, Spain or Portugal?")}
              </h3>
              <div className="bottom-box">
                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                  {t("Equipment.In these countries, you need a warning sign for protruding loads, such as bicycles on the bike carrier.")} {t("Equipment.To be optimally visible at night, it is necessary to attach a warning sign to the wheels.")}&nbsp;
                  {t("Equipment.We provide you with the fastening straps and the warning sign if you add them during the booking process.")}
                </div>

                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>

              </div>
              <div className="image-gallery">
                <picture className="text-0 h-full">
                  <img src="images/Submenu 1.png" className="rounded" alt="popup-img" />
                </picture>
                <picture className="text-0 h-full">
                  <img src="images/Submenu 2.png" className="rounded" alt="popup-img" />
                </picture>

              </div>
            </div>
          </div>
        </div>
        : null}

      {showResults13 ?
        <div className="top-equipped-modal">
          <div className="top-equipped-modal-body full-width">
            <div className="text-right">
              <button onClick={onClose13} className="close-top-eq-mdal">X</button>
            </div>
            <div className="pt-4 pb-4 text-left mx-auto max-w-4xl">
              <h3 className="my-5 first:mt-0 break-words text-lg md:text-2xl">
               {t("Equipment.For extra coziness")}
              </h3>
              <div className="bottom-box">
                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                  {t("Equipment.Would you like to have it nice and warm in your roof tent too? Then the heater hose extension is just right for you.")} {t("Equipment.Attach the hose directly to the outlet of your parking heater, and enjoy a comfortable warmth in your roof tent even in cold weather.")}
                </div>

                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>

              </div>
            </div>
          </div>
        </div>
        : null}

      {showResults14 ?
        <div className="top-equipped-modal">
          <div className="top-equipped-modal-body full-width">
            <div className="text-right">
              <button onClick={onClose14} className="close-top-eq-mdal">X</button>
            </div>
            <div className="pt-4 pb-4 text-left mx-auto max-w-4xl">
              <h3 className="my-5 first:mt-0 break-words text-lg md:text-2xl">
                {t("Equipment.Safety on snowy roads")}
              </h3>
              <div className="bottom-box">
                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                  {t("Equipment.Snow chains should be used whenever the roads are covered in snow or ice.")} {t("Equipment.They are attached to the drive wheels, ensuring safe driving even on snowy roads.")} {t("Equipment.We offer suitable snow chains for every model to ensure your safety in winter conditions.")}
                </div>

                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>

              </div>
            </div>
          </div>
        </div>
        : null}

      {showResults15 ?
        <div className="top-equipped-modal">
          <div className="top-equipped-modal-body full-width">
            <div className="text-right">
              <button onClick={onClose15} className="close-top-eq-mdal">X</button>
            </div>
            <div className="pt-4 pb-4 text-left mx-auto max-w-4xl">
              <h3 className="my-5 first:mt-0 break-words text-lg md:text-2xl">
                {t("Equipment.See what you need to see")}
              </h3>
              <div className="bottom-box">
                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                  {t("Equipment.When transporting a caravan, you need additional mirrors to be able to see the traffic behind the caravan.")} {t("Equipment.These are included free of charge with the caravan.")}
                </div>

                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>

              </div>
              <div className="image-gallery">
                <picture className="text-0 h-full">
                  <img src="images/mirror-popup.png" className="rounded" alt="popup-img" />
                </picture>

              </div>
            </div>
          </div>
        </div>
        : null}


      {showResults16 ?
        <div className="top-equipped-modal">
          <div className="top-equipped-modal-body full-width">
            <div className="text-right">
              <button onClick={onClose16} className="close-top-eq-mdal">X</button>
            </div>
            <div className="pt-4 pb-4 text-left mx-auto max-w-4xl">
              <h3 className="my-5 first:mt-0 break-words text-lg md:text-2xl">
               {t("Equipment.Warm throughout the winter")}
              </h3>
              <div className="bottom-box">
                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal only-mobile-br">
                  {t("Equipment.We not only take care of the warmth in the rooftop tent with our heating hose but also ensure optimal insulation in the living area during the cold season.")}&nbsp;<br/>{t("Equipment.Our insulation mats are extremely practical in winter and serve to keep the icy cold outside and preserve the warmth inside.")}
                </div>

                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>

              </div>
              <div className="image-gallery">
                <picture className="text-0 h-full">
                  <img src="images/Pop-Up-picture-Isolation-mats.jpg" className="rounded" alt="popup-img" />
                </picture>

              </div>
            </div>
          </div>
        </div>
        : null}


    </>

  );
}