import React, { useState, useEffect } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import Slider from "react-slick";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-tabs/style/react-tabs.css';
import { Link } from "react-router-dom";
import {t} from 'i18next';
const Imprint = () => {

  return (
    <>
      <Header />

      <div className="min-h-screen bg-off-white">
        <div className="pb-5 text-center"></div>

        <div className="mx-5 max-w-screen-xl xl-padded:mx-auto privacy-container">
          <div className="hero-padding sm-pt-x  pb-4 2xl:pb-8 text-center mx-auto max-w-3xl">
            <h1 className="mb-40 first:mt-0 break-words text-xl md:text-3xl">
              {t("print.Imprint Born To Camp")}
            </h1>
            <div className="min-h-[30px] break-words text-md 2xl:text-2md my-5">
              <span className="fw-700">{t("print.Registered Office")}</span>
            </div>
            <div className="min-h-[30px] break-words text-md 2xl:text-2md">Born To Camp e.U. </div>
            <div className="min-h-[30px] break-words text-md 2xl:text-2md">Fidelhof 3</div>
            <div className="min-h-[30px] break-words text-md 2xl:text-2md">93128, Regenstauf</div>
            <div className="min-h-[30px] break-words text-md 2xl:text-2md"></div>
            <div className="min-h-[30px] break-words text-md 2xl:text-2md">{t("print.Contact Person")} </div>
            <div className="min-h-[30px] break-words text-md 2xl:text-2md">Luca Brandl</div>
            <div className="min-h-[30px] break-words text-md 2xl:text-2md"></div>
            <div className="min-h-[30px] break-words text-md 2xl:text-2md">E-Mail</div>
            <div className="min-h-[30px] break-words text-md 2xl:text-2md"><a href="mailto:'info@borntocamp.de'" className="no-underline">info@borntocamp.de</a></div>
            <div className="min-h-[30px] break-words text-md 2xl:text-2md">{t("print.VAT Tax–ID")}: DE345033221</div>
            <div className="min-h-[30px] break-words text-md 2xl:text-2md">{t("print.Court of Registration")} </div>
            <div className="min-h-[30px] break-words text-md 2xl:text-2md">Amtsgericht Regensburg</div>
            <div className="min-h-[30px] break-words text-md 2xl:text-2md"></div>
            <div className="min-h-[30px] break-words text-md 2xl:text-2md">{t("print.Authorized Partner")} </div>
            <div className="min-h-[30px] break-words text-md 2xl:text-2md">Luca Brandl</div>
            <div className="min-h-[30px] break-words text-md 2xl:text-2md"></div>

            <div className="min-h-[30px] break-words text-md 2xl:text-2md">{t("print.Responsible for Content according to §55 (2) RStV")} </div>
            <div className="min-h-[30px] break-words text-md 2xl:text-2md">Luca Brandl</div>
            <div className="min-h-[30px] break-words text-md 2xl:text-2md"></div>

            <div className="min-h-[30px] break-words text-md 2xl:text-2md">{t("print.Image and Graphic Sources")}</div>
            <div className="min-h-[30px] break-words text-md 2xl:text-2md">{t("print.All Rights reserved by Born To Camp e.U.")}</div>
            <div className="min-h-[30px] break-words text-md 2xl:text-2md"></div>

            <div className="min-h-[30px] break-words text-md 2xl:text-2md">{t("print.Contact Information and Business Hours")}</div>
            <div className="min-h-[30px] break-words text-md 2xl:text-2md">{t("print.You can reach us during our business hours and by appointment")}</div>
            <div className="min-h-[30px] break-words text-md 2xl:text-2md">{t("print.Monday - Friday from 09am to 05 pm")}.</div>
            <div className="min-h-[30px] break-words text-md 2xl:text-2md"></div>
            <div className="min-h-[30px] break-words text-md 2xl:text-2md"></div>
            <div className="min-h-[30px] break-words text-md 2xl:text-2md">E-Mail:&nbsp;<a href="mailto:'info@borntocamp.de'">info@borntocamp.de</a></div>
            <div className="min-h-[30px] break-words text-md 2xl:text-2md">{t("Rent.Phone Number")}:<a className="no-underline" href="tel:'+49 (0) 163 2937354'"> +49 (0) 163 2937354</a></div>
            <div className="min-h-[30px] break-words text-md 2xl:text-2md"></div>
            <div className="min-h-[30px] break-words text-md 2xl:text-2md"></div>
            <div className="min-h-[30px] break-words text-md 2xl:text-2md">{t("print.All prices include the statutory value-added tax")}.</div>
            <div className="min-h-[30px] break-words text-md 2xl:text-2md">{t("print.German consumer protection law applies")}.</div>




            

          </div>
          <div className="pt-8 2xl:pt-20 pb-8 2xl:pb-12">
          <h1 className="mb-5 my-5 first:mt-0 break-words text-xl md:text-3xl text-center">
              {t("print.Legal Information")}
            </h1>
            <div className="min-h-[30px] break-words text-md 2xl:text-2md"></div>
            <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg mx-auto p-0 max-w-3xl legal-information">
              <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                <summary className="flex cursor-pointer select-none items-center justify-between py-6 px-7 2xl:py-8 2xl:px-9">
                  <h3>
                    <div className="flex items-center">
                      <p className="text-md 2xl:text-lg text-18">
                      {t("print.Limitation of Liability")}:
                      </p>
                    </div>
                  </h3>
                  <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-8 md:child:w-8">
                    <svg
                      width="42"
                      height="42"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path
                          d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                          fill="var(--svg-color-secondary, #163435)"
                        ></path>
                      </g>
                    </svg>
                  </div>
                </summary>
                <div className="px-7 2xl:px-9">
                  <div>
                    <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                     {t("print.The contents on this website are created with utmost care. However, the operator cannot guarantee the accuracy, completeness, and timeliness of the provided information. The use of the website's contents is at the user's own risk. Contributions identified by the author's name reflect their personal opinions and may not always represent the views of the operator. Mere use of the website does not establish any contractual relationship between the user and the operator")}.
                      </div>
                      
                    </div>
                  </div>
                </div>
              </details>
              <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                <summary className="flex cursor-pointer select-none items-center justify-between py-6 px-7 2xl:py-8 2xl:px-9">
                  <h3>
                    <div className="flex items-center">
                      <p className="text-md 2xl:text-lg text-18">
                      {t("print.External Links")}:
                      </p>
                    </div>
                  </h3>
                  <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-8 md:child:w-8">
                    <svg
                      width="42"
                      height="42"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path
                          d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                          fill="var(--svg-color-secondary, #163435)"
                        ></path>
                      </g>
                    </svg>
                  </div>

                </summary>
                <div className="px-7 2xl:px-9">
                  <div>
                    <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                      {t("print.This website contains links to third-party websites")}&nbsp;("{t("print.External Links1")}").&nbsp;{t("print.These websites are the responsibility of their respective operators. Upon the initial linking, these external contents were checked for possible legal violations, none of which were evident at the time. The operator has no influence over the current and future design and content of the linked pages. Setting up external links does not mean that the operator adopts the content behind the reference or link as their own. Continuous monitoring of external links is not feasible for the operator without specific indications of legal violations. However, if legal infringements become known, such external links will be deleted immediately")}.
                      </div>
                      
                    </div>
                  </div>
                </div>
              </details>
              <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                <summary className="flex cursor-pointer select-none items-center justify-between py-6 px-7 2xl:py-8 2xl:px-9">
                  <h3>
                    <div className="flex items-center">
                      <p className="text-md 2xl:text-lg text-18">
                      {t("print.Copyright")}<span className="mobile-none">{t("print.and Performance Rights")}:</span> 
                      </p>
                    </div>
                  </h3>
                  <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-8 md:child:w-8">
                    <svg
                      width="42"
                      height="42"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path
                          d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                          fill="var(--svg-color-secondary, #163435)"
                        ></path>
                      </g>
                    </svg>
                  </div>

                </summary>
                <div className="px-7 2xl:px-9">
                  <div>
                    <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                      {t("print.The contents published on this website are protected by German copyright and performance protection laws. Any use not permitted by German copyright and performance protection law requires the prior written consent of the operator or the respective rights holder. This applies particularly to duplication, modification, translation, storage, processing, or reproduction of content in databases or other electronic media and systems. Contents and rights of third parties are duly marked as such. Unauthorized duplication or distribution of individual contents or complete pages is not permitted and is punishable. Only the production of copies and downloads for personal, private, and non-commercial use is allowed. The display of this website in external frames is only allowed with written permission")}.
                      </div>
                      
                    </div>
                  </div>
                </div>
              </details>
              <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                <summary className="flex cursor-pointer select-none items-center justify-between py-6 px-7 2xl:py-8 2xl:px-9">

                  <h3>
                    <div className="flex items-center">
                      <p className="text-md 2xl:text-lg text-18">
                     {t("print.Data Protection")}:
                      </p>
                    </div>
                  </h3>
                  <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-8 md:child:w-8">
                    <svg
                      width="42"
                      height="42"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path
                          d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                          fill="var(--svg-color-secondary, #163435)"
                        ></path>
                      </g>
                    </svg>
                  </div>

                </summary>
                <div className="px-7 2xl:px-9">
                  <div>
                    <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                      {t("print.The privacy policy is available at")}: <Link to="/privacystatement" target="_blank">www.borntocamp.de/datenschutz</Link>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </details>
            </div>
          </div>
          <div className="min-h-[30px] break-words text-md 2xl:text-2md"></div>
          <div className="min-h-[30px] break-words text-md 2xl:text-2md"></div>
        </div>

      </div>



      <Footer />
    </>
  );
};

export default Imprint;
