import React, { useState, useEffect, useRef } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import Explanatory from "../../Components/Explanatory/Explanatory";
import Campersrent from "../../Components/Campersrent/Campersrent";
import { Link, useNavigate } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@splidejs/react-splide/css/core";
import Select from "react-select";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ContactService from "../../Api/services/ContactService";
import HomeService from "../../Api/services/HomeService";
import CamperService from "../../Api/services/CamperService";

import { useTranslation } from "react-i18next";
const Contactus = () => {
  const { i18n, t } = useTranslation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const pickerRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1200);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (isMobile && pickerRef.current !== null) {
      pickerRef.current.input.readOnly = true;
    }
  }, [isMobile, pickerRef]);


  const conatctForm = useRef(null);

  const [Error, setError] = useState("");
  const [isFormSubmit, setisFormSubmit] = useState(false);
  const navigate = useNavigate();
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);
  const [location, setLocation] = useState([]);
  const [camper, setCamper] = useState([]);
  useEffect(() => {
    getLocation();
    getCamper();
    setNav1(slider1);
    setNav2(slider2);
  }, []);

  const getCamper = async () => {
    try {
      let camperList = await CamperService.index().json();
      var responsedata = camperList.data.filter(function (item) {
        return item.camper_type_id == 1;
      });
      var activerent = responsedata.filter(function (item) {
        return item.status == 1;
      })
      console.log(activerent, "respon")
      setCamper(activerent);
    } catch (error) {
      console.log(error);
    }
  };
  const getLocation = async () => {
    try {
      let locationList = await HomeService.location().json();
      // console.log(locationList.data);
      setLocation(locationList.data);
    } catch (error) {
      console.log(error);
    }
  };

  const question = [
    { value: "General Question", label: t("Contact.General Question") },
    { value: "Booking Request", label: t("Contact.Booking Request") },
    { value: "Existing Booking", label: t("Contact.Existing Booking") },
    { value: "Subscription", label: t("Contact.Subscription") },
    { value: "Camper Sale", label: t("Contact.Camper Sale") },
  ];
  const [inputBox, setInputBox] = useState(false);
  function onChange(value) {
    if (value.value == "Existing Booking") {
      setInputBox(true);
    } else {
      setInputBox(false);
    }
  }

  const onSubmitForm = async (event) => {
    event.preventDefault();
    setError("");
    try {

      var userData = {
        firstName: event.target.firstName.value,
        camper_id: event.target.camper_id.value,
        email: event.target.email.value,
        phone: event.target.phone.value,
        reasonRequest: event.target.reasonRequest.value,

        "bookingNumber": inputBox
          ? event.target.bookingNumber.value
            ? event.target.bookingNumber.value
            : ""
          : "",
        message: event.target.message.value,
        type: "Contact",
        "lng": i18n.resolvedLanguage
      };
      if (event.target.firstName.value === "") {
        setError(t("Login.The first name field is required."))
      }
      else if (event.target.email.value === "") {
        setError(t("Login.The email field is required."))
      }
      else if (event.target.phone.value === "") {
        setError(t("Login.The phone field is required."))
      }
      else {
      let responseData = await ContactService.store(userData).json();
      if (responseData.status === true) {
        // console.log(responseData)
        // alert("Form Submitted Successfully!!");
        event.target.reset();
        // navigate("/contactus");
        setisFormSubmit(true);
      }
    }
    } catch (error) {
      console.log(error);
      if (error.name === "HTTPError") {
        const errorJson = await error.response.json();
        setError(
          errorJson.message.substr(0, errorJson.message.lastIndexOf("."))
        );
      }
    }
  };
  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: ".slider-nav",
  };

  const settingsThumbs = {
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: ".slider-for",
    dots: true,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: "10px",
    arrows: true,
    responsive: [
      {
        breakpoint: 1273,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  var camperslider = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1273,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
        },
      },
    ],
  };

  return (
    <>
      <Header />

      <div className="min-h-screen bg-off-white">
        <div className="text-center">
          <div className="overflow-hidden">
            <div className="-ml-[5%] w-[110%] overflow-hidden rounded-b-[60%20%] px-[5%]">
              <div className="relative">
                <div className="overflow-hidden">
                  <img
                    src="images/Helpcenter-banner.png"
                    alt="Helpcenter-banner"
                    className="absolute max-h-full w-full scale-110 object-cover blur-lg dark:brightness-90 sm:aspect-hero-regular 3xl:aspect-hero-long aspect-square"
                  />
                </div>
                <picture className="flex max-h-[60vh] w-full text-0">
                  <img
                    className="z-0 max-h-full w-full object-cover dark:brightness-90 sm:aspect-hero-regular 3xl:aspect-hero-long aspect-square"
                    src="images/Helpcenter-banner.png"
                    alt="Helpcenter-banner"
                    width="2552"
                    height="1286"
                  />
                </picture>
                <div className="absolute top-0 left-0 flex h-full w-full flex-col items-center justify-end">
                  <div className="pb-12 w-full bg-gradient-to-t from-black-pure/80 to-transparent pt-20 md:pt-14 xl:pt-32">
                    <h1 className="whitespace-pre-line break-words m-auto max-w-4xl px-8 text-xl md:text-2xl text-white drop-shadow-text dark:text-off-white xs:text-2xl xl:text-3xl mb-5 md:mb-8 xl:mb-12">
                      {t("Contact.Helpcenter")}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark relative mx-auto -mt-12 child:h-24 child:w-24 2xl:-mt-14 2xl:child:h-28 2xl:child:w-28">
            <img
              className=""
              src="images/contact-banner-svg.svg"
              alt="contact-banner-svg"
            />
          </div>
        </div>

        <div className="mx-5 max-w-screen-xl xl-padded:mx-auto knowledge-Hub-wrapper">
          <div className="pt-4 md:pt-8 pb-0 mx-auto flex max-w-3xl flex-col items-center text-center">
            <h2 className="whitespace-pre-line break-words text-xl md:text-2xl mb-4">
              {t("Contact.Knowledge Hub")}
            </h2>
            <p className="whitespace-pre-line text-2md font-normal md:text-lg-paragraph md:font-normal md:mb-0 mb-5 text-20">
              {t("Contact.Your Questions, Our Answers")}: {t("Contact.Discover everything about camper rental, subscription, sales, and trip planning.")}
            </p>
          </div>

          <div className="2xl:pt-8 pb-8 2xl:pb-12">
            <div className="mx-auto border-b border-b-grey last:border-b-0 dark:border-b-grey-dark lg:max-w-4xl contact-accordian">
              <div>


                <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark tab-title">
                  <summary className="flex cursor-pointer items-center justify-between py-4 md:py-8">
                    <h2 className="whitespace-pre-line break-words text-lg 2xl:text-xl text-xl-custom">
                      {t("Contact.Camper Rent General Info")}
                    </h2>
                    <div className="find-help-block-svg inline-block align-top pl-4 child:h-12 child:w-12 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-12 md:child:w-12 2xl:child:h-12 2xl:child:w-12">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g fill="none" fillRule="evenodd">
                          <path
                            d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                            fill="var(--svg-color-secondary, #163435)"
                          ></path>
                        </g>
                      </svg>
                    </div>
                  </summary>
                  <div className="mb-8">
                    <div>

                      <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg mx-auto p-0 max-w-3xl legal-information legal-information details">

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Are there long-term discounts available?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Yes, we offer long-term discounts for those traveling with their campers for extended periods. These discounts are automatically applied to the rental price when you make your booking and can be combined with other discount codes.")}<br />{t("Contact.Here's the breakdown")}:
                                </div>
                                {/* <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.We take the protection of your personal data very seriously, treating it confidentially and in accordance with legal data protection regulations and this privacy policy. Please be aware that data transmission over the Internet(e.g., via email communication) may have security vulnerabilities. A complete protection of data from access by third parties is not possible")}.
                                </div> */}
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  <ul className="custom-ul">
                                    <li>{t("Contact.For rentals lasting 8 days or more, you'll receive a 5% discount")}.</li>
                                    <li>{t("Contact.For rentals lasting 15 days or more, you'll receive a 10% discount")}.</li>
                                    <li>{t("Contact.For rentals lasting 22 days or more, you'll receive a 15% discount")}.</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Who is eligible to rent a camper?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Any person who is at least 21 years old and has held a Class B driver's license continuously for at least one year meets the requirements. International driver's licenses are accepted provided they are recognized as valid by Germany and the countries being visited")}.
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Can I bring my dog with me?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Yes, you can bring your four-legged friend along on the journey. Simply select the")} "{t("Contact.Dog Camper")}"{t("Contact.option when booking. Please note that an additional fee for professional deep cleaning applies. This fee excludes deep cleaning of upholstery and mattresses. The camper must still be returned cleaned. If you bring a dog without selecting the")} "{t("Contact.Dog Camper1")}" {t("Contact.option, we will charge an increased fee")}.
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Can I visit the campers before renting?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Absolutely, you're welcome to visit our location and inspect the campers. To ensure availability and allocate sufficient time from our team, it's crucial to schedule an appointment in advance. If you wish to arrange a visit, please send us a message through our contact form, specifying the location and your desired camper model")}.
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.How to find suitable campsites?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.The apps Alpaca Camping, Park4Night, and Roadsurfer Spots are ideal companions for searching for campsites. With these apps, you can filter your search by the type of campsite. For example, do you want to find only natural pitches or perhaps accommodation next to a secluded farm in nature? Many campsites can be contacted directly through the apps")}.
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.How do you empty the toilet?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.In our camper tutorial videos, we show how to empty the toilet. You can find the videos, for example, on the camper rental page directly after the models.")}
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.How far in advance should I book a camper?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.It is recommended to book as early as possible, especially during peak season. Popular dates can fill up quickly")}.
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Can you provide me with my license plate number?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Unfortunately, due to logistical reasons, we cannot provide the license plate number before the start of your trip. This is not reliably possible due to the size and dynamics of our vehicle fleet")}.
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.If you need to book a ferry or a campsite, it is usually sufficient to indicate")} "{t("Contact.Rental Car")}" {t("Contact.and provide the license plate number later. This approach has proven to be effective in practice")}.
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("Contact.Many vignettes can be easily ordered online within a few minutes using your mobile phone, without the need for a physical vignette. Additionally, if needed, you can also purchase vignettes at various gas stations and rest areas1")}.</div>
                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Are bicycle carriers on the ferry an issue?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Generally not. The ferry just needs to be booked for the vehicle length plus the length of the bicycle carrier")}.
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Do your campers have a trailer hitch?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.All our campers have a trailer hitch except for the Deluxe Camper. Please always check the Camper description page for your Model")}.
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Can I smoke in the vehicle?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.No, smoking inside the camper is strictly prohibited. Failure to comply with this policy will result in a special cleaning fee of €500")}.
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Environmental sticker in France")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Foreign vehicles must display an environmental sticker called")} "{t("Contact.Crit'Air Vignette")}" {t("Contact.when driving in France. If you plan to travel through France during your trip, there are a few things you should know")}:
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.In France, there are two types of environmental zones")}:
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  "ZPA" (zones à protection de l'air) {t("Contact.are temporary zones where driving restrictions in city centers or urban areas can be imposed during periods of high air pollution")}.
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  "ZCR" (zones à circulation restreinte) {t("Contact.are permanent environmental zones with traffic restrictions. To drive in these zones, your vehicle needs to have a")} 'Crit'Air'{t("Contact.sticker. Stickers are mandatory (since April 2019) in Paris, Grenoble (including highways), Lille, Lyon (except A6, A7, A42), Annecy, and Strasbourg")}.&nbsp; {t("Contact.When traveling to France, it is your responsibility to obtain the sticker. You can easily do this online. Unfortunately, due to logistical reasons, we cannot provide the registration number for this purpose before the start of your trip. This is not reliably possible due to the size and dynamics of our fleet")}.
                                </div>

                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.After picking up your camper, you can apply for the necessary sticker for your motorhome on the official website of the French Ministry of Environment for a processing fee of 4.21 euros. The confirmation of your order on your mobile phone is sufficient in case of any checks. This approach has proven to be effective in practice. A few hours after the registration, you should receive a confirmation of your order to the email address you used for the registration. Keep this as an official proof of your registration")}.
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.If you have further questions, you can find all the information at")} <a className="underline" target="_blank" href="https://www.certificat- air.gouv.fr/">https://www.certificat- air.gouv.fr/</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.How can I pay toll fees in Sweden and Norway?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.For toll fees in Sweden and Norway, you can use the Epass24 platform. You have the option to register a credit card or choose the invoice payment method. We recommend the invoice option to ensure that you are only charged for expenses related to your actual travel and not for other vehicle-related costs")}.
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Here's how it works")}:
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Create an account on the Epass24 platform, selecting the")} "{t("Contact.Rental")}" {t("Contact.or")} "{t("Contact.Hire vehicle")}"{t("Contact.option during registration to specify your travel period. It's advisable not to provide credit card information in your account; you can skip that request. After your trip, you'll receive an invoice in your account, which can be settled via bank transfer. Don't forget to remove the vehicle from your account after your trip to avoid additional charges")}.
                                </div>



                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.How is the number of booking days calculated?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Our standard booking times are from 2 PM for Pick Up to 11 AM for Return. But if you need different times, just let us know. Returning before 11 AM won't cost extra.However, returning later than 11 AM will incur an additional charge equal to half a day's booking. The same applies in reverse for Pick Up")}.
                                </div>
                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.How many drivers are included?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.The number of drivers included is unlimited. To add additional drivers, we kindly request that you present their original driver's licenses during the pickup process")}.
                                </div>
                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.How many free kilometers are included?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.There are two rental options. Firstly, there is the 'regular' rental, which lasts for up to 2 months. In this case, you have an average of 250 free kilometers per night available. Additional kilometers are available for €0.3 per extra kilometer")}.
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Secondly, we offer a subscription rental, ideal for bookings exceeding 2 months. With the subscription, 2,000 kilometers per month are already included, and you can add an extra 1,000 kilometers per month if needed")}.
                                </div>
                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Do you provide specialized vehicles for allergies?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.While we do not have specific vehicles designated for allergic individuals, we prioritize thorough cleaning of all our campers after each rental")}.
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.What is the International Insurance Card for Motor Vehicles, and do I need it? (Green Insurance card)")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.The International Insurance Card for Motor Vehicles, formerly known as the Green Card, serves as proof of motor liability insurance coverage when traveling abroad. It is mandatory in only a few countries within our coverage area, such as Albania, Bosnia and Herzegovina, Moldova, and Serbia. You can locate it in the Info Folder inside the glove box of your camper")}.
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Is bedding already included?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Bedding is not included, but you can easily add")} "{t("Contact.bedsheets")}" {t("Contact.and our")} "{t("Contact.comfort blanket & pillow")}" {t("Contact.during the booking process to ensure you're all set")}.
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Are your campers affected by diesel driving bans?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Our vehicles are not subject to diesel driving bans. Nevertheless, certain countries, like France, may require additional environmental stickers. We recommend researching the country you plan to travel to and obtaining the necessary stickers as needed")}.
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.What type of driver's license is required?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Since all our Campers have a maximum permissible weight of 3,500 kg, you will need a Class B driver's license that you have held continuously for at least one year. The specific requirements for Caravans can be found on the detail pages of each model. International driver's licenses are accepted provided they are recognized as valid by Germany and the countries being visited")}.
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.What equipment is included in the rental?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.To review the included camping equipment for your specific model, please visit the detailed page of your camper and select the equipment list for your model")}.
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.The basic equipment provided in every camper includes")}:
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  <ul className="custom-ul">
                                    <li>{t("Contact.Cooking and kitchen utensils")}</li>
                                    <li>{t("Contact.1 camping table and 2 camping chairs")}</li>
                                    <li>{t("Contact.Broom and garbage bags")}</li>
                                    <li>{t("Contact.Extension cord with adapter")}</li>
                                    <li>{t("Contact.Hose for freshwater tank")}</li>
                                  </ul>
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.How are traffic violations handled?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.In the event of a traffic violation or offense, we receive a notification. After identifying the renter, we provide their contact information to the relevant authorities and forward the ticket to the person involved. To process a ticket (e.g., speeding, illegal parking, non-payment of tolls), we charge a processing fee of €19.00, which will be invoiced along with the ticket via email")}.
                                </div>


                              </div>
                            </div>
                          </div>
                        </details>


                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.What is the minimum age to rent a camper?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.The minimum age for renting a camper is 21 years old, and you must have held a valid Class B driver's license for at least one year without any interruptions. International driver's licenses are accepted provided they are recognized as valid by Germany and the countries being visited.")}
                                </div>


                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.What is the minimum rental duration for a camper?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Our minimum rental period varies depending on the booking period and season. In August, it is 5 nights, while in June and July, it is 3 nights. In all other months, it is only 2 nights. For weekend bookings in June and July, we also offer the option to return the camper on Sunday evening instead of Monday")}.

                                </div>


                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Where am I allowed to travel with the camper?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.You may use the rental vehicle exclusively in the following countries Albania, Andorra, Belgium, Bosnia and Herzegovina, Bulgaria, Germany, Denmark, Estonia, France, Finland, Greece, Great Britain, Italy, Ireland, Iceland, Croatia, Luxembourg, Liechtenstein, Lithuania, Latvia, Malta, Moldova, Monaco, Montenegro, Netherlands, North Macedonia, Norway, Austria, Portugal, Poland, Romania, San Marino, Sweden, Slovakia, Spain, Switzerland, Serbia, Slovenia, Czech Republic, Hungary, Vatican City, and Cyprus")}.

                                </div>


                              </div>
                            </div>
                          </div>
                        </details>

                      </div>



                    </div>
                  </div>
                </details>

                <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark tab-title">
                  <summary className="flex cursor-pointer items-center justify-between py-4 md:py-8">
                    <h2 className="whitespace-pre-line break-words text-lg 2xl:text-xl text-xl-custom">
                      {t("Contact.Camper Rent Booking, Modifying Orders, and Cancellations")}
                    </h2>
                    <div className="find-help-block-svg inline-block align-top pl-4 child:h-12 child:w-12 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-12 md:child:w-12 2xl:child:h-12 2xl:child:w-12">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g fill="none" fillRule="evenodd">
                          <path
                            d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                            fill="var(--svg-color-secondary, #163435)"
                          ></path>
                        </g>
                      </svg>
                    </div>
                  </summary>
                  <div className="mb-8">
                    <div>

                      <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg mx-auto p-0 max-w-3xl legal-information legal-information details">

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Can I add equipment to my reservation later?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.If the equipment you want is available for your travel dates, you can add it to your existing booking using your portal login")}.
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.What are the cancellation terms?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.We offer two cancellation options that you can freely choose from when booking. No changes to the cancellation option are possible within 60 days before the start of the trip")}.
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  <h3 class="text-sm 2xl:text-md">{t("Contact.Standard Option (included)")}:</h3>

                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  <ul className="custom-ul">
                                    <li>{t("Contact.With our Standard Option, you will receive a full refund of the deposit amount up to 60 days before the start of the trip")}.</li>
                                    <li>{t("Contact.Between 59 and 14 days before the start of the trip, the cancellation fee is 50% of the total rental price, while the remaining 50% is issued as a voucher")}.</li>
                                    <li>{t("Contact.For cancellations within 14 days, 100% of the costs will be retained")}.</li>
                                  </ul>
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  <h3 class="text-sm 2xl:text-md">{t("Contact.Flex Option")}:</h3>
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  <ul className="custom-ul">
                                    <li>{t("Contact.With our Flex Option, you will receive a full refund of the deposit amount up to 60 days before the start of the trip")}.</li>
                                    <li>{t("Contact.Between 59 and 14 days before the start of the trip, 100% of the costs will be issued as a voucher that can be redeemed on the next booking")}.</li>
                                    <li>{t("Contact.For cancellations within 14 days, the cancellation fee is 50% of the total rental price, while the remaining 50% is issued as a voucher")}.</li>
                                  </ul>
                                  <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                  <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("Contact.The costs for the Flex Option will not be refunded for cancellations made within 60 days")}.</div>
                                </div>


                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Is it possible to cancel equipment or services already reserved?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Regrettably, we cannot issue refunds for equipment or services that have already been reserved, such as bike racks, shuttle services, or interior cleaning")}.
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Can I change my pick-up and return time?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.You can adjust your pickup and return times if they are still available for your travel period. Contact us directly to make changes")}.
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Can I extend my reservation?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Yes, in general, it is possible to extend your booking if the vehicle is still available for the requested period at the time of your inquiry. Contact us directly to make changes")}.
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Can I make changes to my booking afterwards?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Changes to your booking are generally possible, depending on availability")}.
                                </div>
                                {
                                  i18n.resolvedLanguage === 'en' ?
                                    <>
                                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                      </div>
                                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                        {t("Contact.Cancellations or partial cancellations are governed by the terms and conditions outlined in our GTC")}.
                                      </div>
                                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                      </div>
                                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                        {t("Contact.If you have any change requests, please send us a message with your booking number and the requested changes via our contact form")}.
                                      </div>
                                    </>
                                    : ''
                                }



                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.How can I use a coupon/discount code?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Once you have entered your contact details for your reservation and proceeded to payment, you can enter the voucher code under the total amount in the payment methods section")}.
                                </div>
                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.What should I do if I can't find my booking confirmation?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.No worries, we can resend your booking confirmation. Please send us an email with your booking number and the main renter's name via our contact form, and we'll be happy to assist you by resending the confirmation")}.
                                </div>
                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.My Camper is fully booked, is there a waiting list?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.We do not maintain waiting lists for fully booked campers. Feel free to give us a call, and we may be able to find a suitable model for you during a viewing. Alternatively, we recommend checking the availability on our website regularly. Cancellations happen from time to time, making a camper available again")}.
                                </div>
                              </div>
                            </div>
                          </div>
                        </details>

                      </div>



                    </div>
                  </div>
                </details>

                <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark tab-title">
                  <summary className="flex cursor-pointer items-center justify-between py-4 md:py-8">
                    <h2 className="whitespace-pre-line break-words text-lg 2xl:text-xl text-xl-custom">
                      {t("Contact.Camper Rent Payment and deposit")}
                    </h2>
                    <div className="find-help-block-svg inline-block align-top pl-4 child:h-12 child:w-12 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-12 md:child:w-12 2xl:child:h-12 2xl:child:w-12">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g fill="none" fillRule="evenodd">
                          <path
                            d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                            fill="var(--svg-color-secondary, #163435)"
                          ></path>
                        </g>
                      </svg>
                    </div>
                  </summary>
                  <div className="mb-8">
                    <div>

                      <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg mx-auto p-0 max-w-3xl legal-information legal-information details">

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Can I divide the payment into a deposit and final payment?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.For bookings over €2,000, you can choose a 50% down payment option, with the remaining 50% due 7 days before pickup via wire transfer. To split your payment this way, please contact us directly through our contact form")}.
                                </div>

                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("Contact.If you include the following information in your message, the process will be even faster")}:</div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  <ul className="custom-ul">
                                    <li>{t("Contact.The exact travel date, including pickup and return times")}</li>
                                    <li>{t("Contact.Your desired camper and additional accessories")}</li>
                                    <li>{t("Contact.Your preferred insurance package")}</li>
                                    <li>{t("Contact.Your contact details (name, first name, date of birth, address, and phone number)")}</li>
                                    <li>{t("Contact.If available voucher code")}</li>
                                  </ul>
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("Contact.Also, in order for us to definitively create the booking, you must confirm our terms and conditions. Therefore, you can also write directly in the email")}: "{t("Contact.I have read and agree to the terms and conditions.")}"</div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("Contact.We will then take care of everything for you and send you the invoice and booking confirmation by email.")}</div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Is a deposit required, and how much is it?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Yes, a deposit is mandatory, and the amount depends on your chosen insurance package. The Basic package requires a €1,500 deposit, the Advanced package is €900, and the Pro package is €400")}.
                                </div>
                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.How can I pay the deposit?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Please transfer the security deposit to our account at least 4 days before pickup. You can find the account details in the booking portal under the PDF \"Handover\". The deposit will be released within a week after the return, provided there are no damages to the camper.")}
                                </div>
                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Payment Options")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.You can make online bookings via instant bank transfer or cash payment. If you choose the cash payment option, you must arrange a date with us within 4 days to make the payment. During this appointment, you can also inspect your camper, provided that the desired model is available. If you encounter any issues with the payment, we can issue an invoice for a bank transfer. For any assistance, you can contact us through the contact form")}.
                                </div>
                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Outstanding Balances")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Settle outstanding balances at pickup using cash, a debit or credit card")}.
                                </div>
                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Security Deposit Refund")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Your security deposit will be refunded within one week after return if there are no damages")}.
                                </div>
                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Why a Deposit?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.The deposit safeguards the camper and covers damages up to the deductible amount. It also helps with costs for unfilled fuel tanks or damages not covered by insurance, like roof or awning damage or improper operation")}.
                                </div>
                              </div>
                            </div>
                          </div>
                        </details>

                      </div>



                    </div>
                  </div>
                </details>

                <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark tab-title">
                  <summary className="flex cursor-pointer items-center justify-between py-4 md:py-8">
                    <h2 className="whitespace-pre-line break-words text-lg 2xl:text-xl text-xl-custom">
                      {t("Contact.Camper Rent Pickup and Return")}
                    </h2>
                    <div className="find-help-block-svg inline-block align-top pl-4 child:h-12 child:w-12 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-12 md:child:w-12 2xl:child:h-12 2xl:child:w-12">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g fill="none" fillRule="evenodd">
                          <path
                            d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                            fill="var(--svg-color-secondary, #163435)"
                          ></path>
                        </g>
                      </svg>
                    </div>
                  </summary>
                  <div className="mb-8">
                    <div>

                      <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg mx-auto p-0 max-w-3xl legal-information legal-information details">

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.At which address can I pick up my camper?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Our camper pickup location is at Fidelhof 3, 93128, Regenstauf. The nearest public train station is Bahnhof Regenstauf, approximately 2.5km away. We suggest bringing a bicycle on the train as it's a 30-minute walk")}.
                                </div>
                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Can I park my own car at your rental station?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Yes, you can park your vehicle at our location for a small fee. Add the 'Parking Spot' during booking")}.
                                </div>
                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Is pickup and return available on Sundays and public holidays?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              {
                                i18n.resolvedLanguage === 'en' ?
                                  <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                    <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                      {t("Contact.Yes, we are open on Sundays and public holidays")}.
                                    </div>
                                    <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                      {t("Contact.Choose any available day on the booking calendar")}.
                                    </div>
                                  </div>
                                  :
                                  <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                    <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                      {t("Contact.Yes, we are open on Sundays and public holidays")}.&nbsp;{t("Contact.Choose any available day on the booking calendar")}.
                                    </div>

                                  </div>
                              }

                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Can I return my camper earlier?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.With prior arrangement, it is generally possible to return the camper earlier. There will be no refund for shortening the rental period. Call us at +49 (0) 163 2937354 if you would like to return the camper earlier")}.
                                </div>
                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Do I have to personally pick up my camper?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Yes, the primary renter must be present. Otherwise, transfer the booking to the pickup person, who becomes the primary renter and insurance holder")}.
                                </div>
                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Do you offer a shuttle service from Bahnhof Regenstauf?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Our shuttle service is available during the Booking process and includes round-trip transportation to and from Bahnhof Regenstauf train station. Simply choose the 'Shuttle service' add-on during the booking process")}.
                                </div>
                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.How can I prepare for the pick-up?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {i18n.resolvedLanguage === "en" ?
                                    <>Plan your arrival to our address: Fidelhof 3, 93128, Regenstauf. Watch our camper tutorial videos beforehand. Bring <b>original identification, driver's licenses, and make sure you wire transfer the deposit at least 4 days prior to Pick-up.</b> You can find the account details in the booking portal under the PDF "Pick-Up".</>
                                    :
                                    <>Plane deine <b>pünktliche</b> Ankunft an unserer Abholstation: Fidelhof 3, 93128, Regenstauf. Schau dir vorab unsere <b>Tutorial-Videos</b> an, bring deinen <b> Personalausweis und alle Führerscheine</b> mit. Stelle sicher, dass du die <b>Kaution mindestens 4 Tage vor Abholung</b> überweist. Die Kontodetails findest du im Buchungsportal unter dem PDF "Übergabe".</>

                                  }
                                  {/* {t("Contact.Plan your arrival to our address Fidelhof 3, 93128, Regenstauf. Watch our camper tutorial videos beforehand.")} <b>{t("Contact.Bring original identification, driver's licenses, and make sure you wire transfer the deposit at least 4 days prior to Pick-up.")}</b>&nbsp;{t("Contact.You can find the account details in the booking portal under the PDF Pick-Up")} */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.How does the pickup process work?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Firstly, the check-in process takes place, during which we collect your documents and those of any additional drivers. If there are any outstanding payments, they will be settled at this time. Following that, there will be a briefing (approximately 30 minutes in duration) where we will explain everything about the camper and its functionalities to you. Then your adventure can begin!")}
                                </div>
                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.What days & times are available for pickup and return?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.You can pick up and return your camper any day, Monday to Sunday. High season (April to October) hours are 9 am to 5 pm, low season (October to March) is 9 am to 4 pm.")}
                                </div>
                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Is returning outside opening hours possible?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Returning outside opening hours requires prior arrangement and incurs a 40€ fee")}.
                                </div>
                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.What to consider when returning the camper?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Clean the interior (unless you have booked an interior cleaning service)")}.
                                </div>
                                {i18n.resolvedLanguage === 'en' ?
                                  <>
                                    <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                    <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("Contact.Empty wastewater tanks, as well as the toilet cassette. Please use designated disposal stations for this purpose. You can find nearby disposal stations online")}.</div>
                                    <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                    <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("Contact.Return the vehicle with a full tank of fuel and Ad-Blue. (Available at Gas Station in Regenstauf)")}</div>
                                    <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                    <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("Contact.The return process usually takes around 25 minutes")}.</div>
                                  </> : ""}


                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.What do I need to bring for pickup?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {/* {t("Contact.Original identification document (such as a personal ID card or passport) of the main renter")}. */}
                                  {t("Contact.Original")} <b>{t("Contact.identification document")}</b> {t("Contact.(such as a personal ID card or passport) of the main renter")}.
                                  <br />
                                  {t("Contact.Original1")} <b>{t("Contact.driver's licenses of all drivers.")}</b> {t("Contact.Please make sure you")} <b>{t("Contact.wire transfer the deposit at least 4 days prior to Pick-up.")}</b> {t("Contact.You can find the account details in the booking portal under the PDF Pick-Up")}
                                </div>
                                {/* {i18n.resolvedLanguage === 'en' ?
                                  <>
                                    <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                    <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("Contact.Original driver's licenses of all drivers")}.</div>
                                    <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                    <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("Contact.Cash, Credit or debit card of the main renter for the security deposit and potential additional payments")}.</div>
                                  </>
                                  : ''
                                } */}

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.What are the consequences of returning the camper late?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.If you can't make the agreed-upon return time, please notify us promptly via phone or email as soon as you realize you won’t be on time. If you can't return within our operating hours, rescheduling to the next day may be necessary, and there may be a €50 per hour delay charge")}.
                                </div>
                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.What is the fuel policy?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.You are responsible for refueling the camper. It's provided with a full fuel and Ad-Blue tank and should be returned the same way. Failing to do so will result in a €19 refueling fee in addition to fuel costs")}.
                                </div>
                              </div>
                            </div>
                          </div>
                        </details>


                      </div>



                    </div>
                  </div>
                </details>

                <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark tab-title">
                  <summary className="flex cursor-pointer items-center justify-between py-4 md:py-8">
                    <h2 className="whitespace-pre-line break-words text-lg 2xl:text-xl text-xl-custom">
                      {t("Contact.Camper Rent Damage and insurance")}
                    </h2>
                    <div className="find-help-block-svg inline-block align-top pl-4 child:h-12 child:w-12 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-12 md:child:w-12 2xl:child:h-12 2xl:child:w-12">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g fill="none" fillRule="evenodd">
                          <path
                            d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                            fill="var(--svg-color-secondary, #163435)"
                          ></path>
                        </g>
                      </svg>
                    </div>
                  </summary>
                  <div className="mb-8">
                    <div>

                      <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg mx-auto p-0 max-w-3xl legal-information legal-information details">

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Can I change my insurance package?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.You can upgrade your insurance package before pickup or on-site. Note that refunds or downgrades after booking higher-value packages are generally not possible")}.
                                </div>
                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.How am I insured during my camping trip?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.You are always fully comprehensive insured with us. We offer different levels Basic (deductible €1500), Advanced (deductible €900), and Pro (deductible €400)")}.
                                </div>
                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.I have technical questions or issues with the camper")}.
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">

                                  {t("Contact.For technical issues or questions regarding the operation of the camper, our instructional videos can provide assistance")}.
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Additionally, you can find many solutions to common problems in our Help section under")} "{t("Contact.Information and Help for On the Road")}".
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("Contact.If you can't find the right solution there, please feel free to contact us. You can reach our on the road support at")} <a className="underline" href="tel:'+49 (0) 163 2937354'">+49 (0) 163 2937354</a>&nbsp;{t("Contact.or use our contact form")}.</div>
                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.What occurs in the event of damage or an accident?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.In case of a breakdown or accident, please contact our Customer Support through email, phone, or our contact form")}.
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.If your camper becomes unfit for the road, we will direct you to the mobility guarantee insurance hotline, which covers Western Europe and an increasing number of Eastern European countries")}.
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.How do the insurance packages differ?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Insurance packages vary based on maximum deductible, and deposit amount")}.
                                </div>


                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.What is not covered by the insurance?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.The insurance excludes")}:
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  <ul className="custom-ul">
                                    <li>{t("Contact.Self-inflicted damages like vandalism, violence, or misuse (e.g., awning or roof damage)")}.</li>
                                    <li>{t("Contact.Malfunctions due to refueling errors")}.</li>
                                    <li>{t("Contact.Damages from improper use (e.g., off-road driving)")}.</li>
                                    <li>{t("Contact.Damages from improperly secured cargo")}.</li>
                                    <li>{t("Contact.Unauthorized driver or prohibited use. The renter is responsible for repair costs")}.</li>
                                  </ul>
                                </div>


                              </div>
                            </div>
                          </div>
                        </details>



                      </div>



                    </div>
                  </div>
                </details>

                <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark tab-title">
                  <summary className="flex cursor-pointer items-center justify-between py-4 md:py-8">
                    <h2 className="whitespace-pre-line break-words text-lg 2xl:text-xl text-xl-custom">
                      {t("Contact.General tips for camping trips")}
                    </h2>
                    <div className="find-help-block-svg inline-block align-top pl-4 child:h-12 child:w-12 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-12 md:child:w-12 2xl:child:h-12 2xl:child:w-12">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g fill="none" fillRule="evenodd">
                          <path
                            d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                            fill="var(--svg-color-secondary, #163435)"
                          ></path>
                        </g>
                      </svg>
                    </div>
                  </summary>
                  <div className="mb-8">
                    <div>

                      <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg mx-auto p-0 max-w-3xl legal-information legal-information details">

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Is there a toll or vignette requirement in my destination country?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Several EU countries have tolls, like France, Greece, UK, Ireland, Italy, Croatia, Poland, Portugal, and Spain. Refer to the ADAC Website for details")}.
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Please understand that our customer service cannot provide legally binding and up-to-date information about tolls and vignettes in all travel destinations. Therefore, it is important for you to independently research and familiarize yourself with the specific requirements of your destination country before your trip")}.
                                </div>
                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.I would like to book a ferry – what should I consider?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.When booking a ferry, such as for example to Sardinia, Corsica, or Norway, you can find the required length for your chosen model's ferry booking under")} "{t("Contact.Technical Details")}" {t("Contact.on our website, specifically on the respective model page")}.
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.For the vehicle registration, please always enter")} "{t("Contact.Rental Car1")}" {i18n.resolvedLanguage === 'de' ? 'an' : ''}.&nbsp;{t("Contact.Due to the size and dynamics of our fleet, we can provide you with the specific registration number only upon pickup. The ferry providers are familiar with and accept this procedure")}.
                                </div>
                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.What do I need to consider as a Swiss citizen?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.As a Swiss citizen planning to rent a camper from us, there are a few things to consider")}:
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Duration of stay")}: {t("Contact.Since May 1st, 2020, there is a regulation in Switzerland that allows you to drive our camper into the country, but you are only allowed to stay for a maximum of eight consecutive days")}.
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Registration at a border customs station")}: {t("Contact.Additionally, you are required to register the vehicle at a border customs station")}.
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Please note that it is important to familiarize yourself with the specific regulations and requirements of the Swiss authorities regarding camper rentals and cross-border travel")}.
                                </div>
                              </div>
                            </div>
                          </div>
                        </details>

                      </div>



                    </div>
                  </div>
                </details>

                <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark tab-title">
                  <summary className="flex cursor-pointer items-center justify-between py-4 md:py-8">
                    <h2 className="whitespace-pre-line break-words text-lg 2xl:text-xl text-xl-custom">
                      {t("Contact.Our camper vans and equipment")}
                    </h2>
                    <div className="find-help-block-svg inline-block align-top pl-4 child:h-12 child:w-12 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-12 md:child:w-12 2xl:child:h-12 2xl:child:w-12">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g fill="none" fillRule="evenodd">
                          <path
                            d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                            fill="var(--svg-color-secondary, #163435)"
                          ></path>
                        </g>
                      </svg>
                    </div>
                  </summary>
                  <div className="mb-8">
                    <div>

                      <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg mx-auto p-0 max-w-3xl legal-information legal-information details">

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Can I see the campers before renting them?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.You're welcome to visit our location and view the campers, but please schedule an appointment in advance to ensure availability. To arrange a visit, kindly message us via our contact form with your desired model. For spontaneous visits give is a call at")} <a className='underline' href="tel:'+49 (0) 163 2937354'">+49 (0) 163 2937354</a>&nbsp;{i18n.resolvedLanguage === 'de' ? 'an' : ''}.
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Can I get the license plate number in advance?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Unfortunately, due to logistical reasons, we cannot provide the license plate number before the start of your trip. This is not reliably possible due to the size and dynamics of our vehicle fleet1")}.
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.If you need to book a ferry or a campsite, it is usually sufficient to indicate")} "{t("Contact.Rental Car1")}" {t("Contact.and provide the license plate number later. This approach has proven to be effective in practice1")}.
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("Contact.Many vignettes can be easily ordered online within a few minutes using your mobile phone, without the need for a physical vignette. Additionally, if needed, you can also purchase vignettes at various gas stations and rest areas")}.</div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Do your campers have trailer hitches?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.All our campers have a trailer hitch except for the Deluxe Camper. Please always check the Camper description page for your Model")}.
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Can I use my own bike rack?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.You can use your bike rack at your own risk if it's suitable for the camper model. We can provide an extra license plate for your bike rack")}.
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>
                        {/* //question 5 */}
                        {/* <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Can I receive the registration for my camper in advance for the French environmental sticker?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Our campers do not come equipped with a French environmental sticker as standard. You can only obtain this once you pick up the camper and receive the vehicle registration document from us with all the relevant details")}.
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("Contact.Due to logistical reasons, we cannot provide you with the data earlier. There is a possibility of last-minute damages during pre-rental inspections and vehicle reassignments. In such a case, you might need to reapply for the sticker and pay for it again, which we and you would naturally want to avoid")}.</div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("Contact.You can apply for the sticker online within a few minutes, and having the confirmation on your phone is sufficient for any potential checks")}.</div>

                              </div>
                            </div>
                          </div>
                        </details> */}

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Is there an existing Austria or Switzerland toll vignette for my camper?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.Our campers do not come with vignettes for tolls in other European countries as a standard feature, nor are they equipped with a toll box. Therefore, we kindly ask you to take care of this yourself and gather information about it before your trip")}.
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("Contact.In some cases, the vignette may have already been purchased by the previous renters, and if that's the case, we will leave it on the vehicle. Please understand that we cannot confirm this definitively before your trip. Most vignettes can be purchased online within a few minutes after picking up your rental or at service areas and gas stations")}.</div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.What can I do if my gas runs out during my trip?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.If your gas cylinder runs out during your trip")}:
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("Contact.In Germany, you can easily exchange or refill it at gas stations, hardware stores, garden centers, or traditional gas suppliers")}.</div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("Contact.When traveling in other European countries, different connection systems and gas cylinders may be encountered. Please check the relevant information in advance, as you may need an adapter. We recommend getting one before your trip, which can be purchased online or in camping accessory shops. We do not reimburse the cost of such adapters")}.</div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("Contact.Note that both propane and butane gas can be used in our campers, with the main difference being their ignition points and chemical composition. Propane is more common in northern European countries, while butane is found in southern, warmer countries")}.</div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.What do I need to consider when using the camping toilet?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.In our campervans, travel vans, and motorhomes, there is an integrated camping toilet. Follow these steps")}:
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  <ul className="custom-ul">
                                    <li>{t("Contact.Ensure the freshwater tank is filled")}.</li>
                                    <li>{t("Contact.Slide the gray lever below the toilet to the right to open the cassette")}.</li>
                                    <li>{t("Contact.Flush after each use")}.</li>
                                    <li>{t("Contact.Regularly empty the cassette (usually every 3 to 5 days, indicated by a red indicator)")}.</li>
                                    <li>{t("Contact.Add toilet chemical before the first use and after emptying the cassette")}.</li>
                                    <li>{t("Contact.Only dispose of toilet paper in the toilet to prevent blockages")}.</li>
                                    <li>{t("Contact.Empty the cassette at designated areas only")}.</li>
                                  </ul>
                                </div>


                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.What additional equipment can I rent?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.We offer various add-on equipment, including hammocks and bike racks. View our equipment options here")}: <Link class="underline color-blue" to="/equipment">{t("Contact.Equipment")}</Link>.
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                      </div>



                    </div>
                  </div>
                </details>

                <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark tab-title">
                  <summary className="flex cursor-pointer items-center justify-between py-4 md:py-8">
                    <h2 className="whitespace-pre-line break-words text-lg 2xl:text-xl text-xl-custom">
                      {t("Contact.Camper Subscription")}
                    </h2>
                    <div className="find-help-block-svg inline-block align-top pl-4 child:h-12 child:w-12 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-12 md:child:w-12 2xl:child:h-12 2xl:child:w-12">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g fill="none" fillRule="evenodd">
                          <path
                            d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                            fill="var(--svg-color-secondary, #163435)"
                          ></path>
                        </g>
                      </svg>
                    </div>
                  </summary>
                  <div className="mb-8">
                    <div>

                      <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg mx-auto p-0 max-w-3xl legal-information legal-information details">

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("subscription.What is the Camper Subscription exactly?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("subscription.The Camper Subscription combines the benefits of camper rental with those of a long-term lease. It offers maximum flexibility with a short minimum term of just two months. Included are services such as comprehensive insurance, vehicle tax, and assumption of maintenance costs. In short, the Camper Subscription allows you to enjoy a camper without the usual hassle associated with vehicle ownership while avoiding a long-term commitment of 24 to 36 months to a contract")}.
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("subscription.How does the payment work?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("subscription.You pay the contributions every month. In other words, the first installment is due as soon as the contract is finalized. Then it starts in the second month of the term and so on – every month at the beginning")}.
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("subscription.Who is allowed to drive the vehicle?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("subscription.We list the main person renting the camper and anyone else who wants to drive it in the rental agreement. At the key handover, you must show your original driver's licenses – unfortunately, copies do not count")}
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("subscription.Who pays for fuel, oil, and AdBlue?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("subscription.We deliver the car fully fueled – diesel and AdBlue included – and would like to receive it back the same way. If oil or other fluids run low on the road, please top them up at your own expense. This also applies if you need to refill gas")}.
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("subscription.What is the minimum term?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("subscription.The minimum term is 2 months.")}
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("subscription.What is the maximum term?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("subscription.The maximum term is 12 months. And if you want more, we simply start with a new contract after 12 months")}.
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("subscription.Who can use the Camper Subscription?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("subscription.You can sign up for the Camper Subscription if you are at least 21 years old, have held a class B driver's license for at least one year, and have your residence in Germany")}.
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("subscription.Does the takeover have to be at the beginning of the month?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("subscription.No, you decide when you want to start. We calculate the costs proportionally from the day your subscription begins, for example, from March 20th")}.
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("subscription.Can I cancel my contract before the start of the subscription?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("subscription.Yes, you can. If you cancel at least 60 days before the planned start, the cancellation is free. After that, the first month's rate will be incurred as a cancellation fee, which is non-refundable")}.
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("subscription.Can I cancel the subscription early?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("subscription.Unfortunately, that is not possible. If you are unsure, we recommend extending the subscription if the camper is still available")}.
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("subscription.Is there a deposit?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("subscription.Yes, we require a deposit of €1500, which you can pay by EC card or in cash upon pickup. You will receive this back when you return the camper in good condition")}.
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("subscription.How is the camper insured?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("subscription.The subscription includes comprehensive insurance with a deductible of €1,500 per claim and liability insurance with coverage of €100 million")}.
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>


                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("subscription.And the extra kilometers?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("subscription.The monthly rate includes 2000 free kilometers. If you drive beyond that, additional kilometers are charged at €0.35 (including VAT) per kilometer. The settlement takes place after you return your camper. You also have the option to book additional kilometer packages at the time of contract signing, which are also based on your booked total term. You determine the distribution of kilometers over the term yourself, and unused kilometers can be easily used in the following month. Please note that up to 1000 kilometers per month can be additionally booked in advance, and the maximum of 3000 km per month must not be exceeded. If you drive more than the maximum, the additional kilometers will be charged at €0.70 per kilometer")}.

                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        {/* <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                What does the one-time start fee include?
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                At contract signing, there is a one-time fee of €169. This covers the costs for creating your profile and credit check.

                                </div>
                                
                              </div>
                            </div>
                          </div>
                        </details> */}
                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-lg xs:mb-6">
                                  {t("subscription.What does the one-time entry fee include?")}
                                </p>
                              </div>
                            </h3>

                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div className="">
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("subscription.A one-off fee of €169 is charged when the contract is concluded. This covers the costs for the creation of your profile and the credit check")}.
                                </div>
                              </div>
                            </div>
                          </div>
                        </details>
                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("subscription.Can the deductible be reduced?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("subscription.Unfortunately, we do not offer a reduction of the deductible, but you can additionally insure yourself privately")}.

                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("subscription.Is a camper inspection possible in advance?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("subscription.Of course, just make an appointment with us to view the camper")}.
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("subscription.Which countries am I allowed to travel to with the camper?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("subscription.You may use the rental vehicle exclusively in the following countries Albania, Andorra, Belgium, Bosnia and Herzegovina, Bulgaria, Germany, Denmark, Estonia, France, Finland, Greece, Great Britain, Italy, Ireland, Iceland, Croatia, Luxembourg, Liechtenstein, Lithuania, Latvia, Malta, Moldova, Monaco, Montenegro, Netherlands, North Macedonia, Norway, Austria, Portugal, Poland, Romania, San Marino, Sweden, Slovakia, Spain, Switzerland, Serbia, Slovenia, Czech Republic, Hungary, Vatican City, and Cyprus. Please note that you are not allowed to use the vehicle temporarily and continuously in a country other than Germany for more than three months in a row")}.
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("subscription.What if a service is due?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("subscription.No problem. Bring the camper by, and we'll take care of the service within half a day")}.
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>


                      </div>



                    </div>
                  </div>
                </details>

                <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark tab-title">
                  <summary className="flex cursor-pointer items-center justify-between py-4 md:py-8">
                    <h2 className="whitespace-pre-line break-words text-lg 2xl:text-xl text-xl-custom">
                      {t("Contact.Camper Sale")}
                    </h2>
                    <div className="find-help-block-svg inline-block align-top pl-4 child:h-12 child:w-12 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-12 md:child:w-12 2xl:child:h-12 2xl:child:w-12">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g fill="none" fillRule="evenodd">
                          <path
                            d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                            fill="var(--svg-color-secondary, #163435)"
                          ></path>
                        </g>
                      </svg>
                    </div>
                  </summary>
                  <div className="mb-8">
                    <div>

                      <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg mx-auto p-0 max-w-3xl legal-information legal-information details">

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("sale.Can I Test-Camp the Camper before buying it?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("sale.Absolutely! you can simply rent the camper, and upon purchase, we'll reimburse you up to €500 of rental costs.")}
                                  <br />{t("sale.This is your chance to experience the camper up close before making your final decision")}.
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("sale.Do You Offer Purchase Guidance & Inspections?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("sale.We're available weekdays from")} {t("sale.9 am to 5 pm")} {t("sale.at 0163 2937354 to assist you with the purchase process.")} <br />{t("sale.Just schedule a consultation or inspection appointment via phone or through the contact form.")}
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("sale.How Old Are Your Campers?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("sale.All our campers come from first-hand ownership.")} <br />{t("sale.You can always find the first registration date listed at the top of the campers available for sale.")}
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("sale.What's the Cost of Buying a Camper?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("sale.The price of a camper depends on the mileage and model.")} <br />{t("sale.But don't worry, we'll work together to find the best deal for you!")}
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("sale.Do You Offer Financing?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("sale.We're here to help you discover the most affordable financing options and guide you through the entire application process.")} <br />{t("sale.The money bag and chart are ready!")}
                                </div>

                              </div>
                            </div>
                          </div>
                        </details>



                      </div>



                    </div>
                  </div>
                </details>

                <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark tab-title">
                  <summary className="flex cursor-pointer items-center justify-between py-4 md:py-8">
                    <h2 className="whitespace-pre-line break-words text-lg 2xl:text-xl text-xl-custom">
                      {t("trip.Travel Planner")}
                    </h2>
                    <div className="find-help-block-svg inline-block align-top pl-4 child:h-12 child:w-12 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-12 md:child:w-12 2xl:child:h-12 2xl:child:w-12">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g fill="none" fillRule="evenodd">
                          <path
                            d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                            fill="var(--svg-color-secondary, #163435)"
                          ></path>
                        </g>
                      </svg>
                    </div>
                  </summary>
                  <div className="mb-8">
                    <div>

                      <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg mx-auto p-0 max-w-3xl legal-information legal-information details">

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("trip.What exactly does the Travel Planner offer me?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("trip.We've learned from some of our customers that they don't want the hassle of constant planning during their vacations")}
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("trip.During peak season, we recommend booking your campsites and activities well in advance.")}</div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("trip.What do I get with the Travel Planner?")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("trip.Our Travel Planner includes")}:
                                </div>

                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  <ul className="custom-ul">
                                    <li>{t("trip.Circular Route Planning")}:&nbsp;{t("trip.Avoid retracing your steps. Our routes offer you a beautiful circuit where you'll always discover something new.")}</li>
                                    <li>{t("trip.Personalization")}:&nbsp;{t("trip.Customize your route based on your preferences")}.</li>
                                    <li>{t("trip.Cultural insights1")}:&nbsp;{t("trip.Gain detailed insights into cultural treasures")}.</li>
                                    <li>{t("trip.Top Camping Spots")}:&nbsp;{t("trip.No more tedious searching; we provide top-rated recommendations directly")}.</li>
                                    <li>{t("trip.Activities")}:&nbsp;{t("trip.Exciting activities for every taste")}.</li>
                                    <li>{t("trip.Across Europe")}:&nbsp;{t("trip.We plan your route wherever you want to go")}.</li>
                                    <li>{t("trip.Customer Support")}:&nbsp;{t("trip.We're here for you if you have any questions")}!</li>
                                  </ul>
                                </div>


                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("trip.How many days after booking will I receive my itinerary")}?
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("trip.Typically, you'll receive your itinerary 5-10 days after booking. For last-minute bookings (at least 4 days before departure), you'll receive your itinerary no later than 24 hours before departure")}.
                                </div>


                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("trip.What's the process after booking")}?
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("trip.After booking, one of our travel planners will get in touch with you. You'll receive a questionnaire by email to learn more about your vacation expectations. If necessary, we'll clarify any open questions over the phone to tailor your route to your wishes. Normally, your customized itinerary will be created and provided to you within 5-10 days after booking")}.
                                </div>


                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("trip.Do you book campsites for me")}?
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("trip.We do not book pitches or campsites for you. You stay flexible! For the peak season, we recommend booking your pitch as early as possible. And remember, the sooner you book our Travel Planner, the sooner you'll receive your itinerary")}.
                                </div>


                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("trip.I have some questions; can I contact you")}?
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("trip.Certainly, you can reach us via email at")} info@borntocamp.de, {t("trip.through our contact form, or by phone at the following number")} +49 (0) 163 2937354 {t("trip.contact")}
                                </div>


                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("trip.How last-minute can I book the Travel Planner with you")}?
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("trip.To create a travel plan for you, there must be at least 4 days remaining until departure")}.
                                </div>


                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("trip.What is the cost of the Travel Planner")}?
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("trip.The Travel Planner costs €17 per day. If you need specific assistance with route planning for longterm rentals (15 days or more) or for your camper subscription, please don't hesitate to contact us. We can determine a customized total price for you")}.
                                </div>


                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("trip.Can I book the Travel Planner later or for a trip with my own camper")}?
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("trip.Yes, you have the option to book our Travel Planner either as a standalone product or add it to your camper rental up to 4 days before departure")}.
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("trip.However, our strong recommendation is to book the Travel Planner as early as possible, especially during peak season. It's possible that the top camping spots we recommend may be fully booked. (Please note that we do not check availability when creating the route.)")}</div>


                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("trip.What if I want to change my route")}?
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("trip.Your travel route can be adjusted flexibly. If your plans change and you'd like a new route from us, we're ready to create one for you. However, please be aware that last-minute re-planning right before your departure cannot be guaranteed, and fees may apply for creating a new route")}.
                                </div>


                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("trip.What's the advantage of the Travel Planner")}?
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div className="">
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <ul className="my-5 first:mt-0 pl-8 md:pl-10 text-left list-disc text-sm 2xl:text-2md 2xl:font-normal">
                                  <li className="pl-1 md:pl-2">{t("trip.No pre-travel planning stress")}.</li>
                                  <li className="pl-1 md:pl-2">{t("trip.An adventurous experience Be surprised by our activities and destinations")}.</li>
                                  <li className="pl-1 md:pl-2">{t("trip.No time-consuming on-site research; just enjoy")}.</li>
                                  <li className="pl-1 md:pl-2">{t("trip.Camping spots, activities, and destinations tailored to your preferences")}.</li>

                                </ul>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("trip.Having a customized route in your pocket turns your vacation into a treasure hunt. Every place is a surprise, stress-free, and exactly the way you love it")}.</div>
                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("trip.Is it possible to cancel the Travel Planner")}?
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("trip.Cancellation of the Travel Planner is free as long as you haven't returned the questionnaire for individual route planning. After sending back the questionnaire, cancellation will incur charges")}.
                                </div>


                              </div>
                            </div>
                          </div>
                        </details>

                      </div>



                    </div>
                  </div>
                </details>

                <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark tab-title">
                  <summary className="flex cursor-pointer items-center justify-between py-4 md:py-8">
                    <h2 className="whitespace-pre-line break-words text-lg 2xl:text-xl text-xl-custom">
                      {t("Contact.On the Road Support")}
                    </h2>
                    <div className="find-help-block-svg inline-block align-top pl-4 child:h-12 child:w-12 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-12 md:child:w-12 2xl:child:h-12 2xl:child:w-12">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g fill="none" fillRule="evenodd">
                          <path
                            d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                            fill="var(--svg-color-secondary, #163435)"
                          ></path>
                        </g>
                      </svg>
                    </div>
                  </summary>
                  <div className="mb-8">
                    <div>

                      <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg mx-auto p-0 max-w-3xl legal-information legal-information details">

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Procedure in case of damage Accident involving third parties")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.If you are involved in an accident with third parties, please remain calm and proceed step by step")}:
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  <ul>
                                    <li>1.&nbsp;&nbsp;{t("Contact.Ensure your own safety and put on a reflective vest")}.</li>
                                    <li>2.&nbsp;&nbsp;{t("Contact.Secure the accident scene")}.</li>
                                    <li>3.&nbsp;&nbsp;{t("Contact.Call emergency services or provide first aid if anyone is injured")}.</li>
                                    <li>4.&nbsp;&nbsp;{t("Contact.Inform the police")}.</li>
                                    <li>5.&nbsp;&nbsp;{t("Contact.Fill out the accident report (last page in the information booklet) and have it signed by all parties involved. Note that the insurance may refuse coverage in case of a missing report")}.</li>
                                    <li>6.&nbsp;&nbsp;{t("Contact.Document the accident carefully with photos of all damages and the license plates of the vehicles involved. Send the accident report, photos, and all relevant data directly after the accident to")}&nbsp;<a className='underline' href="mailto:'info@borntocamp.de'">info@borntocamp.de</a></li>
                                  </ul>
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("Contact.If your vehicle is no longer safe or drivable due to the damage, please utilize the appropriate mobility guarantee and contact us")}.</div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Procedure in case of damage Accident without involvement of third parties")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.If you are involved in an accident without third-party involvement, it's important to remain calm and proceed as follows")}:
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  <ul >
                                    <li>1.&nbsp;&nbsp;{t("Contact.Ensure your own safety and put on a reflective vest")}.</li>
                                    <li>2.&nbsp;&nbsp;{t("Contact.Secure the accident scene")}.</li>
                                    <li>3.&nbsp;&nbsp;{t("Contact.Call emergency services or provide first aid if anyone is injured")}.</li>
                                    <li>4.&nbsp;&nbsp;{t("Contact.Even in an accident without involvement of third parties, inform the police to obtain an accident report (especially if third-party property was damaged")}</li>
                                    <li>5.&nbsp;&nbsp;{t("Contact.Fill out the accident report (last page in the information booklet). Even if there is no other party involved, you can sketch and describe the sequence of events, as well as explain the circumstances. Additionally, include the location, date, and time, which are crucial for the report. Note that the insurance may refuse coverage in case of a missing report")}.</li>
                                    <li>6.&nbsp;&nbsp;{t("Contact.Document the accident carefully with photos of all damages and the license plates of the vehicles involved. Send the accident report, photos, and all relevant data directly after the accident to")}&nbsp;<a className="underline" href="mailto:'info@borntocamp.de'">info@borntocamp.de</a></li>
                                  </ul>
                                </div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("Contact.If your vehicle is no longer safe or drivable due to the damage, please utilize the appropriate mobility guarantee and contact us")}.</div>

                              </div>
                            </div>
                          </div>
                        </details>

                        <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                          <summary className="flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">
                            <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                              <svg
                                width="42"
                                height="42"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <h3>
                              <div className="flex items-center">
                                <p className="text-md 2xl:text-lg">
                                  {t("Contact.Procedure in case of damage Vandalism")}
                                </p>
                              </div>
                            </h3>
                          </summary>
                          <div className="px-7 2xl:px-9">
                            <div>
                              <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">

                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("Contact.If your vehicle has incurred damage that you did not notice, please report it immediately to the police and file a report. Additionally, please fill out the accident report and inform us directly about the incurred damage. You can do this by email at")}&nbsp;<a className="underline" href="mailto:'info@borntocamp.de'">info@borntocamp.de</a>&nbsp;{t("Contact.do")}
                                </div>


                              </div>
                            </div>
                          </div>
                        </details>

                      </div>



                    </div>
                  </div>
                </details>



              </div>
            </div>
          </div>
        </div>

        <div className="mx-5 max-w-screen-xl bg-primary rounded-lg px-4 md:px-8 xl:mx-auto message-contact-box">
          <div className="mx-0 max-w-screen-xl xl-padded:mx-auto">
            <div className="pt-8 2xl:pt-8 pb-0 mx-auto flex max-w-3xl flex-col items-center text-center">
              <p className="mobile-none mb-4 whitespace-pre-line text-md text-white">
                {t("Contact.We're Just one Message Away")}
              </p>
              <h2 className="whitespace-pre-line break-words text-xl md:text-2xl mb-4 text-white text-orange">
                {i18n.resolvedLanguage === 'de' ? <>{t("Contact.Our Customer Support")}<span className="mobile-none">{t("Contact.Team")}</span></> : <>{t("Contact.Our Customer Support")}<span className="mobile-none">&nbsp;{t("Contact.Team")}</span></>}

              </h2>
              <p className="whitespace-pre-line text-2md font-normal md:text-lg-paragraph md:font-normal text-white">
                {t("Contact.If you have any more questions or need additional information about your booking, we're here for you.")} {t("Contact.You can reach us by phone or through our contact form.")}
              </p>
            </div>

            <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12 pb-10 px-2">
              <div className="rounded bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-6 2xl:mt-14 2xl:h-[calc(100%-3.5rem)] pt-0 pb-7">
                <div className="flex justify-center align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-10 mb-8 child:h-24 child:w-24 2xl:-mt-10 2xl:child:h-25 2xl:child:w-25">
                  <div className="border primary-border rounded-full bg-white rounded-full h-full w-full">
                    <svg
                      className=""
                      width="70"
                      height="70"
                      viewBox="0 0 32 32"
                    >
                      <g fill="#fff" fillRule="evenodd">
                        <path
                          d="M12.004 8.139c-.171-.569-.342-1.024-.91-1.08L10.014 7C8.875 6.945 7 8.651 7 11.493c.057 4.947 7.448 13.02 12.338 13.476 2.9.284 4.72-1.422 4.776-2.616V21.33c.057-.569-.398-.796-.966-1.024-1.08-.398-2.16-.91-3.241-1.308-.455-.17-.853.114-1.137.285-.398.34-.683.568-1.024.966-.284.228-.568.341-.853.057-2.445-1.25-4.435-3.411-5.458-5.913-.227-.341-.114-.625.17-.853a16.92 16.92 0 0 0 1.024-.967c.227-.227.512-.625.398-1.08-.341-1.08-.682-2.274-1.023-3.354z"
                          fill="var(--svg-color-secondary, #EE7214)"
                        ></path>
                      </g>
                    </svg>
                  </div>
                </div>
                <p className="whitespace-pre-line text-center text-lg md:text-xl">
                  {t("Contact.Give us a call!")}
                </p>
                <Link
                  to={"tel:+49 (0) 163 2937354"}
                  className="group/button block disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark p-3 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated pl-3 py-2 w-full mt-6"
                >
                  <div className="flex h-full items-center md:justify-between justify-center ">
                    <div className="mobile-none inline-block align-top mr-2 rounded-full bg-white p-1 child:h-7 child:w-7 dark:bg-off-white">
                      <svg width="32" height="32" viewBox="0 0 32 32">
                        <g fill="none" fillRule="evenodd">
                          <path
                            d="M12.004 8.139c-.171-.569-.342-1.024-.91-1.08L10.014 7C8.875 6.945 7 8.651 7 11.493c.057 4.947 7.448 13.02 12.338 13.476 2.9.284 4.72-1.422 4.776-2.616V21.33c.057-.569-.398-.796-.966-1.024-1.08-.398-2.16-.91-3.241-1.308-.455-.17-.853.114-1.137.285-.398.34-.683.568-1.024.966-.284.228-.568.341-.853.057-2.445-1.25-4.435-3.411-5.458-5.913-.227-.341-.114-.625.17-.853a16.92 16.92 0 0 0 1.024-.967c.227-.227.512-.625.398-1.08-.341-1.08-.682-2.274-1.023-3.354z"
                            fill="var(--svg-color-secondary, #163435)"
                          ></path>
                        </g>
                      </svg>
                    </div>
                    <span className="flex">+49 (0) 163 2937354</span>
                    <div className="mobile-none inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g fill="none" fillRule="evenodd">
                          <path
                            d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                            fill="var(--svg-color-secondary, #163435)"
                          ></path>
                        </g>
                      </svg>
                    </div>
                  </div>
                </Link>
              </div>

              <div className="rounded bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-6 2xl:mt-14 2xl:h-[calc(100%-3.5rem)] pt-0 pb-7">
                <div className="flex justify-center align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-10 mb-8 child:h-24 child:w-24 2xl:-mt-10 2xl:child:h-25 2xl:child:w-25">
                  <div className="border primary-border rounded-full h-full w-full">
                    <svg
                      className="bg-white rounded-full "
                      width="70"
                      height="70"
                      viewBox="0 0 32 32"
                      version="1.1"
                    >
                      <title>icon_contact_document</title>
                      <g
                        id="icon_contact_document"
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <path
                          d="M17.9178007,6 C18.7291626,6 19.4995365,6.33165483 20.0593197,6.91967801 L21.039,7.949 L19.568,9.668 L18.4296787,8.47105496 L18.3425912,8.39300464 C18.2199225,8.29970749 18.0725017,8.25 17.9178007,8.25 L9.97231673,8.25 L9.87447009,8.25694254 C9.52240915,8.30729672 9.25,8.62539514 9.25,9.00875622 L9.24954809,13.896061 C9.1536008,14.3734594 9.15308409,14.8664351 9.24835822,15.3445308 L9.25,22.6663681 L9.25660912,22.7691509 C9.30454502,23.1389727 9.60736668,23.4251243 9.97231673,23.4251243 L20.0847509,23.4251243 L20.1825976,23.4181818 C20.5346585,23.3678276 20.8070677,23.0497292 20.8070677,22.6663681 L20.807,19.585 L23.057,16.961 L23.0570677,22.6663681 C23.0570677,24.2446718 21.8425887,25.5767869 20.2618524,25.6699248 L20.0847509,25.6751243 L9.97231673,25.6751243 C8.30610628,25.6751243 7,24.3031275 7,22.6663681 L7,9.00875622 C7,7.37199682 8.30610628,6 9.97231673,6 L17.9178007,6 Z M26.7421892,6.99537872 C27.3327153,7.48618759 27.4074666,8.35752766 26.9086702,8.93937036 L18.3333707,18.9360165 C17.8023465,19.5552812 16.8460507,19.5891639 16.2707777,19.0096449 L12.5274156,15.2362611 C11.9867629,14.691607 11.9968213,13.817003 12.5502104,13.2854503 C13.1012366,12.7552571 13.9836709,12.7655668 14.5219414,13.3087527 L17.2050961,16.0102116 L24.7773448,7.15843866 C25.2742929,6.57875194 26.1535102,6.50610494 26.7421892,6.99537872 Z"
                          id="Combined-Shape"
                          fill="var(--svg-color-secondary, #EE7214)"
                        ></path>
                      </g>
                    </svg>
                  </div>
                </div>
                <p className="whitespace-pre-line text-center text-lg md:text-xl">
                  {t("Contact.Our use the contact form.")}
                </p>
                <button
                  onClick={() =>
                    window.scrollTo({
                      top: conatctForm.current.offsetTop - 118,
                      behavior: "smooth",
                    })
                  }
                  className="group/button block disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark p-3 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated pl-3 py-2 w-full mt-6"
                >
                  <div className="flex h-full items-center md:justify-between justify-center">
                    <div className="mobile-none inline-block align-top mr-2 rounded-full bg-white p-1 child:h-7 child:w-7 dark:bg-off-white">
                      <svg
                        width="32px"
                        height="32px"
                        viewBox="0 0 32 32"
                        version="1.1"
                      >
                        <title>icon_contact_mail</title>
                        <g
                          id="icon_contact_mail"
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <g
                            id="Group"
                            transform="translate(5.000000, 7.700000)"
                            fill="var(--svg-color-secondary, #163435)"
                          >
                            <path
                              d="M17.1541722,0 C18.5243618,0 19.2165846,0.133674373 19.9368674,0.518885858 C20.6000248,0.873546296 21.1264537,1.39997522 21.4811141,2.06313259 C21.8663256,2.78341544 22,3.47563815 22,4.8458278 L21.9981828,11.9648343 C21.9810588,13.1622375 21.8406449,13.810058 21.4811141,14.482322 C21.1264537,15.1454793 20.6000248,15.6719082 19.9368674,16.0265687 C19.2165846,16.4117802 18.5243618,16.5454545 17.1541722,16.5454545 L4.8458278,16.5454545 C3.47563815,16.5454545 2.78341544,16.4117802 2.06313259,16.0265687 C1.39997522,15.6719082 0.873546296,15.1454793 0.518885858,14.482322 C0.133674373,13.7620391 0,13.0698164 0,11.6996267 L0,4.8458278 C0,3.47563815 0.133674373,2.78341544 0.518885858,2.06313259 C0.873546296,1.39997522 1.39997522,0.873546296 2.06313259,0.518885858 C2.78341544,0.133674373 3.47563815,0 4.8458278,0 Z M20,5.563 L11,12.1092247 L2,5.564 L2,11.6996267 L2.00467769,12.1173697 C2.02375106,12.8767631 2.10330814,13.2040417 2.28251225,13.5391242 C2.45077309,13.8537447 2.69170989,14.0946815 3.0063303,14.2629423 C3.31348932,14.4272127 3.61409085,14.507752 4.24698433,14.5347258 L4.6271902,14.5443078 L17.3728098,14.5443078 C17.4423488,14.5435357 17.5086323,14.5423663 17.5719151,14.5407769 L17.9176389,14.5260186 C18.4406182,14.4924165 18.7144342,14.4122791 18.9936697,14.2629423 C19.3082901,14.0946815 19.5492269,13.8537447 19.7174877,13.5391242 C19.9265592,13.1481946 20,12.767887 20,11.6996267 L20,5.563 Z M17.1541722,2 L4.8458278,2 L4.42808487,2.00467769 C3.66869148,2.02375106 3.34141286,2.10330814 3.0063303,2.28251225 C2.69170989,2.45077309 2.45077309,2.69170989 2.28251225,3.0063303 C2.24438729,3.07761778 2.21077244,3.14855205 2.1812906,3.22336911 L10.999,9.63500008 L19.8187094,3.22336911 C19.7892276,3.14855205 19.7556127,3.07761778 19.7174877,3.0063303 C19.5492269,2.69170989 19.3082901,2.45077309 18.9936697,2.28251225 C18.6585871,2.10330814 18.3313085,2.02375106 17.5719151,2.00467769 L17.1541722,2 Z"
                              id="Combined-Shape"
                            ></path>
                          </g>
                        </g>
                      </svg>
                    </div>
                    <span className="flex">{t("Contact.To the contact form")}</span>
                    <div className="mobile-none inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g fill="none" fillRule="evenodd">
                          <path
                            d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                            fill="var(--svg-color-secondary, #163435)"
                          ></path>
                        </g>
                      </svg>
                    </div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="mx-5 max-w-screen-xl xl-padded:mx-auto">
          <div className="pt-8 2xl:pt-12 2xl:pb-6 text-center mx-auto max-w-5xl">
            <h2 className="my-5 first:mt-0 break-words text-xl md:text-2xl">
              {t("Contact.Opening Hours")}
            </h2>
            <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal text-20 sm-br-none">
              {t("Contact.Get answers about our models, booking and general questions from")}
              <br />
              {t("Contact.our experienced customer service team.")}
            </div>

            <h4 className="my-5 first:mt-0 break-words text-lg">
              {t("Contact.Monday to Sunday")}
            </h4>
            <div className="text-20 min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
              {t("Contact.9 am to 5 pm")} ({t("Contact.depending on the season")})
            </div>
          </div>

          <Explanatory />

          <div ref={conatctForm}>
            <div className="2xl:pt-6 pb-4 2xl:pb-8">
              <div className="mx-auto flex max-w-3xl flex-col items-center">
                <div className="inline-flex items-center justify-center rounded-full border border-primary bg-white p-3 dark:border-primary-dark dark:bg-off-white mb-5 md:mb-6">
                  <div className="inline-block align-top child:h-10 child:w-10">
                    <img
                      src="images/contact-form-img.svg"
                      alt="interested-camper"
                    />
                  </div>
                </div>
                <h2 className="whitespace-pre-line break-words mb-4 text-center md:mb-5 text-xl md:text-2xl">
                  {t("Contact.Contact form")}
                </h2>
                <div className="mb-4 text-center md:mb-5">
                  <div className="text-20 min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                    {t("Contact.Your questions, our commitment!")}
                    <br />
                    {t("Contact.We aim to respond to your inquiries and assist you within 12 hours.")}
                  </div>
                </div>
                {isFormSubmit ? (
                  <>
                    <div className="opacity-100 scale-100 success-msg text-center">
                      <div className="inline-flex items-center justify-center rounded-full orange-border bg-white p-1 dark:bg-off-white">
                        <div className="inline-block align-top">
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g fill="none" fill-rule="evenodd">
                              <path
                                d="m21.906 9.488-8.547 9.56-3.45-3.096a1.757 1.757 0 0 0-2.563.209c-.64.792-.547 1.98.21 2.659l4.764 4.274c.735.66 1.833.602 2.496-.13l9.73-10.869a1.954 1.954 0 0 0-.069-2.665 1.758 1.758 0 0 0-2.571.058z"
                                fill="var(--svg-color-secondary, #163435)"
                              ></path>
                            </g>
                          </svg>
                        </div>
                      </div>
                      <h2 className="mt-4 max-w-lg text-lg-paragraph">
                        {t("Contact.Thanks for your message!")}<br /> {t("Contact.You will hear back from us soon.")}
                      </h2>
                    </div>
                  </>
                ) : (
                  <form
                    className="flex w-full flex-col items-center"
                    onSubmit={onSubmitForm}
                  >
                    <div className="grid grid-cols-1 gap-x-4 md:grid-cols-2 w-full">
                      <div className="transition-all mb-4 col-span-2">
                        <div className="relative z-foreground mb-0 md:mb-0 flex h-[60px] items-center rounded border bg-white text-black transition-all focus-within:border-primary focus:border-primary dark:bg-off-black-desat dark:text-off-white dark:focus-within:border-primary-dark border-transparent shadow dark:shadow-dark">
                          <div className="inline-block align-top absolute pl-3 child:h-7 child:w-7 dark:cssvar-secondary-to-off-white">
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M16 14.6527C13.6105 14.6527 11.6736 12.7158 11.6736 10.3264C11.6736 7.93688 13.6105 6 16 6C18.3895 6 20.3264 7.93688 20.3264 10.3264C20.3264 12.7158 18.3895 14.6527 16 14.6527Z"
                                fill="var(--svg-color-secondary, #163435)"
                              ></path>
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M16.1565 15.9742C12.2188 15.8883 9 19.0545 9 22.9726C9 23.4621 9.05258 23.9383 9.15042 24.3979C9.26224 24.9247 9.72616 25.3021 10.2643 25.3021H21.7361C22.2693 25.3021 22.7352 24.9324 22.847 24.4112C23.0091 23.6528 23.05 22.8501 22.9329 22.0181C22.4596 18.6482 19.5587 16.0487 16.1565 15.9742Z"
                                fill="var(--svg-color-secondary, #163435)"
                              ></path>
                            </svg>
                          </div>
                          <div className="peer flex w-full flex-col">
                            <input
                              className="peer w-full rounded bg-transparent pt-2 pb-1 pl-12 text-md outline-none focus:pr-6 pr-6"
                              type="text"
                              name="firstName"
                              placeholder={t("Contact.First & Last Name*")}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="transition-all mb-4 col-span-2 md:col-span-1">
                        <div className="relative z-foreground md:mb-0 mb-0 flex h-[60px] items-center rounded border bg-white text-black transition-all focus-within:border-primary focus:border-primary dark:bg-off-black-desat dark:text-off-white dark:focus-within:border-primary-dark border-transparent shadow dark:shadow-dark">
                          <div className="inline-block align-top absolute pl-3 child:h-7 child:w-7 dark:cssvar-secondary-to-off-white">
                            <svg
                              width="32px"
                              height="32px"
                              viewBox="0 0 32 32"
                              version="1.1"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <title>icon_contact_mail</title>
                              <g
                                id="icon_contact_mail"
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                              >
                                <g
                                  id="Group"
                                  transform="translate(5.000000, 7.700000)"
                                  fill="var(--svg-color-secondary, #163435)"
                                >
                                  <path
                                    d="M17.1541722,0 C18.5243618,0 19.2165846,0.133674373 19.9368674,0.518885858 C20.6000248,0.873546296 21.1264537,1.39997522 21.4811141,2.06313259 C21.8663256,2.78341544 22,3.47563815 22,4.8458278 L21.9981828,11.9648343 C21.9810588,13.1622375 21.8406449,13.810058 21.4811141,14.482322 C21.1264537,15.1454793 20.6000248,15.6719082 19.9368674,16.0265687 C19.2165846,16.4117802 18.5243618,16.5454545 17.1541722,16.5454545 L4.8458278,16.5454545 C3.47563815,16.5454545 2.78341544,16.4117802 2.06313259,16.0265687 C1.39997522,15.6719082 0.873546296,15.1454793 0.518885858,14.482322 C0.133674373,13.7620391 0,13.0698164 0,11.6996267 L0,4.8458278 C0,3.47563815 0.133674373,2.78341544 0.518885858,2.06313259 C0.873546296,1.39997522 1.39997522,0.873546296 2.06313259,0.518885858 C2.78341544,0.133674373 3.47563815,0 4.8458278,0 Z M20,5.563 L11,12.1092247 L2,5.564 L2,11.6996267 L2.00467769,12.1173697 C2.02375106,12.8767631 2.10330814,13.2040417 2.28251225,13.5391242 C2.45077309,13.8537447 2.69170989,14.0946815 3.0063303,14.2629423 C3.31348932,14.4272127 3.61409085,14.507752 4.24698433,14.5347258 L4.6271902,14.5443078 L17.3728098,14.5443078 C17.4423488,14.5435357 17.5086323,14.5423663 17.5719151,14.5407769 L17.9176389,14.5260186 C18.4406182,14.4924165 18.7144342,14.4122791 18.9936697,14.2629423 C19.3082901,14.0946815 19.5492269,13.8537447 19.7174877,13.5391242 C19.9265592,13.1481946 20,12.767887 20,11.6996267 L20,5.563 Z M17.1541722,2 L4.8458278,2 L4.42808487,2.00467769 C3.66869148,2.02375106 3.34141286,2.10330814 3.0063303,2.28251225 C2.69170989,2.45077309 2.45077309,2.69170989 2.28251225,3.0063303 C2.24438729,3.07761778 2.21077244,3.14855205 2.1812906,3.22336911 L10.999,9.63500008 L19.8187094,3.22336911 C19.7892276,3.14855205 19.7556127,3.07761778 19.7174877,3.0063303 C19.5492269,2.69170989 19.3082901,2.45077309 18.9936697,2.28251225 C18.6585871,2.10330814 18.3313085,2.02375106 17.5719151,2.00467769 L17.1541722,2 Z"
                                    id="Combined-Shape"
                                  ></path>
                                </g>
                              </g>
                            </svg>
                          </div>
                          <div className="peer flex w-full flex-col">
                            <input
                              className="peer w-full rounded bg-transparent pt-2 pb-1 pl-12 text-md outline-none focus:pr-6 pr-6"
                              type="email"
                              name="email"
                              placeholder={t("Contact.E-mail*")}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="transition-all mb-4 col-span-2 md:col-span-1">
                        <div className="relative z-foreground md:mb-0 mb-0 flex h-[60px] items-center rounded border bg-white text-black transition-all focus-within:border-primary focus:border-primary dark:bg-off-black-desat dark:text-off-white dark:focus-within:border-primary-dark border-transparent shadow dark:shadow-dark">
                          <div className="inline-block align-top absolute pl-3 child:h-7 child:w-7 dark:cssvar-secondary-to-off-white">
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g fill="none" fillRule="evenodd">
                                <path
                                  d="M12.004 8.139c-.171-.569-.342-1.024-.91-1.08L10.014 7C8.875 6.945 7 8.651 7 11.493c.057 4.947 7.448 13.02 12.338 13.476 2.9.284 4.72-1.422 4.776-2.616V21.33c.057-.569-.398-.796-.966-1.024-1.08-.398-2.16-.91-3.241-1.308-.455-.17-.853.114-1.137.285-.398.34-.683.568-1.024.966-.284.228-.568.341-.853.057-2.445-1.25-4.435-3.411-5.458-5.913-.227-.341-.114-.625.17-.853a16.92 16.92 0 0 0 1.024-.967c.227-.227.512-.625.398-1.08-.341-1.08-.682-2.274-1.023-3.354z"
                                  fill="var(--svg-color-secondary, #163435)"
                                ></path>
                              </g>
                            </svg>
                          </div>
                          <div className="peer flex w-full flex-col">
                            <input
                              className="peer w-full rounded bg-transparent pt-2 pb-1 pl-12 text-md outline-none focus:pr-6 pr-6"
                              type="tel"
                              name="phone"
                              id=""
                              placeholder={t("Contact.Phone number")}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="transition-all mb-4 col-span-2">
                        <div className="relative w-full z-foreground custom-select camper-car no-border text-left">
                          <Select
                            backspaceRemovesValue={false}
                            hideSelectedOptions={false}
                            tabSelectsValue={false}
                            options={camper}
                            className="react-select-container"
                            classNamePrefix="react-select"
                            placeholder={t("Contact.Camper Model")}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.title}
                            name="camper_id"
                            isSearchable={false}
                          />
                        </div>
                      </div>

                      <div className="transition-all pb-4 col-span-2">
                        <div className="relative w-full z-foreground custom-select question no-border text-left">
                          <Select
                            backspaceRemovesValue={false}
                            hideSelectedOptions={false}
                            tabSelectsValue={false}
                            options={question}
                            onChange={onChange}
                            className="react-select-container"
                            classNamePrefix="react-select"
                            placeholder={t("Contact.Reason for your request*")}
                            name="reasonRequest"
                            isSearchable={false}
                          />
                        </div>
                      </div>

                      {inputBox ? (
                        <div className="transition-all mb-4 col-span-2">
                          <div className="relative z-foreground md:mb-0 mb-0 flex h-[60px] items-center rounded border bg-white text-black transition-all focus-within:border-primary focus:border-primary dark:bg-off-black-desat dark:text-off-white dark:focus-within:border-primary-dark border-transparent shadow dark:shadow-dark">
                            <div className="peer flex w-full flex-col">
                              <input
                                className="peer w-full rounded bg-transparent pt-2 pb-1 pl-12 text-md outline-none focus:pr-6 pr-6"
                                type="text"
                                name="bookingNumber"
                                placeholder={t("Login.Booking Number")}
                              />
                            </div>
                          </div>
                        </div>
                      ) : null}

                      <div className="transition-all mb-1 md:mb-4 col-span-2">
                        <div className="relative z-foreground flex w-full flex-col">
                          <textarea
                            rows="5"
                            className="peer relative mb-4 w-full items-center rounded border bg-white p-4 pt-6 text-md text-black outline-none transition-all focus:border-primary dark:bg-off-black-desat dark:text-off-white dark:focus-within:border-primary-dark border-transparent shadow dark:shadow-dark"
                            name="message"
                            id=""
                            placeholder={t("Contact.Your message...*")}
                          ></textarea>
                        </div>
                      </div>

                      <div className="mt-2 mb-4 col-span-2">
                        <div className="relative flex">
                          <input
                            type="checkbox"
                            className="peer h-7 w-7 min-w-[28px] cursor-pointer appearance-none rounded-sm border border-grey bg-white outline-none transition-all checked:border-primary checked:bg-primary focus:border-primary dark:border-grey-dark dark:bg-off-black-desat"
                            name=""
                            id=""
                            required
                          />
                          <div className="align-top pointer-events-none absolute left-0.5 top-0.5 hidden cssvar-secondary-to-white peer-checked:block child:h-6 child:w-6 dark:cssvar-secondary-to-off-white">
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g fill="none" fillRule="evenodd">
                                <path
                                  d="m21.906 9.488-8.547 9.56-3.45-3.096a1.757 1.757 0 0 0-2.563.209c-.64.792-.547 1.98.21 2.659l4.764 4.274c.735.66 1.833.602 2.496-.13l9.73-10.869a1.954 1.954 0 0 0-.069-2.665 1.758 1.758 0 0 0-2.571.058z"
                                  fill="var(--svg-color-secondary, #163435)"
                                ></path>
                              </g>
                            </svg>
                          </div>
                          <label className="ml-3 cursor-pointer" htmlFor="">
                            {
                              i18n.resolvedLanguage === 'en' ?
                                <>
                                  <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                    I have read the &nbsp;
                                    <Link
                                      to={"/privacystatement"}
                                      className="inline-block text-primary underline hover:text-off-black dark:text-primary-light hover:dark:text-off-white"
                                    >
                                      data {t("sale.privacy")}&nbsp;{t("sale.statement")}
                                    </Link>

                                  </div>
                                </>
                                :
                                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                  {t("sale.I have read the")}{" "}
                                  <Link
                                    to={"/privacystatement"}
                                    className="inline-block text-primary underline hover:text-off-black dark:text-primary-light hover:dark:text-off-white"
                                  >
                                    {t("sale.privacy")}
                                  </Link>
                                  &nbsp;{t("sale.statement")}
                                </div>
                            }

                          </label>
                        </div>
                        <div className="relative bottom-4 overflow-hidden bg-warning text-center text-xs font-medium text-black transition-all mt-1 rounded max-h-0"></div>
                      </div>
                    </div>
                    <div className="text-danger fs-12" >    {Error ? <p style={{ color: "red" }} className="mb-2 flex w-full animate-wiggle items-center justify-between rounded bg-warning px-4 py-2 text-lg text-white">{Error}</p> : ''}</div>
                    <div className="text-danger fs-12">

                    </div>
                    <button
                      type="submit"
                      className="group/button block disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark p-3 pl-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated pr-5 mt-2 w-full md:w-auto md:min-w-[50%]"
                    >
                      <div className="flex h-full items-center justify-center">
                        <span className="flex">{t("sale.Send")}</span>
                      </div>
                    </button>
                    <p className="mt-5 text-xs">{t("sale.*Mandatory field")}</p>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Contactus;
