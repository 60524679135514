import React, { useState, useEffect } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import Campersrent from "../../Components/Campersrent/Campersrent";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@splidejs/react-splide/css/core";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Camperbox from "../../Components/Camperbox/Camperbox";
import Accordion from 'react-bootstrap/Accordion';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const Explanatoryvideov1 = () => {
  
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [nav3, setNav3] = useState(null);
  const [nav4, setNav4] = useState(null);
  const [nav5, setNav5] = useState(null);
  const [nav6, setNav6] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);
  const [slider3, setSlider3] = useState(null);
  const [slider4, setSlider4] = useState(null);
  const [slider5, setSlider5] = useState(null);
  const [slider6, setSlider6] = useState(null);
  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
    setNav3(slider3);
    setNav4(slider4);
    setNav5(slider5);
    setNav6(slider6);

  });

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: ".slider-nav",
  };
  const settingsThumbs = {
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: ".slider-for",
    dots: true,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: "10px",
    arrows: false,
    responsive: [
      {
        breakpoint: 1273,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  var camperslider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1273,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  var fiverow = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1273,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  var fourrow = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1273,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  var tworow = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1273,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      <Header />

      <div className="min-h-screen bg-off-white">
       
        <div className="pb-5 text-center">
          <div className="overflow-hidden">
            <div className="-ml-[5%] w-[110%] overflow-hidden rounded-b-[60%20%] px-[5%]">
              <div className="relative">
                <div className="overflow-hidden">
                  <img
                    src="images/explainatory-videos-title.png"
                    alt="explainatory-videos-title"
                    className="absolute max-h-full w-full scale-110 object-cover blur-lg dark:brightness-90 sm:aspect-hero-regular 3xl:aspect-hero-long aspect-square"
                  />
                </div>
                <picture className="flex max-h-[50vh] w-full text-0">
                  <img
                    className="z-0 max-h-full w-full object-cover dark:brightness-90 sm:aspect-hero-regular 3xl:aspect-hero-long aspect-square"
                    src="images/explainatory-videos-title.png"
                    alt="explainatory-videos-title"
                    width="2552"
                    height="1286"
                  />
                </picture>
                <div className="absolute top-0 left-0 flex h-full w-full flex-col items-center justify-end">
                  <div className="pb-12 w-full bg-gradient-to-t from-black-pure/80 to-transparent pt-20 md:pt-14 xl:pt-32">
                    <h1 className="whitespace-pre-line break-words m-auto max-w-4xl px-8 text-xl text-white drop-shadow-text dark:text-off-white xs:text-2xl xl:text-3xl mb-5 md:mb-8 xl:mb-12">
                      Campers - Explanatory videos
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark relative mx-auto -mt-12 child:h-24 child:w-24 2xl:-mt-14 2xl:child:h-28 2xl:child:w-28">
            <img
              className=""
              src="images/trip-planner-banner.svg"
              alt="trip-planner-banner"
            />
          </div>
        </div>

     

        <div className="mx-5 max-w-screen-xl xl-padded:mx-auto">
          <div className="pt-8 pb-0 mx-auto flex max-w-3xl flex-col items-center text-center">
            <p className="mb-4 whitespace-pre-line text-md">
            Camper Secrets Revealed
            </p>
            <h2 className="whitespace-pre-line break-words text-xl md:text-2xl mb-4">
              Camping like a Pro
            </h2>
            <p className="whitespace-pre-line text-2md font-normal md:text-lg-paragraph md:font-normal">
              Our videos show you how to effortlessly master your camper and equipment. From setting up the beds to changing the gas bottles. You'll feel like a true pro!
            </p>
          </div>
        
          </div>

          <div id="campervans">


          <div className="max-w-7xl sm:mx-0 xl-padded:mx-auto pt-8 2xl:pt-12">
            <div className="mb-8">
              <div>
                <div className="-ml-5 flex w-screen justify-center lg:w-auto xl-padded-with-arrows:m-auto lg:mx-14 xl-padded-with-arrows:mx-14 flex-col">
                <Tabs>
                <TabList className="tablist">
                  <Tab>
                  <div className="overflow-hidden rounded-sm bg-white shadow dark:bg-off-black-desat dark:shadow-dark flex flex-col items-center justify-center p-3 text-center 1xs:rounded sm:rounded-md sm:p-5 -mb-1 min-h-full rounded-b-none pb-10 2xs:rounded-b-none sm:rounded-b-none sm:pb-5">
                        <picture className="text-0 mb-1 xs:mb-2 lg:mb-3 lg:w-[200px] lg:max-w-full xl:w-[210px]">
                          <img
                            className="inline-block max-w-full object-cover dark:brightness-90 rounded mb-1 xs:mb-2 lg:mb-3 w-full aspect-tabs lg:max-h-[160px]"
                            src="images/Euro-Camper.png"
                            alt="Euro Camper-min"
                          />
                        </picture>
                        <p className="overflow-hidden text-md f-NolandBold">Euro Camper</p>
                      </div>

                  </Tab>
                  <Tab>
                  <div className="overflow-hidden rounded-sm bg-white shadow dark:bg-off-black-desat dark:shadow-dark flex flex-col items-center justify-center p-3 text-center 1xs:rounded sm:rounded-md sm:p-5 -mb-1 min-h-full rounded-b-none pb-10 2xs:rounded-b-none sm:rounded-b-none sm:pb-5">
                        <picture className="text-0 mb-1 xs:mb-2 lg:mb-3 lg:w-[200px] lg:max-w-full xl:w-[210px]">
                          <img
                            className="inline-block max-w-full object-cover dark:brightness-90 rounded mb-1 xs:mb-2 lg:mb-3 w-full aspect-tabs lg:max-h-[160px]"
                            src="images/Westfalia Kelsey -min.jpeg"
                            alt="Westfalia Kelsey -min"
                          />
                        </picture>
                        <p className="overflow-hidden text-md f-NolandBold">Flex Camper</p>
                      </div>
                  </Tab>
                  <Tab>
                  <div className="overflow-hidden rounded-sm bg-white shadow dark:bg-off-black-desat dark:shadow-dark flex flex-col items-center justify-center p-3 text-center 1xs:rounded sm:rounded-md sm:p-5 -mb-1 min-h-full rounded-b-none pb-10 2xs:rounded-b-none sm:rounded-b-none sm:pb-5">
                        <picture className="text-0 mb-1 xs:mb-2 lg:mb-3 lg:w-[200px] lg:max-w-full xl:w-[210px] mt-5">
                          <img
                            className="inline-block max-w-full object-cover dark:brightness-90 rounded mb-1 xs:mb-2 lg:mb-3 w-full aspect-tabs lg:max-h-[160px]"
                            src="images/cover-img-iniziale-6_e08f96efbe343d8ecdce0e5836434a3b_t.png"
                            alt="vw_t6_beach_oceas.png"
                          />
                        </picture>
                        <p className="overflow-hidden text-md f-NolandBold">Deluxe Camper</p>
                      </div>
                  </Tab>  
                  <Tab>
                  <div className="overflow-hidden rounded-sm bg-white shadow dark:bg-off-black-desat dark:shadow-dark flex flex-col items-center justify-center p-3 text-center 1xs:rounded sm:rounded-md sm:p-5 -mb-1 min-h-full rounded-b-none pb-10 2xs:rounded-b-none sm:rounded-b-none sm:pb-5">
                        <picture className="text-0 mb-1 xs:mb-2 lg:mb-3 lg:w-[200px] lg:max-w-full xl:w-[210px]">
                          <img
                            className="inline-block max-w-full object-cover dark:brightness-90 rounded mb-1 xs:mb-2 lg:mb-3 w-full aspect-tabs lg:max-h-[160px]"
                            src="images/poessl_summit-shine-600_fiat_light__691-min.png"
                            alt="vw_t6_beach_oceas.png"
                          />
                        </picture>
                        <p className="overflow-hidden text-md f-NolandBold">Couple Camper</p>
                      </div>
                  </Tab>
                  
                  <Tab>
                  <div className="overflow-hidden rounded-sm bg-white shadow dark:bg-off-black-desat dark:shadow-dark flex flex-col items-center justify-center p-3 text-center 1xs:rounded sm:rounded-md sm:p-5 -mb-1 min-h-full rounded-b-none pb-10 2xs:rounded-b-none sm:rounded-b-none sm:pb-5">
                        <picture className="text-0 mb-1 xs:mb-2 lg:mb-3 lg:w-[200px] lg:max-w-full xl:w-[210px]">
                          <img
                            className="inline-block max-w-full object-cover dark:brightness-90 rounded mb-1 xs:mb-2 lg:mb-3 w-full aspect-tabs lg:max-h-[160px]"
                            src="images/family-camper.png"
                            alt="family-camper-min"
                          />
                        </picture>
                        <p className="overflow-hidden text-md f-NolandBold">Family Camper</p>
                      </div>
                  </Tab>


                  <Tab>
                  <div className="overflow-hidden rounded-sm bg-white shadow dark:bg-off-black-desat dark:shadow-dark flex flex-col items-center justify-center p-3 text-center 1xs:rounded sm:rounded-md sm:p-5 -mb-1 min-h-full rounded-b-none pb-10 2xs:rounded-b-none sm:rounded-b-none sm:pb-5">
                        <picture className="text-0 mb-1 xs:mb-2 lg:mb-3 lg:w-[200px] lg:max-w-full xl:w-[210px]">
                          <img
                            className="inline-block max-w-full object-cover dark:brightness-90 rounded mb-1 xs:mb-2 lg:mb-3 w-full aspect-tabs lg:max-h-[160px]"
                            src="images/csm_kta-weinsberg.png"
                            alt="Glückskarren-min"
                          />
                        </picture>
                        <p className="overflow-hidden text-md f-NolandBold">Glückskarren</p>
                      </div>
                  </Tab>
                </TabList>
                <TabPanel>

                <div className="overflow-hidden bg-white p-6 dark:bg-off-black -ml-5 min-h-[40px] w-screen rounded-none py-0 2xs:rounded-none lg:ml-0 lg:w-auto 2xs:rounded-lg lg:px-12">
                            <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                              <div className="overflow-hidden rounded relative mx-auto p-0 w-full bg-white dark:border-gray-dark dark:bg-off-black">
                              <div className="pt-0 pb-8 2xl:pb-12">
                                <div className="relative mx-auto max-w-6xl">

                                <details className="group border-b border-b-grey  dark:border-b-grey-dark">
                                    <summary className="flex cursor-pointer items-center justify-between py-4 md:py-8">
                                      <h2 className="whitespace-pre-line break-words text-lg 2xl:text-xl">
                                      Room-Tour
                                      </h2>
                                      <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-10 md:child:w-10 2xl:child:h-12 2xl:child:w-12">
                                        <svg
                                          width="32"
                                          height="32"
                                          viewBox="0 0 32 32"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g fill="none" fillRule="evenodd">
                                            <path
                                              d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                              fill="var(--svg-color-secondary, #163435)"
                                            ></path>
                                          </g>
                                        </svg>
                                      </div>
                                    </summary>
                                    <div className="mb-8">
                                          <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12">
                                              <div className="flex flex-col justify-center">
                                                  <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                                                    <div className="relative mx-auto max-w-3xl">
                                                      <div className="mask-image relative h-0 w-full overflow-hidden rounded bg-off-white pt-[56.25%] dark:bg-off-black-dark">
                                                      
                                                      <iframe className="absolute top-0 left-0 aspect-video h-full w-full" src="https://www.youtube.com/embed/Sr-3K8r-nE0" title="Westfalia Kelsey - Vorstellungs- und Erklärvideo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                                                      </div>
                                                    </div>
                                                  </div>
                                              </div>
                                              <div className="flex flex-col justify-center">
                                                  <div class="pt-8 2xl:pt-12 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                                      <div class="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">In this short room tour, Sina introduces you to all the highlights of the Beach.</div>
                                                  </div>
                                              </div>
                                          </div>
                                    </div>
                                </details>

                                <details className="group border-b border-b-grey  dark:border-b-grey-dark">
                                    <summary className="flex cursor-pointer items-center justify-between py-4 md:py-8">
                                      <h2 className="whitespace-pre-line break-words text-lg 2xl:text-xl">
                                      Interior
                                      </h2>
                                      <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-10 md:child:w-10 2xl:child:h-12 2xl:child:w-12">
                                        <svg
                                          width="32"
                                          height="32"
                                          viewBox="0 0 32 32"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g fill="none" fillRule="evenodd">
                                            <path
                                              d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                              fill="var(--svg-color-secondary, #163435)"
                                            ></path>
                                          </g>
                                        </svg>
                                      </div>
                                    </summary>
                                    <div className="mb-8">
                                          <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12">
                                              <div className="flex flex-col justify-center">
                                                  <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                                                    <div className="relative mx-auto max-w-3xl">
                                                      <div className="mask-image relative h-0 w-full overflow-hidden rounded bg-off-white pt-[56.25%] dark:bg-off-black-dark">
                                                      
                                                      <iframe className="absolute top-0 left-0 aspect-video h-full w-full" src="https://www.youtube.com/embed/Sr-3K8r-nE0" title="Westfalia Kelsey - Vorstellungs- und Erklärvideo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                                                      </div>
                                                    </div>
                                                  </div>
                                              </div>
                                              <div className="flex flex-col justify-center">
                                                  <div class="pt-8 2xl:pt-12 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                                      <div class="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">In this short room tour, Sina introduces you to all the highlights of the Beach.</div>
                                                  </div>
                                              </div>
                                          </div>
                                    </div>
                                </details>


                                <details className="group border-b border-b-grey  dark:border-b-grey-dark">
                                    <summary className="flex cursor-pointer items-center justify-between py-4 md:py-8">
                                      <h2 className="whitespace-pre-line break-words text-lg 2xl:text-xl">
                                      Stove & handling of the gas bottle
                                      </h2>
                                      <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-10 md:child:w-10 2xl:child:h-12 2xl:child:w-12">
                                        <svg
                                          width="32"
                                          height="32"
                                          viewBox="0 0 32 32"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g fill="none" fillRule="evenodd">
                                            <path
                                              d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                              fill="var(--svg-color-secondary, #163435)"
                                            ></path>
                                          </g>
                                        </svg>
                                      </div>
                                    </summary>
                                    <div className="mb-8">
                                          <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12">
                                              <div className="flex flex-col justify-center">
                                                  <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                                                    <div className="relative mx-auto max-w-3xl">
                                                      <div className="mask-image relative h-0 w-full overflow-hidden rounded bg-off-white pt-[56.25%] dark:bg-off-black-dark">
                                                      
                                                      <iframe className="absolute top-0 left-0 aspect-video h-full w-full" src="https://www.youtube.com/embed/Sr-3K8r-nE0" title="Westfalia Kelsey - Vorstellungs- und Erklärvideo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                                                      </div>
                                                    </div>
                                                  </div>
                                              </div>
                                              <div className="flex flex-col justify-center">
                                                  <div class="pt-8 2xl:pt-12 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                                      <div class="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">In this short room tour, Sina introduces you to all the highlights of the Beach.</div>
                                                  </div>
                                              </div>
                                          </div>
                                    </div>
                                </details>


                                <details className="group border-b border-b-grey  dark:border-b-grey-dark">
                                    <summary className="flex cursor-pointer items-center justify-between py-4 md:py-8">
                                      <h2 className="whitespace-pre-line break-words text-lg 2xl:text-xl">
                                      Cool boxes
                                      </h2>
                                      <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-10 md:child:w-10 2xl:child:h-12 2xl:child:w-12">
                                        <svg
                                          width="32"
                                          height="32"
                                          viewBox="0 0 32 32"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g fill="none" fillRule="evenodd">
                                            <path
                                              d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                              fill="var(--svg-color-secondary, #163435)"
                                            ></path>
                                          </g>
                                        </svg>
                                      </div>
                                    </summary>
                                    <div className="mb-8">
                                          <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12">
                                              <div className="flex flex-col justify-center">
                                                  <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                                                    <div className="relative mx-auto max-w-3xl">
                                                      <div className="mask-image relative h-0 w-full overflow-hidden rounded bg-off-white pt-[56.25%] dark:bg-off-black-dark">
                                                      
                                                      <iframe className="absolute top-0 left-0 aspect-video h-full w-full" src="https://www.youtube.com/embed/Sr-3K8r-nE0" title="Westfalia Kelsey - Vorstellungs- und Erklärvideo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                                                      </div>
                                                    </div>
                                                  </div>
                                              </div>
                                              <div className="flex flex-col justify-center">
                                                  <div class="pt-8 2xl:pt-12 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                                      <div class="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">In this short room tour, Sina introduces you to all the highlights of the Beach.</div>
                                                  </div>
                                              </div>
                                          </div>
                                    </div>
                                </details>


                                </div>
                              </div>
                              </div> 
                            </div>             
                      </div>

                </TabPanel>
                <TabPanel>

                  
                <div className="overflow-hidden bg-white p-6 dark:bg-off-black -ml-5 min-h-[40px] w-screen rounded-none py-0 2xs:rounded-none lg:ml-0 lg:w-auto 2xs:rounded-lg lg:px-12">
                            <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                              <div className="overflow-hidden rounded relative mx-auto p-0 w-full bg-white dark:border-gray-dark dark:bg-off-black">
                              <div className="pt-0 pb-8 2xl:pb-12">
                                <div className="relative mx-auto max-w-6xl">

                                <details className="group border-b border-b-grey  dark:border-b-grey-dark">
                                    <summary className="flex cursor-pointer items-center justify-between py-4 md:py-8">
                                      <h2 className="whitespace-pre-line break-words text-lg 2xl:text-xl">
                                      Room-Tour
                                      </h2>
                                      <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-10 md:child:w-10 2xl:child:h-12 2xl:child:w-12">
                                        <svg
                                          width="32"
                                          height="32"
                                          viewBox="0 0 32 32"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g fill="none" fillRule="evenodd">
                                            <path
                                              d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                              fill="var(--svg-color-secondary, #163435)"
                                            ></path>
                                          </g>
                                        </svg>
                                      </div>
                                    </summary>
                                    <div className="mb-8">
                                          <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12">
                                              <div className="flex flex-col justify-center">
                                                  <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                                                    <div className="relative mx-auto max-w-3xl">
                                                      <div className="mask-image relative h-0 w-full overflow-hidden rounded bg-off-white pt-[56.25%] dark:bg-off-black-dark">
                                                      
                                                      <iframe
                                      className="absolute top-0 left-0 aspect-video h-full w-full"
                                      src="https://www.youtube.com/embed/Rv8gTE9uDNM?si=yfRiT0dETCxDLaUn"
                                      frameborder="0"
                                    ></iframe>

                                                      </div>
                                                    </div>
                                                  </div>
                                              </div>
                                              <div className="flex flex-col justify-center">
                                                  <div class="pt-8 2xl:pt-12 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                                      <div class="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">In this short room tour, Sina introduces you to all the highlights of the Beach.</div>
                                                  </div>
                                              </div>
                                          </div>
                                    </div>
                                </details>

                                <details className="group border-b border-b-grey  dark:border-b-grey-dark">
                                    <summary className="flex cursor-pointer items-center justify-between py-4 md:py-8">
                                      <h2 className="whitespace-pre-line break-words text-lg 2xl:text-xl">
                                      Interior
                                      </h2>
                                      <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-10 md:child:w-10 2xl:child:h-12 2xl:child:w-12">
                                        <svg
                                          width="32"
                                          height="32"
                                          viewBox="0 0 32 32"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g fill="none" fillRule="evenodd">
                                            <path
                                              d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                              fill="var(--svg-color-secondary, #163435)"
                                            ></path>
                                          </g>
                                        </svg>
                                      </div>
                                    </summary>
                                    <div className="mb-8">
                                          <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12">
                                              <div className="flex flex-col justify-center">
                                                  <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                                                    <div className="relative mx-auto max-w-3xl">
                                                      <div className="mask-image relative h-0 w-full overflow-hidden rounded bg-off-white pt-[56.25%] dark:bg-off-black-dark">
                                                      
                                                      <iframe
                                      className="absolute top-0 left-0 aspect-video h-full w-full"
                                      src="https://www.youtube.com/embed/Rv8gTE9uDNM?si=yfRiT0dETCxDLaUn"
                                      frameborder="0"
                                    ></iframe>

                                                      </div>
                                                    </div>
                                                  </div>
                                              </div>
                                              <div className="flex flex-col justify-center">
                                                  <div class="pt-8 2xl:pt-12 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                                      <div class="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">In this short room tour, Sina introduces you to all the highlights of the Beach.</div>
                                                  </div>
                                              </div>
                                          </div>
                                    </div>
                                </details>


                                <details className="group border-b border-b-grey  dark:border-b-grey-dark">
                                    <summary className="flex cursor-pointer items-center justify-between py-4 md:py-8">
                                      <h2 className="whitespace-pre-line break-words text-lg 2xl:text-xl">
                                      Stove & handling of the gas bottle
                                      </h2>
                                      <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-10 md:child:w-10 2xl:child:h-12 2xl:child:w-12">
                                        <svg
                                          width="32"
                                          height="32"
                                          viewBox="0 0 32 32"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g fill="none" fillRule="evenodd">
                                            <path
                                              d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                              fill="var(--svg-color-secondary, #163435)"
                                            ></path>
                                          </g>
                                        </svg>
                                      </div>
                                    </summary>
                                    <div className="mb-8">
                                          <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12">
                                              <div className="flex flex-col justify-center">
                                                  <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                                                    <div className="relative mx-auto max-w-3xl">
                                                      <div className="mask-image relative h-0 w-full overflow-hidden rounded bg-off-white pt-[56.25%] dark:bg-off-black-dark">
                                                      
                                                      <iframe
                                      className="absolute top-0 left-0 aspect-video h-full w-full"
                                      src="https://www.youtube.com/embed/Rv8gTE9uDNM?si=yfRiT0dETCxDLaUn"
                                      frameborder="0"
                                    ></iframe>

                                                      </div>
                                                    </div>
                                                  </div>
                                              </div>
                                              <div className="flex flex-col justify-center">
                                                  <div class="pt-8 2xl:pt-12 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                                      <div class="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">In this short room tour, Sina introduces you to all the highlights of the Beach.</div>
                                                  </div>
                                              </div>
                                          </div>
                                    </div>
                                </details>


                                <details className="group border-b border-b-grey  dark:border-b-grey-dark">
                                    <summary className="flex cursor-pointer items-center justify-between py-4 md:py-8">
                                      <h2 className="whitespace-pre-line break-words text-lg 2xl:text-xl">
                                      Cool boxes
                                      </h2>
                                      <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-10 md:child:w-10 2xl:child:h-12 2xl:child:w-12">
                                        <svg
                                          width="32"
                                          height="32"
                                          viewBox="0 0 32 32"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g fill="none" fillRule="evenodd">
                                            <path
                                              d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                              fill="var(--svg-color-secondary, #163435)"
                                            ></path>
                                          </g>
                                        </svg>
                                      </div>
                                    </summary>
                                    <div className="mb-8">
                                          <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12">
                                              <div className="flex flex-col justify-center">
                                                  <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                                                    <div className="relative mx-auto max-w-3xl">
                                                      <div className="mask-image relative h-0 w-full overflow-hidden rounded bg-off-white pt-[56.25%] dark:bg-off-black-dark">
                                                      
                                                      <iframe
                                      className="absolute top-0 left-0 aspect-video h-full w-full"
                                      src="https://www.youtube.com/embed/Rv8gTE9uDNM?si=yfRiT0dETCxDLaUn"
                                      frameborder="0"
                                    ></iframe>

                                                      </div>
                                                    </div>
                                                  </div>
                                              </div>
                                              <div className="flex flex-col justify-center">
                                                  <div class="pt-8 2xl:pt-12 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                                      <div class="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">In this short room tour, Sina introduces you to all the highlights of the Beach.</div>
                                                  </div>
                                              </div>
                                          </div>
                                    </div>
                                </details>


                                </div>
                              </div>
                              </div> 
                            </div>             
                      </div>

                </TabPanel>
                <TabPanel>

                <div className="overflow-hidden bg-white p-6 dark:bg-off-black -ml-5 min-h-[40px] w-screen rounded-none py-0 2xs:rounded-none lg:ml-0 lg:w-auto 2xs:rounded-lg lg:px-12">
                            <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                              <div className="overflow-hidden rounded relative mx-auto p-0 w-full bg-white dark:border-gray-dark dark:bg-off-black">
                              <div className="pt-0 pb-8 2xl:pb-12">
                                <div className="relative mx-auto max-w-6xl">

                                <details className="group border-b border-b-grey  dark:border-b-grey-dark">
                                    <summary className="flex cursor-pointer items-center justify-between py-4 md:py-8">
                                      <h2 className="whitespace-pre-line break-words text-lg 2xl:text-xl">
                                      Room-Tour
                                      </h2>
                                      <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-10 md:child:w-10 2xl:child:h-12 2xl:child:w-12">
                                        <svg
                                          width="32"
                                          height="32"
                                          viewBox="0 0 32 32"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g fill="none" fillRule="evenodd">
                                            <path
                                              d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                              fill="var(--svg-color-secondary, #163435)"
                                            ></path>
                                          </g>
                                        </svg>
                                      </div>
                                    </summary>
                                    <div className="mb-8">
                                          <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12">
                                              <div className="flex flex-col justify-center">
                                                  <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                                                    <div className="relative mx-auto max-w-3xl">
                                                      <div className="mask-image relative h-0 w-full overflow-hidden rounded bg-off-white pt-[56.25%] dark:bg-off-black-dark">
                                                      
                                                      <iframe
                      className="absolute top-0 left-0 aspect-video h-full w-full"
                      src="https://www.youtube.com/embed/Rv8gTE9uDNM?si=yfRiT0dETCxDLaUn"
                      frameborder="0"
                    ></iframe>

                                                      </div>
                                                    </div>
                                                  </div>
                                              </div>
                                              <div className="flex flex-col justify-center">
                                                  <div class="pt-8 2xl:pt-12 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                                      <div class="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">In this short room tour, Sina introduces you to all the highlights of the Beach.</div>
                                                  </div>
                                              </div>
                                          </div>
                                    </div>
                                </details>

                                <details className="group border-b border-b-grey  dark:border-b-grey-dark">
                                    <summary className="flex cursor-pointer items-center justify-between py-4 md:py-8">
                                      <h2 className="whitespace-pre-line break-words text-lg 2xl:text-xl">
                                      Interior
                                      </h2>
                                      <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-10 md:child:w-10 2xl:child:h-12 2xl:child:w-12">
                                        <svg
                                          width="32"
                                          height="32"
                                          viewBox="0 0 32 32"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g fill="none" fillRule="evenodd">
                                            <path
                                              d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                              fill="var(--svg-color-secondary, #163435)"
                                            ></path>
                                          </g>
                                        </svg>
                                      </div>
                                    </summary>
                                    <div className="mb-8">
                                          <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12">
                                              <div className="flex flex-col justify-center">
                                                  <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                                                    <div className="relative mx-auto max-w-3xl">
                                                      <div className="mask-image relative h-0 w-full overflow-hidden rounded bg-off-white pt-[56.25%] dark:bg-off-black-dark">
                                                      
                                                      <iframe
                      className="absolute top-0 left-0 aspect-video h-full w-full"
                      src="https://www.youtube.com/embed/Rv8gTE9uDNM?si=yfRiT0dETCxDLaUn"
                      frameborder="0"
                    ></iframe>

                                                      </div>
                                                    </div>
                                                  </div>
                                              </div>
                                              <div className="flex flex-col justify-center">
                                                  <div class="pt-8 2xl:pt-12 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                                      <div class="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">In this short room tour, Sina introduces you to all the highlights of the Beach.</div>
                                                  </div>
                                              </div>
                                          </div>
                                    </div>
                                </details>


                                <details className="group border-b border-b-grey  dark:border-b-grey-dark">
                                    <summary className="flex cursor-pointer items-center justify-between py-4 md:py-8">
                                      <h2 className="whitespace-pre-line break-words text-lg 2xl:text-xl">
                                      Stove & handling of the gas bottle
                                      </h2>
                                      <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-10 md:child:w-10 2xl:child:h-12 2xl:child:w-12">
                                        <svg
                                          width="32"
                                          height="32"
                                          viewBox="0 0 32 32"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g fill="none" fillRule="evenodd">
                                            <path
                                              d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                              fill="var(--svg-color-secondary, #163435)"
                                            ></path>
                                          </g>
                                        </svg>
                                      </div>
                                    </summary>
                                    <div className="mb-8">
                                          <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12">
                                              <div className="flex flex-col justify-center">
                                                  <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                                                    <div className="relative mx-auto max-w-3xl">
                                                      <div className="mask-image relative h-0 w-full overflow-hidden rounded bg-off-white pt-[56.25%] dark:bg-off-black-dark">
                                                      
                                                      <iframe
                      className="absolute top-0 left-0 aspect-video h-full w-full"
                      src="https://www.youtube.com/embed/Rv8gTE9uDNM?si=yfRiT0dETCxDLaUn"
                      frameborder="0"
                    ></iframe>

                                                      </div>
                                                    </div>
                                                  </div>
                                              </div>
                                              <div className="flex flex-col justify-center">
                                                  <div class="pt-8 2xl:pt-12 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                                      <div class="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">In this short room tour, Sina introduces you to all the highlights of the Beach.</div>
                                                  </div>
                                              </div>
                                          </div>
                                    </div>
                                </details>


                                <details className="group border-b border-b-grey  dark:border-b-grey-dark">
                                    <summary className="flex cursor-pointer items-center justify-between py-4 md:py-8">
                                      <h2 className="whitespace-pre-line break-words text-lg 2xl:text-xl">
                                      Cool boxes
                                      </h2>
                                      <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-10 md:child:w-10 2xl:child:h-12 2xl:child:w-12">
                                        <svg
                                          width="32"
                                          height="32"
                                          viewBox="0 0 32 32"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g fill="none" fillRule="evenodd">
                                            <path
                                              d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                              fill="var(--svg-color-secondary, #163435)"
                                            ></path>
                                          </g>
                                        </svg>
                                      </div>
                                    </summary>
                                    <div className="mb-8">
                                          <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12">
                                              <div className="flex flex-col justify-center">
                                                  <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                                                    <div className="relative mx-auto max-w-3xl">
                                                      <div className="mask-image relative h-0 w-full overflow-hidden rounded bg-off-white pt-[56.25%] dark:bg-off-black-dark">
                                                      
                                                      <iframe
                      className="absolute top-0 left-0 aspect-video h-full w-full"
                      src="https://www.youtube.com/embed/Rv8gTE9uDNM?si=yfRiT0dETCxDLaUn"
                      frameborder="0"
                    ></iframe>

                                                      </div>
                                                    </div>
                                                  </div>
                                              </div>
                                              <div className="flex flex-col justify-center">
                                                  <div class="pt-8 2xl:pt-12 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                                      <div class="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">In this short room tour, Sina introduces you to all the highlights of the Beach.</div>
                                                  </div>
                                              </div>
                                          </div>
                                    </div>
                                </details>


                                </div>
                              </div>
                              </div> 
                            </div>             
                      </div>

                </TabPanel>

                <TabPanel>

                <div className="overflow-hidden bg-white p-6 dark:bg-off-black -ml-5 min-h-[40px] w-screen rounded-none py-0 2xs:rounded-none lg:ml-0 lg:w-auto 2xs:rounded-lg lg:px-12">
                            <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                              <div className="overflow-hidden rounded relative mx-auto p-0 w-full bg-white dark:border-gray-dark dark:bg-off-black">
                              <div className="pt-0 pb-8 2xl:pb-12">
                                <div className="relative mx-auto max-w-6xl">

                                <details className="group border-b border-b-grey  dark:border-b-grey-dark">
                                    <summary className="flex cursor-pointer items-center justify-between py-4 md:py-8">
                                      <h2 className="whitespace-pre-line break-words text-lg 2xl:text-xl">
                                      Room-Tour
                                      </h2>
                                      <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-10 md:child:w-10 2xl:child:h-12 2xl:child:w-12">
                                        <svg
                                          width="32"
                                          height="32"
                                          viewBox="0 0 32 32"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g fill="none" fillRule="evenodd">
                                            <path
                                              d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                              fill="var(--svg-color-secondary, #163435)"
                                            ></path>
                                          </g>
                                        </svg>
                                      </div>
                                    </summary>
                                    <div className="mb-8">
                                          <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12">
                                              <div className="flex flex-col justify-center">
                                                  <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                                                    <div className="relative mx-auto max-w-3xl">
                                                      <div className="mask-image relative h-0 w-full overflow-hidden rounded bg-off-white pt-[56.25%] dark:bg-off-black-dark">
                                                      
                                                      <iframe
                      className="absolute top-0 left-0 aspect-video h-full w-full"
                      src="https://www.youtube.com/embed/Rv8gTE9uDNM?si=yfRiT0dETCxDLaUn"
                      frameborder="0"
                    ></iframe>
                 

                                                      </div>
                                                    </div>
                                                  </div>
                                              </div>
                                              <div className="flex flex-col justify-center">
                                                  <div class="pt-8 2xl:pt-12 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                                      <div class="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">In this short room tour, Sina introduces you to all the highlights of the Beach.</div>
                                                  </div>
                                              </div>
                                          </div>
                                    </div>
                                </details>

                                <details className="group border-b border-b-grey  dark:border-b-grey-dark">
                                    <summary className="flex cursor-pointer items-center justify-between py-4 md:py-8">
                                      <h2 className="whitespace-pre-line break-words text-lg 2xl:text-xl">
                                      Interior
                                      </h2>
                                      <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-10 md:child:w-10 2xl:child:h-12 2xl:child:w-12">
                                        <svg
                                          width="32"
                                          height="32"
                                          viewBox="0 0 32 32"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g fill="none" fillRule="evenodd">
                                            <path
                                              d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                              fill="var(--svg-color-secondary, #163435)"
                                            ></path>
                                          </g>
                                        </svg>
                                      </div>
                                    </summary>
                                    <div className="mb-8">
                                          <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12">
                                              <div className="flex flex-col justify-center">
                                                  <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                                                    <div className="relative mx-auto max-w-3xl">
                                                      <div className="mask-image relative h-0 w-full overflow-hidden rounded bg-off-white pt-[56.25%] dark:bg-off-black-dark">
                                                      
                                                      <iframe
                      className="absolute top-0 left-0 aspect-video h-full w-full"
                      src="https://www.youtube.com/embed/Rv8gTE9uDNM?si=yfRiT0dETCxDLaUn"
                      frameborder="0"
                    ></iframe>
                 

                                                      </div>
                                                    </div>
                                                  </div>
                                              </div>
                                              <div className="flex flex-col justify-center">
                                                  <div class="pt-8 2xl:pt-12 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                                      <div class="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">In this short room tour, Sina introduces you to all the highlights of the Beach.</div>
                                                  </div>
                                              </div>
                                          </div>
                                    </div>
                                </details>


                                <details className="group border-b border-b-grey  dark:border-b-grey-dark">
                                    <summary className="flex cursor-pointer items-center justify-between py-4 md:py-8">
                                      <h2 className="whitespace-pre-line break-words text-lg 2xl:text-xl">
                                      Stove & handling of the gas bottle
                                      </h2>
                                      <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-10 md:child:w-10 2xl:child:h-12 2xl:child:w-12">
                                        <svg
                                          width="32"
                                          height="32"
                                          viewBox="0 0 32 32"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g fill="none" fillRule="evenodd">
                                            <path
                                              d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                              fill="var(--svg-color-secondary, #163435)"
                                            ></path>
                                          </g>
                                        </svg>
                                      </div>
                                    </summary>
                                    <div className="mb-8">
                                          <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12">
                                              <div className="flex flex-col justify-center">
                                                  <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                                                    <div className="relative mx-auto max-w-3xl">
                                                      <div className="mask-image relative h-0 w-full overflow-hidden rounded bg-off-white pt-[56.25%] dark:bg-off-black-dark">
                                                      
                                                      <iframe
                      className="absolute top-0 left-0 aspect-video h-full w-full"
                      src="https://www.youtube.com/embed/Rv8gTE9uDNM?si=yfRiT0dETCxDLaUn"
                      frameborder="0"
                    ></iframe>
                 

                                                      </div>
                                                    </div>
                                                  </div>
                                              </div>
                                              <div className="flex flex-col justify-center">
                                                  <div class="pt-8 2xl:pt-12 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                                      <div class="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">In this short room tour, Sina introduces you to all the highlights of the Beach.</div>
                                                  </div>
                                              </div>
                                          </div>
                                    </div>
                                </details>


                                <details className="group border-b border-b-grey  dark:border-b-grey-dark">
                                    <summary className="flex cursor-pointer items-center justify-between py-4 md:py-8">
                                      <h2 className="whitespace-pre-line break-words text-lg 2xl:text-xl">
                                      Cool boxes
                                      </h2>
                                      <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-10 md:child:w-10 2xl:child:h-12 2xl:child:w-12">
                                        <svg
                                          width="32"
                                          height="32"
                                          viewBox="0 0 32 32"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g fill="none" fillRule="evenodd">
                                            <path
                                              d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                              fill="var(--svg-color-secondary, #163435)"
                                            ></path>
                                          </g>
                                        </svg>
                                      </div>
                                    </summary>
                                    <div className="mb-8">
                                          <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12">
                                              <div className="flex flex-col justify-center">
                                                  <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                                                    <div className="relative mx-auto max-w-3xl">
                                                      <div className="mask-image relative h-0 w-full overflow-hidden rounded bg-off-white pt-[56.25%] dark:bg-off-black-dark">
                                                      
                                                      <iframe
                      className="absolute top-0 left-0 aspect-video h-full w-full"
                      src="https://www.youtube.com/embed/Rv8gTE9uDNM?si=yfRiT0dETCxDLaUn"
                      frameborder="0"
                    ></iframe>
                 

                                                      </div>
                                                    </div>
                                                  </div>
                                              </div>
                                              <div className="flex flex-col justify-center">
                                                  <div class="pt-8 2xl:pt-12 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                                      <div class="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">In this short room tour, Sina introduces you to all the highlights of the Beach.</div>
                                                  </div>
                                              </div>
                                          </div>
                                    </div>
                                </details>


                                </div>
                              </div>
                              </div> 
                            </div>             
                      </div>


</TabPanel>

<TabPanel>
<div className="overflow-hidden bg-white p-6 dark:bg-off-black -ml-5 min-h-[40px] w-screen rounded-none py-0 2xs:rounded-none lg:ml-0 lg:w-auto 2xs:rounded-lg lg:px-12">
                            <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                              <div className="overflow-hidden rounded relative mx-auto p-0 w-full bg-white dark:border-gray-dark dark:bg-off-black">
                              <div className="pt-0 pb-8 2xl:pb-12">
                                <div className="relative mx-auto max-w-6xl">

                                <details className="group border-b border-b-grey  dark:border-b-grey-dark">
                                    <summary className="flex cursor-pointer items-center justify-between py-4 md:py-8">
                                      <h2 className="whitespace-pre-line break-words text-lg 2xl:text-xl">
                                      Room-Tour
                                      </h2>
                                      <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-10 md:child:w-10 2xl:child:h-12 2xl:child:w-12">
                                        <svg
                                          width="32"
                                          height="32"
                                          viewBox="0 0 32 32"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g fill="none" fillRule="evenodd">
                                            <path
                                              d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                              fill="var(--svg-color-secondary, #163435)"
                                            ></path>
                                          </g>
                                        </svg>
                                      </div>
                                    </summary>
                                    <div className="mb-8">
                                          <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12">
                                              <div className="flex flex-col justify-center">
                                                  <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                                                    <div className="relative mx-auto max-w-3xl">
                                                      <div className="mask-image relative h-0 w-full overflow-hidden rounded bg-off-white pt-[56.25%] dark:bg-off-black-dark">
                                                      
                                                      <iframe
                      className="absolute top-0 left-0 aspect-video h-full w-full"
                      src="https://www.youtube.com/embed/Rv8gTE9uDNM?si=yfRiT0dETCxDLaUn"
                      frameborder="0"
                    ></iframe>
                 

                                                      </div>
                                                    </div>
                                                  </div>
                                              </div>
                                              <div className="flex flex-col justify-center">
                                                  <div class="pt-8 2xl:pt-12 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                                      <div class="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">In this short room tour, Sina introduces you to all the highlights of the Beach.</div>
                                                  </div>
                                              </div>
                                          </div>
                                    </div>
                                </details>

                                <details className="group border-b border-b-grey  dark:border-b-grey-dark">
                                    <summary className="flex cursor-pointer items-center justify-between py-4 md:py-8">
                                      <h2 className="whitespace-pre-line break-words text-lg 2xl:text-xl">
                                      Interior
                                      </h2>
                                      <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-10 md:child:w-10 2xl:child:h-12 2xl:child:w-12">
                                        <svg
                                          width="32"
                                          height="32"
                                          viewBox="0 0 32 32"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g fill="none" fillRule="evenodd">
                                            <path
                                              d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                              fill="var(--svg-color-secondary, #163435)"
                                            ></path>
                                          </g>
                                        </svg>
                                      </div>
                                    </summary>
                                    <div className="mb-8">
                                          <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12">
                                              <div className="flex flex-col justify-center">
                                                  <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                                                    <div className="relative mx-auto max-w-3xl">
                                                      <div className="mask-image relative h-0 w-full overflow-hidden rounded bg-off-white pt-[56.25%] dark:bg-off-black-dark">
                                                      
                                                      <iframe
                      className="absolute top-0 left-0 aspect-video h-full w-full"
                      src="https://www.youtube.com/embed/Rv8gTE9uDNM?si=yfRiT0dETCxDLaUn"
                      frameborder="0"
                    ></iframe>
                 

                                                      </div>
                                                    </div>
                                                  </div>
                                              </div>
                                              <div className="flex flex-col justify-center">
                                                  <div class="pt-8 2xl:pt-12 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                                      <div class="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">In this short room tour, Sina introduces you to all the highlights of the Beach.</div>
                                                  </div>
                                              </div>
                                          </div>
                                    </div>
                                </details>


                                <details className="group border-b border-b-grey  dark:border-b-grey-dark">
                                    <summary className="flex cursor-pointer items-center justify-between py-4 md:py-8">
                                      <h2 className="whitespace-pre-line break-words text-lg 2xl:text-xl">
                                      Stove & handling of the gas bottle
                                      </h2>
                                      <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-10 md:child:w-10 2xl:child:h-12 2xl:child:w-12">
                                        <svg
                                          width="32"
                                          height="32"
                                          viewBox="0 0 32 32"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g fill="none" fillRule="evenodd">
                                            <path
                                              d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                              fill="var(--svg-color-secondary, #163435)"
                                            ></path>
                                          </g>
                                        </svg>
                                      </div>
                                    </summary>
                                    <div className="mb-8">
                                          <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12">
                                              <div className="flex flex-col justify-center">
                                                  <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                                                    <div className="relative mx-auto max-w-3xl">
                                                      <div className="mask-image relative h-0 w-full overflow-hidden rounded bg-off-white pt-[56.25%] dark:bg-off-black-dark">
                                                      
                                                      <iframe
                      className="absolute top-0 left-0 aspect-video h-full w-full"
                      src="https://www.youtube.com/embed/Rv8gTE9uDNM?si=yfRiT0dETCxDLaUn"
                      frameborder="0"
                    ></iframe>
                 

                                                      </div>
                                                    </div>
                                                  </div>
                                              </div>
                                              <div className="flex flex-col justify-center">
                                                  <div class="pt-8 2xl:pt-12 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                                      <div class="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">In this short room tour, Sina introduces you to all the highlights of the Beach.</div>
                                                  </div>
                                              </div>
                                          </div>
                                    </div>
                                </details>


                                <details className="group border-b border-b-grey  dark:border-b-grey-dark">
                                    <summary className="flex cursor-pointer items-center justify-between py-4 md:py-8">
                                      <h2 className="whitespace-pre-line break-words text-lg 2xl:text-xl">
                                      Cool boxes
                                      </h2>
                                      <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-10 md:child:w-10 2xl:child:h-12 2xl:child:w-12">
                                        <svg
                                          width="32"
                                          height="32"
                                          viewBox="0 0 32 32"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g fill="none" fillRule="evenodd">
                                            <path
                                              d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                              fill="var(--svg-color-secondary, #163435)"
                                            ></path>
                                          </g>
                                        </svg>
                                      </div>
                                    </summary>
                                    <div className="mb-8">
                                          <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12">
                                              <div className="flex flex-col justify-center">
                                                  <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                                                    <div className="relative mx-auto max-w-3xl">
                                                      <div className="mask-image relative h-0 w-full overflow-hidden rounded bg-off-white pt-[56.25%] dark:bg-off-black-dark">
                                                      
                                                      <iframe
                      className="absolute top-0 left-0 aspect-video h-full w-full"
                      src="https://www.youtube.com/embed/Rv8gTE9uDNM?si=yfRiT0dETCxDLaUn"
                      frameborder="0"
                    ></iframe>
                 

                                                      </div>
                                                    </div>
                                                  </div>
                                              </div>
                                              <div className="flex flex-col justify-center">
                                                  <div class="pt-8 2xl:pt-12 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                                      <div class="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">In this short room tour, Sina introduces you to all the highlights of the Beach.</div>
                                                  </div>
                                              </div>
                                          </div>
                                    </div>
                                </details>


                                </div>
                              </div>
                              </div> 
                            </div>             
                      </div>
</TabPanel>

<TabPanel>

<div className="overflow-hidden bg-white p-6 dark:bg-off-black -ml-5 min-h-[40px] w-screen rounded-none py-0 2xs:rounded-none lg:ml-0 lg:w-auto 2xs:rounded-lg lg:px-12">
                            <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                              <div className="overflow-hidden rounded relative mx-auto p-0 w-full bg-white dark:border-gray-dark dark:bg-off-black">
                              <div className="pt-0 pb-8 2xl:pb-12">
                                <div className="relative mx-auto max-w-6xl">

                                <details className="group border-b border-b-grey  dark:border-b-grey-dark">
                                    <summary className="flex cursor-pointer items-center justify-between py-4 md:py-8">
                                      <h2 className="whitespace-pre-line break-words text-lg 2xl:text-xl">
                                      Room-Tour
                                      </h2>
                                      <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-10 md:child:w-10 2xl:child:h-12 2xl:child:w-12">
                                        <svg
                                          width="32"
                                          height="32"
                                          viewBox="0 0 32 32"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g fill="none" fillRule="evenodd">
                                            <path
                                              d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                              fill="var(--svg-color-secondary, #163435)"
                                            ></path>
                                          </g>
                                        </svg>
                                      </div>
                                    </summary>
                                    <div className="mb-8">
                                          <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12">
                                              <div className="flex flex-col justify-center">
                                                  <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                                                    <div className="relative mx-auto max-w-3xl">
                                                      <div className="mask-image relative h-0 w-full overflow-hidden rounded bg-off-white pt-[56.25%] dark:bg-off-black-dark">
                                                      <iframe className="absolute top-0 left-0 aspect-video h-full w-full" src="https://www.youtube.com/embed/I3HBS7i-Xac" title="Glückskarren Vorstellungsvideo Weinsberg CaraCito 470 EU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                 

                                                      </div>
                                                    </div>
                                                  </div>
                                              </div>
                                              <div className="flex flex-col justify-center">
                                                  <div class="pt-8 2xl:pt-12 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                                      <div class="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">In this short room tour, Sina introduces you to all the highlights of the Beach.</div>
                                                  </div>
                                              </div>
                                          </div>
                                    </div>
                                </details>

                                <details className="group border-b border-b-grey  dark:border-b-grey-dark">
                                    <summary className="flex cursor-pointer items-center justify-between py-4 md:py-8">
                                      <h2 className="whitespace-pre-line break-words text-lg 2xl:text-xl">
                                      Interior
                                      </h2>
                                      <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-10 md:child:w-10 2xl:child:h-12 2xl:child:w-12">
                                        <svg
                                          width="32"
                                          height="32"
                                          viewBox="0 0 32 32"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g fill="none" fillRule="evenodd">
                                            <path
                                              d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                              fill="var(--svg-color-secondary, #163435)"
                                            ></path>
                                          </g>
                                        </svg>
                                      </div>
                                    </summary>
                                    <div className="mb-8">
                                          <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12">
                                              <div className="flex flex-col justify-center">
                                                  <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                                                    <div className="relative mx-auto max-w-3xl">
                                                      <div className="mask-image relative h-0 w-full overflow-hidden rounded bg-off-white pt-[56.25%] dark:bg-off-black-dark">
                                                      
                                                      <iframe className="absolute top-0 left-0 aspect-video h-full w-full" src="https://www.youtube.com/embed/I3HBS7i-Xac" title="Glückskarren Vorstellungsvideo Weinsberg CaraCito 470 EU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                 

                                                      </div>
                                                    </div>
                                                  </div>
                                              </div>
                                              <div className="flex flex-col justify-center">
                                                  <div class="pt-8 2xl:pt-12 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                                      <div class="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">In this short room tour, Sina introduces you to all the highlights of the Beach.</div>
                                                  </div>
                                              </div>
                                          </div>
                                    </div>
                                </details>


                                <details className="group border-b border-b-grey  dark:border-b-grey-dark">
                                    <summary className="flex cursor-pointer items-center justify-between py-4 md:py-8">
                                      <h2 className="whitespace-pre-line break-words text-lg 2xl:text-xl">
                                      Stove & handling of the gas bottle
                                      </h2>
                                      <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-10 md:child:w-10 2xl:child:h-12 2xl:child:w-12">
                                        <svg
                                          width="32"
                                          height="32"
                                          viewBox="0 0 32 32"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g fill="none" fillRule="evenodd">
                                            <path
                                              d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                              fill="var(--svg-color-secondary, #163435)"
                                            ></path>
                                          </g>
                                        </svg>
                                      </div>
                                    </summary>
                                    <div className="mb-8">
                                          <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12">
                                              <div className="flex flex-col justify-center">
                                                  <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                                                    <div className="relative mx-auto max-w-3xl">
                                                      <div className="mask-image relative h-0 w-full overflow-hidden rounded bg-off-white pt-[56.25%] dark:bg-off-black-dark">
                                                      
                                                      <iframe className="absolute top-0 left-0 aspect-video h-full w-full" src="https://www.youtube.com/embed/I3HBS7i-Xac" title="Glückskarren Vorstellungsvideo Weinsberg CaraCito 470 EU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                 

                                                      </div>
                                                    </div>
                                                  </div>
                                              </div>
                                              <div className="flex flex-col justify-center">
                                                  <div class="pt-8 2xl:pt-12 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                                      <div class="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">In this short room tour, Sina introduces you to all the highlights of the Beach.</div>
                                                  </div>
                                              </div>
                                          </div>
                                    </div>
                                </details>


                                <details className="group border-b border-b-grey  dark:border-b-grey-dark">
                                    <summary className="flex cursor-pointer items-center justify-between py-4 md:py-8">
                                      <h2 className="whitespace-pre-line break-words text-lg 2xl:text-xl">
                                      Cool boxes
                                      </h2>
                                      <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-10 md:child:w-10 2xl:child:h-12 2xl:child:w-12">
                                        <svg
                                          width="32"
                                          height="32"
                                          viewBox="0 0 32 32"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <g fill="none" fillRule="evenodd">
                                            <path
                                              d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                              fill="var(--svg-color-secondary, #163435)"
                                            ></path>
                                          </g>
                                        </svg>
                                      </div>
                                    </summary>
                                    <div className="mb-8">
                                          <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12">
                                              <div className="flex flex-col justify-center">
                                                  <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                                                    <div className="relative mx-auto max-w-3xl">
                                                      <div className="mask-image relative h-0 w-full overflow-hidden rounded bg-off-white pt-[56.25%] dark:bg-off-black-dark">
                                                      
                                                      <iframe className="absolute top-0 left-0 aspect-video h-full w-full" src="https://www.youtube.com/embed/I3HBS7i-Xac" title="Glückskarren Vorstellungsvideo Weinsberg CaraCito 470 EU" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                 

                                                      </div>
                                                    </div>
                                                  </div>
                                              </div>
                                              <div className="flex flex-col justify-center">
                                                  <div class="pt-8 2xl:pt-12 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                                                      <div class="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">In this short room tour, Sina introduces you to all the highlights of the Beach.</div>
                                                  </div>
                                              </div>
                                          </div>
                                    </div>
                                </details>


                                </div>
                              </div>
                              </div> 
                            </div>             
                      </div>

</TabPanel>
              </Tabs>


                  
                </div>
              </div>
            </div>



            


          </div> 

          <div className="mx-5 max-w-screen-xl xl-padded:mx-auto pt-8 2xl:pt-12 pb-8 2xl:pb-12"> 

          <div className="mx-5 max-w-screen-xl xl-padded:mx-auto">
          <div className="pt-0 pb-0 mx-auto flex max-w-3xl flex-col items-center text-center">
            <p className="mb-4 whitespace-pre-line text-md">
            Model Categories
            </p>
            <h2 className="whitespace-pre-line break-words text-xl md:text-2xl mb-4">
              Camper Van, Travel van, Motorhome hire & Caravan
            </h2>
            
          </div>
          </div>


          <div className="pt-8 2xl:pt-12">
            <div className="mx-5 max-w-screen-xl xl-padded:mx-auto pt-5">
            
              <div className="pt-0 pb-8 2xl:pb-12 grid gap-x-5 sm:grid-cols-2 lg:grid-cols-4">

              <Link to={"/campervan"} className="mb-5">
                  <div className=" overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent group flex h-full flex-col p-4 hover:shadow-elevated hover:dark:shadow-dark-elevated">
                    <div className="mask-image shrink-0 overflow-hidden rounded-sm 2xs:rounded-md">
                      <picture className="text-0 h-full w-full">
                        <img
                          className="inline-block max-w-full object-cover dark:brightness-90 aspect-square max-h-[270px] w-full group-hover:scale-110 transition-all duration-300"
                          src="images/picture camper van model.jpg"
                          alt="picture camper van model"
                        />
                      </picture>
                    </div>
                    <div className="flex h-full flex-col items-center justify-center px-4 text-center">
                      <p className="whitespace-pre-line text-lg mt-4">
                         Rent a Campervan
                      </p>
                      <p className="mt-3 whitespace-pre-line line-clamp-5">
                          Experience the freedom and ease with our compact campervans. Ideal for narrow streets and spontaneous detours. Your perfect companions for flexible and effortless journeys into nature.
                      </p>
                      <div className="mt-auto">
                        <div className="group/button disabled:opacity-50 border border-transparent text-md text-white dark:text-off-white button-primary-bg dark:bg-primary-dark hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated p-1 rounded-full mt-4 inline-block px-4">
                          <div className="flex h-full items-center justify-center">
                            <span className="flex">View Campers</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>

                <Link to={"/campervan"} className="md:mb-5">
                  <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent group flex h-full flex-col p-4 hover:shadow-elevated hover:dark:shadow-dark-elevated">
                    <div className="mask-image shrink-0 overflow-hidden rounded-sm 2xs:rounded-md">
                      <picture className="text-0 h-full w-full">
                        <img
                          className="inline-block max-w-full object-cover dark:brightness-90 aspect-square max-h-[270px] w-full group-hover:scale-110 transition-all duration-300"
                          src="images/picture travel van model-min.jpg"
                          alt="picture travel van model-min"
                        />
                      </picture>
                    </div>
                    <div className="flex h-full flex-col items-center justify-center px-4 text-center">
                      <p className="whitespace-pre-line text-lg mt-4">
                        Van Rental
                      </p>
                      <p className="mt-3 whitespace-pre-line line-clamp-5">
                          Enjoy independence in our vans with standing height, a fully equipped kitchen, and a private bathroom – your mobile home for the journey
                      </p>
                      <div className="mt-auto">
                        <div className="group/button disabled:opacity-50 border border-transparent text-md text-white dark:text-off-white button-primary-bg dark:bg-primary-dark hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated p-1 rounded-full mt-4 inline-block px-4">
                          <div className="flex h-full items-center justify-center">
                            <span className="flex">View Campers</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>

                <Link to={"/campervan"} className="md:mb-5">
                  <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent group flex h-full flex-col p-4 hover:shadow-elevated hover:dark:shadow-dark-elevated">
                    <div className="mask-image shrink-0 overflow-hidden rounded-sm 2xs:rounded-md">
                      <picture className="text-0 h-full w-full">
                        <img
                          className="inline-block max-w-full object-cover dark:brightness-90 aspect-square max-h-[270px] w-full group-hover:scale-110 transition-all duration-300"
                          src="images/davidferk_mooveo.png"
                          alt="davidferk_portugal_t6_beach_sommer"
                        />
                      </picture>
                    </div>
                    <div className="flex h-full flex-col items-center justify-center px-4 text-center">
                      <p className="whitespace-pre-line text-lg mt-4">
                      Motorhome Rental
                      </p>
                      <p className="mt-3 whitespace-pre-line line-clamp-5">
                      Our motorhomes – the perfect choice for comfort and space. Enjoy ample room for your loved ones and all your luggage. Discover the world in your own cozy home on wheels.
                      </p>
                      <div className="mt-auto">
                        <div className="group/button disabled:opacity-50 border border-transparent text-md text-white dark:text-off-white button-primary-bg dark:bg-primary-dark hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated p-1 rounded-full mt-4 inline-block px-4">
                          <div className="flex h-full items-center justify-center">
                            <span className="flex">View Campers</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>

                
                <Link to={"/campervan"} className="md:mb-5">
                  <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent group flex h-full flex-col p-4 hover:shadow-elevated hover:dark:shadow-dark-elevated">
                    <div className="mask-image shrink-0 overflow-hidden rounded-sm 2xs:rounded-md">
                      <picture className="text-0 h-full w-full">
                        <img
                          className="inline-block max-w-full object-cover dark:brightness-90 aspect-square max-h-[270px] w-full group-hover:scale-110 transition-all duration-300"
                          src="images/picture caravan model-min.jpg"
                          alt="picture caravan model-min"
                        />
                      </picture>
                    </div>
                    <div className="flex h-full flex-col items-center justify-center px-4 text-center">
                      <p className="whitespace-pre-line text-lg mt-4">
                        Caravan Rental
                      </p>
                      <p className="mt-3 whitespace-pre-line line-clamp-5">
                      Enjoy the comfort of a large motorhome while having your own car with you at all times. Ideal for a relaxed vacation at a location of your choice.
                      </p>
                      <div className="mt-auto">
                        <div className="group/button disabled:opacity-50 border border-transparent text-md text-white dark:text-off-white button-primary-bg dark:bg-primary-dark hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated p-1 rounded-full mt-4 inline-block px-4">
                          <div className="flex h-full items-center justify-center">
                            <span className="flex">View Campers</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
                </div>
  
            
            </div>
          </div>
          



          <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
            <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mask-image relative p-0 md:flex md:min-h-[400px] md:rounded-xl md:bg-transparent dark:md:bg-transparent justify-end">
            <Link to={"/contactus"} className="w-full md:flex justify-end">
                <div className="md:hidden">
                  <div className="overflow-hidden">
                    <div className="-ml-[5%] w-[110%] overflow-hidden rounded-b-[60%20%] px-[5%]">
                      <div className="relative">
                        <picture className="text-0">
                          <img
                            className="inline-block max-w-full object-cover dark:brightness-90 max-h-[40vh] w-full group-hover/parent:scale-110 transition-all duration-300"
                            src="images/Helpccenter.png"
                            alt="portugal_t6_beach_sommers"
                          />
                        </picture>
                      </div>
                    </div>
                  </div>
                </div>
                <picture className=" text-0 absolute top-0 left-0 z-background hidden h-full w-full transition-all duration-300 group-hover/parent:scale-105 md:block md:group-hover/parent:scale-[103%]">
                  <img
                    className="inline-block max-w-full object-cover dark:brightness-90 h-full w-full"
                    src="images/Helpccenter-ext.jpg"
                    alt="portugal_t6_beach_sommers"
                  />
                </picture>
                <div className="relative -mt-10 mb-4 flex justify-center xs:mb-4 md:hidden">
                  <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark child:h-20 child:w-20">
                    <img src="images/helpcenter.svg" alt="selection-icon" />
                  </div>
                </div>
                <div className="z-0 m-6 mt-0 flex flex-col text-center xs:mt-0 md:m-5 md:mt-5 md:min-h-[calc(100%-theme(spacing.5)*2)] md:w-full md:max-w-1/2 md:rounded-lg md:bg-white md:p-8 md:text-left md:shadow md:dark:bg-off-black-desat xl:max-w-[45%] 2xl:m-9 2xl:mt-9 2xl:min-h-[calc(100%-theme(spacing.9)*2)]">
                  <div className="mb-6 flex items-center justify-center md:justify-start">
                    <div className="relative mr-4 hidden md:block">
                      <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark child:h-16 child:w-16">
                        <img src="images/helpcenter.svg" alt="selection-icon" />
                      </div>
                    </div>
                    <p className="text-lg">Find you answers</p>
                  </div>
                  <h2 className="whitespace-pre-line break-words mb-4 text-xl xs:mb-6">
                    Our Helpcenter
                  </h2>
                  <div className="mb-4 md:mb-6">
                    <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                      Can I take my dog with me? Do I have a kilometre limit? Do
                      I have to pay attention to anything when choosing a
                      camper? In the FAQ you will find the most frequently asked
                      questions about camper rental, subscription, sale and trip
                      planner.
                    </div>
                  </div>
                  <div className="group/button block disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark p-3 pl-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated w-full md:mt-auto">
                    <div className="flex h-full items-center justify-between">
                      <span className="flex">Find answers</span>
                      <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                        <svg width="32" height="32" viewBox="0 0 32 32">
                          <g fill="none" fillRule="evenodd">
                            <path
                              d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                              fill="var(--svg-color-secondary, #fff)"
                            ></path>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          </div>


           



        </div>
      </div>

      <Footer />
    </>
  );
};

export default Explanatoryvideov1;
