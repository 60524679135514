import React, { useState, useEffect, useRef } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

import { Link, useNavigate } from "react-router-dom";
import { NumericFormat } from 'react-number-format';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CamperService from "../../Api/services/CamperService";
import ContactService from '../../Api/services/ContactService';
import HomeService from '../../Api/services/HomeService';
import { useTranslation } from "react-i18next";
const Campersale = () => {
  const { i18n, t } = useTranslation();
  const paragraphRef = useRef(null);
  const saleConatctForm = useRef(null);
  var camperUrl = process.env.REACT_APP_LARAVEL_CAMPER_IMAGE_URL;
  const [location, setLocation] = useState([]);

  const [Error, setError] = useState("");
  const [saleError, setSaleError] = useState("");
  const [nav3, setNav3] = useState(null);
  const [nav4, setNav4] = useState(null);
  const [isFormSubmit, setisFormSubmit] = useState(false);
  const [isFormSubmit2, setisFormSubmit2] = useState(false);

  var imageUrl = process.env.REACT_APP_LARAVEL_CAMPER_IMAGE_URL;
  const [camper, setCamper] = useState([]);
  const navigate = useNavigate();


  useEffect(() => {
    // setNav1(slider1);
    // setNav2(slider2);
    getLocation();

  }, []);

  const getLocation = async () => {
    try {
      let locationList = await HomeService.location().json();
      // console.log(locationList.data);
      setLocation(locationList.data);
    } catch (error) {
      console.log(error);
    }
  };

  const onSubmitForm2 = async (event) => {
    event.preventDefault();
    setError("")
    try {
      var userData = {
        "first_name": event.target.first_name.value,
        "email": event.target.email.value,
        "type": "Sale",
        "lng": i18n.resolvedLanguage

      }
      if (event.target.first_name.value === "") {
        setSaleError(t("Login.The first name field is required."))
      }
      else if (event.target.email.value === "") {
        setSaleError(t("Login.The email field is required."))
      }

      else {
        let responseData = await HomeService.store(userData).json()
        if (responseData.status === true) {

          event.target.reset();

          setisFormSubmit(true)
        }
      }
    } catch (error) {

      if (error.name === 'HTTPError') {
        const errorJson = await error.response.json();
        setSaleError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
      }
    }

  };
  var camperslider = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1273,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };


  var whichCamperSlider = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1273,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };


  var fourrow = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1273,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  };

  const settingsMain1 = {
    slidesToShow: 1,
    infinite: false,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: nav4,
    draggable: false,
    touchThreshold: 0,
    adaptiveHeight: true,
  };

  const settingsThumbs1 = {
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: nav3,
    dots: true,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: "10px",
    arrows: true,
    infinite: false,
    responsive: [
      {
        breakpoint: 1273,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          arrows: false,
          centerMode: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          dots: false,
          centerMode: false,
        },
      },
    ],
  };


  useEffect(() => {
    getCamper();

  }, []);
  // ----------- get Campers  ------------ //
  const getCamper = async () => {
    try {
      let camperList = await CamperService.index().json();

      var responsedata = camperList.data.filter(function (item) {
        return item.camper_type_id == 3;
      });
      var statusdata = responsedata.filter((element) => { return element.status === 1 })
      setCamper(statusdata);

    } catch (error) {
      console.log(error);
    }
  };

  const onSubmitForm = async (event) => {

    event.preventDefault();

    try {
      var userData = {
        firstName: event.target.firstName.value,
        camper_id: '',
        email: event.target.email.value,
        phone: event.target.phone.value,
        reasonRequest: '',
        order_id: "",
        message: event.target.message.value,
        type: "Sale",
        "lng": i18n.resolvedLanguage
      }
      if (event.target.firstName.value === "") {
        setError(t("Login.The first name field is required."))
      }
      else if (event.target.email.value === "") {
        setError(t("Login.The email field is required."))
      }
      else if (event.target.phone.value === "") {
        setError(t("Login.The phone field is required."))
      }
      else {
        let responseData = await ContactService.store(userData).json()
        if (responseData.status === true) {
          setError("")
          setisFormSubmit2(true)

        }
      }
    } catch (error) {
      if (error.name === 'HTTPError') {
        const errorJson = await error.response.json();
        setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
      }
    }





  };
  return (

    <>
      <Header />

      <div className="min-h-screen bg-off-white">

        <div className="text-center">
          <div className="overflow-hidden">
            <div className="-ml-[5%] w-[110%] overflow-hidden rounded-b-[60%20%] px-[5%]">
              <div className="relative">
                <div className="overflow-hidden">
                  <img
                    src="title-images/camper-sale-new-title-picture.jpg"
                    alt="jamy_grand_california"
                    className="absolute max-h-full w-full scale-110 object-cover blur-lg dark:brightness-90 sm:aspect-hero-regular 3xl:aspect-hero-long aspect-square"
                  />
                </div>
                <picture className="flex max-h-[60vh] w-full text-0">
                  <img
                    className="z-0 max-h-full w-full object-cover dark:brightness-90 sm:aspect-hero-regular 3xl:aspect-hero-long aspect-square"
                    src="title-images/camper-sale-new-title-picture.jpg"
                    alt="verkauf-campersale"
                    width="2552"
                    height="1286"
                  />
                </picture>
                <div className="absolute top-0 left-0 flex h-full w-full flex-col items-center justify-end">
                  <div className="pb-12 w-full bg-gradient-to-t from-black-pure/80 to-transparent pt-20 md:pt-14 xl:pt-32">
                    <h1 className="whitespace-pre-line break-words m-auto max-w-4xl px-8 text-xl md:text-2xl text-white drop-shadow-text dark:text-off-white xs:text-2xl xl:text-3xl mb-5 md:mb-8 xl:mb-12">
                      {t("sale.buyacamper")}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark relative mx-auto -mt-12 child:h-24 child:w-24 2xl:-mt-14 2xl:child:h-28 2xl:child:w-28">
            <img className="" src="images/sales-icon.svg" alt="sales-icon" />
          </div>
        </div>

        <div className="mx-5 max-w-screen-xl xl-padded:mx-auto">
          <div className="pt-8 2xl:pt-8 pb-0 mx-auto flex max-w-5xl flex-col items-center text-center">
            <p className="mb-4 whitespace-pre-line text-md">
              {t("sale.Be flexible all year round!")}
            </p>
            <h2 className="whitespace-pre-line break-words text-xl md:text-2xl mb-4 mobile-br">
              {t("sale.It's not just about a vehicle.")}<br />  {t("sale.It's about a way of life.")}
            </h2>
            <p className="br-sm whitespace-pre-line text-2md font-normal md:text-lg-paragraph md:font-normal price-font text-20">
              {t("sale.Occasionally, we sell one of our beloved campers!")}<br /> {t("sale.Fair prices")} <br /> {t("sale.and top condition guaranteed!")}
            </p>
            <p className="mobile-none whitespace-pre-line text-2md font-normal md:text-lg-paragraph md:font-normal price-font text-20">
              {t("sale.And the best part? Test the camper risk-free during a vacation.")}<br /> {t("sale.Explore, enjoy, then decide!")}
            </p>

          </div>

          <div className="flex justify-center pt-4 2xl:pt-8">
            <button onClick={() =>
              window.scrollTo({
                top: paragraphRef.current.offsetTop - 110,
                behavior: "smooth"
              })
            }
              className="group/button disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark p-3 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated pl-3 py-2 inline-block bg-green-bt"
            >
              <div className="flex h-full items-center justify-between">

                <span className="flex ml-2">{t("sale.View Campers")}</span>
                <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g fill="none" fillRule="evenodd">
                      <path
                        d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                        fill="var(--svg-color-secondary, #163435)"
                      ></path>
                    </g>
                  </svg>
                </div>
              </div>
            </button>
          </div>


        </div>

      </div>

      <div className="mx-5 mt-6 md:mt-12 arrow-white bg-primary pt-4 2xl:pt-4 2xl:pb-4 max-w-screen-xl xl:mx-auto bg-primary rounded-lg pb-5">



        <div className="pt-0 2xl:pt-4 pb-0 mx-auto flex  flex-col items-center text-center">
          <h2 className="mobile-none whitespace-pre-line break-words text-xl md:text-2xl mb-4 text-orange">
            {t("sale.Buying a camper - Your Dream on Wheels")}
          </h2>
          <h2 className="our-value-text-mobile whitespace-pre-line break-words text-xl md:text-2xl mb-4 text-orange">
            {t("sale.buyacamper")}<br /> {t("sale.Your Dream on Wheels")}
          </h2>
          <p className="max-p-sm whitespace-pre-line text-2md font-normal md:text-lg-paragraph md:font-normal text-white">
            {t("sale.Choosetop-notch")}
          </p>
          <p className="max-p-sm whitespace-pre-line text-2md font-normal md:text-lg-paragraph md:font-normal text-white">
            {t("sale.The choice is in your hands!")}
          </p>

        </div>


        <div className="mx-5">
          <div className="pt-1 2xl:pt-4 pb-0">
            <div className="md:mx-5 mx-0 max-w-screen-xl xl-padded:mx-auto md:pt-5 md:px-4 px-0 md:px-2 md:pb-4 pb-3">
              <Slider className="camper-slider camper-arrow orange-arrow" {...fourrow}>

                <div className="rounded bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-6 2xl:mt-14 2xl:h-[calc(100%-3.5rem)] pt-0 pb-3">
                  <div className="flex flex-col justify-between h-full">
                    <div>
                      <div className="flex justify-center align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-12 mb-2 md:mb-5 child:h-24 child:w-24 2xl:-mt-14 2xl:child:h-28 2xl:child:w-28">
                        <img
                          src="images/services-svg-2.svg"
                          alt="services-icon"
                        />
                      </div>
                      <h3 className="mb-1 md:mb-3 whitespace-pre-line text-center text-lg">
                        {t("sale.Top Models")}
                      </h3>
                      <p className="whitespace-pre-line text-center">
                        {t("sale.First-class, well-maintained campers from first-hand.")}
                      </p>
                    </div>

                    <button onClick={() =>
                      window.scrollTo({
                        top: paragraphRef.current.offsetTop - 110,
                        behavior: "smooth"
                      })
                    }
                      className="view-button group/button block disabled:opacity-50 border border-transparent rounded-full text-md  group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all not-oblique hover:oblique group-hover/parent:oblique cssvar-secondary-to-current bg-white text-secondary hover:bg-white dark:text-tertiary dark:bg-off-black  mb-0 group-[:hover:not(:has(.secondbutton:hover))]:border-secondary dark:group-[:hover:not(:has(.secondbutton:hover))]:border-tertiary">
                      <div className="m-auto">
                        <div className="group/button block disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white button-primary-bg dark:bg-primary-dark p-2 pl-3 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated md:mt-5 w-full">
                          <div className="flex h-full items-center justify-between">
                            <span className="flex text-16"> {t("sale.ViewCamperModels")}</span>
                            <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                              <svg width="32" height="32" viewBox="0 0 32 32">
                                <g fill="none" fillRule="evenodd">
                                  <path d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z" fill="var(--svg-color-secondary, #163435)"></path>
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>

                <div className="rounded bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-6 2xl:mt-14 2xl:h-[calc(100%-3.5rem)] pt-0 pb-3">
                  <div className="flex flex-col justify-between h-full">
                    <div>
                      <div className="flex justify-center align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-12 mb-2 md:mb-5 child:h-24 child:w-24 2xl:-mt-14 2xl:child:h-28 2xl:child:w-28">
                        <img
                          src="images/services-svg-4.svg"
                          alt="services-icon"
                        />
                      </div>
                      <h3 className="mb-1 md:mb-3 whitespace-pre-line text-center text-lg">
                        {t("sale.Warranty")}
                      </h3>
                      <p className="whitespace-pre-line text-center">
                        {t("sale.Rest assured! Your safety is our priority, which is why every camper we sell comes with a 12-month warranty included!")}
                      </p>
                    </div>

                  </div>
                </div>


                <div className="rounded bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-6 2xl:mt-14 2xl:h-[calc(100%-3.5rem)] pt-0 pb-3">
                  <div className="flex flex-col justify-between h-full">
                    <div>
                      <div className="flex justify-center align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-12 mb-2 md:mb-5 child:h-24 child:w-24 2xl:-mt-14 2xl:child:h-28 2xl:child:w-28">
                        <img
                          src="images/purchase-icon.svg"
                          alt="purchase-icon"
                        />
                      </div>
                      <h3 className="mb-1 md:mb-3 whitespace-pre-line text-center text-lg">
                        {t("sale.Free Trial Camping")}
                      </h3>
                      <p className="whitespace-pre-line text-center">
                        {t("sale.Take your dream camper on vacation and put it to the test, and if you decide to buy it, the rental is on us.")}
                      </p>
                    </div>

                  </div>
                </div>


                <div className="rounded bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-6 2xl:mt-14 2xl:h-[calc(100%-3.5rem)] pt-0 pb-3">
                  <div className="flex flex-col justify-between h-full">
                    <div>
                      <div className="flex justify-center align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-12 mb-2 md:mb-5 child:h-24 child:w-24 2xl:-mt-14 2xl:child:h-28 2xl:child:w-28">
                        <img
                          src="images/sales-icon.svg"
                          alt="services-icon"
                        />
                      </div>
                      <h3 className="mb-1 md:mb-3 whitespace-pre-line text-center text-lg">
                        {t("sale.Buying Guidance")}
                      </h3>
                      <p className="whitespace-pre-line text-center">
                        {t("sale.We guide you through your model from A to Z and advise you on which model suits your needs,even if it means you don't buy from us.")}
                      </p>
                    </div>

                  </div>
                </div>


                <div className="rounded bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-6 2xl:mt-14 2xl:h-[calc(100%-3.5rem)] pt-0 pb-3">
                  <div className="flex flex-col justify-between h-full">
                    <div>
                      <div className="flex justify-center align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-12 mb-2 md:mb-5 child:h-24 child:w-24 2xl:-mt-14 2xl:child:h-28 2xl:child:w-28">
                        <img
                          src="images/funding-icon.svg"
                          alt="services-icon"
                        />
                      </div>
                      <h3 className="mb-1 md:mb-3 whitespace-pre-line text-center text-lg">
                        {t("sale.Competitive Prices")}
                      </h3>
                      <p className="whitespace-pre-line text-center">
                        {t("sale.Transparent prices well below the list price.")}
                      </p>
                    </div>
                    <button onClick={() =>
                      window.scrollTo({
                        top: saleConatctForm.current.offsetTop - 110,
                        behavior: "smooth"
                      })
                    } className="view-button group/button block disabled:opacity-50 border border-transparent rounded-full text-md  group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all not-oblique hover:oblique group-hover/parent:oblique cssvar-secondary-to-current bg-white text-secondary hover:bg-white dark:text-tertiary dark:bg-off-black  mb-0 group-[:hover:not(:has(.secondbutton:hover))]:border-secondary dark:group-[:hover:not(:has(.secondbutton:hover))]:border-tertiary">
                      <div className="m-auto">
                        <div className="group/button block disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white button-primary-bg dark:bg-primary-dark p-2 pl-3 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated md:mt-5 w-full">
                          <div className="flex h-full items-center justify-between">

                            <span className="flex text-sm text-16">{t("sale.Book an inspection")}</span>
                            <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                              <svg width="32" height="32" viewBox="0 0 32 32">
                                <g fill="none" fillRule="evenodd">
                                  <path d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z" fill="var(--svg-color-secondary, #163435)"></path>
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </button>
                  </div>
                </div>

              </Slider>



            </div>
          </div>

        </div>

      </div>
      <div className="2xl:pt-12 md:pt-12 pt-2 2xl:pb-12" ref={paragraphRef}>
        <div className="pt-0 pb-0 mx-auto flex max-w-4xl flex-col items-center text-center">
          <p className="mb-4 whitespace-pre-line text-md mt-sm-5">{t("sale.Freedom in Everyday Life")}</p>
          <h2 className="whitespace-pre-line break-words text-xl md:text-2xl mb-4">{t("sale.Campers for Sale")}</h2>
          <p className="md:mb-7 mb-0 whitespace-pre-line text-2md font-normal md:text-lg-paragraph md:font-normal text-20">
            {t("sale.Here you will find all campers that we currently offer for sale.")}
          </p>
        </div>

        <div className="mx-5 max-w-screen-xl xl-padded:mx-auto camper-sale-slider">
          <div className="min-h-screen">
            <div className="pt-0 pb-8 2xl:pb-12 grid gap-x-5 sm:grid-cols-2 lg:grid-cols-3 justify-center sale-overview">
              {camper.map((item, index) => (
                <div className="relative flex flex-col mt-6 text-gray-700 bg-white shadow-md bg-clip-border rounded-xl mt-[50%] hover-bg">
                  <Link
                    to={`/availabilityinfo/${item.id}`}
                    className="box-border h-full max-w-5xl pt-[60%]"
                  >
                    <div
                      className="relative  mx-4 text-white  -mt-[60%] bg-clip-border rounded-xl bg-blue-gray-500 shadow-blue-gray-500/40 camper-divImg">
                      <picture className="text-0">
                        <img
                          className="box-img inline-block max-w-full object-cover dark:brightness-90 aspect-[1.5] w-full  group-hover:scale-105 transition-all duration-300 divImg -mt-[50%]"
                          height={item.image ? 100 : 0}
                          width={item.image ? 100 : 0}
                          src={'images/0.gif'}
                          style={{ backgroundImage: "url(" + imageUrl + item.image + ")" }}
                          //  src={imageUrl + item.image}

                          alt="rent-img-one"
                        />
                      </picture>
                    </div>
                    <div className="xl:p-0 p-0">
                      <h5 className="mb-2 text-xl xs:mb-6 price-font fw-600 text-center tracking-wide text-orange" >{item.title}</h5>
                      {camper[index].camper_details.map((camper_details) => {
                        return (
                          <>

                            <h4 className="block mb-2 text-md  font-semibold leading-snug tracking-normal text-blue-gray-900 text-center title-camper">
                              {camper_details.sub_title}
                            </h4>
                          </>
                        )
                      })}

                      {camper[index].camper_price.map((camperPrice) => {

                        return (
                          <>
                            {
                              camperPrice.discount_price != 0 ?
                                <h4 className="block text-center text-md">
                                  {camperPrice.price - camperPrice.discount_price} insted of {camperPrice.price}
                                </h4>
                                :
                                <h4 className="block text-center text-md">
                                  {" "}
                                  <span className='price-font text-md inline-block align-bottom'>

                                    <NumericFormat
                                      value={item.campernew_price}
                                      thousandSeparator={'.'}
                                      decimalSeparator={','}
                                      displayType={'text'}

                                    />

                                    €
                                  </span>

                                  <div><p className="text-md inline-block align-bottom ml-2">{t("sale.including VAT")}</p></div>
                                </h4>
                            }
                          </>

                        )

                      })}


                    </div>
                    <div>
                      <div className="mt-4 mb-1  bg-off-white p-3 dark:bg-black px-8">
                        {camper[index].feature.map((feature) => {
                          return (
                            <div className="mb-2 flex last:mb-0">
                              <div className="flex items-center justify-center self-start rounded-full bg-white p-1 dark:bg-off-white">
                                <div className=" rounded-full bg-white inline-block align-top child:h-5 child:w-5 xs:child:h-6 xs:child:w-6 sm:child:h-5 sm:child:w-5 2md:child:h-6 2md:child:w-6 lg:child:h-5 lg:child:w-5 xl-padded-with-arrows:child:h-6 xl-padded-with-arrows:child:w-6">
                                  <img src={process.env.REACT_APP_LARAVEL_FEATURE_IMAGE_URL + '' + feature.smallicon} width="24px" height="24px" className="rounded-full bg-white" />
                                </div>
                              </div>
                              <p className="mt-1 ml-2 text-sm xs:text-sm sm:text-xs 2md:text-sm lg:text-xs xl-padded-with-arrows:text-sm">
                                {feature.title}
                              </p>
                            </div>
                          );
                        })}

                      </div>


                    </div>
                  </Link>
                  <div className="p-6 pt-0">
                    <div className="mt-4">



                      <Link
                        className="mt-4 group/button block disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white green-bg dark:bg-primary-dark p-3 pl-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated w-full"
                        to={`/availabilityinfo/${item.id}`}
                      >
                        <div className="flex h-full items-center justify-between">
                          <span className="flex"> {t("sale.See details")}</span>
                          <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-secondary">
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g fill="none" fillRule="evenodd">
                                <path
                                  d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                                  fill="var(--svg-color-secondary, #fff)"
                                ></path>
                              </g>
                            </svg>
                          </div>
                        </div>
                      </Link>

                    </div>
                  </div>

                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="mx-5">

          <div className="2xl:pt-12 pt-10 md:pt-8 pb-0 mx-auto flex max-w-4xl flex-col items-center text-center">
            <h2 className="whitespace-pre-line break-words text-xl md:text-2xl mb-4">
              {t("sale.How to buy a camper")}
            </h2>
            <p className="md:mb-0 mb-3 whitespace-pre-line text-2md font-normal md:text-lg-paragraph md:font-normal text-20">
              {t("sale.Turn your dream of owning your own camper into reality with just 4 simple steps!")}<br /> {t("sale.We are here personally to assist you and provide expert guidance.")}
            </p>
          </div>

          <div className="">
            <div className="pt-1 2xl:pt-4 pb-0">
              <div className="md:mx-5 max-w-screen-xl xl-padded:mx-auto pt-3">
                <Slider className="camper-slider" {...fourrow}>


                  <div className="relative rounded bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-6 2xl:mt-14 2xl:h-[calc(100%-3.5rem)] pt-0 pb-7 flex-col-imp">
                    <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-12 mb-5 child:h-24 child:w-24 2xl:-mt-14 2xl:child:h-28 2xl:child:w-28">
                      <img
                        src="images/services-svg-2.svg"
                        alt="services-icon"
                      />
                    </div>
                    <h3 className="mb-3 whitespace-pre-line text-center text-lg">
                      <span className='price-font'>1.</span> {t("sale.Choose Your Camper")}
                    </h3>
                    <p className="whitespace-pre-line text-center mb-75">
                      {t("sale.Go online and choose the camper you like the most!")}
                    </p>
                    <button onClick={() =>
                      window.scrollTo({
                        top: paragraphRef.current.offsetTop - 110,
                        behavior: "smooth"
                      })
                    }>
                      <div className="mt-auto w-full">
                        <div className="bottom-btn group/button block disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark p-2 pl-3 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated md:mt-5 w-full">
                          <div className="flex h-full items-center justify-between">

                            <span className="flex">
                              {t("sale.View camper models")}
                            </span>
                            <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                              <svg width="32" height="32" viewBox="0 0 32 32">
                                <g fill="none" fillRule="evenodd">
                                  <path d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z" fill="var(--svg-color-secondary, #163435)"></path>
                                </g>
                              </svg>
                            </div>


                          </div>
                        </div>
                      </div>

                    </button>

                  </div>





                  <div className="relative text-center rounded bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-6 2xl:mt-14 2xl:h-[calc(100%-3.5rem)] pt-0 pb-7">
                    <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-12 mb-5 child:h-24 child:w-24 2xl:-mt-14 2xl:child:h-28 2xl:child:w-28">
                      <img
                        src="images/inspection-icon.svg"
                        alt="inspection-icon"
                      />
                    </div>
                    <h3 className="mb-3 whitespace-pre-line text-center text-lg">
                      <span className='price-font'>2.</span> {t("sale.Thorough inspection")}
                    </h3>
                    <p className="whitespace-pre-line text-center">
                      {t("sale.Inspect your camper or even take it for a test drive during a vacation – if you buy the rental up to 500€ is free!")}

                    </p>


                  </div>


                  <div className="relative text-center rounded bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-6 2xl:mt-14 2xl:h-[calc(100%-3.5rem)] pt-0 pb-7">
                    <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-12 mb-5 child:h-24 child:w-24 2xl:-mt-14 2xl:child:h-28 2xl:child:w-28">
                      <img src="images/sales-icon.svg" alt="services-icon" />
                    </div>
                    <h3 className="mb-3 whitespace-pre-line text-center text-lg">
                      <span className='price-font'>3.</span> {t("sale.Seal the Deal")} </h3>
                    <p className="whitespace-pre-line text-center">
                      {t("sale.Easily sign the contract online or in person.")}
                    </p>
                  </div>


                  <div className="relative text-center rounded bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-6 2xl:mt-14 2xl:h-[calc(100%-3.5rem)] pt-0 pb-7">
                    <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-12 mb-5 child:h-24 child:w-24 2xl:-mt-14 2xl:child:h-28 2xl:child:w-28">
                      <img src="images/rentel-icon.svg" alt="rentel-icon" />
                    </div>
                    <h3 className="mb-3 whitespace-pre-line text-center text-lg">
                      <span className='price-font'>4.</span>  {t("sale.Pick Up Your Camper")}
                    </h3>
                    <p className="whitespace-pre-line text-center">
                      {t("sale.Now all that's left to do is visit us and take possession of your motorhome.")}
                    </p>
                    <button onClick={() =>
                      window.scrollTo({
                        top: saleConatctForm.current.offsetTop - 100,
                        behavior: "smooth"
                      })
                    } className="mt-auto w-full">
                      <div className="bottom-btn group/button block disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark p-2 pl-3 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated md:mt-5 w-full">
                        <div className="flex h-full items-center justify-between">
                          <span className="flex"> {t("sale.Contact form")}</span>
                          <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                            <svg width="32" height="32" viewBox="0 0 32 32">
                              <g fill="none" fillRule="evenodd">
                                <path d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z" fill="var(--svg-color-secondary, #163435)"></path>
                              </g>
                            </svg>
                          </div>
                        </div>
                      </div>
                    </button>
                  </div>


                </Slider>

                <div className="flex justify-center pt-10 xl:pt-4 2xl:pt-12">
                  <button onClick={() =>
                    window.scrollTo({
                      top: saleConatctForm.current.offsetTop - 100,
                      behavior: "smooth"
                    })
                  }
                    className="group/button disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark p-3 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated pl-3 py-2 inline-block"
                  >
                    <div className="flex h-full items-center justify-between">
                      <div className="mobile-none inline-block align-top mr-2 rounded-full bg-white p-1 child:h-7 child:w-7 dark:bg-off-white">
                        <img src="images/contact-us-icon.svg" alt="contact-us-icon" />
                      </div>
                      <span className="flex">{t("sale.Arrange a consultation appointment")}</span>
                      <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g fill="none" fillRule="evenodd">
                            <path
                              d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                              fill="var(--svg-color-secondary, #163435)"
                            ></path>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div className="mx-5 max-w-screen-xl xl-padded:mx-auto">

          <div className="pt-12 2xl:pt-12">
            <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mask-image relative p-0 md:flex md:min-h-[400px] md:rounded-xl md:bg-transparent dark:md:bg-transparent justify-end">
              <Link
                to={"/contactus"}
                className="w-full md:flex justify-end"
                href=""
              >
                <div className="md:hidden">
                  <div className="overflow-hidden">
                    <div className="-ml-[5%] w-[110%] overflow-hidden rounded-b-[60%20%] px-[5%]">
                      <div className="relative">
                        <picture className="text-0">
                          <img
                            className=""
                            src="images/questions.png"
                            alt="chrissi___leo_camper_u__bergab"
                          />
                        </picture>
                      </div>
                    </div>
                  </div>
                </div>
                <picture className="text-0 absolute top-0 left-0 z-background hidden h-full w-full transition-all duration-300 group-hover/parent:scale-105 md:block md:group-hover/parent:scale-[103%]">
                  <img
                    className=""
                    src="images/questions.png"
                    alt="chrissi___leo_camper_u__bergab"
                  />
                </picture>
                <div className="relative -mt-10 mb-4 flex justify-center xs:mb-4 md:hidden">
                  <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark child:h-20 child:w-20">
                    <img src="images/mail-icon.svg" alt="mail-icon" />
                  </div>
                </div>
                <div className="z-0 m-6 mt-0 flex flex-col text-center xs:mt-0 md:m-5 md:mt-5 md:min-h-[calc(100%-theme(spacing.5)*2)] md:w-full md:max-w-1/2 md:rounded-lg md:bg-white md:p-8 md:text-left md:shadow md:dark:bg-off-black-desat xl:max-w-[45%] 2xl:m-9 2xl:mt-9 2xl:min-h-[calc(100%-theme(spacing.9)*2)]">
                  <div className="mb-2 md:mb-6 flex items-center justify-center md:justify-start">
                    <div className="relative mr-4 hidden md:block">
                      <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark child:h-16 child:w-16">
                        <img src="images/mail-icon.svg" alt="mail-icon" />
                      </div>
                    </div>
                    <p className="text-lg"> {t("sale.How to Get in Touch")} </p>
                  </div>
                  <h3 className="whitespace-pre-line break-words md:mb-4 mb-2 text-xl xs:mb-6 current-flex">
                    {t("sale.Viewing Appointments")}<p className="mobile-none" >&&nbsp;{t("sale.Your Personal Questions")}</p>
                  </h3>
                  <div className="mb-4 md:mb-6">
                    <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal image-box-text">
                      {t("sale.Are you eyeing your dream RV or seeking detailed answers to your inquiries?")} <br />{t("sale.Feel free to use our contact form, and we'll promptly get back to you.")}<br /> {t("sale.P.S. Craving a taste of freedom? A complimentary trial camping trip awaits you upon a successful purchase!")}

                    </div>
                    {/* <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal image-box-text">
                      P.S. Craving a taste of freedom? A complimentary trial camping trip awaits you upon a
                      successful purchase!
                    </div> */}
                  </div>
                  <div className="group/button block disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark p-3 pl-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated w-full md:mt-auto button-primary-bg">
                    <div className="flex h-full items-center justify-between">
                      <span className="flex">{t("sale.To the contact form")}</span>
                      <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                        <svg width="32" height="32" viewBox="0 0 32 32">
                          <g fill="none" fillRule="evenodd">
                            <path
                              d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                              fill="var(--svg-color-secondary, #fff)"
                            ></path>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>

          <div className="pt-8 2xl:pt-12 pb-0 mx-auto flex max-w-3xl flex-col items-center text-center">
            <p className="mb-4 whitespace-pre-line text-md">{t("sale.A Dream come true")}
            </p>
            <h2 className="whitespace-pre-line break-words text-xl md:text-2xl mb-4">
              {t("sale.Secure the Freedom of Camper Life!")}
            </h2>
            <p className="mb-3 whitespace-pre-line text-2md font-normal md:text-lg-paragraph md:font-normal text-20">
              {t("sale.Explore our wide range of vans, motorhomes, and caravans.")} <br />{t("sale.If you're unsure about the right category, we're here to help.")}<br />{t("sale.For added peace of mind, we offer the opportunity to personally test the categories.")} <br />{t("sale.When you purchase one of our motorhomes, we'll refund up to €500 of your exploration expenses.")}
            </p>
          </div>

          <div className="video-sliders">
            <div className="md:mx-5 max-w-screen-xl xl-padded:mx-auto pt-5 tabslider">

              <Slider
                {...settingsThumbs1}

                ref={(slider) => setNav4(slider)}
                className="slider-thumbnail camper-slider subscription-slider campersale-slider"
              >
                <div className="custom-min-h tab-bg overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark flex flex-col items-center justify-center md:p-3 p-0 text-center 2xs:rounded sm:rounded-lg sm:p-5 -mb-1 min-h-full rounded-b-none rounded-b-none-all xl:pb-10 pb-2 2xs:rounded-b-none sm:rounded-b-none sm:pb-10">
                  <picture className="text-0 mb-1 xs:mb-2 lg:mb-3 lg:w-[200px] lg:max-w-full xl:w-[210px]">
                    <img
                      className="inline-block max-w-full dark:brightness-90 rounded-xs sm:rounded-md mb-1 xs:mb-2 lg:mb-3 w-full aspect-tabs small-diV-img lg:max-h-[160px]"
                      src={'images/0.gif'}
                      style={{ backgroundImage: "url(images/Campervans-picture.png)" }}
                      alt="tab-img"
                    />
                  </picture>
                  <h4 className="overflow-hidden text-ellipsis text-lg lg:text-lg">
                    {t("sale.Campervans")}

                  </h4>
                </div>

                <div className="custom-min-h tab-bg overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark flex flex-col items-center justify-center md:p-3 p-0 text-center 2xs:rounded sm:rounded-lg sm:p-5 -mb-1 min-h-full rounded-b-none rounded-b-none-all xl:pb-10 pb-2 2xs:rounded-b-none sm:rounded-b-none sm:pb-10">
                  <picture className="text-0 mb-1 xs:mb-2 lg:mb-3 lg:w-[200px] lg:max-w-full xl:w-[210px]">
                    <img
                      className="inline-block max-w-full dark:brightness-90 rounded-xs sm:rounded-md mb-1 xs:mb-2 lg:mb-3 w-full aspect-tabs small-diV-img lg:max-h-[160px] small-diV-img"
                      src={'images/0.gif'}
                      style={{ backgroundImage: "url(images/Travel-Vans.png)" }}
                      alt="tab-img"
                    />
                  </picture>
                  <h4 className="overflow-hidden text-ellipsis text-lg lg:text-lg">
                    {t("sale.Travel Vans")}
                  </h4>
                </div>

                <div className="custom-min-h tab-bg overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark flex flex-col items-center justify-center md:p-3 p-0 text-center 2xs:rounded sm:rounded-lg sm:p-5 -mb-1 min-h-full rounded-b-none rounded-b-none-all xl:pb-10 pb-2 2xs:rounded-b-none sm:rounded-b-none sm:pb-10">
                  <picture className="text-0 mb-1 xs:mb-2 lg:mb-3 lg:w-[200px] lg:max-w-full xl:w-[210px]">
                    <img
                      className="inline-block max-w-full object-cover dark:brightness-90 rounded-xs sm:rounded-md mb-1 xs:mb-2 lg:mb-3 w-full aspect-tabs lg:max-h-[160px] small-diV-img"
                      src={'images/0.gif'}
                      style={{ backgroundImage: "url(images/Motorhomes.png)" }}
                      alt="tab-img"
                    />
                  </picture>
                  <h4 className="overflow-hidden text-ellipsis text-lg lg:text-lg">
                    {t("sale.Motorhomes")}
                  </h4>
                </div>

                <div className="custom-min-h tab-bg overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark flex flex-col items-center justify-center md:p-3 p-0 text-center 2xs:rounded sm:rounded-lg sm:p-5 -mb-1 min-h-full rounded-b-none rounded-b-none-all xl:pb-10 pb-2 2xs:rounded-b-none sm:rounded-b-none sm:pb-10">
                  <picture className="text-0 mb-1 xs:mb-2 lg:mb-3 lg:w-[200px] lg:max-w-full xl:w-[210px]">
                    <img
                      className="inline-block max-w-full object-cover dark:brightness-90 rounded-xs sm:rounded-md mb-1 xs:mb-2 lg:mb-3 w-full aspect-tabs lg:max-h-[160px] small-diV-img"
                      src={'images/0.gif'}
                      style={{ backgroundImage: "url(images/Caravans-picture.png)" }}
                      alt="tab-img"
                    />
                  </picture>
                  <h4 className="overflow-hidden text-ellipsis text-lg lg:text-lg">
                    {t("sale.Caravans")}
                  </h4>
                </div>


              </Slider>

              <Slider
                className="height-slider slider-main camper-slider"
                {...settingsMain1}

                ref={(slider) => setNav3(slider)}
              >

                <div className="overflow-hidden bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark -ml-5 min-h-[40px] w-screen rounded py-0 2xs:rounded-none lg:ml-0 lg:w-auto lg:rounded-xl lg:px-12">
                  <div className="">
                    <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12">
                      <div className="">
                        <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                          <picture className="text-0 flex">
                            <img
                              className="inline-block object-cover dark:brightness-90 rounded 2xs:rounded-lg w-full max-w-3xl mx-auto"
                              src="images/camper-van.jpeg"
                              alt="davidferk_portugal_t6_beach_sommers"
                            />
                          </picture>
                        </div>
                      </div>

                      <div className="">
                        <div className="pt-0 md:pt-8 2xl:pt-12 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                          <h3 className="my-5 first:mt-0 break-words text-lg md:text-xl">
                            {t("sale.Campervans - Effortless Freedom")}
                          </h3>
                          <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                            {t("sale.Our agile campervans can accommodate up to 4 people and make perfect everyday vehicles.")}<br />{t("sale.With a simple pop-up roof and a cozy bed inside, traveling becomes a pleasure.")}<br />{t("sale.A fully equipped kitchen, refrigerator, toilet, and hot water shower ensure your comfort.")}

                          </div>
                          <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>

                          <div className="mobile-none min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                          <div className="md:text-left text-center min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                            <Link
                              to={"/campers"}
                              className="group/button disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark p-3 pl-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated inline-block"
                            >
                              <div className="flex h-full items-center justify-between">
                                <span className="flex"> {t("sale.Explore Campervans")} </span>
                                <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                                  <svg
                                    width="32"
                                    height="32"
                                    viewBox="0 0 32 32"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g fill="none" fillRule="evenodd">
                                      <path
                                        d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                                        fill="var(--svg-color-secondary, #163435)"
                                      ></path>
                                    </g>
                                  </svg>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>



                <div className="overflow-hidden bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark -ml-5 min-h-[40px] w-screen rounded py-0 2xs:rounded-none lg:ml-0 lg:w-auto lg:rounded-xl lg:px-12">
                  <div className="">
                    <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12">
                      <div className="">
                        <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                          <picture className="text-0 flex">
                            <img
                              className="inline-block object-cover dark:brightness-90 rounded 2xs:rounded-lg w-full max-w-3xl mx-auto"
                              src="images/Travelvan.jpg"
                              alt="davidferk_portugal_t6_beach_sommers"
                            />
                          </picture>
                        </div>
                      </div>

                      <div className="">
                        <div className="pt-0 md:pt-8 2xl:pt-12 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                          <h3 className="my-5 first:mt-0 break-words text-lg md:text-xl">
                            {t("sale.Travel Vans - Self-sufficient and Practical")}
                          </h3>
                          <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                            {t("sale.our travel")}<br />
                            {t("sale.a fully equipped kitchen")}{i18n.resolvedLanguage === 'de' ? <><br />{t("sale.Eine voll ausgestattete Küchenzeile")}</> : ""}&nbsp;{t("sale.a hot water shower")},&nbsp;{t("sale.a toilet")}, &nbsp;{t("sale.fresh water, and a large bed are all on board no need for any modifications.")}<br /> {t("sale.The standing height and ample storage space make your trips exceptionally comfortable.")}

                          </div>
                          <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                          <div className="md:text-left text-center min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                            <Link
                              to={"/campers"}
                              className="group/button disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark p-3 pl-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated inline-block"
                            >
                              <div className="flex h-full items-center justify-between">
                                <span className="flex">{t("sale.Explore Travel Vans")}
                                </span>
                                <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                                  <svg
                                    width="32"
                                    height="32"
                                    viewBox="0 0 32 32"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g fill="none" fillRule="evenodd">
                                      <path
                                        d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                                        fill="var(--svg-color-secondary, #163435)"
                                      ></path>
                                    </g>
                                  </svg>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="overflow-hidden bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark -ml-5 min-h-[40px] w-screen rounded py-0 2xs:rounded-none lg:ml-0 lg:w-auto lg:rounded-xl lg:px-12">
                  <div className="">
                    <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12">
                      <div className="">
                        <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                          <picture className="text-0 flex">
                            <img
                              className="inline-block object-cover dark:brightness-90 rounded 2xs:rounded-lg w-full max-w-3xl mx-auto"
                              src="images/motorhome-categories.png"
                              alt="davidferk_portugal_t6_beach_sommers"
                            />
                          </picture>
                        </div>
                      </div>

                      <div className="">
                        <div className="pt-0 md:pt-8 2xl:pt-12 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                          <h3 className="my-5 first:mt-0 break-words text-lg md:text-xl">
                            {t("sale.Motorhomes - Luxury on Wheels")}
                          </h3>
                          <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                            {t("sale.Experience pure convenience and luxury in our motorhomes.")}<br />{t("sale.They can comfortably accommodate up to 5 people.")}<br />{t("sale.The beds are generously sized, the kitchenette is well-equipped, and the spacious bathroom adds extra comfort.")}<br />{t("sale.The cozy seating area will make you feel right at home.")}<br />{t("sale.With an integrated garage, you have plenty of storage space,")}&nbsp;{t("sale.and no one has to compromise on amenities while traveling.")}
                          </div>
                          <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                          <div className="md:text-left text-center min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                            <Link
                              to={'/campers'}
                              className="group/button disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark p-3 pl-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated inline-block"
                            >
                              <div className="flex h-full items-center justify-between">
                                <span className="flex">{t("sale.Explore Motorhomes")}</span>
                                <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                                  <svg
                                    width="32"
                                    height="32"
                                    viewBox="0 0 32 32"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g fill="none" fillRule="evenodd">
                                      <path
                                        d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                                        fill="var(--svg-color-secondary, #163435)"
                                      ></path>
                                    </g>
                                  </svg>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="overflow-hidden bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark -ml-5 min-h-[40px] w-screen rounded py-0 2xs:rounded-none lg:ml-0 lg:w-auto lg:rounded-xl lg:px-12">
                  <div className="">
                    <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12">
                      <div className="">
                        <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                          <picture className="text-0 flex">
                            <img
                              className="inline-block object-cover dark:brightness-90 rounded 2xs:rounded-lg w-full max-w-3xl mx-auto"
                              src="images/caravan.jpg"
                              alt="davidferk_portugal_t6_beach_sommers"
                            />
                          </picture>
                        </div>
                      </div>

                      <div className="">
                        <div className="pt-0 md:pt-8 2xl:pt-12 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                          <h3 className="my-5 first:mt-0 break-words text-lg md:text-xl">
                            {t("sale.Caravans - Comfortable Freedom")}
                          </h3>

                          <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                            {t("sale.Our caravans can comfortably sleep up to 5 people.")}<br /> {t("sale.Enjoy the comfort of a spacious caravan while keeping your own car with you at all times.")} <br />{t("sale.An ideal choice for relaxed vacations at your favorite destination cost-effective, spacious, and comfortable!")}
                          </div>
                          <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                          <div className="md:text-left text-center min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                            <Link
                              to={"/campers"}
                              className="group/button disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark p-3 pl-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated inline-block"
                            >
                              <div className="flex h-full items-center justify-between">
                                <span className="flex">{t("sale.Explore Caravans")} </span>
                                <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                                  <svg
                                    width="32"
                                    height="32"
                                    viewBox="0 0 32 32"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g fill="none" fillRule="evenodd">
                                      <path
                                        d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                                        fill="var(--svg-color-secondary, #163435)"
                                      ></path>
                                    </g>
                                  </svg>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </Slider>
            </div>
          </div>


          <div className="2xl:pt-12 pt-8 pb-0 mx-auto flex max-w-4xl flex-col items-center text-center">
            <div className="inline-flex items-center justify-center rounded-full border border-primary bg-white p-3 dark:border-primary-dark dark:bg-off-white mb-5 md:mb-6">
              <div className="inline-block align-top child:h-10 child:w-10">
                <img
                  src="images/contact-form-img.svg"
                  alt="interested-camper"
                />
              </div>
            </div>
            <h2 className="whitespace-pre-line break-words text-xl md:text-2xl mb-4">
              {t("sale.Frequently Asked Questions About")} <br />{t("sale.Buying a Camper")}
            </h2>
            <p className="whitespace-pre-line text-2md font-normal md:text-lg-paragraph md:font-normal text-20">
              {t("sale.Here, you'll find answers to the most common questions regarding the purchase of a camper.")} {t("sale.And if your question isn't listed, just drop us a message through the contact form!")}
            </p>
          </div>

          <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12 sub-faq">
            <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg mx-auto p-0 max-w-3xl">
              <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                <summary className="justify-between flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">

                  <h3>
                    <div className="flex items-center">
                      <p className="text-lg xs:mb-6">
                        {t("sale.Can I Test-Camp the Camper before buying it?")}

                      </p>
                    </div>
                  </h3>
                  <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path
                          d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                          fill="var(--svg-color-secondary, #EE7214)"
                        ></path>
                      </g>
                    </svg>
                  </div>

                </summary>
                <div className="px-7 2xl:px-9">
                  <div className="">
                    <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("sale.Absolutely! you can simply rent the camper, and upon purchase, we'll reimburse you up to €500 of rental costs.")} <br />{t("sale.This is your chance to experience the camper up close before making your final decision")}.
                      </div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>

                    </div>
                  </div>
                </div>
              </details>
              <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                <summary className="justify-between flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">

                  <h3>
                    <div className="flex items-center">
                      <p className="text-lg xs:mb-6">
                        {t("sale.Do You Offer Purchase Guidance & Inspections?")}
                      </p>
                    </div>
                  </h3>
                  <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path
                          d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                          fill="var(--svg-color-secondary, #EE7214)"
                        ></path>
                      </g>
                    </svg>
                  </div>

                </summary>
                <div className="px-7 2xl:px-9">
                  <div className="">
                    <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal price-font">
                        {t("sale.We're available weekdays from")} {t("sale.9 am to 5 pm")} {t("sale.at 0163 2937354 to assist you with the purchase process.")} <br />{t("sale.Just schedule a consultation or inspection appointment via phone or through the contact form.")}
                      </div>
                    </div>
                  </div>
                </div>
              </details>
              <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                <summary className="justify-between flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">

                  <h3>
                    <div className="flex items-center">
                      <p className="text-lg xs:mb-6">
                        {t("sale.How Old Are Your Campers?")}
                      </p>
                    </div>
                  </h3>
                  <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path
                          d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                          fill="var(--svg-color-secondary, #EE7214)"
                        ></path>
                      </g>
                    </svg>
                  </div>

                </summary>
                <div className="px-7 2xl:px-9">
                  <div className="">
                    <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("sale.All our campers come from first-hand ownership.")} <br />{t("sale.You can always find the first registration date listed at the top of the campers available for sale.")}
                      </div>
                    </div>
                  </div>
                </div>
              </details>
              <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                <summary className="justify-between flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">

                  <h3>
                    <div className="flex items-center">
                      <p className="text-lg xs:mb-6">
                        {t("sale.What's the Cost of Buying a Camper?")}
                      </p>
                    </div>
                  </h3>
                  <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path
                          d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                          fill="var(--svg-color-secondary, #EE7214)"
                        ></path>
                      </g>
                    </svg>
                  </div>

                </summary>
                <div className="px-7 2xl:px-9">
                  <div className="">
                    <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("sale.The price of a camper depends on the mileage and model.")} <br />{t("sale.But don't worry, we'll work together to find the best deal for you!")}
                      </div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>

                    </div>
                  </div>
                </div>
              </details>
              <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                <summary className="justify-between flex cursor-pointer select-none items-center gap-10 py-6 px-7 2xl:py-8 2xl:px-9">

                  <h3>
                    <div className="flex items-center">
                      <p className="text-lg xs:mb-6">
                        {t("sale.Do You Offer Financing?")}
                      </p>
                    </div>
                  </h3>
                  <div className="inline-block align-top dark:cssvar-secondary-to-off-white md:child:h-8 md:child:w-8">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path
                          d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                          fill="var(--svg-color-secondary, #EE7214)"
                        ></path>
                      </g>
                    </svg>
                  </div>

                </summary>
                <div className="px-7 2xl:px-9">
                  <div className="">
                    <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("sale.We're here to help you discover the most affordable financing options and guide you through the entire application process.")}<br /> {t("sale.The money bag and chart are ready!")}
                      </div>
                    </div>
                  </div>
                </div>
              </details>
            </div>


          </div>

          <div className="flex justify-center">
            <Link
              to={"/contactus"}
              className="group/button disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark p-3 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated pl-3 py-2 inline-block"
            >
              <div className="flex h-full items-center justify-between">

                <span className="flex">
                  {t("sale.More questions? Check out our Helpcenter")}<span className="our-value-text-mobile me-1 ms-1"></span>
                  {/* {t("sale.More questions?")} <span className="mobile-none me-2 ms-2">Go to</span> <span className="our-value-text-mobile me-1 ms-1">Go To </span>
                  Helpcenter */}
                </span>
                <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g fill="none" fillRule="evenodd">
                      <path
                        d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                        fill="var(--svg-color-secondary, #163435)"
                      ></path>
                    </g>
                  </svg>
                </div>
              </div>
            </Link>
          </div>



          <div className="pt-8 2xl:pt-12 pb-0 mx-auto flex max-w-4xl flex-col items-center text-center">
            <h2 className="mobile-none whitespace-pre-line break-words text-xl md:text-2xl mb-4">
              {t("sale.Tips & Tricks for Your Perfect Camper Purchase")}
            </h2>
            <h2 className="our-value-text-mobile whitespace-pre-line break-words text-xl md:text-2xl mb-4">
              {t("sale.Camper Purchase")}: {t("sale.Tips")}
            </h2>
            <p className="whitespace-pre-line text-2md font-normal md:text-lg-paragraph md:font-normal text-20">
              {t("sale.Discover useful advice to find the ideal companion for your adventures!")}
            </p>
          </div>

          <div className="pt-1 2xl:pt-1 pb-8 2xl:pb-0">
            <div className="md:mx-5 max-w-screen-xl xl-padded:mx-auto pt-5 pb-10">
              <Slider className="camper-slider" {...camperslider}>

                <Link onClick={() =>
                  window.scrollTo({
                    top: paragraphRef.current.offsetTop - 118,
                    behavior: "smooth",
                  })
                }
                >
                  <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent group flex h-full flex-col p-4 hover:shadow-elevated hover:dark:shadow-dark-elevated">
                    <div className="mask-image shrink-0 overflow-hidden rounded-sm 2xs:rounded-md">
                      <picture className="text-0 h-full w-full">
                        <img
                          className="inline-block max-w-full object-cover dark:brightness-90 aspect-square max-h-[270px] w-full group-hover:scale-110 transition-all duration-300"
                          src="images/testdrive.png"
                          alt="_louiseder_grandcalifornia_spanien"
                        />
                      </picture>
                    </div>
                    <div className="flex h-full flex-col items-center justify-center px-4 text-center">
                      <p className="whitespace-pre-line text-lg mt-4">
                        {t("sale.Give it a try")}
                      </p>
                      <p className="mt-3 whitespace-pre-line line-clamp-5">
                        {t("sale.Get up close and personal with your camper by taking it for a test drive.")}

                      </p>
                      <div className="mt-auto">
                        <div className="group/button disabled:opacity-50 border border-transparent text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated p-1 rounded-full mt-4 inline-block px-4">
                          <div className="flex h-full items-center justify-center">
                            <span className="flex">{t("sale.Explore Models")}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>


                <Link to={'/explanatoryvideo'}>
                  <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent group flex h-full flex-col p-4 hover:shadow-elevated hover:dark:shadow-dark-elevated">
                    <div className="mask-image shrink-0 overflow-hidden rounded-sm 2xs:rounded-md">
                      <picture className="text-0 h-full w-full">
                        <img
                          className="inline-block max-w-full object-cover dark:brightness-90 aspect-square max-h-[270px] w-full group-hover:scale-110 transition-all duration-300"
                          src="images/videos.png"
                          alt="messe_free_id_buzz_team"
                        />
                      </picture>
                    </div>
                    <div className="flex h-full flex-col items-center justify-center px-4 text-center">
                      <p className="whitespace-pre-line text-lg mt-4">
                        {t("sale.Camper Videos")}
                      </p>
                      <p className="mt-3 whitespace-pre-line line-clamp-5">
                        {t("sale.Preview your camper right now.")}
                      </p>
                      <div className="mt-auto">
                        <div className="group/button disabled:opacity-50 border border-transparent text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated p-1 rounded-full mt-4 inline-block px-4">
                          <div className="flex h-full items-center justify-center">
                            <span className="flex">{t("sale.Watch Videos")}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>


                <Link onClick={() =>
                  window.scrollTo({
                    top: paragraphRef.current.offsetTop - 118,
                    behavior: "smooth",
                  })
                }>
                  <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent group flex h-full flex-col p-4 hover:shadow-elevated hover:dark:shadow-dark-elevated">
                    <div className="mask-image shrink-0 overflow-hidden rounded-sm 2xs:rounded-md">
                      <picture className="text-0 h-full w-full">
                        <img
                          className="inline-block max-w-full object-cover dark:brightness-90 aspect-square max-h-[270px] w-full group-hover:scale-110 transition-all duration-300"
                          src="images/models.png"
                          alt="camper_modelle"
                        />
                      </picture>
                    </div>
                    <div className="flex h-full flex-col items-center justify-center px-4 text-center">
                      <p className="whitespace-pre-line text-lg mt-4">
                        {t("sale.Compare Models")}
                      </p>
                      <p className="mt-3 whitespace-pre-line line-clamp-5">
                        {t("sale.Find out which camper suits you best.")}

                      </p>
                      <div className="mt-auto">
                        <div className="group/button disabled:opacity-50 border border-transparent text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated p-1 rounded-full mt-4 inline-block px-4">
                          <div className="flex h-full items-center justify-center">
                            <span className="flex">{t("sale.Explore Modelss")}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>

              </Slider>
            </div>
          </div>



          <div className="div sale-newsletter" id="">
            <div className="pt-1 2xl:pt-12 pb-4 2xl:pb-4">
              <div className="mx-auto max-w-4xl newsletter-wrapper rounded bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg flex flex-col items-center overflow-visible bg-primary from-secondary/90 to-secondary/50 text-center text-white dark:to-primary-dark 2xl:p-4 mt-10 pt-0 2xl:mt-12 2xl:pt-0">
                <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-10 mb-4 child:h-20 child:w-20 md:child:h-24 md:child:w-24 2xl:-mt-12">
                  <img src="images/mail-icon.svg" alt="newsletter-icon" />
                </div>
                <h2 className="whitespace-pre-line break-words mb-4 max-w-2xl text-xl md:text-2xl text-orange">
                  {t("sale.Don't miss out on sales updates!")}
                </h2>
                <p className="mb-2 max-w-lg">
                  {t("sale.Are you ready for the latest developments in camper sales?")} {t("sale.We'll keep you informed!")}
                </p>

                <ul className="list-disc text-left mb-4">
                  <li>{t("sale.Discover brand-new models")} </li>
                  <li>{t("sale.Benefit from exclusive special offers")}</li>
                  <li>{t("sale.Exclusive Invitations for a Tour")}</li>
                </ul>
                {
                  isFormSubmit ?
                    <>
                      <div className="opacity-100 scale-100 success-msg">
                        <div className="inline-flex items-center justify-center rounded-full orange-border bg-white p-1 dark:bg-off-white">
                          <div className="inline-block align-top">
                            <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                              <g fill="none" fill-rule="evenodd">
                                <path d="m21.906 9.488-8.547 9.56-3.45-3.096a1.757 1.757 0 0 0-2.563.209c-.64.792-.547 1.98.21 2.659l4.764 4.274c.735.66 1.833.602 2.496-.13l9.73-10.869a1.954 1.954 0 0 0-.069-2.665 1.758 1.758 0 0 0-2.571.058z" fill="var(--svg-color-secondary, #163435)"></path>
                              </g>
                            </svg>
                          </div>
                        </div>
                        <h2 className="mt-4 max-w-lg text-lg-paragraph">{t("aboutus.Thank you for signing up!")}</h2>
                      </div>
                    </>
                    :


                    <form
                      className="mt-5 flex w-full max-w-3xl flex-col lg:grid lg:grid-cols-3 lg:gap-4 position-relative"
                      onSubmit={onSubmitForm2}
                    >


                      <div className="transition-all mb-4">
                        <div className="relative z-foreground mb-4 flex h-[60px] items-center rounded border bg-white text-black transition-all focus-within:border-primary focus:border-primary dark:focus-within:border-primary-dark border-transparent shadow dark:shadow-dark dark:bg-off-white dark:text-black">
                          <div className="inline-block align-top absolute pl-3 child:h-7 child:w-7 dark:cssvar-secondary-to-off-white dark:cssvar-secondary-to-secondary">
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M16 14.6527C13.6105 14.6527 11.6736 12.7158 11.6736 10.3264C11.6736 7.93688 13.6105 6 16 6C18.3895 6 20.3264 7.93688 20.3264 10.3264C20.3264 12.7158 18.3895 14.6527 16 14.6527Z"
                                fill="var(--svg-color-secondary, #163435)"
                              ></path>
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M16.1565 15.9742C12.2188 15.8883 9 19.0545 9 22.9726C9 23.4621 9.05258 23.9383 9.15042 24.3979C9.26224 24.9247 9.72616 25.3021 10.2643 25.3021H21.7361C22.2693 25.3021 22.7352 24.9324 22.847 24.4112C23.0091 23.6528 23.05 22.8501 22.9329 22.0181C22.4596 18.6482 19.5587 16.0487 16.1565 15.9742Z"
                                fill="var(--svg-color-secondary, #163435)"
                              ></path>
                            </svg>
                          </div>
                          <div className="peer flex w-full flex-col">
                            <input
                              type="text"
                              className="peer w-full rounded bg-transparent pt-2 pb-1 pl-12 text-md outline-none focus:pr-6 pr-6"
                              placeholder={t("sale.First Name")}
                              name='first_name'
                            />

                          </div>
                        </div>
                        <div className="relative bottom-4 overflow-hidden bg-warning text-center text-xs font-medium text-black transition-all -mt-4 rounded-b max-h-0"></div>
                      </div>
                      <div className="transition-all mb-4">
                        <div className="relative z-foreground mb-4 flex h-[60px] items-center rounded border bg-white text-black transition-all focus-within:border-primary focus:border-primary dark:focus-within:border-primary-dark border-transparent shadow dark:shadow-dark dark:bg-off-white dark:text-black">
                          <div className="inline-block align-top absolute pl-3 child:h-7 child:w-7 dark:cssvar-secondary-to-off-white dark:cssvar-secondary-to-secondary">
                            <svg
                              width="32px"
                              height="32px"
                              viewBox="0 0 32 32"
                              version="1.1"
                            >
                              <title>icon_contact_mail</title>
                              <g
                                id="icon_contact_mail"
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                              >
                                <g
                                  id="Group"
                                  transform="translate(5.000000, 7.700000)"
                                  fill="var(--svg-color-secondary, #163435)"
                                >
                                  <path
                                    d="M17.1541722,0 C18.5243618,0 19.2165846,0.133674373 19.9368674,0.518885858 C20.6000248,0.873546296 21.1264537,1.39997522 21.4811141,2.06313259 C21.8663256,2.78341544 22,3.47563815 22,4.8458278 L21.9981828,11.9648343 C21.9810588,13.1622375 21.8406449,13.810058 21.4811141,14.482322 C21.1264537,15.1454793 20.6000248,15.6719082 19.9368674,16.0265687 C19.2165846,16.4117802 18.5243618,16.5454545 17.1541722,16.5454545 L4.8458278,16.5454545 C3.47563815,16.5454545 2.78341544,16.4117802 2.06313259,16.0265687 C1.39997522,15.6719082 0.873546296,15.1454793 0.518885858,14.482322 C0.133674373,13.7620391 0,13.0698164 0,11.6996267 L0,4.8458278 C0,3.47563815 0.133674373,2.78341544 0.518885858,2.06313259 C0.873546296,1.39997522 1.39997522,0.873546296 2.06313259,0.518885858 C2.78341544,0.133674373 3.47563815,0 4.8458278,0 Z M20,5.563 L11,12.1092247 L2,5.564 L2,11.6996267 L2.00467769,12.1173697 C2.02375106,12.8767631 2.10330814,13.2040417 2.28251225,13.5391242 C2.45077309,13.8537447 2.69170989,14.0946815 3.0063303,14.2629423 C3.31348932,14.4272127 3.61409085,14.507752 4.24698433,14.5347258 L4.6271902,14.5443078 L17.3728098,14.5443078 C17.4423488,14.5435357 17.5086323,14.5423663 17.5719151,14.5407769 L17.9176389,14.5260186 C18.4406182,14.4924165 18.7144342,14.4122791 18.9936697,14.2629423 C19.3082901,14.0946815 19.5492269,13.8537447 19.7174877,13.5391242 C19.9265592,13.1481946 20,12.767887 20,11.6996267 L20,5.563 Z M17.1541722,2 L4.8458278,2 L4.42808487,2.00467769 C3.66869148,2.02375106 3.34141286,2.10330814 3.0063303,2.28251225 C2.69170989,2.45077309 2.45077309,2.69170989 2.28251225,3.0063303 C2.24438729,3.07761778 2.21077244,3.14855205 2.1812906,3.22336911 L10.999,9.63500008 L19.8187094,3.22336911 C19.7892276,3.14855205 19.7556127,3.07761778 19.7174877,3.0063303 C19.5492269,2.69170989 19.3082901,2.45077309 18.9936697,2.28251225 C18.6585871,2.10330814 18.3313085,2.02375106 17.5719151,2.00467769 L17.1541722,2 Z"
                                    id="Combined-Shape"
                                  ></path>
                                </g>
                              </g>
                            </svg>
                          </div>
                          <div className="peer flex w-full flex-col">
                            <input
                              type="email"
                              className="peer w-full rounded bg-transparent pt-2 pb-1 pl-12 text-md outline-none focus:pr-6 pr-6"
                              placeholder={t("sale.Email")} name='email'
                            />

                          </div>
                        </div>
                        <div className="relative bottom-4 overflow-hidden bg-warning text-center text-xs font-medium text-black transition-all -mt-4 rounded-b max-h-0"></div>
                      </div>

                      <button
                        type="submit"
                        className="group/button disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white button-primary-bg dark:bg-primary-dark p-3 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated pl-3 py-2 inline-block lg:h-[60px]"
                      >
                        <div className="flex h-full items-center justify-between">
                          <span className="flex">{t("sale.Get Updates")}</span>
                          <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g fill="none" fillRule="evenodd">
                                <path
                                  d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                                  fill="var(--svg-color-secondary, #163435)"
                                ></path>
                              </g>
                            </svg>
                          </div>
                        </div>
                      </button>
                      {saleError ? <p className="mb-2 flex w-full animate-wiggle items-center justify-between rounded bg-warning px-4 py-2 text-lg text-white" style={{ color: 'red' }}>{saleError}</p> : ''}

                    </form>
                }

              </div>
            </div>
          </div>



          <div className="" ref={saleConatctForm}>
            <div className="pt-8 2xl:pt-8 pb-4 2xl:pb-12">
              <div className="mx-auto flex max-w-3xl flex-col items-center">
                <div className="inline-flex items-center justify-center rounded-full border border-primary bg-white p-5 dark:border-primary-dark dark:bg-off-white mb-5 md:mb-6">
                  <div className="inline-block align-top child:h-10 child:w-10">
                    <img
                      src="images/interested-camper.svg"
                      alt="interested-camper"
                    />
                  </div>
                </div>
                <h2 className="whitespace-pre-line break-words mb-6 text-center md:mb-5 text-xl md:text-2xl">
                  {t("sale.Questions about Camper Sales?")}
                </h2>
                <div className="mb-8 text-center md:mb-8">
                  <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal text-20 br-none">
                    {t("sale.Feel free to use our contact form to ask any questions about camper sales or schedule a viewing appointment.")} <br />{t("sale.We're here to assist you!")}

                  </div>

                  <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal text-20 br-none">
                    {t("sale.Email")}: &nbsp;
                    <Link
                      to={"mailto:info@borntocamp.de"}
                      className="text-primary underline hover:text-off-black dark:text-primary-light hover:dark:text-off-white text-20"
                    >
                      info@borntocamp.de
                    </Link>
                    &nbsp; &nbsp;&nbsp;
                    <br />
                    {t("sale.Phone")}:  &nbsp;
                    <Link
                      to={"tel:0163 2937354"}
                      className="text-primary underline hover:text-off-black dark:text-primary-light hover:dark:text-off-white text-20"
                    >
                      0163 2937354
                    </Link>
                  </div>
                </div>
                {
                  isFormSubmit2 ?
                    <>
                      <div className="opacity-100 scale-100 success-msg text-center">
                        <div className="inline-flex items-center justify-center rounded-full orange-border bg-white p-1 dark:bg-off-white">
                          <div className="inline-block align-top">
                            <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                              <g fill="none" fill-rule="evenodd">
                                <path d="m21.906 9.488-8.547 9.56-3.45-3.096a1.757 1.757 0 0 0-2.563.209c-.64.792-.547 1.98.21 2.659l4.764 4.274c.735.66 1.833.602 2.496-.13l9.73-10.869a1.954 1.954 0 0 0-.069-2.665 1.758 1.758 0 0 0-2.571.058z" fill="var(--svg-color-secondary, #163435)"></path>
                              </g>
                            </svg>
                          </div>
                        </div>
                        <h2 className="mt-4 max-w-lg text-lg-paragraph">{t("aboutus.Thank you for your message!")}<br /> {t("aboutus.We'll respond as quickly as we can")}</h2>
                      </div>
                    </>
                    :
                    <form className="flex w-full flex-col items-center" onSubmit={onSubmitForm}>
                      <div className="grid grid-cols-1 gap-x-4 md:grid-cols-2 w-full">
                        <div className="transition-all md:mb-4 mb-1 col-span-2">
                          <div className="relative z-foreground mb-4 flex h-[60px] items-center rounded border bg-white text-black transition-all focus-within:border-primary focus:border-primary dark:bg-off-black-desat dark:text-off-white dark:focus-within:border-primary-dark border-transparent shadow dark:shadow-dark">
                            <div className="inline-block align-top absolute pl-3 child:h-7 child:w-7 dark:cssvar-secondary-to-off-white">
                              <svg
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16 14.6527C13.6105 14.6527 11.6736 12.7158 11.6736 10.3264C11.6736 7.93688 13.6105 6 16 6C18.3895 6 20.3264 7.93688 20.3264 10.3264C20.3264 12.7158 18.3895 14.6527 16 14.6527Z"
                                  fill="var(--svg-color-secondary, #163435)"
                                ></path>
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.1565 15.9742C12.2188 15.8883 9 19.0545 9 22.9726C9 23.4621 9.05258 23.9383 9.15042 24.3979C9.26224 24.9247 9.72616 25.3021 10.2643 25.3021H21.7361C22.2693 25.3021 22.7352 24.9324 22.847 24.4112C23.0091 23.6528 23.05 22.8501 22.9329 22.0181C22.4596 18.6482 19.5587 16.0487 16.1565 15.9742Z"
                                  fill="var(--svg-color-secondary, #163435)"
                                ></path>
                              </svg>
                            </div>
                            <div className="peer flex w-full flex-col">
                              <input
                                className="peer w-full rounded bg-transparent pt-2 pb-1 pl-12 text-md outline-none focus:pr-6 pr-6"
                                type="text" name="firstName" placeholder={t("sale.First & Last Name*")}
                              />

                            </div>
                          </div>
                        </div>

                        <div className="transition-all md:mb-4 mb-1 col-span-2 md:col-span-1">
                          <div className="relative z-foreground mb-4 flex h-[60px] items-center rounded border bg-white text-black transition-all focus-within:border-primary focus:border-primary dark:bg-off-black-desat dark:text-off-white dark:focus-within:border-primary-dark border-transparent shadow dark:shadow-dark">
                            <div className="inline-block align-top absolute pl-3 child:h-7 child:w-7 dark:cssvar-secondary-to-off-white">
                              <svg
                                width="32px"
                                height="32px"
                                viewBox="0 0 32 32"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <title>icon_contact_mail</title>
                                <g
                                  id="icon_contact_mail"
                                  stroke="none"
                                  strokeWidth="1"
                                  fill="none"
                                  fillRule="evenodd"
                                >
                                  <g
                                    id="Group"
                                    transform="translate(5.000000, 7.700000)"
                                    fill="var(--svg-color-secondary, #163435)"
                                  >
                                    <path
                                      d="M17.1541722,0 C18.5243618,0 19.2165846,0.133674373 19.9368674,0.518885858 C20.6000248,0.873546296 21.1264537,1.39997522 21.4811141,2.06313259 C21.8663256,2.78341544 22,3.47563815 22,4.8458278 L21.9981828,11.9648343 C21.9810588,13.1622375 21.8406449,13.810058 21.4811141,14.482322 C21.1264537,15.1454793 20.6000248,15.6719082 19.9368674,16.0265687 C19.2165846,16.4117802 18.5243618,16.5454545 17.1541722,16.5454545 L4.8458278,16.5454545 C3.47563815,16.5454545 2.78341544,16.4117802 2.06313259,16.0265687 C1.39997522,15.6719082 0.873546296,15.1454793 0.518885858,14.482322 C0.133674373,13.7620391 0,13.0698164 0,11.6996267 L0,4.8458278 C0,3.47563815 0.133674373,2.78341544 0.518885858,2.06313259 C0.873546296,1.39997522 1.39997522,0.873546296 2.06313259,0.518885858 C2.78341544,0.133674373 3.47563815,0 4.8458278,0 Z M20,5.563 L11,12.1092247 L2,5.564 L2,11.6996267 L2.00467769,12.1173697 C2.02375106,12.8767631 2.10330814,13.2040417 2.28251225,13.5391242 C2.45077309,13.8537447 2.69170989,14.0946815 3.0063303,14.2629423 C3.31348932,14.4272127 3.61409085,14.507752 4.24698433,14.5347258 L4.6271902,14.5443078 L17.3728098,14.5443078 C17.4423488,14.5435357 17.5086323,14.5423663 17.5719151,14.5407769 L17.9176389,14.5260186 C18.4406182,14.4924165 18.7144342,14.4122791 18.9936697,14.2629423 C19.3082901,14.0946815 19.5492269,13.8537447 19.7174877,13.5391242 C19.9265592,13.1481946 20,12.767887 20,11.6996267 L20,5.563 Z M17.1541722,2 L4.8458278,2 L4.42808487,2.00467769 C3.66869148,2.02375106 3.34141286,2.10330814 3.0063303,2.28251225 C2.69170989,2.45077309 2.45077309,2.69170989 2.28251225,3.0063303 C2.24438729,3.07761778 2.21077244,3.14855205 2.1812906,3.22336911 L10.999,9.63500008 L19.8187094,3.22336911 C19.7892276,3.14855205 19.7556127,3.07761778 19.7174877,3.0063303 C19.5492269,2.69170989 19.3082901,2.45077309 18.9936697,2.28251225 C18.6585871,2.10330814 18.3313085,2.02375106 17.5719151,2.00467769 L17.1541722,2 Z"
                                      id="Combined-Shape"
                                    ></path>
                                  </g>
                                </g>
                              </svg>
                            </div>
                            <div className="peer flex w-full flex-col">
                              <input
                                className="peer w-full rounded bg-transparent pt-2 pb-1 pl-12 text-md outline-none focus:pr-6 pr-6"
                                type="email" name="email" placeholder={t("sale.Email") + "*"}
                              />

                            </div>
                          </div>
                        </div>

                        <div className="transition-all md:mb-4 mb-1 col-span-2 md:col-span-1">
                          <div className="relative z-foreground mb-4 flex h-[60px] items-center rounded border bg-white text-black transition-all focus-within:border-primary focus:border-primary dark:bg-off-black-desat dark:text-off-white dark:focus-within:border-primary-dark border-transparent shadow dark:shadow-dark">
                            <div className="inline-block align-top absolute pl-3 child:h-7 child:w-7 dark:cssvar-secondary-to-off-white">
                              <svg
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="M12.004 8.139c-.171-.569-.342-1.024-.91-1.08L10.014 7C8.875 6.945 7 8.651 7 11.493c.057 4.947 7.448 13.02 12.338 13.476 2.9.284 4.72-1.422 4.776-2.616V21.33c.057-.569-.398-.796-.966-1.024-1.08-.398-2.16-.91-3.241-1.308-.455-.17-.853.114-1.137.285-.398.34-.683.568-1.024.966-.284.228-.568.341-.853.057-2.445-1.25-4.435-3.411-5.458-5.913-.227-.341-.114-.625.17-.853a16.92 16.92 0 0 0 1.024-.967c.227-.227.512-.625.398-1.08-.341-1.08-.682-2.274-1.023-3.354z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <div className="peer flex w-full flex-col">
                              <input
                                className="peer w-full rounded bg-transparent pt-2 pb-1 pl-12 text-md outline-none focus:pr-6 pr-6"
                                type="tel"
                                name="phone"
                                id=""
                                placeholder={t("sale.Phone number") + "*"}
                              />

                            </div>
                          </div>
                        </div>

                        <div className="transition-all md:mb-4 mb-1 col-span-2">
                          <div className="relative w-full z-foreground custom-select camper-car no-border text-left">

                          </div>
                        </div>


                        <div className="transition-all md:mb-4 mb-1 col-span-2">
                          <div className="relative z-foreground flex w-full flex-col">
                            <textarea
                              rows="5"
                              className="peer relative mb-4 w-full items-center rounded border bg-white p-4 pt-6 text-md text-black outline-none transition-all focus:border-primary dark:bg-off-black-desat dark:text-off-white dark:focus-within:border-primary-dark border-transparent shadow dark:shadow-dark"
                              name="message"
                              id=""
                              placeholder={t("sale.Your message") + "...*"}
                            ></textarea>

                          </div>
                        </div>

                        <div className="mt-2 mb-4 col-span-2">
                          <div className="relative flex">
                            <input
                              type="checkbox"
                              className="peer h-7 w-7 min-w-[28px] cursor-pointer appearance-none rounded-sm border border-grey bg-white outline-none transition-all checked:border-primary checked:bg-primary focus:border-primary dark:border-grey-dark dark:bg-off-black-desat"
                              name=""
                              id=""
                              required

                            />
                            <div className="align-top pointer-events-none absolute left-0.5 top-0.5 hidden cssvar-secondary-to-white peer-checked:block child:h-6 child:w-6 dark:cssvar-secondary-to-off-white">
                              <svg
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m21.906 9.488-8.547 9.56-3.45-3.096a1.757 1.757 0 0 0-2.563.209c-.64.792-.547 1.98.21 2.659l4.764 4.274c.735.66 1.833.602 2.496-.13l9.73-10.869a1.954 1.954 0 0 0-.069-2.665 1.758 1.758 0 0 0-2.571.058z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                            <label className="ml-3 cursor-pointer" htmlFor="">
                              {/* <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                              {t("sale.I have read the")}{" "}
                                <Link
                                  to={"/privacystatement"}
                                  className="text-primary underline hover:text-off-black dark:text-primary-light hover:dark:text-off-white"
                                >
                                {t("sale.privacy")}
                                </Link>
                                &nbsp;{t("sale.statement")}
                              </div> */}
                              {
                                i18n.resolvedLanguage === 'en' ?
                                  <>
                                    <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                      I have read the &nbsp;
                                      <Link
                                        to={"/privacystatement"}
                                        className="inline-block text-primary underline hover:text-off-black dark:text-primary-light hover:dark:text-off-white"
                                      >
                                        data {t("sale.privacy")}&nbsp;{t("sale.statement")}
                                      </Link>

                                    </div>
                                  </>
                                  :
                                  <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                    {t("sale.I have read the")}{" "}
                                    <Link
                                      to={"/privacystatement"}
                                      className="inline-block text-primary underline hover:text-off-black dark:text-primary-light hover:dark:text-off-white"
                                    >
                                      {t("sale.privacy")}
                                    </Link>
                                    &nbsp;{t("sale.statement")}
                                  </div>
                              }

                            </label>
                          </div>
                          <div className="relative bottom-4 overflow-hidden bg-warning text-center text-xs font-medium text-black transition-all mt-1 rounded max-h-0"></div>
                        </div>
                      </div>
                      <div className="text-danger fs-12" >    {Error ? <p style={{ color: "red" }} className="mb-2 flex w-full animate-wiggle items-center justify-between rounded bg-warning px-4 py-2 text-lg text-white">{Error}</p> : ''}</div>
                      <button
                        type="submit"
                        className="group/button block disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark p-3 pl-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated pr-5 mt-2 w-full md:w-auto md:min-w-[50%]"
                      >
                        <div className="flex h-full items-center justify-center">
                          <span className="flex">{t("sale.Send")}</span>
                        </div>
                      </button>
                      <p className="mt-5 text-xs">{t("sale.*Mandatory field")}</p>
                    </form>
                }
              </div>
            </div>
            <div className="pt-0 pb-8 2xl:pb-12 text-center mx-auto max-w-3xl">
              <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                {t("sale.You have problems with the contact form? Just send us an e-mail at")}{" "}
                <Link
                  to={"mailto:info@borntocamp.de"}
                  className="text-primary underline hover:text-off-black dark:text-primary-light hover:dark:text-off-white"
                >
                  info@borntocamp.de
                </Link>
              </div>
            </div>
          </div>

          <div className="flex justify-center pt-4 2xl:pt-2 pb-12 2xl:pb-1">
            <Link to="/campers" className="group/button disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white bg-green-bt dark:bg-primary-dark p-3 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated pl-3 py-2 inline-block">
              <div className="flex h-full items-center justify-between">
                <div className="inline-block align-top mr-2 rounded-full bg-white p-1 child:h-7 child:w-7 dark:bg-off-white">
                  <img src="images/camper-model.svg" alt="camper-model" />
                </div>
                <span className="flex">{t("sale.To the campers")}</span>
                <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g fill="none" fillRule="evenodd">
                      <path
                        d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                        fill="var(--svg-color-secondary, #163435)"
                      ></path>
                    </g>
                  </svg>
                </div>
              </div>
            </Link>
          </div>


        </div>
      </div>

      <Footer />
    </>
  );
};

export default Campersale;
