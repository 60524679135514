import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// import swal from "sweetalert";
import Userdashboard from '../../Pages/Userdashboard/Userdashboard'
import DocumentService from '../../Api/services/DocumentService';
import Loader from "../../Components/Loader/Loader";
import Loader2 from "../../Components/Loader/loader2";
import { useTranslation } from "react-i18next";
const AddDocument = () => {
    const { i18n, t } = useTranslation();
    const getId = useLocation()
    // console.log(getId.state)
    const user_id = getId.state.id
    const [Error, setError] = useState('');
    const [loader, setLoader] = useState(false);
    const [file, setFile] = useState('')
    const navigate = useNavigate();

    const onFileChange = (e) => {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(e.target.files[0])
        fileReader.onload = () => {
            var document_upload = fileReader.result
            setFile(document_upload)
        }
    }
    const onSubmit = async (event) => {
        event.preventDefault();

        if (file === "") {
            setError(t("Login.Please Upload Document!!!"))
            return true;
        } else {
            setLoader(true)
            try {
                var userData = {
                    "user_id": user_id,
                    "document_title": event.target.document_title.value,
                    "document_upload": file ? file.split(',')[1] : "",

                }
                let responseData = await DocumentService.store(userData).json()
                if (responseData.status === true) {
                    console.log(responseData)
                    navigate(`/view_document/${user_id}`);
                }
                // console.log(responseData.data)
            } catch (error) {
                if (error.name === 'HTTPError') {
                    const errorJson = await error.response.json();

                    setError(errorJson.message)
                }
            }
            setLoader(false)
        }
    }


    return (
        <>
            <Userdashboard />

            <div className='userdashboard-content mt-5'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                                        <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                                            <h2 className="heading mb-0 text-lg">{t("Login.Add Document")}</h2>

                                        </div>
                                        <div className="h-100vh p-5 md:p-10 mt-12 mb-12 relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md h-full w-full overflow-auto">

                                            {loader ? <Loader2 /> : <form onSubmit={onSubmit}>
                                                <div className="mb-10">

                                                    <label className="flex flex-col text-lg mb-3" htmlFor="">{t("Login.Document Type")}</label>
                                                    {/* <input
                                            className="w-full h-full font-light outline-none"
                                            type="text" name="document_title"
                                        /> */}
                                                    <select className='select-document' name="document_title">
                                                        <option value="Drivers License (Front Side)">{t("Login.Drivers License (Front Side)")}</option>
                                                        <option value="Drivers License (Back Side)">{t("Login.Drivers License (Back Side)")}</option>
                                                        <option value="ID (Front Side)">{t("Login.ID (Front Side)")}</option>
                                                        <option value="ID (Back Side)">{t("Login.ID (Back Side)")}</option>
                                                    </select>
                                                </div>
                                                {
                                                    i18n.resolvedLanguage === 'en' ?
                                                        <>
                                                            <div className="mb-10 bg-clip-border">

                                                                <label className="flex flex-col text-lg mb-3" htmlFor="">{t("Login.Document Upload")}</label>
                                                                <div className="upload-wrapper">
                                                                    <div className="upload-file">
                                                                        <input
                                                                            className="upload-input h-full font-light outline-none"
                                                                            type="file" name="document_Upload" onChange={onFileChange} accept="image/png, image/jpeg"
                                                                        />
                                                                    </div>

                                                                    <img className="img-drop-area" height={file ? 200 : 0} width={file ? 200 : 0} src={file}
                                                                        alt='' />
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <div className="mb-10 bg-clip-border germanfileupload">

                                                            <label className="flex flex-col text-lg mb-3" htmlFor="">{t("Login.Document Upload")}</label>
                                                            <div className="upload-wrapper">
                                                                <div className="upload-file">
                                                                    <input
                                                                        className="upload-input h-full font-light outline-none"
                                                                        type="file" name="document_Upload" onChange={onFileChange} accept="image/png, image/jpeg"
                                                                    />
                                                                </div>

                                                                <img className="img-drop-area" height={file ? 200 : 0} width={file ? 200 : 0} src={file}
                                                                    alt='' />
                                                            </div>
                                                        </div>
                                                }
                                                {/*  */}
                                                <div>
                                                    <button type="submit" className="green-bg group/button block disabled:opacity-50 border border-transparent full-rounded text-md text-white dark:text-off-white dark:bg-primary-dark p-2 pl-5 pr-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated md:mt-auto">{t("Login.Submit")}</button>
                                                </div>
                                            </form>}
                                            <p className="red-alert mt-3">{Error}</p>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
};

export default AddDocument;
