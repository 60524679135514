import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import "@splidejs/react-splide/css";
import "@splidejs/react-splide/css/core";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ContactService from "../../Api/services/ContactService";

import Userdashboard from '../../Pages/Userdashboard/Userdashboard'
// import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
const UserContactUs = () => {
  const [Error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isFormSubmit, setisFormSubmit] = useState(false);
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  useEffect(() => {

  }, []);



  const onSubmitForm = async (event) => {
    event.preventDefault();
    setSuccess("");
    setError("");
    try {
      var userData = {
        firstName: event.target.firstName.value,
        email: event.target.email.value,
        phone: event.target.phone.value,
        bookingNumber: event.target.order_id.value,
        message: event.target.message.value,
        type: "Login",
        "lng": i18n.resolvedLanguage
      };
      if (event.target.firstName.value === "") {
        setError(t("Login.The first name field is required."))
      }
      else if (event.target.email.value === "") {
        setError(t("Login.The email field is required."))
      }
      else if (event.target.phone.value === "") {
        setError(t("Login.The phone field is required."))
      }
      else {
        let responseData = await ContactService.store(userData).json();
        if (responseData.status === true) {
          event.target.reset();
          setisFormSubmit(true);

        }

      }
    } catch (error) {
      console.log(error);
      if (error.name === "HTTPError") {
        const errorJson = await error.response.json();
        setError(
          errorJson.message.substr(0, errorJson.message.lastIndexOf("."))
        );
      }
    }
  };
  return (
    <>
      <Userdashboard />
      <div className='userdashboard-content mt-5'>
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-12">
              <div>
                <div className="2xl:pt-6 pb-4 2xl:pb-8">
                  <div className="mx-auto flex max-w-3xl flex-col items-center">
                    <div className="inline-flex items-center justify-center rounded-full border border-primary bg-white p-3 dark:border-primary-dark dark:bg-off-white mb-5 md:mb-6">
                      <div className="inline-block align-top child:h-10 child:w-10">
                        <img
                          src="images/contact-form-img.svg"
                          alt="interested-camper"
                        />
                      </div>
                    </div>
                    <h2 className="whitespace-pre-line break-words mb-4 text-center md:mb-5 text-xl md:text-2xl">
                      {t("Contact.Contact form")}
                    </h2>
                    <div className="mb-4 text-center md:mb-5">
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("Login.Would you like to modify your booking or do you have any general inquiries")}?
                        {i18n.resolvedLanguage === 'en' ? <><br />{t("Login.Dont hesitate to contact us—were here to help")}.</> : ""}<br /> {t("Login.You can also reach us by phone or send us a WhatsApp message")} <br />{t("Login.at")} +49 163 2937354.
                        <br />
                        {t("Login.We aim to respond to your inquiries and assist you within 12 hours")}
                      </div>
                    </div>
                    {isFormSubmit ? (
                      <>
                        <div className="opacity-100 scale-100 success-msg text-center">
                          <div className="inline-flex items-center justify-center rounded-full orange-border bg-white p-1 dark:bg-off-white">
                            <div className="inline-block align-top">
                              <svg
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fill-rule="evenodd">
                                  <path
                                    d="m21.906 9.488-8.547 9.56-3.45-3.096a1.757 1.757 0 0 0-2.563.209c-.64.792-.547 1.98.21 2.659l4.764 4.274c.735.66 1.833.602 2.496-.13l9.73-10.869a1.954 1.954 0 0 0-.069-2.665 1.758 1.758 0 0 0-2.571.058z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                          </div>
                          <h2 className="mt-4 max-w-lg text-lg-paragraph">
                            {t("aboutus.Thank you for your message!")}<br /> {t("aboutus.We'll respond as quickly as we can")}
                          </h2>
                        </div>
                      </>
                    ) :


                      <form
                        className="flex w-full flex-col items-center"
                        onSubmit={onSubmitForm}
                      >
                        <div className="grid grid-cols-1 gap-x-4 md:grid-cols-2 w-full">
                          <div className="transition-all mb-4 col-span-2">
                            <div className="relative z-foreground md:mb-4 mb-0 flex h-[60px] items-center rounded border bg-white text-black transition-all focus-within:border-primary focus:border-primary dark:bg-off-black-desat dark:text-off-white dark:focus-within:border-primary-dark border-transparent shadow dark:shadow-dark">
                              <div className="inline-block align-top absolute pl-3 child:h-7 child:w-7 dark:cssvar-secondary-to-off-white">
                                <svg
                                  width="32"
                                  height="32"
                                  viewBox="0 0 32 32"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M16 14.6527C13.6105 14.6527 11.6736 12.7158 11.6736 10.3264C11.6736 7.93688 13.6105 6 16 6C18.3895 6 20.3264 7.93688 20.3264 10.3264C20.3264 12.7158 18.3895 14.6527 16 14.6527Z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M16.1565 15.9742C12.2188 15.8883 9 19.0545 9 22.9726C9 23.4621 9.05258 23.9383 9.15042 24.3979C9.26224 24.9247 9.72616 25.3021 10.2643 25.3021H21.7361C22.2693 25.3021 22.7352 24.9324 22.847 24.4112C23.0091 23.6528 23.05 22.8501 22.9329 22.0181C22.4596 18.6482 19.5587 16.0487 16.1565 15.9742Z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </svg>
                              </div>
                              <div className="peer flex w-full flex-col">
                                <input
                                  className="peer w-full rounded bg-transparent pt-2 pb-1 pl-12 text-md outline-none focus:pr-6 pr-6"
                                  type="text"
                                  name="firstName"
                                  placeholder={t("Contact.First & Last Name1*")}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="transition-all mb-4 col-span-2 md:col-span-1">
                            <div className="relative z-foreground md:mb-4 mb-0 flex h-[60px] items-center rounded border bg-white text-black transition-all focus-within:border-primary focus:border-primary dark:bg-off-black-desat dark:text-off-white dark:focus-within:border-primary-dark border-transparent shadow dark:shadow-dark">
                              <div className="inline-block align-top absolute pl-3 child:h-7 child:w-7 dark:cssvar-secondary-to-off-white">
                                <svg
                                  width="32px"
                                  height="32px"
                                  viewBox="0 0 32 32"
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <title>icon_contact_mail</title>
                                  <g
                                    id="icon_contact_mail"
                                    stroke="none"
                                    strokeWidth="1"
                                    fill="none"
                                    fillRule="evenodd"
                                  >
                                    <g
                                      id="Group"
                                      transform="translate(5.000000, 7.700000)"
                                      fill="var(--svg-color-secondary, #163435)"
                                    >
                                      <path
                                        d="M17.1541722,0 C18.5243618,0 19.2165846,0.133674373 19.9368674,0.518885858 C20.6000248,0.873546296 21.1264537,1.39997522 21.4811141,2.06313259 C21.8663256,2.78341544 22,3.47563815 22,4.8458278 L21.9981828,11.9648343 C21.9810588,13.1622375 21.8406449,13.810058 21.4811141,14.482322 C21.1264537,15.1454793 20.6000248,15.6719082 19.9368674,16.0265687 C19.2165846,16.4117802 18.5243618,16.5454545 17.1541722,16.5454545 L4.8458278,16.5454545 C3.47563815,16.5454545 2.78341544,16.4117802 2.06313259,16.0265687 C1.39997522,15.6719082 0.873546296,15.1454793 0.518885858,14.482322 C0.133674373,13.7620391 0,13.0698164 0,11.6996267 L0,4.8458278 C0,3.47563815 0.133674373,2.78341544 0.518885858,2.06313259 C0.873546296,1.39997522 1.39997522,0.873546296 2.06313259,0.518885858 C2.78341544,0.133674373 3.47563815,0 4.8458278,0 Z M20,5.563 L11,12.1092247 L2,5.564 L2,11.6996267 L2.00467769,12.1173697 C2.02375106,12.8767631 2.10330814,13.2040417 2.28251225,13.5391242 C2.45077309,13.8537447 2.69170989,14.0946815 3.0063303,14.2629423 C3.31348932,14.4272127 3.61409085,14.507752 4.24698433,14.5347258 L4.6271902,14.5443078 L17.3728098,14.5443078 C17.4423488,14.5435357 17.5086323,14.5423663 17.5719151,14.5407769 L17.9176389,14.5260186 C18.4406182,14.4924165 18.7144342,14.4122791 18.9936697,14.2629423 C19.3082901,14.0946815 19.5492269,13.8537447 19.7174877,13.5391242 C19.9265592,13.1481946 20,12.767887 20,11.6996267 L20,5.563 Z M17.1541722,2 L4.8458278,2 L4.42808487,2.00467769 C3.66869148,2.02375106 3.34141286,2.10330814 3.0063303,2.28251225 C2.69170989,2.45077309 2.45077309,2.69170989 2.28251225,3.0063303 C2.24438729,3.07761778 2.21077244,3.14855205 2.1812906,3.22336911 L10.999,9.63500008 L19.8187094,3.22336911 C19.7892276,3.14855205 19.7556127,3.07761778 19.7174877,3.0063303 C19.5492269,2.69170989 19.3082901,2.45077309 18.9936697,2.28251225 C18.6585871,2.10330814 18.3313085,2.02375106 17.5719151,2.00467769 L17.1541722,2 Z"
                                        id="Combined-Shape"
                                      ></path>
                                    </g>
                                  </g>
                                </svg>
                              </div>
                              <div className="peer flex w-full flex-col">
                                <input
                                  className="peer w-full rounded bg-transparent pt-2 pb-1 pl-12 text-md outline-none focus:pr-6 pr-6"
                                  type="email"
                                  name="email"
                                  placeholder={t("Contact.E-mail*")}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="transition-all mb-4 col-span-2 md:col-span-1">
                            <div className="relative z-foreground md:mb-4 mb-0 flex h-[60px] items-center rounded border bg-white text-black transition-all focus-within:border-primary focus:border-primary dark:bg-off-black-desat dark:text-off-white dark:focus-within:border-primary-dark border-transparent shadow dark:shadow-dark">
                              <div className="inline-block align-top absolute pl-3 child:h-7 child:w-7 dark:cssvar-secondary-to-off-white">
                                <svg
                                  width="32"
                                  height="32"
                                  viewBox="0 0 32 32"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g fill="none" fillRule="evenodd">
                                    <path
                                      d="M12.004 8.139c-.171-.569-.342-1.024-.91-1.08L10.014 7C8.875 6.945 7 8.651 7 11.493c.057 4.947 7.448 13.02 12.338 13.476 2.9.284 4.72-1.422 4.776-2.616V21.33c.057-.569-.398-.796-.966-1.024-1.08-.398-2.16-.91-3.241-1.308-.455-.17-.853.114-1.137.285-.398.34-.683.568-1.024.966-.284.228-.568.341-.853.057-2.445-1.25-4.435-3.411-5.458-5.913-.227-.341-.114-.625.17-.853a16.92 16.92 0 0 0 1.024-.967c.227-.227.512-.625.398-1.08-.341-1.08-.682-2.274-1.023-3.354z"
                                      fill="var(--svg-color-secondary, #163435)"
                                    ></path>
                                  </g>
                                </svg>
                              </div>
                              <div className="peer flex w-full flex-col">
                                <input
                                  className="peer w-full rounded bg-transparent pt-2 pb-1 pl-12 text-md outline-none focus:pr-6 pr-6"
                                  type="tel"
                                  name="phone"
                                  id=""
                                  placeholder={t("Contact.Phone number")}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="transition-all mb-4 col-span-2">
                            <div className="relative z-foreground md:mb-4 mb-0 flex h-[60px] items-center rounded border bg-white text-black transition-all focus-within:border-primary focus:border-primary dark:bg-off-black-desat dark:text-off-white dark:focus-within:border-primary-dark border-transparent shadow dark:shadow-dark">
                              <input
                                className="peer w-full rounded bg-transparent pt-2 pb-1 pl-5 text-md outline-none focus:pr-6 pr-6"
                                type="text"
                                name="order_id"
                                id=""
                                placeholder={t("Login.Booking Number")}
                              />
                            </div>
                          </div>

                          <div className="transition-all md:mb-4 mb-0 col-span-2">
                            <div className="relative z-foreground flex w-full flex-col">
                              <textarea
                                rows="5"
                                className="peer relative mb-4 w-full items-center rounded border bg-white p-4 pt-6 text-md text-black outline-none transition-all focus:border-primary dark:bg-off-black-desat dark:text-off-white dark:focus-within:border-primary-dark border-transparent shadow dark:shadow-dark"
                                name="message"
                                id=""
                                placeholder={t("Contact.Your message...*")}
                                required
                              ></textarea>
                            </div>
                          </div>

                          <div className="mt-2 mb-4 col-span-2">
                            <div className="relative flex">
                              <input
                                type="checkbox"
                                className="peer h-7 w-7 min-w-[28px] cursor-pointer appearance-none rounded-sm border border-grey bg-white outline-none transition-all checked:border-primary checked:bg-primary focus:border-primary dark:border-grey-dark dark:bg-off-black-desat"
                                name=""
                                id=""
                                required
                              />
                              <div className="align-top pointer-events-none absolute left-0.5 top-0.5 hidden cssvar-secondary-to-white peer-checked:block child:h-6 child:w-6 dark:cssvar-secondary-to-off-white">
                                <svg
                                  width="32"
                                  height="32"
                                  viewBox="0 0 32 32"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g fill="none" fillRule="evenodd">
                                    <path
                                      d="m21.906 9.488-8.547 9.56-3.45-3.096a1.757 1.757 0 0 0-2.563.209c-.64.792-.547 1.98.21 2.659l4.764 4.274c.735.66 1.833.602 2.496-.13l9.73-10.869a1.954 1.954 0 0 0-.069-2.665 1.758 1.758 0 0 0-2.571.058z"
                                      fill="var(--svg-color-secondary, #163435)"
                                    ></path>
                                  </g>
                                </svg>
                              </div>
                              <label className="ml-3 cursor-pointer" htmlFor="">
                                {
                                  i18n.resolvedLanguage === 'en' ?
                                    <>
                                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                        I have read the &nbsp;
                                        <Link
                                          to={"/privacystatement"}
                                          className="inline-block text-primary underline hover:text-off-black dark:text-primary-light hover:dark:text-off-white"
                                        >
                                          data {t("sale.privacy")}&nbsp;{t("sale.statement")}
                                        </Link>

                                      </div>
                                    </>
                                    :
                                    <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                                      {t("sale.I have read the")}{" "}
                                      <Link
                                        to={"/privacystatement"}
                                        className="inline-block text-primary underline hover:text-off-black dark:text-primary-light hover:dark:text-off-white"
                                      >
                                        {t("sale.privacy")}
                                      </Link>
                                      &nbsp;{t("sale.statement")}
                                    </div>
                                }
                              </label>
                            </div>
                            <div className="relative bottom-4 overflow-hidden bg-warning text-center text-xs font-medium text-black transition-all mt-1 rounded max-h-0"></div>
                          </div>
                        </div>
                        <div className="text-danger fs-12">
                          {/* <h4 style={{ color: "red" }}>{Error}</h4> */}
                          {Error ? (
                            <p
                              style={{ color: "red" }}
                              className="mb-2 flex w-full animate-wiggle items-center justify-between rounded bg-warning px-4 py-2 text-lg text-white"
                            >
                              {Error}
                            </p>
                          ) : (
                            ""
                          )}
                          <h4 style={{ color: "green" }}>{success}</h4>
                        </div>
                        <button
                          type="submit"
                          className="group/button block disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark p-3 pl-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated pr-5 mt-2 w-full md:w-auto md:min-w-[50%]"
                        >
                          <div className="flex h-full items-center justify-center">
                            <span className="flex">{t("sale.Send")}</span>
                          </div>
                        </button>
                        <p className="mt-5 text-xs">{t("sale.*Mandatory field")}</p>
                      </form>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default UserContactUs;
