import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import VoucherService from "../../Api/services/VoucherService";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import Header from "../../Components/Header/Header";
import { CLIENT_ID } from '../../Config/Config';
import { useTranslation } from "react-i18next";
const initialOptions = {
  clientId: "test",
  currency: "EUR",
  intent: "capture",
  "client-id": CLIENT_ID
};
const Payment = (props) => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  if (localStorage.getItem("payment_status_voucher") == null) {
    navigate("/")
  }
  const [searchParams, setSearchParams] = useSearchParams();
  const getDetails = useLocation();
  var userData;
  var price;
  var voucher;

  if (searchParams.get("canceled")) {
    userData = JSON.parse(localStorage.getItem('voucherpropsPersonal'))
    price = JSON.parse(localStorage.getItem('voucherprice'))
    voucher = JSON.parse(localStorage.getItem('voucherplan'))
  } else {
    userData = getDetails.state.userData;
    price = getDetails.state.price;
    voucher = getDetails.state.voucher;
    localStorage.setItem("voucherpropsPersonal", JSON.stringify(userData));
    localStorage.setItem("voucherprice", JSON.stringify(price));
    localStorage.setItem("voucherplan", JSON.stringify(voucher));
  }
  console.log(voucher.title, "usersa")
  const [is_Cod, setIs_code] = useState(true);
  const [currentInformation, setCurrentInformation] = useState();
  const [termcheck, settermcheck] = useState(false);
  const [show, setShow] = useState(false);
  const [success, setSuccess] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");
  const [orderID, setOrderID] = useState(false);
  const [amount, setAmount] = useState(0);
  const [payerdetail, setPayerDetails] = useState('');
  const [is_paypal, setIs_paypal] = useState(false);
  const [error, setError] = useState();
  var imageUrl = process.env.REACT_APP_LARAVEL_IMAGE_URL + 'Voucher/';
  useEffect(() => {
   
    if (localStorage.getItem("payment_status_voucher") == null) {
      
      navigate("/")
    }
    if (success) {
      checkOrder(orderID)
    }
  }, [success]);

  const handleAmount = () => {
    setIs_paypal(is_paypal ? false : true)
    if (is_paypal === false) {
      setShow(true)
      setAmount(price)
      setIs_code(is_Cod ? false : true)
    }
    else {
      setShow(false)
      setIs_code(is_Cod ? false : true)
    }

  }

  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          description: "Buy Voucher",
          amount: {
            value: price ? price : 10,
          },
        },
      ],
    }).then((orderID) => {
      setOrderID(orderID);
      return orderID;
    });
  };
  // check Approval
  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      const { payer } = details;
      setPayerDetails(details)
      // console.log(details);
      setSuccess(true);
    });
  };

  const checkOrder = async (orderID) => {
    try {
      var data = {
        price: price,
        order_id: orderID,
        is_cod: 0,
        is_paid: 1,
        "lng": i18n.resolvedLanguage
      }
      await VoucherService.update(userData.data.id, data).json()
      setAmount(0)
      navigate('/thankyou-voucher')
    }
    catch (error) {
      if (error.name === 'HTTPError') {
        const errorJson = await error.response.json();
        setErrorMessage(errorJson.message)
      }
    }
  }
  const onSubmitForm = async (event) => {
    event.preventDefault();
    const randomId =
      "id-" +
      Math.random().toString(36).substr(2, 9) +
      "-" +
      Date.now().toString(36);

    try {
      var tripuserData1 = {
        // first_last_name:userData.name,
        // start_date:startDate,
        // end_date:endDate,
        // zip_code:userData.zip

        "first_last_name": userData.name,
        "email": userData.email,
        "dob": userData.dob,
        "phone": userData.phone,
        "address_addition": userData.address_addition,
        "street": userData.street,
        "zip_code": userData.zip,
        "city": userData.city,
        "country": userData.country,
        "company": userData.company,
        "vat_id": userData.vat_id,
        "status": 0,
        "user_id": userData.user_id,
        "is_newsletter": userData.is_newsletter,
        "price": price,
        "voucher_id": voucher.id,
        "voucher_code": Math.random().toString(36).substr(2, 9),
        "lng": i18n.resolvedLanguage


      }
      if (is_Cod) {
        var tripuserData = {
          // first_last_name:userData.name,
          // start_date:startDate,
          // end_date:endDate,
          // zip_code:userData.zip

          "first_last_name": userData.name,
          "email": userData.email,
          "dob": userData.dob,
          "phone": userData.phone,
          "address_addition": userData.address_addition,
          "street": userData.street,
          "zip_code": userData.zip,
          "city": userData.city,
          "country": userData.country,
          "company_name": userData.company,
          "vat_id": userData.vat_id,
          "status": 0,
          "user_id": userData.user_id,
          "is_newsletter": userData.is_newsletter,
          "price": price,
          "voucher_id": voucher.id,
          "voucher_code": Math.random().toString(36).substr(2, 9),
          "lng": i18n.resolvedLanguage


        }
      
        let responseData = await VoucherService.store(tripuserData).json()
        var data = {
          price: price,
          order_id: randomId,
          is_cod: 1,
          is_paid: 0,
          "lng": i18n.resolvedLanguage
        }
        await VoucherService.update(responseData.data.id, data).json()
        setAmount(0)
        navigate('/thankyou-voucher')
      } else {
        var stripedata = {
          "price": price,
          "name": voucher.title,
          "first_last_name": userData.name,
          "email": userData.email,
          "request_data": tripuserData1,
          "lng": i18n.resolvedLanguage,
          
        }
        var striptripuserData = {
          // first_last_name:userData.name,
          // start_date:startDate,
          // end_date:endDate,
          // zip_code:userData.zip

          "first_last_name": userData.name,
          "email": userData.email,
          "dob": userData.dob,
          "phone": userData.phone,
          "address_addition": userData.address_addition,
          "street": userData.street,
          "zip_code": userData.zip,
          "city": userData.city,
          "country": userData.country,
          "company": userData.company,
          "vat_id": userData.vat_id,
          "status": 0,
          "user_id": userData.user_id,
          "is_newsletter": userData.is_newsletter,
          "price": price,
          "voucher_id": voucher.id,
          "voucher_code": Math.random().toString(36).substr(2, 9),
         
          "lng": i18n.resolvedLanguage


        }
     
        var resp = await VoucherService.stripCheckout(stripedata).json();
        localStorage.setItem("stripesessionvoucher", JSON.stringify(resp.data));
        localStorage.setItem("stripedatavoucher", JSON.stringify(striptripuserData));
        localStorage.setItem("stripedatavoucherprice", JSON.stringify(price));
        localStorage.setItem("stripedatavoucherorderid", JSON.stringify(randomId));
        window.location.href = resp.url
      }
    }
    catch (error) {

      if (error.name === 'HTTPError') {
        const errorJson = await error.response.json();

        setErrorMessage(errorJson.message)
      }
    }
  }
  return (
    <>
      <div className="relative flex flex-col min-h-screen font-brand pb-16 xl:pb-0">
        <Header />
        <main className="flex-grow  xl:mb-64 min-h-screen pt-100">
          {/* <div className="fw-500 mb-4 mt-2 w-full text-center text-3xl">Your offer</div> */}
          <div className="w-full max-w-5xl xl:flex mx-auto detail-width">
            <div className="xl:ml-3 xl:mr-3 mr-3 ml-3 xl:block xl:w-1/2 md:mt-5 pt-0 md:pt-7 pt-4">
              <div className="aspect-w-2 aspect-h-1 w-full">
                <img
                  className="top-radius -mb-8 w-full h-full object-contain object-bottom"
                  src={imageUrl + voucher.voucher_image}
                  alt="side-detail-img"
                />
              </div>
              <div className="bg-white rounded-button shadow rounded-none divide-secondary divide-opacity-disabled divide-y overflow-hidden rounded -mt-10 pt-10 divide-y-0">
                <div className="flex flex-col items-center p-6">
                  <span className="fw-500 text-center"></span>
                  <h3 className="fw-500 break-words text-3xl text-center">
                    {voucher.title}
                  </h3>
                  <span className="mb-1 mt-2 text-md text-primary text-orange block text-18">{t("Rent.Price")}: {price}€</span>
                </div>
              </div>

            </div>

            <div className="xl:w-1/2 ml-3 mr-3 xl:ml-0 xl:mr-0 ">
              <div className="mt-4 p-4 pb-10 xl:pb-0 bg-white xl:mt-0 xl:p-10 xl:bg-transparent rounded">
                <form className="mx-auto max-w-xl space-y-4 md:space-y-6" onSubmit={onSubmitForm}>

                  <div className="grid grid-cols-2 items-center">
                    <div className="py-2 text-xl font-normal medium-text">{t("Rent.Total")}</div>
                    <div className="justify-self-end text-xl font-normal medium-text price-font">{price}.00 €</div>


                  </div>

                  <div className="font-light">{t("Rent.You will be redirected to the relevant payment provider upon booking")}.</div>




                  <div className="payment-button relative">
                    <input className="payment-radio-input" id="sofrt" type="checkbox" checked={is_paypal} onClick={handleAmount} />
                    <label className="mb-3 transition-all flex items-center px-4 h-16 border border-secondary border-opacity-disabled hover:border-transparent hover:shadow bg-white rounded cursor-pointer space-x-4" htmlFor="">
                      <div className="w-12 h-8 bg-white rounded-lg shrink-0 mb-3">

                        <img src="../images/card-logo-payment.png" alt="payment-logo" />
                      </div>
                      <div className="flex-grow overflow-hidden">

                        <div className="overflow-hidden text-ellipsis text-lg sm:text-xl" >
                          {t("Rent.Card Payment")}
                        </div>
                      </div>
                      <div className="checked-hidden justify-self-end w-7 h-7 border border-secondary border-opacity-disabled rounded-full shrink-0"></div>
                      <svg className="checked-show hidden w-7 h-7 cssvar-secondary-to-primary" width="32" height="32"><svg viewBox="0 0 32 32" fill="none" id="icon-check-circle"><path fillRule="evenodd" clipRule="evenodd" d="M27 16C27 22.0751 22.0751 27 16 27C9.92487 27 5 22.0751 5 16C5 9.92487 9.92487 5 16 5C22.0751 5 27 9.92487 27 16ZM10.7533 16.2181C11.0708 15.7706 11.6483 15.7117 12.0431 16.0865L14.2653 18.1963L19.6446 11.4402C19.9837 11.0144 20.5638 10.9928 20.9402 11.3921C21.3167 11.7914 21.347 12.4603 21.0079 12.8862L15.0481 20.3715C14.7223 20.7806 14.1708 20.8189 13.7915 20.4588L10.8933 17.7072C10.4984 17.3323 10.4358 16.6656 10.7533 16.2181Z" fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"></path></svg></svg>
                    </label>

                  </div>
                  <div className="payment-button relative">
                    <input name="payment-info" className="payment-radio-input" id="sofrt" type="checkbox" onChange={() => {


                      setIs_code(is_Cod ? false : true)
                      setIs_paypal(is_paypal ? false : true)
                      setShow(false)
                    }
                    }
                      checked={is_Cod} />
                    <label className="transition-all flex items-center px-4 h-16 border border-secondary border-opacity-disabled hover:border-transparent hover:shadow bg-white rounded cursor-pointer space-x-4" htmlFor="">
                      <div className="w-12 h-8 bg-white rounded-lg shrink-0">
                        <svg fill="#000000" width="32" height="32" version="1.1" id="Capa_1"
                          viewBox="0 0 425.521 425.521">
                          <g>
                            <path d="M317.233,94.552l4.794-9.228c0.273-0.06,0.549-0.106,0.818-0.191c2.402-0.76,4.404-2.442,5.565-4.678l16.597-31.944
		c2.419-4.656,0.606-10.391-4.05-12.81l-31.86-16.553c-2.235-1.162-4.842-1.387-7.244-0.628c-2.402,0.76-4.404,2.442-5.565,4.678
		l-16.597,31.944c-1.353,2.604-1.38,5.544-0.329,8.054l-4.744,9.131c-10.808-3.918-22.088-6.841-33.732-8.655v-8.673h8.623
		c5.247,0,9.5-4.253,9.5-9.5V9.5c0-5.247-4.253-9.5-9.5-9.5h-73.497c-5.247,0-9.5,4.253-9.5,9.5v35.999c0,5.247,4.253,9.5,9.5,9.5
		h8.624v8.673C97.581,77.233,30.749,152.711,30.749,243.509c0,100.362,81.65,182.012,182.012,182.012s182.012-81.65,182.012-182.012
		C394.773,181.993,364.091,127.514,317.233,94.552z M308.767,40.387l15.001,7.793l-7.837,15.084l-15.001-7.793L308.767,40.387z
		 M295.206,73.909l8.929,4.639l-3.048,5.867c-2.926-1.631-5.897-3.188-8.919-4.66L295.206,73.909z M185.512,19h54.497v16.999
		h-54.497V19z M203.636,54.999h18.25v6.727c-3.023-0.15-6.065-0.229-9.125-0.229c-3.06,0-6.101,0.079-9.125,0.229V54.999z
		 M212.76,406.521c-89.885,0-163.012-73.127-163.012-163.012S122.876,80.497,212.76,80.497c89.885,0,163.012,73.127,163.012,163.012
		S302.646,406.521,212.76,406.521z"/>
                            <path d="M212.76,98.783c-79.802,0-144.726,64.924-144.726,144.727s64.924,144.727,144.726,144.727
		c79.803,0,144.727-64.924,144.727-144.727S292.564,98.783,212.76,98.783z M212.76,369.236
		c-69.326,0-125.726-56.401-125.726-125.727s56.4-125.727,125.726-125.727s125.727,56.401,125.727,125.727
		S282.086,369.236,212.76,369.236z"/>
                            <path d="M235.385,155.2v-16.706c0-5.247-4.253-9.5-9.5-9.5s-9.5,4.253-9.5,9.5v14.922h-7.249v-14.922c0-5.247-4.253-9.5-9.5-9.5
		s-9.5,4.253-9.5,9.5V155.2c-15.731,4.954-27.172,19.677-27.172,37.023v21.98c0,17.346,11.441,32.068,27.172,37.023v59.565
		c-4.942-3.605-8.172-9.423-8.172-15.994c0-5.247-4.253-9.5-9.5-9.5s-9.5,4.253-9.5,9.5c0,17.346,11.441,32.068,27.172,37.023
		v16.706c0,5.247,4.253,9.5,9.5,9.5s9.5-4.253,9.5-9.5v-14.922h7.249v14.922c0,5.247,4.253,9.5,9.5,9.5s9.5-4.253,9.5-9.5v-16.706
		c15.732-4.954,27.173-19.677,27.173-37.023v-21.98c0-17.346-11.441-32.069-27.173-37.023v-59.565
		c4.942,3.605,8.173,9.423,8.173,15.995v12.211c0,5.247,4.253,9.5,9.5,9.5s9.5-4.253,9.5-9.5v-12.211
		C262.558,174.876,251.117,160.154,235.385,155.2z M216.385,172.416v61.593h-7.249v-61.593H216.385z M181.964,214.203v-21.98
		c0-6.572,3.23-12.389,8.172-15.994v53.969C185.194,226.592,181.964,220.774,181.964,214.203z M209.136,314.603v-61.593h7.249
		v61.593H209.136z M243.558,272.816v21.98c0,6.572-3.23,12.39-8.173,15.995v-53.97C240.327,260.426,243.558,266.244,243.558,272.816
		z"/>
                          </g>
                        </svg>
                      </div>
                      <div className="flex-grow overflow-hidden">

                        <div className="overflow-hidden text-ellipsis text-lg sm:text-xl cash-text" >
                          {t("Rent.Cash")}
                        </div>
                      </div>
                      <div className="checked-hidden justify-self-end w-7 h-7 border border-secondary border-opacity-disabled rounded-full shrink-0"></div>
                      <svg className="checked-show hidden w-7 h-7 cssvar-secondary-to-primary" width="32" height="32"><svg viewBox="0 0 32 32" fill="none" id="icon-check-circle"><path fillRule="evenodd" clipRule="evenodd" d="M27 16C27 22.0751 22.0751 27 16 27C9.92487 27 5 22.0751 5 16C5 9.92487 9.92487 5 16 5C22.0751 5 27 9.92487 27 16ZM10.7533 16.2181C11.0708 15.7706 11.6483 15.7117 12.0431 16.0865L14.2653 18.1963L19.6446 11.4402C19.9837 11.0144 20.5638 10.9928 20.9402 11.3921C21.3167 11.7914 21.347 12.4603 21.0079 12.8862L15.0481 20.3715C14.7223 20.7806 14.1708 20.8189 13.7915 20.4588L10.8933 17.7072C10.4984 17.3323 10.4358 16.6656 10.7533 16.2181Z" fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"></path></svg></svg>
                    </label>

                  </div>


                  <div className="book-now-text font-light">{t("Rent.By booking your Camper, you agree to the terms and conditions of Born To Camp. Our privacy policy applies")}.</div>

                  <button
                    type="submit"
                    className="grid-cols1 inline-grid grid-rows-1 text-white bg-secondary hover:bg-primary mt-4 pl-5 pr-2 w-full h-12 rounded"
                  >
                    <span className="relative flex col-start-1 row-start-1 items-center justify-between w-full h-full transition-opacity duration-300">
                      <span className="transform transition-transform duration-300 translate-x-0">

                        <span className="text-20">{t("Rent.Finish Payment")}</span>
                      </span>
                      <svg
                        className="w-7 h-7 cssvar-secondary-to-white pointer-events-none"
                        width="32"
                        height="32"
                      >
                        <svg
                          viewBox="0 0 32 32"
                          fill="none"
                          id="icon-chevron-right"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M22.1256 15.9999L14.479 8.35332C14.009 7.88273 13.2445 7.88213 12.7739 8.35211L12.3532 8.77342C11.8809 9.24581 11.8833 10.0073 12.3544 10.4785L17.8759 15.9999L12.3544 21.5219C11.8833 21.9925 11.8809 22.7546 12.3532 23.2264L12.7739 23.6477C13.2445 24.1182 14.009 24.117 14.479 23.6465L22.1256 15.9999Z"
                            fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"
                          ></path>
                        </svg>
                      </svg>
                    </span>
                  </button>

                </form>
                <div className="absolute left-0 top-0 hidden w-full h-20 pointer-events-none md:block">
                  <div className="relative mx-auto max-w-7xl h-full">

                  </div>
                </div>
              </div>
            </div>



          </div>
        </main>
      </div>
    </>
  );
};
export default Payment;
