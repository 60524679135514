import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams, } from "react-router-dom";
import CamperService from "../../Api/services/CamperService";
import Loader from "../Loader/Loader";
import { NumericFormat } from 'react-number-format';
import { t } from "i18next";
const Campersrent_fillter = (props) => {
  var imageUrl = process.env.REACT_APP_LARAVEL_CAMPER_IMAGE_URL;
  const [camper, setCamper] = useState([]);
  const navigate = useNavigate();
  const [error, setError] = useState("")
  const [loader, setLoder] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [responseData, setResponseData] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    getCamper();
  }, []);

  const params = { location: searchParams.get('location'), date: searchParams.get('date') };

  // ----------- get Campers  ------------ //
  const getCamper = async () => {

    let dateRange = searchParams.get('date');

    if (dateRange) {

      let startDate1 = dateRange.split("_");

      setStartDate(startDate1[0]);
      setEndDate(startDate1[1]);


      try {
        setLoder(true)
        var userData = {
          "start_date": startDate1[0],
          "end_date": startDate1[1]
        }
        let camperList = await CamperService.camper_filter(userData).json();
        var responsedata = camperList.data.filter(function (item) {
          return item.camper_type_id == 1;
        });

        if (responseData.status === false) {
          setError(responseData.message)
        } else {
          console.log(camperList,"filterdata")
          setCamper(responsedata);
        }
      } catch (error) {
        if (error.name === 'HTTPError') {
          const errorJson = await error.response.json();
          setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
        }
      }
    }
    setLoder(false)
  };



  return (
    <>

      {loader ? <Loader /> : ''}

      <div className="pt-0 pb-8 2xl:pb-12 grid gap-x-5 sm:grid-cols-2 lg:grid-cols-3 justify-center sale-overview">
        {camper && camper.map((item, index) => (
           item.status == 1 ?
          <div className="relative flex flex-col mt-6 text-gray-700 bg-white shadow-md bg-clip-border rounded-xl mt-[50%] hover-bg">
            <Link
              to={`/availabilityinfo/${item.id}?location=1&date=${params.date}&pickup=14&return=11`}
              className="box-border h-full max-w-5xl pt-[60%]"
            >
              <div
                className="relative  mx-4 text-white  -mt-[60%] bg-clip-border rounded-xl bg-blue-gray-500 shadow-blue-gray-500/40 camper-divImg">
                <picture className="text-0">
                  <img
                    className="box-img group-hover:scale-105 inline-block max-w-full object-cover dark:brightness-90 aspect-[1.5] w-full  group-hover:scale-105 transition-all duration-300 divImg -mt-[50%]"
                    height={item.image ? 100 : 0}
                    width={item.image ? 100 : 0}
                    src={'images/0.gif'}
                    style={{ backgroundImage: "url(" + imageUrl + item.image + ")" }}
                    //  src={imageUrl + item.image}

                    alt="rent-img-one"
                  />
                </picture>
              </div>
              <div className="p-0 xl:p-0">

                <h5 className="mb-2 text-xl xs:mb-6 price-font fw-600 text-center tracking-wide ">
                  {item.title}
                </h5>
                {camper[index].camper_details == null ? '' :
                  <h4 className="block mb-2 text-md  font-semibold leading-snug tracking-normal text-blue-gray-900 text-center title-camper">  {camper[index].camper_details.sub_title}</h4>

                }

                
                <h4 className="block text-center text-md">
                {t("Rent.from")}{" "}
                  {camper[index].camper_price == null ? '' :
                    <span className='price-font text-md inline-block align-bottom'>
                        <NumericFormat
                        value={camper[index].camper_price.price}
                        thousandSeparator={'.'}
                        decimalSeparator={','}
                        displayType={'text'}

                      />
                       €
                    </span>
                  }
                   <p className="text-md inline-block align-bottom ">&nbsp;{t("Rent.per Night")}</p>
                </h4>
              </div>
              <div>
                <div className="mt-4 mb-1  bg-off-white p-3 dark:bg-black px-8">

                  {camper[index].features == null ? '' :
                    <div>
                      {camper[index].features && camper[index].features.map((features) => {
                        return (
                          <div className="mb-2 flex last:mb-0">
                            <div className="flex items-center justify-center self-start rounded-full bg-white p-1 dark:bg-off-white">
                              <div className=" rounded-full bg-white inline-block align-top child:h-5 child:w-5 xs:child:h-6 xs:child:w-6 sm:child:h-5 sm:child:w-5 2md:child:h-6 2md:child:w-6 lg:child:h-5 lg:child:w-5 xl-padded-with-arrows:child:h-6 xl-padded-with-arrows:child:w-6">
                                <img src={process.env.REACT_APP_LARAVEL_FEATURE_IMAGE_URL + '' + features.smallicon} width="24px" height="24px" className="rounded-full bg-white" />
                              </div>
                            </div>
                            <p className="mt-1 ml-2 text-sm xs:text-sm sm:text-xs 2md:text-sm lg:text-xs xl-padded-with-arrows:text-sm">
                              {features.title}
                            </p>
                          </div>

                        );
                      })}
                    </div>
                  }

                </div>


              </div>
            </Link>
            <div className="p-6 pt-0 ">
              <div className="mt-4">


                <Link
                  className="hover-border mt-4 group/button block disabled:opacity-50 rounded text-md dark:text-off-white p-3 pl-5 transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated text-off-black bg-transparent border hover:border-secondary group-hover/parent:border-secondary hover:bg-transparent group-hover/parent:bg-transparent dark:bg-transparent dark:hover:bg-transparent dark:group-hover/parent:bg-transparent dark:hover:text-off-white dark:group-hover/parent:text-off-white dark:hover:border-off-white dark:group-hover/parent:border-off-white mb-4 group-hover:border-secondary dark:group-hover:border-off-white"

                  to={`/rentconfiguration/${item.id}?location=1&date=${params.date}&pickup=14&return=11`}
                >

                  <div className="flex h-full items-center justify-between">
                    <span className="flex">{t("Rent.Book now")}</span>
                    <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-black ml-2  dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g fill="none" fillRule="evenodd">
                          <path
                            d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                            fill="var(--svg-color-secondary, #163435)"
                          ></path>
                        </g>
                      </svg>
                    </div>
                  </div>
                </Link>


                <Link
                  className="group/button block disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark p-3 pl-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated w-full"

                  to={`/availabilityinfo/${item.id}?location=1&date=${params.date}&pickup=14&return=11`}
                >
                  <div className="flex h-full items-center justify-between">
                    <span className="flex">{t("Rent.Availability & info")}</span>
                    <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-secondary">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g fill="none" fillRule="evenodd">
                          <path
                            d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                            fill="var(--svg-color-secondary, #FFF)"
                          ></path>
                        </g>
                      </svg>

                    </div>
                  </div>
                </Link>

              </div>
            </div>

          </div>
          :""
        ))}
      </div>
    </>
  );
};

export default Campersrent_fillter;
