import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate, useSearchParams } from 'react-router-dom';
import swal from "sweetalert";
import Userdashboard from '../../Pages/Userdashboard/Userdashboard'
import DocumentService from '../../Api/services/DocumentService';

import { useTranslation } from "react-i18next";
const ViewDocument = () => {
    var imageUrl = process.env.REACT_APP_LARAVEL_DOCUMENT_IMAGE_URL;
    const params = useParams()
    // console.log(params)
    const { i18n, t } = useTranslation();
    const [Error, setError] = useState('');
    const [DocumentList, setDocumentList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPage = 100;
    const lastIndex = currentPage * recordsPage;
    const firstIndex = lastIndex - recordsPage;
    const records = DocumentList.slice(firstIndex, lastIndex);
    const npage = Math.ceil(DocumentList.length / recordsPage)
    const number = [...Array(npage + 1).keys()].slice(1)
    const navigate = useNavigate();
    function prePage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    function changeCPage(id) {
        setCurrentPage(id);
    }
    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }
    useEffect(() => {
        var id = params.id;
        if (localStorage.getItem('usertoken')) {
            getDocumentList(id);
        }
        else {
            navigate('/');
        }

    }, [])
    const handleDelete = async (e) => {

        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this data!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {

            if (willDelete) {
                try {
                    DocumentService.destroy(e).json();
                    swal("Your Document has been deleted successfully!", {
                        icon: "success",
                    });

                } catch (error) {
                    console.log(error)
                    if (error.name === 'HTTPError') {
                        const errorJson = error.response.json();
                        setError(errorJson.message)
                        swal("Error.Your Document has not been deleted!!!", {
                            icon: "error",
                        });
                    }
                }
                var id = params.id;
                getDocumentList(id);
            }
        }).catch(() => {
            swal("Your Deal is safe!");
        });

    }
    const getDocumentList = async (id) => {
        try {
            let responseData = await DocumentService.show(id).json()
            setDocumentList(responseData.data)
            console.log(responseData)
        } catch (error) {
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();

                setError(errorJson.message)
            }
        }
    }


    return (
        <>
            <Userdashboard />
            <div className='userdashboard-content mt-5'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                                        <div className="table-top-heading tbl-caption d-flex justify-content-between text-wrap align-items-center">
                                            <h2 className="heading mb-3 text-lg">{t("Login.Document Detail List")}</h2>
                                            <Link className='mobile-none green-bg group/button block disabled:opacity-50 border border-transparent full-rounded text-md text-white dark:text-off-white dark:bg-primary-dark p-2 pl-5 pr-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated md:mt-auto' to={'/add_document'} state={params}>{t("Login.Add Document")}</Link>
                                        </div>
                                        <p className='md:text-left text-center'>{t("Login.Please upload a photo of the following documents here")}<br /> {t("Login.driver's licenses of all drivers (front and back) and the ID of the main renter (front and back)")}.</p>
                                        <div className="text-center desktop-hidden">
                                            <Link className='rounded-3xl mt-3 d-flex text-center inline-block w-auto green-bg group/button block disabled:opacity-50 border border-transparent full-rounded text-md text-white dark:text-off-white dark:bg-primary-dark p-2 pl-5 pr-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated md:mt-auto' to={'/add_document'} state={params}>{t("Login.Add Document")}</Link>
                                        </div>
                                        <div className="mt-6 md:mt-10 md:mb-12 mb-6 relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md h-full w-full overflow-auto">
                                            <table id="empoloyees-tblwrapper" className="table ItemsCheckboxSec dataTable no-footer mb-0 table-auto w-full min-w-max table-auto text-left">
                                                <thead>
                                                    <tr style={{ textAlign: 'center' }}>
                                                        <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                                            <strong>{t("Login.No")}.</strong>
                                                        </th>
                                                        <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                                            <strong>{t("Login.Document")}</strong>
                                                        </th>
                                                        <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                                            <strong>{t("Login.Document Image")}</strong>
                                                        </th>
                                                        <th className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                                            <strong>{t("Login.Action")}</strong>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {
                                                        records.map((element, index) => {

                                                            return (
                                                                <tr style={{ textAlign: 'center' }}>
                                                                    <td className='p-4 border-b border-blue-gray-50'>
                                                                        {index + 1}
                                                                    </td>
                                                                    <td className='p-4 border-b border-blue-gray-50'>
                                                                        {
                                                                            element.document_title === 'Drivers License (Front Side)'
                                                                                ?
                                                                                t("Login.Drivers License (Front Side)")
                                                                                : ''
                                                                        }
                                                                        {
                                                                            element.document_title === 'Drivers License (Back Side)' ?
                                                                                t("Login.Drivers License (Back Side)")
                                                                                :
                                                                                ''
                                                                        }
                                                                        {
                                                                            element.document_title === 'ID (Front Side)' ?
                                                                                t("Login.ID (Front Side)")
                                                                                :
                                                                                ''
                                                                        }
                                                                         {
                                                                            element.document_title === 'ID (Back Side)' ?
                                                                                t("Login.ID (Back Side)")
                                                                                :
                                                                                ''
                                                                        }
                                                                    </td>
                                                                    <td className='p-4 border-b border-blue-gray-50 document-wrapper'>
                                                                        <img className="img-drop-area" height={element.document_upload ? 70 : 0} width={element.document_upload ? 70 : 0} src={element.document_upload ? imageUrl + element.document_upload : "blog"} alt='blog' />
                                                                        {/* {element.document_upload} */}
                                                                    </td>


                                                                    <td className='p-4 border-b border-blue-gray-50'>
                                                                        <div className="d-flex">

                                                                            <Link className="btn btn-danger me-1 p-2 rounded bg-[#ef4444] text-white" onClick={() => handleDelete(element.id)} > {t("Login.Delete")}</Link>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }

                                                </tbody>

                                            </table>
                                        </div>
                                        {/* <div className="d-sm-flex text-center justify-content-between align-items-center">
                                                <div className='dataTables_info'>
                                                    Showing {lastIndex - recordsPage + 1} to{" "}
                                                    {DocumentList.length < lastIndex ? DocumentList.length : lastIndex}
                                                    {" "}of {DocumentList.length} entries
                                                </div>
                                                <div
                                                    className="dataTables_paginate paging_simple_numbers justify-content-center"
                                                    id="example2_paginate"
                                                >
                                                    <Link
                                                        className="paginate_button previous disabled"
                                                        to="#"
                                                        onClick={prePage}
                                                    >
                                                        <i className="fa-solid fa-angle-left" />
                                                    </Link>
                                                    <span className='p-2 border-b border-blue-gray-50 bg-white '>
                                                        {number.map((n, i) => (
                                                            <Link className={`paginate_button ${currentPage === n ? 'current' : ''} `} key={i}
                                                                onClick={() => changeCPage(n)}
                                                            >
                                                                {n}

                                                            </Link>
                                                        ))}
                                                    </span>
                                                    <Link
                                                        className="paginate_button next"
                                                        to="#"
                                                        onClick={nextPage}
                                                    >
                                                        <i className="fa-solid fa-angle-right" />
                                                    </Link>
                                                </div>
                                            </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
};

export default ViewDocument;
