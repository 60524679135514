import React, { useEffect } from "react";

import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import PaymentService from '../../Api/services/PaymentService';
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
const Thankyou = () => {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams()
  useEffect(() => {
    // window.noBack();
    checkstatus()
    localStorage.removeItem("payment_status")
    localStorage.removeItem("stripedata")
    localStorage.removeItem("stripesession")

  }, []);
  const { i18n, t } = useTranslation();

  Cookies.set('Token', 'value', { expires: 7 })
  const checkstatus = async () => {
    var getId = searchParams.get('id')
    if (getId) {
      var p_service = await PaymentService.checkstripepayment(getId).json()
    }
    // Old Code Start
    /*if (localStorage.getItem('stripesession')) {
      var stripesessiondata = JSON.parse(localStorage.getItem('stripesession'));
      var p_service = await PaymentService.checkstripepayment(stripesessiondata.id).json()
      if (p_service.status == true) {
        if (localStorage.getItem('stripedata')) {
          var stripesessiondata = JSON.parse(localStorage.getItem('stripedata'));
          var payment_service = await PaymentService.store(stripesessiondata).json()

          if (payment_service.status == true) {
            var paymentid = payment_service.payment_id;
            var data = {
              name: p_service.name,
              email: p_service.email,
              id: paymentid
            }
            await PaymentService.updatepaymentStatus(data).json()
            localStorage.removeItem("stripedata")
            localStorage.removeItem("stripesession")
            localStorage.removeItem("payment_status")
          }

        }

      }

    }*/
    // old Code End
    // localStorage.removeItem("payment_status")
  }
  return (
    <>
      <Header />

      <div className="min-h-screen bg-off-white">



        <div className="pb-5 text-center">
          <div className="overflow-hidden">
            <div className="-ml-[5%] w-[110%] overflow-hidden rounded-b-[60%20%] px-[5%]">
              <div className="relative">
                <div className="overflow-hidden">
                  <img
                    src="../images/Thank you pages.jpg"
                    alt="hero-banner"
                    className="absolute max-h-full w-full scale-110 object-cover blur-lg dark:brightness-90 sm:aspect-hero-regular 3xl:aspect-hero-long aspect-square"
                  />
                </div>
                <picture className="flex max-h-[65vh] w-full text-0">
                  <img
                    className="z-0 max-h-full w-full object-cover dark:brightness-90 sm:aspect-hero-regular 3xl:aspect-hero-long aspect-square"
                    src="../images/Thank you pages.jpg"
                    alt="hero-banner"
                    width="2552"
                    height="1286"
                  />
                </picture>
                <div className="absolute top-0 left-0 flex h-full w-full flex-col items-center justify-end">
                  <div className="pb-12 2xl:pb-14 w-full bg-gradient-to-t from-black-pure/80 to-transparent pt-20 md:pt-14 xl:pt-32">
                    <h1 className="whitespace-pre-line break-words m-auto max-w-4xl px-8 text-xl md:text-2xl text-white drop-shadow-text dark:text-off-white xs:text-2xl xl:text-3xl mb-5 md:mb-8 xl:mb-12">
                      {t("thankyou.Thank You")}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mx-5 max-w-screen-xl xl-padded:mx-auto">

          <div className="xl:pt-8 pt-3 2xl:pt-8 pb-8 2xl:pb-12 mx-auto flex max-w-4xl flex-col items-center text-center">
            <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark mb-4 child:h-20 child:w-20">
              <img src="../images/aboutus-header-banner.svg" alt="aboutus-header-banner" />
            </div>
            {/* <p className="mb-4 whitespace-pre-line text-md">What we strive for</p> */}
            <h2 className="whitespace-pre-line break-words text-xl md:text-2xl mb-4">
              {t("thankyou.Thank you for your trust!")}
            </h2>
            <p className="mb-4 whitespace-pre-line text-2md font-normal md:text-lg-paragraph md:font-normal text-20">
              {t("thankyou.You will soon receive a confirmation email with your login password for our website.")} {t("thankyou.There, you can manage your booking, add equipment, view instructions, and much more!")}
            </p>
            <p className="mb-2 whitespace-pre-line text-2md font-normal md:text-lg-paragraph md:font-normal text-20">
              {t("thankyou.We look forward to meeting you upon pickup!")}
            </p>
            <p className="mb-2 whitespace-pre-line text-2md font-normal md:text-lg-paragraph md:font-normal text-20">
              {t("thankyou.Do you have any questions? Just reach out to us!")}
            </p>
            <p className="mb-2 whitespace-pre-line text-2md font-normal md:text-lg-paragraph md:font-normal text-20">
              WhatsApp: +49 163 2937354
            </p>
            <p className="mb-2 whitespace-pre-line text-2md font-normal md:text-lg-paragraph md:font-normal text-20">
              {t("thankyou.Go To")}<Link className="w-full text-center hover:text-primary hover:dark:text-primary-light mb-2 last:mb-0" to={'/'}> {t("thankyou.Home")}</Link>
            </p>
          </div>


        </div>

      </div>

      <Footer />
    </>
  );
};

export default Thankyou;
