import React, { useState,useEffect } from 'react';
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

import { Link,useParams } from "react-router-dom";

import '@splidejs/react-splide/css';
import '@splidejs/react-splide/css/core';

import HomeService from "../../Api/services/HomeService";
import Vanlife from '../../Components/Vanlife/Vanlife';

import{t}from 'i18next';

const Bloginner = () => {
  var imageUrl = process.env.REACT_APP_LARAVEL_BLOG_IMAGE_URL;
  const params = useParams(); 
  const [blog, setBlog] = useState([]);
  useEffect(() => {
    var id = params.id;
    getBlogById(id);
  }, []);
  const getBlogById = async (id) => {
    try {
      let blogDetail = await HomeService.show(id).json();
      console.log(blogDetail.data);
      setBlog(blogDetail.data);
    } catch (error) {
      console.log(error);
    }
  };
  var fourrow = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1273,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <>
      <Header />

      <div className="min-h-screen bg-off-white">


        <div className="pb-5 text-center">
          <div className="overflow-hidden">
            <div className="-ml-[5%] w-[110%] overflow-hidden rounded-b-[60%20%] px-[5%]">
              <div className="relative">
                <div className="overflow-hidden">
                  <img
                    src="images/camper-subscription-banner.png"
                    alt="camper-subscription-banner"
                    className="absolute max-h-full w-full scale-110 object-cover blur-lg dark:brightness-90 sm:aspect-hero-regular 3xl:aspect-hero-long aspect-square"
                  />
                </div>
                <picture className="flex max-h-[60vh] w-full text-0">
                  <img
                    className="z-0 max-h-full w-full object-cover dark:brightness-90 sm:aspect-hero-regular 3xl:aspect-hero-long aspect-square"
                    // src="/images/blog-img.png"
                    src={imageUrl + blog.blog_image}
                    alt="blog-hero-banner"
                    width="2552"
                    height="1286"
                  />
                </picture>
                <div className="absolute top-0 left-0 flex h-full w-full flex-col items-center justify-end">
                  <div className="pb-12 2xl:pb-14 w-full bg-gradient-to-t from-black-pure/80 to-transparent pt-20 md:pt-14 xl:pt-32">
                    <h1 className="whitespace-pre-line break-words m-auto max-w-4xl px-8 text-xl text-white drop-shadow-text dark:text-off-white xs:text-2xl xl:text-3xl mb-5 md:mb-8 xl:mb-12">
                       {blog.title} 
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
        </div>

        <div className="bg-off-white">
          <h2 class="pt-8 2xl:pt-12 -mt-2 mb-1 text-center text-md not-italic mb-6 whitespace-pre-line text-xl 2xl:text-2xl price-font fw-600 blog-title">{blog.sub_title}</h2>
        

          <div className="mx-5 max-w-screen-xl xl-padded:mx-auto"> 
            <div className='custom-blog-inner' dangerouslySetInnerHTML={{ __html: blog.description}}>
              
          </div>
           
            <div className="">
              <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mask-image relative p-0 md:flex md:min-h-[400px] md:rounded-xl md:bg-transparent dark:md:bg-transparent justify-end">
                  <Link to={'/campers'} className="w-full md:flex justify-end">
                  <div className="md:hidden">
                    <div className="overflow-hidden">
                      <div className="-ml-[5%] w-[110%] overflow-hidden rounded-b-[60%20%] px-[5%]">
                        <div className="relative">
                          <picture className="text-0">
                            <img className="inline-block max-w-full object-cover dark:brightness-90 max-h-[40vh] w-full group-hover/parent:scale-110 transition-all duration-300"
                              src="/images/home-banner-img.png"
                              alt="routenservice"
                            />
                          </picture>
                        </div>
                      </div>
                    </div>
                  </div>
                  <picture className="text-0 absolute top-0 left-0 z-background hidden h-full w-full transition-all duration-300 group-hover/parent:scale-105 md:block md:group-hover/parent:scale-[103%]">
                    <img className="inline-block max-w-full object-cover dark:brightness-90 h-full w-full"
                      src="/images/home-banner-img.png"
                      alt="routenservice"
                    />
                  </picture>
                  <div className="relative -mt-10 mb-4 flex justify-center xs:mb-4 md:hidden">
                    <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark child:h-20 child:w-20">
                      <img
                        src="/images/trip-planner-side-img.svg"
                        alt="selection-icon"
                      />
                    </div>
                  </div>
                  <div className="z-0 m-6 mt-0 flex flex-col text-center xs:mt-0 md:m-5 md:mt-5 md:min-h-[calc(100%-theme(spacing.5)*2)] md:w-full md:max-w-1/2 md:rounded-lg md:bg-white md:p-8 md:text-left md:shadow md:dark:bg-off-black-desat xl:max-w-[45%] 2xl:m-9 2xl:mt-9 2xl:min-h-[calc(100%-theme(spacing.9)*2)]">
                    <div className="md:mb-6 mb-2 flex items-center justify-center md:justify-start">
                      <div className="relative mr-4 hidden md:block">
                        <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark child:h-16 child:w-16">
                          <img
                            src="/images/trip-planner-side-img.svg"
                            alt="selection-icon"
                          />
                        </div>
                      </div>
                      <p className="text-lg">{t("bloginner.Flexible Camper Rent")}</p>
                    </div>
                    <h2 className="whitespace-pre-line break-words md:mb-4 mb-2 text-xl xs:mb-6">
                      {t("bloginner.Our Rental Campers")}
                    </h2>
                    <div className="mb-4 md:mb-6">
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("bloginner.From compact campervans to spacious caravans – our models combine cutting-edge technology, comfort, and clever design so you can enjoy the feeling of home on the go")}
                      </div>
                      <div className="mobile-none min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                    </div>
                    <div className="group/button block disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark p-3 pl-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated w-full md:mt-auto">
                      <div className="flex h-full items-center justify-between">
                        <span className="flex">{t("bloginner.Explore our Models")}</span>
                        <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                          <svg width="32" height="32" viewBox="0 0 32 32">
                            <g fill="none" fillRule="evenodd">
                              <path
                                d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                                fill="var(--svg-color-secondary, #fff)"
                              ></path>
                            </g>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                  </Link>
                </div>
              </div>

            

              <div className="">
                <div className="xl:pt-8 pt-0 text-center mx-auto max-w-3xl">
                  <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                    <Link to="/campers" className="group/button disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark p-3 pl-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated inline-block">
                      <div className="flex h-full items-center justify-between">
                        <span className="flex">{t("home.Rent a camper")}</span>
                        <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                        <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                          <g fill="none" fillRule="evenodd">
                          <path d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z" fill="var(--svg-color-secondary, #163435)"></path>
                          </g>
                        </svg>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            
            </div>
           
          </div>
        </div>

        <div className="mx-5 max-w-screen-xl xl-padded:mx-auto">
        
          <div className="xl:pt-8 pt-0 2xl:pt-12 md:pb-8 pb-0 2xl:pb-12">
              <div className="md:mx-5 max-w-screen-xl xl-padded:mx-auto pt-5 pb-8">
                  <Vanlife/>
              </div>
          </div>
        </div>

      </div>

      <Footer />
    </>
  );
};

export default Bloginner;
