import { useState,useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import LeftArrow from "../../Assests/Image/left-arrow.png";

import {t} from 'i18next';
const Userdashboard = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [userId, setuserId] = useState(0);
  const toggle = () => setIsOpen(!isOpen);
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("usertoken");
    localStorage.removeItem("user");
    
  }
  
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (localStorage.getItem('usertoken')) {
      const userd = JSON.parse(localStorage.getItem('user'));
      setuserId(userd.id)
 
    }
    else {
        navigate('/');
    }

}, [])
  return (
    <>
      <div className="mobile-header">
        <div className="mobile-header-wrap">
          <div className="panel-expand-control">
            <a
              
              onClick={toggle}
              className="d-none d-md-block"
            >
              <img src={LeftArrow} />
            </a>
            <a
              
              onClick={toggle}
              className="d-block d-md-none hamburger-icon"
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
              </svg>
              {/* close */}
            </a>
          </div>
          <div className="logo d-block d-md-none">
            <NavLink to="/" className="link main-logo">
              {/* <h2>Born To Camp</h2> */}
            </NavLink>
          </div>
        </div>
        <div className={isOpen ? "sidebar  sidebar-active" : "sidebar"}>
          <div Name="sidebar-top">
            <a
             
              className="btn-close"
              aria-label="Close"
              onClick={toggle}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
              </svg>
            </a>
            <div className="logo">
              <NavLink to="/" className="link">
                <img className="header-camper-logo" src="/images/born-camper-logo.png" alt="camper-logo" />
              </NavLink>
            </div>
            <ul>
              <li>
                <NavLink to={ '/my_order'} className="link">
                  <span className="link-icon">
                    <i className="fa fa-user" aria-hidden="true"></i>
                  </span>
                  <span className="link-text">{t("Login.Booking Overview")}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to={ '/video_tutorial'} className="link">
                  <span className="link-icon">
                    <i className="fa fa-user" aria-hidden="true"></i>
                  </span>
                  <span className="link-text">{t("Login.Camper Tutorial")}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to={ `/view_document/${userId}`} className="link">
                  <span className="link-icon">
                    <i className="fa fa-user" aria-hidden="true"></i>
                  </span>
                  <span className="link-text">{t("Login.Upload your Documents")}</span>
                </NavLink>
              </li>
              <li>
                <NavLink to={ '/user-contact-us'} className="link">
                  <span className="link-icon">
                    <i className="fa fa-user" aria-hidden="true"></i>
                  </span>
                  <span className="link-text">{t("Login.Contact & Help")}</span>
                </NavLink>
              </li>
              <li>
                <NavLink onClick={handleLogout} to={'/'} className="link">
                  {/* <span className="link-icon">
                    <i className="fa fa-user" aria-hidden="true"></i>
                  </span> */}
                  <span className="link-text">{t("Login.Logout")}</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Userdashboard;

