import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import Campersrent from "../../Components/Campersrent/Campersrent";
import { Link } from "react-router-dom";
import Equipments from "../../Components/Topequipped/Equipments";
import { useTranslation } from "react-i18next";
const Equipment = () => {
  const { i18n, t } = useTranslation();
  return (
    <>
      <Header />

      <div className="min-h-screen bg-off-white">
       
        <div className="pb-5 text-center">
          <div className="overflow-hidden">
            <div className="-ml-[5%] w-[110%] overflow-hidden rounded-b-[60%20%] px-[5%]">
              <div className="relative">
                <div className="overflow-hidden">
                  <img
                    src="images/Add-ons page title picture-min (1).jpg"
                    alt="jamy_grand_california"
                    className="absolute max-h-full w-full scale-110 object-cover blur-lg dark:brightness-90 sm:aspect-hero-regular 3xl:aspect-hero-long aspect-square"
                  />
                </div>
                <picture className="flex max-h-[60vh] w-full text-0">
                  <img
                    className="z-0 max-h-full w-full object-cover dark:brightness-90 sm:aspect-hero-regular 3xl:aspect-hero-long aspect-square"
                    src="images/Add-ons page title picture-min (1).jpg"
                    alt="jamy_grand_california"
                    width="2552"
                    height="1286"
                  />
                </picture>
                <div className="absolute top-0 left-0 flex h-full w-full flex-col items-center justify-end">
                  <div className="pb-12 w-full bg-gradient-to-t from-black-pure/80 to-transparent pt-20 md:pt-14 xl:pt-32">
                    <h1 className="whitespace-pre-line break-words m-auto max-w-4xl px-8 text-xl md:text-2xl text-white drop-shadow-text dark:text-off-white xs:text-2xl xl:text-3xl mb-5 md:mb-8 xl:mb-12">
                    {t("Equipment.Additional Equipment")}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mx-5 max-w-screen-xl xl-padded:mx-auto">

            <div className="pt-4 2xl:pt-8 pb-0 mx-auto flex max-w-3xl flex-col items-center text-center">
              <h2 className="whitespace-pre-line break-words text-xl md:text-2xl mb-4">{t("Equipment.Looking for extra equipment?")}</h2>
              <p className="whitespace-pre-line text-2md font-normal md:text-lg-paragraph md:font-normal text-20">{t("Equipment.Whether you dream of a relaxing hammock,")} {t("Equipment.want to wrap yourself in warm blankets,")} {t("Equipment.or wish to bring your bicycles – we have everything you need for your adventure! Your individuality is at the forefront, and in the booking process, you can add what makes your journey unique!")}</p>
            </div>

        </div>

            <div className='topeq-slider'>
               <div className='mx-5 pt-5 pb-8'>
                  <Equipments/>
               </div>
            </div>



            <div className="mx-5 max-w-screen-xl xl-padded:mx-auto">
              <div className="h-full pb-8 2xl:pb-12">
                <div className="text-center">
                  <Link
                    to={"/campers"}
                    className="text-18 w-auto group/button inline-block disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark p-3 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated pl-3 py-2 w-full"
                  >
                    <div className="flex h-full items-center justify-between">

                      <span className="flex">{t("Equipment.Rent a camper & equipment")}</span>
                      <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g fill="none" fillRule="evenodd">
                            <path
                              d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                              fill="var(--svg-color-secondary, #163435)"
                            ></path>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
        



      </div>

      <Footer />
    </>
  );
};

export default Equipment;
