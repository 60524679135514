import ky from 'ky';

let userData = localStorage.getItem('usertoken') ? localStorage.getItem('usertoken') : "";

const clientAuth = ky.extend({
  prefixUrl: 'https://api.borntocamp.de/api/camper/user',
  //prefixUrl : 'http://127.0.0.1:8000/api/camper/user',
  timeout:false,
  hooks: {
    beforeRequest: [
      (options) => {
        const localHeaders = JSON.parse(localStorage.getItem('usertoken'));

        if (localHeaders) {
          // console.log("token work",localHeaders);
          options.headers.set('Authorization', `Bearer ${localHeaders}`);
        }
      },
    ],
    afterResponse: [
			(_request, _options, response) => {
				// You could do something with the response, for example, logging.
				//log(response);

				// Or return a `Response` instance to overwrite the response.
				//return new Response('A different response', {status: 200});
			},
			// Or retry with a fresh token on a 403 error
			async (request, options, response) => {
				if (response.status === 401) {
          //Unauthorized
          console.log("Okay 401");
          window.location.replace("/login");    //redirect to login page
				}
        if(response.status == 422) {
         
          //console.log("Validation Errors");
          
        }
			}
		]
  },
  // headers: { 'Authorization': `Bearer ${userData}` }
});

export default clientAuth;