// import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import VoucherService from "../../Api/services/VoucherService";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@reach/listbox/styles.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import Loader from "../../Components/Loader/Loader";
import { t } from "i18next";
const Voucher = () => {
  var fiverow = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1273,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  const imageUrl = process.env.REACT_APP_LARAVEL_Voucher_IMAGE_URL;

  const [voucher, setVoucher] = useState([]);
  const [loader, setLoder] = useState(false);
  useEffect(() => {
    getVoucher();
  }, []);
  const getVoucher = async () => {
    try {
      setLoder(true);
      let VoucherList = await VoucherService.index().json();

      setVoucher(VoucherList.data);
    } catch (error) {
      console.log(error);
    }
    setLoder(false);
  };
  return (
    <>

      <Header />

      <div className="min-h-screen bg-off-white">
        <div className="text-center">
          <div className="overflow-hidden">
            <div className="-ml-[5%] w-[110%] overflow-hidden rounded-b-[60%20%] px-[5%]">
              <div className="relative">
                <div className="overflow-hidden">
                  <img
                    src="images/Main picture-min.jpg"
                    alt="camper-subscription-banner"
                    className="absolute max-h-full w-full scale-110 object-cover blur-lg dark:brightness-90 sm:aspect-hero-regular 3xl:aspect-hero-long aspect-square"
                  />
                </div>
                <picture className="flex max-h-[60vh] w-full text-0">
                  <img
                    className="z-0 max-h-full w-full object-cover dark:brightness-90 sm:aspect-hero-regular 3xl:aspect-hero-long aspect-square"
                    src="images/Main picture-min.jpg"
                    alt="blog-hero-banner"
                    width="2552"
                    height="1286"
                  />
                </picture>
                <div className="absolute top-0 left-0 flex h-full w-full flex-col items-center justify-end">
                  <div className="pb-12 2xl:pb-14 w-full bg-gradient-to-t from-black-pure/80 to-transparent pt-20 md:pt-14 xl:pt-32">
                    <h1 className="whitespace-pre-line break-words m-auto max-w-4xl px-8 text-xl md:text-2xl text-white drop-shadow-text dark:text-off-white xs:text-2xl xl:text-3xl mb-5 md:mb-8 xl:mb-12">
                      {t("Voucher.Voucher")}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark relative mx-auto -mt-12 child:h-24 child:w-24 2xl:-mt-14 2xl:child:h-28 2xl:child:w-28">
            <img className="" src="images/blog-header.svg" alt="blog-header" />
          </div>
        </div>

        <div className="pt-8 2xl:pt-12 pb-0 mx-auto flex max-w-3xl flex-col items-center text-center mobile-margin">
          <h2 className="whitespace-pre-line break-words text-xl md:text-2xl mb-4">
            {t("Voucher.Give memories")}
          </h2>
          <p className="whitespace-pre-line text-2md font-normal md:text-lg-paragraph md:font-normal">
            {t("Voucher.Experiences are what we remember even after years")}.<br/> {t("Voucher.Often, it's the lived stories that define a person.")}<br /> {t("Voucher.So, what could be better to give than an adventure?")}
          </p>
        </div>

        <div className="mx-5 max-w-screen-xl xl-padded:mx-auto">
          {loader ? <Loader /> :
            <div className="pt-8 2xl:pt-8">
              <div className="">
                <div className="mb-0 grid grid-cols-1 gap-20  md:gap-y-16 sm:grid-cols-2 lg:grid-cols-3">
                  {voucher &&
                    voucher.map((item, index) => (
                      <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent group flex h-full flex-col p-4 hover:shadow-elevated hover:dark:shadow-dark-elevated">
                        <Link
                          to={`/voucher-detail/${item.id}`}
                          className="box-border h-full max-w-5xl "
                        >
                          <div className="sm-radius mask-image shrink-0 overflow-hidden rounded-sm 2xs:rounded-md camper-divImg">
                            <picture className="text-0 h-full w-full">
                              <img
                                className="inline-block max-w-full object-cover dark:brightness-90 aspect-square max-h-[270px] w-full group-hover:scale-110 transition-all duration-300 divImg"
                                height={item.voucher_image ? 100 : 0}
                                width={item.voucher_image ? 100 : 0}
                                src={"images/0.gif"}
                                style={{
                                  backgroundImage:
                                    "url(" +
                                    imageUrl +
                                    item.voucher_image +
                                    ")",
                                }}
                                //  src={imageUrl + item.image}

                                alt="rent-img-one"
                              />
                            </picture>
                          </div>
                        </Link>
                        <div className="flex h-full flex-col items-center justify-center px-4 text-center">
                          <div className="my-2 md:my-4 font-semibold">
                            <p className="whitespace-pre-line text-lg my-2 md:my-4">
                              {item.title}
                            </p>
                            <div className="group/button disabled:opacity-50 border border-transparent text-md text-white dark:text-off-white button-primary-bg dark:bg-primary-dark hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated p-1 rounded-full my-0 md:my-4 inline-block px-4">
                              <Link
                                to={`/voucher-detail/${item.id}`}
                                className="box-border h-full max-w-5xl "
                              >
                                <div className="flex h-full items-center justify-center">
                                  <span className="flex tracking-widest">
                                    {item.startprice}€ - {item.endprice}€
                                  </span>
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          }


          <div className="pt-10 xl:pt-20 2xl:pt-20">
            <div className="md:mx-5 max-w-screen-xl xl-padded:mx-auto pt-0 pb-0">
              <Slider className="camper-slider" {...fiverow}>
                <div className="rounded bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-6 2xl:mt-14 2xl:h-[calc(100%-3.5rem)] pt-0 pb-7">
                  <div className="flex justify-center align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-12 mb-5 child:h-24 child:w-24 2xl:-mt-14 2xl:child:h-28 2xl:child:w-28">
                    <div className="rounded-full border-4 img-rounded border-primary bg-white p-5 small-img">
                      <img
                        className="small-logo"
                        src="images/flexible-customizable-icon.svg"
                        alt="services-icon"
                      />
                    </div>
                  </div>
                  <h3 className="mb-3 whitespace-pre-line text-center text-lg">
                    {t("Voucher.Flexible Travel Period")}
                  </h3>
                  <p className="whitespace-pre-line text-center">
                    {t("Voucher.Gift the voucher now and allow the recipient to decide when it suits them!")}
                  </p>
                </div>

                <div className="rounded bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-6 2xl:mt-14 2xl:h-[calc(100%-3.5rem)] pt-0 pb-7">
                  <div className="flex justify-center align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-12 mb-5 child:h-24 child:w-24 2xl:-mt-14 2xl:child:h-28 2xl:child:w-28">
                    <div className="rounded-full border-4 img-rounded border-primary bg-white p-5 small-img">
                      <img className="small-logo" src="images/three-years-icon.svg" alt="services-icon" />
                    </div>
                  </div>
                  <h3 className="mb-3 whitespace-pre-line text-center text-lg">
                    {t("Voucher.Long Validity")}
                  </h3>
                  <p className="whitespace-pre-line text-center">
                    {t("Voucher.Our voucher is valid for a full 3 years, providing ample time to plan and enjoy the adventure!")}
                  </p>
                </div>

                <div className="rounded bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-6 2xl:mt-14 2xl:h-[calc(100%-3.5rem)] pt-0 pb-7">
                  <div className="flex justify-center align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-12 mb-5 child:h-24 child:w-24 2xl:-mt-14 2xl:child:h-28 2xl:child:w-28">
                    <div className="rounded-full border-4 img-rounded border-primary bg-white p-5 small-img">
                      <img className="small-logo"
                        src="images/fast-delivery-svgrepo-com.svg"
                        alt="services-icon"
                      />
                    </div>
                  </div>
                  <h3 className="mb-3 whitespace-pre-line text-center text-lg">
                    {t("Voucher.Fast Delivery")}
                  </h3>
                  <p className="whitespace-pre-line text-center">
                    {t("Voucher.Receive your voucher within 48 hours via email. (Even faster if needed - contact us directly!)")}
                  </p>
                </div>

                <div className="rounded bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-6 2xl:mt-14 2xl:h-[calc(100%-3.5rem)] pt-0 pb-7">
                  <div className="flex justify-center align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-12 mb-5 child:h-24 child:w-24 2xl:-mt-14 2xl:child:h-28 2xl:child:w-28">
                    <div className="rounded-full border-4 img-rounded border-primary bg-white p-5">
                      <img className="small-logo" src="images/customize-icon.svg" alt="services-icon" />
                    </div>
                  </div>
                  <h3 className="mb-3 whitespace-pre-line text-center text-lg">
                    {t("Voucher.Customized Design")}
                  </h3>
                  <p className="whitespace-pre-line text-center">
                    {t("Voucher.Personalize your voucher with a heartfelt message and make your gift even more special!")}
                  </p>
                </div>

              </Slider>
            </div>
          </div>

          <div className="pt-12-custom xl:pt-12 pb-10 2xl:pb-16">
            <div className="overflow-hidden rounded bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg mx-auto flex max-w-3xl flex-col items-center md:p-8 2xl:p-16">
              <div className="inline-flex items-center justify-center rounded-full primary-borber border bg-white p-1 dark:bg-off-white mb-6 border-info dark:border-info">
                <div className="inline-block align-top child:h-8 child:w-8 2xl:child:h-10 2xl:child:w-10">
                  <svg
                    width="32px"
                    height="32px"
                    viewBox="0 0 32 32"
                    version="1.1"
                  >
                    <title>icon/actions_info</title>
                    <g
                      id="icon/actions_info"
                      stroke="none"
                      strokeWidth="1"
                      fill="EE7214"
                      fillRule="evenodd"
                    >
                      <path
                        d="M15.0627781,13.2833484 C14.2292216,13.2833484 13.4095577,13.2833484 12.5843367,13.2833484 C12.4040026,13.2849033 12.2246588,13.3085077 12.0508606,13.3535621 C11.8387141,13.3950229 11.676741,13.5559636 11.6451964,13.7566407 C11.5865132,13.9746677 11.7037133,14.2006319 11.9230486,14.2923451 C12.190053,14.4092135 12.476874,14.4813856 12.7704977,14.5055866 C13.5429267,14.5393932 13.8541211,14.8566551 13.7374232,15.6004 C13.5206985,16.9916712 13.2289538,18.3725403 12.9788868,19.7612109 C12.809397,20.6973934 12.6848928,21.2550492 12.5843367,22.2403035 C12.4530963,23.5262052 13.2109841,24.7401952 14.1368811,25.0131061 C15.0627781,25.286017 15.724825,25.286017 16.3214484,25.2794864 C17.5999796,25.2780159 18.8212793,24.7831633 19.6973522,23.9116198 C19.8564682,23.7743898 19.9759403,23.6017433 20.0446674,23.409722 C20.0782267,23.2419815 20.0359721,23.0687264 19.9279695,22.9312287 C19.7974575,22.8547704 19.6367149,22.8384803 19.4917416,22.8870201 C19.2639028,22.9572338 19.0610707,23.1028622 18.838789,23.1938799 C18.5790019,23.3073326 18.3070186,23.3945099 18.0274607,23.4539306 C17.8020089,23.5057029 17.5636393,23.4622801 17.3753928,23.335146 C17.1871463,23.2080119 17.0679843,23.0099721 17.0494211,22.7934019 C17.0026623,22.551429 16.9942363,22.3043299 17.0244144,22.0600589 C17.1188841,21.4567414 17.2522531,20.8586248 17.3689511,20.2605082 C17.6857025,18.6291236 18.0070849,16.9968722 18.3330981,15.3637539 C18.4220108,14.9164667 18.5720509,14.4769811 18.4720242,14.0062893 C18.4564927,13.79721 18.3517318,13.6027725 18.1813424,13.4667803 C18.010953,13.3307881 17.7892668,13.2646798 17.5662261,13.2833484 C16.7326696,13.2833484 15.8963346,13.2833484 15.0627781,13.2833484 Z"
                        id="Path"
                        fill="var(--svg-color-secondary, #EE7214)"
                      ></path>
                      <path
                        d="M16.5742939,6.28268183 C15.5498224,6.23775316 14.6000794,6.81738803 14.1716262,7.74904688 C13.743173,8.68070573 13.9212618,9.77901022 14.6221567,10.5275466 C15.3230516,11.276083 16.4072909,11.52591 17.3650741,11.159561 C18.3228574,10.793212 18.9636082,9.88358197 18.9860507,8.85837141 C19.0250803,7.48444098 17.9479291,6.33655519 16.5742939,6.28823887 L16.5742939,6.28268183 Z"
                        id="Path"
                        fill="var(--svg-color-secondary, #EE7214)"
                      ></path>
                    </g>
                  </svg>
                </div>
              </div>
              <p className="whitespace-pre-line text-center text-lg md:text-xl">{t("aboutus.Questions? We have answers!")}</p>
              <Link to={"/contactus"} className="w-100 group/button disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark p-3 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated pl-3 py-2 inline-block mt-4" href="https://cms.camperboys.com/pdf-files/equipment_vw_t6_1_california_ocean_editionen-1.pdf">
                <div className="flex h-full items-center justify-between">
                  <div className="inline-block align-top mr-2 rounded-full bg-white p-1 child:h-7 child:w-7 dark:bg-off-white">
                    <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
                      <title>icon/actions_question</title>
                      <g id="icon/actions_question" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <path d="M16.5609275,17.3580413 L16.3692127,19.2467266 C15.73983,19.3562778 14.562691,19.3562778 13.8779956,19.3015022 C13.6320422,19.027624 13.6862809,15.1407023 13.6862809,15.1407023 C16.2596615,15.1407023 18.2305102,14.2369044 18.2305102,12.1838923 C18.2305102,11.1442293 17.4915762,9.66582429 15.3837884,9.66582429 C13.4951032,9.66582429 12.1536372,10.6512486 12.1536372,10.6512486 C12.1536372,10.6512486 10.9222596,8.70778776 11.003886,8.05048019 C11.6338057,7.33947102 13.3033885,6.6 15.8767691,6.6 C19.0242198,6.6 21.6523761,8.43390961 21.6523761,11.8831633 C21.6523761,15.1680901 19.2707101,17.0019997 16.5609275,17.3580413 M16.5732788,25.0115932 C15.8048089,25.7891924 14.5471175,25.7972476 13.7695183,25.0287777 C12.9913822,24.2608449 12.9833269,23.0031534 13.7517968,22.2250173 C14.5197297,21.4468811 15.7779581,21.4393629 16.5555573,22.2072957 C17.3336935,22.9757656 17.3412117,24.2334571 16.5732788,25.0115932" id="Fill-1" fill="var(--svg-color-secondary, #163435)"></path>
                      </g>
                    </svg>
                  </div>
                  <span className="flex">
                    {t("aboutus.To the helpcenter")}
                  </span>
                  <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                    <svg width="32" height="32" viewBox="0 0 32 32">
                      <g fill="none" fillRule="evenodd">
                        <path d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z" fill="var(--svg-color-secondary, #163435)"></path>
                      </g>
                    </svg>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Voucher;
