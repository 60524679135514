import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import NavDropdown from "react-bootstrap/NavDropdown";
import Form from "react-bootstrap/Form";
import "bootstrap/js/src/collapse.js";
import {t} from 'i18next';
const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  const handleLogout = () => {
    localStorage.removeItem("usertoken");
    localStorage.removeItem("user");
  }
  return (
    <>
      <header className="block-header z-index-99999 fixed z-header h-header-height-mobile w-full bg-white transition-all duration-300 dark:bg-off-black-desat dark:shadow-dark lg:h-header-height-laptop 2xl:h-header-height-desktop top-0 shadow">
        <div className="m-auto ml-5 flex h-full max-w-screen-3xl max-w-screen-2xl items-center justify-between 3xl-padded:ml-auto">
          <Link to="/">
            <img className="header-camper-logo" src="/images/born-camper-logo.png" alt="camper-logo" />
          </Link>
          <button
            type="button"
            aria-label="menu"
            className="mr-1 h-12 w-12 min-w-[2rem] cursor-pointer p-4 lg:hidden"
          >
            <span className="block w-full mb-[0.19rem] h-[0.19rem] transition[transform, opacity] duration-300 opacity-100 rounded-full bg-black dark:bg-off-white origin-left"></span>
            <span className="block w-full mb-[0.19rem] h-[0.19rem] transition[transform, opacity] duration-300 opacity-100 rounded-full bg-black dark:bg-off-white"></span>
            <span className="block w-full mb-[0.19rem] h-[0.19rem] transition[transform, opacity] duration-300 opacity-100 rounded-full bg-black dark:bg-off-white origin-left"></span>
          </button>
          <nav className="mr-5 hidden h-full lg:visible lg:flex 3xl-padded:mr-0">
            <div className="position:relative">
              <ul className="flex h-full">
                <li className="group h-full relative dropdown">
                  <Link to={'/offers'} className="flex h-full items-center text-center flex h-full items-center">

                    <div className="rounded py-2 px-2 transition-all group-hover:bg-off-white dark:group-hover:bg-black">
                      <p className="text-md font-medium">% {t("header.deals")}</p>
                    </div>

                  </Link>
                </li>
                <li className="group h-full">
                  <Link to={'/campers'} className="flex h-full items-center text-center flex h-full items-center">

                    <div className="rounded py-2 px-2 transition-all group-hover:bg-off-white dark:group-hover:bg-black">
                      <p className="text-md font-medium">{t("header.Camper rent")}</p>
                    </div>

                  </Link>
                </li>
                <li className="group h-full">
                  <Link to={'/campersubscription'} className="flex h-full items-center text-center flex h-full items-center">
                    <div className="rounded py-2 px-2 transition-all group-hover:bg-off-white dark:group-hover:bg-black">
                      <p className="text-md font-medium">{t("header.Camper subscription")}</p>
                    </div>
                  </Link>
                </li>
                <li className="group h-full">
                  <Link to={'/campersale'} className="flex h-full items-center text-center flex h-full items-center">
                    <div className="rounded py-2 px-2 transition-all group-hover:bg-off-white dark:group-hover:bg-black">
                      <p className="text-md font-medium">{t("header.Sale")}</p>
                    </div>
                  </Link>
                </li>
                <li className="group h-full">
                  <Link to={"/tripplanner"} className="flex h-full items-center text-center flex h-full items-center">
                    <div className="rounded py-2 px-2 transition-all group-hover:bg-off-white dark:group-hover:bg-black">
                      <p className="text-md font-medium">{t("header.Travel Planner")}</p>
                    </div>
                  </Link>
                </li>
                <li className="group h-full">
                  <Link to={"/blog"} className="flex h-full items-center text-center flex h-full items-center">

                    <div className="rounded py-2 px-2 transition-all group-hover:bg-off-white dark:group-hover:bg-black">
                      <p className="text-md font-medium">{t("header.Vanlife")}</p>
                    </div>

                  </Link>
                </li>
                <li className="group h-full">
                  <Link to={"/aboutus"} className="flex h-full items-center text-center flex h-full items-center">

                    <div className="rounded py-2 px-2 transition-all group-hover:bg-off-white dark:group-hover:bg-black">
                      <p className="text-md font-medium">{t("header.The Team")}</p>
                    </div>

                  </Link>
                </li>
                <li className="group h-full">
                  <Link to={"/contactus"} className="flex h-full items-center text-center flex h-full items-center">

                    <div className="rounded py-2 px-2 transition-all group-hover:bg-off-white dark:group-hover:bg-black">
                      <p className="text-md font-medium">{t("header.Contact & help")}</p>
                    </div>

                  </Link>
                </li>
                {
                  localStorage.getItem('usertoken') ?
                    <>
                      
                      <li>
                        <Link to={'/my_order'} className="flex h-full items-center text-center flex h-full items-center">

                          <div className="rounded py-2 px-2 transition-all group-hover:bg-off-white dark:group-hover:bg-black">
                            <p className="text-md font-medium">{t("header.Dashboard")}</p>
                          </div>

                        </Link>
                      </li>
                      <li>
                        <Link onClick={handleLogout} className="flex h-full items-center text-center flex h-full items-center">

                          <div className="rounded py-2 px-2 transition-all group-hover:bg-off-white dark:group-hover:bg-black">
                            <p className="text-md font-medium">{t("header.Logout")}</p>
                          </div>

                        </Link>
                      </li>
                     
                    </>
                    :
                    <li className="group h-full">
                      <Link to={"/login"} className="flex h-full items-center text-center flex h-full items-center">

                        <div className="rounded py-2 px-2 transition-all group-hover:bg-off-white dark:group-hover:bg-black">
                          <p className="text-md font-medium">{t("header.Login")}</p>
                        </div>

                      </Link>
                    </li>

                }


              </ul>
            </div>
          </nav>
        </div >
      </header >

      <nav className="navbar custom-navbar z-index-99999 fixed z-header h-header-height-mobile w-full bg-white transition-all duration-300 dark:bg-off-black-desat dark:shadow-dark lg:h-header-height-laptop 2xl:h-header-height-desktop top-0 shadow">
        <Link to={"/"}>
          <img className="header-camper-logo" src="/images/born-camper-logo.png" alt="camper-logo" /></Link>
        <div className={`menu ${isOpen ? 'open' : ''}`}>
          <ul className="">
            <li className="group h-full relative dropdown">
              <Link to={'/offers'} className="h-full items-center text-center h-full items-center">

                <div className="rounded py-2 px-4 transition-all group-hover:bg-off-white dark:group-hover:bg-black">
                  <p className="text-md font-medium">%  {t("header.deals")}</p>
                </div>

              </Link>
            </li>
            <li className="group h-full">
              <Link to={'/campers'} className="h-full items-center text-center h-full items-center">

                <div className="rounded py-2 px-4 transition-all group-hover:bg-off-white dark:group-hover:bg-black">
                  <p className="text-md font-medium">{t("header.Camper rent")}</p>
                </div>

              </Link>
            </li>
            <li className="group h-full">
              <Link to={'/campersubscription'} className="h-full items-center text-center h-full items-center">
                <div className="rounded py-2 px-4 transition-all group-hover:bg-off-white dark:group-hover:bg-black">
                  <p className="text-md font-medium">{t("header.Camper subscription")}</p>
                </div>
              </Link>
            </li>
            <li className="group h-full">
              <Link to={'/campersale'} className="h-full items-center text-center h-full items-center">
                <div className="rounded py-2 px-4 transition-all group-hover:bg-off-white dark:group-hover:bg-black">
                  <p className="text-md font-medium">{t("header.Sale")}</p>
                </div>
              </Link>
            </li>
            <li className="group h-full">
              <Link to={"/tripplanner"} className="h-full items-center text-center h-full items-center">
                <div className="rounded py-2 px-4 transition-all group-hover:bg-off-white dark:group-hover:bg-black">
                  <p className="text-md font-medium">{t("header.Travel Planner")}</p>
                </div>
              </Link>
            </li>
            <li className="group h-full">
              <Link to={"/blog"} className="h-full items-center text-center h-full items-center">

                <div className="rounded py-2 px-4 transition-all group-hover:bg-off-white dark:group-hover:bg-black">
                  <p className="text-md font-medium">{t("header.Vanlife")}</p>
                </div>

              </Link>
            </li>
            <li className="group h-full">
              <Link to={"/aboutus"} className="h-full items-center text-center h-full items-center">

                <div className="rounded py-2 px-4 transition-all group-hover:bg-off-white dark:group-hover:bg-black">
                  <p className="text-md font-medium">{t("header.The Team")}</p>
                </div>

              </Link>
            </li>
            <li className="group h-full">
              <Link to={"/contactus"} className="h-full items-center text-center h-full items-center">

                <div className="rounded py-2 px-4 transition-all group-hover:bg-off-white dark:group-hover:bg-black">
                  <p className="text-md font-medium">{t("header.Contact & help")}</p>
                </div>

              </Link>
            </li>
            {
                  localStorage.getItem('usertoken') ?
                    <>
                      
                      <li>
                        <Link to={'/my_order'} className="h-full items-center text-center h-full items-center">

                          <div className="rounded py-2 px-4 transition-all group-hover:bg-off-white dark:group-hover:bg-black">
                            <p className="text-md font-medium">{t("header.Dashboard")}</p>
                          </div>

                        </Link>
                      </li>
                      <li>
                        <Link onClick={handleLogout} className="h-full items-center text-center h-full items-center">

                          <div className="rounded py-2 px-4 transition-all group-hover:bg-off-white dark:group-hover:bg-black">
                            <p className="text-md font-medium">{t("header.Logout")}</p>
                          </div>

                        </Link>
                      </li>
                     
                    </>
                    :
                    <li className="group h-full">
                      <Link to={"/login"} className="h-full items-center text-center h-full items-center">

                        <div className="rounded py-2 px-4 transition-all group-hover:bg-off-white dark:group-hover:bg-black">
                          <p className="text-md font-medium">{t("header.Login")}</p>
                        </div>

                      </Link>
                    </li>

                }

          </ul>
        </div>
        <div className="burger-menu" onClick={toggleNavbar}>
          <div className={`bar ${isOpen ? 'bar1' : ''}`}></div>
          <div className={`bar ${isOpen ? 'bar2' : ''}`}></div>
          <div className={`bar ${isOpen ? 'bar3' : ''}`}></div>
        </div>
      </nav>
    </>
  );
};

export default Header;
