

export const Germancountries = 
    {
  AF: "Afghanistan",
  AL: "Albanien",
  DZ: "Algerien",
  AS: "Amerikanischen Samoa-Inseln",
  AD: "Andorra",
  AO: "Angola",
  AI: "Anguilla",
  AG: "Antigua and Barbuda",
  AR: "Argentinien",
  AM: "Armenien",
  AW: "Aruba",
  AU: "Australien",
  AT: "Österreich",
  AZ: "Aserbaidschan",
  BS: "Bahamas",
  BH: "Bahrain",
  BD: "Bangladesch",
  BB: "Barbados",
  BY: "Weißrussland",
  BE: "Belgien",
  BZ: "Belize",
  BJ: "Benin",
  BM: "Bermuda",
  BT: "Bhutan",
  BO: "Bolivien, plurinationaler Staat",
  BA: "Bosnien und Herzegowina",
  BW: "Botswana",
  BR: "Brasilien",
  IO: "Britisches Territorium des Indischen Ozeans",
  BG: "Bulgarien",
  BF: "Burkina Faso",
  BI: "Burundi",
  KH: "Kambodscha",
  CM: "Kamerun",
  CA: "Kanada",
  CV: "Kap Verde",
  KY: "Cayman Inseln",
  CF: "Zentralafrikanische Republik",
  TD: "Tschad",
  CL: "Chile",
  CN: "China",
  CO: "Kolumbien",
  KM: "Komoren",
  CG: "Kongo",
  CD: "Demokratische Republik Kongo",
  CK: "Cookinseln",
  CR: "Costa Rica",
  CI: "Elfenbeinküste",
  HR: "Kroatien",
  CU: "Kuba",
  CW: "Curacao",
  CY: "Zypern",
  CZ: "Tschechien",
  DK: "Dänemark",
  DJ: "Dschibuti",
  DM: "Dominica",
  DO: "Dominikanische Republik",
  EC: "Ecuador",
  EG: "Ägypten",
  SV: "El Salvador",
  GQ: "Äquatorialguinea",
  ER: "Eritrea",
  EE: "Estland",
  ET: "Äthiopien",
  FK: "Falklandinseln (Malvinas)",
  FO: "Färöer Inseln",
  FJ: "Fidschi",
  FI: "Finnland",
  FR: "Frankreich",
  PF: "Französisch Polynesien",
  GA: "Gabun",
  GM: "Gambia",
  GE: "Georgia",
  DE: "Deutschland",
  GH: "Ghana",
  GI: "Gibraltar",
  GR: "Griechenland",
  GL: "Grönland",
  GD: "Grenada",
  GU: "Guam",
  GT: "Guatemala",
  GG: "Guernsey",
  GN: "Guinea",
  GW: "Guinea-Bissau",
  HT: "Haiti",
  HN: "Honduras",
  HK: "Hongkong",
  HU: "Ungarn",
  IS: "Island",
  IN: "Indien",
  ID: "Indonesien",
  IR: "Iran, Islamische Republik",
  IQ: "Irak",
  IE: "Irland",
  IM: "Isle of Man",
  IL: "Israel",
  IT: "Italien",
  JM: "Jamaika",
  JP: "Japan",
  JE: "Jersey",
  JO: "Jordanien",
  KZ: "Kasachstan",
  KE: "Kenia",
  KI: "Kiribati",
  KP: "Nord Korea",
  KR: "Südkorea",
  KW: "Kuwait",
  KG: "Kirgisistan",
  LA: "Demokratische Volksrepublik Laos",
  LV: "Lettland",
  LB: "Libanon",
  LS: "Lesotho",
  LR: "Liberia",
  LY: "Libyen",
  LI: "Liechtenstein",
  LT: "Litauen",
  LU: "Luxemburg",
  MO: "Macau",
  MK: "Republik Mazedonien",
  MG: "Madagaskar",
  MW: "Malawi",
  MY: "Malaysia",
  MV: "Malediven",
  ML: "Mali",
  MT: "Malta",
  MH: "Marshallinseln",
  MQ: "Martinique",
  MR: "Mauretanien",
  MU: "Mauritius",
  MX: "Mexiko",
  FM: "Mikronesien, Föderierte Staaten von",
  MD: "Republik Moldawien",
  MC: "Monaco",
  MN: "Mongolei",
  ME: "Montenegro",
  MS: "Montserrat",
  MA: "Marokko",
  MZ: "Mosambik",
  MM: "Myanmar",
  NA: "Namibia",
  NR: "Nauru",
  NP: "Nepal",
  NL: "Niederlande",
  NZ: "Neuseeland",
  NI: "Nicaragua",
  NE: "Niger",
  NG: "Nigeria",
  NU: "Niue",
  NF: "Norfolkinsel",
  MP: "Nördliche Marianneninseln",
  NO: "Norwegen",
  OM: "Oman",
  PK: "Pakistan",
  PW: "Palau",
  PS: "Palästinensisches Gebiet",
  PA: "Panama",
  PG: "Papua Neu-Guinea",
  PY: "Paraguay",
  PE: "Peru",
  PH: "Philippinen",
  PN: "Pitcairn",
  PL: "Polen",
  PT: "Portugal",
  PR: "Puerto Rico",
  QA: "Katar",
  RO: "Rumänien",
  RU: "Russland",
  RW: "Ruanda",
  KN: "St. Kitts und Nevis",
  LC: "St. Lucia",
  WS: "Samoa",
  SM: "San Marino",
  ST: "São Tomé und Príncipe",
  SA: "Saudi-Arabien",
  SN: "Senegal",
  RS: "Serbien",
  SC: "Seychellen",
  SL: "Sierra Leone",
  SG: "Singapur",
  SX: "Sint Maarten",
  SK: "Slowakei",
  SI: "Slowenien",
  SB: "Salomon-Inseln",
  SO: "Somalia",
  ZA: "Südafrika",
  SS: "Südsudan",
  ES: "Spanien",
  LK: "Sri Lanka",
  SD: "Sudan",
  SR: "Surinam",
  SZ: "Swasiland",
  SE: "Schweden",
  CH: "Schweiz",
  SY: "Syrien",
  TW: "Taiwan",
  TJ: "Tadschikistan",
  TZ: "Tansania",
  TH: "Thailand",
  TG: "Gehen",
  TK: "Tokelau",
  TO: "Tonga",
  TT: "Trinidad und Tobago",
  TN: "Tunesien",
  TR: "Truthahn",
  TM: "Turkmenistan",
  TC: "Turks- und Caicosinseln",
  TV: "Tuvalu",
  UG: "Uganda",
  UA: "Ukraine",
  AE: "Vereinigte Arabische Emirate",
  GB: "Großbritannien",
  US: "Vereinigte Staaten",
  UY: "Uruguay",
  UZ: "Usbekistan",
  VU: "Vanuatu",
  VE: "Venezuela, Bolivarische Republik",
  VN: "Vietnam",
  VI: "Jungferninseln",
  YE: "Jemen",
  ZM: "Sambia",
  ZW: "Zimbabwe",
}