import React, { useState, useEffect, } from 'react';
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { Helmet } from 'react-helmet';
import CamperService from "../../Api/services/CamperService";
import { NumericFormat } from 'react-number-format';
// import Sliderbox from "../../Components/Sliderbox/Sliderbox";
import { useLocation, Link, useNavigate, generatePath, createSearchParams, useSearchParams } from "react-router-dom";
// import { Splide, SplideSlide } from "@splidejs/react-splide";
// import { PropTypes } from "prop-types";
import { useTranslation } from "react-i18next";
import "@splidejs/react-splide/css";
import "@splidejs/react-splide/css/core";
import Select from "react-select";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import Camperbox from "../../Components/Camperbox/Camperbox";
import DatePicker from "react-datepicker";
import moment from 'moment';
import { addMonths, parse } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";
import HomeService from "../../Api/services/HomeService";
import TopEquipped from "../../Components/Topequipped/Top-equipped";
import Vanlife from '../../Components/Vanlife/Vanlife';

const Home = () => {
  const { i18n, t } = useTranslation();
  var imageUrl = process.env.REACT_APP_LARAVEL_BLOG_IMAGE_URL;
  var camperUrl = process.env.REACT_APP_LARAVEL_CAMPER_IMAGE_URL;
  var dealUrl = process.env.REACT_APP_LARAVEL_Deal_IMAGE_URL;
  const [searchParams, setSearchParams] = useSearchParams()
  const [Error, setError] = useState("")
  const navigate = useNavigate();
  const [blog, setBlog] = useState([]);
  const [location, setLocation] = useState([]);
  const [skills, setSkills] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [Deal, setDeal] = useState([]);
  const [endDate, setEndDate] = useState(null);
  const variable = useLocation();
  const [camper, setCamper] = useState([]);
  const [loader, setLoder] = useState(false);
  const onChangedate = (date, e) => {
    const [start, end] = date;
    setStartDate(start);
    setEndDate(end);

    if (date[1] !== null) {
      if (variable.search == '') {
        navigate(generatePath(`?date=${moment(startDate).format('YYYY-MM-DD') + '_' + moment(date[1]).format('YYYY-MM-DD')}`))
      } else if (variable.search !== '') {
        setSearchParams((searchParams) => {
          searchParams.set('date', `${moment(startDate).format('YYYY-MM-DD') + '_' + moment(date[1]).format('YYYY-MM-DD')}`);
          return searchParams;
        });
      } else {
        navigate(generatePath(variable.search + `&date=${moment(startDate).format('YYYY-MM-DD') + '_' + moment(date[1]).format('YYYY-MM-DD')}`))
      }
    }
  };

  useEffect(() => {
    getBlog();
    getLocation();
    getCamper();
    getDeals();
  }, []);
  const getCamper = async () => {
    try {
      setLoder(true)
      let camperList = await CamperService.index().json();
      var responsedata = camperList.data.filter(function (item) {
        return item.camper_type_id == 1;
      });

      setCamper(responsedata);
    } catch (error) {
      console.log(error);
    }
    setLoder(false)
  };

  var camperslider = {
    initialSlide: 0,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
          infinite: false,
          variableWidth: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          initialSlide: 0,
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          infinite: false,
          variableWidth: true,
          centerMode: false,
        },
      },

    ],
  };
  var fiverow = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1273,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,

          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,

        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,

        },
      },
    ],
  };
  var fourrow = {
    dots: true,

    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1273,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,

          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  var threerow = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1273,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,

          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,

        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  var dreamCamper = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1273,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,

          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const getBlog = async () => {
    try {
      let blogList = await HomeService.index().json();
      // console.log(blogList.data);
      setBlog(blogList.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getLocation = async () => {
    try {
      let locationList = await HomeService.location().json();
      // console.log(getData,"getdata")
      // setLocation(getData);
      var getData = locationList.data.filter((element) => { return element.title === 'Berlin' })

      setSearchParams((searchParams) => {
        searchParams.set('location', `${getData[0].id}`);
        return searchParams;
      });
      setSkills(getData)
    } catch (error) {
      console.log(error);
    }
  };
  const getDeals = async () => {
    try {
      let dealsList = await HomeService.deals().json();
      var dealshome = dealsList.data.filter((element) => { return element.is_home_page === 1 })

      setDeal(dealshome)
      // setDeal(dealsList.data)

    } catch (error) {
      console.log(error);
    }
  };
  const [isFormSubmit, setisFormSubmit] = useState(false);
  const onSubmitForm = async (event) => {

    event.preventDefault();
    setError("")
    try {
      var userData = {
        "first_name": event.target.first_name.value,
        "email": event.target.email.value,
        "type": "Home",
        "lng": i18n.resolvedLanguage
      }
      if (event.target.first_name.value === "") {
        setError(t("Login.The first name field is required."))
      }
      else if (event.target.email.value === "") {
        setError(t("Login.The email field is required."))
      }

      else {
        let responseData = await HomeService.store(userData).json()
        if (responseData.status === true) {
          // alert("Newsletters Submitted Successfully!!");
          event.target.reset();
          // navigate('/');
          setisFormSubmit(true)
        }
      }
    } catch (error) {
      console.log(error)
      if (error.name === 'HTTPError') {
        const errorJson = await error.response.json();
        setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
      }
    }

  };

  return (
    <>

      <Header />
      <div className="min-h-screen bg-off-white">

        <div className="pb-5 text-center">
          <div className="overflow-hidden">
            <div className="-ml-[12.5%] w-[125%] overflow-hidden rounded-b-[60%20%]  px-[5%]">
              <div className="relative">
                <div className="overflow-hidden">
                  <img
                    src="images/homebanner-png.jpeg"
                    alt=""
                    className="absolute max-h-full w-full scale-110 object-cover blur-lg dark:brightness-90 sm:aspect-hero-regular 3xl:aspect-hero-long aspect-square"
                  />
                </div>
                <picture className="flex max-h-[65vh] w-full text-0">
                  <img
                    className="z-0 max-h-full w-full object-cover dark:brightness-90 sm:aspect-hero-regular 3xl:aspect-hero-long aspect-square"
                    src="images/picture homepage.png"
                    alt="Girl in mountain scenery, leaning out passenger window of VW T6.1 California Beach."
                    width="2552"
                    height="1286"
                  />
                </picture>
                <div className="absolute top-0 left-0 flex h-full w-full flex-col items-center justify-end">
                  <div className="pb-12 w-full bg-gradient-to-t from-black-pure/80 to-transparent pt-20 md:pt-14 xl:pt-32">
                    <h1 className="whitespace-pre-line break-words m-auto max-w-4xl px-8 text-xl md:text-2xl text-white drop-shadow-text dark:text-off-white xs:text-2xl xl:text-3xl mb-5 md:mb-8 xl:mb-12">
                      {t("home.Rent a Camper, Motorhome or Caravan")}
                    </h1>
                    <Helmet>
                      <script src="https://static.elfsight.com/platform/platform.js" data-use-service-core defer></script>
                    </Helmet>
                    {/* <div className='absolute top-0 text-left'>
                      <div className='mx-5 flex flex-col items-center justify-center gap-2 xs:flex-row xs:items-stretch google-review-widget'>
                        <div className="elfsight-app-47d662cd-6cea-46ec-b526-a65bdd61c9a9" data-elfsight-app-lazy></div>
                      </div>
                    </div> */}

                  </div>




                </div>

              </div>
            </div>
          </div>


        </div>

        <div className='text-center'>

          <div className='w-full md:w-2/6 m-auto mt-5'>
            <form
              action=""
              className="relative view-campers-hero-btn md-padded:mx-auto"
            >



              <Link to={'/campers'} className="group/button block border border-transparent rounded text-md text-white dark:text-off-white button-primary-bg dark:bg-primary-dark p-3 pl-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated col-span-2 disabled:opacity-100 sm:col-span-1 text-lg text-center">
                <div className="flex h-full items-center justify-center">
                  <span className="flex" >{t("sale.View Campers")}</span>
                  <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path
                          d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                          fill="var(--svg-color-secondary, #163435)"
                        ></path>
                      </g>
                    </svg>
                  </div>
                </div>
              </Link>
            </form>
          </div>


        </div>

        <div className="pt-8 2xl:pt-12 mx-2 md:mx-5 xl-padded:mx-auto">

          <div className='mt-0 max-w-screen-xl mx-auto'>
            <div className=" pb-0 max-w-screen-xl mx-auto flex max-w-3xl flex-col items-center text-center">
              <p className="mb-1 md:mb-4 whitespace-pre-line text-md ">
                {t("home.Flexible & uncomplicated")}
              </p>
              <h2 className="whitespace-pre-line break-words text-xl md:text-2xl mb-6 ">
                {t("home.Our Promise To YOU")}
              </h2>
            </div>

            <div className="">
              <div className="pt-0 pb-0">
                <div className="mx-5 xl-padded:mx-auto pt-0">
                  <Slider className="camper-slider" {...fiverow}>

                    <div className="bg-primary orange-border text-white rounded  p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-6 2xl:mt-14 2xl:h-[calc(100%-5.5rem)] pt-0 md:pb-7">
                      <div className="flex justify-center align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-12 mb-5 child:h-24 child:w-24 md:-mt-16 2xl:-mt-20 2xl:child:h-28 2xl:child:w-28">

                        <img
                          className='first-slider-img'
                          src="images/services-svg.svg"
                          alt="services-icon"
                        />

                      </div>
                      <h3 className="mb-3 whitespace-pre-line text-center text-lg-custom">
                        {t("home.All About YOU")}!
                      </h3>
                      <p className="whitespace-pre-line text-center mb-6">
                        {t("home.Support before, during and after your roadtrip")}
                      </p>
                    </div>



                    <div className="bg-primary orange-border text-white relative rounded md:py-6 md:px-3 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-2 2xl:mt-14 2xl:h-[calc(100%-5.5rem)] pt-0 pb-7">
                      <div className="flex justify-center align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-12 mb-5 child:h-24 child:w-24 md:-mt-16 2xl:-mt-20 2xl:child:h-28 2xl:child:w-28">
                        <div className='rounded-full border-4 img-rounded border-primary bg-white p-3'>
                          <img
                            src="images/chair-logo.svg"
                            alt="services-icon"
                            className='chair-logo'
                          />
                        </div>
                      </div>
                      <h3 className="mb-3 whitespace-pre-line text-center text-lg-custom">
                        {t("home.Equipment included")}
                      </h3>
                      <p className="whitespace-pre-line text-center mb-6">
                        {t("home.Kitchen and camping gear included")}
                      </p>
                      {/* <div className="mt-auto text-right pr-2">
                        <Link to="/explanatoryvideo" className="group/button disabled:opacity-50 border border-transparent text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated p-1 rounded-full inline-block px-4 h-10 w-3 plus-icon-button">
                          <div className="flex h-full items-center justify-center">
                            <span className="flex">
                             <svg fill="#ffffff" version="1.1" id="Capa_1" viewBox="0 0 45.402 45.402"  stroke="#ffffff" height="20" width="20">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                  <g id="SVGRepo_iconCarrier"> <g> <path d="M41.267,18.557H26.832V4.134C26.832,1.851,24.99,0,22.707,0c-2.283,0-4.124,1.851-4.124,4.135v14.432H4.141 c-2.283,0-4.139,1.851-4.138,4.135c-0.001,1.141,0.46,2.187,1.207,2.934c0.748,0.749,1.78,1.222,2.92,1.222h14.453V41.27 c0,1.142,0.453,2.176,1.201,2.922c0.748,0.748,1.777,1.211,2.919,1.211c2.282,0,4.129-1.851,4.129-4.133V26.857h14.435 c2.283,0,4.134-1.867,4.133-4.15C45.399,20.425,43.548,18.557,41.267,18.557z"></path> 
                                  </g> 
                                </g>
                                </svg>
                            </span>
                          </div>
                        </Link>
                      </div> */}
                      <Link to={"/equipment"}>
                        <div className='plus-icon orange-background'>+</div>
                      </Link>
                    </div>



                    <div className="bg-primary orange-border text-white rounded md:p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-6 2xl:mt-14 2xl:h-[calc(100%-5.5rem)] pt-0 md:pb-7">
                      <div className="flex justify-center align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-12 mb-5 child:h-24 child:w-24 md:-mt-16 2xl:-mt-20 2xl:child:h-28 2xl:child:w-28">
                        <img
                          src="images/services-svg-2.svg"
                          alt="services-icon"
                        />
                      </div>
                      <h3 className="mb-3 whitespace-pre-line text-center text-lg-custom home-slide-text">
                        {t("home.No service fee")}
                      </h3>
                      <p className="whitespace-pre-line text-center mb-6 home-slide-p">
                        {t("home.We include all service charges because your comfort is our top priority")}!
                      </p>
                    </div>



                    <div className="bg-primary orange-border text-white rounded md:p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-6 2xl:mt-14 2xl:h-[calc(100%-5.5rem)] pt-0 md:pb-7">
                      <div className="flex justify-center align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-12 mb-5 child:h-24 child:w-24 md:-mt-16 2xl:-mt-20 2xl:child:h-28 2xl:child:w-28">
                        <img
                          src="images/services-svg-3.svg"
                          alt="services-icon"
                        />
                      </div>
                      <h3 className="mb-3 whitespace-pre-line text-center text-lg-custom">
                        {t("aboutus.Flexibility")}
                      </h3>
                      <p className="whitespace-pre-line text-center mb-6">
                        {t("home.Pick-up and drop-off available 7 days a week from 9 AM to 5 PM")}.
                      </p>
                    </div>



                    <div className="bg-primary orange-border text-white rounded md:p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-6 2xl:mt-14 2xl:h-[calc(100%-5.5rem)] pt-0 md:pb-7">
                      <div className="flex justify-center align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-12 mb-5 child:h-24 child:w-24 md:-mt-16 2xl:-mt-20 2xl:child:h-28 2xl:child:w-28">
                        <img
                          src="images/services-svg-4.svg"
                          alt="services-icon"
                        />
                      </div>
                      <h3 className="mb-3 whitespace-pre-line text-center text-lg-custom">
                        {t("home.Insurance")}
                      </h3>
                      <p className="whitespace-pre-line text-center mb-6">
                        {t("home.Enjoy worry-free travel with full insurance and Europe-wide roadside assistance")}!
                      </p>
                    </div>


                  </Slider>
                </div>
              </div>

            </div>

          </div>

          {
            Deal.map((element) => {
              return (

                <div className="mx-3 md:mx-5 max-w-screen-xl xl-padded:mx-auto pt-8 md:pt-8 2xl:pt-0">
                  <div className="text-center mb-4">
                    <p className="md:mb-4 mb-1 whitespace-pre-line text-md mt-1">{t("Offers.Special Offers & Exclusive Deals")}</p>
                    <h2 className="whitespace-pre-line break-words text-xl md:text-2xl mb-6">{t("Offers.Find your perfect deal!")} </h2>
                  </div>
                  <div className="overflow-hidden rounded-lg bg-white shadow dark:bg-off-black-desat dark:shadow-dark group/parent mask-image relative p-0 md:flex md:min-h-[400px]  md:bg-transparent dark:md:bg-transparent justify-end">
                    <Link
                      to={"/offers"}
                      className="w-full md:flex justify-end"
                      href=""
                    >
                      <div className="md:hidden">
                        <div className="overflow-hidden">
                          <div className="-ml-[5%] w-[110%] overflow-hidden rounded-b-[60%20%] px-[5%]">
                            <div className="relative">
                              <picture className="text-0">
                                {/* <img
                                  src="images/deals-mobile.jpg"
                                  alt=""
                                /> */}
                                <img src={element.mobile_image ? dealUrl + element.mobile_image : ''} alt="" className='' />

                              </picture>
                            </div>
                          </div>
                        </div>
                      </div>
                      <picture className="text-0 absolute top-0 left-0 z-background hidden h-full w-full transition-all duration-300 group-hover/parent:scale-105 md:block md:group-hover/parent:scale-[103%]">
                        {/* <img src="images/deals.png" alt="" className='h-full' /> */}
                        <img src={element.feature_image ? dealUrl + element.feature_image : ''} alt="" className='h-full inline-block max-w-full object-cover dark:brightness-90 h-full w-full' />
                      </picture>
                      <div className="relative -mt-10 mb-4 flex justify-center xs:mb-4 hidden">
                        <div className="sm:hidden inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark child:h-20 child:w-20">
                          {/* <img
                            src="images/percentage-icon.svg"
                            alt="percentage-icon"
                          /> */}
                          <img src={element.logo ? dealUrl + element.logo : ''} alt="percentage-icon" />

                        </div>
                      </div>
                      <div className="z-0 m-6 sm:mt-4 flex flex-col text-center xs:mt-0 md:m-5 md:mt-5 md:min-h-[calc(100%-theme(spacing.5)*2)] md:w-full md:max-w-1/2 md:rounded-lg md:bg-white md:p-8 md:text-left md:shadow md:dark:bg-off-black-desat xl:max-w-[45%] 2xl:m-9 2xl:mt-9 2xl:min-h-[calc(100%-theme(spacing.9)*2)]">
                        <div className="mb-2 md:mb-6 flex items-center justify-center md:justify-start">
                          <div className="relative mr-4 hidden md:visible">
                            <div className=" inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark child:h-16 child:w-16">
                              {/* <img
                                src="images/percentage-icon.svg"
                                alt="percentage-icon"
                              /> */}
                              <img src={element.logo ? dealUrl + element.logo : ''} alt="percentage-icon" />
                            </div>
                          </div>
                          <p className="small-lg text-lg md:text-lg price-font">
                            {/* Get 60€ discount */}
                            {element.title ? element.title : ' '}
                          </p>
                        </div>
                        <h3 className="whitespace-pre-line break-words mb-4 text-xl xs:mb-6">
                          {element.sub_title ? element.sub_title : ''}
                        </h3>
                        <div className="mb-4 md:mb-6">
                          <div className="min-h-[30px] break-words text-sm text-2md 2xl:text-2md 2xl:font-normal price-font image-box-text">
                            {element.description ? element.description : ''}
                            {/* Camping in autumn is always a good idea. With our autumn
                            discount you save 60€ on your booking. */}
                          </div>
                          <div className="hidden md:visible min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                            {/* In October, we also have especially favorable periods to
                            offer! Information about exact periods and models can be
                            found on our offers page. */}
                          </div>
                        </div>
                        <div className="group/button block disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white button-primary-bg dark:bg-primary-dark p-3 pl-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated w-full md:mt-auto">
                          <div className="flex h-full items-center justify-between">
                            <span className="flex">
                              {/* Check out autumn deals */}
                              {element.button_text ? element.button_text : ''}
                            </span>
                            <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                              <svg width="32" height="32" viewBox="0 0 32 32">
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                                    fill="var(--svg-color-secondary, #fff)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              )
            })
          }


          <div className="pt-8 2xl:pt-12 pb-0 mx-auto flex max-w-3xl flex-col items-center text-center">
            <p className="mb-1 md:mb-4 whitespace-pre-line text-md">{t("home.Its your choice")}</p>
            <h2
              id="63c156edfb14d04765fe0338"
              className="whitespace-pre-line break-words text-xl md:text-2xl md:mb-6 mb-1"
            >
              {t("home.Our Models")}
            </h2>
            <p className="mb-4 whitespace-pre-line text-2md font-normal md:text-lg-paragraph md:font-normal text-20">
              {t("home.From compact campervans to spacious caravans – our models offer first-class amenities and reliably accompany you on your journey")}.
            </p>
          </div>

          <div className="mx-5 max-w-screen-xl xl-padded:mx-auto home-camper-slider">


            <Slider className="camper-slider" {...camperslider}>
              {camper.map((item, index) => (
                item.status == 1 ?
                  <div className="relative flex flex-col mt-6 text-gray-700 bg-white shadow-md bg-clip-border rounded-xl w-96 mt-[50%] hover-bg">
                    <Link
                       to={`/availabilityinfo/${item.id}?location=1&pickup=14&return=11`}
                      className="box-border h-full max-w-5xl pt-[50%]"
                    >
                      <div
                        className="relative  mx-4 text-white  -mt-[50%] bg-clip-border rounded-xl bg-blue-gray-500 shadow-blue-gray-500/40 camper-divImg">
                        <picture className="text-0 d-block h-full">
                          {item.image ?
                            <img
                              className="box-img inline-block max-w-full object-cover dark:brightness-90 aspect-[1.5] w-full  group-hover:scale-105 transition-all duration-300 divImg -mt-[50%]"
                              src={'images/0.gif'}
                              style={{ backgroundImage: "url(" + camperUrl + item.image + ")" }}

                              alt="rent-img-one"
                            />
                            : ''}
                        </picture>
                      </div>
                      <div className="pt-0 p-3 md:p-3 xl:p-3 mobile-top-margin-space">
                        <h4 className="mb-2 text-xl xs:mb-6 price-font fw-600 text-center tracking-wide"> {item.title}</h4>

                        {camper[index].camper_details.map((camper_details) => {
                          return (
                            <>
                              <h5 className="block mb-2 text-md  font-semibold leading-snug tracking-normal text-blue-gray-900 text-center title-camper"> {camper_details.sub_title}</h5>

                            </>
                          )
                        })}

                        {camper[index].camper_price.map((camper_price) => {
                          return (
                            <>
                              <h4 className="block text-center text-md"> {t("Rent.from")}{" "}<span className='price-font text-md inline-block align-bottom'><NumericFormat
                                value={camper_price.price}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                displayType={'text'}

                              />

                                €</span> <p className="text-md inline-block align-bottom ">&nbsp;{t("Rent.per Night")}</p></h4>

                            </>
                          )
                        })}
                      </div>
                      <div>
                        <div className="mt-0 md:mt-4 mb-1  bg-off-white p-3 dark:bg-black px-8">
                          {camper[index].feature.map((feature) => {
                            return (
                              <div className="mb-2 flex last:mb-0">
                                <div className="flex items-center justify-center self-start rounded-full bg-white p-1 dark:bg-off-white">
                                  <div className="inline-block align-top child:h-5 child:w-5 xs:child:h-6 xs:child:w-6 sm:child:h-5 sm:child:w-5 2md:child:h-6 2md:child:w-6 lg:child:h-5 lg:child:w-5 xl-padded-with-arrows:child:h-6 xl-padded-with-arrows:child:w-6">

                                    <img src={process.env.REACT_APP_LARAVEL_FEATURE_IMAGE_URL + '' + feature.smallicon} width="24px" height="24px" className="rounded-full bg-white" />
                                  </div>
                                </div>
                                <p className="mt-1 ml-2 text-sm xs:text-sm sm:text-xs 2md:text-sm lg:text-xs xl-padded-with-arrows:text-sm">
                                  {feature.title}
                                </p>
                              </div>
                            );
                          })}

                        </div>


                      </div>
                    </Link>
                    <div className="p-6 pt-0">
                      <div className="mt-4">
                        {/* <p>{props ? props.data && props.data.location:''}</p> */}
                        <Link
                          className="hover-border mt-4 group/button block disabled:opacity-50 rounded text-md dark:text-off-white p-3 pl-5 transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated text-off-black bg-transparent border hover:border-secondary group-hover/parent:border-secondary hover:bg-transparent group-hover/parent:bg-transparent dark:bg-transparent dark:hover:bg-transparent dark:group-hover/parent:bg-transparent dark:hover:text-off-white dark:group-hover/parent:text-off-white dark:hover:border-off-white dark:group-hover/parent:border-off-white mb-4 group-hover:border-secondary dark:group-hover:border-off-white"
                          to={`/rentconfiguration/${item.id}?location=1&pickup=14&return=11`}
                        >
                          <div className="flex h-full items-center justify-between">
                            <span className="flex">{t("Rent.Book now")}</span>
                            <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2  dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                              <svg
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                                    fill="var(--svg-color-secondary, #163435)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                          </div>
                        </Link>


                        <Link
                          className="group/button block disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark p-3 pl-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated w-full"

                          to={`/availabilityinfo/${item.id}?location=1&pickup=14&return=11`}
                        >
                          <div className="flex h-full items-center justify-between">
                            <span className="flex">{t("Rent.Availability & info")}</span>
                            <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                              <svg
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                                    fill="var(--svg-color-secondary, #FFF)"
                                  ></path>
                                </g>
                              </svg>
                            </div>

                          </div>
                        </Link>


                      </div>
                    </div>

                  </div>
                  : ''
              ))}
            </Slider>
          </div>

          <div className='max-w-screen-xl mx-auto bg-primary rounded-lg mt-16 mobile-margin'>

            <div className="pt-4 2xl:pt-6 pb-0 md:pb-8 2xl:pb-1 mx-auto flex max-w-1xl flex-col items-center text-center px-4">
              <p className="mb-4 whitespace-pre-line text-md text-white"></p>
              <h2 className="whitespace-pre-line break-words text-xl md:text-2xl mb-4 text-orange">
                {t("home.Dream Camper Rental Made Easy")}
              </h2>
              <p className="whitespace-pre-line text-2md font-normal md:text-lg-paragraph md:font-normal text-white px-2 text-20">
                {t("home.Transparent terms, no hidden costs – Your key to carefree camping adventures")}.
              </p>
            </div>

            <div className="">
              <div className="pt-0 pb-8 2xl:pb-6">
                <div className="mx-5 max-w-screen-xl xl-padded:mx-auto pt-5 lg:px-6">
                  <Slider className="camper-slider dream-camper-wrap" {...dreamCamper}>

                    <Link to={"/contactus"}>
                      <div className="rounded bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-6 2xl:mt-14 2xl:h-[calc(100%-3.5rem)] pt-0 pb-7">
                        <div className="flex justify-center align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-12 mb-5 child:h-24 child:w-24 2xl:-mt-14 2xl:child:h-28 2xl:child:w-28">
                          <img src="images/rates-icon.svg" alt="rates-icon" />
                        </div>
                        <h3 className="mb-3 whitespace-pre-line text-center text-xl slide-font">
                          {t("home.Insurance")}:&nbsp;{t("home.Travel with Peace of Mind")}
                        </h3>
                        <p className="whitespace-pre-line text-center">
                          {t("home.Enjoy relaxed travel with our comprehensive full coverage insurance. You can choose from extended insurance options to tailor your safety")}.
                        </p>
                        <div className="w-full mt-auto">
                          <div className="group/button block disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white button-primary-bg dark:bg-primary-dark p-3 pl-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated mt-5 w-full">
                            <div className="flex h-full items-center justify-between">
                              <span className="flex">{t("home.More info")}</span>
                              <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                                <svg
                                  width="32"
                                  height="32"
                                  viewBox="0 0 32 32"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g fill="none" fillRule="evenodd">
                                    <path
                                      d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                                      fill="var(--svg-color-secondary, #163435)"
                                    ></path>
                                  </g>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>

                    <Link to={"/equipment"}>
                      <div className="rounded bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-6 2xl:mt-14 2xl:h-[calc(100%-3.5rem)] pt-0 pb-7">
                        <div className="flex justify-center align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-12 mb-5 child:h-24 child:w-24 2xl:-mt-14 2xl:child:h-28 2xl:child:w-28">
                          <img src="images/rentel-icon.svg" alt="rentel-icon" />
                        </div>
                        <h3 className="mb-3 whitespace-pre-line text-center text-xl slide-font">
                          {t("home.Your Camper, Your Adventure Tailored extras")}
                        </h3>
                        <p className="whitespace-pre-line text-center">
                          {t("home.Parking for your car, bike rack, extra camping chairs, outdoor grill, paddle boards, hammock, and  more – choose what you need and turn your camper into the ideal companion for every adventure")}.
                        </p>
                        <div className="w-full mt-auto">
                          <div className="group/button block disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white button-primary-bg dark:bg-primary-dark p-3 pl-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated mt-5 w-full">
                            <div className="flex h-full items-center justify-between">
                              <span className="flex">{t("home.To the Equipment")}</span>
                              <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                                <svg
                                  width="32"
                                  height="32"
                                  viewBox="0 0 32 32"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g fill="none" fillRule="evenodd">
                                    <path
                                      d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                                      fill="var(--svg-color-secondary, #163435)"
                                    ></path>
                                  </g>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </Link>

                    <Link to={"/contactus"}>
                      <div className="rounded bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-6 2xl:mt-14 2xl:h-[calc(100%-3.5rem)] pt-0 pb-7">
                        <div className="flex justify-center align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-12 mb-5 child:h-24 child:w-24 2xl:-mt-14 2xl:child:h-28 2xl:child:w-28">
                          <img
                            src="images/insurance-icon.svg"
                            alt="insurance-icon"
                          />
                        </div>
                        <h3 className="mb-3 whitespace-pre-line text-center text-xl slide-font">
                          {t("home.Rental Info Your Questions, Our Answers")}
                        </h3>
                        <p className="whitespace-pre-line text-center">
                          {t("home.Pets? Cleaning? Special services? Find all essential information at a glance. Start your  adventure informed and worry-free")}.
                        </p>
                        <div className="w-full mt-auto">
                          <div className="group/button block disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white button-primary-bg dark:bg-primary-dark p-3 pl-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated mt-5 w-full">
                            <div className="flex h-full items-center justify-between">
                              <span className="flex">{t("home.To FAQ")}</span>
                              <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                                <svg
                                  width="32"
                                  height="32"
                                  viewBox="0 0 32 32"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g fill="none" fillRule="evenodd">
                                    <path
                                      d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                                      fill="var(--svg-color-secondary, #163435)"
                                    ></path>
                                  </g>
                                </svg>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>

                  </Slider>
                </div>
              </div>
            </div>
          </div>

          <div className='mx-5 max-w-screen-xl xl-padded:mx-auto mobile-margin'>
            <div className="pt-12 2xl:pt-12">
              <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mask-image relative p-0 md:flex md:min-h-[400px] md:rounded-xl md:bg-transparent dark:md:bg-transparent justify-end">
                <Link
                  to={"/campers"}
                  className="w-full md:flex justify-end items-center"
                  href=""
                >
                  <div className="graphic-section">
                    <div className="overflow-hidden">
                      <div className="-ml-[5%] w-[110%] overflow-hidden rounded-b-[60%20%] px-[5%] sm-p-0">
                        <div className="relative">
                          <picture className="text-0 max-w-lg absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-2/4 z-10 mobile-graphics">
                            {/* //graphic */}
                            {
                              i18n.resolvedLanguage === 'en' ?
                                <img className="inline-block max-w-full object-cover dark:brightness-90 rounded 2xs:rounded-lg" src="images/grafik_langzeitrabatte_camper_detail_en_copy_m.png" alt="" />
                                :
                                <img className="inline-block max-w-full object-cover dark:brightness-90 rounded 2xs:rounded-lg" src="images/german-grafik_langzeitrabatte_camper_detail_en_copy_m.png" alt="" />
                            }

                          </picture>
                          <picture className="text-0">
                            <img
                              className="inline-block max-w-full object-cover dark:brightness-90 max-h-[40vh] w-full group-hover/parent:scale-110 transition-all duration-300 mx-h-25"
                              src="images/pciture background discounts.jpg"
                              alt=""
                            />
                          </picture>
                        </div>
                      </div>
                    </div>
                  </div>
                  <picture className="text-0 absolute top-0 left-0 z-background hidden h-full w-full transition-all duration-300 group-hover/parent:scale-105 md:block md:group-hover/parent:scale-[103%]">
                    <img
                      className="inline-block max-w-full object-cover dark:brightness-90 h-full w-full"
                      src="images/pciture background discounts.jpg"
                      alt=""
                    />
                  </picture>
                  <div className="relative -mt-10 mb-4 flex justify-center xs:mb-4 md:hidden">
                    <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark child:h-20 child:w-20">
                      <img
                        src="images/percentage-icon.svg"
                        alt="percentage-icon"
                      />
                    </div>
                  </div>
                  <div className='z-0 m-6 mt-0 flex flex-col hidden md:visible'>
                    <picture className="text-0 max-w-lg">
                      <img className="inline-block max-w-full object-cover dark:brightness-90 rounded 2xs:rounded-lg" src="images/grafik_langzeitrabatte_camper_detail_en_copy_m.png" alt="" />
                    </picture>
                  </div>
                  <div className="z-0 m-md-6 m-3 mt-0 mb-6 flex flex-col text-center xs:mt-0 md:m-5 md:mt-5 md:min-h-[calc(100%-theme(spacing.5)*2)] md:w-full md:max-w-1/2 md:rounded-lg md:bg-white md:p-8 md:text-left md:shadow md:dark:bg-off-black-desat xl:max-w-[45%] 2xl:m-9 2xl:mt-9 2xl:min-h-[calc(100%-theme(spacing.9)*2)] ">
                    <div className="mb-6 sm-mb-2 flex items-center md:justify-start justify-center">
                      <div className="relative mr-4 hidden md:block">
                        <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark child:h-16 child:w-16">
                          <img
                            src="images/percentage-icon.svg"
                            alt="percentage-icon"
                          />
                        </div>
                      </div>
                      <div>
                        <p className="text-lg small-lg">{t("home.Travel More, Save More")}! </p>

                      </div>
                    </div>

                    <div className="mb-4 md:mb-6">
                      <h5 className="whitespace-pre-line break-words mb-4 text-xl xs:mb-6 large-title-mobile">
                        {t("home.Your Long-Term Discount")}
                      </h5>
                      <div className="min-h-[30px] break-words text-sm text-2md 2xl:text-2md 2xl:font-normal price-font image-box-text">
                        {t("home.Take advantage of our long-term discount starting from 8 nights. The longer your journey,  the bigger the discount – automatically save up to 15% on your booking price. And as a  bonus Sometimes, booking for a longer period can even get you an extra night at a lower  cost!")}
                      </div>
                    </div>
                    <div className="group/button block disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark p-3 pl-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated w-full md:mt-auto">
                      <div className="flex h-full items-center justify-between">
                        {/* <div className="inline-block align-top mr-2 rounded-full bg-white child:h-7 child:w-7 dark:bg-off-white">
                        <img width="34" src="images/services-svg-2.svg" alt="trip-booking"/>
                        </div> */}
                        <span className="flex">{t("home.Rent a camper")}</span>
                        <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                          <svg width="32" height="32" viewBox="0 0 32 32">
                            <g fill="none" fillRule="evenodd">
                              <path
                                d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                                fill="var(--svg-color-secondary, #fff)"
                              ></path>
                            </g>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>


            <div className="pt-8 2xl:pt-12 pb-0 mx-auto flex max-w-4xl flex-col items-center text-center">
              <p className="mb-4 sm-mb-2 whitespace-pre-line text-md">
                {t("home.Model Categories")}
              </p>
              <h2 className="whitespace-pre-line break-words text-xl md:text-2xl mb-6 sm-mb-2">
                {t("home.Camper, travel van, motorhome hire & Caravan")}
              </h2>
              <p className="whitespace-pre-line text-2md font-normal md:text-lg-paragraph md:font-normal text-20 ">
                {t("home.Pop-up roof or wet room? Maximum storage or ideal maneuverability")}?
              </p>
              <p className="whitespace-pre-line text-2md font-normal md:text-lg-paragraph md:font-normal text-20">
                {t("home.The good news We offer a wide selection to suit your specific camping dream")}
              </p>
            </div>

            <div className="">
              <div className="md:mx-5 max-w-screen-xl xl-padded:mx-auto pt-5">
                <Slider className="camper-slider" {...threerow}>

                  <Link to={"/campers"}>
                    <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent group flex h-full flex-col p-4 hover:shadow-elevated hover:dark:shadow-dark-elevated">
                      <div className="mask-image shrink-0 overflow-hidden rounded-sm 2xs:rounded-md">
                        <picture className="text-0 h-full w-full">
                          <img
                            className="inline-block max-w-full object-cover dark:brightness-90 aspect-square max-h-[220px] w-full group-hover:scale-110 transition-all duration-300"
                            src="images/picture camper van model.jpg"
                            alt="picture camper van model"
                          />
                        </picture>
                      </div>
                      <div className="flex h-full flex-col items-center justify-center px-4 text-center">
                        <p className="whitespace-pre-line text-lg mt-4">
                          {t("Rent.Rent a Campervan")}
                        </p>
                        <p className="mt-3 whitespace-pre-line line-clamp-5">
                          {t("home.Experience the freedom and ease with our compact campervans. Ideal for narrow streets  and spontaneous detours. Your perfect companions for flexible and effortless journeys into nature")}.
                        </p>
                        <div className="mt-auto">
                          <div className="group/button disabled:opacity-50 border border-transparent text-md text-white dark:text-off-white button-primary-bg dark:bg-primary-dark hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated p-3 rounded mt-4 inline-block px-4">
                            <div className="flex h-full items-center justify-center">
                              <span className="flex">{t("sale.View Campers")}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>

                  <Link to={"/campers"}>
                    <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent group flex h-full flex-col p-4 hover:shadow-elevated hover:dark:shadow-dark-elevated">
                      <div className="mask-image shrink-0 overflow-hidden rounded-sm 2xs:rounded-md">
                        <picture className="text-0 h-full w-full">
                          <img
                            className="inline-block max-w-full object-cover dark:brightness-90 aspect-square max-h-[220px] w-full group-hover:scale-110 transition-all duration-300"
                            src="images/picture travel van model-min.jpg"
                            alt="picture travel van model-min"
                          />
                        </picture>
                      </div>
                      <div className="flex h-full flex-col items-center justify-center px-4 text-center">
                        <p className="whitespace-pre-line text-lg mt-4">
                          {t("home.Van Rental")}
                        </p>
                        <p className="mt-3 whitespace-pre-line line-clamp-5">
                          {t("Rent.Enjoy independence in our vans with standing height")}.
                        </p>
                        <div className="mt-auto">
                          <div className="group/button disabled:opacity-50 border border-transparent text-md text-white dark:text-off-white button-primary-bg dark:bg-primary-dark hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated p-3 rounded mt-4 inline-block px-4">
                            <div className="flex h-full items-center justify-center">
                              <span className="flex">{t("sale.View Campers")}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>

                  <Link to={"/campers"}>
                    <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent group flex h-full flex-col p-4 hover:shadow-elevated hover:dark:shadow-dark-elevated">
                      <div className="mask-image shrink-0 overflow-hidden rounded-sm 2xs:rounded-md">
                        <picture className="text-0 h-full w-full">
                          <img
                            className="inline-block max-w-full object-cover dark:brightness-90 aspect-square max-h-[220px] w-full group-hover:scale-110 transition-all duration-300"
                            src="images/davidferk_mooveo-1.png"
                            alt="davidferk_portugal_t6_beach_sommer"
                          />
                        </picture>
                      </div>
                      <div className="flex h-full flex-col items-center justify-center px-4 text-center">
                        <p className="whitespace-pre-line text-lg mt-4">
                          {t("Rent.Motorhome Rental")}
                        </p>
                        <p className="mt-3 whitespace-pre-line line-clamp-5">
                          {t("Rent.Our motorhomes – the perfect choice for comfort and space")} {t("Rent.Enjoy ample room for your loved ones and all your luggage.")}
                          {/* {t("home.Discover the world in your own cozy home on wheels")}. */}
                        </p>
                        <div className="mt-auto">
                          <div className="group/button disabled:opacity-50 border border-transparent text-md text-white dark:text-off-white button-primary-bg dark:bg-primary-dark hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated p-3 rounded mt-4 inline-block px-4">
                            <div className="flex h-full items-center justify-center">
                              <span className="flex">{t("sale.View Campers")}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>


                  <Link to={"/campers"}>
                    <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent group flex h-full flex-col p-4 hover:shadow-elevated hover:dark:shadow-dark-elevated">
                      <div className="mask-image shrink-0 overflow-hidden rounded-sm 2xs:rounded-md">
                        <picture className="text-0 h-full w-full">
                          <img
                            className="inline-block max-w-full object-cover dark:brightness-90 aspect-square max-h-[220px] w-full group-hover:scale-110 transition-all duration-300"
                            src="images/picture caravan model-min.jpg"
                            alt="picture caravan model-min"
                          />
                        </picture>
                      </div>
                      <div className="flex h-full flex-col items-center justify-center px-4 text-center">
                        <p className="whitespace-pre-line text-lg mt-4">
                          {t("Rent.Caravan Rental")}
                        </p>
                        <p className="mt-3 whitespace-pre-line line-clamp-5">
                          {t("Rent.Enjoy the comfort of a large motorhome while having your own car with you at all times.")} {t("Rent.Ideal for a relaxed vacation at a location of your choice.")}
                        </p>
                        <div className="mt-auto">
                          <div className="group/button disabled:opacity-50 border border-transparent text-md text-white dark:text-off-white button-primary-bg dark:bg-primary-dark hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated p-3 rounded mt-4 inline-block px-4">
                            <div className="flex h-full items-center justify-center">
                              <span className="flex">{t("sale.View Campers")}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>

                </Slider>

              </div>
            </div>

            <div className="pt-8 2xl:pt-12 2xl:pt-0 pb-0 mx-auto flex max-w-3xl flex-col items-center text-center">
              <p className="mb-4 whitespace-pre-line text-md sm-mb-2">{t("home.Inspiration")}</p>
              <h2 className="whitespace-pre-line break-words text-xl md:text-2xl mb-6 sm-mb-2">
                {t("home.Rent a Camper & Explore Europe")}
              </h2>
              <p className="whitespace-pre-line text-2md font-normal md:text-lg-paragraph md:font-normal text-20">
                {t("home.Rent now and explore Europe at your own pace")}.
              </p>
              <p className="whitespace-pre-line text-2md font-normal md:text-lg-paragraph md:font-normal text-20">{t("home.Let our blog inspire you for unforgettable camping experiences")}.</p>
            </div>

            <div className="md:pb-8 pb-2 2xl:pb-12">

              <div className="md:mx-5 max-w-screen-xl xl-padded:mx-auto" >
                <Vanlife />
              </div>

            </div>

            <div className="flex justify-center pt-0 pb-8">
              <Link
                to={"/blog"}
                className="group/button disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark p-3 pl-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated inline-block"
              >
                <div className="flex h-full items-center justify-between">
                  <span className="flex">{t("home.To the blog")}</span>
                  <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path
                          d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                          fill="var(--svg-color-secondary, #163435)"
                        ></path>
                      </g>
                    </svg>
                  </div>
                </div>
              </Link>
            </div>

            <div className="pt-4 pb-4">
              <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mask-image relative p-0 md:flex md:min-h-[400px] md:rounded-xl md:bg-transparent dark:md:bg-transparent justify-end">
                <Link to={"/tripplanner"} className="w-full md:flex">
                  <div className="md:hidden">
                    <div className="overflow-hidden">
                      <div className="-ml-[5%] w-[110%] overflow-hidden rounded-b-[60%20%] px-[5%]">
                        <div className="relative">
                          <picture className="text-0">
                            <img
                              className="inline-block max-w-full object-cover dark:brightness-90 max-h-[40vh] w-full group-hover/parent:scale-110 transition-all duration-300"
                              src="images/travel-assistant-mobile.png"
                              alt="artboard"
                            />
                          </picture>
                        </div>
                      </div>
                    </div>
                  </div>
                  <picture className="text-0 absolute top-0 left-0 z-background hidden h-full w-full transition-all duration-300 group-hover/parent:scale-105 md:block md:group-hover/parent:scale-[103%]">
                    <img
                      className="inline-block max-w-full object-cover dark:brightness-90 h-full w-full"
                      src="images/travel-assistant.png"
                      alt="artboard"
                    />
                  </picture>
                  <div className="relative -mt-10 mb-4 flex justify-center xs:mb-4 md:hidden">
                    <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark child:h-20 child:w-20">
                      <img
                        src="images/travel-planing-icon.svg"
                        alt="travel-planing-icon"
                      />
                    </div>
                  </div>
                  <div className="z-0 m-6 mt-0 flex flex-col text-center xs:mt-0 md:m-5 md:mt-5 md:min-h-[calc(100%-theme(spacing.5)*2)] md:w-full md:max-w-1/2 md:rounded-lg md:bg-white md:p-8 md:text-left md:shadow md:dark:bg-off-black-desat xl:max-w-[45%] 2xl:m-9 2xl:mt-9 2xl:min-h-[calc(100%-theme(spacing.9)*2)]">
                    <div className="mb-6 flex items-center justify-center md:justify-start sm-mb-2">
                      <div className="relative mr-4 hidden md:block">
                        <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark child:h-16 child:w-16">
                          <img
                            src="images/travel-planing-icon.svg"
                            alt="travel-planing"
                          />
                        </div>
                      </div>
                      <p className="text-lg">{t("home.Our Travel Planner")}</p>
                    </div>
                    <h2 className="whitespace-pre-line break-words mb-4 text-xl xs:mb-6 ">
                      {t("home.Travel Planner Your Travel Assistant")}
                    </h2>
                    <div className="mb-4 md:mb-6">
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal price-font image-box-text">
                        {t("home.Start your vacation relaxed – with our personal itinerary planner. We design your individual  travel route in your desired countries – tailored for your unforgettable camper experience")}.
                      </div>
                      <div className="mobile-none min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                    </div>
                    <div className="group/button block disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark p-3 pl-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated w-full md:mt-auto">
                      <div className="flex h-full items-center justify-between">
                        <span className="flex">{t("home.More info")}</span>
                        <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                          <svg width="32" height="32" viewBox="0 0 32 32">
                            <g fill="none" fillRule="evenodd">
                              <path
                                d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                                fill="var(--svg-color-secondary, #fff)"
                              ></path>
                            </g>
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="pt-8 2xl:pt-8 pb-0 mx-auto flex max-w-5xl flex-col items-center text-center">
              <h2 className="whitespace-pre-line break-words text-xl md:text-2xl mb-6 sm-mb-2">
                {t("home.Top-equipped, reliable freedom on wheels!")}
              </h2>
              <p className="whitespace-pre-line text-2md font-normal md:text-sm-paragraph md:font-normal text-20">
                {t("home.With our top-equipped and well-maintained campers, you'll experience absolute freedom on your road trip. Whether it's a van, motorhome, or caravan, we provide you with a reliable companion for your journey")}.
              </p>
            </div>
          </div>

          <div className='topeq-slider home-topeq-slider'>
            <div className='mx-5 sm-m-0 pt-5 pb-8 sm-pt-0'>
              <TopEquipped />
            </div>
          </div>
          <div className='mx-5 max-w-screen-xl xl-padded:mx-auto pt-4 pb-8 mobile-margin'>
            <div className="pb-12 2xl:pb-16">
              <div className="overflow-hidden rounded bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg mx-auto flex max-w-3xl flex-col items-center md:p-8 2xl:p-16">
                <div className="inline-flex items-center justify-center rounded-full primary-borber border bg-white p-1 dark:bg-off-white mb-6 border-info dark:border-info sm-mb-2">
                  <div className="inline-block align-top child:h-8 child:w-8 2xl:child:h-10 2xl:child:w-10">
                    <svg
                      width="32px"
                      height="32px"
                      viewBox="0 0 32 32"
                      version="1.1"
                    >
                      <title>icon/actions_info</title>
                      <g
                        id="icon/actions_info"
                        stroke="none"
                        strokeWidth="1"
                        fill="EE7214"
                        fillRule="evenodd"
                      >
                        <path
                          d="M15.0627781,13.2833484 C14.2292216,13.2833484 13.4095577,13.2833484 12.5843367,13.2833484 C12.4040026,13.2849033 12.2246588,13.3085077 12.0508606,13.3535621 C11.8387141,13.3950229 11.676741,13.5559636 11.6451964,13.7566407 C11.5865132,13.9746677 11.7037133,14.2006319 11.9230486,14.2923451 C12.190053,14.4092135 12.476874,14.4813856 12.7704977,14.5055866 C13.5429267,14.5393932 13.8541211,14.8566551 13.7374232,15.6004 C13.5206985,16.9916712 13.2289538,18.3725403 12.9788868,19.7612109 C12.809397,20.6973934 12.6848928,21.2550492 12.5843367,22.2403035 C12.4530963,23.5262052 13.2109841,24.7401952 14.1368811,25.0131061 C15.0627781,25.286017 15.724825,25.286017 16.3214484,25.2794864 C17.5999796,25.2780159 18.8212793,24.7831633 19.6973522,23.9116198 C19.8564682,23.7743898 19.9759403,23.6017433 20.0446674,23.409722 C20.0782267,23.2419815 20.0359721,23.0687264 19.9279695,22.9312287 C19.7974575,22.8547704 19.6367149,22.8384803 19.4917416,22.8870201 C19.2639028,22.9572338 19.0610707,23.1028622 18.838789,23.1938799 C18.5790019,23.3073326 18.3070186,23.3945099 18.0274607,23.4539306 C17.8020089,23.5057029 17.5636393,23.4622801 17.3753928,23.335146 C17.1871463,23.2080119 17.0679843,23.0099721 17.0494211,22.7934019 C17.0026623,22.551429 16.9942363,22.3043299 17.0244144,22.0600589 C17.1188841,21.4567414 17.2522531,20.8586248 17.3689511,20.2605082 C17.6857025,18.6291236 18.0070849,16.9968722 18.3330981,15.3637539 C18.4220108,14.9164667 18.5720509,14.4769811 18.4720242,14.0062893 C18.4564927,13.79721 18.3517318,13.6027725 18.1813424,13.4667803 C18.010953,13.3307881 17.7892668,13.2646798 17.5662261,13.2833484 C16.7326696,13.2833484 15.8963346,13.2833484 15.0627781,13.2833484 Z"
                          id="Path"
                          fill="var(--svg-color-secondary, #EE7214)"
                        ></path>
                        <path
                          d="M16.5742939,6.28268183 C15.5498224,6.23775316 14.6000794,6.81738803 14.1716262,7.74904688 C13.743173,8.68070573 13.9212618,9.77901022 14.6221567,10.5275466 C15.3230516,11.276083 16.4072909,11.52591 17.3650741,11.159561 C18.3228574,10.793212 18.9636082,9.88358197 18.9860507,8.85837141 C19.0250803,7.48444098 17.9479291,6.33655519 16.5742939,6.28823887 L16.5742939,6.28268183 Z"
                          id="Path"
                          fill="var(--svg-color-secondary, #EE7214)"
                        ></path>
                      </g>
                    </svg>
                  </div>
                </div>
                <p className="whitespace-pre-line text-center text-lg md:text-xl">{t("home.Questions? Here, you'll find countless answers!")}</p>
                <Link to={"/contactus"} className="w-100 group/button disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark p-3 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated pl-3 py-2 inline-block mt-4" href="https://cms.camperboys.com/pdf-files/equipment_vw_t6_1_california_ocean_editionen-1.pdf">
                  <div className="flex h-full items-center justify-between">
                    <div className="inline-block align-top mr-2 rounded-full bg-white p-1 child:h-7 child:w-7 dark:bg-off-white">
                      <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
                        <title>icon/actions_question</title>
                        <g id="icon/actions_question" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                          <path d="M16.5609275,17.3580413 L16.3692127,19.2467266 C15.73983,19.3562778 14.562691,19.3562778 13.8779956,19.3015022 C13.6320422,19.027624 13.6862809,15.1407023 13.6862809,15.1407023 C16.2596615,15.1407023 18.2305102,14.2369044 18.2305102,12.1838923 C18.2305102,11.1442293 17.4915762,9.66582429 15.3837884,9.66582429 C13.4951032,9.66582429 12.1536372,10.6512486 12.1536372,10.6512486 C12.1536372,10.6512486 10.9222596,8.70778776 11.003886,8.05048019 C11.6338057,7.33947102 13.3033885,6.6 15.8767691,6.6 C19.0242198,6.6 21.6523761,8.43390961 21.6523761,11.8831633 C21.6523761,15.1680901 19.2707101,17.0019997 16.5609275,17.3580413 M16.5732788,25.0115932 C15.8048089,25.7891924 14.5471175,25.7972476 13.7695183,25.0287777 C12.9913822,24.2608449 12.9833269,23.0031534 13.7517968,22.2250173 C14.5197297,21.4468811 15.7779581,21.4393629 16.5555573,22.2072957 C17.3336935,22.9757656 17.3412117,24.2334571 16.5732788,25.0115932" id="Fill-1" fill="var(--svg-color-secondary, #163435)"></path>
                        </g>
                      </svg>
                    </div>
                    <span className="flex">
                      {t("aboutus.To the helpcenter")}
                    </span>
                    <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                      <svg width="32" height="32" viewBox="0 0 32 32">
                        <g fill="none" fillRule="evenodd">
                          <path d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z" fill="var(--svg-color-secondary, #163435)"></path>
                        </g>
                      </svg>
                    </div>
                  </div>
                </Link>
              </div>
            </div>

            <div className="md:mx-5 max-w-screen-xl xl-padded:mx-auto pb-8">
              <div className=" pt-8 pb-10">
                <div className="newsletter-wrapper rounded bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg flex flex-col items-center overflow-visible from-secondary/90 to-secondary/50 text-center text-white dark:to-primary-dark 2xl:p-4 mt-10 pt-0 2xl:mt-12 2xl:pt-0">
                  <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-10 mb-4 child:h-20 child:w-20 md:child:h-24 md:child:w-24 2xl:-mt-12">
                    <img src="images/newsletter-icon.svg" alt="newsletter-icon" />
                  </div>
                  <h2 className="whitespace-pre-line break-words mb-4 max-w-2xl text-xl md:text-2xl text-orange">
                    {t("home.Sign Up for Our Newsletter and Save Up to €50")}
                  </h2>
                  <p className="mb-0 max-w-lg">
                    {t("home.Receive free travel recommendations, exclusive offers, and travel suggestions directly in  your inbox. Secure a €50 voucher for your first booking")}<br />
                    {t("aboutus.Act quickly")}: {t("home.Please note that the discount  from the newsletter cannot be combined with other marketing discounts and is valid for  first-time customers only")}.
                    <br />
                    {/* {t("aboutus.Act quickly")}: {t("aboutus.The newsletter discount is valid for first bookings only and cannot be combined with other offers.")}<br /> */}
                    <p className="mb-8 max-w-lg">{t("aboutus.Your next adventure awaits! Sign up now!")}<br />{t("aboutus.Sign up now!")}</p>
                    {/* <p className='mobile-none'></p>  */}
                  </p>
                  {
                    isFormSubmit ?
                      <>
                        <div className="opacity-100 scale-100 success-msg">
                          <div className="inline-flex items-center justify-center rounded-full orange-border bg-white p-1 dark:bg-off-white">
                            <div className="inline-block align-top">
                              <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                <g fill="none" fill-rule="evenodd">
                                  <path d="m21.906 9.488-8.547 9.56-3.45-3.096a1.757 1.757 0 0 0-2.563.209c-.64.792-.547 1.98.21 2.659l4.764 4.274c.735.66 1.833.602 2.496-.13l9.73-10.869a1.954 1.954 0 0 0-.069-2.665 1.758 1.758 0 0 0-2.571.058z" fill="var(--svg-color-secondary, #163435)"></path>
                                </g>
                              </svg>
                            </div>
                          </div>
                          <h2 className="mt-4 max-w-lg text-lg-paragraph"> {t("aboutus.Thank you for signing up!")}</h2>
                        </div>
                      </>
                      :
                      <form
                        className="flex w-full max-w-3xl flex-col lg:grid lg:grid-cols-3 lg:gap-4 position-relative"
                        onSubmit={onSubmitForm}
                      >

                        <div className="transition-all mb-4">
                          <div className="relative z-foreground mb-4 flex h-[60px] items-center rounded border bg-white text-black transition-all focus-within:border-primary focus:border-primary dark:focus-within:border-primary-dark border-transparent shadow dark:shadow-dark dark:bg-off-white dark:text-black">
                            <div className="inline-block align-top absolute pl-3 child:h-7 child:w-7 dark:cssvar-secondary-to-off-white dark:cssvar-secondary-to-secondary">
                              <svg
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16 14.6527C13.6105 14.6527 11.6736 12.7158 11.6736 10.3264C11.6736 7.93688 13.6105 6 16 6C18.3895 6 20.3264 7.93688 20.3264 10.3264C20.3264 12.7158 18.3895 14.6527 16 14.6527Z"
                                  fill="var(--svg-color-secondary, #163435)"
                                ></path>
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M16.1565 15.9742C12.2188 15.8883 9 19.0545 9 22.9726C9 23.4621 9.05258 23.9383 9.15042 24.3979C9.26224 24.9247 9.72616 25.3021 10.2643 25.3021H21.7361C22.2693 25.3021 22.7352 24.9324 22.847 24.4112C23.0091 23.6528 23.05 22.8501 22.9329 22.0181C22.4596 18.6482 19.5587 16.0487 16.1565 15.9742Z"
                                  fill="var(--svg-color-secondary, #163435)"
                                ></path>
                              </svg>
                            </div>
                            <div className="peer flex w-full flex-col">
                              <input
                                type="text"
                                className="peer w-full rounded bg-transparent pt-2 pb-1 pl-12 text-md outline-none focus:pr-6 pr-6"
                                placeholder={t("sale.First Name")}
                                name='first_name'
                              />
                            </div>
                          </div>
                          <div className="relative bottom-4 overflow-hidden bg-warning text-center text-xs font-medium text-black transition-all -mt-4 rounded-b max-h-0"></div>
                        </div>
                        <div className="transition-all mb-4">
                          <div className="relative z-foreground mb-4 flex h-[60px] items-center rounded border bg-white text-black transition-all focus-within:border-primary focus:border-primary dark:focus-within:border-primary-dark border-transparent shadow dark:shadow-dark dark:bg-off-white dark:text-black">
                            <div className="inline-block align-top absolute pl-3 child:h-7 child:w-7 dark:cssvar-secondary-to-off-white dark:cssvar-secondary-to-secondary">
                              <svg
                                width="32px"
                                height="32px"
                                viewBox="0 0 32 32"
                                version="1.1"
                              >
                                <title>icon_contact_mail</title>
                                <g
                                  id="icon_contact_mail"
                                  stroke="none"
                                  strokeWidth="1"
                                  fill="none"
                                  fillRule="evenodd"
                                >
                                  <g
                                    id="Group"
                                    transform="translate(5.000000, 7.700000)"
                                    fill="var(--svg-color-secondary, #163435)"
                                  >
                                    <path
                                      d="M17.1541722,0 C18.5243618,0 19.2165846,0.133674373 19.9368674,0.518885858 C20.6000248,0.873546296 21.1264537,1.39997522 21.4811141,2.06313259 C21.8663256,2.78341544 22,3.47563815 22,4.8458278 L21.9981828,11.9648343 C21.9810588,13.1622375 21.8406449,13.810058 21.4811141,14.482322 C21.1264537,15.1454793 20.6000248,15.6719082 19.9368674,16.0265687 C19.2165846,16.4117802 18.5243618,16.5454545 17.1541722,16.5454545 L4.8458278,16.5454545 C3.47563815,16.5454545 2.78341544,16.4117802 2.06313259,16.0265687 C1.39997522,15.6719082 0.873546296,15.1454793 0.518885858,14.482322 C0.133674373,13.7620391 0,13.0698164 0,11.6996267 L0,4.8458278 C0,3.47563815 0.133674373,2.78341544 0.518885858,2.06313259 C0.873546296,1.39997522 1.39997522,0.873546296 2.06313259,0.518885858 C2.78341544,0.133674373 3.47563815,0 4.8458278,0 Z M20,5.563 L11,12.1092247 L2,5.564 L2,11.6996267 L2.00467769,12.1173697 C2.02375106,12.8767631 2.10330814,13.2040417 2.28251225,13.5391242 C2.45077309,13.8537447 2.69170989,14.0946815 3.0063303,14.2629423 C3.31348932,14.4272127 3.61409085,14.507752 4.24698433,14.5347258 L4.6271902,14.5443078 L17.3728098,14.5443078 C17.4423488,14.5435357 17.5086323,14.5423663 17.5719151,14.5407769 L17.9176389,14.5260186 C18.4406182,14.4924165 18.7144342,14.4122791 18.9936697,14.2629423 C19.3082901,14.0946815 19.5492269,13.8537447 19.7174877,13.5391242 C19.9265592,13.1481946 20,12.767887 20,11.6996267 L20,5.563 Z M17.1541722,2 L4.8458278,2 L4.42808487,2.00467769 C3.66869148,2.02375106 3.34141286,2.10330814 3.0063303,2.28251225 C2.69170989,2.45077309 2.45077309,2.69170989 2.28251225,3.0063303 C2.24438729,3.07761778 2.21077244,3.14855205 2.1812906,3.22336911 L10.999,9.63500008 L19.8187094,3.22336911 C19.7892276,3.14855205 19.7556127,3.07761778 19.7174877,3.0063303 C19.5492269,2.69170989 19.3082901,2.45077309 18.9936697,2.28251225 C18.6585871,2.10330814 18.3313085,2.02375106 17.5719151,2.00467769 L17.1541722,2 Z"
                                      id="Combined-Shape"
                                    ></path>
                                  </g>
                                </g>
                              </svg>
                            </div>
                            <div className="peer flex w-full flex-col">
                              <input
                                type="email"
                                className="peer w-full rounded bg-transparent pt-2 pb-1 pl-12 text-md outline-none focus:pr-6 pr-6"
                                placeholder={t("sale.Email")} name='email'
                              />

                            </div>
                          </div>
                          <div className="relative bottom-4 overflow-hidden bg-warning text-center text-xs font-medium text-black transition-all -mt-4 rounded-b max-h-0"></div>
                        </div>
                        
                        {/* <p className='error-msg' style={{ color: 'red' }}>{Error}</p> */}
                        <button
                          type="submit"
                          className="group/button disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white button-primary-bg dark:bg-primary-dark p-3 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated pl-3 py-2 inline-block lg:h-[60px]"
                        >
                          <div className="flex h-full items-center justify-between">
                            <span className="flex">{t("aboutus.Receive Voucher")}</span>
                            <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                              <svg
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="#000000" fillRule="evenodd">
                                  <path
                                    d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                                    fill="#fff"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                          </div>
                        </button>
                        {Error ? <p className="mb-2 flex w-full animate-wiggle items-center justify-between rounded bg-warning px-4 py-2 text-lg text-white" style={{ color: 'red' }}>{Error}</p> : ''}
                      </form>
                  }
                </div>
              </div>
              <div className="flex justify-center pt-0">
                <Link
                  to={"/campers"}
                  className="group/button disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white button-primary-bg dark:bg-primary-dark p-3 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated pl-3 py-2 inline-block"
                >
                  <div className="flex h-full items-center justify-between">
                    <div className="inline-block align-top mr-2 rounded-full bg-white p-1 child:h-7 child:w-7 dark:bg-off-white">
                      <img src="images/camper-icon.svg" alt="camper-icon" />
                    </div>
                    <span className="flex">{t("aboutus.View Campers")}</span>
                    <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                      <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g fill="none" fillRule="evenodd">
                          <path
                            d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                            fill="var(--svg-color-secondary, #163435)"
                          ></path>
                        </g>
                      </svg>
                    </div>
                  </div>
                </Link>
              </div>
            </div>


          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Home;
