import client from "../client";
let lng = localStorage.getItem('lng') ? localStorage.getItem('lng'):'de';
export default {
  index: () =>client.get(`voucher/index?lng=`+lng),
  show:(id)=>client.get(`voucher/show/${id}?lng=`+lng),
  store: (userData) =>client.post('vouchercheckout/store', { json: userData }),
  storestripe: (userData) =>client.post('vouchercheckout/storestripe', { json: userData }),
  update:(id,userData)=>client.post(`voucherpay/update/${id}`, { json: userData }),
  stripCheckout:(userData)=>client.post(`voucherpay/stripCheckout?lng=`+lng, { json: userData }),
  checkstripepayment: (id) => client.get(`voucherpay/checkstripepayment/${id}`),
  updatepaymentStatus: (stripedata) => client.post('voucherpay/updatepaymentStatus', { json: stripedata }),
};