import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import VoucherService from '../../Api/services/VoucherService';
import Loader from "../../Components/Loader/Loader";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

const VoucherDetails = (props) => {
  var imageUrl = process.env.REACT_APP_LARAVEL_Voucher_IMAGE_URL;
  const [loader, setLoder] = useState(true);
  const params = useParams();
  const { i18n, t } = useTranslation();
  const variable = useLocation();
  const navigate = useNavigate();
  const [voucher, setVoucher] = useState([]);
  const [price, setPrice] = useState([]);
  const [checkoutPrice, setCheckoutPrice] = useState(0);
  const [error, setError] = useState("")
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  // Get the current date
  const today = new Date();
  // Set the minimum selectable date to tomorrow
  const minSelectableDate = new Date(today);
  minSelectableDate.setDate(today.getDate() + 1);
  useEffect(() => {
    var id = params.id;
    getVoucherByID(id);

  }, []);

  const getVoucherByID = async (id) => {
    try {
      let getVoucher = await VoucherService.show(id).json();
      setVoucher(getVoucher.data);

      var p = []
      for (var i = getVoucher.data.startprice; i <= getVoucher.data.endprice; i++) {
        if (i % 25 == 0) {
          p.push(i)
        }
      }
      setPrice(p)
      setLoder(false);
    } catch (error) {
      if (error.name === 'HTTPError') {
        const errorJson = await error.response.json();
      }
    }
  };

  const handleCheckout = (id) => {
    if (checkoutPrice === 0) {
      setError(t("Voucher.Please select price!"))
    } else {
      navigate(`/voucher-checkout/${id}`, { state: checkoutPrice });
      setError("")
    }

  }
  var options = [];

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    asNavFor: nav2,
  };

  const settingsThumbs = {
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: nav1,
    dots: false,

    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: "5px",
    arrows: false,
    infinite: false,
  };


  return (
    <>
      <Header />
      <main className="min-h-screen">
        <div className="mb-8 flex max-w-screen-xl lg:mx-5 lg:grid lg:grid-cols-12 xl-padded:mx-auto pt-100">
          <div className="mx-auto w-full lg:col-span-8 xl:px-12 2xl:px-0 md:pt-12 pt-0">
            {loader ? <Loader /> :

              <div className="mx-5">

                <div className="item voucher-detail-img">
                  <img class="" src={imageUrl + voucher.voucher_image} alt="clara" />
                </div>

                <div className="flex justify-center md:pt-8 lg:hidden">
                  <div className="md:mx-5 w-full overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg mt-8  p-5 md:p-5 pt-5 md:pt-7 transition-all duration-300 lg:block top-6">
                    <div className="flex max-h-[calc(100vh-80px)] flex-col">
                      <div className="flex max-h-full flex-col items-center  text-center">
                        <h2 className="mb-1 whitespace-pre-line text-xl 2xl:text-2xl price-font fw-600 voucher-detail-text">
                          <p>{voucher.title}</p>
                        </h2>
                        <p><span className="md:mb-3 mb-2 text-md text-primary text-orange block text-18">{voucher.startprice}€ - {voucher.endprice}€</span></p>
                        {/* <p className="mb-3" dangerouslySetInnerHTML={{ __html: voucher.description }}></p> */}
                        <div className="mt-2 w-full rounded bg-off-white p-5 text-left dark:bg-black mb-4 md:mb-6">

                          <div className="mb-4 flex items-center last:mb-0">
                            <div className="inline-flex items-center justify-center rounded-full border border-primary bg-white dark:border-primary-dark dark:bg-off-white mr-3 p-0">
                              <div className="inline-block align-top child:h-6 child:w-6">
                                <svg width="32" height="32" viewBox="0 0 32 32">
                                  <g fill="none" fillRule="evenodd">
                                    <path
                                      d="m21.906 9.488-8.547 9.56-3.45-3.096a1.757 1.757 0 0 0-2.563.209c-.64.792-.547 1.98.21 2.659l4.764 4.274c.735.66 1.833.602 2.496-.13l9.73-10.869a1.954 1.954 0 0 0-.069-2.665 1.758 1.758 0 0 0-2.571.058z"
                                      fill="var(--svg-color-secondary, #163435)"
                                    ></path>
                                  </g>
                                </svg>
                              </div>
                            </div>
                            <p className="text-md">{t("Voucher.SSL-secured checkout")}</p>
                          </div>
                          <div className="mb-4 flex items-center last:mb-0">
                            <div className="inline-flex items-center justify-center rounded-full border border-primary bg-white dark:border-primary-dark dark:bg-off-white mr-3 p-0">
                              <div className="inline-block align-top child:h-6 child:w-6">
                                <svg width="32" height="32" viewBox="0 0 32 32">
                                  <g fill="none" fillRule="evenodd">
                                    <path
                                      d="m21.906 9.488-8.547 9.56-3.45-3.096a1.757 1.757 0 0 0-2.563.209c-.64.792-.547 1.98.21 2.659l4.764 4.274c.735.66 1.833.602 2.496-.13l9.73-10.869a1.954 1.954 0 0 0-.069-2.665 1.758 1.758 0 0 0-2.571.058z"
                                      fill="var(--svg-color-secondary, #163435)"
                                    ></path>
                                  </g>
                                </svg>
                              </div>
                            </div>
                            <p className="text-md">{t("Voucher.Quick delivery as PDF")}</p>
                          </div>
                          <div className="mb-4 flex items-center last:mb-0">
                            <div className="inline-flex items-center justify-center rounded-full border border-primary bg-white dark:border-primary-dark dark:bg-off-white mr-3 p-0">
                              <div className="inline-block align-top child:h-6 child:w-6">
                                <svg width="32" height="32" viewBox="0 0 32 32">
                                  <g fill="none" fillRule="evenodd">
                                    <path
                                      d="m21.906 9.488-8.547 9.56-3.45-3.096a1.757 1.757 0 0 0-2.563.209c-.64.792-.547 1.98.21 2.659l4.764 4.274c.735.66 1.833.602 2.496-.13l9.73-10.869a1.954 1.954 0 0 0-.069-2.665 1.758 1.758 0 0 0-2.571.058z"
                                      fill="var(--svg-color-secondary, #163435)"
                                    ></path>
                                  </g>
                                </svg>
                              </div>
                            </div>
                            <p className="text-md">{t("Voucher.Questions?")} <Link className="underline" to={"/contactus"}>{t("Voucher.Contact us!")}</Link></p>
                          </div>
                        </div>
                        <span className="group outline-none-focus w-full">
                          <div className="relative flex items-center px-2 h-12 border border-secondary border-opacity-disabled rounded block w-full h-12 group-focus:border-primary rounded cursor-default">
                            <div className=" items-center justify-between w-full opacity-disabled custom-pickup-return-none">
                              <select onChange={e => setCheckoutPrice(e.target.value)}>
                                <option>{t("Voucher.Select Price")}</option>
                                {price && price.map(fbb =>
                                  <option key={fbb} value={fbb}>{fbb}</option>
                                )}
                              </select>
                              {/* <Select
                                // autoFocus
                                backspaceRemovesValue={false}
                                hideSelectedOptions={false}
                                tabSelectsValue={false}
                                className="react-select-container"
                                classNamePrefix="react-select"
                                options={price}
                                isSearchable={false}
                              
                                placeholder="{t("Voucher.Select Price")}"
                          
                              /> */}

                            </div></div>

                        </span>

                      </div>
                    </div>
                    <Button onClick={(e) => handleCheckout(params.id)} className="mt-3 md:mt-5 grid-cols1 inline-grid grid-rows-1 text-white bg-secondary hover:bg-primary pl-5 pr-2 w-full h-12 rounded bg-primary hover:bg-secondary transition-all"> <span className="relative flex col-start-1 row-start-1 items-center justify-between w-full h-full transition-opacity duration-300">
                      <span className="transform transition-transform duration-300 translate-x-0">
                        <span>{t("Voucher.Continue to checkout1")}</span>
                      </span>
                      <svg className="w-7 h-7 cssvar-secondary-to-white pointer-events-none" width="32" height="32"><svg viewBox="0 0 32 32" fill="none" id="icon-chevron-right"><path fillRule="evenodd" clipRule="evenodd" d="M22.1256 15.9999L14.479 8.35332C14.009 7.88273 13.2445 7.88213 12.7739 8.35211L12.3532 8.77342C11.8809 9.24581 11.8833 10.0073 12.3544 10.4785L17.8759 15.9999L12.3544 21.5219C11.8833 21.9925 11.8809 22.7546 12.3532 23.2264L12.7739 23.6477C13.2445 24.1182 14.009 24.117 14.479 23.6465L22.1256 15.9999Z" fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"></path></svg></svg>
                    </span></Button>
                    <div className="error-text text-center mt-"> {error} </div>
                  </div>
                </div>

                <div className="pt-12 pb-12 max-w-2xl m-auto">
                  <div className="bg-primary flex  justify-center full-rounded rounded-lg p-4 dark:bg-black sm:flex-row sm:px-6 sm:py-6">
                    <div className="md:mb-4 flex items-center last:mb-0 sm:mx-2 sm:mb-0 sm:w-1/5 flex-col">
                      <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark md:mr-3 child:h-12 child:w-12 sm:mr-0 sm:mb-3 sm:child:h-16 sm:child:w-16">
                        <img className='white-img mh-64' src="../images/flexible-customizable-icon.svg" />
                      </div>
                      <p className="text-white fw-500 whitespace-pre-line text-md text-center green-box-text px-3 md:px-0">
                        {t("Voucher.Flexible Date")}
                      </p>

                    </div>
                    <div className="md:mb-4 flex items-center last:mb-0 sm:mx-2 sm:mb-0 sm:w-1/5 flex-col">
                      <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark md:mr-3 child:h-12 child:w-12 sm:mr-0 sm:mb-3 sm:child:h-16 sm:child:w-16">
                        <img className='white-img mh-64' src="../images/three-years-icon.svg" />
                      </div>
                      <p className="text-white fw-500 whitespace-pre-line text-md text-center green-box-text px-3 md:px-0">
                        {t("Voucher.Valid for 3 years")}
                      </p>

                    </div>
                    <div className="md:mb-4 flex items-center last:mb-0 sm:mx-2 sm:mb-0 sm:w-1/5 flex-col">
                      <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark md:mr-3 child:h-12 child:w-12 sm:mr-0 sm:mb-3 sm:child:h-16 sm:child:w-16">
                        <img className='white-img mh-64' src="../images/fast-delivery-svgrepo-com.svg" />
                      </div>
                      <p className="text-white fw-500 whitespace-pre-line text-md text-center green-box-text px-3 md:px-0">
                        {t("Voucher.Fast delivery")}
                      </p>

                    </div>
                    <div className="md:mb-4 flex items-center last:mb-0 sm:mx-2 sm:mb-0 sm:w-1/5 sm:flex-col hidden md:flex">
                      <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark mr-3 child:h-12 child:w-12 sm:mr-0 sm:mb-3 sm:child:h-16 sm:child:w-16">
                        <img className='white-img mh-64' src="../images/customize-icon.svg" />
                      </div>
                      <p className="text-white fw-500 whitespace-pre-line text-md sm:text-center green-box-text">
                        {t("Voucher.Customizable")}
                      </p>

                    </div>
                    <div className="md:mb-4 flex items-center last:mb-0 sm:mx-2 sm:mb-0 sm:w-1/5 sm:flex-col hidden md:flex">
                      <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark mr-3 child:h-12 child:w-12 sm:mr-0 sm:mb-3 sm:child:h-16 sm:child:w-16">
                        <img className='white-img mh-64' src="../images/sustainable-sustainability-svgrepo-com.svg" />
                      </div>
                      <p className="text-white fw-500 whitespace-pre-line text-md sm:text-center green-box-text">
                        {t("Voucher.Sustainable gift")}
                      </p>

                    </div>

                  </div>
                </div>

                <div className="text-detail">
                  <div className="mb-2 min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal tablet-text">
                    {t("Voucher.This voucher is valid for renting a camper at")} <a className="fw-700 underline" target="_blank" href="www.borntocamp.de">www.borntocamp.de</a>!
                  </div>
                  <div className="mb-2 min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal tablet-text">
                    {t("Voucher.Order your voucher here and receive it within 48 hours. Need it faster? Send us an email at")}&nbsp;
                    <a className="fw-700 underline" href="mailto:'info@borntocamp.de'">info@borntocamp.de</a>, {t("Voucher.and we'll respond as quickly as possible to send your voucher promptly.")}
                  </div>
                  <div className="mb-2 min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal tablet-text">
                    {t("Voucher.For maximum flexibility, the voucher remains valid for a full three years, and any remaining balances will be retained.")}
                  </div>
                  <div className="mb-2 min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal tablet-text">
                    {t("Voucher.Would you like to set a specific amount for your value voucher?")}<br /> {t("Voucher.Feel free to contact us at")}&nbsp;
                    <a className="fw-700 underline" href="mailto:'info@borntocamp.de'">info@borntocamp.de</a>.
                  </div>
                </div>

                <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                  <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mask-image relative p-0 md:flex md:min-h-[400px] md:rounded-xl md:bg-transparent dark:md:bg-transparent justify-end">
                    <Link className="w-full md:flex" to={'/explanatoryvideo'}>
                      <div className="md:hidden">
                        <div className="overflow-hidden">
                          <div className="-ml-[5%] w-[110%] overflow-hidden rounded-b-[60%20%] px-[5%]">
                            <div className="relative">
                              <picture className="text-0">
                                <img
                                  className="inline-block max-w-full object-cover dark:brightness-90 max-h-[20vh] md:max-h-[40vh] w-full group-hover/parent:scale-110 transition-all duration-300"
                                  src="../images/video.jpg"
                                  alt="video_teaser"
                                />
                              </picture>
                            </div>
                          </div>
                        </div>
                      </div>
                      <picture className="text-0 absolute top-0 left-0 z-background hidden h-full w-full transition-all duration-300 group-hover/parent:scale-105 md:block md:group-hover/parent:scale-[103%]">
                        <img
                          className="inline-block max-w-full object-cover dark:brightness-90 h-full w-full"
                          src="../images/video.jpg"
                          alt="video_teaser"
                        />
                      </picture>
                      <div className="relative -mt-10 mb-4 flex justify-center xs:mb-4 md:hidden">
                        <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark child:h-20 child:w-20">
                          <img
                            src="../images/youtube-icon.svg"
                            alt="youtube-icon"
                          />
                        </div>
                      </div>
                      <div className="z-0 m-6 mt-0 flex flex-col text-center xs:mt-0 md:m-5 md:mt-5 md:min-h-[calc(100%-theme(spacing.5)*2)] md:w-full md:max-w-1/2 md:rounded-lg md:bg-white md:p-8 md:text-left md:shadow md:dark:bg-off-black-desat xl:max-w-[60%] 2xl:m-9 2xl:mt-9 2xl:min-h-[calc(100%-theme(spacing.9)*2)]">
                        <div className="mb-2 md:mb-6 flex items-center justify-center md:justify-start">
                          <div className="relative mr-4 hidden md:block">
                            <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark child:h-16 child:w-16">
                              <img
                                src="../images/youtube-icon.svg"
                                alt="youtube-icon"
                              />
                            </div>
                          </div>
                          <p className="text-lg text-18-mobile">{t("Contact.Camper Secrets Revealed")}</p>
                        </div>
                        <h2 className="whitespace-pre-line break-words mb-2 text-xl xs:mb-6">
                          {t("Contact.Explanatory camper videos")}
                        </h2>
                        <div className="mb-6 md:mb-6">
                          <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                            {t("Contact.How to extend the awning, and what to do if the heater won't start? Explore valuable tips, smart tricks, and exciting highlights about our campers right here.")}
                          </div>
                        </div>
                        <div className="group/button block disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark p-3 pl-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated w-full md:mt-auto">
                          <div className="flex h-full items-center justify-between">
                            <span className="flex">{t("Contact.To the Videos")}</span>
                            <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                              <svg width="32" height="32" viewBox="0 0 32 32">
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                                    fill="var(--svg-color-secondary, #fff)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>

                </div>

              </div>


            }
          </div>

          <div className="lg:col-span-4">
            <div className="rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg sticky ml-8 mt-8 hidden p-5 pt-7 transition-all duration-300 lg:block top-6">
              <div className="flex max-h-[calc(100vh-80px)] flex-col">
                <div className="flex max-h-full flex-col items-center  text-center">
                  <h2 className="mb-1 whitespace-pre-line text-xl 2xl:text-2xl price-font fw-600">
                    <p>{voucher.title}</p>
                  </h2>
                  <p><span className="mb-3 text-md text-primary text-orange block text-18">{voucher.startprice}€ - {voucher.endprice}€</span></p>
                  <p className="mb-3" dangerouslySetInnerHTML={{ __html: voucher.description }}></p>
                  <div className="mt-2 w-full rounded bg-off-white p-5 text-left dark:bg-black mb-6">

                    <div className="mb-4 flex items-center last:mb-0">
                      <div className="inline-flex items-center justify-center rounded-full border border-primary bg-white dark:border-primary-dark dark:bg-off-white mr-3 p-0">
                        <div className="inline-block align-top child:h-6 child:w-6">
                          <svg width="32" height="32" viewBox="0 0 32 32">
                            <g fill="none" fillRule="evenodd">
                              <path
                                d="m21.906 9.488-8.547 9.56-3.45-3.096a1.757 1.757 0 0 0-2.563.209c-.64.792-.547 1.98.21 2.659l4.764 4.274c.735.66 1.833.602 2.496-.13l9.73-10.869a1.954 1.954 0 0 0-.069-2.665 1.758 1.758 0 0 0-2.571.058z"
                                fill="var(--svg-color-secondary, #163435)"
                              ></path>
                            </g>
                          </svg>
                        </div>
                      </div>
                      <p className="text-md">{t("Voucher.SSL-secured checkout")}</p>
                    </div>
                    <div className="mb-4 flex items-center last:mb-0">
                      <div className="inline-flex items-center justify-center rounded-full border border-primary bg-white dark:border-primary-dark dark:bg-off-white mr-3 p-0">
                        <div className="inline-block align-top child:h-6 child:w-6">
                          <svg width="32" height="32" viewBox="0 0 32 32">
                            <g fill="none" fillRule="evenodd">
                              <path
                                d="m21.906 9.488-8.547 9.56-3.45-3.096a1.757 1.757 0 0 0-2.563.209c-.64.792-.547 1.98.21 2.659l4.764 4.274c.735.66 1.833.602 2.496-.13l9.73-10.869a1.954 1.954 0 0 0-.069-2.665 1.758 1.758 0 0 0-2.571.058z"
                                fill="var(--svg-color-secondary, #163435)"
                              ></path>
                            </g>
                          </svg>
                        </div>
                      </div>
                      <p className="text-md">{t("Voucher.Quick delivery as PDF")}</p>
                    </div>
                    <div className="mb-4 flex items-center last:mb-0">
                      <div className="inline-flex items-center justify-center rounded-full border border-primary bg-white dark:border-primary-dark dark:bg-off-white mr-3 p-0">
                        <div className="inline-block align-top child:h-6 child:w-6">
                          <svg width="32" height="32" viewBox="0 0 32 32">
                            <g fill="none" fillRule="evenodd">
                              <path
                                d="m21.906 9.488-8.547 9.56-3.45-3.096a1.757 1.757 0 0 0-2.563.209c-.64.792-.547 1.98.21 2.659l4.764 4.274c.735.66 1.833.602 2.496-.13l9.73-10.869a1.954 1.954 0 0 0-.069-2.665 1.758 1.758 0 0 0-2.571.058z"
                                fill="var(--svg-color-secondary, #163435)"
                              ></path>
                            </g>
                          </svg>
                        </div>
                      </div>
                      <p className="text-md">{t("Voucher.Questions?")} <Link className="underline" to={"/contactus"}>{t("Voucher.Contact us!")}</Link></p>
                    </div>
                  </div>
                  <span className="group outline-none-focus w-full">
                    <div className="relative flex items-center px-2 h-12 border border-secondary border-opacity-disabled rounded block w-full h-12 group-focus:border-primary rounded cursor-default">
                      <div className=" items-center justify-between w-full opacity-disabled custom-pickup-return-none">
                        <select onChange={e => setCheckoutPrice(e.target.value)}>
                          <option>{t("Voucher.Select Price")}</option>
                          {price && price.map(fbb =>
                            <option key={fbb} value={fbb}>{fbb}</option>
                          )}
                        </select>

                      </div></div>

                  </span>

                </div>
              </div>
              {error ? <p style={{ color: "red" }} className="mb-2 flex w-full animate-wiggle items-center justify-between rounded bg-warning px-4 py-2 text-lg text-white">{error}</p> : ''}
              <Button onClick={(e) => handleCheckout(params.id)} className="mt-5 grid-cols1 inline-grid grid-rows-1 text-white bg-secondary hover:bg-primary pl-5 pr-2 w-full h-12 rounded bg-primary hover:bg-secondary transition-all"> <span className="relative flex col-start-1 row-start-1 items-center justify-between w-full h-full transition-opacity duration-300">
                <span className="transform transition-transform duration-300 translate-x-0">
                  <span>{t("Voucher.Continue to checkout1")}</span>
                </span>
                <svg className="w-7 h-7 cssvar-secondary-to-white pointer-events-none" width="32" height="32"><svg viewBox="0 0 32 32" fill="none" id="icon-chevron-right"><path fillRule="evenodd" clipRule="evenodd" d="M22.1256 15.9999L14.479 8.35332C14.009 7.88273 13.2445 7.88213 12.7739 8.35211L12.3532 8.77342C11.8809 9.24581 11.8833 10.0073 12.3544 10.4785L17.8759 15.9999L12.3544 21.5219C11.8833 21.9925 11.8809 22.7546 12.3532 23.2264L12.7739 23.6477C13.2445 24.1182 14.009 24.117 14.479 23.6465L22.1256 15.9999Z" fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"></path></svg></svg>
              </span></Button>

            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
export default VoucherDetails;

