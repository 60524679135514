import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import swal from "sweetalert";
import Userdashboard from '../../Pages/Userdashboard/Userdashboard'
import PaymentService from '../../Api/services/PaymentService';
import {t} from 'i18next';
const VideoTutorial = () => {
    const [Error, setError] = useState('');
    const [TutorialList, setTutorialList] = useState([]);
    var url = process.env.REACT_APP_LARAVEL_CAMPER_IMAGE_URL;
    // const [currentPage, setCurrentPage] = useState(1);
    // const recordsPage = 5;
    // const lastIndex = currentPage * recordsPage;
    // const firstIndex = lastIndex - recordsPage;
    // const records = TutorialList.slice(firstIndex, lastIndex);
    // const npage = Math.ceil(TutorialList.length / recordsPage)
    // const number = [...Array(npage + 1).keys()].slice(1)
    const navigate = useNavigate();
    // function prePage() {
    //     if (currentPage !== 1) {
    //         setCurrentPage(currentPage - 1)
    //     }
    // }
    // function changeCPage(id) {
    //     setCurrentPage(id);
    // }
    // function nextPage() {
    //     if (currentPage !== npage) {
    //         setCurrentPage(currentPage + 1)
    //     }
    // }


    const getTutorialList = async () => {

        try {
           
            let responseData = await PaymentService.tutorials().json()
            setTutorialList(responseData.data)
            console.log(responseData.data)
        } catch (error) {
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();

                setError(errorJson.message)
            }
        }
    }
    useEffect(() => {
        if (localStorage.getItem('usertoken')) {

            getTutorialList();

        }
        else {
            navigate('/');
        }

    }, [])
 
    return (
        <>
            <Userdashboard/>
            <div className='userdashboard-content mt-5'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                                        <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                                            <h2 className="heading mb-0 text-lg">{t("Login.Camper Tutorial")}</h2>
                                        </div>
                                        <div className="mt-12 mb-12 relative flex flex-col  text-gray-700  h-full w-full overflow-auto">
                                            <div className='grid grid-cols-1 gap-5 md:gap-y-16 gap-y-6 sm:grid-cols-2 lg:grid-cols-3'>
                                        {TutorialList.map((element, index)=>{
                                                return (
                                                    <>
                                                   <div className='bg-white px-5 md:py-10 py-5 rounded'>
                                                        <h4 className='break-words text-xl text-center fw-800 mb-5'>{element.title}</h4>

                                                        <div className="mb-5 mask-image relative h-0 w-full overflow-hidden rounded bg-off-white pt-[56.25%] shadow dark:bg-black dark:shadow-dark 2xs:rounded-lg">
                                                            <iframe
                                                            className="absolute top-0 left-0 aspect-video h-full w-full"
                                                            src={element.explanatory_video_url}
                                                            frameborder="0"
                                                            allowfullscreen
                                                            ></iframe>
                                                        </div>
                                                        {element.on_board_basic_equipment?<p className='download-document'>Guide<a href={url+element.on_board_basic_equipment} target="_blank"><img src="images/pdf-download.svg" alt="" /></a></p>:''}
                                                        {element.pick_up_infos?<p className='download-document'>{t("Login.Pick-Up Infos")}<a href={url+element.pick_up_infos} target="_blank"><img src="images/pdf-download.svg" alt="" /></a></p>:''}
                                                        {element.return_infos?<p className='download-document'>{t("Login.Return Infos")}<a href={url+element.return_infos} target="_blank"><img src="images/pdf-download.svg" alt="" /></a></p>:''}

                                                    </div>
                                                    </>
                                                )

                                                })}
                                                </div>
                                            {/* <table id="empoloyees-tblwrapper" className="table ItemsCheckboxSec dataTable no-footer mb-0 table-auto w-full min-w-max table-auto text-left">
                                                <thead>
                                                    <tr style={{ textAlign: 'center' }}>
                                                        <th  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                                            <strong>No.</strong>
                                                        </th>
                                                        <th  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                                            <strong>Payment Order Id</strong>
                                                        </th>
                                                        <th  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                                            <strong>Camper Name</strong>
                                                        </th>
                                                        <th  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                                            <strong>Billing User Name</strong>
                                                        </th>
                                                        <th  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                                            <strong>Billing Email</strong>
                                                        </th>
                                                        <th  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                                            <strong>Billing Amount</strong>
                                                        </th>
                                                        <th  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                                            <strong>Start Date</strong>
                                                        </th>
                                                        <th  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                                            <strong>End Date</strong>
                                                        </th>
                                                    
                                                        <th  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"><strong>Action</strong></th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {
                                                        records.map((element, index) => {

                                                            return (
                                                                <tr style={{ textAlign: 'center' }}>
                                                                    <td className='p-4 border-b border-blue-gray-50'>
                                                                        {index + 1}
                                                                    </td>
                                                                    <td  className='p-4 border-b border-blue-gray-50'> {element.payment_order_id}</td>
                                                                    <td  className='p-4 border-b border-blue-gray-50'>{element.camper_name}</td>
                                                                    <td  className='p-4 border-b border-blue-gray-50'>{element.billing_user_name}</td>
                                                                    <td  className='p-4 border-b border-blue-gray-50'>{element.billing_email}</td>
                                                                    <td  className='p-4 border-b border-blue-gray-50'>{element.billing_amount}</td>
                                                                    <td  className='p-4 border-b border-blue-gray-50'>{element.start_date}</td>
                                                                    <td  className='p-4 border-b border-blue-gray-50'>{element.end_date}</td>
                                                                
                                                                    <td  className='p-4 border-b border-blue-gray-50'>
                                                                        {/* <div className="d-flex">
                                                                        <Link
                                                                     className="btn btn-danger me-1"
                                                                     onClick={handleCancle}
                                                                     >{buttonText}</Link>
                                                                    <Link className="btn btn-primary me-1" onClick={handleRefund}> {refundText}</Link>
                                                                    <Link className="btn btn-primary me-1"> Document Upload</Link>
                                                                  </div> 
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })
                                                    }

                                                </tbody>

                                            </table> */}
                                            </div>
                                            {/* <div className="d-sm-flex text-center justify-content-between align-items-center">
                                                <div className='dataTables_info'>
                                                    Showing {lastIndex - recordsPage + 1} to{" "}
                                                    {TutorialList.length < lastIndex ? TutorialList.length : lastIndex}
                                                    {" "}of {TutorialList.length} entries
                                                </div>
                                                <div
                                                    className="dataTables_paginate paging_simple_numbers justify-content-center"
                                                    id="example2_paginate"
                                                >
                                                    <Link
                                                        className="paginate_button previous disabled"
                                                        to="#"
                                                        onClick={prePage}
                                                    >
                                                        <i className="fa-solid fa-angle-left" />
                                                    </Link>
                                                    <span className='p-2 border-b border-blue-gray-50 bg-white '>
                                                        {number.map((n, i) => (
                                                            <Link className={`paginate_button ${currentPage === n ? 'current' : ''} `} key={i}
                                                                onClick={() => changeCPage(n)}
                                                            >
                                                                {n}

                                                            </Link>
                                                        ))}
                                                    </span>
                                                    <Link
                                                        className="paginate_button next"
                                                        to="#"
                                                        onClick={nextPage}
                                                    >
                                                        <i className="fa-solid fa-angle-right" />
                                                    </Link>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-12">
                            
                        </div>
                    </div>
                    </div>
                </div>
                                                      

        </>
    );
};

export default VideoTutorial;
