import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
// import swal from "sweetalert";
import Userdashboard from '../../Pages/Userdashboard/Userdashboard'
import TripReviewService from '../../Api/services/TripReviewService';


const AddDocument = () => {
   
    const  params  = useParams()
    // console.log(params)
    const [Error, setError] = useState('');
    // const [trip, setTrip] = useState([]);
    const navigate = useNavigate();
    // useEffect(() => {
    //     var id = params.id;
    //     getTripID(id);
    //   }, []);
    // const getTripID = async (id) => {
    //     try {
    //       let tripList = await TripReviewService.show(id).json();
    //       console.log(tripList.data)
    //       setTrip(tripList.data);
    //     } catch (error) {
    //         if (error.name === 'HTTPError') {
    //             const errorJson = await error.response.json();
    //             setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
    //           }
    //     }
    //   };
    
    const onSubmit = async (event) => {
        event.preventDefault();
        try {
            var userData = {
                "order_id": params.id,
                "comment": event.target.comment.value,
               
            }
            console.log(userData)
            let responseData = await TripReviewService.store(userData).json()
            if (responseData.status === true) {
                console.log(responseData)
                navigate('/my_order');
              }
            // console.log(responseData.data)
        } catch (error) {
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();

                setError(errorJson.message)
            }
        }
    }
  
 
    return (
        <>
            <Userdashboard/>
            <div className='userdashboard-content mt-5'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                                        <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                                            <h2 className="heading mb-0 text-lg">Payment Detail List</h2>

                                        </div>
                                        <div className="mt-12 mb-12 relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md h-full w-full overflow-auto">
                                        <form onSubmit={onSubmit}>
                                        <div className="mb-10 ">
                                            
                                        <label className="flex flex-col" htmlFor="">Comment</label>
                                        <textarea rows="3" className="form-control" name='comment' required
                                        ></textarea>
                                        </div>
                                       
                                        <div>
                                            <button type="submit" className="btn btn-primary me-1">Submit</button>
                                        </div>
                                        </form>

                                        
                                          
                                        </div>
                                         
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                                                      

        </>
    );
};

export default AddDocument;
