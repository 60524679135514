
import clientAuth from "../clientAuth";
import client from "../client";
let lng = localStorage.getItem('lng') ? localStorage.getItem('lng'):'de';
export default {
 
 store: (userData) => client.post('personalDetail/store', { json: userData }),
  
 index: () =>client.get(`personalDetail/index?lng=`+lng),
 show:(id)=>client.get(`personalDetail/show/${id}?lng=`+lng),
};