import clientAuth from "../clientAuth";
import client from "../client";
let lng = localStorage.getItem('lng') ? localStorage.getItem('lng'):'de';
export default {

  index: () =>client.get(`blogs/index?lng=`+lng),
  location: () =>client.get(`location/index?lng=`+lng),
  period: () =>client.get(`period/index?lng=`+lng),
  deals: () =>client.get(`deals/index?lng=`+lng),
  
  show:(id)=>client.get(`blogs/show/${id}?lng=`+lng),
  store: (userData) => client.post(`newsletters/store?lng=`+lng, { json: userData }),
};