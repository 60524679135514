import React, { useState, useEffect,useRef } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import Explanatory from "../../Components/Explanatory/Explanatory";
import Campersrent from "../../Components/Campersrent/Campersrent";
import Campersrent_fillter from "../../Components/Campersrent/Campersrent_fillter";

import { useLocation, Link, useSearchParams, useNavigate, generatePath, createSearchParams } from "react-router-dom";
// import { Splide, SplideSlide } from '@splidejs/react-splide';
// import { PropTypes } from "prop-types"
import '@splidejs/react-splide/css';
import '@splidejs/react-splide/css/core';
import Select from 'react-select'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HomeService from "../../Api/services/HomeService";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from 'moment';
import { addMonths } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import de from 'date-fns/locale/de';
registerLocale("de",de);
const   Campers = () => {
  const { i18n, t } = useTranslation();
  const viewcampers = useRef(null);
  var imageUrl = process.env.REACT_APP_LARAVEL_BLOG_IMAGE_URL;
  var dealUrl = process.env.REACT_APP_LARAVEL_Deal_IMAGE_URL;
  const navigate = useNavigate();
  const [location, setLocation] = useState([]);
  const [startDate, setStartDate] = useState('');

  const [endDate, setEndDate] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams()
  const [skills, setSkills] = useState([]);
  const today = new Date();
  const minSelectableDate = new Date(today);
  minSelectableDate.setDate(today.getDate() + 1);

  const [Deal, setDeal] = useState([]);
  const variable = useLocation();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const pickerRef = useRef(null);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1200);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
      if (isMobile && pickerRef.current !== null) {
        pickerRef.current.input.readOnly = true;
      }
  }, [isMobile, pickerRef]);

  useEffect(() => {
    let dateRange = searchParams.get('date')
    
    getLocation();

    getDeals();
    if (dateRange === null) {


    }
    else {
      let startDate1 = dateRange.split("_");
      setStartDate(new Date(startDate1[0]));

      setEndDate(new Date(startDate1[1]))
    }

  }, []);

  
  const onChangedate = (date) => {

    

    const [start, end] = date;
    var i = 0;
    if (end !== null) {
      let DateObj = new Date(start);
      let months = DateObj.getMonth();
      var time_difference = end.getTime() - start.getTime();
      var days_difference = time_difference / (1000 * 60 * 60 * 24);
      if (months == 7) {
        if (days_difference < 3) {
          alert(t("Rent.The Minimum Rent Time in August is 3 nights"));
          i = 1;
          setStartDate('');
          setEndDate('');
          return true;
        }
      }else if (months == 5 || months == 6) {
        if (days_difference < 3) {
          alert(t("Rent.The Minimum Rent Time in June & July is 3 nights"));
          i = 1;
          setStartDate('');
          setEndDate('');
          return true;
        }
      }else{
        if (days_difference < 2) {
          alert(t("Rent.The Minimum Rent Time is 2 nights"));
          i = 1;
          setStartDate('');
          setEndDate('');
          return true;
        }
      }
    }
    if (i == 0) {
      setStartDate(start);
      setEndDate(end);

      if (date[1] !== null) {
        if (variable.search == '') {
          navigate(generatePath(`?date=${moment(startDate).format('YYYY-MM-DD') + '_' + moment(date[1]).format('YYYY-MM-DD')}`))
        } else if (variable.search !== '') {
          setSearchParams((searchParams) => {
            searchParams.set('date', `${moment(startDate).format('YYYY-MM-DD') + '_' + moment(date[1]).format('YYYY-MM-DD')}`);
            return searchParams;
          });
        } else {
          navigate(generatePath(variable.search + `&date=${moment(startDate).format('YYYY-MM-DD') + '_' + moment(date[1]).format('YYYY-MM-DD')}`))
        }
      }
    }
  }

  var threerow = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const params = { location: searchParams.get('location'), date: searchParams.get('date') };

  const getDeals = async () => {
    try {
      let dealsList = await HomeService.deals().json();
        
      var dealshome = dealsList.data.filter((element) => { return element.is_home_page === 1 })

      setDeal(dealshome)

    } catch (error) {
      console.log(error);
    }
  };
  const getLocation = async () => {

    try {
      let locationList = await HomeService.location().json();

      var getData = locationList.data.filter((element) => { return element.title === 'Berlin' })

      setSearchParams((searchParams) => {
        searchParams.set('location', `${getData[0].id}`);
        return searchParams;
      });
      setSkills(getData)
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Header />

      <div className="min-h-screen bg-off-white">
        <div ref={viewcampers} className="flex h-full w-full flex-col items-center justify-end pt-10-custom">
          <div className="w-full">
            <h1 className="text-center whitespace-pre-line break-words m-auto max-w-4xl px-8 text-xl md:text-2xl  drop-shadow-text dark: xs:text-2xl xl:text-3xl">
              {t("Rent.All camper models")}
            </h1>
          </div>
        </div>
        <div className="mx-5 md:mx-5 max-w-screen-xl xl-padded:mx-auto">
          <div className="pt-4 pb-0 mx-auto flex max-w-3xl flex-col items-center text-center">
            <p className="whitespace-pre-line text-2md font-normal md:text-lg-paragraph md:font-normal">{t("Rent.Each camper boasts a unique character")}<br/> {t("Rent.yet they all share one common trait")}<br/> {t("Rent.top- notch equipment, ready for your adventure!")}</p>
          </div>
          <div className="mt-10 mb-5 relative rent-calender m-auto">

            <span className="flex h-14 w-full items-center bg-white dark:bg-off-black-desat transition-all child-span:overflow-hidden child-span:text-ellipsis border focus:border-primary focus:outline-none justify-between border-grey hover:shadow dark:border-grey-dark dark:hover:shadow-dark rounded">
              <div className="inline-block align-top ml-3 mr-1 min-w-fit child:h-7 child:w-7 dark:cssvar-secondary-to-off-white">
                <svg width="32" height="32" viewBox="0 0 32 32" >
                  <g fill="none" fillRule="evenodd">
                    <path
                      d="M21.06 4.5c.474 0 .884.3 1.01.741l.028.124.01.127-.002 1.327h1.14c1.503 0 2.772 1.005 3.049 2.362l.03.179.019.182.006.183v13.87c0 1.476-1.178 2.7-2.715 2.882l-.194.017-.195.006H9.104c-1.569 0-2.882-1.094-3.079-2.54l-.019-.183L6 23.595V9.725c0-1.477 1.178-2.7 2.715-2.882l.194-.018.195-.005 1.139-.001V5.492c0-.473.347-.864.808-.966l.117-.02.121-.006c.473 0 .883.3 1.01.741l.027.124.01.127-.001 1.327h2.858V5.463c0-.452.316-.828.74-.934l.12-.022.122-.007a.98.98 0 0 1 .941.697l.029.117.012.149-.002 1.356h2.859V5.492c0-.473.348-.864.808-.966l.117-.02.122-.006zm3.197 7.015H8.092v11.989c0 .473.328.877.764.983l.122.021.126.007h14.142c.476 0 .878-.33.983-.764l.021-.122.007-.125V11.515zm-7.883 1.614 1.085 2.516c.031.071.097.12.174.129l2.75.28c.187.02.26.253.118.377l-2.072 1.791a.214.214 0 0 0-.07.21l.613 2.73a.215.215 0 0 1-.321.23l-2.365-1.446a.214.214 0 0 0-.222 0L13.7 21.393a.215.215 0 0 1-.32-.232l.611-2.729a.215.215 0 0 0-.07-.21l-2.071-1.79a.215.215 0 0 1 .118-.377l2.75-.281a.212.212 0 0 0 .174-.129l1.085-2.516a.217.217 0 0 1 .397 0z"
                      fill="var(--svg-color-secondary, #163435)"
                    ></path>
                  </g>
                </svg>
              </div>

              <span className="text-ellipsis whitespace-nowrap text-md custom-calender" >
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={onChangedate}
                  minDate={minSelectableDate}
                  dateFormat="dd/MM/yyyy"
                  locale={i18n.resolvedLanguage}
                  maxDate={addMonths(new Date(), 12)}
                  monthsShown={isMobile ? 1 : 2}
                  withPortal
                  placeholderText={t("Rent.Whenever")}
                  onFocus={e => e.target.blur()}
                  ref={pickerRef}
                  required
                  
                  
                />
              </span>



              <div className="inline-block align-top ml-2 mr-4 min-w-fit transition-all child:h-6 child:w-6 dark:cssvar-secondary-to-off-white">

                <svg width="32" height="32" viewBox="0 0 32 32">
                  <g fill="none" fillRule="evenodd">
                    <path
                      d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                      fill="var(--svg-color-secondary, #163435)"
                    ></path>

                  </g>
                </svg>
              </div>
            </span>
          </div>


          <div className="fixed left-0 z-sticky-collapsible flex w-full flex-col rounded rounded-t-none bg-white shadow backdrop-blur-sm transition-all duration-300 dark:bg-off-black-desat dark:shadow-dark pointer-events-none -mt-16 opacity-0 top-0 max-h-screen bg-white/90 dark:bg-off-black-desat/90">
            <div className="flex items-center justify-between p-5 sm:hidden">
              <span className="text-md">Choose period</span>
              <div className="flex items-center">
                <button className="rounded-full bg-primary shadow dark:bg-primary-dark">
                  <div className="inline-block align-top transition-all cssvar-secondary-to-white child:h-8 child:w-8 dark:cssvar-secondary-to-off-white">
                    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                      <g fill="none" fillRule="evenodd">
                        <path d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z" fill="var(--svg-color-secondary, #163435)"></path>
                      </g>
                    </svg>
                  </div>
                </button>
              </div>
            </div>
            <div className="transition-all duration-300 max-h-0 overflow-hidden sm:max-h-[100vh] sm:overflow-auto">
              <div className="p-5">
                <div className="mx-auto grid w-full max-w-3xl grid-cols-2 gap-4">
                  <div className="relative w-full col-span-2 xs:col-span-1 custom-select location no-border">
                    <span className="react-select-container">Berlin</span>

                  </div>
                  <div className="relative w-full col-span-2 xs:col-span-1">
                    <span className="flex w-full items-center bg-white dark:bg-off-black-desat transition-all child-span:overflow-hidden child-span:text-ellipsis border focus:border-primary focus:outline-none justify-between border-grey hover:shadow dark:border-grey-dark dark:hover:shadow-dark lg:text-2md h-[62px] rounded">
                      <div className="inline-block align-top cursor ml-3 mr-1 min-w-fit child:h-7 child:w-7 dark:cssvar-secondary-to-off-white">
                        <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                          <g fill="none" fillRule="evenodd">
                            <path d="M21.06 4.5c.474 0 .884.3 1.01.741l.028.124.01.127-.002 1.327h1.14c1.503 0 2.772 1.005 3.049 2.362l.03.179.019.182.006.183v13.87c0 1.476-1.178 2.7-2.715 2.882l-.194.017-.195.006H9.104c-1.569 0-2.882-1.094-3.079-2.54l-.019-.183L6 23.595V9.725c0-1.477 1.178-2.7 2.715-2.882l.194-.018.195-.005 1.139-.001V5.492c0-.473.347-.864.808-.966l.117-.02.121-.006c.473 0 .883.3 1.01.741l.027.124.01.127-.001 1.327h2.858V5.463c0-.452.316-.828.74-.934l.12-.022.122-.007a.98.98 0 0 1 .941.697l.029.117.012.149-.002 1.356h2.859V5.492c0-.473.348-.864.808-.966l.117-.02.122-.006zm3.197 7.015H8.092v11.989c0 .473.328.877.764.983l.122.021.126.007h14.142c.476 0 .878-.33.983-.764l.021-.122.007-.125V11.515zm-7.883 1.614 1.085 2.516c.031.071.097.12.174.129l2.75.28c.187.02.26.253.118.377l-2.072 1.791a.214.214 0 0 0-.07.21l.613 2.73a.215.215 0 0 1-.321.23l-2.365-1.446a.214.214 0 0 0-.222 0L13.7 21.393a.215.215 0 0 1-.32-.232l.611-2.729a.215.215 0 0 0-.07-.21l-2.071-1.79a.215.215 0 0 1 .118-.377l2.75-.281a.212.212 0 0 0 .174-.129l1.085-2.516a.217.217 0 0 1 .397 0z" fill="var(--svg-color-secondary, #163435)"></path>
                          </g>
                        </svg>
                      </div>
                      <span className="text-ellipsis whitespace-nowrap text-md custom-calender">
                        Whenever

                      </span>
                      <div className="inline-block align-top ml-2 mr-4 min-w-fit transition-all child:h-6 child:w-6 dark:cssvar-secondary-to-off-white">
                        <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                          <g fill="none" fillRule="evenodd">
                            <path d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z" fill="var(--svg-color-secondary, #163435)"></path>
                          </g>
                        </svg>
                      </div>

                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>



          {startDate && endDate ? (
            <div className="min-h-screen camper-sale-slider">
              { }
              <Campersrent_fillter data={params} />
            </div>
          ) : (
            <div className="min-h-screen camper-sale-slider">
              { }
              <Campersrent data={params} />
            </div>
          )}

          <Explanatory />

          <div className="mx-auto flex max-w-3xl flex-col items-center text-center">
            <p className="mb-4 sm-mb-2 whitespace-pre-line text-md">{t("Rent.Your ideal companion")}</p>
            <h2 className="whitespace-pre-line break-words text-xl md:text-2xl">{t("Rent.Our camper fleet")}</h2>

          </div>

          <div className="pt-8 sm-p-0">
            <div className="md:mx-5 max-w-screen-xl xl-padded:mx-auto pt-5 pb-8">
              <Slider className="camper-slider" {...threerow}>

                <Link onClick={() =>
                    window.scrollTo({
                      top: viewcampers.current.offsetTop - 118,
                      behavior: "smooth",
                    })
                  }>
                  <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent group flex h-full flex-col p-4 hover:shadow-elevated hover:dark:shadow-dark-elevated">
                    <div className="mask-image shrink-0 overflow-hidden rounded-sm 2xs:rounded-md">
                      <picture className="text-0 h-full w-full">
                        <img
                          className="inline-block max-w-full object-cover dark:brightness-90 aspect-square max-h-[270px] w-full group-hover:scale-110 transition-all duration-300"
                          src="images/picture camper van model.jpg"
                          alt="picture camper van model"
                        />
                      </picture>
                    </div>
                    <div className="flex h-full flex-col items-center justify-center px-4 text-center">
                      <p className="whitespace-pre-line text-lg mt-4">
                        {t("Rent.Rent a Campervan")}
                      </p>
                      <p className="mt-3 whitespace-pre-line line-clamp-5">
                      {t("home.Experience the freedom and ease with our compact campervans. Ideal for narrow streets  and spontaneous detours. Your perfect companions for flexible and effortless journeys into nature")}. 
                      </p>
                      <div className="mt-auto">
                        <div className="group/button disabled:opacity-50 border border-transparent text-md text-white dark:text-off-white button-primary-bg dark:bg-primary-dark hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated p-1 rounded-full mt-4 inline-block px-4">
                          <div className="flex h-full items-center justify-center">
                            <span className="flex">{t("sale.View Campers")}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>

                <Link onClick={() =>
                    window.scrollTo({
                      top: viewcampers.current.offsetTop - 118,
                      behavior: "smooth",
                    })
                  }>
                  <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent group flex h-full flex-col p-4 hover:shadow-elevated hover:dark:shadow-dark-elevated">
                    <div className="mask-image shrink-0 overflow-hidden rounded-sm 2xs:rounded-md">
                      <picture className="text-0 h-full w-full">
                        <img
                          className="inline-block max-w-full object-cover dark:brightness-90 aspect-square max-h-[270px] w-full group-hover:scale-110 transition-all duration-300"
                          src="images/picture travel van model-min.jpg"
                          alt="picture travel van model-min"
                        />
                      </picture>
                    </div>
                    <div className="flex h-full flex-col items-center justify-center px-4 text-center">
                      <p className="whitespace-pre-line text-lg mt-4">
                        {t("Rent.Van Rental")}
                      </p>
                      <p className="mt-3 whitespace-pre-line line-clamp-5">
                      {t("home.Enjoy independence in our vans with standing height, a fully equipped kitchen, and a private bathroom – your mobile home for the journey")}.
                      </p>
                      <div className="mt-auto">
                        <div className="group/button disabled:opacity-50 border border-transparent text-md text-white dark:text-off-white button-primary-bg dark:bg-primary-dark hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated p-1 rounded-full mt-4 inline-block px-4">
                          <div className="flex h-full items-center justify-center">
                            <span className="flex">{t("sale.View Campers")}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>

                <Link onClick={() =>
                    window.scrollTo({
                      top: viewcampers.current.offsetTop - 118,
                      behavior: "smooth",
                    })
                  }>
                  <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent group flex h-full flex-col p-4 hover:shadow-elevated hover:dark:shadow-dark-elevated">
                    <div className="mask-image shrink-0 overflow-hidden rounded-sm 2xs:rounded-md">
                      <picture className="text-0 h-full w-full">
                        <img
                          className="inline-block max-w-full object-cover dark:brightness-90 aspect-square max-h-[270px] w-full group-hover:scale-110 transition-all duration-300"
                          src="images/davidferk_mooveo.png"
                          alt="davidferk_portugal_t6_beach_sommer"
                        />
                      </picture>
                    </div>
                    <div className="flex h-full flex-col items-center justify-center px-4 text-center">
                      <p className="whitespace-pre-line text-lg mt-4">
                        {t("Rent.Motorhome Rental")}
                      </p>
                      <p className="mt-3 whitespace-pre-line line-clamp-5">
                        {t("Rent.Our motorhomes – the perfect choice for comfort and space")} {t("Rent.Enjoy ample room for your loved ones and all your luggage.")}
                      </p>
                      <div className="mt-auto">
                        <div className="group/button disabled:opacity-50 border border-transparent text-md text-white dark:text-off-white button-primary-bg dark:bg-primary-dark hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated p-1 rounded-full mt-4 inline-block px-4">
                          <div className="flex h-full items-center justify-center">
                            <span className="flex">{t("sale.View Campers")}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>

                <Link onClick={() =>
                    window.scrollTo({
                      top: viewcampers.current.offsetTop - 118,
                      behavior: "smooth",
                    })
                  }>
                  <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent group flex h-full flex-col p-4 hover:shadow-elevated hover:dark:shadow-dark-elevated">
                    <div className="mask-image shrink-0 overflow-hidden rounded-sm 2xs:rounded-md">
                      <picture className="text-0 h-full w-full">
                        <img
                          className="inline-block max-w-full object-cover dark:brightness-90 aspect-square max-h-[270px] w-full group-hover:scale-110 transition-all duration-300"
                          src="images/picture caravan model-min.jpg"
                          alt="picture caravan model-min"
                        />
                      </picture>
                    </div>
                    <div className="flex h-full flex-col items-center justify-center px-4 text-center">
                      <p className="whitespace-pre-line text-lg mt-4">
                        {t("Rent.Caravan Rental")}
                      </p>
                      <p className="mt-3 whitespace-pre-line line-clamp-5">
                        {t("Rent.Enjoy the comfort of a large motorhome while having your own car with you at all times.")} {t("Rent.Ideal for a relaxed vacation at a location of your choice.")}
                      </p>
                      <div className="mt-auto">
                        <div className="group/button disabled:opacity-50 border border-transparent text-md text-white dark:text-off-white button-primary-bg dark:bg-primary-dark hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated p-1 rounded-full mt-4 inline-block px-4">
                          <div className="flex h-full items-center justify-center">
                            <span className="flex">{t("sale.View Campers")}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>

              </Slider>

            </div>
          </div>

          {
            Deal.map((element) => {
              return (
                <div className="pt-8 xl:pb-8 2xl:pb-16 mx-0 max-w-screen-xl xl-padded:mx-auto pt-5 pb-8">
                  <div className="overflow-hidden rounded-lg bg-white shadow dark:bg-off-black-desat dark:shadow-dark group/parent mask-image relative p-0 md:flex md:min-h-[400px]  md:bg-transparent dark:md:bg-transparent justify-end">
                    <Link
                      to={"/offers"}
                      className="w-full md:flex justify-end"
                      href=""
                    >
                      <div className="md:hidden">
                        <div className="overflow-hidden">
                          <div className="-ml-[5%] w-[110%] overflow-hidden rounded-b-[60%20%] px-[5%]">
                            <div className="relative">
                              <picture className="text-0">
                                {/* <img
                                  src="images/deals-mobile.jpg"
                                  alt=""
                                /> */}
                                <img src={element.mobile_image ? dealUrl + element.mobile_image : ''} alt="" className="" />

                              </picture>
                            </div>
                          </div>
                        </div>
                      </div>
                      <picture className="text-0 absolute top-0 left-0 z-background hidden h-full w-full transition-all duration-300 group-hover/parent:scale-105 md:block hidden md:group-hover/parent:scale-[103%]">
                        {/* <img src="images/deals.png" alt="" className='h-full' /> */}
                        <img src={element.tablet_image ? dealUrl + element.tablet_image : ''} alt="" className='h-full inline-block max-w-full object-cover dark:brightness-90 h-full w-full' />
                      </picture>

                      <picture className="text-0 absolute top-0 left-0 z-background hidden h-full w-full transition-all duration-300 group-hover/parent:scale-105  xl:block hidden md:group-hover/parent:scale-[103%]">
                        {/* <img src="images/deals.png" alt="" className='h-full' /> */}
                        <img src={element.feature_image ? dealUrl + element.feature_image : ''} alt="" className='h-full inline-block max-w-full object-cover dark:brightness-90 h-full w-full' />
                      </picture>
                      <div className="relative -mt-10 mb-4 flex justify-center xs:mb-4 md:hidden">
                        <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark child:h-20 child:w-20">
                          {/* <img
                            src="images/percentage-icon.svg"
                            alt="percentage-icon"
                          /> */}
                          <img className="opacity-sm-0" src={element.logo ? dealUrl + element.logo : ''} alt="percentage-icon" />

                        </div>
                      </div>
                      <div className="z-0 m-6 mt-0 flex flex-col text-center xs:mt-0 md:m-5 md:mt-5 md:min-h-[calc(100%-theme(spacing.5)*2)] md:w-full md:max-w-1/2 md:rounded-lg md:bg-white md:p-8 md:text-left md:shadow md:dark:bg-off-black-desat xl:max-w-[45%] 2xl:m-9 2xl:mt-9 2xl:min-h-[calc(100%-theme(spacing.9)*2)]">
                        <div className="mb-2 mb:md-6 flex items-center justify-center md:justify-start">
                          <div className="relative mr-4 hidden md:block">
                            <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark child:h-16 child:w-16">
                              {/* <img
                                src="images/percentage-icon.svg"
                                alt="percentage-icon"
                              /> */}
                              <img src={element.logo ? dealUrl + element.logo : ''} alt="percentage-icon" />
                            </div>
                          </div>
                          <p className="small-lg text-lg price-font">
                            {/* Get 60€ discount */}
                            {element.title ? element.title : ' '}
                          </p>
                        </div>
                        <h3 className="whitespace-pre-line break-words mb-4 text-xl xs:mb-6">
                          {element.sub_title ? element.sub_title : ''}
                        </h3>
                        <div className="mb-4 md:mb-6">
                          <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal price-font image-box-text">
                          {element.description ? element.description : ''}
                            {/* Camping in autumn is always a good idea. With our autumn
                            discount you save 60€ on your booking. */}
                          </div>
                          <div className="hidden md:visible min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                            {/* In October, we also have especially favorable periods to
                            offer! Information about exact periods and models can be
                            found on our offers page. */}
                          </div>
                        </div>
                        <div className="group/button block disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white button-primary-bg dark:bg-primary-dark p-3 pl-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated w-full md:mt-auto">
                          <div className="flex h-full items-center justify-between">
                            <span className="flex">
                              {/* Check out autumn deals */}
                              {element.button_text ? element.button_text : ''}
                            </span>
                            <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                              <svg width="32" height="32" viewBox="0 0 32 32">
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                                    fill="var(--svg-color-secondary, #fff)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              )
            })
          }



          <div className="pb-8 2xl:pb-16 question-answer">
            <div className="overflow-hidden rounded bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg mx-auto flex max-w-3xl flex-col items-center md:p-8 2xl:p-16">
              <div className="inline-flex items-center justify-center rounded-full primary-borber border bg-white p-1 dark:bg-off-white mb-6 border-info dark:border-info">
                <div className="inline-block align-top child:h-8 child:w-8 2xl:child:h-10 2xl:child:w-10">
                  <svg
                    width="32px"
                    height="32px"
                    viewBox="0 0 32 32"
                    version="1.1"
                  >
                    <title>icon/actions_info</title>
                    <g
                      id="icon/actions_info"
                      stroke="none"
                      strokeWidth="1"
                      fill="EE7214"
                      fillRule="evenodd"
                    >
                      <path
                        d="M15.0627781,13.2833484 C14.2292216,13.2833484 13.4095577,13.2833484 12.5843367,13.2833484 C12.4040026,13.2849033 12.2246588,13.3085077 12.0508606,13.3535621 C11.8387141,13.3950229 11.676741,13.5559636 11.6451964,13.7566407 C11.5865132,13.9746677 11.7037133,14.2006319 11.9230486,14.2923451 C12.190053,14.4092135 12.476874,14.4813856 12.7704977,14.5055866 C13.5429267,14.5393932 13.8541211,14.8566551 13.7374232,15.6004 C13.5206985,16.9916712 13.2289538,18.3725403 12.9788868,19.7612109 C12.809397,20.6973934 12.6848928,21.2550492 12.5843367,22.2403035 C12.4530963,23.5262052 13.2109841,24.7401952 14.1368811,25.0131061 C15.0627781,25.286017 15.724825,25.286017 16.3214484,25.2794864 C17.5999796,25.2780159 18.8212793,24.7831633 19.6973522,23.9116198 C19.8564682,23.7743898 19.9759403,23.6017433 20.0446674,23.409722 C20.0782267,23.2419815 20.0359721,23.0687264 19.9279695,22.9312287 C19.7974575,22.8547704 19.6367149,22.8384803 19.4917416,22.8870201 C19.2639028,22.9572338 19.0610707,23.1028622 18.838789,23.1938799 C18.5790019,23.3073326 18.3070186,23.3945099 18.0274607,23.4539306 C17.8020089,23.5057029 17.5636393,23.4622801 17.3753928,23.335146 C17.1871463,23.2080119 17.0679843,23.0099721 17.0494211,22.7934019 C17.0026623,22.551429 16.9942363,22.3043299 17.0244144,22.0600589 C17.1188841,21.4567414 17.2522531,20.8586248 17.3689511,20.2605082 C17.6857025,18.6291236 18.0070849,16.9968722 18.3330981,15.3637539 C18.4220108,14.9164667 18.5720509,14.4769811 18.4720242,14.0062893 C18.4564927,13.79721 18.3517318,13.6027725 18.1813424,13.4667803 C18.010953,13.3307881 17.7892668,13.2646798 17.5662261,13.2833484 C16.7326696,13.2833484 15.8963346,13.2833484 15.0627781,13.2833484 Z"
                        id="Path"
                        fill="var(--svg-color-secondary, #EE7214)"
                      ></path>
                      <path
                        d="M16.5742939,6.28268183 C15.5498224,6.23775316 14.6000794,6.81738803 14.1716262,7.74904688 C13.743173,8.68070573 13.9212618,9.77901022 14.6221567,10.5275466 C15.3230516,11.276083 16.4072909,11.52591 17.3650741,11.159561 C18.3228574,10.793212 18.9636082,9.88358197 18.9860507,8.85837141 C19.0250803,7.48444098 17.9479291,6.33655519 16.5742939,6.28823887 L16.5742939,6.28268183 Z"
                        id="Path"
                        fill="var(--svg-color-secondary, #EE7214)"
                      ></path>
                    </g>
                  </svg>
                </div>
              </div>
              <p className="whitespace-pre-line text-center text-lg md:text-xl">{t("aboutus.Questions? We have answers!")}</p>
              <Link to={"/contactus"} className="w-100 group/button disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark p-3 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated pl-3 py-2 inline-block mt-4" href="https://cms.camperboys.com/pdf-files/equipment_vw_t6_1_california_ocean_editionen-1.pdf">
                <div className="flex h-full items-center justify-between">
                  <div className="inline-block align-top mr-2 rounded-full bg-white p-1 child:h-7 child:w-7 dark:bg-off-white">
                    <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
                      <title>icon/actions_question</title>
                      <g id="icon/actions_question" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <path d="M16.5609275,17.3580413 L16.3692127,19.2467266 C15.73983,19.3562778 14.562691,19.3562778 13.8779956,19.3015022 C13.6320422,19.027624 13.6862809,15.1407023 13.6862809,15.1407023 C16.2596615,15.1407023 18.2305102,14.2369044 18.2305102,12.1838923 C18.2305102,11.1442293 17.4915762,9.66582429 15.3837884,9.66582429 C13.4951032,9.66582429 12.1536372,10.6512486 12.1536372,10.6512486 C12.1536372,10.6512486 10.9222596,8.70778776 11.003886,8.05048019 C11.6338057,7.33947102 13.3033885,6.6 15.8767691,6.6 C19.0242198,6.6 21.6523761,8.43390961 21.6523761,11.8831633 C21.6523761,15.1680901 19.2707101,17.0019997 16.5609275,17.3580413 M16.5732788,25.0115932 C15.8048089,25.7891924 14.5471175,25.7972476 13.7695183,25.0287777 C12.9913822,24.2608449 12.9833269,23.0031534 13.7517968,22.2250173 C14.5197297,21.4468811 15.7779581,21.4393629 16.5555573,22.2072957 C17.3336935,22.9757656 17.3412117,24.2334571 16.5732788,25.0115932" id="Fill-1" fill="var(--svg-color-secondary, #163435)"></path>
                      </g>
                    </svg>
                  </div>
                  <span className="flex">
                    {t("aboutus.To the helpcenter")}
                  </span>
                  <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                    <svg width="32" height="32" viewBox="0 0 32 32">
                      <g fill="none" fillRule="evenodd">
                        <path d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z" fill="var(--svg-color-secondary, #163435)"></path>
                      </g>
                    </svg>
                  </div>
                </div>
              </Link>
            </div>
          </div>

        </div>
      </div>

      <Footer />
    </>
  );
};

export default Campers;
