import React, { useEffect, useState, useRef } from "react";
import {
  Link,
  useNavigate,
  useSearchParams,
  generatePath,
  useParams,
  useLocation,
} from "react-router-dom";
import CamperService from "../../Api/services/CamperService";
import HomeService from "../../Api/services/HomeService";
import Select from "react-select";
import DatePicker, { registerLocale } from "react-datepicker";
import moment from "moment";
import { addMonths, getDate, differenceInDays, add } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "react-bootstrap";
import { NumericFormat } from "react-number-format";
import { useTranslation } from "react-i18next";
import de from 'date-fns/locale/de';
registerLocale("de", de);
const RentConfiguration = () => {
  const { i18n, t } = useTranslation();
  var imageUrl = process.env.REACT_APP_LARAVEL_CAMPER_IMAGE_URL;
  var packageimageurl = process.env.REACT_APP_LARAVEL_IMAGE_URL
  const params = useParams();
  const variable = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [count, setCount] = useState(0);
  const [handlecounter, sethandlecounter] = useState(false);
  const [handlereturncounter, sethandlereturncounter] = useState(false);
  const [ExcludedateList, setExcludedateList] = useState([]);

  const [error, setError] = useState("");
  const [discountprice, setdiscountprice] = useState(0);
  const [camper, setCamper] = useState([]);
  const [Inventory, setInventory] = useState([]);

  const [selectPackages, setSelectPackages] = useState([]);
  const [TotalPackagevalue, setTotalPackagevalue] = useState(0);
  const [optionSelected, setSelected] = useState();
  //cancelpolice
  const [CanceloptionSelected, setCancelSelected] = useState();
  const [CancellationSelected, setCancellationSelected] = useState([]);
  const [TotalCancelvalue, setTotalCelvalue] = useState(0);
  //service

  const [ServiceSelected, setServiceSelected] = useState([]);
  const [ServiceSelectedFix, setServiceSelectedFix] = useState([]);
  const [TotalServicevalue, setTotalServicevalue] = useState(0);
  const [TotalServicevalueFix, setTotalServicevalueFix] = useState(0);
  // Accessories
  const [AccessoriesSelectedFix, setAccessoriesSelectedFix] = useState([]);
  const [AccessoriesSelected, setAccessoriesSelected] = useState([]);
  const [TotalAccessoriesvalue, setTotalAccessoriesvalue] = useState(0);
  const [TotalAccessoriesvalueFix, setTotalAccessoriesvalueFix] = useState(0);
  //
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState(null);
  const [skills, setSkills] = useState([]);
  const [serviceFee, setServiceFee] = useState("");
  const [pickup, setPickup] = useState("");
  const [returntime, setReturntime] = useState("");

  const [policies, setPolicies] = useState([]);
  // Get the current date
  const today = new Date();
  // Set the minimum selectable date to tomorrow
  const minSelectableDate = new Date(today);
  minSelectableDate.setDate(today.getDate() + 1);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const pickerRef = useRef(null);
  const [totalAmount, setTotalAmount] = useState(0);

  //additional code for camper price by KP
  const [CamperPriceTotal, setCaperPriceTotal] = useState(0);
  const [LastDayPrice, setLastDayPrice] = useState(0);
  const handlePopstate = () => {

    navigate(-1)

  };

  useEffect(() => {


    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);


    };
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1200);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);


  }, []);



  useEffect(() => {
    if (isMobile && pickerRef.current !== null) {
      pickerRef.current.input.readOnly = true;
    }
  }, [isMobile, pickerRef]);

  useEffect(() => {
    var header = document.getElementById("boxesWrp");
    var btns = header.getElementsByClassName("rotateBtn");
    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", function () {
        var current = document.getElementsByClassName("active");
        if (current.length > 0) {
          current[0].className = current[0].className.replace(" active", "");
        }
        this.parentElement.className += " active";
      });
    }
  });

  const getCamperByID = async (id) => {
    try {
      let getCamper = await CamperService.show(id).json();

      setCamper(getCamper.data);
      let service_fee = getCamper.data.price_additional[0].service_fee;
      // getCamper.data.pa
      setServiceFee(service_fee);
      addChecked(
        getCamper.data.packages[0].id,
        getCamper.data.packages[0].title,
        getCamper.data.packages[0].price,
        "carefree"
      );
      var newarraay = [];
      newarraay.push({
        id: getCamper.data.packages[0].id,
        title: getCamper.data.packages[0].title,
        price: getCamper.data.packages[0].price,
      });

      setSelectPackages(newarraay);


      let dateRange = searchParams.get("date");

      if (dateRange) {
        let startDate1 = dateRange.split("_");
        setStartDate(new Date(startDate1[0]));
        setEndDate(new Date(startDate1[1]));
        let result = 0;
        result = differenceInDays(
          new Date(startDate1[1]),
          new Date(startDate1[0])
        );
        // if (searchParams.get("return") > 11 && searchParams.get("pickup") < 14) {

        //   setCount(1 + result);
        // }
        // else if (searchParams.get("pickup") < 14) {

        //   setCount(0.5 + result);
        // }
        // else if (searchParams.get("return") > 11) {

        //   setCount(0.5 + result);
        // }
        // else {
        //   setCount(result);
        // }
        // if (
        //   searchParams.get("pickup") < 14 ||
        //   searchParams.get("return") > 11
        // ) {
        //   setCount(0.5 + result);
        // } else {
        //   setCount(result);
        // }
        /* check here if the start date and end date is in between our date */
        let p_start_date = new moment(getCamper.data.price_managment.start_date).format("X");

        let p_end_date = new moment(getCamper.data.price_managment.end_date).format("X");
        let nowcheck = new moment(startDate1[0]);
        let endcheck = new moment(startDate1[1]);
        let totalDaysCalculation = 0;
        let lastDay = 0;
        var getprice =
          getCamper.data.camper_price &&
          getCamper.data.camper_price.map((camper_price) => {
            //return camper_price.price
            return camper_price.price;
          });
        while (nowcheck <= endcheck) {

          let cktime = nowcheck.format("X");
          var finalprice = getprice;
          var totalp = 0;

          finalprice = getCamper.data.price_managment.map((element) => {

            if (cktime >= moment(element.start_date).format("X") && cktime <= moment(element.end_date).format("X")) {
              return parseFloat((getprice * element.percentage) / 100 + parseFloat(getprice));

            }

          }

          )
          for (var i = 0; i < finalprice.length; i++) {
            if (finalprice[i])
              totalp = finalprice[i];
          }

          if (totalp == 0)
            totalp = parseFloat(getprice);


          var newprice = ''
          if (finalprice === undefined) {

            lastDay = Math.round(totalp)
          }
          else {
            newprice = finalprice
            newprice = newprice.filter(Boolean);

            lastDay = Math.round(totalp)

          }
          totalDaysCalculation += lastDay

          nowcheck = moment(nowcheck).add(1, 'days');
        };

        setCaperPriceTotal((totalDaysCalculation - lastDay));
        // while (nowcheck <= endcheck) {
        //   //alldates.push(now.format("YYYY-MM-DD"));
        //   let cktime = nowcheck.format("X");

        //   if (cktime >= p_start_date && cktime <= p_end_date)
        //     lastDay = parseFloat(
        //       (getprice * getCamper.data.price_managment.percentage) / 100 +
        //       parseFloat(getprice)
        //     );
        //   else
        //     lastDay = parseFloat(getprice);

        //   totalDaysCalculation += lastDay;
        //   nowcheck = moment(nowcheck).add(1, 'days'); // you are updating the same object again and again, so here I am creating new object
        // };

        // setCaperPriceTotal((totalDaysCalculation - lastDay));
        setLastDayPrice(lastDay);
        if (searchParams.get("return") > 11 && searchParams.get("pickup") < 14) {
          sethandlecounter(true);
          sethandlereturncounter(true);
          setCaperPriceTotal(totalDaysCalculation - lastDay + (lastDay * 1))
          setCount(1 + result);
        }
        else if (searchParams.get("pickup") < 14) {
          sethandlecounter(true);

          setCaperPriceTotal(totalDaysCalculation - lastDay + (lastDay * 0.5))

          setCount(0.5 + result);
        }
        else if (searchParams.get("return") > 11) {
          sethandlereturncounter(true);
          setCaperPriceTotal(totalDaysCalculation - lastDay + (lastDay * 0.5))
          setCount(0.5 + result);
        }
        else {
          // alert("else call")
          // setCaperPriceTotal(totalDaysCalculation - lastDay - (lastDay * 0.5))
          setCount(result);
        }
        // var newpricecel = totalDaysCalculation - lastDay
        var newpricecel = ""
        if (searchParams.get("return") > 11 && searchParams.get("pickup") < 14) {

          newpricecel = totalDaysCalculation - lastDay * 1
        }
        else if (searchParams.get("pickup") < 14) {

          newpricecel = totalDaysCalculation - lastDay * 0.5
        }
        else if (searchParams.get("return") > 11) {

          newpricecel = totalDaysCalculation - lastDay * 0.5
        }
        else {
          newpricecel = totalDaysCalculation - lastDay
        }

        var newresult = result + 1;

        if (result >= 8 && result < 15) {

          var newcount =
            getCamper.data.camper_price &&
            getCamper.data.camper_price.map((camper_price) => {
              return camper_price.discount_price === 0
                ? camper_price.price
                : camper_price.price - camper_price.discount_price;
            });
          // setdiscountprice((result * newcount * 5) / 100);
          console.log(newpricecel, "onchanges")
          setdiscountprice((newpricecel * 5) / 100);
        } else if (result >= 15 && result < 22) {
          var newcount =
            getCamper.data.camper_price &&
            getCamper.data.camper_price.map((camper_price) =>
              camper_price.discount_price === 0
                ? (result * camper_price.price * 10) / 100
                : result * camper_price.price -
                camper_price.discount_price -
                result * camper_price.price -
                (camper_price.discount_price * 10) / 100
            );
          setdiscountprice((newpricecel * 10) / 100);
          // setdiscountprice(newcount);
        } else if (result >= 22) {
          var newcount =
            getCamper.data.camper_price &&
            getCamper.data.camper_price.map((camper_price) =>
              camper_price.discount_price === 0
                ? (result * camper_price.price * 15) / 100
                : newresult * camper_price.price -
                camper_price.discount_price -
                result * camper_price.price -
                (camper_price.discount_price * 15) / 100
            );
          setdiscountprice((newpricecel * 15) / 100);
          // setdiscountprice(newcount);
        } else {
          setdiscountprice(0);
        }




      }
    } catch (error) {
      if (error.name === "HTTPError") {
        const errorJson = await error.response.json();
        setError(
          errorJson.message.substr(0, errorJson.message.lastIndexOf("."))
        );
      }
    }
  };

  const getExcludedate = async (id) => {
    try {
      let Excludedate = await CamperService.excludedates(id).json();
      if (Excludedate.message === "Inventory not avalable!!!") {
        const daysRange = 365;

        var newinverntory = [];
        for (let i = 0; i < daysRange; i++) {
          newinverntory.push(add(new Date(), { days: i }));
        }

        //setExcludedateListStart(newinverntory)
        setExcludedateList(newinverntory);
      } else if (Excludedate.message === "Excluded dates listing") {
        setExcludedateList(Excludedate.exclude_date);
      } else {
        console.log("");
      }
    } catch (error) {
      if (error.name === "HTTPError") {
        const errorJson = await error.response.json();
        setError(
          errorJson.message.substr(0, errorJson.message.lastIndexOf("."))
        );
      }
    }
  };
  const getAccessoriesInventory = async (accessoiresId) => {
    try {
      let dateRange = searchParams.get("date");
      if (dateRange) {
        let startDate1 = dateRange.split("_");
        var userData = {
          id: accessoiresId,
          start_date: moment(startDate1[0]).format("YYYY-MM-DD"),
          end_date: moment(startDate1[1]).format("YYYY-MM-DD")
        }

        let getcheckaccessoires = await CamperService.checkaccessoires(userData).json();
        setInventory(getcheckaccessoires.data)

      }
      else {
        var userData = {
          id: accessoiresId,
          start_date: "",
          end_date: ""
        }

        let getcheckaccessoires = await CamperService.checkaccessoires(userData).json();
        setInventory(getcheckaccessoires.data)
      }

    }
    catch (error) {
      if (error.name === "HTTPError") {
        const errorJson = await error.response.json();
        setError(
          errorJson.message.substr(0, errorJson.message.lastIndexOf("."))
        );
      }

    }
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    var id = params.id;

    getCamperByID(id);
    getLocation();
    getPolicies();
    getExcludedate(id);
    getAccessoriesInventory(id);
    let pickrange = searchParams.get("pickup");

    let returnrange = searchParams.get("return");
    if (pickrange) {
      setPickup(pickrange);
    }
    if (returnrange) {
      setReturntime(returnrange);
    }
  }, []);

  const addChecked = (itemId, itemTitle, itemPrice, selection) => {
    if (selection === "carefree") {
      if (optionSelected !== itemId) {
        setSelected(itemId);
        var newarraay = [];
        newarraay.push({ id: itemId, title: itemTitle, price: itemPrice });

        setSelectPackages(newarraay);

        setTotalPackagevalue(newarraay[0].price);
      } /*else {
        setSelected("");
        const toBeRemove = selectPackages.find(
          (item) => item.title === itemTitle
        );
        if (toBeRemove) {
          selectPackages.splice(selectPackages.indexOf(toBeRemove), 1);
          setSelectPackages([...selectPackages]);
          setTotalPackagevalue(0);
        }
      }*/
    } else {
      setSelectPackages([
        ...selectPackages,
        { id: itemId, title: itemTitle, price: itemPrice },
      ]);
    }
  };
  const removeChecked = (itemId, itemTitle, selection) => {
    console.log("removed -Checked");
    const toBeRemove = selectPackages.find((item) => item.title === itemTitle);

    if (toBeRemove) {
      selectPackages.splice(selectPackages.indexOf(toBeRemove), 1);
      setSelectPackages([...selectPackages]);
    }
  };

  const addcancellation = (itemId, itemTitle, itemPrice, selection) => {
    if (selection === "cancellation") {
      if (CanceloptionSelected !== itemId) {
        setCancelSelected(itemId);
        var newarraay = [];
        newarraay.push({ id: itemId, title: itemTitle, price: itemPrice });

        setCancellationSelected(newarraay);
        setTotalCelvalue(newarraay[0].price);
      } /*else {
        setCancelSelected("");
        const toBeRemove = CancellationSelected.find(
          (item) => item.title === itemTitle
        );
        if (toBeRemove) {
          CancellationSelected.splice(
            CancellationSelected.indexOf(toBeRemove),
            1
          );
          setCancellationSelected([...CancellationSelected]);
          setTotalCelvalue(0);
        }
      }*/
    } else {
      setCancellationSelected([
        ...CancellationSelected,
        { id: itemId, title: itemTitle, price: itemPrice },
      ]);
    }
  };

  const removecancellation = (itemId, itemTitle, selection) => {
    const toBeRemove = CancellationSelected.find(
      (item) => item.title === itemTitle
    );

    if (toBeRemove) {
      CancellationSelected.splice(CancellationSelected.indexOf(toBeRemove), 1);
      setCancellationSelected([...CancellationSelected]);
    }
  };

  const addservice = (itemId, itemTitle, itemPrice, selection) => {
    if (selection === "service") {
      var newarraay = [];
      newarraay.push({ id: itemId, title: itemTitle, price: itemPrice });
      setServiceSelected((current) => [...current, ...newarraay]);

      setTotalServicevalue(TotalServicevalue + newarraay[0].price);
    } else if (selection === "servicefix") {
      var newarraay = [];
      newarraay.push({ id: itemId, title: itemTitle, price: itemPrice });
      setServiceSelectedFix((current) => [...current, ...newarraay]);
      setTotalServicevalueFix(TotalServicevalueFix + newarraay[0].price);
    } else {
      setServiceSelected([
        ...ServiceSelected,
        { id: itemId, title: itemTitle, price: itemPrice },
      ]);
      setTotalServicevalue(0);
    }
  };

  const removeservice = (itemId, itemTitle, itemPrice, selection) => {
    if (selection === "service") {
      const toBeRemove = ServiceSelected.find(
        (item) => item.title === itemTitle
      );
      if (toBeRemove) {
        ServiceSelected.splice(ServiceSelected.indexOf(toBeRemove), 1);
        setServiceSelected([...ServiceSelected]);
        if (ServiceSelected === null) {
          setTotalServicevalue(0);
        } else {
          setTotalServicevalue(
            ServiceSelected.reduce((a, v) => (a = a + v.price), 0)
          );
        }
      }
    } else {
      const toBeRemove = ServiceSelectedFix.find(
        (item) => item.title === itemTitle
      );
      if (toBeRemove) {
        ServiceSelectedFix.splice(ServiceSelectedFix.indexOf(toBeRemove), 1);
        setServiceSelectedFix([...ServiceSelectedFix]);
        if (ServiceSelectedFix === null) {
          setTotalServicevalueFix(0);
        } else {
          setTotalServicevalueFix(
            ServiceSelectedFix.reduce((a, v) => (a = a + v.price), 0)
          );
        }
      }
    }
  };
  const addAccessories = (itemId, itemTitle, itemPrice, selection) => {
    if (selection === "Accessories") {
      var newarraay = [];
      newarraay.push({ id: itemId, title: itemTitle, price: itemPrice });
      setAccessoriesSelected((current) => [...current, ...newarraay]);

      setTotalAccessoriesvalue(TotalAccessoriesvalue + newarraay[0].price);
    } else if (selection === "Accessoriesfix") {
      var newarraay = [];
      newarraay.push({ id: itemId, title: itemTitle, price: itemPrice });
      setAccessoriesSelectedFix((current) => [...current, ...newarraay]);
      setTotalAccessoriesvalueFix(
        TotalAccessoriesvalueFix + newarraay[0].price
      );
    } else {
      setAccessoriesSelected([
        ...ServiceSelected,
        { id: itemId, title: itemTitle, price: itemPrice },
      ]);
      setTotalAccessoriesvalue(0);
    }
  };
  const removeAccessories = (itemId, itemTitle, itemPrice, selection) => {
    if (selection === "Accessories") {
      const toBeRemove = AccessoriesSelected.find(
        (item) => item.title === itemTitle
      );
      if (toBeRemove) {
        AccessoriesSelected.splice(AccessoriesSelected.indexOf(toBeRemove), 1);
        setAccessoriesSelected([...AccessoriesSelected]);
        if (AccessoriesSelected === null) {
          setTotalAccessoriesvalue(0);
        } else {
          setTotalAccessoriesvalue(
            AccessoriesSelected.reduce((a, v) => (a = a + v.price), 0)
          );
        }
      }
    } else {
      const toBeRemove = AccessoriesSelectedFix.find(
        (item) => item.title === itemTitle
      );
      if (toBeRemove) {
        AccessoriesSelectedFix.splice(
          AccessoriesSelectedFix.indexOf(toBeRemove),
          1
        );
        setAccessoriesSelectedFix([...AccessoriesSelectedFix]);
        if (ServiceSelectedFix === null) {
          setTotalAccessoriesvalueFix(0);
        } else {
          setTotalAccessoriesvalueFix(
            AccessoriesSelectedFix.reduce((a, v) => (a = a + v.price), 0)
          );
        }
      }
    }
  };



  function updateTotal(e, amount) {

    setTotalAmount((totalAmount) => {
      if (e.target.checked) {
        totalAmount = totalAmount + amount;
        return totalAmount;
      } else {
        totalAmount = totalAmount - amount;
        return totalAmount;
      }
    });
  }

  const getPolicies = async () => {
    try {
      let policiesdata = await CamperService.policiesindex().json();
      setPolicies(policiesdata.data);
      addcancellation(
        policiesdata.data[0].id,
        policiesdata.data[0].title,
        policiesdata.data[0].price,
        "cancellation"
      );
    } catch (error) {
      if (error.name === "HTTPError") {
        const errorJson = await error.response.json();
        setError(
          errorJson.message.substr(0, errorJson.message.lastIndexOf("."))
        );
      }
    }
  };
  const getLocation = async () => {
    try {
      let locationList = await HomeService.location().json();
      var getData = locationList.data.filter((element) => {
        return element.title === "Berlin";
      });

      setSearchParams((searchParams) => {
        searchParams.set("location", `${getData[0].id}`);
        return searchParams;
      });

      setSkills(getData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleTime = (e) => {
    let time = e.value;

    if (time !== null) {
      setSearchParams((searchParams) => {
        searchParams.set("pickup", `${time}`);

        return searchParams;
      });
      let dateRange = searchParams.get("date");
      if (dateRange) {
        let startDate1 = dateRange.split("_");
        let result = 0;

        result = differenceInDays(
          new Date(startDate1[1]),
          new Date(startDate1[0])
        );

        if (searchParams.get("return") > 11 && searchParams.get("pickup") < 14) {



          setCount(1 + result);
        }
        else if (searchParams.get("pickup") < 14) {

          setCount(0.5 + result);

        }
        else if (searchParams.get("return") > 11) {

          setCount(0.5 + result);

        }
        else {


          setCount(result);

        }


      }
      if (time < 14) {
        sethandlecounter(true);

        if (handlecounter === true) {
          // setCount(count);
        } else {
          // setCount(count + 0.5);
          setCaperPriceTotal(CamperPriceTotal + (LastDayPrice * 0.5));
        }
      } else {
        sethandlecounter(false);
        if (handlecounter === false) {
          // setCount(count);

        } else {
          // setCount(count - 0.5);
          setCaperPriceTotal(CamperPriceTotal - (LastDayPrice * 0.5));
        }
      }

      setPickup(time);
    }
  };
  const handleReturnTime = (e) => {
    let time = e.value;

    if (time !== null) {
      // Check pricing Rule
      let i = 0;
      if (endDate != "") {
        let DateObj = new Date(endDate);
        let months = DateObj.getMonth();
        if (
          months == 0 ||
          months == 1 ||
          months == 2 ||
          months == 9 ||
          months == 10 ||
          months == 11
        ) {
          if (time == "17") {
            alert(
              t("Rent.From October 1st to March 31st, the latest return time is 16")
            );
            i = 1;
            setReturntime("");
          }
        }
      }
      if (i == 0) {
        setSearchParams((searchParams) => {
          searchParams.set("return", `${time}`);
          return searchParams;
        });
        let dateRange = searchParams.get("date");
        if (dateRange) {
          let startDate1 = dateRange.split("_");
          let result = 0;
          result = differenceInDays(
            new Date(startDate1[1]),
            new Date(startDate1[0])
          );

          if (searchParams.get("return") > 11 && searchParams.get("pickup") < 14) {

            setCount(1 + result);
          }
          else if (searchParams.get("pickup") < 14) {

            setCount(0.5 + result);

          }
          else if (searchParams.get("return") > 11) {

            setCount(0.5 + result);

          }
          else {
            setCount(result);

          }
          console.log(result, "result")
        }
        if (time > 11) {
          sethandlereturncounter(true);
          if (handlereturncounter === true) {
            // setCount(count);
          } else {
            // setCount(count + 0.5);
            // here we need to updatae total price for the last date ... 
            setCaperPriceTotal(CamperPriceTotal + (LastDayPrice * 0.5));
          }
        } else {
          sethandlereturncounter(false);
          if (handlereturncounter === false) {
            // setCount(count);
          } else {
            // setCount(count - 0.5);
            setCaperPriceTotal(CamperPriceTotal - (LastDayPrice * 0.5));
          }
        }
        setReturntime(time);
      }
    }
  };

  const excludeDatesget = [];
  const excludeDatesStart = [];
  const excludeDatesEnd = [];

  ExcludedateList.map((element) => {
    return excludeDatesget.push(new Date(element));
  });

  const renderDayContents = (day, date) => {
    var filtered = camper.camper_price.filter((element) => {
      const date = element.startdate;

      return date === moment(date).format("YYYY/MM/DD");
    });

    var getdate = filtered.map((camper_price) => {
      return camper_price.startdate;
    });
    var getprice =
      camper.camper_price &&
      camper.camper_price.map((camper_price) => {
        //return camper_price.price
        return camper_price.price;
      });

    let formetDates = moment(date).format("YYYY/MM/DD").indexOf(getdate) != 0;

    //now we need to check if this dates are in the range of what we set in the package ...
    let current_date = moment(date).format("X");
    var finalprice = getprice;
    var totalp = 0;
    finalprice = camper.price_managment.map((element) => {

      if (current_date >= moment(element.start_date).format("X") && current_date <= moment(element.end_date).format("X")) {
        return parseFloat((getprice * element.percentage) / 100 + parseFloat(getprice));
      }
    });

    for (var i = 0; i < finalprice.length; i++) {
      if (finalprice[i])
        totalp = finalprice[i];
    }

    if (totalp == 0)
      totalp = parseFloat(getprice);

    return (
      <span>
        {getDate(date)}
        <p className="price-font">
          {formetDates
            ? ""
            : "€ " +

            Math.round(totalp)
          }
        </p>

      </span>
    );


  };
  const onChangedate = async (date) => {
    const [start, end] = date;

    var i = 0;
    if (end !== null) {
      let DateObj = new Date(start);
      let months = DateObj.getMonth();

      var time_difference = end.getTime() - start.getTime();
      var days_difference = time_difference / (1000 * 60 * 60 * 24);
      if (months == 7) {
        if (days_difference < 3) {
          alert(t("Rent.The Minimum Rent Time in August is 3 nights"));
          i = 1;
          setStartDate("");
          setEndDate("");
          return true;
        }
      } else if (months == 5 || months == 6) {
        if (days_difference < 3) {
          alert(t("Rent.The Minimum Rent Time in June & July is 3 nights"));
          i = 1;
          setStartDate("");
          setEndDate("");
          return true;
        }
      } else {
        if (days_difference < 2) {
          alert(t("Rent.The Minimum Rent Time is 2 nights"));
          i = 1;
          setStartDate("");
          setEndDate("");
          return true;
        }
      }
      //inventory api
      var userData = {
        id: params.id,
        start_date: moment(start).format("YYYY-MM-DD"),
        end_date: moment(end).format("YYYY-MM-DD")
      }

      let getcheckaccessoires = await CamperService.checkaccessoires(userData).json();

      setInventory(getcheckaccessoires.data)

    }
    if (i == 0) {
      setStartDate(start);
      setEndDate(end);

      let result = 0;
      result = differenceInDays(end, start);
      if (searchParams.get("return") > 11 && searchParams.get("pickup") < 14) {

        setCount(1 + result);
      }
      else if (searchParams.get("pickup") < 14) {

        setCount(0.5 + result);
      }
      else if (searchParams.get("return") > 11) {

        setCount(0.5 + result);
      }
      else {
        setCount(result);
      }
      // if (searchParams.get("pickup") < 14 || searchParams.get("return") > 11) {
      //   setCount(0.5 + result);
      // } else {
      //   setCount(result);
      // }
      // var newresult = result + 1;

      // if (result >= 8 && result < 15) {
      //   var newcount =
      //     camper.camper_price &&
      //     camper.camper_price.map((camper_price) =>
      //       camper_price.discount_price === 0
      //         ? (result * camper_price.price * 5) / 100
      //         : result * camper_price.price -
      //         camper_price.discount_price -
      //         result * camper_price.price -
      //         (camper_price.discount_price * 5) / 100
      //     );
      //   setdiscountprice(newcount);
      // } else if (result >= 15 && result < 22) {
      //   var newcount =
      //     camper.camper_price &&
      //     camper.camper_price.map((camper_price) =>
      //       camper_price.discount_price === 0
      //         ? (result * camper_price.price * 10) / 100
      //         : result * camper_price.price -
      //         camper_price.discount_price -
      //         result * camper_price.price -
      //         (camper_price.discount_price * 10) / 100
      //     );
      //   setdiscountprice(newcount);
      // } else if (result >= 22) {
      //   var newcount =
      //     camper.camper_price &&
      //     camper.camper_price.map((camper_price) =>
      //       camper_price.discount_price === 0
      //         ? (result * camper_price.price * 15) / 100
      //         : result * camper_price.price -
      //         camper_price.discount_price -
      //         result * camper_price.price -
      //         (camper_price.discount_price * 15) / 100
      //     );
      //   setdiscountprice(newcount);
      // } else {
      //   setCaperPriceTotal(CamperPriceTotal)
      //   // console.log(CamperPriceTotal,"Camperprice")
      //   setdiscountprice(0);
      // }

      if (date[1] !== null) {
        if (variable.search == "") {
          navigate(
            generatePath(
              `?date=${moment(startDate).format("YYYY-MM-DD") +
              "_" +
              moment(date[1]).format("YYYY-MM-DD")
              }`
            )
          );
        } else if (variable.search !== "") {
          setSearchParams((searchParams) => {
            searchParams.set(
              "date",
              `${moment(startDate).format("YYYY-MM-DD") +
              "_" +
              moment(date[1]).format("YYYY-MM-DD")
              }`
            );
            return searchParams;
          });
        } else {
          navigate(
            generatePath(
              variable.search +
              `&date=${moment(startDate).format("YYYY-MM-DD") +
              "_" +
              moment(date[1]).format("YYYY-MM-DD")
              }`
            )
          );
        }
      }

      //need to do calculation here about the camper total price 
      /* custom work done by kp starts */

      /* check here if the start date and end date is in between our date */
      // let p_start_date = new moment(camper.price_managment.start_date).format("X");
      // let p_end_date = new moment(camper.price_managment.end_date).format("X");
      // let nowcheck = new moment(start);
      // let endcheck = new moment(end);
      // let totalDaysCalculation = 0;
      // let lastDay = 0;
      // var getprice =
      //   camper.camper_price &&
      //   camper.camper_price.map((camper_price) => {
      //     //return camper_price.price
      //     return camper_price.price;
      //   });
      // while (nowcheck <= endcheck) {

      //   let cktime = nowcheck.format("X");
      //   var finalprice = getprice;
      //   var totalp = 0;

      //   finalprice = camper.price_managment.map((element) => {

      //     if (cktime >= moment(element.start_date).format("X") && cktime <= moment(element.end_date).format("X")) {
      //       return parseFloat((getprice * element.percentage) / 100 + parseFloat(getprice));


      //     }

      //   }

      //   )
      //   for (var i = 0; i < finalprice.length; i++) {
      //     if (finalprice[i])
      //       totalp = finalprice[i];
      //   }

      //   if (totalp == 0)
      //     totalp = parseFloat(getprice);


      //   var newprice = ''
      //   if (finalprice === undefined) {

      //     lastDay = Math.round(totalp)
      //   }
      //   else {
      //     newprice = finalprice
      //     newprice = newprice.filter(Boolean);

      //     lastDay = Math.round(totalp)

      //   }
      //   totalDaysCalculation += lastDay

      //   nowcheck = moment(nowcheck).add(1, 'days'); // you are updating the same object again and again, so here I am creating new object
      // };

      // setCaperPriceTotal((totalDaysCalculation - lastDay));

      // setLastDayPrice(lastDay);
      // var newpricecel = totalDaysCalculation - lastDay
      // console.log(totalDaysCalculation,"totalDaysCalculation")
      // console.log(lastDay,"lastDay")
      // // if (searchParams.get("return") > 11 && searchParams.get("pickup") < 14) {

      // //   newpricecel = totalDaysCalculation - lastDay * 1
      // // }
      // // else if (searchParams.get("pickup") < 14) {

      // //   newpricecel = totalDaysCalculation - lastDay * 0.5
      // // }
      // // else if (searchParams.get("return") > 11) {

      // //   newpricecel = totalDaysCalculation - lastDay * 0.5
      // // }
      // // else {
      // //   newpricecel = totalDaysCalculation - lastDay
      // // }


      // var newresult = result + 1;

      // if (result >= 8 && result < 15) {

      //   var newcount =
      //     camper.camper_price &&
      //     camper.camper_price.map((camper_price) =>
      //       camper_price.discount_price === 0
      //         ? (result * camper_price.price * 10) / 100
      //         : result * camper_price.price -
      //         camper_price.discount_price -
      //         result * camper_price.price -
      //         (camper_price.discount_price * 10) / 100
      //     );
      //   // setdiscountprice((result * newcount * 5) / 100);
      //   console.log(newpricecel,"onchanges")
      //   setdiscountprice((newpricecel * 5) / 100);
      // } else if (result >= 15 && result < 22) {
      //   var newcount =
      //     camper.camper_price &&
      //     camper.camper_price.map((camper_price) =>
      //       camper_price.discount_price === 0
      //         ? (result * camper_price.price * 10) / 100
      //         : result * camper_price.price -
      //         camper_price.discount_price -
      //         result * camper_price.price -
      //         (camper_price.discount_price * 10) / 100
      //     );
      //   setdiscountprice((newpricecel * 10) / 100);
      //   // setdiscountprice(newcount);
      // } else if (result >= 22) {
      //   var newcount =
      //     camper.camper_price &&
      //     camper.camper_price.map((camper_price) =>
      //       camper_price.discount_price === 0
      //         ? (result * camper_price.price * 15) / 100
      //         : newresult * camper_price.price -
      //         camper_price.discount_price -
      //         result * camper_price.price -
      //         (camper_price.discount_price * 15) / 100
      //     );
      //   setdiscountprice((newpricecel * 15) / 100);
      //   // setdiscountprice(newcount);
      // } else {
      //   setdiscountprice(0);
      // }
      let nowcheck = new moment(start);
      let endcheck = new moment(end);
      let totalDaysCalculation = 0;
      let lastDay = 0;
      var getprice =
        camper.camper_price &&
        camper.camper_price.map((camper_price) => {
          //return camper_price.price
          return camper_price.price;
        });
      while (nowcheck <= endcheck) {

        let cktime = nowcheck.format("X");
        var finalprice = getprice;
        var totalp = 0;

        finalprice = camper.price_managment.map((element) => {

          if (cktime >= moment(element.start_date).format("X") && cktime <= moment(element.end_date).format("X")) {
            return parseFloat((getprice * element.percentage) / 100 + parseFloat(getprice));

          }

        }

        )
        for (var i = 0; i < finalprice.length; i++) {
          if (finalprice[i])
            totalp = finalprice[i];
        }

        if (totalp == 0)
          totalp = parseFloat(getprice);


        var newprice = ''
        if (finalprice === undefined) {

          lastDay = Math.round(totalp)
        }
        else {
          newprice = finalprice
          newprice = newprice.filter(Boolean);

          lastDay = Math.round(totalp)

        }
        totalDaysCalculation += lastDay

        nowcheck = moment(nowcheck).add(1, 'days');
      };

      setCaperPriceTotal((totalDaysCalculation - lastDay));
      // while (nowcheck <= endcheck) {
      //   //alldates.push(now.format("YYYY-MM-DD"));
      //   let cktime = nowcheck.format("X");

      //   if (cktime >= p_start_date && cktime <= p_end_date)
      //     lastDay = parseFloat(
      //       (getprice * getCamper.data.price_managment.percentage) / 100 +
      //       parseFloat(getprice)
      //     );
      //   else
      //     lastDay = parseFloat(getprice);

      //   totalDaysCalculation += lastDay;
      //   nowcheck = moment(nowcheck).add(1, 'days'); // you are updating the same object again and again, so here I am creating new object
      // };

      // setCaperPriceTotal((totalDaysCalculation - lastDay));
      setLastDayPrice(lastDay);
      if (searchParams.get("return") > 11 && searchParams.get("pickup") < 14) {
        setCaperPriceTotal(totalDaysCalculation - lastDay + (lastDay * 1))
        setCount(1 + result);
      }
      else if (searchParams.get("pickup") < 14) {
        setCaperPriceTotal(totalDaysCalculation - lastDay + (lastDay * 0.5))
        setCount(0.5 + result);
      }
      else if (searchParams.get("return") > 11) {
        setCaperPriceTotal(totalDaysCalculation - lastDay + (lastDay * 0.5))
        setCount(0.5 + result);
      }
      else {
        // alert("else call")
        // setCaperPriceTotal(totalDaysCalculation - lastDay - (lastDay * 0.5))
        setCount(result);
      }
      // var newpricecel = totalDaysCalculation - lastDay
      var newpricecel = ""
      if (searchParams.get("return") > 11 && searchParams.get("pickup") < 14) {

        newpricecel = totalDaysCalculation - lastDay * 1
      }
      else if (searchParams.get("pickup") < 14) {

        newpricecel = totalDaysCalculation - lastDay * 0.5
      }
      else if (searchParams.get("return") > 11) {

        newpricecel = totalDaysCalculation - lastDay * 0.5
      }
      else {
        newpricecel = totalDaysCalculation - lastDay
      }

      var newresult = result + 1;

      if (result >= 8 && result < 15) {

        // var newcount =
        //   getCamper.data.camper_price &&
        //   getCamper.data.camper_price.map((camper_price) => {
        //     return camper_price.discount_price === 0
        //       ? camper_price.price
        //       : camper_price.price - camper_price.discount_price;
        //   });
        // setdiscountprice((result * newcount * 5) / 100);
        console.log(newpricecel, "onchanges")
        setdiscountprice((newpricecel * 5) / 100);
      } else if (result >= 15 && result < 22) {
        // var newcount =
        //   getCamper.data.camper_price &&
        //   getCamper.data.camper_price.map((camper_price) =>
        //     camper_price.discount_price === 0
        //       ? (result * camper_price.price * 10) / 100
        //       : result * camper_price.price -
        //       camper_price.discount_price -
        //       result * camper_price.price -
        //       (camper_price.discount_price * 10) / 100
        //   );
        setdiscountprice((newpricecel * 10) / 100);
        // setdiscountprice(newcount);
      } else if (result >= 22) {
        // var newcount =
        //   getCamper.data.camper_price &&
        //   getCamper.data.camper_price.map((camper_price) =>
        //     camper_price.discount_price === 0
        //       ? (result * camper_price.price * 15) / 100
        //       : newresult * camper_price.price -
        //       camper_price.discount_price -
        //       result * camper_price.price -
        //       (camper_price.discount_price * 15) / 100
        //   );
        setdiscountprice((newpricecel * 15) / 100);
        // setdiscountprice(newcount);
      } else {
        setdiscountprice(0);
      }

      /* custom work done by kp ends */
      //Inventory access


    }
  };
  const detailData = {
    type: "Rent",
    location: searchParams.get("location"),
    date: searchParams.get("date"),
    pickup: searchParams.get("pickup"),
    return: searchParams.get("return"),
    packages: selectPackages,
    totalCamperPrice: CamperPriceTotal,
    cancelpackages: CancellationSelected,
    servicePackages: ServiceSelected,
    servicePackagesFix: ServiceSelectedFix,
    AccessoriesPackages: AccessoriesSelected,
    AccessoriesPackagesFix: AccessoriesSelectedFix,
    kilometerpackage: [],
    serviceFee: serviceFee,
    days: count,
    discountcount: discountprice,
    totalPackages:
      camper.camper_price &&
      camper.camper_price.map((camper_price) =>
        count
          ? selectPackages.length != 0 ?
            Math.round(selectPackages.reduce(
              (total, item) =>
                total +
                count * item.price +
                // CamperPriceTotal +
                count * TotalCancelvalue +
                count * TotalServicevalue +
                TotalServicevalueFix +
                count * TotalAccessoriesvalue +
                TotalAccessoriesvalueFix,
              camper_price.discount_price === 0
                ? parseInt(CamperPriceTotal) -
                discountprice +
                parseInt(serviceFee)
                : CamperPriceTotal -
                camper_price.discount_price -
                discountprice +
                parseInt(serviceFee)
            ))
            : CancellationSelected.length != 0 ?
              Math.round(CancellationSelected.reduce(
                (total, item) =>
                  total +
                  count * item.price +
                  count * TotalPackagevalue +
                  count * TotalServicevalue +
                  TotalServicevalueFix +
                  count * TotalAccessoriesvalue +
                  TotalAccessoriesvalueFix,
                camper_price.discount_price === 0
                  ? count * camper_price.price -
                  discountprice +
                  parseInt(serviceFee)
                  : count * camper_price.price -
                  camper_price.discount_price -
                  discountprice +
                  parseInt(serviceFee)
              )) : ServiceSelected.length != 0 ? Math.round(ServiceSelected.reduce(
                (total, item) =>
                  total +
                  count * item.price +
                  count * TotalPackagevalue +
                  count * TotalCancelvalue +
                  count * TotalAccessoriesvalue,
                camper_price.discount_price === 0
                  ? count * camper_price.price -
                  discountprice +
                  parseInt(serviceFee)
                  : count * camper_price.price -
                  camper_price.discount_price -
                  discountprice +
                  parseInt(serviceFee)
              ))
                : ServiceSelectedFix.length != 0 ?
                  Math.round(ServiceSelectedFix.reduce(
                    (total, item) =>
                      total +
                      item.price +
                      TotalPackagevalue +
                      TotalCancelvalue +
                      TotalAccessoriesvalue +
                      TotalAccessoriesvalueFix +
                      count * TotalServicevalue,
                    camper_price.discount_price === 0
                      ? camper_price.price +
                      parseInt(serviceFee)
                      : camper_price.price -
                      camper_price.discount_price +
                      parseInt(serviceFee)
                  )) : AccessoriesSelected.length != 0 ?
                    Math.round(
                      AccessoriesSelected.reduce(
                        (total, item) =>
                          total +
                          count * item.price +
                          count * TotalPackagevalue +
                          count * TotalCancelvalue +
                          count * TotalServicevalue +
                          TotalServicevalueFix,
                        camper_price.discount_price === 0
                          ? count * camper_price.price -
                          discountprice +
                          parseInt(serviceFee)
                          : count * camper_price.price -
                          camper_price.discount_price -
                          discountprice +
                          parseInt(serviceFee)
                      )
                    )
                    : AccessoriesSelectedFix.length != 0 ?
                      Math.round(
                        AccessoriesSelectedFix.reduce(
                          (total, item) =>
                            total +
                            item.price +
                            TotalPackagevalue +
                            TotalCancelvalue +
                            TotalAccessoriesvalue +
                            count * TotalServicevalue,
                          camper_price.discount_price === 0
                            ? camper_price.price +
                            parseInt(serviceFee)
                            : camper_price.price -
                            camper_price.discount_price +
                            parseInt(serviceFee)
                        )
                      ) : selectPackages.reduce(
                        (total, item) =>
                          total + count * item.price,
                        camper_price.discount_price === 0
                          ? count * camper_price.price -
                          discountprice +
                          parseInt(serviceFee)
                          : count * camper_price.price -
                          camper_price.discount_price -
                          discountprice +
                          parseInt(serviceFee)
                      )
          : selectPackages.length != 0
            ? selectPackages.reduce(
              (total, item) =>
                total +
                item.price +
                TotalCancelvalue +
                TotalServicevalue +
                TotalServicevalueFix +
                TotalAccessoriesvalue +
                TotalAccessoriesvalueFix,
              camper_price.discount_price === 0
                ? camper_price.price + parseInt(serviceFee)
                : camper_price.price -
                camper_price.discount_price +
                parseInt(serviceFee)
            )
            : CancellationSelected.length != 0
              ? CancellationSelected.reduce(
                (total, item) =>
                  total +
                  item.price +
                  TotalPackagevalue +
                  TotalServicevalue +
                  TotalServicevalueFix +
                  TotalAccessoriesvalue +
                  TotalAccessoriesvalueFix,
                camper_price.discount_price === 0
                  ? camper_price.price + parseInt(serviceFee)
                  : camper_price.price -
                  camper_price.discount_price +
                  parseInt(serviceFee)
              )
              : ServiceSelected.length != 0
                ? ServiceSelected.reduce(
                  (total, item) =>
                    total +
                    item.price +
                    TotalPackagevalue +
                    TotalCancelvalue +
                    TotalAccessoriesvalue +
                    TotalAccessoriesvalueFix,
                  camper_price.discount_price === 0
                    ? camper_price.price + parseInt(serviceFee)
                    : camper_price.price -
                    camper_price.discount_price +
                    parseInt(serviceFee)
                )
                : ServiceSelectedFix.length != 0
                  ? ServiceSelectedFix.reduce(
                    (total, item) =>
                      total +
                      item.price +
                      TotalPackagevalue +
                      TotalCancelvalue +
                      TotalAccessoriesvalue +
                      count * TotalServicevalue,
                    camper_price.discount_price === 0
                      ? camper_price.price + parseInt(serviceFee)
                      : camper_price.price -
                      camper_price.discount_price +
                      parseInt(serviceFee)
                  )
                  : AccessoriesSelected.length != 0
                    ? AccessoriesSelected.reduce(
                      (total, item) =>
                        total +
                        item.price +
                        TotalPackagevalue +
                        TotalCancelvalue +
                        TotalServicevalue +
                        TotalServicevalueFix,
                      camper_price.discount_price === 0
                        ? camper_price.price + parseInt(serviceFee)
                        : camper_price.price -
                        camper_price.discount_price +
                        parseInt(serviceFee)
                    )
                    : AccessoriesSelectedFix.length != 0
                      ? AccessoriesSelectedFix.reduce(
                        (total, item) =>
                          total +
                          item.price +
                          TotalPackagevalue +
                          TotalCancelvalue +
                          TotalAccessoriesvalue +
                          count * TotalServicevalue,
                        camper_price.discount_price === 0
                          ? camper_price.price + parseInt(serviceFee)
                          : camper_price.price -
                          camper_price.discount_price +
                          parseInt(serviceFee)
                      )
                      : selectPackages.reduce(
                        (total, item) => total + item.price,
                        camper_price.discount_price === 0
                          ? camper_price.price + parseInt(serviceFee)
                          : camper_price.price -
                          camper_price.discount_price +
                          parseInt(serviceFee)
                      )
      ),
  };

  const options = [
    // { value: "9", label: "9:00" },
    { value: "10", label: "10:00" },
    { value: "11", label: "11:00" },
    { value: "12", label: "12:00" },
    { value: "13", label: "13:00" },
    { value: "14", label: "14:00" },
    { value: "15", label: "15:00" },
    { value: "16", label: "16:00" },
    // { value: "17", label: "17:00" },
  ];
  const handleCheckout = (id) => {
    if (ExcludedateList.length === 365) {
      setError(t("rentinfo.Inventory is not available"));
    } else if (startDate === "") {
      setError(t("Rent.Please Select Date!"));
    } else if (pickup === "") {
      setError(t("Rent.Please Select Pickup Time!"));
    } else if (returntime === "") {
      setError(t("Rent.Please Select Return Time!"));
    } else {
      getCamperCheckDate(id);

      setError("");
    }
  };
  const getCamperCheckDate = async (id) => {
    const yeardate = startDate.getFullYear();
    const monthdate = String(startDate.getMonth() + 1).padStart(2, "0");
    const daydate = String(startDate.getDate()).padStart(2, "0");

    const year = endDate.getFullYear();
    const month = String(endDate.getMonth() + 1).padStart(2, "0");
    const day = String(endDate.getDate()).padStart(2, "0");

    const oneDate = `${yeardate}-${monthdate}-${daydate}`;
    const twoDate = `${year}-${month}-${day}`;

    console.log(twoDate);
    try {
      var userData = {
        id: id,
        startdate: oneDate,
        enddate: twoDate,
      };

      let responseData = await CamperService.checkdate(userData).json();

      if (responseData.status === false) {
        console.log(responseData.message)
        setError(t("Rent.Camper not available!!!"));
      } else {
        localStorage.setItem("expireTime", 300000);
        navigate(
          `/configurationdetail/${id}?location=${detailData.location}&date=${detailData.date}&pickup=${detailData.pickup}&return=${detailData.return}`,
          { state: detailData }
        );
      }
      // setError(responseData.message)
    } catch (error) {
      if (error.name === "HTTPError") {
        const errorJson = await error.response.json();
        setError(
          errorJson.message.substr(0, errorJson.message.lastIndexOf("."))
        );
      }
    }
  };
  const handleBack = () => {
    if (searchParams.get("date") === null) {
      navigate(`/campers?location=${detailData.location}`);
    } else {
      navigate(
        `/campers?location=${detailData.location}&date=${searchParams.get(
          "date"
        )}`
      );
    }
  };

  return (
    <>
      <div className="relative flex flex-col min-h-screen font-brand pb-0 md:pb-16 xl:pb-0">
        <div className="py-4 h-20 bg-white shadow xl:mb-5 z-10">
          <div className="flex items-center h-full xl:mx-auto xl:max-w-7xl">
            <Link to="/" className="flex items-center pl-4 w-1/5 h-5">
              <img
                className="header-camper-logo w-32"
                src="/images/born-camper-logo.png"
                alt="camper-logo"
              />
            </Link>
            <div className="flex items-center justify-center w-3/5 xl:items-start step-process">
              <div className="stepper grid-cols4 relative grid gap-y-1.5 grid-flow-col grid-rows-2 justify-items-center w-5/6 md:w-3/4 configuration-channel">
                <button className="step relative w-full">
                  <div className="mx-auto w-6 h-6 text-center text-secondary small-text border-2px fw-500 rounded-full border-primary">
                    <svg className="w-full h-full" width="32" height="32">
                      <svg
                        fill="#163435"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 50 50"
                        width="17px"
                        height="17px"
                      >
                        <path d="M 41.9375 8.625 C 41.273438 8.648438 40.664063 9 40.3125 9.5625 L 21.5 38.34375 L 9.3125 27.8125 C 8.789063 27.269531 8.003906 27.066406 7.28125 27.292969 C 6.5625 27.515625 6.027344 28.125 5.902344 28.867188 C 5.777344 29.613281 6.078125 30.363281 6.6875 30.8125 L 20.625 42.875 C 21.0625 43.246094 21.640625 43.410156 22.207031 43.328125 C 22.777344 43.242188 23.28125 42.917969 23.59375 42.4375 L 43.6875 11.75 C 44.117188 11.121094 44.152344 10.308594 43.78125 9.644531 C 43.410156 8.984375 42.695313 8.589844 41.9375 8.625 Z" />
                      </svg>
                    </svg>
                  </div>
                </button>
                <div className="fw-500 text-center text-secondary hidden xl:block">
                  {t("Rent.Select model")}
                </div>
                <button className="step relative w-full activeStep">
                  <div className="mx-auto w-6 h-6 text-center text-secondary small-text border-2px fw-500 rounded-full border-primary">
                    2
                  </div>
                </button>
                <div className="fw-500 text-center text-secondary col-span-full row-start-2 xl:col-auto">
                  {t("Rent.Configuration")}
                </div>
                <button className="step relative w-full">
                  <div className="mx-auto w-6 h-6 text-center text-secondary small-text border-2px fw-500 rounded-full text-opacity-disabled">
                    3
                  </div>
                </button>
                <div className="text-center text-secondary hidden xl:block xl:text-opacity-disabled">
                  {t("Rent.Details")}
                </div>
                <button className="step relative w-full">
                  <div className="mx-auto w-6 h-6 text-center text-secondary small-text border-2px fw-500 rounded-full text-opacity-disabled">
                    4
                  </div>
                </button>
                <div className="text-center text-secondary hidden xl:block xl:text-opacity-disabled">
                  {t("Rent.Payment")}
                </div>
              </div>
            </div>
            {/* <a
              className="flex flex-row items-center justify-end pr-4 w-1/5"
              href=""
            >
              <svg
                className="w-6 h-6 cssvar-secondary-to-white bg-info rounded-full"
                width="32"
                height="32"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 32 32"
                  fill="none"
                  id="icon-info"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.0951 12.6199C14.3751 12.6199 13.6671 12.6199 12.9543 12.6199C12.7986 12.6213 12.6437 12.6431 12.4935 12.6847C12.3103 12.7229 12.1704 12.8715 12.1432 13.0567C12.0925 13.2579 12.1937 13.4664 12.3831 13.551C12.6138 13.6589 12.8615 13.7255 13.1151 13.7478C13.7823 13.779 14.0511 14.0718 13.9503 14.7582C13.7631 16.0422 13.5111 17.3166 13.2951 18.5982C13.1487 19.4621 13.0263 20.3285 12.8847 21.1925C12.6951 22.3565 13.7799 23.4485 14.6415 23.5925C15.1519 23.664 15.667 23.6969 16.1823 23.6909C17.2866 23.6895 18.3415 23.2328 19.0982 22.4285C19.2357 22.3019 19.3389 22.1425 19.3982 21.9653C19.4272 21.8105 19.3907 21.6506 19.2974 21.5237C19.1847 21.4531 19.0459 21.4381 18.9206 21.4829C18.7238 21.5477 18.5486 21.6821 18.3567 21.7661C18.1323 21.8708 17.8973 21.9513 17.6559 22.0061C17.4611 22.0539 17.2552 22.0138 17.0926 21.8965C16.93 21.7792 16.8271 21.5964 16.8111 21.3965C16.7707 21.1732 16.7634 20.9452 16.7895 20.7197C16.8711 20.1629 16.9863 19.6109 17.0871 19.059C17.3607 17.5534 17.6383 16.047 17.9199 14.5398C17.9967 14.127 18.1263 13.7214 18.0399 13.287C18.0264 13.0941 17.936 12.9146 17.7888 12.7891C17.6416 12.6636 17.4501 12.6026 17.2575 12.6199C16.5375 12.6199 15.8151 12.6199 15.0951 12.6199ZM16.4007 7.28235C15.5158 7.24354 14.6954 7.7442 14.3254 8.54893C13.9553 9.35365 14.1091 10.3023 14.7145 10.9489C15.3199 11.5954 16.2564 11.8112 17.0837 11.4948C17.911 11.1783 18.4645 10.3926 18.4839 9.50711C18.5176 8.32037 17.5872 7.32888 16.4007 7.28715V7.28235Z"
                    fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"
                  ></path>
                </svg>
              </svg>
              <span className="hidden pl-2 md:block">{t("Rent.Info")}</span>
            </a> */}
          </div>
        </div>
        <div className="absolute left-0 top-0 hidden w-full h-20 pointer-events-none md:block">
          <div className="relative mx-auto max-w-7xl h-full"></div>
        </div>
        <img
          class="hero-img-campers inline-block max-w-full object-cover dark:brightness-90 aspect-square w-full group-hover:scale-110 transition-all duration-300"
          src={imageUrl + camper.background_image}
          alt="clara"
        />
        <main className="flex-grow xl:mb-64">
          <form action="">
            <div className="relative w-full max-w-7xl xl:flex xl:mx-auto">
              <Button
                onClick={(e) => handleBack()}
                className="py-4 text-white z-10 pointer-events-auto absolute top-30 left-0 flex items-center px-4"
              >
                <svg
                  class="h-6 w-6 cssvar-secondary-to-current"
                  width="32"
                  height="32"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 32 32"
                    fill="none"
                    id="icon-chevron-left"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M9.87444 15.9999L17.521 8.35332C17.991 7.88273 18.7555 7.88213 19.2261 8.35211L19.6468 8.77342C20.1191 9.24581 20.1167 10.0073 19.6456 10.4785L14.1241 15.9999L19.6456 21.5219C20.1167 21.9925 20.1191 22.7546 19.6468 23.2264L19.2261 23.6477C18.7555 24.1182 17.991 24.117 17.521 23.6465L9.87444 15.9999Z"
                      fill="var(--svg-custom-color, var(--svg-color-secondary, #fff))"
                    ></path>
                  </svg>
                </svg>
                {t("Rent.Back")}
              </Button>
              <div className="xl:w-2/3">
                <div className="md:h-[36.5rem] relative w-full overflow-hidden xl:overflow-visible">
                  <div className="relative grid place-items-center p-5 pb-5 h-full md:p-10 md:pb-10 mt-5">
                    <img
                      className="object-contain object-bottom rent-config-img"
                      src={imageUrl + camper.image}
                      alt="VW T6.1 California Beach Edition"
                    />
                  </div>
                </div>

                <div className="space-y-16 md:space-y-24" id="boxesWrp">
                  <section className="mobile-none">
                    <div className="flex px-5 py-4 overflow-y-auto space-x-6 lg:px-10">
                      {camper.packages &&
                        camper.packages.map((packages, index) => (
                          <label className="overflow-hidden text-center group min-w-[15rem] flex flex-col items-stretch px-4 w-1/3 bg-white border border-transparent rounded shadow divide-secondary divide-opacity-disabled divide-y">
                            <h5 className="fw-500 py-6 text-lg ">
                              {t("Rent.Carefree package")}{" "}
                              <span className="fw-500 py-6 text-lg ">
                                "{packages.title}"
                              </span>
                            </h5>
                            <div className="flex flex-col items-stretch justify-between h-100-c bg-off-white -mx-5 px-5 py-1 pb-0">
                              <div className="pb-4">
                                <div className="grid  gap-y-2 content-start items-center py-1 grid-col-2">
                                  <img
                                    className="w-7 h-7 cssvar-secondary-to-primary"
                                    height={32}
                                    width={32}
                                    src="/images/blue-right-mark.svg"
                                    alt="blue-right-mark"
                                  />

                                  <span className="text-left font-light text-md">
                                    {t("Rent.Deductable")} ({packages.deposit_price}€)
                                  </span>
                                </div>
                                {packages.package_item &&
                                  packages.package_item.map((package_item) => (
                                    <div className="grid  gap-y-2 content-start items-center py-1 grid-col-2">
                                      <img
                                        className="w-7 h-7 cssvar-secondary-to-primary"
                                        height={32}
                                        width={32}
                                        src="/images/blue-right-mark.svg"
                                        alt="blue-right-mark"
                                      />

                                      <span className="text-left font-light text-md">
                                        {package_item.title}
                                      </span>
                                    </div>
                                  ))}
                              </div>
                              <div className="py-6 items-center flex flex-col -mx-5  bg-white">
                                <div className="price-font ml-2 text-lg mb-5">
                                  <NumericFormat
                                    value={packages.price}
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    displayType={"text"}
                                  />
                                  .00 €
                                  <span className="text-xs block">
                                    {t("Rent.per night")}
                                  </span>
                                </div>

                                <input
                                  type="radio"
                                  className="peer d-none"
                                  name="carefree-id"
                                  checked={packages.id === optionSelected}
                                  value={packages.id}
                                  onClick={(e) => {
                                    console.log(e.target.checked);
                                    if (e.target.checked) {
                                      addChecked(
                                        packages.id,
                                        packages.title,
                                        packages.price,
                                        "carefree"
                                      );
                                    } else {
                                      removeChecked(
                                        packages.id,
                                        packages.title,
                                        packages.price,
                                        "carefree"
                                      );
                                    }
                                  }}
                                  onChange={(e) => {
                                    updateTotal(e, packages.price);
                                  }}
                                  onLoad={() => {
                                    if (index == 0) {
                                      addChecked(
                                        packages.id,
                                        packages.title,
                                        packages.price,
                                        "carefree"
                                      );
                                    } else {
                                    }
                                  }}
                                />

                                <div className="peer-checked:hidden w-7 h-7 border border-secondary group-hover:border-primary border-opacity-disabled rounded-full"></div>
                                <svg
                                  className="peer-checked:block hidden w-7 h-7 bg-primary rounded-full cssvar-secondary-to-white p-0.5"
                                  width="32"
                                  height="32"
                                >
                                  <svg
                                    fill="#FFFFFF"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    height="24px"
                                  >
                                    <path d="M 19.980469 5.9902344 A 1.0001 1.0001 0 0 0 19.292969 6.2929688 L 9 16.585938 L 5.7070312 13.292969 A 1.0001 1.0001 0 1 0 4.2929688 14.707031 L 8.2929688 18.707031 A 1.0001 1.0001 0 0 0 9.7070312 18.707031 L 20.707031 7.7070312 A 1.0001 1.0001 0 0 0 19.980469 5.9902344 z" />
                                  </svg>
                                </svg>
                                <div className="peer-checked:absolute left-0 top-0 hidden w-full h-full border border-primary rounded pointer-events-none"></div>
                              </div>
                            </div>
                          </label>
                        ))}
                    </div>
                  </section>

                  <section className="mt-0-imp desktop-hidden">
                    <div className="fw-500 inline-flex justify-center pb-5 px-5 w-full text-3xl md:justify-between md:px-10">
                      <h3>{t("Rent.Carefree package")}</h3>
                    </div>

                    <div className="grid gap-x-6 gap-y-5 grid-cols-1 px-5 md:grid-cols-4 md:px-10 lg:grid-cols-5 xl:grid-cols-4">
                      {camper.packages &&
                        camper.packages.map((packages, index) => (
                          <div className="div">
                            <div className="preserve-3d relative grid grid-cols-1 grid-rows-1 h-full transition-transform rotateBox">
                              <div className="backface-hidden col-start-1 row-start-1 w-full h-full">
                                <label className="group flex items-center px-0  w-full h-full bg-white border-transparent rounded shadow cursor-pointer md:flex-col md:px-0">
                                  <div className="service-sm-box flex items-center justify-center  w-full -mx-8 bg-off-white py-2">
                                    <img
                                      className="w-14 h-14 md:w-20 md:h-20"
                                      // src="/images/package-svgrepo-com.svg"
                                      src={packageimageurl + "Package/" + packages.icon}
                                    />
                                  </div>
                                  <div className="md:px-4  px-2 py-4 flex flex-grow items-center self-stretch w-full border-secondary border-opacity-disabled md:flex-col md:pt-4 md:border-l-0 md:border-t">
                                    <div className="fw-500 flex flex-col flex-grow items-start pl-4 md:items-center md:p-0 md:text-center">
                                      <div className="text-lg-c">
                                        {packages.title}
                                      </div>
                                      <div className="font-light price-font text-lg">
                                        <NumericFormat
                                          value={packages.price}
                                          thousandSeparator={"."}
                                          decimalSeparator={","}
                                          displayType={"text"}
                                        />
                                        .00 €
                                        <span className="text-xs block">
                                          {t("Rent.per night")}
                                        </span>
                                      </div>
                                    </div>

                                    <input
                                      type="radio"
                                      className="peer d-none"
                                      id="carefree-id"
                                      checked={packages.id === optionSelected}
                                      value={packages.id}
                                      onClick={(e) => {
                                        if (e.target.checked) {
                                          addChecked(
                                            packages.id,
                                            packages.title,
                                            packages.price,
                                            "carefree"
                                          );
                                        } else {
                                          removeChecked(
                                            packages.id,
                                            packages.title,
                                            packages.price,
                                            "carefree"
                                          );
                                        }
                                      }}
                                      onChange={(e) => {
                                        updateTotal(e, packages.price);
                                      }}
                                      onLoad={() => {
                                        if (index == 0) {
                                          addChecked(
                                            packages.id,
                                            packages.title,
                                            packages.price,
                                            "carefree"
                                          );
                                        } else {
                                        }
                                      }}
                                    />

                                    <div className="peer-checked:hidden mx-auto my-2 w-7 h-7 border border-secondary group-hover:border-primary border-opacity-disabled rounded-full"></div>
                                    <svg
                                      className="peer-checked:block hidden mx-auto w-7 h-7 my-2 bg-primary rounded-full cssvar-secondary-to-white p-0.5"
                                      width="32"
                                      height="32"
                                    >
                                      <svg
                                        fill="#FFFFFF"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        width="24px"
                                        height="24px"
                                      >
                                        <path d="M 19.980469 5.9902344 A 1.0001 1.0001 0 0 0 19.292969 6.2929688 L 9 16.585938 L 5.7070312 13.292969 A 1.0001 1.0001 0 1 0 4.2929688 14.707031 L 8.2929688 18.707031 A 1.0001 1.0001 0 0 0 9.7070312 18.707031 L 20.707031 7.7070312 A 1.0001 1.0001 0 0 0 19.980469 5.9902344 z" />
                                      </svg>
                                    </svg>
                                    <div className="absolute left-0 top-0 peer-checked:block hidden w-full h-full border border-primary rounded pointer-events-none"></div>
                                  </div>
                                </label>
                              </div>

                              <button
                                type="button"
                                className="flip-btn-icon backface-hidden absolute right-0 top-0 w-10 h-10 -translate-y-1/2 translate-x-1/2 md:translate-x-0 md:translate-y-0 rotateBtn"
                              >
                                <div className="relative m-auto w-7 h-7">
                                  <svg
                                    className="w-7 h-7 hover:cssvar-secondary-to-white hover:bg-info bg-white rounded-full shadow md:shadow-none"
                                    width="32"
                                    height="32"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 32 32"
                                      fill="none"
                                      id="icon-info"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M15.0951 12.6199C14.3751 12.6199 13.6671 12.6199 12.9543 12.6199C12.7986 12.6213 12.6437 12.6431 12.4935 12.6847C12.3103 12.7229 12.1704 12.8715 12.1432 13.0567C12.0925 13.2579 12.1937 13.4664 12.3831 13.551C12.6138 13.6589 12.8615 13.7255 13.1151 13.7478C13.7823 13.779 14.0511 14.0718 13.9503 14.7582C13.7631 16.0422 13.5111 17.3166 13.2951 18.5982C13.1487 19.4621 13.0263 20.3285 12.8847 21.1925C12.6951 22.3565 13.7799 23.4485 14.6415 23.5925C15.1519 23.664 15.667 23.6969 16.1823 23.6909C17.2866 23.6895 18.3415 23.2328 19.0982 22.4285C19.2357 22.3019 19.3389 22.1425 19.3982 21.9653C19.4272 21.8105 19.3907 21.6506 19.2974 21.5237C19.1847 21.4531 19.0459 21.4381 18.9206 21.4829C18.7238 21.5477 18.5486 21.6821 18.3567 21.7661C18.1323 21.8708 17.8973 21.9513 17.6559 22.0061C17.4611 22.0539 17.2552 22.0138 17.0926 21.8965C16.93 21.7792 16.8271 21.5964 16.8111 21.3965C16.7707 21.1732 16.7634 20.9452 16.7895 20.7197C16.8711 20.1629 16.9863 19.6109 17.0871 19.059C17.3607 17.5534 17.6383 16.047 17.9199 14.5398C17.9967 14.127 18.1263 13.7214 18.0399 13.287C18.0264 13.0941 17.936 12.9146 17.7888 12.7891C17.6416 12.6636 17.4501 12.6026 17.2575 12.6199C16.5375 12.6199 15.8151 12.6199 15.0951 12.6199ZM16.4007 7.28235C15.5158 7.24354 14.6954 7.7442 14.3254 8.54893C13.9553 9.35365 14.1091 10.3023 14.7145 10.9489C15.3199 11.5954 16.2564 11.8112 17.0837 11.4948C17.911 11.1783 18.4645 10.3926 18.4839 9.50711C18.5176 8.32037 17.5872 7.32888 16.4007 7.28715V7.28235Z"
                                        fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"
                                      ></path>
                                    </svg>
                                  </svg>
                                </div>
                              </button>

                              <div className="backface-hidden col-start-1 row-start-1 rotateBoxshow">
                                <div className="break-word relative grid grid-cols-1 grid-rows-1 place-items-center py-5 h-full text-center bg-white border border-transparent rounded shadow md:py-10 md:overflow-hidden">
                                  <div className="mx-5 max-w-full break-words md:mx-2 text-md">
                                    <div className="grid  gap-y-2 content-start items-center py-1 grid-col-2">
                                      <img
                                        className="w-7 h-7 cssvar-secondary-to-primary"
                                        height={32}
                                        width={32}
                                        src="/images/blue-right-mark.svg"
                                        alt="blue-right-mark"
                                      />
                                      <span className="text-left font-light text-md">
                                        {t("Rent.Deductable")} ({packages.deposit_price}€)
                                      </span>
                                    </div>
                                    {packages.package_item &&
                                      packages.package_item.map(
                                        (package_item) => (
                                          <div className="grid  gap-y-2 content-start items-center py-1 grid-col-2">
                                            <img
                                              className="w-7 h-7 cssvar-secondary-to-primary"
                                              height={32}
                                              width={32}
                                              src="/images/blue-right-mark.svg"
                                              alt="blue-right-mark"
                                            />

                                            <span className="text-left font-light text-md">
                                              {package_item.title}
                                            </span>
                                          </div>
                                        )
                                      )}
                                  </div>
                                  <button
                                    type="button"
                                    className="rotateBtn absolute right-0 top-0 w-10 h-10"
                                  >
                                    <svg
                                      width="32px"
                                      height="32px"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                                        fill="#000000"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </section>
                  <section className="mt-10-imp mobile-none">
                    <div className="fw-500 inline-flex justify-center pb-5 px-5 w-full text-3xl md:justify-between md:px-10">
                      <h3>{t("Rent.Cancellation policies")}</h3>
                    </div>
                    <div className="flex px-5 py-4 overflow-y-auto space-x-6 lg:px-10">
                      {camper.cancellationpolicy &&
                        camper.cancellationpolicy.map((packages) => (
                          <label className="overflow-hidden text-center group min-w-[15rem] flex flex-col items-stretch px-4 w-1/3 bg-white border border-transparent rounded shadow divide-secondary divide-opacity-disabled divide-y">
                            <h5 className="fw-500 py-6 text-lg ">
                              {packages.title}
                            </h5>
                            <div className="flex flex-col items-stretch justify-between h-100-c bg-off-white -mx-5 px-5 py-1 pb-0">
                              <div className="py-4">
                                {packages.cancellationpolicy_item &&
                                  packages.cancellationpolicy_item.map((package_item) => (
                                    <div className="grid  gap-y-2 content-start items-center py-1 grid-col-2">
                                      <img
                                        className="w-7 h-7 cssvar-secondary-to-primary"
                                        height={32}
                                        width={32}
                                        src="/images/blue-right-mark.svg"
                                        alt="blue-right-mark"
                                      />

                                      <span className="text-left font-light text-md">
                                        {package_item.title}
                                      </span>
                                    </div>
                                  ))}
                              </div>
                              <div className="py-6 items-center flex flex-col -mx-5  bg-white">
                                <div className="price-font ml-2 text-lg mb-5">
                                  <NumericFormat
                                    value={packages.price}
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    displayType={"text"}
                                  />
                                  .00 €
                                  <span className="text-xs block">
                                    {t("Rent.per night")}
                                  </span>
                                </div>
                                <input
                                  type="radio"
                                  className="peer d-none"
                                  id="cancellation-id"
                                  checked={packages.id === CanceloptionSelected}
                                  value={packages.id}
                                  onClick={(e) => {
                                    if (e.target.checked) {
                                      addcancellation(
                                        packages.id,
                                        packages.title,
                                        packages.price,
                                        "cancellation"
                                      );
                                    } else {
                                      removecancellation(
                                        packages.id,
                                        packages.title,
                                        packages.price,
                                        "cancellation"
                                      );
                                    }
                                  }}
                                  onChange={(e) => {
                                    updateTotal(e, packages.price);
                                  }}
                                />
                                <div className="peer-checked:hidden w-7 h-7 border border-secondary group-hover:border-primary border-opacity-disabled rounded-full"></div>
                                <svg
                                  className="peer-checked:block hidden w-7 h-7 bg-primary rounded-full cssvar-secondary-to-white p-0.5"
                                  width="32"
                                  height="32"
                                >
                                  <svg
                                    fill="#FFFFFF"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    height="24px"
                                  >
                                    <path d="M 19.980469 5.9902344 A 1.0001 1.0001 0 0 0 19.292969 6.2929688 L 9 16.585938 L 5.7070312 13.292969 A 1.0001 1.0001 0 1 0 4.2929688 14.707031 L 8.2929688 18.707031 A 1.0001 1.0001 0 0 0 9.7070312 18.707031 L 20.707031 7.7070312 A 1.0001 1.0001 0 0 0 19.980469 5.9902344 z" />
                                  </svg>
                                </svg>
                                <div className="peer-checked:absolute left-0 top-0 hidden w-full h-full border border-primary rounded pointer-events-none"></div>
                              </div>
                            </div>
                          </label>
                        ))}
                    </div>
                  </section>

                  <section className="mt-10-imp desktop-hidden">
                    <div className="fw-500 inline-flex justify-center pb-5 px-5 w-full text-3xl md:justify-between md:px-10">
                      <h3>{t("Rent.Cancellation policies")}</h3>
                    </div>

                    <div className="grid gap-x-6 gap-y-5 grid-cols-1 px-5 md:grid-cols-4 md:px-10 lg:grid-cols-5 xl:grid-cols-4">
                      {camper.cancellationpolicy &&
                        camper.cancellationpolicy.map((packages) => (
                          <div className="div">
                            <div className="preserve-3d relative grid grid-cols-1 grid-rows-1 h-full transition-transform rotateBox">
                              <div className="backface-hidden col-start-1 row-start-1 w-full h-full">
                                <label className="group flex items-center px-0  w-full h-full bg-white border-transparent rounded shadow cursor-pointer md:flex-col md:px-0">
                                  <div className="service-sm-box flex items-center justify-center  w-full -mx-8 bg-off-white py-2">
                                    <img
                                      className="w-14 h-14 md:w-20 md:h-20"
                                      //src="/images/piano-svgrepo-com.svg"
                                      src={packageimageurl + "Policy/" + packages.icon}
                                    />

                                  </div>
                                  <div className="md:px-4  px-2 py-4 flex flex-grow items-center self-stretch w-full border-secondary border-opacity-disabled md:flex-col md:pt-4 md:border-l-0 md:border-t">
                                    <div className="fw-500 flex flex-col flex-grow items-start pl-4 md:items-center md:p-0 md:text-center">
                                      <div className="text-lg-c">
                                        {packages.title}
                                      </div>
                                      <div className="font-light price-font text-lg">
                                        <NumericFormat
                                          value={packages.price}
                                          thousandSeparator={"."}
                                          decimalSeparator={","}
                                          displayType={"text"}
                                        />
                                        .00 €
                                        <span className="text-xs block">
                                          {t("Rent.per night")}
                                        </span>
                                      </div>
                                    </div>

                                    <input
                                      type="radio"
                                      className="peer d-none"
                                      id="cancellation-id"
                                      checked={
                                        packages.id === CanceloptionSelected
                                      }
                                      value={packages.id}
                                      onClick={(e) => {
                                        if (e.target.checked) {
                                          addcancellation(
                                            packages.id,
                                            packages.title,
                                            packages.price,
                                            "cancellation"
                                          );
                                        } else {
                                          removecancellation(
                                            packages.id,
                                            packages.title,
                                            packages.price,
                                            "cancellation"
                                          );
                                        }
                                      }}
                                      onChange={(e) => {
                                        updateTotal(e, packages.price);
                                      }}
                                    />

                                    <div className="peer-checked:hidden mx-auto my-2 w-7 h-7 border border-secondary group-hover:border-primary border-opacity-disabled rounded-full"></div>
                                    <svg
                                      className="peer-checked:block hidden mx-auto w-7 h-7 my-2 bg-primary rounded-full cssvar-secondary-to-white p-0.5"
                                      width="32"
                                      height="32"
                                    >
                                      <svg
                                        fill="#FFFFFF"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        width="24px"
                                        height="24px"
                                      >
                                        <path d="M 19.980469 5.9902344 A 1.0001 1.0001 0 0 0 19.292969 6.2929688 L 9 16.585938 L 5.7070312 13.292969 A 1.0001 1.0001 0 1 0 4.2929688 14.707031 L 8.2929688 18.707031 A 1.0001 1.0001 0 0 0 9.7070312 18.707031 L 20.707031 7.7070312 A 1.0001 1.0001 0 0 0 19.980469 5.9902344 z" />
                                      </svg>
                                    </svg>
                                    <div className="absolute left-0 top-0 peer-checked:block hidden w-full h-full border border-primary rounded pointer-events-none"></div>
                                  </div>
                                </label>
                              </div>

                              <button
                                type="button"
                                className="flip-btn-icon backface-hidden absolute right-0 top-0 w-10 h-10 -translate-y-1/2 translate-x-1/2 md:translate-x-0 md:translate-y-0 rotateBtn"
                              >
                                <div className="relative m-auto w-7 h-7">
                                  <svg
                                    className="w-7 h-7 hover:cssvar-secondary-to-white hover:bg-info bg-white rounded-full shadow md:shadow-none"
                                    width="32"
                                    height="32"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 32 32"
                                      fill="none"
                                      id="icon-info"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M15.0951 12.6199C14.3751 12.6199 13.6671 12.6199 12.9543 12.6199C12.7986 12.6213 12.6437 12.6431 12.4935 12.6847C12.3103 12.7229 12.1704 12.8715 12.1432 13.0567C12.0925 13.2579 12.1937 13.4664 12.3831 13.551C12.6138 13.6589 12.8615 13.7255 13.1151 13.7478C13.7823 13.779 14.0511 14.0718 13.9503 14.7582C13.7631 16.0422 13.5111 17.3166 13.2951 18.5982C13.1487 19.4621 13.0263 20.3285 12.8847 21.1925C12.6951 22.3565 13.7799 23.4485 14.6415 23.5925C15.1519 23.664 15.667 23.6969 16.1823 23.6909C17.2866 23.6895 18.3415 23.2328 19.0982 22.4285C19.2357 22.3019 19.3389 22.1425 19.3982 21.9653C19.4272 21.8105 19.3907 21.6506 19.2974 21.5237C19.1847 21.4531 19.0459 21.4381 18.9206 21.4829C18.7238 21.5477 18.5486 21.6821 18.3567 21.7661C18.1323 21.8708 17.8973 21.9513 17.6559 22.0061C17.4611 22.0539 17.2552 22.0138 17.0926 21.8965C16.93 21.7792 16.8271 21.5964 16.8111 21.3965C16.7707 21.1732 16.7634 20.9452 16.7895 20.7197C16.8711 20.1629 16.9863 19.6109 17.0871 19.059C17.3607 17.5534 17.6383 16.047 17.9199 14.5398C17.9967 14.127 18.1263 13.7214 18.0399 13.287C18.0264 13.0941 17.936 12.9146 17.7888 12.7891C17.6416 12.6636 17.4501 12.6026 17.2575 12.6199C16.5375 12.6199 15.8151 12.6199 15.0951 12.6199ZM16.4007 7.28235C15.5158 7.24354 14.6954 7.7442 14.3254 8.54893C13.9553 9.35365 14.1091 10.3023 14.7145 10.9489C15.3199 11.5954 16.2564 11.8112 17.0837 11.4948C17.911 11.1783 18.4645 10.3926 18.4839 9.50711C18.5176 8.32037 17.5872 7.32888 16.4007 7.28715V7.28235Z"
                                        fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"
                                      ></path>
                                    </svg>
                                  </svg>
                                </div>
                              </button>

                              <div className="backface-hidden col-start-1 row-start-1 rotateBoxshow">
                                <div className="break-word relative grid grid-cols-1 grid-rows-1 place-items-center py-5 h-full text-center bg-white border border-transparent rounded shadow md:py-10 md:overflow-hidden">
                                  <div className="mx-5 max-w-full break-words md:mx-2 text-md">
                                    {packages.cancellationpolicy_item &&
                                      packages.cancellationpolicy_item.map((package_item) => (
                                        <div className="grid  gap-y-2 content-start items-center py-1 grid-col-2">
                                          <img
                                            className="w-7 h-7 cssvar-secondary-to-primary"
                                            height={32}
                                            width={32}
                                            src="/images/blue-right-mark.svg"
                                            alt="blue-right-mark"
                                          />

                                          <span className="text-left font-light text-md">
                                            {package_item.title}
                                          </span>
                                        </div>
                                      ))}
                                  </div>
                                  <button
                                    type="button"
                                    className="rotateBtn absolute right-0 top-0 w-10 h-10"
                                  >
                                    <svg
                                      width="32px"
                                      height="32px"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                                        fill="#000000"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </section>
                  <section className="mt-10-imp">
                    <div className="fw-500 inline-flex justify-center pb-5 px-5 w-full text-3xl md:justify-between md:px-10">
                      <h3>{t("subscription.Services")}</h3>
                    </div>

                    <div className="grid gap-x-6 gap-y-5 grid-cols-1 px-5 md:grid-cols-4 md:px-10 lg:grid-cols-5 xl:grid-cols-4">
                      {camper.service &&
                        camper.service.map((service) => (
                          <div className="div">
                            <div className="preserve-3d relative grid grid-cols-1 grid-rows-1 h-full transition-transform rotateBox">
                              <div className="backface-hidden col-start-1 row-start-1 w-full h-full">
                                <label className="group flex items-center px-0  w-full h-full bg-white border-transparent rounded shadow cursor-pointer md:flex-col md:px-0">
                                  <div className="service-sm-box flex items-center justify-center  w-full -mx-8 bg-off-white py-2">
                                    <img
                                      className="w-14 h-14 md:w-20 md:h-20"
                                      src={
                                        service.icon
                                          ? process.env
                                            .REACT_APP_LARAVEL_IMAGE_URL +
                                          "Service/" +
                                          service.icon
                                          : ""
                                      }
                                    />
                                  </div>
                                  <div className="md:px-4  px-2 py-4 flex flex-grow items-center self-stretch w-full border-secondary border-opacity-disabled md:flex-col md:pt-4 md:border-l-0 md:border-t">
                                    <div className="fw-500 flex flex-col flex-grow items-start pl-4 md:items-center md:p-0 md:text-center">
                                      <div className="text-lg-c">
                                        {service.title}
                                      </div>
                                      <div className="font-light price-font text-lg">
                                        <NumericFormat
                                          value={service.price}
                                          thousandSeparator={"."}
                                          decimalSeparator={","}
                                          displayType={"text"}
                                        />
                                        &nbsp;€{" "}
                                        {service.type == 1 ? (
                                          <span className="text-xs block">
                                            {t("Rent.per night")}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    </div>

                                    {service.type == 1 ? (
                                      <input
                                        hidden=""
                                        name="+products.1256"
                                        type="checkbox"
                                        className="peer d-none"
                                        data-gtm-form-interact-field-id="2"
                                        onClick={(e) => {
                                          if (e.target.checked) {
                                            addservice(
                                              service.id,
                                              service.title,
                                              service.price,
                                              "service"
                                            );
                                          } else {
                                            removeservice(
                                              service.id,
                                              service.title,
                                              service.price,
                                              "service"
                                            );
                                          }
                                        }}
                                        onChange={(e) => {
                                          updateTotal(e, service.price);
                                        }}
                                      ></input>
                                    ) : (
                                      <input
                                        hidden=""
                                        name="+products.1256"
                                        type="checkbox"
                                        className="peer d-none"
                                        data-gtm-form-interact-field-id="2"
                                        onClick={(e) => {
                                          if (e.target.checked) {
                                            addservice(
                                              service.id,
                                              service.title,
                                              service.price,
                                              "servicefix"
                                            );
                                          } else {
                                            removeservice(
                                              service.id,
                                              service.title,
                                              service.price,
                                              "servicefix"
                                            );
                                          }
                                        }}
                                        onChange={(e) => {
                                          updateTotal(e, service.price);
                                        }}
                                      ></input>
                                    )}

                                    <div className="peer-checked:hidden mx-auto my-2 w-7 h-7 border border-secondary group-hover:border-primary border-opacity-disabled rounded-full"></div>
                                    <svg
                                      className="peer-checked:block hidden mx-auto w-7 h-7 my-2 bg-primary rounded-full cssvar-secondary-to-white p-0.5"
                                      width="32"
                                      height="32"
                                    >
                                      <svg
                                        fill="#FFFFFF"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        width="24px"
                                        height="24px"
                                      >
                                        <path d="M 19.980469 5.9902344 A 1.0001 1.0001 0 0 0 19.292969 6.2929688 L 9 16.585938 L 5.7070312 13.292969 A 1.0001 1.0001 0 1 0 4.2929688 14.707031 L 8.2929688 18.707031 A 1.0001 1.0001 0 0 0 9.7070312 18.707031 L 20.707031 7.7070312 A 1.0001 1.0001 0 0 0 19.980469 5.9902344 z" />
                                      </svg>
                                    </svg>
                                    <div className="absolute left-0 top-0 peer-checked:block hidden w-full h-full border border-primary rounded pointer-events-none"></div>
                                  </div>
                                </label>
                              </div>

                              <button
                                type="button"
                                className="flip-btn-icon backface-hidden absolute right-0 top-0 w-10 h-10 -translate-y-1/2 translate-x-1/2 md:translate-x-0 md:translate-y-0 rotateBtn"
                              >
                                <div className="relative m-auto w-7 h-7">
                                  <svg
                                    className="w-7 h-7 hover:cssvar-secondary-to-white hover:bg-info bg-white rounded-full shadow md:shadow-none"
                                    width="32"
                                    height="32"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 32 32"
                                      fill="none"
                                      id="icon-info"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M15.0951 12.6199C14.3751 12.6199 13.6671 12.6199 12.9543 12.6199C12.7986 12.6213 12.6437 12.6431 12.4935 12.6847C12.3103 12.7229 12.1704 12.8715 12.1432 13.0567C12.0925 13.2579 12.1937 13.4664 12.3831 13.551C12.6138 13.6589 12.8615 13.7255 13.1151 13.7478C13.7823 13.779 14.0511 14.0718 13.9503 14.7582C13.7631 16.0422 13.5111 17.3166 13.2951 18.5982C13.1487 19.4621 13.0263 20.3285 12.8847 21.1925C12.6951 22.3565 13.7799 23.4485 14.6415 23.5925C15.1519 23.664 15.667 23.6969 16.1823 23.6909C17.2866 23.6895 18.3415 23.2328 19.0982 22.4285C19.2357 22.3019 19.3389 22.1425 19.3982 21.9653C19.4272 21.8105 19.3907 21.6506 19.2974 21.5237C19.1847 21.4531 19.0459 21.4381 18.9206 21.4829C18.7238 21.5477 18.5486 21.6821 18.3567 21.7661C18.1323 21.8708 17.8973 21.9513 17.6559 22.0061C17.4611 22.0539 17.2552 22.0138 17.0926 21.8965C16.93 21.7792 16.8271 21.5964 16.8111 21.3965C16.7707 21.1732 16.7634 20.9452 16.7895 20.7197C16.8711 20.1629 16.9863 19.6109 17.0871 19.059C17.3607 17.5534 17.6383 16.047 17.9199 14.5398C17.9967 14.127 18.1263 13.7214 18.0399 13.287C18.0264 13.0941 17.936 12.9146 17.7888 12.7891C17.6416 12.6636 17.4501 12.6026 17.2575 12.6199C16.5375 12.6199 15.8151 12.6199 15.0951 12.6199ZM16.4007 7.28235C15.5158 7.24354 14.6954 7.7442 14.3254 8.54893C13.9553 9.35365 14.1091 10.3023 14.7145 10.9489C15.3199 11.5954 16.2564 11.8112 17.0837 11.4948C17.911 11.1783 18.4645 10.3926 18.4839 9.50711C18.5176 8.32037 17.5872 7.32888 16.4007 7.28715V7.28235Z"
                                        fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"
                                      ></path>
                                    </svg>
                                  </svg>
                                </div>
                              </button>

                              <div className="backface-hidden col-start-1 row-start-1 rotateBoxshow">
                                <div className="break-word relative grid grid-cols-1 grid-rows-1 place-items-center py-5 h-full text-center bg-white border border-transparent rounded shadow md:py-10 md:overflow-hidden">
                                  <div className="mx-5 max-w-full break-words md:mx-2 text-md">
                                    <span>{service.description}</span>
                                  </div>
                                  <button
                                    type="button"
                                    className="rotateBtn absolute right-0 top-0 w-10 h-10"
                                  >
                                    <svg
                                      width="32px"
                                      height="32px"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                                        fill="#000000"
                                      />
                                    </svg>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </section>
                  <section className="mt-10-imp">
                    <div className="fw-500 inline-flex justify-center pb-5 px-5 w-full text-3xl md:justify-between md:px-10">
                      <h3>{t("Rent.Accessoires")} </h3>
                    </div>
                    {/* bg-off-white */}
                    <div className="grid gap-x-6 gap-y-5 grid-cols-1 px-5 md:grid-cols-4 md:px-10 lg:grid-cols-5 xl:grid-cols-4">
                      {
                        Inventory.map((accessory) => (
                          <>
                            {
                              accessory.status === 1 ?
                                <div className="div">
                                  <div className="preserve-3d relative grid grid-cols-1 grid-rows-1 h-full transition-transform rotateBox ">
                                    <div className="backface-hidden col-start-1 row-start-1 w-full h-full ">
                                      <label className="group flex items-center px-0  w-full h-full bg-white border-transparent rounded shadow cursor-pointer md:flex-col md:px-0">
                                        <div className="service-sm-box flex items-center justify-center  w-full -mx-8 bg-off-white py-2">
                                          <img
                                            className="w-14 h-14 md:w-20 md:h-20"
                                            src={
                                              accessory.icon
                                                ? process.env
                                                  .REACT_APP_LARAVEL_IMAGE_URL +
                                                "Accessory/" +
                                                accessory.icon
                                                : ""
                                            }
                                          />
                                        </div>
                                        {/* bg-off-white */}
                                        <div className="md:px-4  px-2 py-4 flex flex-grow items-center self-stretch w-full border-secondary border-opacity-disabled md:flex-col md:pt-4 md:border-l-0 md:border-t ">
                                          <div className="fw-500 flex flex-col flex-grow items-start pl-4 md:items-center md:p-0 md:text-center">
                                            <div className="text-lg-c">
                                              {accessory.title}
                                            </div>
                                            <div className="font-light price-font text-lg">
                                              <NumericFormat
                                                value={accessory.price}
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                displayType={"text"}
                                              />
                                              &nbsp;€{" "}
                                              {accessory.type == 1 ? (
                                                <span className="text-xs block">
                                                  {t("Rent.per night")}
                                                </span>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                          {accessory.type == 1 ? (
                                            <input
                                              hidden=""
                                              name="+products.1256"
                                              type="checkbox"
                                              className="peer d-none"
                                              data-gtm-form-interact-field-id="2"
                                              onClick={(e) => {
                                                if (e.target.checked) {
                                                  addAccessories(
                                                    accessory.id,
                                                    accessory.title,
                                                    accessory.price,
                                                    "Accessories"
                                                  );
                                                } else {
                                                  removeAccessories(
                                                    accessory.id,
                                                    accessory.title,
                                                    accessory.price,
                                                    "Accessories"
                                                  );
                                                }
                                              }}
                                              onChange={(e) => {
                                                updateTotal(e, accessory.price);
                                              }}
                                            ></input>
                                          ) : (
                                            <input
                                              hidden=""
                                              name="+products.1256"
                                              type="checkbox"
                                              className="peer d-none"
                                              data-gtm-form-interact-field-id="2"
                                              onClick={(e) => {
                                                if (e.target.checked) {
                                                  addAccessories(
                                                    accessory.id,
                                                    accessory.title,
                                                    accessory.price,
                                                    "Accessoriesfix"
                                                  );
                                                } else {
                                                  removeAccessories(
                                                    accessory.id,
                                                    accessory.title,
                                                    accessory.price,
                                                    "Accessoriesfix"
                                                  );
                                                }
                                              }}
                                              onChange={(e) => {
                                                updateTotal(e, accessory.price);
                                              }}
                                            ></input>
                                          )}

                                          <div className="peer-checked:hidden mx-auto my-2 w-7 h-7 border border-secondary group-hover:border-primary border-opacity-disabled rounded-full"></div>
                                          <svg
                                            className="peer-checked:block hidden mx-auto w-7 h-7 my-2 bg-primary rounded-full cssvar-secondary-to-white p-0.5"
                                            width="32"
                                            height="32"
                                          >
                                            <svg
                                              fill="#FFFFFF"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                              width="24px"
                                              height="24px"
                                            >
                                              <path d="M 19.980469 5.9902344 A 1.0001 1.0001 0 0 0 19.292969 6.2929688 L 9 16.585938 L 5.7070312 13.292969 A 1.0001 1.0001 0 1 0 4.2929688 14.707031 L 8.2929688 18.707031 A 1.0001 1.0001 0 0 0 9.7070312 18.707031 L 20.707031 7.7070312 A 1.0001 1.0001 0 0 0 19.980469 5.9902344 z" />
                                            </svg>
                                          </svg>
                                          <div className="absolute left-0 top-0 peer-checked:block hidden w-full h-full border border-primary rounded pointer-events-none"></div>
                                        </div>
                                      </label>
                                    </div>

                                    <button
                                      type="button"
                                      className="flip-btn-icon backface-hidden absolute right-0 top-0 w-10 h-10 -translate-y-1/2 translate-x-1/2 md:translate-x-0 md:translate-y-0 rotateBtn"
                                    >
                                      <div className="relative m-auto w-7 h-7">
                                        <svg
                                          className="w-7 h-7 hover:cssvar-secondary-to-white hover:bg-info bg-white rounded-full shadow md:shadow-none"
                                          width="32"
                                          height="32"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 32 32"
                                            fill="none"
                                            id="icon-info"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M15.0951 12.6199C14.3751 12.6199 13.6671 12.6199 12.9543 12.6199C12.7986 12.6213 12.6437 12.6431 12.4935 12.6847C12.3103 12.7229 12.1704 12.8715 12.1432 13.0567C12.0925 13.2579 12.1937 13.4664 12.3831 13.551C12.6138 13.6589 12.8615 13.7255 13.1151 13.7478C13.7823 13.779 14.0511 14.0718 13.9503 14.7582C13.7631 16.0422 13.5111 17.3166 13.2951 18.5982C13.1487 19.4621 13.0263 20.3285 12.8847 21.1925C12.6951 22.3565 13.7799 23.4485 14.6415 23.5925C15.1519 23.664 15.667 23.6969 16.1823 23.6909C17.2866 23.6895 18.3415 23.2328 19.0982 22.4285C19.2357 22.3019 19.3389 22.1425 19.3982 21.9653C19.4272 21.8105 19.3907 21.6506 19.2974 21.5237C19.1847 21.4531 19.0459 21.4381 18.9206 21.4829C18.7238 21.5477 18.5486 21.6821 18.3567 21.7661C18.1323 21.8708 17.8973 21.9513 17.6559 22.0061C17.4611 22.0539 17.2552 22.0138 17.0926 21.8965C16.93 21.7792 16.8271 21.5964 16.8111 21.3965C16.7707 21.1732 16.7634 20.9452 16.7895 20.7197C16.8711 20.1629 16.9863 19.6109 17.0871 19.059C17.3607 17.5534 17.6383 16.047 17.9199 14.5398C17.9967 14.127 18.1263 13.7214 18.0399 13.287C18.0264 13.0941 17.936 12.9146 17.7888 12.7891C17.6416 12.6636 17.4501 12.6026 17.2575 12.6199C16.5375 12.6199 15.8151 12.6199 15.0951 12.6199ZM16.4007 7.28235C15.5158 7.24354 14.6954 7.7442 14.3254 8.54893C13.9553 9.35365 14.1091 10.3023 14.7145 10.9489C15.3199 11.5954 16.2564 11.8112 17.0837 11.4948C17.911 11.1783 18.4645 10.3926 18.4839 9.50711C18.5176 8.32037 17.5872 7.32888 16.4007 7.28715V7.28235Z"
                                              fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"
                                            ></path>
                                          </svg>
                                        </svg>
                                      </div>
                                    </button>

                                    <div className="backface-hidden col-start-1 row-start-1 rotateBoxshow">
                                      <div className="break-word relative grid grid-cols-1 grid-rows-1 place-items-center py-5 h-full text-center bg-white border border-transparent rounded shadow md:py-10 md:overflow-hidden">
                                        <div className="mx-5 max-w-full break-words md:mx-2 text-md">
                                          <span>{accessory.description}</span>
                                        </div>
                                        <button
                                          type="button"
                                          className="rotateBtn absolute right-0 top-0 w-10 h-10"
                                        >
                                          <svg
                                            width="32px"
                                            height="32px"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                                              fill="#000000"
                                            />
                                          </svg>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                :
                                <div className="div disable-service">
                                  <div className="preserve-3d relative grid grid-cols-1 grid-rows-1 h-full transition-transform rotateBox ">
                                    <div className="backface-hidden col-start-1 row-start-1 w-full h-full ">
                                      <label className="group flex items-center px-0  w-full h-full bg-white border-transparent rounded shadow cursor-pointer md:flex-col md:px-0">
                                        <div className="service-sm-box flex items-center justify-center  w-full -mx-8  py-2">
                                          <img
                                            className="w-14 h-14 md:w-20 md:h-20"
                                            src={
                                              accessory.icon
                                                ? process.env
                                                  .REACT_APP_LARAVEL_IMAGE_URL +
                                                "Accessory/" +
                                                accessory.icon
                                                : ""
                                            }
                                          />
                                        </div>
                                        {/* bg-off-white */}
                                        <div className="md:px-4  px-2 py-4 flex flex-grow items-center self-stretch w-full border-secondary border-opacity-disabled md:flex-col md:pt-4 md:border-l-0 md:border-t">
                                          <div className="fw-500 flex flex-col flex-grow items-start pl-4 md:items-center md:p-0 md:text-center">
                                            <div className="text-lg-c">
                                              {accessory.title}
                                            </div>
                                            <div className="font-light price-font text-lg">
                                              <NumericFormat
                                                value={accessory.price}
                                                thousandSeparator={"."}
                                                decimalSeparator={","}
                                                displayType={"text"}
                                              />
                                              &nbsp;€{" "}
                                              {accessory.type == 1 ? (
                                                <span className="text-xs block">
                                                  {t("Rent.per night")}
                                                </span>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                          {accessory.type == 1 ? (
                                            <input
                                              hidden=""
                                              name="+products.1256"
                                              type="checkbox"
                                              className="peer d-none"
                                              data-gtm-form-interact-field-id="2"
                                              // onClick={(e) => {
                                              //   if (e.target.checked) {
                                              //     addAccessories(
                                              //       accessory.id,
                                              //       accessory.title,
                                              //       accessory.price,
                                              //       "Accessories"
                                              //     );
                                              //   } else {
                                              //     removeAccessories(
                                              //       accessory.id,
                                              //       accessory.title,
                                              //       accessory.price,
                                              //       "Accessories"
                                              //     );
                                              //   }
                                              // }}
                                              // onChange={(e) => {
                                              //   updateTotal(e, accessory.price);
                                              // }}
                                              disabled
                                            ></input>
                                          ) : (
                                            <input
                                              hidden=""
                                              name="+products.1256"
                                              type="checkbox"
                                              className="peer d-none"
                                              data-gtm-form-interact-field-id="2"

                                              disabled
                                            ></input>
                                          )}

                                          <div className="peer-checked:hidden mx-auto my-2 w-7 h-7 border border-secondary group-hover:border-primary border-opacity-disabled rounded-full"></div>
                                          <svg
                                            className="peer-checked:block hidden mx-auto w-7 h-7 my-2 bg-primary rounded-full cssvar-secondary-to-white p-0.5"
                                            width="32"
                                            height="32"
                                          >
                                            <svg
                                              fill="#FFFFFF"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                              width="24px"
                                              height="24px"
                                            >
                                              <path d="M 19.980469 5.9902344 A 1.0001 1.0001 0 0 0 19.292969 6.2929688 L 9 16.585938 L 5.7070312 13.292969 A 1.0001 1.0001 0 1 0 4.2929688 14.707031 L 8.2929688 18.707031 A 1.0001 1.0001 0 0 0 9.7070312 18.707031 L 20.707031 7.7070312 A 1.0001 1.0001 0 0 0 19.980469 5.9902344 z" />
                                            </svg>
                                          </svg>
                                          <div className="absolute left-0 top-0 peer-checked:block hidden w-full h-full border border-primary rounded pointer-events-none"></div>
                                        </div>
                                      </label>
                                    </div>

                                    <button
                                      type="button"
                                      className="flip-btn-icon backface-hidden absolute right-0 top-0 w-10 h-10 -translate-y-1/2 translate-x-1/2 md:translate-x-0 md:translate-y-0 rotateBtn"
                                    >
                                      <div className="relative m-auto w-7 h-7">
                                        <svg
                                          className="w-7 h-7 hover:cssvar-secondary-to-white hover:bg-info bg-white rounded-full shadow md:shadow-none"
                                          width="32"
                                          height="32"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 32 32"
                                            fill="none"
                                            id="icon-info"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M15.0951 12.6199C14.3751 12.6199 13.6671 12.6199 12.9543 12.6199C12.7986 12.6213 12.6437 12.6431 12.4935 12.6847C12.3103 12.7229 12.1704 12.8715 12.1432 13.0567C12.0925 13.2579 12.1937 13.4664 12.3831 13.551C12.6138 13.6589 12.8615 13.7255 13.1151 13.7478C13.7823 13.779 14.0511 14.0718 13.9503 14.7582C13.7631 16.0422 13.5111 17.3166 13.2951 18.5982C13.1487 19.4621 13.0263 20.3285 12.8847 21.1925C12.6951 22.3565 13.7799 23.4485 14.6415 23.5925C15.1519 23.664 15.667 23.6969 16.1823 23.6909C17.2866 23.6895 18.3415 23.2328 19.0982 22.4285C19.2357 22.3019 19.3389 22.1425 19.3982 21.9653C19.4272 21.8105 19.3907 21.6506 19.2974 21.5237C19.1847 21.4531 19.0459 21.4381 18.9206 21.4829C18.7238 21.5477 18.5486 21.6821 18.3567 21.7661C18.1323 21.8708 17.8973 21.9513 17.6559 22.0061C17.4611 22.0539 17.2552 22.0138 17.0926 21.8965C16.93 21.7792 16.8271 21.5964 16.8111 21.3965C16.7707 21.1732 16.7634 20.9452 16.7895 20.7197C16.8711 20.1629 16.9863 19.6109 17.0871 19.059C17.3607 17.5534 17.6383 16.047 17.9199 14.5398C17.9967 14.127 18.1263 13.7214 18.0399 13.287C18.0264 13.0941 17.936 12.9146 17.7888 12.7891C17.6416 12.6636 17.4501 12.6026 17.2575 12.6199C16.5375 12.6199 15.8151 12.6199 15.0951 12.6199ZM16.4007 7.28235C15.5158 7.24354 14.6954 7.7442 14.3254 8.54893C13.9553 9.35365 14.1091 10.3023 14.7145 10.9489C15.3199 11.5954 16.2564 11.8112 17.0837 11.4948C17.911 11.1783 18.4645 10.3926 18.4839 9.50711C18.5176 8.32037 17.5872 7.32888 16.4007 7.28715V7.28235Z"
                                              fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"
                                            ></path>
                                          </svg>
                                        </svg>
                                      </div>
                                    </button>

                                    <div className="backface-hidden col-start-1 row-start-1 rotateBoxshow">
                                      <div className="break-word relative grid grid-cols-1 grid-rows-1 place-items-center py-5 h-full text-center bg-white border border-transparent rounded shadow md:py-10 md:overflow-hidden">
                                        <div className="mx-5 max-w-full break-words md:mx-2 text-md">
                                          <span>{accessory.description}</span>
                                        </div>
                                        <button
                                          type="button"
                                          className="rotateBtn absolute right-0 top-0 w-10 h-10"
                                        >
                                          <svg
                                            width="32px"
                                            height="32px"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                          >
                                            <path
                                              fillRule="evenodd"
                                              clipRule="evenodd"
                                              d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
                                              fill="#000000"
                                            />
                                          </svg>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                            }
                          </>

                        ))
                      }
                    </div>

                  </section>

                  <div className="question-box mt-10-imp mb-16 mx-5 bg-white rounded shadow overflow-hidden md:mx-10 xl:mb-0">
                    <div className="flex flex-col items-center justify-center my-5 md:my-10 px-4 text-center text-2xl sm:p-6 letter-spacing-1-2">
                      <svg
                        className="mb-4 w-8 h-8 cssvar-secondary-to-white bg-info rounded-full"
                        width="32"
                        height="32"
                      >
                        <svg viewBox="0 0 32 32" fill="none" id="icon-info">
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M15.0951 12.6199C14.3751 12.6199 13.6671 12.6199 12.9543 12.6199C12.7986 12.6213 12.6437 12.6431 12.4935 12.6847C12.3103 12.7229 12.1704 12.8715 12.1432 13.0567C12.0925 13.2579 12.1937 13.4664 12.3831 13.551C12.6138 13.6589 12.8615 13.7255 13.1151 13.7478C13.7823 13.779 14.0511 14.0718 13.9503 14.7582C13.7631 16.0422 13.5111 17.3166 13.2951 18.5982C13.1487 19.4621 13.0263 20.3285 12.8847 21.1925C12.6951 22.3565 13.7799 23.4485 14.6415 23.5925C15.1519 23.664 15.667 23.6969 16.1823 23.6909C17.2866 23.6895 18.3415 23.2328 19.0982 22.4285C19.2357 22.3019 19.3389 22.1425 19.3982 21.9653C19.4272 21.8105 19.3907 21.6506 19.2974 21.5237C19.1847 21.4531 19.0459 21.4381 18.9206 21.4829C18.7238 21.5477 18.5486 21.6821 18.3567 21.7661C18.1323 21.8708 17.8973 21.9513 17.6559 22.0061C17.4611 22.0539 17.2552 22.0138 17.0926 21.8965C16.93 21.7792 16.8271 21.5964 16.8111 21.3965C16.7707 21.1732 16.7634 20.9452 16.7895 20.7197C16.8711 20.1629 16.9863 19.6109 17.0871 19.059C17.3607 17.5534 17.6383 16.047 17.9199 14.5398C17.9967 14.127 18.1263 13.7214 18.0399 13.287C18.0264 13.0941 17.936 12.9146 17.7888 12.7891C17.6416 12.6636 17.4501 12.6026 17.2575 12.6199C16.5375 12.6199 15.8151 12.6199 15.0951 12.6199ZM16.4007 7.28235C15.5158 7.24354 14.6954 7.7442 14.3254 8.54893C13.9553 9.35365 14.1091 10.3023 14.7145 10.9489C15.3199 11.5954 16.2564 11.8112 17.0837 11.4948C17.911 11.1783 18.4645 10.3926 18.4839 9.50711C18.5176 8.32037 17.5872 7.32888 16.4007 7.28715V7.28235Z"
                            fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"
                          ></path>
                        </svg>
                      </svg>
                      <div>{t("Voucher.Questions?")}</div>
                      <div>{t("Rent.We are available almost at any time")}</div>
                      <div className="xl:block price-font">
                        <Link
                          to="tel:+49 (0) 163 2937354"
                          class="mt-2 w-full text-center  hover:text-primary hover:dark:text-primary-light lg:block text-lg"
                        >
                          +49 (0) 163 2937354
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="relative my-10">
                <div className="bg-white rounded-button shadow rounded-none divide-secondary divide-opacity-disabled divide-y overflow-hidden xl:rounded-3xl max-w-[100vw] h-full max-h-screen overflow-y-auto xl:sticky xl:top-10 xl:h-auto xl:overflow-y-visible custom-overflow">
                  <div className="flex flex-col items-center p-6 mx-auto max-w-3xl ">
                    <h1 className="break-words mb-2 text-xl xs:mb-6 price-font fw-600 text-center">
                      {camper.title}
                    </h1>
                    {camper.camper_details &&
                      camper.camper_details.map((technical) => (
                        <span className="mb-3">{technical.sub_title}</span>
                      ))}
                    <div className="relative w-full">
                      <span className="flex h-14 w-full items-center bg-white dark:bg-off-black-desat transition-all child-span:overflow-hidden child-span:text-ellipsis border focus:border-primary focus:outline-none justify-between border-grey hover:shadow dark:border-grey-dark dark:hover:shadow-dark rounded">
                        <div className="inline-block align-top ml-3 mr-1 min-w-fit child:h-7 child:w-7 dark:cssvar-secondary-to-off-white">
                          <svg width="32" height="32" viewBox="0 0 32 32">
                            <g fill="none" fillRule="evenodd">
                              <path
                                d="M21.06 4.5c.474 0 .884.3 1.01.741l.028.124.01.127-.002 1.327h1.14c1.503 0 2.772 1.005 3.049 2.362l.03.179.019.182.006.183v13.87c0 1.476-1.178 2.7-2.715 2.882l-.194.017-.195.006H9.104c-1.569 0-2.882-1.094-3.079-2.54l-.019-.183L6 23.595V9.725c0-1.477 1.178-2.7 2.715-2.882l.194-.018.195-.005 1.139-.001V5.492c0-.473.347-.864.808-.966l.117-.02.121-.006c.473 0 .883.3 1.01.741l.027.124.01.127-.001 1.327h2.858V5.463c0-.452.316-.828.74-.934l.12-.022.122-.007a.98.98 0 0 1 .941.697l.029.117.012.149-.002 1.356h2.859V5.492c0-.473.348-.864.808-.966l.117-.02.122-.006zm3.197 7.015H8.092v11.989c0 .473.328.877.764.983l.122.021.126.007h14.142c.476 0 .878-.33.983-.764l.021-.122.007-.125V11.515zm-7.883 1.614 1.085 2.516c.031.071.097.12.174.129l2.75.28c.187.02.26.253.118.377l-2.072 1.791a.214.214 0 0 0-.07.21l.613 2.73a.215.215 0 0 1-.321.23l-2.365-1.446a.214.214 0 0 0-.222 0L13.7 21.393a.215.215 0 0 1-.32-.232l.611-2.729a.215.215 0 0 0-.07-.21l-2.071-1.79a.215.215 0 0 1 .118-.377l2.75-.281a.212.212 0 0 0 .174-.129l1.085-2.516a.217.217 0 0 1 .397 0z"
                                fill="var(--svg-color-secondary, #163435)"
                              ></path>
                            </g>
                          </svg>
                        </div>
                        <p>{ }</p>

                        <span className="text-ellipsis whitespace-nowrap text-md custom-calender">
                          <DatePicker
                            selectsRange={true}
                            startDate={
                              excludeDatesget.length === 365 ? "" : startDate
                            }
                            endDate={endDate}
                            onChange={onChangedate}
                            //excludeDates={excludeDates.length === 0 ? '' : excludeDates}
                            excludeDates={excludeDatesget}
                            minDate={minSelectableDate}
                            maxDate={addMonths(new Date(), 12)}
                            renderDayContents={renderDayContents}
                            dateFormat="dd/MM/yyyy"
                            locale={i18n.resolvedLanguage}
                            monthsShown={isMobile ? 1 : 2}
                            onFocus={(e) => e.target.blur()}
                            ref={pickerRef}
                            withPortal
                            placeholderText={t("Rent.Whenever")}
                            required
                          />
                        </span>

                        <div className="inline-block align-top ml-2 mr-4 min-w-fit transition-all child:h-6 child:w-6 dark:cssvar-secondary-to-off-white">
                          <svg width="32" height="32" viewBox="0 0 32 32">
                            <g fill="none" fillRule="evenodd">
                              <path
                                d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                fill="var(--svg-color-secondary, #163435)"
                              ></path>
                            </g>
                          </svg>
                        </div>
                      </span>
                    </div>

                    <div className="grid gap-4 grid-cols-2 mt-2 w-full custom-pickup-return">
                      <div>
                        <span className="group outline-none">
                          <div className="relative flex items-center h-12 border-opacity-disabled  block w-full h-12 cursor-default">
                            <div className="flex items-center justify-between w-full opacity-disabled">
                              <svg class="w-7 h-7 watch" width="28px" height="28px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 7V12L14.5 13.5M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                              {/* Pickup */}
                              <Select
                                // autoFocus
                                backspaceRemovesValue={false}
                                hideSelectedOptions={false}
                                tabSelectsValue={false}
                                className="react-select-container w-full"
                                classNamePrefix="react-select"
                                options={options}
                                placeholder={t("Rent.Pickup")}
                                isSearchable={false}
                                value={options.filter(function (option) {
                                  return option.value === pickup;
                                })}
                                onChange={handleTime}
                              />
                              <svg
                                className="down-arrow w-7 h-7"
                                width="32"
                                height="32"
                                aria-hidden="true"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 32 32"
                                  fill="none"
                                  id="icon-chevron-down"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M16.0001 22.1256L23.6467 14.479C24.1173 14.009 24.1179 13.2445 23.6479 12.7739L23.2266 12.3532C22.7542 11.8809 21.9927 11.8833 21.5215 12.3544L16.0001 17.8759L10.4781 12.3544C10.0075 11.8833 9.24543 11.8809 8.77364 12.3532L8.35234 12.7739C7.88175 13.2445 7.88296 14.009 8.35354 14.479L16.0001 22.1256Z"
                                    fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"
                                  ></path>
                                </svg>
                              </svg>
                            </div>
                          </div>
                        </span>
                      </div>

                      <div>
                        <span className="group outline-none">
                          <div className="relative flex items-center h-12 block w-full h-12  rounded cursor-default">
                            <div className="flex items-center justify-between w-full opacity-disabled">
                              <svg class="w-7 h-7 watch" width="28px" height="28px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 7V12L14.5 13.5M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                              {/* Return */}
                              <Select
                                // autoFocus
                                backspaceRemovesValue={false}
                                hideSelectedOptions={false}
                                tabSelectsValue={false}
                                className="react-select-container w-full"
                                classNamePrefix="react-select"
                                options={options}
                                isSearchable={false}
                                // value={returntime}
                                value={options.filter(function (option) {
                                  return option.value === returntime;
                                })}
                                placeholder={t("Rent.Return")}
                                onChange={handleReturnTime}
                              />
                              <svg
                                className="down-arrow w-7 h-7"
                                width="32"
                                height="32"
                                aria-hidden="true"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 32 32"
                                  fill="none"
                                  id="icon-chevron-down"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M16.0001 22.1256L23.6467 14.479C24.1173 14.009 24.1179 13.2445 23.6479 12.7739L23.2266 12.3532C22.7542 11.8809 21.9927 11.8833 21.5215 12.3544L16.0001 17.8759L10.4781 12.3544C10.0075 11.8833 9.24543 11.8809 8.77364 12.3532L8.35234 12.7739C7.88175 13.2445 7.88296 14.009 8.35354 14.479L16.0001 22.1256Z"
                                    fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"
                                  ></path>
                                </svg>
                              </svg>
                            </div>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="overflow-child">

                    <div className="p-6 pl-8  mx-auto max-w-3xl bg-off-white ">
                      <div className="grid gap-y-4 font-light custom-grid">
                        {selectPackages.map((element) => {
                          return (
                            <>
                              <svg
                                className="w-7 h-7 cssvar-secondary-to-primary"
                                width="32"
                                height="32"
                              >
                                <svg
                                  viewBox="0 0 32 32"
                                  fill="none"
                                  id="icon-check-circle"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M27 16C27 22.0751 22.0751 27 16 27C9.92487 27 5 22.0751 5 16C5 9.92487 9.92487 5 16 5C22.0751 5 27 9.92487 27 16ZM10.7533 16.2181C11.0708 15.7706 11.6483 15.7117 12.0431 16.0865L14.2653 18.1963L19.6446 11.4402C19.9837 11.0144 20.5638 10.9928 20.9402 11.3921C21.3167 11.7914 21.347 12.4603 21.0079 12.8862L15.0481 20.3715C14.7223 20.7806 14.1708 20.8189 13.7915 20.4588L10.8933 17.7072C10.4984 17.3323 10.4358 16.6656 10.7533 16.2181Z"
                                    fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"
                                  ></path>
                                </svg>
                              </svg>
                              <div>
                                {element.title}
                                <br />
                                {count ? "" + count + " " + t("Rent.Nights") + " " : ""}
                              </div>
                              <div className="justify-self-center">
                                {" "}
                                <div className="justify-self-center"></div>
                              </div>
                              <div className="ml-auto justify-self-end font-normal price-font">
                                <NumericFormat
                                  value={
                                    count ? count * element.price : element.price
                                  }
                                  thousandSeparator={"."}
                                  decimalSeparator={","}
                                  displayType={"text"}
                                />
                                €
                              </div>
                            </>
                          );
                        })}
                        {CancellationSelected.map((element) => {
                          return (
                            <>
                              <svg
                                className="w-7 h-7 cssvar-secondary-to-primary"
                                width="32"
                                height="32"
                              >
                                <svg
                                  viewBox="0 0 32 32"
                                  fill="none"
                                  id="icon-check-circle"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M27 16C27 22.0751 22.0751 27 16 27C9.92487 27 5 22.0751 5 16C5 9.92487 9.92487 5 16 5C22.0751 5 27 9.92487 27 16ZM10.7533 16.2181C11.0708 15.7706 11.6483 15.7117 12.0431 16.0865L14.2653 18.1963L19.6446 11.4402C19.9837 11.0144 20.5638 10.9928 20.9402 11.3921C21.3167 11.7914 21.347 12.4603 21.0079 12.8862L15.0481 20.3715C14.7223 20.7806 14.1708 20.8189 13.7915 20.4588L10.8933 17.7072C10.4984 17.3323 10.4358 16.6656 10.7533 16.2181Z"
                                    fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"
                                  ></path>
                                </svg>
                              </svg>
                              <div>
                                {element.title}
                                <br />
                                {count ? "" + count + " " + t("Rent.Nights") + " " : ""}
                              </div>
                              <div className="justify-self-center">
                                {" "}
                                <div className="justify-self-center"></div>
                              </div>
                              <div className="ml-auto justify-self-end font-normal price-font">
                                {" "}
                                <NumericFormat
                                  value={
                                    count ? count * element.price : element.price
                                  }
                                  thousandSeparator={"."}
                                  decimalSeparator={","}
                                  displayType={"text"}
                                />
                                €
                              </div>
                            </>
                          );
                        })}
                        {ServiceSelected.map((element) => {
                          return (
                            <>
                              <svg
                                className="w-7 h-7 cssvar-secondary-to-primary"
                                width="32"
                                height="32"
                              >
                                <svg
                                  viewBox="0 0 32 32"
                                  fill="none"
                                  id="icon-check-circle"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M27 16C27 22.0751 22.0751 27 16 27C9.92487 27 5 22.0751 5 16C5 9.92487 9.92487 5 16 5C22.0751 5 27 9.92487 27 16ZM10.7533 16.2181C11.0708 15.7706 11.6483 15.7117 12.0431 16.0865L14.2653 18.1963L19.6446 11.4402C19.9837 11.0144 20.5638 10.9928 20.9402 11.3921C21.3167 11.7914 21.347 12.4603 21.0079 12.8862L15.0481 20.3715C14.7223 20.7806 14.1708 20.8189 13.7915 20.4588L10.8933 17.7072C10.4984 17.3323 10.4358 16.6656 10.7533 16.2181Z"
                                    fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"
                                  ></path>
                                </svg>
                              </svg>
                              <div>
                                {element.title} <br />
                                {count ? "" + count + " " + t("Rent.Nights") + " " : ""}
                              </div>
                              <div className="justify-self-center"> </div>
                              <div className="ml-auto justify-self-end font-normal price-font">
                                {" "}
                                <NumericFormat
                                  value={
                                    count ? count * element.price : element.price
                                  }
                                  thousandSeparator={"."}
                                  decimalSeparator={","}
                                  displayType={"text"}
                                />
                                €
                              </div>
                            </>
                          );
                        })}
                        {ServiceSelectedFix.map((element) => {
                          return (
                            <>
                              <svg
                                className="w-7 h-7 cssvar-secondary-to-primary"
                                width="32"
                                height="32"
                              >
                                <svg
                                  viewBox="0 0 32 32"
                                  fill="none"
                                  id="icon-check-circle"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M27 16C27 22.0751 22.0751 27 16 27C9.92487 27 5 22.0751 5 16C5 9.92487 9.92487 5 16 5C22.0751 5 27 9.92487 27 16ZM10.7533 16.2181C11.0708 15.7706 11.6483 15.7117 12.0431 16.0865L14.2653 18.1963L19.6446 11.4402C19.9837 11.0144 20.5638 10.9928 20.9402 11.3921C21.3167 11.7914 21.347 12.4603 21.0079 12.8862L15.0481 20.3715C14.7223 20.7806 14.1708 20.8189 13.7915 20.4588L10.8933 17.7072C10.4984 17.3323 10.4358 16.6656 10.7533 16.2181Z"
                                    fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"
                                  ></path>
                                </svg>
                              </svg>
                              <div>
                                {element.title} <br />
                              </div>
                              <div className="justify-self-center"> </div>
                              <div className="ml-auto justify-self-end font-normal price-font">
                                {" "}
                                <NumericFormat
                                  value={element.price}
                                  thousandSeparator={"."}
                                  decimalSeparator={","}
                                  displayType={"text"}
                                />
                                €
                              </div>
                            </>
                          );
                        })}

                        {AccessoriesSelected.map((element) => {
                          return (
                            <>
                              <svg
                                className="w-7 h-7 cssvar-secondary-to-primary"
                                width="32"
                                height="32"
                              >
                                <svg
                                  viewBox="0 0 32 32"
                                  fill="none"
                                  id="icon-check-circle"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M27 16C27 22.0751 22.0751 27 16 27C9.92487 27 5 22.0751 5 16C5 9.92487 9.92487 5 16 5C22.0751 5 27 9.92487 27 16ZM10.7533 16.2181C11.0708 15.7706 11.6483 15.7117 12.0431 16.0865L14.2653 18.1963L19.6446 11.4402C19.9837 11.0144 20.5638 10.9928 20.9402 11.3921C21.3167 11.7914 21.347 12.4603 21.0079 12.8862L15.0481 20.3715C14.7223 20.7806 14.1708 20.8189 13.7915 20.4588L10.8933 17.7072C10.4984 17.3323 10.4358 16.6656 10.7533 16.2181Z"
                                    fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"
                                  ></path>
                                </svg>
                              </svg>
                              <div>
                                {element.title}
                                <br /> {count ? "" + count + " " + t("Rent.Nights") + " " : ""}
                              </div>
                              <div className="justify-self-center"></div>
                              <div className="ml-auto justify-self-end font-normal price-font">
                                <NumericFormat
                                  value={
                                    count ? count * element.price : element.price
                                  }
                                  thousandSeparator={"."}
                                  decimalSeparator={","}
                                  displayType={"text"}
                                />
                                €
                              </div>
                            </>
                          );
                        })}
                        {AccessoriesSelectedFix.map((element) => {
                          return (
                            <>
                              <svg
                                className="w-7 h-7 cssvar-secondary-to-primary"
                                width="32"
                                height="32"
                              >
                                <svg
                                  viewBox="0 0 32 32"
                                  fill="none"
                                  id="icon-check-circle"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M27 16C27 22.0751 22.0751 27 16 27C9.92487 27 5 22.0751 5 16C5 9.92487 9.92487 5 16 5C22.0751 5 27 9.92487 27 16ZM10.7533 16.2181C11.0708 15.7706 11.6483 15.7117 12.0431 16.0865L14.2653 18.1963L19.6446 11.4402C19.9837 11.0144 20.5638 10.9928 20.9402 11.3921C21.3167 11.7914 21.347 12.4603 21.0079 12.8862L15.0481 20.3715C14.7223 20.7806 14.1708 20.8189 13.7915 20.4588L10.8933 17.7072C10.4984 17.3323 10.4358 16.6656 10.7533 16.2181Z"
                                    fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"
                                  ></path>
                                </svg>
                              </svg>
                              <div>
                                {element.title} <br />
                              </div>
                              <div className="justify-self-center"> </div>
                              <div className="ml-auto justify-self-end font-normal price-font">
                                <NumericFormat
                                  value={element.price}
                                  thousandSeparator={"."}
                                  decimalSeparator={","}
                                  displayType={"text"}
                                />
                                €
                              </div>
                            </>
                          );
                        })}

                        {serviceFee === "0" ? (
                          <></>
                        ) : (
                          <>
                            <svg
                              className="w-7 h-7 cssvar-secondary-to-primary"
                              width="32"
                              height="32"
                            >
                              <svg
                                viewBox="0 0 32 32"
                                fill="none"
                                id="icon-check-circle"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M27 16C27 22.0751 22.0751 27 16 27C9.92487 27 5 22.0751 5 16C5 9.92487 9.92487 5 16 5C22.0751 5 27 9.92487 27 16ZM10.7533 16.2181C11.0708 15.7706 11.6483 15.7117 12.0431 16.0865L14.2653 18.1963L19.6446 11.4402C19.9837 11.0144 20.5638 10.9928 20.9402 11.3921C21.3167 11.7914 21.347 12.4603 21.0079 12.8862L15.0481 20.3715C14.7223 20.7806 14.1708 20.8189 13.7915 20.4588L10.8933 17.7072C10.4984 17.3323 10.4358 16.6656 10.7533 16.2181Z"
                                  fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"
                                ></path>
                              </svg>
                            </svg>
                            <div>{t("Rent.Service fee")}</div>
                            <div className="justify-self-center"></div>
                            <div className="ml-auto justify-self-end font-normal price-font">
                              {serviceFee}€
                            </div>
                          </>
                        )}

                        <svg
                          className="w-7 h-7 cssvar-secondary-to-primary"
                          width="32"
                          height="32"
                        >
                          <svg
                            viewBox="0 0 32 32"
                            fill="none"
                            id="icon-check-circle"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M27 16C27 22.0751 22.0751 27 16 27C9.92487 27 5 22.0751 5 16C5 9.92487 9.92487 5 16 5C22.0751 5 27 9.92487 27 16ZM10.7533 16.2181C11.0708 15.7706 11.6483 15.7117 12.0431 16.0865L14.2653 18.1963L19.6446 11.4402C19.9837 11.0144 20.5638 10.9928 20.9402 11.3921C21.3167 11.7914 21.347 12.4603 21.0079 12.8862L15.0481 20.3715C14.7223 20.7806 14.1708 20.8189 13.7915 20.4588L10.8933 17.7072C10.4984 17.3323 10.4358 16.6656 10.7533 16.2181Z"
                              fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"
                            ></path>
                          </svg>
                        </svg>
                        <div>
                          {camper.title}
                          <br /> {count ? "" + count + " " + t("Rent.Nights") + " " : ""}
                        </div>
                        <div className="justify-self-center"></div>

                        {camper.camper_price &&
                          camper.camper_price.map((camper_price) => (
                            <div className="ml-auto">
                              {count ? (
                                camper_price.discount_price === 0 ? (
                                  <>
                                    <div className="justify-self-end font-normal price-font">
                                      <NumericFormat
                                        //value={count * camper_price.price}
                                        value={parseInt(CamperPriceTotal)}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        displayType={"text"}
                                      />
                                      €
                                    </div>
                                  </>
                                ) : (
                                  <div className="justify-self-end font-normal price-font">
                                    <NumericFormat
                                      /*value={
                                        count * camper_price.price -
                                        camper_price.discount_price
                                      }*/
                                      value={
                                        CamperPriceTotal -
                                        camper_price.discount_price
                                      }
                                      thousandSeparator={"."}
                                      decimalSeparator={","}
                                      displayType={"text"}
                                    />
                                    €
                                  </div>
                                )
                              ) : camper_price.discount_price === 0 ? (
                                <>
                                  <div className="justify-self-end font-normal price-font">
                                    {" "}

                                    {CamperPriceTotal === 0 ? camper_price.price : <NumericFormat
                                      //value={camper_price.price}
                                      value={CamperPriceTotal}
                                      thousandSeparator={"."}
                                      decimalSeparator={","}
                                      displayType={"text"}
                                    />}
                                    {/*{" "} */}
                                    €
                                  </div>
                                </>
                              ) : (
                                <div className="justify-self-end font-normal price-font">
                                  <NumericFormat
                                    /*value={
                                      camper_price.price -
                                      camper_price.discount_price
                                    }*/
                                    value={
                                      CamperPriceTotal -
                                      camper_price.discount_price
                                    }
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    displayType={"text"}
                                  />
                                  €
                                </div>
                              )}
                            </div>
                          ))}
                        {count >= 8 && count < 15 ? (
                          <>
                            <svg
                              className="w-7 h-7 cssvar-secondary-to-primary"
                              width="32"
                              height="32"
                            >
                              <svg
                                viewBox="0 0 32 32"
                                fill="none"
                                id="icon-check-circle"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M27 16C27 22.0751 22.0751 27 16 27C9.92487 27 5 22.0751 5 16C5 9.92487 9.92487 5 16 5C22.0751 5 27 9.92487 27 16ZM10.7533 16.2181C11.0708 15.7706 11.6483 15.7117 12.0431 16.0865L14.2653 18.1963L19.6446 11.4402C19.9837 11.0144 20.5638 10.9928 20.9402 11.3921C21.3167 11.7914 21.347 12.4603 21.0079 12.8862L15.0481 20.3715C14.7223 20.7806 14.1708 20.8189 13.7915 20.4588L10.8933 17.7072C10.4984 17.3323 10.4358 16.6656 10.7533 16.2181Z"
                                  fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"
                                ></path>
                              </svg>
                            </svg>
                            <div>{t("Rent.5% Long term discount")}</div>
                            <div className="justify-self-center"></div>
                            <div className="ml-auto">-{Math.round(discountprice)} €</div>
                          </>
                        ) : count >= 15 && count < 22 ? (
                          <>
                            <svg
                              className="w-7 h-7 cssvar-secondary-to-primary"
                              width="32"
                              height="32"
                            >
                              <svg
                                viewBox="0 0 32 32"
                                fill="none"
                                id="icon-check-circle"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M27 16C27 22.0751 22.0751 27 16 27C9.92487 27 5 22.0751 5 16C5 9.92487 9.92487 5 16 5C22.0751 5 27 9.92487 27 16ZM10.7533 16.2181C11.0708 15.7706 11.6483 15.7117 12.0431 16.0865L14.2653 18.1963L19.6446 11.4402C19.9837 11.0144 20.5638 10.9928 20.9402 11.3921C21.3167 11.7914 21.347 12.4603 21.0079 12.8862L15.0481 20.3715C14.7223 20.7806 14.1708 20.8189 13.7915 20.4588L10.8933 17.7072C10.4984 17.3323 10.4358 16.6656 10.7533 16.2181Z"
                                  fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"
                                ></path>
                              </svg>
                            </svg>
                            <div>{t("Rent.10% Long term discount")}</div>
                            <div className="justify-self-center"></div>
                            <div className="ml-auto">-{Math.round(discountprice)} €</div>
                          </>
                        ) : count >= 22 ? (
                          <>
                            <svg
                              className="w-7 h-7 cssvar-secondary-to-primary"
                              width="32"
                              height="32"
                            >
                              <svg
                                viewBox="0 0 32 32"
                                fill="none"
                                id="icon-check-circle"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M27 16C27 22.0751 22.0751 27 16 27C9.92487 27 5 22.0751 5 16C5 9.92487 9.92487 5 16 5C22.0751 5 27 9.92487 27 16ZM10.7533 16.2181C11.0708 15.7706 11.6483 15.7117 12.0431 16.0865L14.2653 18.1963L19.6446 11.4402C19.9837 11.0144 20.5638 10.9928 20.9402 11.3921C21.3167 11.7914 21.347 12.4603 21.0079 12.8862L15.0481 20.3715C14.7223 20.7806 14.1708 20.8189 13.7915 20.4588L10.8933 17.7072C10.4984 17.3323 10.4358 16.6656 10.7533 16.2181Z"
                                  fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"
                                ></path>
                              </svg>
                            </svg>
                            <div>{t("Rent.15% Long term discount")}</div>
                            <div className="justify-self-center"></div>
                            <div className="ml-auto">- {Math.round(discountprice)} €</div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>

                    <div className="flex flex-col items-center p-6 md:sticky z-10 bottom-0 mx-auto max-w-3xl bg-white xl:relative md:z-auto">
                      <div className="text-center items-center w-full text-2xl font-normal relative mb-2 pb-4">


                        {camper.camper_price &&
                          camper.camper_price.map((camper_price) => (
                            <div className="justify-self-end text-4xl font-normal price-font fw-600">
                              <div>
                                {count ? (
                                  <div>
                                    {selectPackages.length != 0 ? (
                                      <NumericFormat
                                        value={Math.round(selectPackages.reduce(
                                          (total, item) =>
                                            total +
                                            count * item.price +
                                            // CamperPriceTotal +
                                            count * TotalCancelvalue +
                                            count * TotalServicevalue +
                                            TotalServicevalueFix +
                                            count * TotalAccessoriesvalue +
                                            TotalAccessoriesvalueFix,
                                          camper_price.discount_price == 0
                                            ? parseInt(CamperPriceTotal) -
                                            discountprice +
                                            parseInt(serviceFee)
                                            : CamperPriceTotal -
                                            camper_price.discount_price -
                                            discountprice +
                                            parseInt(serviceFee)
                                        ))}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        displayType={"text"}
                                      />
                                    ) : CancellationSelected.length != 0 ? (
                                      <NumericFormat
                                        value={CancellationSelected.reduce(
                                          (total, item) =>
                                            total +
                                            count * item.price +
                                            count * TotalPackagevalue +
                                            count * TotalServicevalue +
                                            TotalServicevalueFix +
                                            count * TotalAccessoriesvalue +
                                            TotalAccessoriesvalueFix,
                                          camper_price.discount_price === 0
                                            ? count * camper_price.price -
                                            discountprice +
                                            parseInt(serviceFee)
                                            : count * camper_price.price -
                                            camper_price.discount_price -
                                            discountprice +
                                            parseInt(serviceFee)
                                        )}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        displayType={"text"}
                                      />
                                    ) : ServiceSelected.length != 0 ? (
                                      <NumericFormat
                                        value={ServiceSelected.reduce(
                                          (total, item) =>
                                            total +
                                            count * item.price +
                                            count * TotalPackagevalue +
                                            count * TotalCancelvalue +
                                            count * TotalAccessoriesvalue,
                                          camper_price.discount_price === 0
                                            ? count * camper_price.price -
                                            discountprice +
                                            parseInt(serviceFee)
                                            : count * camper_price.price -
                                            camper_price.discount_price -
                                            discountprice +
                                            parseInt(serviceFee)
                                        )}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        displayType={"text"}
                                      />
                                    ) : ServiceSelectedFix.length != 0 ? (
                                      <NumericFormat
                                        value={ServiceSelectedFix.reduce(
                                          (total, item) =>
                                            total +
                                            item.price +
                                            TotalPackagevalue +
                                            TotalCancelvalue +
                                            TotalAccessoriesvalue +
                                            TotalAccessoriesvalueFix +
                                            count * TotalServicevalue,
                                          camper_price.discount_price === 0
                                            ? camper_price.price +
                                            parseInt(serviceFee)
                                            : camper_price.price -
                                            camper_price.discount_price +
                                            parseInt(serviceFee)
                                        )}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        displayType={"text"}
                                      />
                                    ) : AccessoriesSelected.length != 0 ? (
                                      <NumericFormat
                                        value={AccessoriesSelected.reduce(
                                          (total, item) =>
                                            total +
                                            count * item.price +
                                            count * TotalPackagevalue +
                                            count * TotalCancelvalue +
                                            count * TotalServicevalue +
                                            TotalServicevalueFix,
                                          camper_price.discount_price === 0
                                            ? count * camper_price.price -
                                            discountprice +
                                            parseInt(serviceFee)
                                            : count * camper_price.price -
                                            camper_price.discount_price -
                                            discountprice +
                                            parseInt(serviceFee)
                                        )}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        displayType={"text"}
                                      />
                                    ) : AccessoriesSelectedFix.length != 0 ? (
                                      <NumericFormat
                                        value={AccessoriesSelectedFix.reduce(
                                          (total, item) =>
                                            total +
                                            item.price +
                                            TotalPackagevalue +
                                            TotalCancelvalue +
                                            TotalAccessoriesvalue +
                                            count * TotalServicevalue,
                                          camper_price.discount_price === 0
                                            ? camper_price.price +
                                            parseInt(serviceFee)
                                            : camper_price.price -
                                            camper_price.discount_price +
                                            parseInt(serviceFee)
                                        )}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        displayType={"text"}
                                      />
                                    ) : (
                                      //
                                      <NumericFormat
                                        value={selectPackages.reduce(
                                          (total, item) =>
                                            total + count * item.price,
                                          camper_price.discount_price === 0
                                            ? count * camper_price.price -
                                            discountprice +
                                            parseInt(serviceFee)
                                            : count * camper_price.price -
                                            camper_price.discount_price -
                                            discountprice +
                                            parseInt(serviceFee)
                                        )}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        displayType={"text"}
                                      />
                                    )}
                                    €
                                  </div>
                                ) : (
                                  <div>
                                    {selectPackages.length != 0 ? (
                                      <NumericFormat
                                        value={selectPackages.reduce(
                                          (total, item) =>
                                            total +
                                            item.price +
                                            TotalCancelvalue +
                                            TotalServicevalue +
                                            TotalServicevalueFix +
                                            TotalAccessoriesvalue +
                                            TotalAccessoriesvalueFix,
                                          camper_price.discount_price === 0
                                            ? camper_price.price +
                                            parseInt(serviceFee)
                                            : camper_price.price -
                                            camper_price.discount_price +
                                            parseInt(serviceFee)
                                        )}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        displayType={"text"}
                                      />
                                    ) : CancellationSelected.length != 0 ? (
                                      <NumericFormat
                                        value={CancellationSelected.reduce(
                                          (total, item) =>
                                            total +
                                            item.price +
                                            TotalPackagevalue +
                                            TotalServicevalue +
                                            TotalServicevalueFix +
                                            TotalAccessoriesvalue +
                                            TotalAccessoriesvalueFix,
                                          camper_price.discount_price === 0
                                            ? camper_price.price +
                                            parseInt(serviceFee)
                                            : camper_price.price -
                                            camper_price.discount_price +
                                            parseInt(serviceFee)
                                        )}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        displayType={"text"}
                                      />
                                    ) : ServiceSelected.length != 0 ? (
                                      <NumericFormat
                                        value={ServiceSelected.reduce(
                                          (total, item) =>
                                            total +
                                            item.price +
                                            TotalPackagevalue +
                                            TotalCancelvalue +
                                            TotalAccessoriesvalue +
                                            TotalAccessoriesvalueFix +
                                            TotalServicevalueFix,
                                          camper_price.discount_price === 0
                                            ? camper_price.price +
                                            parseInt(serviceFee)
                                            : camper_price.price -
                                            camper_price.discount_price +
                                            parseInt(serviceFee)
                                        )}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        displayType={"text"}
                                      />
                                    ) : ServiceSelectedFix.length != 0 ? (
                                      <NumericFormat
                                        value={ServiceSelectedFix.reduce(
                                          (total, item) =>
                                            total +
                                            item.price +
                                            TotalPackagevalue +
                                            TotalCancelvalue +
                                            TotalAccessoriesvalue +
                                            TotalAccessoriesvalueFix +
                                            count * TotalServicevalue,
                                          camper_price.discount_price === 0
                                            ? camper_price.price +
                                            parseInt(serviceFee)
                                            : camper_price.price -
                                            camper_price.discount_price +
                                            parseInt(serviceFee)
                                        )}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        displayType={"text"}
                                      />
                                    ) : AccessoriesSelected.length != 0 ? (
                                      <NumericFormat
                                        value={AccessoriesSelected.reduce(
                                          (total, item) =>
                                            total +
                                            item.price +
                                            TotalPackagevalue +
                                            TotalCancelvalue +
                                            TotalServicevalue +
                                            TotalServicevalueFix,
                                          camper_price.discount_price === 0
                                            ? camper_price.price +
                                            parseInt(serviceFee)
                                            : camper_price.price -
                                            camper_price.discount_price +
                                            parseInt(serviceFee)
                                        )}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        displayType={"text"}
                                      />
                                    ) : AccessoriesSelectedFix.length != 0 ? (
                                      <NumericFormat
                                        value={AccessoriesSelectedFix.reduce(
                                          (total, item) =>
                                            total +
                                            item.price +
                                            TotalPackagevalue +
                                            TotalCancelvalue +
                                            TotalAccessoriesvalue +
                                            count * TotalServicevalue,
                                          camper_price.discount_price === 0
                                            ? camper_price.price +
                                            parseInt(serviceFee)
                                            : camper_price.price -
                                            camper_price.discount_price +
                                            parseInt(serviceFee)
                                        )}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        displayType={"text"}
                                      />
                                    ) : (
                                      <NumericFormat
                                        value={selectPackages.reduce(
                                          (total, item) =>
                                            total +
                                            item.price +
                                            TotalCancelvalue +
                                            TotalServicevalue +
                                            TotalServicevalueFix +
                                            TotalAccessoriesvalue +
                                            TotalAccessoriesvalueFix,
                                          camper_price.discount_price === 0
                                            ? camper_price.price +
                                            parseInt(serviceFee)
                                            : camper_price.price -
                                            camper_price.discount_price +
                                            parseInt(serviceFee)
                                        )}
                                        thousandSeparator={"."}
                                        decimalSeparator={","}
                                        displayType={"text"}
                                      />
                                    )}{" "}
                                    €{" "}
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                        <div className="text-md font-normal">{t("Rent.Total incl. Vat")}</div>
                      </div>
                      {error ? (
                        <p
                          style={{ color: "red" }}
                          className="mb-2 flex w-full animate-wiggle items-center justify-between rounded bg-warning px-4 py-2 text-lg text-white"
                        >
                          {error}
                        </p>
                      ) : (
                        ""
                      )}

                      <Button
                        onClick={(e) => handleCheckout(camper.id)}
                        className="grid-cols1 inline-grid grid-rows-1 text-white bg-secondary hover:bg-primary pl-5 pr-2 w-full h-12 rounded"
                      >
                        {" "}
                        <span className="relative flex col-start-1 row-start-1 items-center justify-between w-full h-full transition-opacity duration-300">
                          <span className="transform transition-transform duration-300 translate-x-0">
                            <span className="text-lg text-custom-lg-sm font-body font-semibold text-custom-lg-sm">
                              {t("Voucher.Continue to checkout")}
                            </span>
                          </span>
                          <svg
                            className="w-7 h-7 cssvar-secondary-to-white pointer-events-none"
                            width="32"
                            height="32"
                          >
                            <svg
                              viewBox="0 0 32 32"
                              fill="none"
                              id="icon-chevron-right"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M22.1256 15.9999L14.479 8.35332C14.009 7.88273 13.2445 7.88213 12.7739 8.35211L12.3532 8.77342C11.8809 9.24581 11.8833 10.0073 12.3544 10.4785L17.8759 15.9999L12.3544 21.5219C11.8833 21.9925 11.8809 22.7546 12.3532 23.2264L12.7739 23.6477C13.2445 24.1182 14.009 24.117 14.479 23.6465L22.1256 15.9999Z"
                                fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"
                              ></path>
                            </svg>
                          </svg>
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </main>
      </div>
    </>
  );
};
export default RentConfiguration;
