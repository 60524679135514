import React from "react";
import { Link } from "react-router-dom";
import Select from 'react-select'
import { useTranslation } from "react-i18next";

const Explanatory = () => {
  const { i18n, t } = useTranslation();
  return (
    <>
          <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
            <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mask-image relative p-0 md:flex md:min-h-[400px] md:rounded-xl md:bg-transparent dark:md:bg-transparent justify-end">
              <Link className="w-full md:flex" to={'/explanatoryvideo'}>
                <div className="md:hidden">
                  <div className="overflow-hidden">
                    <div className="-ml-[5%] w-[110%] overflow-hidden rounded-b-[60%20%] px-[5%]">
                      <div className="relative">
                        <picture className="text-0">
                          <img
                            className="inline-block max-w-full object-cover dark:brightness-90 max-h-[40vh] sm-h-20 w-full group-hover/parent:scale-110 transition-all duration-300"
                            src="images/video.jpg"
                            alt="video_teaser"
                          />
                        </picture>
                      </div>
                    </div>
                  </div>
                </div>
                <picture className="text-0 absolute top-0 left-0 z-background hidden h-full w-full transition-all duration-300 group-hover/parent:scale-105 md:block md:group-hover/parent:scale-[103%]">
                  <img
                    className="inline-block max-w-full object-cover dark:brightness-90 h-full w-full"
                    src="images/video.jpg"
                    alt="video_teaser"
                  />
                </picture>
                <div className="relative -mt-10 mb-4 flex justify-center xs:mb-4 md:hidden">
                  <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark child:h-20 child:w-20">
                    <img
                      src="images/youtube-icon.svg"
                      alt="youtube-icon"
                    />
                  </div>
                </div>
                <div className="z-0 m-8 mt-0 flex flex-col text-center xs:mt-0 md:m-5 md:mt-5 md:min-h-[calc(100%-theme(spacing.5)*2)] md:w-full md:max-w-1/2 md:rounded-lg md:bg-white md:p-8 md:text-left md:shadow md:dark:bg-off-black-desat xl:max-w-[45%] 2xl:m-9 2xl:mt-9 2xl:min-h-[calc(100%-theme(spacing.9)*2)]">
                  <div className="mb-2 md:mb-6  flex items-center justify-center md:justify-start">
                    <div className="relative mr-4 hidden md:block">
                      <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark child:h-16 child:w-16">
                        <img
                          src="images/youtube-icon.svg"
                          alt="youtube-icon"
                        />
                      </div>
                    </div>
                    <p className="text-lg small-lg">{t("Contact.Camper Secrets Revealed")}</p>
                  </div>
                  <h2 className="whitespace-pre-line break-words mb-2 text-xl xs:mb-6">
                    {t("Contact.Explanatory camper videos")}
                  </h2>
                  <div className="mb-6 md:mb-6">
                    <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal image-box-text">
                    {t("Contact.How to extend the awning, and what to do if the heater won't start? Explore valuable tips, smart tricks, and exciting highlights about our campers right here.")}
                    </div>
                  </div>
                  <div className="group/button block disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark p-3 pl-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated w-full md:mt-auto">
                    <div className="flex h-full items-center justify-between">
                      <span className="flex">{t("Contact.To the Videos")}</span>
                      <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                        <svg width="32" height="32" viewBox="0 0 32 32">
                          <g fill="none" fillRule="evenodd">
                            <path
                              d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                              fill="var(--svg-color-secondary, #fff)"
                            ></path>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
    </>
  );
};

export default Explanatory;
