import client from "../client";
let lng = localStorage.getItem('lng') ? localStorage.getItem('lng'):'de';
export default {
  index: () =>client.get(`tripplan/index?lng=`+lng),
  show:(id)=>client.get(`voucher/show/${id}`),
  store: (userData) =>client.post('tripplanner/store', { json: userData }),
  update:(id,userData)=>client.post(`tripplannerpay/update/${id}`, { json: userData }),
  updatepaymentStatus: (stripedata) => client.post('tripplannerpay/updatepaymentStatus', { json: stripedata }),
  checkstripepayment: (id) => client.get(`tripplannerpay/checkstripepayment/${id}`),
  stripCheckout:(userData)=>client.post(`tripplannerpay/stripcheckout?lng=`+lng, { json: userData }),
  sendtripmail:(userData)=>client.post(`tripplannerpay/sendtripmail?lng=`+lng, { json: userData }),
};