import React, { useState, useRef, useEffect } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import Campersrent from "../../Components/Campersrent/Campersrent";
import { Link, useNavigate } from "react-router-dom";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import '@splidejs/react-splide/css/core';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ContactService from "../../Api/services/ContactService";
import Vanlife from "../../Components/Vanlife/Vanlife";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
const Tripplanner = () => {
  const [showResults, setShowResults] = React.useState(false)
  const { i18n, t } = useTranslation();
  const onClick = () => setShowResults(true)
  const onClose = () => setShowResults(false)
  const Questions = useRef(null);
  const testQuestions = useRef(null);
  const handleButtonClick = () => {
    window.scrollTo({
      top: Questions.current.offsetTop - 118,
      behavior: "smooth",
    })
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setTimeout(() =>{

    if (window.location.hash === '#testform') {
      window.scrollTo({
        top: testQuestions.current.offsetTop - 118,
        behavior: "smooth",
      })
    }
    else {
      console.log(window.location.hash)
    }
  },0);
  }, [])
  const [showResults1, setShowResults1] = React.useState(false)
  const onClick1 = () => setShowResults1(true)
  const onClose1 = () => setShowResults1(false)

  const [showResults2, setShowResults2] = React.useState(false)
  const onClick2 = () => setShowResults2(true)
  const onClose2 = () => setShowResults2(false)


  const CustomArrow = ({ onClick, direction }) => (
    <div className="custom-btn-group">
      <button className={`custom-arrow ${direction}`} onClick={onClick}>
        {direction === 'prev' ? '<' : '>'}
      </button>
    </div>
  );
  const [error, setError] = useState("")
  const [isFormSubmit, setisFormSubmit] = useState(false);
  const onSubmitForm = async (event) => {

    event.preventDefault();
    setError("")
    try {
      var userData = {

        firstName: event.target.firstName.value,
        camper_id: "",
        email: event.target.email.value,
        phone: event.target.phone.value,
        "bookingNumber": event.target.bookingNumber.value,
        reasonRequest: "",
        order_id: "",
        message: event.target.description.value,
        type: "Travel Planner",
        "lng": i18n.resolvedLanguage
      }
      if (event.target.firstName.value === "") {
        setError(t("Login.The first name field is required."))
      }
      else if (event.target.email.value === "") {
        setError(t("Login.The email field is required."))
      }
      else if (event.target.phone.value === "") {
        setError(t("Login.The phone field is required."))
      }
      else {
      let responseData = await ContactService.store(userData).json()
      if (responseData.status === true) {
        event.target.reset();
        setisFormSubmit(true);
      }
    }
    } catch (error) {
      console.log(error)
      if (error.name === 'HTTPError') {
        const errorJson = await error.response.json();
        setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
      }
    }


  };

  var camperslider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1273,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  var fiverow = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1273,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  var fourrow = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    draggable: true,
    touchMove: true,
    responsive: [
      {
        breakpoint: 1273,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,

        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  var tworow = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1273,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  var stepslider = {
    prevArrow: <CustomArrow direction="prev" />,
    nextArrow: <CustomArrow direction="next" />,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1273,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          arrows: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          infinite: false,
        },
      },
    ],
  };
  return (
    <>
      <Header />

      <div className="min-h-screen bg-off-white">

        <div className="text-center">
          <div className="overflow-hidden">
            <div className="-ml-[5%] w-[110%] overflow-hidden rounded-b-[60%20%] px-[5%]">
              <div className="relative">
                <div className="overflow-hidden">
                  <img
                    src="images/travel-planner-title-picture.jpeg"
                    alt="trip-planner-banner"
                    className="absolute max-h-full w-full scale-110 object-cover blur-lg dark:brightness-90 sm:aspect-hero-regular 3xl:aspect-hero-long aspect-square"
                  />
                </div>
                <picture className="flex max-h-[60vh] w-full text-0">
                  <img
                    className="z-0 max-h-full w-full object-cover dark:brightness-90 sm:aspect-hero-regular 3xl:aspect-hero-long aspect-square"
                    src="images/travel-planner-title-picture.jpeg"
                    alt="trip-planner-banner"
                    width="2552"
                    height="1286"
                  />
                </picture>
                <div className="absolute top-0 left-0 flex h-full w-full flex-col items-center justify-end">
                  <div className="pb-12 w-full bg-gradient-to-t from-black-pure/80 to-transparent pt-20 md:pt-14 xl:pt-32">
                    <h1 className="whitespace-pre-line break-words m-auto max-w-4xl px-8 text-xl md:text-2xl text-white drop-shadow-text dark:text-off-white xs:text-2xl xl:text-3xl mb-5 md:mb-8 xl:mb-12">
                      {t("trip.Travel Planner")}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark relative mx-auto -mt-12 child:h-24 child:w-24 2xl:-mt-14 2xl:child:h-28 2xl:child:w-28">
            <img className="" src="images/trip-planner-banner.svg" alt="trip-planner-banner" />
          </div>
        </div>

        <div className="mx-5 max-w-screen-xl xl-padded:mx-auto">

          <div className="pt-8 2xl:pt-8 pb-0 mx-auto flex max-w-4xl flex-col items-center text-center">
            <p className="mb-4 whitespace-pre-line text-md"><span className="mobile-none">{t("trip.Stress-free")}</span> {t("trip.Vacation planning, tailored for you")} </p>
            <h2 className="whitespace-pre-line break-words text-xl md:text-2xl mb-6"> {t("trip.Your personal travel Assistant")} </h2>
            <p className="whitespace-pre-line text-2md font-normal md:text-lg-paragraph md:font-normal small-heading-text text-20">
              {t("trip.We create your dream route")} <br />{t("trip.From selected places to campsites, and activities - all exclusively")}<br /> {t("trip.tailored for you.")} <br /><span className="mobile-none">{t("trip.Your road trip should be perfect, and you still have the freedom.")}<br /> {t("trip.No fixed bookings.")}</span>
            </p>
          </div>

          <div className="">
            <div className="pt-2 pb-2 2xl:pb-2">
              <div className="md:mx-5 max-w-screen-xl xl-padded:mx-auto pt-5 pb-8">

                <Slider className="camper-slider" {...camperslider}>

                  <div className="rounded bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-6 2xl:mt-14 2xl:h-[calc(100%-3.5rem)] pt-0 pb-7">
                    <div className="flex justify-center align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-12 mb-5 child:h-24 child:w-24 2xl:-mt-14 2xl:child:h-28 2xl:child:w-28">
                      <img
                        src="images/trip-planner-box-img.svg"
                        alt="services-icon"
                      />
                    </div>
                    <h3 className="mb-3 whitespace-pre-line text-center text-lg">
                      {t("trip.Get inspired - Stay flexible")}
                    </h3>
                    <p className="whitespace-pre-line text-center">
                      {t("trip.Discover exciting campsites and fascinating activities on your route You can even adjust the travel route")} <br />{t("trip.You can even adjust the travel route spontaneously during your vacation to respond to unforeseen events or new interests.")}
                    </p>
                  </div>


                  <div className="rounded bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-6 2xl:mt-14 2xl:h-[calc(100%-3.5rem)] pt-0 pb-7">
                    <div className="flex justify-center align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-12 mb-5 child:h-24 child:w-24 2xl:-mt-14 2xl:child:h-28 2xl:child:w-28">
                      <img
                        src="images/trip-planner-box-img-1.svg"
                        alt="services-icon"
                      />
                    </div>
                    <h3 className="mb-3 whitespace-pre-line text-center text-lg">
                      {t("trip.Cultural insights1")}
                    </h3>
                    <p className="whitespace-pre-line text-center">
                      {t("trip.We promise that your journey will also include cultural experiences that provide you with a deeper insight into the region.")}
                    </p>
                  </div>


                  <div className="rounded bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-6 2xl:mt-14 2xl:h-[calc(100%-3.5rem)] pt-0 pb-7">
                    <div className="flex justify-center align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-12 mb-5 child:h-24 child:w-24 2xl:-mt-14 2xl:child:h-28 2xl:child:w-28">
                      <img
                        src="images/trip-planner-box-img-2.svg"
                        alt="services-icon"
                      />
                    </div>
                    <h3 className="mb-3 whitespace-pre-line text-center text-lg">
                      {t("trip.Tailored for you")}
                    </h3>
                    <p className="whitespace-pre-line text-center">
                      {t("trip.Leave the details to us and enjoy your precious vacation time without the stress of planning.")}<br />
                      {t("trip.Share your preferences with us, and we will design your route so you can create unforgettable memories.")}

                    </p>
                  </div>

                </Slider>
              </div>
            </div>
            <div className="flex justify-center pt-2 2xl:pt-2 pb-4 2xl:pb-4">
              <Link to={'/campers'} className="group/button disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark p-3 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated pl-3 py-2 inline-block">
                <div className="flex h-full items-center justify-between">
                  {/* <div className="inline-block align-top mr-2 rounded-full bg-white p-1 child:h-7 child:w-7 dark:bg-off-white">
                    <img src="images/trip-booking.svg" alt="trip-booking" />
                  </div> */}
                  <span className="flex">{t("trip.Book Travel Planner now")}</span>
                  <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path
                          d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                          fill="var(--svg-color-secondary, #163435)"
                        ></path>
                      </g>
                    </svg>
                  </div>
                </div>
              </Link>
            </div>

          </div>

          <div className="pt-8 2xl:pt-8 pb-12 2xl:pb-12">
            <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mask-image relative p-0 md:flex md:min-h-[400px] md:rounded-xl md:bg-transparent dark:md:bg-transparent justify-end">
              <Link className="w-full md:flex justify-end">
                <div className="md:hidden">
                  <div className="overflow-hidden">
                    <div className="-ml-[5%] w-[110%] overflow-hidden rounded-b-[60%20%] px-[5%]">
                      <div className="relative">
                        <picture className="text-0">
                          <img className="inline-block max-w-full object-cover dark:brightness-90 max-h-[40vh] w-full group-hover/parent:scale-110 transition-all duration-300"
                            src="images/DSC3990.jpg"
                            alt="routenservice"
                          />
                        </picture>
                      </div>
                    </div>
                  </div>
                </div>
                <picture className="text-0 absolute top-0 left-0 z-background hidden h-full w-full transition-all duration-300 group-hover/parent:scale-105 md:block md:group-hover/parent:scale-[103%]">
                  <img className="inline-block max-w-full object-cover dark:brightness-90 h-full w-full"
                    src="images/DSC3990.jpg"
                    alt="routenservice"
                  />
                </picture>
                <div className="relative -mt-10 mb-4 flex justify-center xs:mb-4 md:hidden">
                  <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark child:h-20 child:w-20">
                    <img
                      src="images/trip-planner-side-img.svg"
                      alt="selection-icon"
                    />
                  </div>
                </div>
                <div className="z-0 m-6 mt-0 flex flex-col text-center xs:mt-0 md:m-5 md:mt-5 md:min-h-[calc(100%-theme(spacing.5)*2)] md:w-full md:max-w-1/2 md:rounded-lg md:bg-white md:p-8 md:text-left md:shadow md:dark:bg-off-black-desat xl:max-w-[45%] 2xl:m-9 2xl:mt-9 2xl:min-h-[calc(100%-theme(spacing.9)*2)]">
                  <div className="md:mb-6 mb-2 flex items-center justify-center md:justify-start">
                    <div className="relative mr-4 hidden md:block">
                      <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark child:h-16 child:w-16">
                        <img
                          src="images/trip-planner-side-img.svg"
                          alt="selection-icon"
                        />
                      </div>
                    </div>
                    <p className="text-lg small-lg">{t("trip.Travel Route Example")}</p>
                  </div>
                  <h2 className="whitespace-pre-line break-words md:mb-4 mb-2 text-xl xs:mb-6">
                    {t("trip.Explore Your Dream Journey")}
                  </h2>
                  <div className="mb-4 md:mb-6">
                    <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal image-box-text">
                      {t("trip.We create custom travel plans with exciting activities, carefully selected campsites, and cultural highlights Just tell us which region you want to explore, and receive your sample travel itinerary directly in your inbox!")}
                    </div>
                    <div className="mobile-none min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>

                  </div>
                  <div className="group/button block disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark p-3 pl-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated w-full md:mt-auto">
                    <div className="flex h-full items-center justify-between" onClick={() => handleButtonClick()} >
                      <span className="flex">{t("trip.View an Example")}</span>
                      <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                        <svg width="32" height="32" viewBox="0 0 32 32">
                          <g fill="none" fillRule="evenodd">
                            <path
                              d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                              fill="var(--svg-color-secondary, #fff)"
                            ></path>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>

        </div>

        <div className="mx-5 max-w-screen-xl xl-padded:mx-auto bg-primary rounded-lg md:px-8 px-8">

          <div className="pt-8 2xl:pt-12 pb-0 mx-auto flex max-w-3xl flex-col items-center text-center">
            <p className="mobile-none mb-4 whitespace-pre-line text-md text-white"> {t("trip.Step by Step to Your Dream Journey")} </p>
            <h2 className="text-orange whitespace-pre-line break-words text-xl md:text-2xl mb-4 newline-text-german">{t("trip.Your Customized Travel Plan in Five Steps")} </h2>

          </div>

          <div className="pt-0 pb-0 md:pb-10 2xl:pb-12 step-slider">
            <div className="md:mx-5 max-w-full xl-padded:mx-auto pt-0 md:pt-5 pb-12 px-0 md:px-5">
              <Slider className="camper-slider arrow-white" {...stepslider}>

                <div className="rounded bg-white md:p-6 p-3 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-6 2xl:mt-14 2xl:h-[calc(100%-3.5rem)] pt-0 md:pb-7 pb-2">
                  <div className="flex justify-center align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-12 mb-5 child:h-24 child:w-24 2xl:-mt-14 2xl:child:h-28 2xl:child:w-28">
                    <img
                      className="travel-slider-img"
                      src="images/calander-icon.svg"
                      alt="services-icon"
                    />
                  </div>
                  <h3 className="mb-3 whitespace-pre-line text-center text-lg">
                    <span className='price-font'>1.</span>&nbsp; {t("trip.Book Your Travel Planner")}
                  </h3>
                  <p className="whitespace-pre-line text-center">
                    {t("trip.When booking your camper, you can add the Travel Planner for only 17€ per day or book it separately later.")}
                  </p>
                  <div className="text-center">
                    <Link to={"/campers"} className="group/button disabled:opacity-50 border border-transparent text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated p-1 rounded-full mt-4 mb-4 inline-block px-4">
                      <div className="flex h-full items-center">
                        {/* <div className="inline-block align-top mr-2 rounded-full bg-white child:h-7 child:w-7 dark:bg-off-white">
                    <img width={40} src="images/services-svg-2.svg" alt="trip-booking" />
                  </div> */}
                        <span className="flex">{t("trip.View Camper")}</span>
                        <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black ml-auto">
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g fill="none" fillRule="evenodd">
                              <path
                                d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                                fill="var(--svg-color-secondary, #163435)"
                              ></path>
                            </g>
                          </svg>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>


                <div className="rounded bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-6 2xl:mt-14 2xl:h-[calc(100%-3.5rem)] pt-0 md:pb-7 pb-2">
                  <div className="flex justify-center align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-12 mb-5 child:h-24 child:w-24 2xl:-mt-14 2xl:child:h-28 2xl:child:w-28">
                    <img
                      src="images/trip-planner-service-img.svg"
                      alt="services-icon"
                    />
                  </div>
                  <h3 className="mb-3 whitespace-pre-line text-center text-lg">
                    <span className='price-font'>2.</span>&nbsp;{t("trip.Share your preferences with us!")}
                  </h3>
                  <p className="whitespace-pre-line text-center">
                    {t("trip.Let us know what matters to you! Fill out our simple questionnaire so we can get to know your travel preferences and ensure your journey aligns perfectly with your vision.")}
                  </p>
                </div>


                <div className="rounded bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-6 2xl:mt-14 2xl:h-[calc(100%-3.5rem)] pt-0 md:pb-7 pb-2">
                  <div className="flex justify-center align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-12 mb-5 child:h-24 child:w-24 2xl:-mt-14 2xl:child:h-28 2xl:child:w-28">
                    <img
                      src="images/trip-planner-service-img1.svg"
                      alt="services-icon"
                    />
                  </div>
                  <h3 className="mb-3 whitespace-pre-line text-center text-lg">
                    <span className='price-font'>3.</span>&nbsp; {t("trip.Leave the Planning to Us")}
                  </h3>
                  <p className="whitespace-pre-line text-center">
                    {t("trip.Our travel planners will create a tailored itinerary for you, featuring the best cultural attractions, local activities, and ideal camping or parking spots.")}
                  </p>
                </div>

                <div className="rounded bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-6 2xl:mt-14 2xl:h-[calc(100%-3.5rem)] pt-0 md:pb-7 pb-2">
                  <div className="flex justify-center align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-12 mb-5 child:h-24 child:w-24 2xl:-mt-14 2xl:child:h-28 2xl:child:w-28">
                    <img
                      src="images/Adventure.info.svg"
                      alt="services-icon"
                    />
                  </div>
                  <h3 className="mb-3 whitespace-pre-line text-center text-lg">
                    <span className='price-font'>4.</span>&nbsp; {t("trip.Your Personalized Itinerary")}
                  </h3>
                  <p className="whitespace-pre-line text-center">
                    {t("trip.Receive your detailed itinerary as a PDF in your inbox and get ready for an unforgettable adventure!")}
                  </p>
                  <div className="text-center">
                    <Link to={"/campers"} className="group/button disabled:opacity-50 border border-transparent text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated p-1 rounded-full mt-4 inline-block px-4">
                      <div className="flex h-full items-center">
                        {/* <div className="inline-block align-top mr-2 rounded-full bg-white child:h-7 child:w-7 dark:bg-off-white">
                    <img width={40} src="images/calander-icon.svg" alt="trip-booking" />
                  </div> */}
                        <span className="flex">{t("trip.Book Travel Planner")}</span>
                        <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black ml-auto">
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g fill="none" fillRule="evenodd">
                              <path
                                d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                                fill="var(--svg-color-secondary, #163435)"
                              ></path>
                            </g>
                          </svg>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>


                <div className="rounded bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-6 2xl:mt-14 2xl:h-[calc(100%-3.5rem)] pt-0 md:pb-7 pb-2">
                  <div className="flex justify-center align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-12 mb-5 child:h-24 child:w-24 2xl:-mt-14 2xl:child:h-28 2xl:child:w-28">
                    <img
                      src="images/contact-banner-svg.svg"
                      alt="services-icon"
                    />
                  </div>
                  <h3 className="mb-3 whitespace-pre-line text-center text-lg">
                    <span className='price-font'>5.</span>&nbsp; {t("trip.On-the-Road Support")}
                  </h3>
                  <p className="whitespace-pre-line text-center" >
                    {t("trip.If you have any questions about your route while you're on the road, feel free to reach out to us anytime, and we'll be ready to assist you!")}
                  </p>

                </div>

              </Slider>
            </div>
          </div>

        </div>

        <div className="mx-5 max-w-screen-xl xl-padded:mx-auto">


          <div className="pt-8 2xl:pt-12 pb-0 mx-auto flex max-w-3xl flex-col items-center text-center">
            <p className="mb-2 whitespace-pre-line text-md">{t("trip.Sit back and relax")} </p>
            <h2 className="whitespace-pre-line break-words text-xl md:text-2xl">{t("trip.Your carefree package")} </h2>
          </div>

          <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12">
            <div className="">
              <div className="pt-8 2xl:pt-8 md:pb-10 pb-0 2xl:pb-12">
                <div className="relative mx-auto max-w-3xl">
                  <div className="mask-image relative h-0 w-full overflow-hidden rounded bg-off-white pt-[56.25%] shadow dark:bg-black dark:shadow-dark 2xs:rounded-lg">
                    <iframe className="absolute top-0 left-0 aspect-video h-full w-full" src="https://www.youtube.com/embed/Sr-3K8r-nE0?si=YigKuympCpZn0DS1" frameborder="0"></iframe>
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="pt-8 2xl:pt-8 pb-4 md:pb-10 2xl:pb-12 text-left mx-auto max-w-3xl">
                <h3 className="my-5 first:mt-0 break-words text-lg md:text-xl">{t("trip.Our travel planner includes")}:</h3>
                <ul className="my-5 first:mt-0 pl-8 md:pl-10 text-left list-disc text-sm 2xl:text-2md 2xl:font-normal">
                  <li className="pl-1 md:pl-2"><strong>{t("trip.Travel inspiration")}:</strong> {t("trip.Get inspired by exciting destinations and activities.")}</li>
                  <li className="pl-1 md:pl-2"><strong>{t("trip.Personalization")}:</strong> {t("trip.Customize your route according to your preferences.")}</li>
                  <li className="pl-1 md:pl-2"><strong>{t("trip.Cultural insights1")}: </strong>{t("trip.Receive detailed insights into cultural treasures.")}</li>
                  <li className="pl-1 md:pl-2"><strong>{t("trip.Top-rated campsites")}:</strong> {t("trip.No need for a cumbersome search; we provide top-rated recommendations.")}</li>
                  <li className="pl-1 md:pl-2"><strong>{t("trip.Activities")}:</strong> {t("trip.Exciting activities for every taste.")}</li>
                  <li className="pl-1 md:pl-2"><strong>{t("trip.Across Europe")}:</strong> {t("trip.We plan your route wherever you want to go.")}</li>
                  <li className="pl-1 md:pl-2"><strong>{t("trip.Customer service")}:</strong> {t("trip.We're here to assist you with any questions!")}</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="pb-0 mx-auto flex max-w-3xl flex-col items-center text-center">
            <p className="mb-2 whitespace-pre-line text-md">{t("trip.This is how it's done")}</p>
            <h2 className="whitespace-pre-line break-words text-xl md:text-2xl">{t("trip.Travel Planner booking")}</h2>
          </div>

          <div className=" 2xl:pt-2 pt-2 pb-4 relative">
            <div className="md:mx-5 max-w-screen-xl xl-padded:mx-auto pt-5 pb-4">
              <Slider className="camper-slider" {...tworow}>

                <Link to={"/campers"}>
                  <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent group flex h-full flex-col p-4 hover:shadow-elevated hover:dark:shadow-dark-elevated">
                    <div className="mask-image shrink-0 overflow-hidden rounded-sm 2xs:rounded-md">
                      <picture className="text-0 h-full w-full">
                        <img
                          className="inline-block max-w-full object-cover dark:brightness-90 aspect-square max-h-[270px] w-full group-hover:scale-110 transition-all duration-300"
                          src="images/IMG_2414.jpg"
                          alt="davidferk_portugal_t6_beach"
                        />
                      </picture>
                    </div>
                    <div className="flex h-full flex-col items-center justify-center px-4 text-center">
                      <p className="whitespace-pre-line text-lg mt-4">
                        {t("trip.Book Travel Planner & Camper")}
                      </p>
                      <p className="mt-3 whitespace-pre-line line-clamp-5">
                        {t("trip.I also want to rent a Camper!")}
                      </p>
                      <div className="mt-auto">
                        <div className="group/button disabled:opacity-50 border border-transparent text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated p-1 rounded-full mt-4 inline-block px-4">
                          <div className="flex h-full items-center justify-center">
                            <span className="flex">
                              {t("trip.Let's go!")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>


                <Link to={"/trip-planner-seperated"}>
                  <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent group flex h-full flex-col p-4 hover:shadow-elevated hover:dark:shadow-dark-elevated">

                    <div className="flex h-full flex-col items-center justify-center px-4 text-center">
                      <p className="whitespace-pre-line text-lg mt-4">
                        {t("trip.Book Travel Planner separately")}
                      </p>
                      <p className="mt-3 whitespace-pre-line line-clamp-5">
                        {t("trip.I have my own Camper!")}
                      </p>
                      <div className="mt-auto mb-5">
                        <div className="group/button disabled:opacity-50 border border-transparent text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated p-1 rounded-full mt-4 inline-block px-4">
                          <div className="flex h-full items-center justify-center">
                            <span className="flex">
                              {t("trip.Let's go!")}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mask-image shrink-0 overflow-hidden rounded-sm 2xs:rounded-md">
                      <picture className="text-0 h-full w-full">
                        <img
                          className="object-postion-custom  inline-block max-w-full object-cover dark:brightness-90 aspect-square max-h-[270px] w-full group-hover:scale-110 transition-all duration-300"
                          src="images/tamas-tuzes-katai-rEn-AdBr3Ig-unsplash.jpg"
                          alt="davidferk_routenservice_portugal_t6_beach_sommer"
                        />
                      </picture>
                    </div>
                  </div>
                </Link>

              </Slider>

              <div className="trip-compare absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <h2 class="whitespace-pre-line break-words text-xl md:text-1xl vs">VS</h2>
              </div>
            </div>
          </div>

          <div className="">
            <div className="md:pt-0 pt-4 pb-0 mx-auto flex max-w-3xl flex-col items-center text-center">
              <p className="mb-2 whitespace-pre-line text-md">{t("trip.What Our Customers Say")}</p>
              <h2 className="whitespace-pre-line break-words text-xl md:text-2xl mb-2 md:mb-6">{t("trip.Customer Feedback")} </h2>
              <p className="md:mb-4 mb-0 whitespace-pre-line text-md small-heading-text text-20">{t("trip.We asked our customers what they think of our travel itineraries, and here's the feedback")}: </p>
            </div>

            <div className="pb-4">
              <div className="md:mx-5 max-w-screen-xl xl-padded:mx-auto pt-3 pb-3">
                <Slider className="camper-slider" {...fourrow}>

                  <Link>
                    <div className="relative overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent group flex h-full flex-col p-4 hover:shadow-elevated hover:dark:shadow-dark-elevated" >
                      <div className="mask-image shrink-0 overflow-hidden rounded-sm 2xs:rounded-md">
                        <picture className="text-0 h-full w-full">
                          <img
                            className="inline-block max-w-full object-cover dark:brightness-90 aspect-square max-h-[270px] w-full group-hover:scale-110 transition-all duration-300"
                            src="images/review-timo-ulla.jpg"
                            alt="clara"
                          />
                        </picture>
                      </div>
                      <div className="flex h-full flex-col items-center justify-center px-4 text-center">
                        <p className="whitespace-pre-line text-lg mt-4">
                          {t("trip.Timo & Ulla")}
                        </p>
                        <p className="mb-50 mt-3 whitespace-pre-line line-clamp-5 sm-mb-20">
                          "{t("trip.The planning was fantastic We wanted a romantic vacation in Italy, and that's exactly what")}
                        </p>
                      </div>
                      <div className="plus-icon white-icon" onClick={onClick}>+</div>
                    </div>
                  </Link>


                  <Link>
                    <div className="relative overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent group flex h-full flex-col p-4 hover:shadow-elevated hover:dark:shadow-dark-elevated">
                      <div className="mask-image shrink-0 overflow-hidden rounded-sm 2xs:rounded-md">
                        <picture className="text-0 h-full w-full">
                          <img
                            className="inline-block max-w-full object-cover dark:brightness-90 aspect-square max-h-[270px] w-full group-hover:scale-110 transition-all duration-300"
                            src="images/review-felix-maxi.png"
                            alt="our-camper"
                          />
                        </picture>
                      </div>
                      <div className="flex h-full flex-col items-center justify-center px-4 text-center">
                        <p className="whitespace-pre-line text-lg mt-4">
                          Maxi & Felix
                        </p>
                        <p className="mb-50 mt-3 whitespace-pre-line line-clamp-5 sm-mb-20">
                          "{t("trip.We went on a guys' road trip without a specific destination in mind The route that Born To Camp planned for us was top-notch Amazing camping spots in nature, hiking")}
                        </p>
                      </div>
                      <div className="plus-icon white-icon" onClick={onClick1}>+</div>
                    </div>
                  </Link>


                  <Link>
                    <div className="relative overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent group flex h-full flex-col p-4 hover:shadow-elevated hover:dark:shadow-dark-elevated">
                      <div className="mask-image shrink-0 overflow-hidden rounded-sm 2xs:rounded-md">
                        <picture className="text-0 h-full w-full">
                          <img
                            className="inline-block max-w-full object-cover dark:brightness-90 aspect-square max-h-[270px] w-full group-hover:scale-110 transition-all duration-300"
                            src="images/review-Lufwig.jpg"
                            alt="davidferk_routenservice_portugal_t6_beach_sommer"
                          />
                        </picture>
                      </div>
                      <div className="flex h-full flex-col items-center justify-center px-4 text-center">
                        <p className="whitespace-pre-line text-lg mt-4">
                          Ludwig
                        </p>
                        <p className="mb-50 mt-3 whitespace-pre-line line-clamp-5 sm-mb-20">
                          {/* "{t("trip.I wanted to get away from everything for 2 months, and the destination was unclear. When I called Born To Camp, Luca immediately offered to adjust the pricing structure based on the duration, and we worked together to create a great route. Thanks for the flexibility and assistance!")} */}
                          "{t("trip.I wanted to get away from everything for 2 months, and the destination was unclear. When I called Born To Camp, Luca immediately")}
                        </p>
                      </div>
                      <div className="plus-icon white-icon" onClick={onClick2}>+</div>
                    </div>
                  </Link>



                </Slider>
                {showResults ?
                  <div className="top-equipped-modal">
                    <div className="top-equipped-modal-body">
                      <div className="text-right">
                        <button onClick={onClose} className="close-top-eq-mdal">X</button>
                      </div>
                      <div className="pt-8 pb-8 text-left mx-auto max-w-3xl">
                        <h3 className="my-5 first:mt-0 break-words text-lg md:text-xl">
                          Timo & Ulla
                        </h3>
                        <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                          "{t("trip.The planning was fantastic We wanted a romantic vacation in Italy, and that's exactly what")}"
                        </div>
                        <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>

                        <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                        <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        </div>
                      </div>
                    </div>
                  </div>
                  : null}

                {showResults1 ?
                  <div className="top-equipped-modal">
                    <div className="top-equipped-modal-body">
                      <div className="text-right">
                        <button onClick={onClose1} className="close-top-eq-mdal">X</button>
                      </div>
                      <div className="pt-8 pb-8 text-left mx-auto max-w-3xl">
                        <h3 className="my-5 first:mt-0 break-words text-lg md:text-xl">
                          Maxi & Felix
                        </h3>
                        <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                          "{t("trip.We went on a guys' road trip without a specific destination in mind The route that Born To Camp planned for us was top-notch Amazing camping spots in nature, hiking, fishing It was just awesome.")}"
                        </div>
                        <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>

                      </div>
                    </div>
                  </div>

                  : null}

                {showResults2 ?
                  <div className="top-equipped-modal">
                    <div className="top-equipped-modal-body">
                      <div className="text-right">
                        <button onClick={onClose2} className="close-top-eq-mdal">X</button>
                      </div>
                      <div className="pt-8 pb-8 text-left mx-auto max-w-3xl">
                        <h3 className="my-5 first:mt-0 break-words text-lg md:text-xl">
                          Ludwig
                        </h3>
                        <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                          "{t("trip.I wanted to get away from everything for 2 months, and the destination was unclear. When I called Born To Camp, Luca immediately offered to adjust the pricing structure based on the duration, and we worked together to create a great route. Thanks for the flexibility and assistance!")}"
                        </div>
                        <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>

                      </div>
                    </div>
                  </div>

                  : null}
              </div>
            </div>
          </div>

          <div className="pt-4 2xl:pt-4 pb-0 mx-auto flex max-w-3xl flex-col items-center text-center" >
            <h2 className="faq-small-title whitespace-pre-line break-words text-xl md:text-2xl md:mb-6 mb-2"> {t("trip.Frequently Asked Questions about")}<br /> {t("trip.the Travel Planner")}
              <span className="mobile-none"></span> </h2>
            <p className="whitespace-pre-line small-heading-text text-20">{t("trip.Here you'll find answers to frequently asked questions about our Travel Planner")}:</p>
          </div>

          <div className="md:pt-8 pt-5 2xl:pt-12 pb-10 2xl:pb-12">
            <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg mx-auto p-0 max-w-3xl">
              <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                <summary className="flex cursor-pointer select-none items-center justify-between py-6 px-7 2xl:py-8 2xl:px-9">
                  <h3>
                    <div className="flex items-center">
                      <p className="text-md 2xl:text-lg text-18">{t("trip.What exactly does the Travel Planner offer me?")}</p>
                    </div>
                  </h3>
                  <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-8 md:child:w-8">
                    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                      <g fill="none" fillRule="evenodd">
                        <path d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z" fill="var(--svg-color-secondary, #163435)"></path>
                      </g>
                    </svg>
                  </div>
                </summary>
                <div className="px-7 2xl:px-9">
                  <div className="">
                    <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("trip.We've learned from some of our customers that they don't want the hassle of constant planning during their vacations")}
                      </div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("trip.During peak season, we recommend booking your campsites and activities well in advance.")}
                      </div>

                    </div>
                  </div>
                </div>
              </details>
              <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                <summary className="flex cursor-pointer select-none items-center justify-between py-6 px-7 2xl:py-8 2xl:px-9">
                  <h3>
                    <div className="flex items-center">
                      <p className="text-md 2xl:text-lg text-18">{t("trip.What do I get with the Travel Planner?")}</p>
                    </div>
                  </h3>
                  <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-8 md:child:w-8">
                    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                      <g fill="none" fillRule="evenodd">
                        <path d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z" fill="var(--svg-color-secondary, #163435)"></path>
                      </g>
                    </svg>
                  </div>
                </summary>
                <div className="px-7 2xl:px-9">
                  <div className="">
                    <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("trip.Our Travel Planner includes")}:
                      </div>

                      <ul className="my-5 first:mt-0 pl-8 md:pl-10 text-left list-disc text-sm 2xl:text-2md 2xl:font-normal">
                        <li className="pl-1 md:pl-2">{t("trip.Circular Route Planning")}:&nbsp;{t("trip.Avoid retracing your steps. Our routes offer you a beautiful circuit where you'll always discover something new.")}</li>
                        <li className="pl-1 md:pl-2">{t("trip.Personalization")}:&nbsp;{t("trip.Customize your route based on your preferences")}.</li>
                        <li className="pl-1 md:pl-2">{t("trip.Cultural insights1")}:&nbsp;{t("trip.Gain detailed insights into cultural treasures")}.</li>
                        <li className="pl-1 md:pl-2">{t("trip.Top Camping Spots")}:&nbsp;{t("trip.No more tedious searching; we provide top-rated recommendations directly")}.</li>
                        <li className="pl-1 md:pl-2">{t("trip.Activities")}:&nbsp;{t("trip.Exciting activities for every taste")}.</li>
                        <li className="pl-1 md:pl-2">{t("trip.Across Europe")}:&nbsp;{t("trip.We plan your route wherever you want to go")}.</li>
                        <li className="pl-1 md:pl-2">{t("trip.Customer Support")}:&nbsp;{t("trip.We're here for you if you have any questions")}!</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </details>
              <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                <summary className="flex cursor-pointer select-none items-center justify-between py-6 px-7 2xl:py-8 2xl:px-9">
                  <h3>
                    <div className="flex items-center">
                      <p className="text-md 2xl:text-lg text-18">{t("trip.How many days after booking will I receive my itinerary")}?</p>
                    </div>
                  </h3>
                  <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-8 md:child:w-8">
                    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                      <g fill="none" fillRule="evenodd">
                        <path d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z" fill="var(--svg-color-secondary, #163435)"></path>
                      </g>
                    </svg>
                  </div>
                </summary>
                <div className="px-7 2xl:px-9">
                  <div className="">
                    <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("trip.Typically, you'll receive your itinerary 5-10 days after booking. For last-minute bookings (at least 4 days before departure), you'll receive your itinerary no later than 24 hours before departure")}.
                      </div>
                    </div>
                  </div>
                </div>
              </details>
              <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                <summary className="flex cursor-pointer select-none items-center justify-between py-6 px-7 2xl:py-8 2xl:px-9">
                  <h3>
                    <div className="flex items-center">
                      <p className="text-md 2xl:text-lg text-18">{t("trip.What's the process after booking")}?</p>
                    </div>
                  </h3>
                  <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-8 md:child:w-8">
                    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                      <g fill="none" fillRule="evenodd">
                        <path d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z" fill="var(--svg-color-secondary, #163435)"></path>
                      </g>
                    </svg>
                  </div>
                </summary>
                <div className="px-7 2xl:px-9">
                  <div className="">
                    <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("trip.After booking, one of our travel planners will get in touch with you. You'll receive a questionnaire by email to learn more about your vacation expectations. If necessary, we'll clarify any open questions over the phone to tailor your route to your wishes. Normally, your customized itinerary will be created and provided to you within 5-10 days after booking")}.
                      </div>

                    </div>
                  </div>
                </div>
              </details>
              <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                <summary className="flex cursor-pointer select-none items-center justify-between py-6 px-7 2xl:py-8 2xl:px-9">
                  <h3>
                    <div className="flex items-center">
                      <p className="text-md 2xl:text-lg text-18">{t("trip.Do you book campsites for me")}? </p>
                    </div>
                  </h3>
                  <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-8 md:child:w-8">
                    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                      <g fill="none" fillRule="evenodd">
                        <path d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z" fill="var(--svg-color-secondary, #163435)"></path>
                      </g>
                    </svg>
                  </div>
                </summary>
                <div className="px-7 2xl:px-9">
                  <div className="">
                    <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("trip.We do not book pitches or campsites for you. You stay flexible! For the peak season, we recommend booking your pitch as early as possible. And remember, the sooner you book our Travel Planner, the sooner you'll receive your itinerary")}.
                      </div>
                    </div>
                  </div>
                </div>
              </details>
              <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                <summary className="flex cursor-pointer select-none items-center justify-between py-6 px-7 2xl:py-8 2xl:px-9">
                  <h3>
                    <div className="flex items-center">
                      <p className="text-md 2xl:text-lg text-18">{t("trip.I have some questions; can I contact you")}? </p>
                    </div>
                  </h3>
                  <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-8 md:child:w-8">
                    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                      <g fill="none" fillRule="evenodd">
                        <path d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z" fill="var(--svg-color-secondary, #163435)"></path>
                      </g>
                    </svg>
                  </div>
                </summary>
                <div className="px-7 2xl:px-9">
                  <div className="">
                    <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("trip.Certainly, you can reach us via email at")} <Link to="mailto:info@borntocamp.de">info@borntocamp.de</Link>,&nbsp;{t("trip.through our contact form, or by phone at the following number")} <Link to="tel:+49 (0) 163 2937354">+49 (0) 163 2937354 {t("trip.contact")}</Link>
                      </div>


                    </div>
                  </div>
                </div>
              </details>
              <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                <summary className="flex cursor-pointer select-none items-center justify-between py-6 px-7 2xl:py-8 2xl:px-9">
                  <h3>
                    <div className="flex items-center">
                      <p className="text-md 2xl:text-lg text-18">{t("trip.How last-minute can I book the Travel Planner with you")}? </p>
                    </div>
                  </h3>
                  <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-8 md:child:w-8">
                    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                      <g fill="none" fillRule="evenodd">
                        <path d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z" fill="var(--svg-color-secondary, #163435)"></path>
                      </g>
                    </svg>
                  </div>
                </summary>
                <div className="px-7 2xl:px-9">
                  <div className="">
                    <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("trip.To create a travel plan for you, there must be at least 4 days remaining until departure")}.
                      </div>
                    </div>
                  </div>
                </div>
              </details>
              <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                <summary className="flex cursor-pointer select-none items-center justify-between py-6 px-7 2xl:py-8 2xl:px-9">
                  <h3>
                    <div className="flex items-center">
                      <p className="text-md 2xl:text-lg text-18">{t("trip.What is the cost of the Travel Planner")}? </p>
                    </div>
                  </h3>
                  <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-8 md:child:w-8">
                    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                      <g fill="none" fillRule="evenodd">
                        <path d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z" fill="var(--svg-color-secondary, #163435)"></path>
                      </g>
                    </svg>
                  </div>
                </summary>
                <div className="px-7 2xl:px-9">
                  <div className="">
                    <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("trip.The Travel Planner costs €17 per day. If you need specific assistance with route planning for longterm rentals (15 days or more) or for your camper subscription, please don't hesitate to contact us. We can determine a customized total price for you")}.
                      </div>
                    </div>
                  </div>
                </div>
              </details>
              <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                <summary className="flex cursor-pointer select-none items-center justify-between py-6 px-7 2xl:py-8 2xl:px-9">
                  <h3>
                    <div className="flex items-center">
                      <p className="text-md 2xl:text-lg text-18">{t("trip.Can I book the Travel Planner later or for a trip with my own camper")}?</p>
                    </div>
                  </h3>
                  <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-8 md:child:w-8">
                    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                      <g fill="none" fillRule="evenodd">
                        <path d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z" fill="var(--svg-color-secondary, #163435)"></path>
                      </g>
                    </svg>
                  </div>
                </summary>
                <div className="px-7 2xl:px-9">
                  <div className="">
                    <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("trip.Yes, you have the option to book our Travel Planner either as a standalone product or add it to your camper rental up to 4 days before departure")}.
                      </div>

                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>

                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("trip.However, our strong recommendation is to book the Travel Planner as early as possible, especially during peak season. It's possible that the top camping spots we recommend may be fully booked. (Please note that we do not check availability when creating the route.)")}
                      </div>
                    </div>
                  </div>
                </div>
              </details>
              <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                <summary className="flex cursor-pointer select-none items-center justify-between py-6 px-7 2xl:py-8 2xl:px-9">
                  <h3>
                    <div className="flex items-center">
                      <p className="text-md 2xl:text-lg text-18">{t("trip.What if I want to change my route")}?</p>
                    </div>
                  </h3>
                  <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-8 md:child:w-8">
                    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                      <g fill="none" fillRule="evenodd">
                        <path d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z" fill="var(--svg-color-secondary, #163435)"></path>
                      </g>
                    </svg>
                  </div>
                </summary>
                <div className="px-7 2xl:px-9">
                  <div className="">
                    <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("trip.Your travel route can be adjusted flexibly. If your plans change and you'd like a new route from us, we're ready to create one for you. However, please be aware that last-minute re-planning right before your departure cannot be guaranteed, and fees may apply for creating a new route")}.
                      </div>
                    </div>
                  </div>
                </div>
              </details>
              <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                <summary className="flex cursor-pointer select-none items-center justify-between py-6 px-7 2xl:py-8 2xl:px-9">
                  <h3>
                    <div className="flex items-center">
                      <p className="text-md 2xl:text-lg text-18">{t("trip.What's the advantage of the Travel Planner")}?</p>
                    </div>
                  </h3>
                  <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-8 md:child:w-8">
                    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                      <g fill="none" fillRule="evenodd">
                        <path d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z" fill="var(--svg-color-secondary, #163435)"></path>
                      </g>
                    </svg>
                  </div>
                </summary>
                <div className="px-7 2xl:px-9">
                  <div className="">
                    <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                      <ul className="my-5 first:mt-0 pl-8 md:pl-10 text-left list-disc text-sm 2xl:text-2md 2xl:font-normal">
                        <li className="pl-1 md:pl-2">{t("trip.No pre-travel planning stress")}.</li>
                        <li className="pl-1 md:pl-2">{t("trip.An adventurous experience Be surprised by our activities and destinations")}.</li>
                        <li className="pl-1 md:pl-2">{t("trip.No time-consuming on-site research; just enjoy")}.</li>
                        <li className="pl-1 md:pl-2">{t("trip.Camping spots, activities, and destinations tailored to your preferences")}.</li>

                      </ul>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("trip.Having a customized route in your pocket turns your vacation into a treasure hunt. Every place is a surprise, stress-free, and exactly the way you love it")}.</div>
                    </div>
                  </div>
                </div>
              </details>
              <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                <summary className="flex cursor-pointer select-none items-center justify-between py-6 px-7 2xl:py-8 2xl:px-9">
                  <h3>
                    <div className="flex items-center">
                      <p className="text-md 2xl:text-lg text-18">{t("trip.Is it possible to cancel the Travel Planner")}? </p>
                    </div>
                  </h3>
                  <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-8 md:child:w-8">
                    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                      <g fill="none" fillRule="evenodd">
                        <path d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z" fill="var(--svg-color-secondary, #163435)"></path>
                      </g>
                    </svg>
                  </div>
                </summary>
                <div className="px-7 2xl:px-9">
                  <div className="">
                    <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">


                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("trip.Cancellation of the Travel Planner is free as long as you haven't returned the questionnaire for individual route planning. After sending back the questionnaire, cancellation will incur charges")}.</div>

                    </div>
                  </div>
                </div>
              </details>

            </div>
          </div>

          <div className="pb-0 mx-auto flex max-w-4xl flex-col items-center text-center">
            <p className="mb-2 whitespace-pre-line text-md">{t("trip.Ideas for Travel Destinations")}</p>
            <h2 className="whitespace-pre-line break-words text-xl md:text-2xl md:mb-4 mb-2">{t("trip.Travel Inspiration")}</h2>
            <p className="whitespace-pre-line text-2md font-normal md:text-lg-paragraph md:font-normal small-heading-text text-20">{t("trip.Are you unsure about what information to provide for your customized travel plan?")}</p>
            <p className="whitespace-pre-line text-2md font-normal md:text-lg-paragraph md:font-normal small-heading-text text-20">{t("trip.Visit our blog and get inspired!")}</p>

          </div>

          <div className="pt-0 2xl:pt-0 pb-0 2xl:pb-8 mobile-padding-0 tablet-padding-0">
            <div className="md:mx-5 max-w-screen-xl xl-padded:mx-auto md:pt-5 pt-0 pb-8 mobile-padding-0">
              <Vanlife />
            </div>
          </div>
          <div className="travel-contact-wrap" ref={Questions} >
            <div className="xl:pt-4 pt-0 pb-8 md:pb-16 2xl:pb-24"  ref={testQuestions} >
              <div className="mx-auto flex max-w-3xl flex-col items-center">
                <div className="inline-flex items-center justify-center rounded-full border border-primary bg-white p-1 dark:border-primary-dark dark:bg-off-white mb-5 md:mb-6">
                  <div className="inline-block align-top child:h-10 child:w-10">
                    <img src="images/request-icon.svg" alt="request-icon" />
                  </div>
                </div>
                <h2 className="whitespace-pre-line break-words mb-4 text-center md:mb-5 text-xl md:text-2xl br-none">{t("trip.Questions about")} <br />{t("trip.Travel Planner?")}</h2>
                <div className="mb-4 text-center md:mb-5">
                  <div className="min-h-[30px] break-words text-2md font-normal md:text-lg-paragraph md:font-normal small-heading-text br-none text-20">{t("trip.Feel free to use our contact form to ask any questions about our Travel Planner!")} <br />{t("trip.We’re happy to help you!")} </div>

                  <div className="br-none min-h-[30px] break-words text-2md font-normal md:text-lg-paragraph md:font-normal small-heading-text">
                    E-Mail: &nbsp;
                    <Link
                      to={"mailto:info@borntocamp.de"}
                      className="text-primary underline hover:text-off-black dark:text-primary-light hover:dark:text-off-white text-2md font-normal md:text-lg-paragraph md:font-normal small-heading-text"
                    >
                      info@borntocamp.de
                    </Link>
                    &nbsp; &nbsp;&nbsp;
                    <br />
                    {t("sale.Phone")}: &nbsp;
                    <Link
                      to={"tel:0163 2937354"}
                      className="text-primary underline hover:text-off-black dark:text-primary-light hover:dark:text-off-white text-2md font-normal md:text-lg-paragraph md:font-normal small-heading-text"
                    >
                      0163 2937354
                    </Link>
                  </div>
                </div>
                {isFormSubmit ? (
                  <>
                    <div className="opacity-100 scale-100 success-msg text-center">
                      <div className="inline-flex items-center justify-center rounded-full orange-border bg-white p-1 dark:bg-off-white">
                        <div className="inline-block align-top">
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g fill="none" fill-rule="evenodd">
                              <path
                                d="m21.906 9.488-8.547 9.56-3.45-3.096a1.757 1.757 0 0 0-2.563.209c-.64.792-.547 1.98.21 2.659l4.764 4.274c.735.66 1.833.602 2.496-.13l9.73-10.869a1.954 1.954 0 0 0-.069-2.665 1.758 1.758 0 0 0-2.571.058z"
                                fill="var(--svg-color-secondary, #163435)"
                              ></path>
                            </g>
                          </svg>
                        </div>
                      </div>
                      <h2 className="mt-4 max-w-lg text-lg-paragraph">
                        {t("Contact.Thanks for your message!")}<br /> {t("Contact.You will hear back from us soon.")}
                      </h2>
                    </div>
                  </>
                ) : (

                  <form className="flex w-full flex-col items-center" onSubmit={onSubmitForm}  >
                    <div className="grid grid-cols-1 gap-x-4 md:grid-cols-2 w-full">
                      <div className="transition-all md:mb-4 mb-1 col-span-2">
                        <div className="relative z-foreground mb-4 flex h-[60px] items-center rounded border bg-white text-black transition-all focus-within:border-primary focus:border-primary dark:bg-off-black-desat dark:text-off-white dark:focus-within:border-primary-dark border-transparent shadow dark:shadow-dark">
                          <div className="inline-block align-top absolute pl-3 child:h-7 child:w-7 dark:cssvar-secondary-to-off-white">
                            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fillRule="evenodd" clipRule="evenodd" d="M16 14.6527C13.6105 14.6527 11.6736 12.7158 11.6736 10.3264C11.6736 7.93688 13.6105 6 16 6C18.3895 6 20.3264 7.93688 20.3264 10.3264C20.3264 12.7158 18.3895 14.6527 16 14.6527Z" fill="var(--svg-color-secondary, #163435)"></path>
                              <path fillRule="evenodd" clipRule="evenodd" d="M16.1565 15.9742C12.2188 15.8883 9 19.0545 9 22.9726C9 23.4621 9.05258 23.9383 9.15042 24.3979C9.26224 24.9247 9.72616 25.3021 10.2643 25.3021H21.7361C22.2693 25.3021 22.7352 24.9324 22.847 24.4112C23.0091 23.6528 23.05 22.8501 22.9329 22.0181C22.4596 18.6482 19.5587 16.0487 16.1565 15.9742Z" fill="var(--svg-color-secondary, #163435)"></path>
                            </svg>
                          </div>
                          <div className="peer flex w-full flex-col">
                            <input className="peer w-full rounded bg-transparent pt-2 pb-1 pl-12 text-md outline-none focus:pr-6 pr-6" type="text" name="firstName" placeholder={t("sale.First & Last Name*")} />
                          </div>
                        </div>
                      </div>

                      <div  className="transition-all md:mb-4 mb-1 col-span-2 md:col-span-1">
                        <div className="relative z-foreground mb-4 flex h-[60px] items-center rounded border bg-white text-black transition-all focus-within:border-primary focus:border-primary dark:bg-off-black-desat dark:text-off-white dark:focus-within:border-primary-dark border-transparent shadow dark:shadow-dark">
                          <div className="inline-block align-top absolute pl-3 child:h-7 child:w-7 dark:cssvar-secondary-to-off-white">
                            <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                              <title>icon_contact_mail</title>
                              <g id="icon_contact_mail" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g id="Group" transform="translate(5.000000, 7.700000)" fill="var(--svg-color-secondary, #163435)">
                                  <path d="M17.1541722,0 C18.5243618,0 19.2165846,0.133674373 19.9368674,0.518885858 C20.6000248,0.873546296 21.1264537,1.39997522 21.4811141,2.06313259 C21.8663256,2.78341544 22,3.47563815 22,4.8458278 L21.9981828,11.9648343 C21.9810588,13.1622375 21.8406449,13.810058 21.4811141,14.482322 C21.1264537,15.1454793 20.6000248,15.6719082 19.9368674,16.0265687 C19.2165846,16.4117802 18.5243618,16.5454545 17.1541722,16.5454545 L4.8458278,16.5454545 C3.47563815,16.5454545 2.78341544,16.4117802 2.06313259,16.0265687 C1.39997522,15.6719082 0.873546296,15.1454793 0.518885858,14.482322 C0.133674373,13.7620391 0,13.0698164 0,11.6996267 L0,4.8458278 C0,3.47563815 0.133674373,2.78341544 0.518885858,2.06313259 C0.873546296,1.39997522 1.39997522,0.873546296 2.06313259,0.518885858 C2.78341544,0.133674373 3.47563815,0 4.8458278,0 Z M20,5.563 L11,12.1092247 L2,5.564 L2,11.6996267 L2.00467769,12.1173697 C2.02375106,12.8767631 2.10330814,13.2040417 2.28251225,13.5391242 C2.45077309,13.8537447 2.69170989,14.0946815 3.0063303,14.2629423 C3.31348932,14.4272127 3.61409085,14.507752 4.24698433,14.5347258 L4.6271902,14.5443078 L17.3728098,14.5443078 C17.4423488,14.5435357 17.5086323,14.5423663 17.5719151,14.5407769 L17.9176389,14.5260186 C18.4406182,14.4924165 18.7144342,14.4122791 18.9936697,14.2629423 C19.3082901,14.0946815 19.5492269,13.8537447 19.7174877,13.5391242 C19.9265592,13.1481946 20,12.767887 20,11.6996267 L20,5.563 Z M17.1541722,2 L4.8458278,2 L4.42808487,2.00467769 C3.66869148,2.02375106 3.34141286,2.10330814 3.0063303,2.28251225 C2.69170989,2.45077309 2.45077309,2.69170989 2.28251225,3.0063303 C2.24438729,3.07761778 2.21077244,3.14855205 2.1812906,3.22336911 L10.999,9.63500008 L19.8187094,3.22336911 C19.7892276,3.14855205 19.7556127,3.07761778 19.7174877,3.0063303 C19.5492269,2.69170989 19.3082901,2.45077309 18.9936697,2.28251225 C18.6585871,2.10330814 18.3313085,2.02375106 17.5719151,2.00467769 L17.1541722,2 Z" id="Combined-Shape"></path>
                                </g>
                              </g>
                            </svg>
                          </div>
                          <div className="peer flex w-full flex-col">
                            <input className="peer w-full rounded bg-transparent pt-2 pb-1 pl-12 text-md outline-none focus:pr-6 pr-6" type="email" name="email" placeholder={t("Contact.E-mail*")} />
                          </div>
                        </div>
                      </div>

                      <div className="transition-all md:mb-4 mb-1 col-span-2 md:col-span-1" >
                        <div className="relative z-foreground mb-4 flex h-[60px] items-center rounded border bg-white text-black transition-all focus-within:border-primary focus:border-primary dark:bg-off-black-desat dark:text-off-white dark:focus-within:border-primary-dark border-transparent shadow dark:shadow-dark">
                          <div className="inline-block align-top absolute pl-3 child:h-7 child:w-7 dark:cssvar-secondary-to-off-white">
                            <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                              <g fill="none" fillRule="evenodd">
                                <path d="M12.004 8.139c-.171-.569-.342-1.024-.91-1.08L10.014 7C8.875 6.945 7 8.651 7 11.493c.057 4.947 7.448 13.02 12.338 13.476 2.9.284 4.72-1.422 4.776-2.616V21.33c.057-.569-.398-.796-.966-1.024-1.08-.398-2.16-.91-3.241-1.308-.455-.17-.853.114-1.137.285-.398.34-.683.568-1.024.966-.284.228-.568.341-.853.057-2.445-1.25-4.435-3.411-5.458-5.913-.227-.341-.114-.625.17-.853a16.92 16.92 0 0 0 1.024-.967c.227-.227.512-.625.398-1.08-.341-1.08-.682-2.274-1.023-3.354z" fill="var(--svg-color-secondary, #163435)"></path>
                              </g>
                            </svg>
                          </div>
                          <div className="peer flex w-full flex-col">
                            <input className="peer w-full rounded bg-transparent pt-2 pb-1 pl-12 text-md outline-none focus:pr-6 pr-6" type="tel" name="phone" id="" placeholder={t("Contact.Phone number")} />
                          </div>
                        </div>
                      </div>

                      <div className="transition-all md:mb-4 mb-1 col-span-2">
                        <div className="relative z-foreground mb-4 flex h-[60px] items-center rounded border bg-white text-black transition-all focus-within:border-primary focus:border-primary dark:bg-off-black-desat dark:text-off-white dark:focus-within:border-primary-dark border-transparent shadow dark:shadow-dark">
                          <div className="inline-block align-top absolute pl-3 child:h-7 child:w-7 dark:cssvar-secondary-to-off-white">
                            <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                              <title>icon/actions_info</title>
                              <g id="icon/actions_info" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <path d="M15.0627781,13.2833484 C14.2292216,13.2833484 13.4095577,13.2833484 12.5843367,13.2833484 C12.4040026,13.2849033 12.2246588,13.3085077 12.0508606,13.3535621 C11.8387141,13.3950229 11.676741,13.5559636 11.6451964,13.7566407 C11.5865132,13.9746677 11.7037133,14.2006319 11.9230486,14.2923451 C12.190053,14.4092135 12.476874,14.4813856 12.7704977,14.5055866 C13.5429267,14.5393932 13.8541211,14.8566551 13.7374232,15.6004 C13.5206985,16.9916712 13.2289538,18.3725403 12.9788868,19.7612109 C12.809397,20.6973934 12.6848928,21.2550492 12.5843367,22.2403035 C12.4530963,23.5262052 13.2109841,24.7401952 14.1368811,25.0131061 C15.0627781,25.286017 15.724825,25.286017 16.3214484,25.2794864 C17.5999796,25.2780159 18.8212793,24.7831633 19.6973522,23.9116198 C19.8564682,23.7743898 19.9759403,23.6017433 20.0446674,23.409722 C20.0782267,23.2419815 20.0359721,23.0687264 19.9279695,22.9312287 C19.7974575,22.8547704 19.6367149,22.8384803 19.4917416,22.8870201 C19.2639028,22.9572338 19.0610707,23.1028622 18.838789,23.1938799 C18.5790019,23.3073326 18.3070186,23.3945099 18.0274607,23.4539306 C17.8020089,23.5057029 17.5636393,23.4622801 17.3753928,23.335146 C17.1871463,23.2080119 17.0679843,23.0099721 17.0494211,22.7934019 C17.0026623,22.551429 16.9942363,22.3043299 17.0244144,22.0600589 C17.1188841,21.4567414 17.2522531,20.8586248 17.3689511,20.2605082 C17.6857025,18.6291236 18.0070849,16.9968722 18.3330981,15.3637539 C18.4220108,14.9164667 18.5720509,14.4769811 18.4720242,14.0062893 C18.4564927,13.79721 18.3517318,13.6027725 18.1813424,13.4667803 C18.010953,13.3307881 17.7892668,13.2646798 17.5662261,13.2833484 C16.7326696,13.2833484 15.8963346,13.2833484 15.0627781,13.2833484 Z" id="Path" fill="var(--svg-color-secondary, #163435)"></path>
                                <path d="M16.5742939,6.28268183 C15.5498224,6.23775316 14.6000794,6.81738803 14.1716262,7.74904688 C13.743173,8.68070573 13.9212618,9.77901022 14.6221567,10.5275466 C15.3230516,11.276083 16.4072909,11.52591 17.3650741,11.159561 C18.3228574,10.793212 18.9636082,9.88358197 18.9860507,8.85837141 C19.0250803,7.48444098 17.9479291,6.33655519 16.5742939,6.28823887 L16.5742939,6.28268183 Z" id="Path" fill="var(--svg-color-secondary, #163435)"></path>
                              </g>
                            </svg>
                          </div>
                          <div className="peer flex w-full flex-col">
                            <input className="peer w-full rounded bg-transparent pt-2 pb-1 pl-12 text-md outline-none focus:pr-6 pr-6" type="text" name="bookingNumber" placeholder={t("trip.Booking number")} />
                          </div>
                        </div>
                      </div>

                      <div className="transition-all md:mb-4 mb-1 col-span-2">
                        <div className="relative z-foreground flex w-full flex-col">
                          <textarea rows="5" className="peer relative mb-4 w-full items-center rounded border bg-white p-4 pt-2 text-md text-black outline-none transition-all focus:border-primary dark:bg-off-black-desat dark:text-off-white dark:focus-within:border-primary-dark border-transparent shadow dark:shadow-dark" name="description" id="" placeholder={t("sale.Your message")}>
                          </textarea>

                        </div>
                      </div>

                      <div className="mt-2 mb-4 col-span-2">
                        <div className="relative flex">
                          <input type="checkbox" className="peer h-7 w-7 min-w-[28px] cursor-pointer appearance-none rounded-sm border border-grey bg-white outline-none transition-all checked:border-primary checked:bg-primary focus:border-primary dark:border-grey-dark dark:bg-off-black-desat" name="" id="" />
                          <div className="align-top pointer-events-none absolute left-0.5 top-0.5 hidden cssvar-secondary-to-white peer-checked:block child:h-6 child:w-6 dark:cssvar-secondary-to-off-white">
                            <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                              <g fill="none" fillRule="evenodd">
                                <path d="m21.906 9.488-8.547 9.56-3.45-3.096a1.757 1.757 0 0 0-2.563.209c-.64.792-.547 1.98.21 2.659l4.764 4.274c.735.66 1.833.602 2.496-.13l9.73-10.869a1.954 1.954 0 0 0-.069-2.665 1.758 1.758 0 0 0-2.571.058z" fill="var(--svg-color-secondary, #163435)"></path>
                              </g>
                            </svg>
                          </div>
                          <label className="ml-3 cursor-pointer" htmlFor="">
                            <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                              {t("sale.I have read the")}&nbsp;<Link className="text-primary underline hover:text-off-black dark:text-primary-light hover:dark:text-off-white">{t("sale.privacy")}</Link>&nbsp;{t("sale.statement")}
                            </div>
                          </label>
                        </div>
                        <div className="relative bottom-4 overflow-hidden bg-warning text-center text-xs font-medium text-black transition-all mt-1 rounded max-h-0"></div>
                      </div>



                    </div>
                    <div className="text-danger fs-12" >    {error ? <p style={{ color: "red" }} className="mb-2 flex w-full animate-wiggle items-center justify-between rounded bg-warning px-4 py-2 text-lg text-white">{error}</p> : ''}</div>
                    <button type="submit" className="group/button block disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark p-3 pl-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated pr-5 mt-2 w-full md:w-auto md:min-w-[50%]">
                      <div className="flex h-full items-center justify-center text-2md">
                        <span className="flex">{t("sale.Send")}</span>
                      </div>
                    </button>

                    <p className="mt-5 text-xs">{t("sale.*Mandatory field")}</p>
                  </form>
                )}
              </div>
            </div>
          </div>


        </div>

      </div>

      <Footer />
    </>
  );
};

export default Tripplanner;
