import React, { useState, useEffect } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import Slider from "react-slick";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-tabs/style/react-tabs.css';
import { t } from 'i18next';
const Blog = () => {

  return (
    <>
      <Header />

      <div className="min-h-screen bg-off-white">
        <div className="pb-5 text-center"></div>

        <div className="mx-5 max-w-screen-xl xl-padded:mx-auto privacy-container">
          <div className="hero-padding sm-pt-x  text-center mx-auto max-w-3xl">
            <h1 className="my-5 first:mt-0 break-words text-xl md:text-3xl">
              {t("footer.Privacy Statement1")}
            </h1>
          </div>
          <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
            <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg mx-auto p-0 max-w-3xl legal-information">
              <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                <summary className="flex cursor-pointer select-none items-center justify-between py-6 px-7 2xl:py-8 2xl:px-9">

                  <h3>
                    <div className="flex items-center">
                      <p className="text-md 2xl:text-lg">
                        {t("footer.General Information")}
                      </p>
                    </div>
                  </h3>
                  <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-8 md:child:w-8">
                    <svg
                      width="42"
                      height="42"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path
                          d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                          fill="var(--svg-color-secondary, #163435)"
                        ></path>
                      </g>
                    </svg>
                  </div>
                </summary>
                <div className="px-7 2xl:px-9">
                  <div>
                    <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">

                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("footer.As a visitor to our website, you expect a high level of quality not only from our services but also in the processing of your personal data. Therefore, we process personal data in strict compliance with the provisions of the GDPR and national data protection laws, only if a legal regulation allows it or if you have given us your consent. Personal data includes all information by which you can be identified or are identifiable")}.
                      </div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("footer.We take the protection of your personal data very seriously, treating it confidentially and in accordance with legal data protection regulations and this privacy policy. Please be aware that data transmission over the Internet (e.g., via email communication) may have security vulnerabilities. A complete protection of data from access by third parties is not possible")}.
                      </div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("footer.According to the GDPR, personal data includes all information relating to an identified or identifiable natural person. An identifiable natural person is one who can be identified directly or indirectly, especially by association with an identifier such as a name, an identification number, location data, an online identifier, or one or more special personal characteristics ('personal data'). Data that can only be assigned to a legal person are not considered personal data and are therefore not subject to the provisions of these data protection notices or the GDPR")}.
                      </div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("footer.Below, you will find information about the data controller responsible for processing your personal data, the data protection officer of the controller, as well as your rights regarding the processing of your personal data. Furthermore, you will receive information about the processing of your personal data and details related to the website and the offers provided on the website. Information on how we process data from business partners and applicants can be found below and in our special data protection notices")}.
                      </div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                      <h4 className="my-5 first:mt-0 break-words text-lg">
                        {t("footer.Responsible")}
                      </h4>

                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal fw-700">{t("footer.Party for Processing Personal Data under the EU General Data Protection Regulation (GDPR)")}:</div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">Born To Camp</div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">Fidelhof 3</div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">93128 Regenstauf</div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                        <a className="no-underline" href="tel:'+49 (0) 163. 2937354'">Tel: +49 (0) 163. 2937354</a>
                      </div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        E-mail: <a href="mailto:'info@borntocamp.de'">info@borntocamp.de</a>
                      </div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                    </div>
                  </div>
                </div>
              </details>

              <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                <summary className="flex cursor-pointer select-none items-center justify-between py-6 px-7 2xl:py-8 2xl:px-9">

                  <h3>
                    <div className="flex items-center">

                      <p className="text-md 2xl:text-lg">{t("footer.Content")}</p>
                    </div>
                  </h3>
                  <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-8 md:child:w-8">
                    <svg
                      width="42"
                      height="42"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path
                          d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                          fill="var(--svg-color-secondary, #163435)"
                        ></path>
                      </g>
                    </svg>
                  </div>

                </summary>
                <div className="px-7 2xl:px-9">
                  <div>
                    <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("footer.In accordance with Art. 13 GDPR, we inform you about the legal bases of our data processing. If you have given your consent to the processing of personal data, we process your personal data based on Art. 6 para. 1 lit. a GDPR or Art. 9 para. 2 lit. a GDPR if special categories of data are processed according to Art. 9 para. 1 GDPR. In the case of explicit consent to the transfer of personal data to third countries, the data processing also takes place based on Art. 49 para. 1 lit. a GDPR. If you have consented to the storage of cookies or access to information on your end device (e.g., via device fingerprinting), the data processing additionally takes place based on § 25 para. 1 TTDSG. The consent can be revoked at any time. If your data is necessary for the fulfillment of a contract or for the implementation of pre-contractual measures, we process your data based on Art. 6 para. 1 lit. b GDPR. Furthermore, we process your data if this is necessary to fulfill a legal obligation based on Art. 6 para. 1 lit. c GDPR. Data processing can also be carried out based on our legitimate interest according to Art. 6 para. 1 lit. f GDPR")}.
                      </div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                    </div>
                  </div>
                </div>
              </details>

              <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                <summary className="flex cursor-pointer select-none items-center justify-between py-6 px-7 2xl:py-8 2xl:px-9">

                  <h3>
                    <div className="flex items-center">
                      <p className="text-md 2xl:text-lg">
                        {t("footer.Who receives my data?")}
                      </p>
                    </div>
                  </h3>
                  <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-8 md:child:w-8">
                    <svg
                      width="42"
                      height="42"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path
                          d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                          fill="var(--svg-color-secondary, #163435)"
                        ></path>
                      </g>
                    </svg>
                  </div>

                </summary>
                <div className="px-7 2xl:px-9">
                  <div>
                    <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                      <h4 className="my-5 first:mt-0 break-words text-lg">
                        {t("footer.Data Deletion and Storage Duration")}
                      </h4>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("footer.We adhere to the principles of data minimization according to Art. 5 para. 1 lit. c GDPR and storage limitation according to Art. 5 para. 1 lit. e GDPR. We only store your personal data for as long as necessary to achieve the purposes mentioned here or as required by the statutory retention periods stipulated by the legislator. After the respective purpose has ceased to exist or upon expiration of these retention periods, the corresponding data will be deleted as soon as possible")}.
                      </div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                      <h4 className="my-5 first:mt-0 break-words text-lg">
                        {t("footer.Note on Data Transfer to Third Countries")}
                      </h4>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("footer.On our website, tools from companies based in third countries are also integrated. When these tools are active, your personal data may be transmitted to the servers of the respective companies. The level of data protection in third countries generally does not comply with EU data protection law. This entails the risk that your data may be disclosed to authorities in these states. We have no inﬂuence on these processing activities")}.
                      </div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                    </div>
                  </div>
                </div>
              </details>

              <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                <summary className="flex cursor-pointer select-none items-center justify-between py-6 px-7 2xl:py-8 2xl:px-9">

                  <h3>
                    <div className="flex items-center">
                      <p className="text-md 2xl:text-lg">{t("footer.Handling of External Links on Our Website")}</p>
                      {/* <p className="text-md 2xl:text-lg text-18">Handling of External Links <span className="mobile-none">on Our Website</span></p> */}
                    </div>
                  </h3>
                  <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-8 md:child:w-8">
                    <svg
                      width="42"
                      height="42"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path
                          d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                          fill="var(--svg-color-secondary, #163435)"
                        ></path>
                      </g>
                    </svg>
                  </div>

                </summary>
                <div className="px-7 2xl:px-9">
                  <div>
                    <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("footer.This website may contain links to third-party websites or other websites within our responsibility. When following a link to a website outside our responsibility, please note that these websites have their own privacy information. We do not assume any responsibility or liability for these external websites and their privacy policies. Therefore, before using these websites, check whether you agree with the privacy statements there")}.

                      </div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">

                        {t("footer.You can recognize external links by their distinct color or underline compared to the rest of the text. Your cursor indicates external links when you move it over such a link. Only when you click on an external link, your personal data is transferred to the link's destination.")} <br />

                      </div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("footer.The operator of the other website receives your IP address, the time you clicked the link, the page on which you clicked the link, and other information, as specified in the privacy policies of the respective provider. Also, note that individual links may lead to data transfers outside the European Economic Area. This may allow foreign authorities access to your data, and you may not have legal remedies against such data access. If you do not want your personal data to be transferred to the link destination or exposed to the access of foreign authorities, please do not click on any links")}.
                      </div>
                    </div>
                  </div>
                </div>
              </details>

              <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                <summary className="flex cursor-pointer select-none items-center justify-between py-6 px-7 2xl:py-8 2xl:px-9">

                  <h3>
                    <div className="flex items-center">
                      <p className="text-md 2xl:text-lg">
                        {t("footer.User Rights")}
                      </p>
                    </div>
                  </h3>
                  <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-8 md:child:w-8">
                    <svg
                      width="42"
                      height="42"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path
                          d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                          fill="var(--svg-color-secondary, #163435)"
                        ></path>
                      </g>
                    </svg>
                  </div>

                </summary>
                <div className="px-7 2xl:px-9">
                  <div>
                    <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("footer.As a data subject under the GDPR, you have the opportunity to exercise various rights. The rights arising from the GDPR include the right to information (Article 15), the right to rectification (Article 16), the right to erasure (Article 17), the right to restriction of processing (Article 18), the right to object (Article 21), the right to lodge a complaint with a supervisory authority, and the right to data portability (Article 20)")}.</div>
                      <h4 className="my-5 first:mt-0 break-words text-lg">
                        {t("footer.Right to Withdraw Consent")}:

                      </h4>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("footer.Some data processing can only take place with your explicit consent. You have the right to withdraw your consent at any time. The legality of data processing until the withdrawal is not aﬀected by it. You can revoke your consent already given at any time by contacting us at")} <a href="mailto:'info@borntocamp.de'">info@borntocamp.de</a>&nbsp;{t("footer.etc")}.
                      </div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                      <h4 className="my-5 first:mt-0 break-words text-lg">
                        {t("footer.Right to Object")}:
                      </h4>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("footer.If the processing is based on Article 6(1)(e) or (f) of the GDPR, you, as a data subject, can object to the processing of your personal data at any time for reasons arising from your particular situation. This right also applies to profiling based on these provisions in accordance with Article 4(4) of the GDPR. If we cannot demonstrate a legitimate interest for the processing that overrides your interests, rights, and freedoms, or if the processing serves the assertion, exercise, or defense of legal claims, we will cease processing your data after the objection. If the processing of personal data is for direct marketing, you also have the right to object at any time. The same applies to profiling associated with direct marketing")}.
                        {t("footer.We will cease processing personal data as soon as you object")}.

                      </div>

                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("footer.extradataingerman")}</div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("footer.Right to Lodge a Complaint with a Supervisory Authority")}:<br /> {t("footer.If you believe that the processing of your personal data violates the GDPR, you have the right to lodge a complaint with a supervisory authority, without prejudice to any other administrative or judicial remedy, especially in the Member State of your residence, workplace, or the place of the alleged violation")}.</div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                      <h4 className="my-5 first:mt-0 break-words text-lg">
                        {t("footer.Right to Data Portability")}:
                      </h4>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("footer.If your data is processed automatically based on consent or the fulfillment of a contract, you have the right to receive this data in a structured, commonly used, and machine-readable format. You also have the right to request the transfer and provision of the data to another controller, as far as technically feasible")}.</div>
                      <h4 className="my-5 first:mt-0 break-words text-lg">
                        {t("footer.Right to Information, Rectiﬁcation, and Erasure")}:
                      </h4>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("footer.You have the right to information about your processed personal data regarding the purpose of data processing, categories, recipients, and duration of storage. For questions on this topic or other matters related to personal data, feel free to contact us using the contact information provided in the imprint")}.</div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>

                      <h4 className="my-5 first:mt-0 break-words text-lg">
                        {t("footer.Right to Restriction of Processing")}:
                      </h4>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("footer.You can assert the right to restrict the processing of your personal data at any time. To do this, you must meet one of the following conditions")}:</div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        <ul className="custom-ul">
                          <li>
                            {t("footer.You dispute the accuracy of the personal data. For the duration of the verification of accuracy, you have the right to request a restriction of processing")}.
                          </li>
                          <li>
                            {t("footer.If processing is unlawful, you can request the restriction of data usage as an alternative to deletion")}.
                          </li>
                          <li>
                            {t("footer.If we no longer need your personal data for the purposes of processing, but you need the data to assert, exercise, or defend legal claims, you can request a restriction of processing as an alternative to deletion")}.
                          </li>
                          <li>
                            {t("footer.If you object to the processing according to Art. 21(1) GDPR, a balancing of interests between your interests and ours is carried out. Until this balancing is completed, you have the right to request a restriction of processing. A restriction of processing means that, apart from storage, personal data may only be processed with your consent or for the assertion, exercise, or defense of legal claims, or for the protection of the rights of another natural or legal person, or for reasons of important public interest of the Union or a Member State")}.
                          </li>
                        </ul>
                      </div>






                    </div>
                  </div>
                </div>
              </details>

              <details className="group border-b border-b-grey last:border-b-0 dark:border-b-grey-dark">
                <summary className="flex cursor-pointer select-none items-center justify-between py-6 px-7 2xl:py-8 2xl:px-9">

                  <h3>
                    <div className="flex items-center">
                      <p className="text-md 2xl:text-lg">{t("print.How is my date processed")}? </p>
                    </div>
                  </h3>
                  <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6 md:child:h-8 md:child:w-8">
                    <svg
                      width="42"
                      height="42"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path
                          d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                          fill="var(--svg-color-secondary, #163435)"
                        ></path>
                      </g>
                    </svg>
                  </div>

                </summary>
                <div className="px-7 2xl:px-9">
                  <div>
                    <div className="pt-0 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("print.Below, we inform you about the individual processing operations, the scope and purpose of data processing, the legal basis, the obligation to provide your data, and the respective storage duration. No automated decision-making in individual cases, including profiling, takes place")}.
                      </div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        <h4 className="my-5 first:mt-0 break-words text-lg">
                          {t("footer.Provision of the Website (Web Host)")}
                        </h4>
                      </div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("footer.Our website is hosted by")}:</div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">Hetzner Online GmbH</div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">Industriestr. 25, 91710 Gunzenhausen</div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">Deutschland</div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("footer.When you access our website, we automatically collect and store information in so-called server log files. Your browser automatically transmits this information to our server or the server of our hosting company")}.</div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("footer.This includes")}:</div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        <ul className="custom-ul">
                          <li>{t("footer.IP address of the visitor's end device")}</li>
                          <li>{t("footer.Device used")}</li>
                          <li>{t("footer.Hostname of the accessing computer")}</li>
                          <li>{t("footer.Visitor's operating system")}</li>
                          <li>{t("footer.Browser type and version")}</li>
                          <li>{t("footer.Name of the retrieved file")}</li>
                          <li>{t("footer.Time of the server request")}</li>
                          <li>{t("footer.Amount of data")}</li>
                          <li>{t("footer.Information on whether the data retrieval was successful")}</li>
                        </ul>
                      </div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("footer.These data are not merged with other data sources")}.</div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("footer.Instead of operating this website on our own server, we can also have it operated on the server of an external service provider (hosting company), which we have mentioned above in this case. The personal data collected by this website will then be stored on the servers of the hosting company. In addition to the data mentioned above, the web host stores for us, for example, contact requests, contact details, names, website access data, meta and communication data, contract data, and other data generated via a website")}.<br />
                        <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                        {t("footer.The legal basis for the processing of these data is Art. 6 (1) lit. f GDPR. Our legitimate interest is the technically error-free presentation and optimization of this website. If the website is accessed to enter into contract negotiations with us or to conclude a contract, the additional legal basis is Art. 6 (1) lit. b GDPR. In the event that we have commissioned a hosting company, there is a contract for order processing with this service provider")}.
                      </div>
                      <h4 className="my-5 first:mt-0 break-words text-lg">
                        {t("footer.Contact Form")}
                      </h4>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("footer.On our website, there is the possibility to contact us via a contact form. For contacting us via this form, your contact details are particularly needed.As the legal basis, reference is made to the processing for the purpose of contract fulfillment or pre-contractual measures according to Art. 6 (1) lit. b GDPR. Additionally, a legitimate")}<br />

                        {t("footer.interest may exist to maintain business relationships or to respond to your inquiry for other reasons. The legal basis for processing your data in this case would be Art. 6 (1) lit. f GDPR. The data will be deleted once we have conclusively responded to your request and there are no other retention obligations to the contrary")}.
                        <br />{t("footer.extra")}<br />{t("footer.extra1german")}
                      </div>
                      
                      <h4 className="my-5 first:mt-0 break-words text-lg">
                        {t("footer.Telephone Contact or E-Mail Contact")}
                      </h4>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("footer.In accordance with legal requirements, we have provided a telephone number and email address on our website. The data transmitted via these means are automatically stored by us in order to process corresponding inquiries or contact the inquiring person. These data are not passed on to third parties without your consent")}.
                        <br />
                        {t("footer.If the contact is made by telephone or via our email address for pre-contractual or contractual purposes, then the processing of personal data is based on the legal basis of Art. 6 (1) lit. b GDPR. For all other contacts initiated by you, the processing of personal data by us is based on our legitimate interest according to Art. 6 (1) lit. f GDPR")}.

                      </div>
                      <h4 className="my-5 first:mt-0 break-words text-lg">
                        {t("footer.Use of Cookies")}
                      </h4>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("footer.Our website uses cookies. Cookies are pieces of information that a web server (server that provides web content) places on your end device to identify this end device. They are either temporarily stored for the duration of a session (session cookies) and deleted at the end of your visit to a website or permanently stored (permanent cookies) on your end device until you delete them yourself or an automatic deletion takes place by your web browser. Cookies can also be stored on your end device by third-party companies when you enter our site (third-party requests).")} <br />{t("footer.This enables us, as the operator, and you, as the visitor of this website, to use certain services from third parties installed on this website. Examples include cookies for processing payment services or cookies for displaying videos.")}<br/> {t("footer.Cookies have a variety of uses. They can improve the functionality of a website, control shopping cart functions, increase the security and comfort of website use, and perform analyses regarding visitor ﬂows and behavior. Depending on the individual functions, the cookies are classified in terms of data protection. If they are necessary for the operation of the website and to provide certain functions (e.g., shopping cart function) or serve to optimize the website (e.g., cookies for measuring visitor behavior), then their use is based on Art. 6 (1) lit. f GDPR. As a website operator, we have a legitimate interest in storing cookies for the technically error-free and optimized provision of our services. In all other cases, the storage of cookies only takes place after your explicit consent (Art. 6 (1) lit. a GDPR and § 25 (1) TTDSG). If cookies are used by third-party companies or for analysis purposes, we will inform you separately in this privacy notice. Your required consent will be requested and can be revoked at any time")}.
                        <br />
                        {t("footer.german")}
                      </div>
                      <h4 className="my-5 first:mt-0 break-words text-lg">
                        {t("footer.Use of External Services")}
                      </h4>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("footer.External services are in use on our website. External services are services from third-party providers that are used on our website. This can be for various reasons, for example, embedding videos or for the security of the website. When using these services, personal data are also transferred to the respective providers of these external services. If we do not have a legitimate interest in using these services, we obtain your revocable consent as a visitor to our website before using them (Art. 6 (1) lit. a GDPR and § 25 (1) TTDSG)")}.
                      </div>
                      <h4 className="my-5 first:mt-0 break-words text-lg">
                        Newsletter
                      </h4>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("footer.If you have expressly consented according to Art. 6 (1) lit. a GDPR or § 25 (1) TTDSG, we use your email address to regularly send you our newsletter. Providing an email address is sufficient for receiving the newsletter. When you sign up for our email newsletter, we regularly send you information about our oﬀers. The only mandatory information for sending the newsletter is your email address. The provision of additional, possibly data is voluntary and will be used to address you personally.")} <br/>{t("footer.By entering your email address and confirming that you would like to receive our newsletter, you give us your consent to use your personal data in accordance with Art. 6 (1) lit. a GDPR or § 25 (1) TTDSG. The data collected by us when you subscribe to the newsletter are used exclusively for promotional communication via the newsletter. You can unsubscribe from the newsletter at any time via the designated link in the newsletter. Alternatively, you can also send your request to unsubscribe at any time to info@borntocamp.de by email. After successful unsubscription, your email address will be immediately deleted from our newsletter distribution list. We use Mailchimp for newsletter distribution")}.
                      </div>
                      <h4 className="my-5 first:mt-0 break-words text-lg">
                        {t("footer.Analytics")}
                      </h4>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("footer.For the analysis of user behavior, we process personal data of website visitors. Through the evaluation of the data obtained, we are able to compile information about the use of the individual components of our website. This allows us to increase the user-friendliness of our website. For example, user profiles for the display of targeted or interest-based advertising messages could be created using the analysis tools, our website visitors could be recognized on their next visit to our website, their click/scroll behavior and their downloads measured, heat maps created, page views recognized, the duration of visits or the bounce rates measured, and the origin of website visitors (city, country, from which page does the visitor come) traced back.")}
                      </div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("footer.The analysis tools help us to improve our market research and marketing activities. The processing of data is based on the legal basis of consent (Art. 6 (1) lit. a GDPR and § 25 (1) TTDSG). As a website visitor, you have consented to the processing of your personal data with your voluntary, explicit, and prior given consent. Without separate consent, personal data will not be processed by us in the manner described above, provided that there is no other legal basis in the sense of Art. 6 (1) GDPR that we base the processing on. We proceed in the same way if you revoke your consent. The legality of the processing carried out until the revocation remains unaﬀected")}.
                      </div>
                      <h4 className="my-5 first:mt-0 break-words text-lg">
                        Google {t("footer.Analytics")}
                      </h4>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("footer.We use the service Google Analytics on our website. The provider of the service is Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland.")}<br/> {t("footer.The use of this service may result in data being transferred to a third country (USA). The provider is certified according to the EU-U.S. Data Privacy Framework, thereby oﬀering an adequate level of data protection.")}<br/> {t("footer.Further information can be found in the provider's privacy policy at the following URL")}: <a target="_blank" href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>.
                      </div>
                      <h4 className="my-5 first:mt-0 break-words text-lg">
                        {t("footer.Microsoft Clarity")}
                      </h4>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("footer.We use the service Microsoft Clarity on our website. The provider of this service is Microsoft Ireland Operations Limited, One Microsoft Place, South County Business Park, Leopardstown, Dublin 18, D18 P521, Ireland.")}<br /> {t("footer.The use of this service may result in data being transferred to a third country (USA). The provider is certified according to the EU-U.S. Data Privacy Framework, thereby oﬀering an adequate level of data protection.")} <br /> {t("footer.Further information can be found in the provider's privacy policy at the following URL")}: <a target="_blank" href="https://privacy.microsoft.com/en-us/privacystatement.">https://privacy.microsoft.com/de-de/privacystatement</a>.
                      </div>
                      <h4 className="my-5 first:mt-0 break-words text-lg">
                        {t("footer.Visual Website Optimizer")}
                      </h4>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("footer.We use the service Visual Website Optimizer on our website. The provider of this service is Wingify, KLJ Tower, 1104, North, Netaji Subhash Place, Pitam Pura, Delhi, India.")} <br/>{t("footer.The use of this service may result in data being transferred to a third country (India).")} <br/>{t("footer.Further information can be found in the provider's privacy policy at the following URL")}: <a target="_blank" href="https://wingify.com/privacy-policy">https://wingify.com/privacy-policy</a>.
                      </div>
                      <h4 className="my-5 first:mt-0 break-words text-lg">
                        {t("footer.HR-Systems")}
                      </h4>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"> {t("footer.We use software to better capture, store, and manage information concerning human resource management. HR systems store personal data, in particular names, addresses, and salary data.")}<br/> {t("footer.Processing occurs only if you consent to this data processing (via our consent banner on the website). The legal basis for this processing is consent (Art. 6 (1) lit. a GDPR). Without your consent, data processing will not occur as described above. If you revoke your consent (e.g., via the consent banner or other means provided on this website), we will stop this data processing. The legality of the processing carried out until the revocation remains unaﬀected")}.</div>
                      <h4 className="my-5 first:mt-0 break-words text-lg">
                        Marketing
                      </h4>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("footer.On our website, we use tools that oﬀer services around campaigns, web analytics, and personalization. This allows for the central and comprehensive collection of all data necessary for the optimization and planning of digital campaigns. These services can be set and used via our website by our advertising partners to create a profile of your interests and show you relevant ads on other websites.")}
                      
                      </div>
                      
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                         {t("footer.The processing of the data is based on the legal basis of consent (Art. 6 (1) lit. a GDPR and § 25 (1) TTDSG). As a website visitor, you have consented to the processing of your personal data with your voluntary, explicit, and prior given consent. Without separate consent, personal data will not be processed by us in the manner described above, provided that there is no other legal basis in the sense of Art. 6 (1) GDPR that we base the processing on. We proceed in the same way if you revoke your consent. The legality of the processing carried out until the revocation remains unaﬀected")}.
                      </div>
                      <h4 className="my-5 first:mt-0 break-words text-lg">
                        {t("footer.Post Affiliate Pro")}
                      </h4>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("footer.We use the service Post Affiliate Pro on our website. The provider of this service is Quality Unit, s. r. o., Polus City Center, Polus Tower I, 4th Floor, Vajnorska 100/A, 83104 Bratislava, Slovakia.")}<br/> {t("footer.Further information can be found in the provider's privacy policy at the following URL")}: <a target="_blank" href="https://www.qualityunit.com/privacy-policy/">https://www.qualityunit.com/privacy-policy/</a>.
                      </div>
                      <h4 className="my-5 first:mt-0 break-words text-lg">
                        {t("footer.Interface Software")}
                      </h4>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("footer.Business processes run more cost-effectively, faster, and with fewer errors when automated with software via interfaces. They can be efficiently integrated into business processes via one's own website or social networks. We use interface software on our website to link different applications and to securely transfer personal data from one application to another")}.
                      </div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("footer.The processing of the data is based on the legal basis of consent(Art. 6 (1) lit. a GDPR and § 25 (1) TTDSG). As a website visitor, you have consented to the processing of your personal data with your voluntary, explicit, and prior given consent. Without separate consent, personal data will not be processed by us in the manner described above, provided that there is no other legal basis in the sense of Art. 6 (1) GDPR that we base the processing on. We proceed in the same way if you revoke your consent. The legality of the processing carried out until the revocation remains unaffected")}.
                      </div>
                      <h4 className="my-5 first:mt-0 break-words text-lg">
                        Google Tag Manager
                      </h4>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("footer.We use the service Google Tag Manager on our website. The provider of this service is Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland.")} <br/>{t("footer.The use of this service may result in data being transferred to a third country (USA). The provider is certified according to the EU-U.S. Data Privacy Framework, thereby oﬀering an adequate level of data protection.")}<br/> {t("footer.Further information can be found in the provider's privacy policy at the following URL")}: <a target="_blank" href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>.
                      </div>
                      <h4 className="my-5 first:mt-0 break-words text-lg">
                        {t("footer.Social Media")}
                      </h4>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("footer.We use social media plugins to connect our website with our social media channels. The integration of the plugins makes it easier for visitors to our website to follow our channels on social networks, to share, like, or comment on content. Some social media plugins allow the analysis of website visitors' behavior concerning their activities on social networks. By using plugins, we aim to increase the awareness and follower count of our channels")}.<br />


                      </div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"> {t("footer.The processing of personal data by the plugins and the transfer of data to these social networks occur as soon as the website is accessed. Processed data include, for example, name, address, email address, telephone number, access time, device information, IP address")}.<br /></div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">  {t("footer.The processing of the data is based on the legal basis of consent (Art. 6 (1) lit. a GDPR and § 25 (1) TTDSG). As a website visitor, you have consented to the processing of your personal data with your voluntary, explicit, and prior given consent. Without separate consent, personal data will not be processed by us in the manner described above, provided that there is no other legal basis in the sense of Art. 6 (1) GDPR that we base the processing on. We proceed in the same way if you revoke your consent. The legality of the processing carried out until the revocation remains unaﬀected")}.</div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                      <h4 className="my-5 first:mt-0 break-words text-lg">TikTok</h4>
                        {t("footer.We use the service TikTok on our website. The provider of this service is TikTok Technology Limited, 10 Earlsfort Terrace, Dublin, D02 T380, Ireland.")}<br /> {t("footer.The use of this service may result in data being transferred to a third country (USA). The provider is certified according to the EU-U.S. Data Privacy Framework, thereby oﬀering an adequate level of data protection.")} <br />{t("footer.Further information can be found in the provider's privacy policy at the following URL")}: <a target="_blank" href="https://www.tiktok.com/legal/privacy-policy-eea?lang=en">https://www.tiktok.com/legal/privacy-policy-eea?lang=de</a>.
                      </div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                      <h4 className="my-5 first:mt-0 break-words text-lg">{t("footer.Video-/Music-provider")}</h4>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("footer.Our website integrates audio and video content. This content is accessed from the server of our provider, known as the audio or video platform. To play an audio or video file, your end device establishes a connection with the audio or video platform, transmitting personal data to it. This includes, in particular, the IP address, any location data, or information about the browser and user's end device.")}<br />{t("footer.Processing occurs only if you consent to this data processing (via our consent banner on the website). The legal basis for this processing is consent (Art. 6(1) lit. a GDPR). Without your consent, data processing will not occur as described above. If you revoke your consent (e.g., via the consent banner or other means provided on this website), we will stop this data processing. The legality of the processing carried out until the revocation remains unaﬀected")}.
                      </div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                      <h4 className="my-5 first:mt-0 break-words text-lg">YouTube</h4>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("footer.We use the service YouTube on our website. The provider of this service is Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland.")}<br /> {t("footer.The use of this service may result in data being transferred to a third country (USA). The provider is certified according to the EU-U.S. Data Privacy Framework, thereby oﬀering an adequate level of data protection.")} <br />{t("footer.Further information can be found in the provider's privacy policy at the following URL")}: <a target="_blank" href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>.
                      </div>
                      <h4 className="my-5 first:mt-0 break-words text-lg">
                        Websecurity
                      </h4>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("footer.On our website, we use tools that provide protection against unauthorized access, spam, or other attacks. This enhances the security of our website.")} <br />{t("footer.We base this processing on a legitimate interest (Art. 6 (1) lit. f GDPR).")}<br /> {t("footer.Our legitimate interest is to ensure the security of our website and to protect ourselves from unauthorized access, spam, and other attacks")}
                      </div>
                      <h4 className="my-5 first:mt-0 break-words text-lg">
                        hCaptcha
                      </h4>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("footer.We use the service hCaptcha on our website. The provider of this service is Intuition Machines, Inc., 350 Alabama St, San Francisco, CA 94110, USA.")} <br />{t("footer.The use of this service may result in data being transferred to a third country (USA).")} <br />{t("footer.Further information can be found in the provider's privacy policy at the following URL")}:<a target="_blank" href="https://www.hcaptcha.com/privacy">https://www.hcaptcha.com/privacy</a>.
                      </div>
                      <h4 className="my-5 first:mt-0 break-words text-lg">
                        {t("footer.Advertising")}
                      </h4>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("footer.On our website, we use tools that facilitate or enable the placement of advertisements and the evaluation of the success of placed ads. Personal data is processed for this purpose, in particular the IP address, access times, and device information.")} <br /> {t("footer.Processing occurs only if you consent to this data processing (via our consent banner on the website). The legal basis for this processing is consent (Art. 6 (1) lit. a GDPR). Without your consent, data processing will not occur as described above. If you revoke your consent (e.g., via the consent banner or other means provided on this website), we will stop this data processing. The legality of the processing carried out until the revocation remains unaﬀected")}.
                      </div>
                      <h4 className="my-5 first:mt-0 break-words text-lg">
                        Microsoft Advertising
                      </h4>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("footer.We use the service Microsoft Advertising on our website. The provider of this service is Microsoft Ireland Operations Ltd., One Microsoft Place, South County Business Park, Leopardstown, Dublin 18, D18 P521, Ireland. The use of this service may result in data being transferred to a third country (USA). Further information can be found in the provider's privacy policy at the following URL")}: <a target="_blank" href="https://privacy.microsoft.com/en-us/privacystatement">https://privacy.microsoft.com/de-de/privacystatement</a>.
                      </div>
                      <h4 className="my-5 first:mt-0 break-words text-lg">
                        Google Ads
                      </h4>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("footer.We use the service Google Ads on our website. The provider of this service is Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland.")} <br />{t("footer.The use of this service may result in data being transferred to a third country (USA). The provider is certified according to the EU-U.S. Data Privacy Framework, thereby oﬀering an adequate level of data protection. Further information can be found in the provider's privacy policy at the following URL")}: <a target="_blank" href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>.
                      </div>
                      <h4 className="my-5 first:mt-0 break-words text-lg">
                        Google AdSense
                      </h4>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("footer.We use the service Google AdSense on our website. The provider of this service is Google Ireland Limited (GV), Gordon House, Barrow Street, Dublin 4, Ireland.")}<br /> {t("footer.The use of this service may result in data being transferred to a third country (USA). The provider is certified according to the EU-U.S. Data Privacy Framework, thereby oﬀering an adequate level of data protection. Further information can be found in the provider's privacy policy at the following URL")}: <a target="_blank" href="https://policies.google.com/privacy">https://policies.google.com/privacy</a>.
                      </div>
                      <h4 className="my-5 first:mt-0 break-words text-lg">
                        Mailchimp
                      </h4>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("footer.This website uses the services of Mailchimp for sending newsletters. The provider is Rocket Science Group LLC, 675 Ponce De Leon Ave NE, Suite 5000, Atlanta, GA 30308, USA. Mailchimp is a service that can be used to organize and analyze the distribution of newsletters. When you enter data for the purpose of subscribing to the newsletter (e.g., email address), they are stored on Mailchimp's servers in the USA")}.
                      </div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("footer.With Mailchimp, we can analyze our newsletter campaigns. When you open an email sent with Mailchimp, a file included in the email (so-called web-beacon) connects to Mailchimp's servers in the USA. This allows us to determine if a newsletter message has been opened and which links have been clicked. Technical information is also collected (e.g., time of retrieval, IP address, browser type, and operating system). This information cannot be assigned to the respective newsletter recipient. They are used exclusively for the statistical analysis of newsletter campaigns. The results of these analyses can be used to better tailor future newsletters to the interests of recipients")}.</div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("footer.If you do not want analysis by Mailchimp, you must unsubscribe from the newsletter. We provide a corresponding link in each newsletter message for this purpose. The data processing is based on your consent (Art. 6 (1) lit. a GDPR). You can revoke this consent at any time by unsubscribing from the newsletter. The legality of the data processing operations already carried out remains unaﬀected by the revocation. The data stored by us for the purpose of subscribing to the newsletter will be stored by us or the newsletter service provider until you unsubscribe from the newsletter and deleted from the newsletter distribution list after you unsubscribe from the newsletter. Data stored for other purposes with us remains unaﬀected.")}<br /> {t("footer.The data transfer to the USA is based on the standard contractual clauses of the EU Commission. Details can be found here")}: <a target="_blank" href="https://mailchimp.com/about/eu-us-data-transfer-statement/ "className="no-underline">https://mailchimp.com/about/eu-us-data-transfer-statement/ </a>  {t("footer.and")}
                        <p><a target="_blank" href="https://mailchimp.com/legal/data-processing-addendum/#Annex_C_-
                      _Standard_Contractual_Clauses">https://mailchimp.com/de/legal/data-processing-addendum/#Annex_C_-%20_Standard_Contractual_Clauses</a>.</p>
                      </div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("footer.After you unsubscribe from the newsletter distribution list, your email address may be stored by us or the newsletter service provider in a blacklist, if this is necessary to prevent future mailings. The data from the blacklist is used only for this purpose and is not merged with other data. This serves both your interest and our interest in complying with the legal requirements for sending newsletters (legitimate interest within the meaning of Art. 6 (1) lit. f GDPR). The storage in the blacklist is not time-limited. You can object to the storage if your interests outweigh our legitimate interest")}.<br/>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                        {t("footer.For more information, see Mailchimp's privacy policy at")}: <a target="_blank" href="https://mailchimp.com/legal/terms/">https://mailchimp.com/legal/terms/</a>.
                      </div>
                      <h4 className="my-5 first:mt-0 break-words text-lg">
                        {t("footer.Presence on Social Media")}
                      </h4>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("footer.Social networks process the personal data of their users extensively. When you visit our profiles, your IP address and other information about your devices are processed, allowing the assignment of IP addresses to individual users. We have no control over this data processing. We advise you that you use our profiles on social networks and their functions at your own responsibility. Details on data processing can be found in the privacy policy of the operator")}.</div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">{t("footer.The purpose of our profiles on social media platforms is to increase our internet presence and the associated greater awareness. Therefore, the legal basis is legitimate interest according to Art. 6 (1) lit. f GDPR. Furthermore, regarding the processing activities by social networks, their own legal bases (e.g., consent according to Art. 6 (1) lit. a GDPR) apply, which can be found in the respective privacy policy. In principle, we are jointly responsible with the social media platform for the data processing operations triggered by visiting our profile")}.&nbsp;
                        {t("footer.Therefore, you can assert your rights under Art. 15ﬀ GDPR against both the social media platform and us. However, we point out that we have no inﬂuence over data processing by the social media platform")}.
                      </div>
                      <h4 className="my-5 first:mt-0 break-words text-lg">
                        {t("footer.Presence on Facebook")}
                      </h4>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("footer.We have a profile on Facebook. The provider of this service is Meta Platforms Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland.")} <br />{t("footer.Details can be found in Facebook's privacy policy")}: <a target="_blank" href="https://www.facebook.com/about/privacy/">https://www.facebook.com/about/privacy/</a>.
                      </div>

                      <h4 className="my-5 first:mt-0 break-words text-lg">
                        {t("footer.Presence on Instagram")}
                      </h4>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("footer.We have a profile on Instagram. The provider of this service is Meta Platforms Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland.")} <br />{t("footer.Detailed information on handling personal data can be found in Instagram's privacy policy")}: <a target="_blank" href="https://help.instagram.com/519522125107875">https://help.instagram.com/519522125107875.</a>
                      </div>

                      <h4 className="my-5 first:mt-0 break-words text-lg">
                        {t("footer.Presence on Pinterest")}
                      </h4>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("footer.We have a profile on Pinterest. The operator is Pinterest Europe Ltd., Palmerston House, 2nd Floor, Fenian Street, Dublin 2, Ireland.")}<br /> {t("footer.Details can be found in Pinterest's privacy policy")}: <a target="_blank" href="https://policy.pinterest.com/en/privacy-policy">https://policy.pinterest.com/de/privacy-policy</a>.
                      </div>

                      <h4 className="my-5 first:mt-0 break-words text-lg">
                        {t("footer.Presence on TikTok")}
                      </h4>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                        {t("footer.We have a profile on TikTok. The provider is TikTok Technology Limited, 10 Earlsfort Terrace, Dublin, D02 T380, Ireland.")}<br /> {t("footer.For detailed information on how personal data is handled, please refer to TikTok's privacy policy")}: <a target="_blank" href="https://www.tiktok.com/legal/privacy- policy?lang=de">https://www.tiktok.com/legal/privacy-policy?lang=de</a>.
                      </div>

                      <h4 className="my-5 first:mt-0 break-words text-lg">
                        {t("footer.Presence on YouTube")}
                      </h4>
                      <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal de-br-none">
                        {t("footer.We have a profile on YouTube. The provider is Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Ireland.")}<br />{t("footer.For detailed information on how personal data is handled, please refer to YouTube's privacy policy")}:&nbsp;<br/>
                        <a target="_blank" href="https://policies.google.com/privacy?hl=de">https://policies.google.com/privacy?hl=de</a>.
                      </div>









                    </div>
                  </div>
                </div>
              </details>

            </div>
          </div>
          <div className="min-h-[30px] break-words text-md 2xl:text-2md"></div>
          <div className="min-h-[30px] break-words text-md 2xl:text-2md"></div>
        </div>

      </div>



      <Footer />
    </>
  );
};

export default Blog;
