import React, { useState, useEffect } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";

import { Link } from "react-router-dom";
import CamperService from "../../Api/services/CamperService";
import HomeService from "../../Api/services/HomeService";
import { useTranslation } from "react-i18next";
const Offers = () => {
  const { i18n, t } = useTranslation();
  const [Error, setError] = useState("")
  const [isFormSubmit, setisFormSubmit] = useState(false);
  const onSubmitForm = async (event) => {
    event.preventDefault();
    setError("")
    try {
      var userData = {
        "first_name": event.target.first_name.value,
        "email": event.target.email.value,
        "type": "Deals",
        "lng": i18n.resolvedLanguage
      }
      if (event.target.first_name.value === "") {
        setError(t("Login.The first name field is required."))
      }
      else if (event.target.email.value === "") {
        setError(t("Login.The email field is required."))
      }

      else {
        let responseData = await HomeService.store(userData).json()
        if (responseData.status === true) {
          //alert("Newsletters Submitted Successfully!!");
          event.target.reset();
          //navigate('/');
          setisFormSubmit(true)
        }
      }
    } catch (error) {
      console.log(error)
      if (error.name === 'HTTPError') {
        const errorJson = await error.response.json();
        setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
      }
    }

  };
  var dealUrl = process.env.REACT_APP_LARAVEL_Deal_IMAGE_URL;
  const [Deal, setDeal] = useState([]);
  const [camper, setCamper] = useState([]);
  useEffect(() => {
    getCamper();
    getDeals();
  }, []);
  const getDeals = async () => {
    try {
      let dealsList = await HomeService.deals().json();
      setDeal(dealsList.data)

    } catch (error) {
      console.log(error);
    }
  };
  // ----------- get Campers  ------------ //
  const getCamper = async () => {
    try {
      let camperList = await CamperService.index().json();

      var responsedata = camperList.data.filter(function (item) {
        return item.camper_type_id == 3;
      });

      setCamper(responsedata);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      <Header />

      <div className="min-h-screen bg-off-white">

        <div className="pb-5 text-center">
          <div className="overflow-hidden">
            <div className="-ml-[5%] w-[110%] overflow-hidden rounded-b-[60%20%]  px-[5%]">
              <div className="relative">
                <div className="overflow-hidden">
                  <img
                    src="title-images/Deals-page-title-picture.jpg"
                    alt="jamy_grand_california"
                    className="absolute max-h-full w-full scale-110 object-cover blur-lg dark:brightness-90 sm:aspect-hero-regular 3xl:aspect-hero-long aspect-square"
                  />
                </div>
                <picture className="flex max-h-[60vh] w-full text-0">
                  <img
                    className="z-0 max-h-full w-full object-cover dark:brightness-90 sm:aspect-hero-regular 3xl:aspect-hero-long aspect-square"
                    src="title-images/Deals-page-title-picture.jpg"
                    alt="jamy_grand_california"
                    width="2552"
                    height="1286"
                  />
                </picture>
                <div className="absolute top-0 left-0 flex h-full w-full flex-col items-center justify-end">
                  <div className="pb-12 w-full bg-gradient-to-t from-black-pure/80 to-transparent pt-20 md:pt-14 xl:pt-32">
                    <h1 className="whitespace-pre-line break-words m-auto max-w-4xl px-8 text-xl md:text-2xl text-white drop-shadow-text dark:text-off-white xs:text-2xl xl:text-3xl mb-5 md:mb-8 xl:mb-12">
                      {t("Offers.Discount Campaigns & Offers")}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark relative mx-auto -mt-12 child:h-24 child:w-24 2xl:-mt-14 2xl:child:h-28 2xl:child:w-28">
            <img className="" src="images/percentage-icon.svg" alt="percentage-icon" />
          </div>
        </div>

        <div className="mx-2 md:mx-5 max-w-screen-xl xl-padded:mx-auto">

          <div className="sm-pt-0 pt-4 2xl:pt-8 pb-0 mx-auto flex max-w-4xl flex-col items-center text-center">
            <p className="mb-4 whitespace-pre-line text-md">{t("Offers.Special Offers & Exclusive Deals")}</p>
            <h2 className="whitespace-pre-line break-words text-xl md:text-2xl mb-4">{t("Offers.Find your perfect deal!")}</h2>
            <h3 className="tablet-none mobile-none whitespace-pre-line break-words text-lg md:text-xl mb-4">{t("Offers.Unique opportunities and last-minute bargains")}</h3>
            <p className="whitespace-pre-line text-2md font-normal md:text-lg-paragraph md:font-normal">{t("Offers.Discover top-notch offers for camper rentals")}, {t("Offers.exclusive discounts,")} {t("Offers.and other ways to save on your travel budget!")}</p>
          </div>
          {
            Deal.map((element) => {
              return (
                <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12 md:mx-5 mx-3 max-w-screen-xl xl-padded:mx-auto pt-5 pb-8">
                  <div className="overflow-hidden rounded-lg bg-white shadow dark:bg-off-black-desat dark:shadow-dark group/parent mask-image relative p-0 md:flex md:min-h-[400px]  md:bg-transparent dark:md:bg-transparent justify-end">
                    <Link
                      // to={"/campers"}
                      href={element.button_link ? element.button_link : ''} target="_blank"
                      // to={{ pathname: element.button_link ? element.button_link :'' }}
                      // href={element.button_link ? element.button_link :''}
                      // target="_blank"
                      className="w-full md:flex justify-end"
                    // href=""
                    >

                      <div className="md:hidden">
                        <div className="overflow-hidden">
                          <div className="-ml-[5%] w-[110%] overflow-hidden rounded-b-[60%20%] px-[5%]">
                            <div className="relative">
                              <picture className="text-0">
                                {/* <img
                                  src="images/deals-mobile.jpg"
                                  alt=""
                                /> */}
                                <img src={element.mobile_image ? dealUrl + element.mobile_image : ''} alt="" className="" />

                              </picture>
                            </div>
                          </div>
                        </div>
                      </div>
                      <picture className="text-0 absolute top-0 left-0 z-background hidden h-full w-full transition-all duration-300 group-hover/parent:scale-105 md:block hidden md:group-hover/parent:scale-[103%]">
                        {/* <img src="images/deals.png" alt="" className='h-full' /> */}
                        <img src={element.tablet_image ? dealUrl + element.tablet_image : ''} alt="" className='h-full inline-block max-w-full object-cover dark:brightness-90 h-full w-full' />
                      </picture>

                      <picture className="text-0 absolute top-0 left-0 z-background hidden h-full w-full transition-all duration-300 group-hover/parent:scale-105  xl:block hidden md:group-hover/parent:scale-[103%]">
                        {/* <img src="images/deals.png" alt="" className='h-full' /> */}
                        <img src={element.feature_image ? dealUrl + element.feature_image : ''} alt="" className='h-full inline-block max-w-full object-cover dark:brightness-90 h-full w-full' />
                      </picture>
                      <div className="relative -mt-10 mb-4 flex justify-center xs:mb-4 hidden">
                        <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark child:h-20 child:w-20">
                          {/* <img
                            src="images/percentage-icon.svg"
                            alt="percentage-icon"
                          /> */}
                          <img className="opacity-sm-0" src={element.logo ? dealUrl + element.logo : ''} alt="percentage-icon" />

                        </div>
                      </div>
                      <div className="z-0 m-6 flex flex-col text-center xs:mt-0 md:m-5 md:mt-5 md:min-h-[calc(100%-theme(spacing.5)*2)] md:w-full md:max-w-1/2 md:rounded-lg md:bg-white md:p-8 md:text-left md:shadow md:dark:bg-off-black-desat xl:max-w-[45%] 2xl:m-9 2xl:mt-9 2xl:min-h-[calc(100%-theme(spacing.9)*2)]">
                        <div className="mb-2 md:mb-6  flex items-center justify-center md:justify-start">
                          <div className="relative mr-4 hidden md:block">
                            <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark child:h-16 child:w-16">
                              {/* <img
                                src="images/percentage-icon.svg"
                                alt="percentage-icon"
                              /> */}
                              <img src={element.logo ? dealUrl + element.logo : ''} alt="percentage-icon" />
                            </div>
                          </div>
                          <p className="small-lg text-lg price-font">
                            {/* Get 60€ discount */}
                            {element.title ? element.title : ' '}

                          </p>
                        </div>
                        <h3 className="whitespace-pre-line break-words mb-4 text-xl xs:mb-6">
                          {element.sub_title ? element.sub_title : ''}
                        </h3>
                        <div className="mb-4 md:mb-6">
                          <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal price-font image-box-text">
                            {element.description ? element.description : ''}
                            {/* Camping in autumn is always a good idea. With our autumn
                            discount you save 60€ on your booking. */}
                          </div>
                          <div className="hidden md:visible min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">

                          </div>
                        </div>
                        <div className="group/button block disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white button-primary-bg dark:bg-primary-dark p-3 pl-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated w-full md:mt-auto">
                          <div className="flex h-full items-center justify-between">
                            <span className="flex">
                              {/* Check out autumn deals */}
                              {element.button_text ? element.button_text : ''}
                            </span>
                            <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                              <svg width="32" height="32" viewBox="0 0 32 32">
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                                    fill="var(--svg-color-secondary, #fff)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              )
            })
          }

          <div className="pt-12 md:mx-5 max-w-screen-xl xl-padded:mx-auto">
            <div className="mx-3">
              <div className="newsletter-wrapper rounded bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg flex flex-col items-center overflow-visible from-secondary/90 to-secondary/50 text-center text-white dark:to-primary-dark 2xl:p-4 mt-10 pt-0 2xl:mt-12 2xl:pt-0">
                <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-10 mb-4 child:h-20 child:w-20 md:child:h-24 md:child:w-24 2xl:-mt-12">
                  <img src="images/newsletter-icon.svg" alt="newsletter-icon" />
                </div>
                <h2 className="whitespace-pre-line break-words mb-4 max-w-2xl text-xl md:text-2xl text-orange">
                  {t("aboutus.Sign up now and save 40€ !")}
                </h2>
                <p className="mb-8 max-w-lg">
                  {t("aboutus.Receive travel tips, exclusive offers, and booking vouchers directly in your inbox.")} {t("aboutus.Upon signing up, you'll receive a 40€ voucher for your first booking via email!")}
                  <br />
                  <p className="mobile-none">{t("aboutus.Act quickly")}: {t("aboutus.The newsletter discount is valid for first bookings only and cannot be combined with other offers.")}<br />

                    {t("aboutus.Your next adventure awaits! Sign up now!")}</p>
                </p>
                {
                  isFormSubmit ?
                    <>
                      <div className="opacity-100 scale-100 success-msg">
                        <div className="inline-flex items-center justify-center rounded-full orange-border bg-white p-1 dark:bg-off-white">
                          <div className="inline-block align-top">
                            <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                              <g fill="none" fill-rule="evenodd">
                                <path d="m21.906 9.488-8.547 9.56-3.45-3.096a1.757 1.757 0 0 0-2.563.209c-.64.792-.547 1.98.21 2.659l4.764 4.274c.735.66 1.833.602 2.496-.13l9.73-10.869a1.954 1.954 0 0 0-.069-2.665 1.758 1.758 0 0 0-2.571.058z" fill="var(--svg-color-secondary, #163435)"></path>
                              </g>
                            </svg>
                          </div>
                        </div>
                        <h2 className="mt-4 max-w-lg text-lg-paragraph"> {t("aboutus.Thank you for signing up!")}</h2>
                      </div>
                    </>
                    :
                    <form
                      className="flex w-full max-w-3xl flex-col lg:grid lg:grid-cols-3 lg:gap-4 position-relative"
                      onSubmit={onSubmitForm}
                    >

                      <div className="transition-all mb-4">
                        <div className="relative z-foreground mb-4 flex h-[60px] items-center rounded border bg-white text-black transition-all focus-within:border-primary focus:border-primary dark:focus-within:border-primary-dark border-transparent shadow dark:shadow-dark dark:bg-off-white dark:text-black">
                          <div className="inline-block align-top absolute pl-3 child:h-7 child:w-7 dark:cssvar-secondary-to-off-white dark:cssvar-secondary-to-secondary">
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M16 14.6527C13.6105 14.6527 11.6736 12.7158 11.6736 10.3264C11.6736 7.93688 13.6105 6 16 6C18.3895 6 20.3264 7.93688 20.3264 10.3264C20.3264 12.7158 18.3895 14.6527 16 14.6527Z"
                                fill="var(--svg-color-secondary, #163435)"
                              ></path>
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M16.1565 15.9742C12.2188 15.8883 9 19.0545 9 22.9726C9 23.4621 9.05258 23.9383 9.15042 24.3979C9.26224 24.9247 9.72616 25.3021 10.2643 25.3021H21.7361C22.2693 25.3021 22.7352 24.9324 22.847 24.4112C23.0091 23.6528 23.05 22.8501 22.9329 22.0181C22.4596 18.6482 19.5587 16.0487 16.1565 15.9742Z"
                                fill="var(--svg-color-secondary, #163435)"
                              ></path>
                            </svg>
                          </div>
                          <div className="peer flex w-full flex-col">
                            <input
                              type="text"
                              className="peer w-full rounded bg-transparent pt-2 pb-1 pl-12 text-md outline-none focus:pr-6 pr-6"
                              placeholder={t("sale.First Name")}
                              name='first_name'
                            />
                          </div>
                        </div>
                        <div className="relative bottom-4 overflow-hidden bg-warning text-center text-xs font-medium text-black transition-all -mt-4 rounded-b max-h-0"></div>
                      </div>
                      <div className="transition-all mb-4">
                        <div className="relative z-foreground mb-4 flex h-[60px] items-center rounded border bg-white text-black transition-all focus-within:border-primary focus:border-primary dark:focus-within:border-primary-dark border-transparent shadow dark:shadow-dark dark:bg-off-white dark:text-black">
                          <div className="inline-block align-top absolute pl-3 child:h-7 child:w-7 dark:cssvar-secondary-to-off-white dark:cssvar-secondary-to-secondary">
                            <svg
                              width="32px"
                              height="32px"
                              viewBox="0 0 32 32"
                              version="1.1"
                            >
                              <title>icon_contact_mail</title>
                              <g
                                id="icon_contact_mail"
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fillRule="evenodd"
                              >
                                <g
                                  id="Group"
                                  transform="translate(5.000000, 7.700000)"
                                  fill="var(--svg-color-secondary, #163435)"
                                >
                                  <path
                                    d="M17.1541722,0 C18.5243618,0 19.2165846,0.133674373 19.9368674,0.518885858 C20.6000248,0.873546296 21.1264537,1.39997522 21.4811141,2.06313259 C21.8663256,2.78341544 22,3.47563815 22,4.8458278 L21.9981828,11.9648343 C21.9810588,13.1622375 21.8406449,13.810058 21.4811141,14.482322 C21.1264537,15.1454793 20.6000248,15.6719082 19.9368674,16.0265687 C19.2165846,16.4117802 18.5243618,16.5454545 17.1541722,16.5454545 L4.8458278,16.5454545 C3.47563815,16.5454545 2.78341544,16.4117802 2.06313259,16.0265687 C1.39997522,15.6719082 0.873546296,15.1454793 0.518885858,14.482322 C0.133674373,13.7620391 0,13.0698164 0,11.6996267 L0,4.8458278 C0,3.47563815 0.133674373,2.78341544 0.518885858,2.06313259 C0.873546296,1.39997522 1.39997522,0.873546296 2.06313259,0.518885858 C2.78341544,0.133674373 3.47563815,0 4.8458278,0 Z M20,5.563 L11,12.1092247 L2,5.564 L2,11.6996267 L2.00467769,12.1173697 C2.02375106,12.8767631 2.10330814,13.2040417 2.28251225,13.5391242 C2.45077309,13.8537447 2.69170989,14.0946815 3.0063303,14.2629423 C3.31348932,14.4272127 3.61409085,14.507752 4.24698433,14.5347258 L4.6271902,14.5443078 L17.3728098,14.5443078 C17.4423488,14.5435357 17.5086323,14.5423663 17.5719151,14.5407769 L17.9176389,14.5260186 C18.4406182,14.4924165 18.7144342,14.4122791 18.9936697,14.2629423 C19.3082901,14.0946815 19.5492269,13.8537447 19.7174877,13.5391242 C19.9265592,13.1481946 20,12.767887 20,11.6996267 L20,5.563 Z M17.1541722,2 L4.8458278,2 L4.42808487,2.00467769 C3.66869148,2.02375106 3.34141286,2.10330814 3.0063303,2.28251225 C2.69170989,2.45077309 2.45077309,2.69170989 2.28251225,3.0063303 C2.24438729,3.07761778 2.21077244,3.14855205 2.1812906,3.22336911 L10.999,9.63500008 L19.8187094,3.22336911 C19.7892276,3.14855205 19.7556127,3.07761778 19.7174877,3.0063303 C19.5492269,2.69170989 19.3082901,2.45077309 18.9936697,2.28251225 C18.6585871,2.10330814 18.3313085,2.02375106 17.5719151,2.00467769 L17.1541722,2 Z"
                                    id="Combined-Shape"
                                  ></path>
                                </g>
                              </g>
                            </svg>
                          </div>
                          <div className="peer flex w-full flex-col">
                            <input
                              type="email"
                              className="peer w-full rounded bg-transparent pt-2 pb-1 pl-12 text-md outline-none focus:pr-6 pr-6"
                              placeholder={t("sale.Email")} name='email'
                            />

                          </div>
                        </div>
                        <div className="relative bottom-4 overflow-hidden bg-warning text-center text-xs font-medium text-black transition-all -mt-4 rounded-b max-h-0"></div>
                      </div>

                      <button
                        type="submit"
                        className="group/button disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white button-primary-bg dark:bg-primary-dark p-3 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated pl-3 py-2 inline-block lg:h-[60px]"
                      >
                        <div className="flex h-full items-center justify-between">
                          <span className="flex">{t("aboutus.Receive Voucher")}</span>
                          <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                            <svg
                              width="32"
                              height="32"
                              viewBox="0 0 32 32"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g fill="#000000" fillRule="evenodd">
                                <path
                                  d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                                  fill="#fff"
                                ></path>
                              </g>
                            </svg>
                          </div>
                        </div>
                      </button>
                      {Error ? <p className="mb-2 flex w-full animate-wiggle items-center justify-between rounded bg-warning px-4 py-2 text-lg text-white" style={{ color: 'red' }}>{Error}</p> : ''}

                    </form>
                }
              </div>
            </div>

          </div>

          <div className="dislaimer-text pt-4 2xl:pt-12 pb-16 2xl:pb-24 text-center mx-auto max-w-3xl">
            <h5 className="my-5 first:mt-0 break-words text-lg md:text-2md sm-mb-2">{t("Offers.Disclaimer")}</h5>
            <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
              {t("Offers.*Applicable to all camper models and accessories.")}<br /> {t("Offers.Not applicable for camper subscription and voucher purchases.")} {t("Offers.Cannot be combined with other discounts.")} <br />{t("Offers.Only for a short time.")}
            </div>

          </div>

        </div>

      </div>

      <Footer />
    </>
  );
};

export default Offers;
