import React, { useState, useEffect } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import TripplannerService from "../../Api/services/Tripplanner";
import { Link, useNavigate ,useSearchParams} from "react-router-dom";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
const Thankyoutripplanner = () => {
  const { i18n, t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams()
  window.onpopstate = function (e) {
    console.log(e.state)
}
  useEffect(() => {
    // window.noBack();
    checkstatus()
    localStorage.removeItem("payment_status_trip")
    localStorage.removeItem("stripedatatrip")
    localStorage.removeItem("stripesessiontrip")
    localStorage.removeItem("stripedatatripprice")
    localStorage.removeItem("stripedatatriporderid")
   
  },[]);
  Cookies.set('Token', 'value', { expires: 7 })
  const checkstatus = async () => {
    var getId = searchParams.get('id')
    if(getId)
    {
      var p_service=await TripplannerService.checkstripepayment(getId).json()
    }
    
    // if (localStorage.getItem('stripesessiontrip')) {
    //   var stripesessiondata = JSON.parse(localStorage.getItem('stripesessiontrip'));
    //   var p_service = await TripplannerService.checkstripepayment(stripesessiondata.id).json()
    //   if (p_service.status == true) {
    //     if (localStorage.getItem('stripedatatrip')) {
    //       var stripedatatrip = JSON.parse(localStorage.getItem('stripedatatrip'));
    //       var price = JSON.parse(localStorage.getItem('stripedatatripprice'));
    //       var orderid = JSON.parse(localStorage.getItem('stripedatatriporderid'));
          
    //       var payment_service = await TripplannerService.store(stripedatatrip).json()
    //       if (payment_service.status == true) {
    //         var paymentid = payment_service.id;
    //         var data = {
    //           name: p_service.name,
    //           email: p_service.email,
    //           id: paymentid,
    //           price: price,
    //           order_id: orderid,
    //         }
    //         await TripplannerService.updatepaymentStatus(data).json()
    //       }
    //       var userData = {
    //         "name": stripedatatrip.first_last_name,
    //         "email": stripedatatrip.email,
    //         "lng": i18n.resolvedLanguage
    //       }
    //       var payment_service = await TripplannerService.sendtripmail(userData).json()
    //     }
    //   }
     
    // }
    // localStorage.removeItem("payment_status_trip")
  }
  return (
    <>
      <Header />

      <div className="min-h-screen bg-off-white">



        <div className="pb-5 text-center">
          <div className="overflow-hidden">
            <div className="-ml-[5%] w-[110%] overflow-hidden rounded-b-[60%20%] px-[5%]">
              <div className="relative">
                <div className="overflow-hidden">
                  <img
                    src="../images/Thank you pages.jpg"
                    alt="hero-banner"
                    className="absolute max-h-full w-full scale-110 object-cover blur-lg dark:brightness-90 sm:aspect-hero-regular 3xl:aspect-hero-long aspect-square"
                  />
                </div>
                <picture className="flex max-h-[65vh] w-full text-0">
                  <img
                    className="z-0 max-h-full w-full object-cover dark:brightness-90 sm:aspect-hero-regular 3xl:aspect-hero-long aspect-square"
                    src="../images/Thank you pages.jpg"
                    alt="hero-banner"
                    width="2552"
                    height="1286"
                  />
                </picture>
                <div className="absolute top-0 left-0 flex h-full w-full flex-col items-center justify-end">
                  <div className="pb-12 2xl:pb-14 w-full bg-gradient-to-t from-black-pure/80 to-transparent pt-20 md:pt-14 xl:pt-32">
                    <h1 className="whitespace-pre-line break-words m-auto max-w-4xl px-8 text-xl md:text-2xl text-white drop-shadow-text dark:text-off-white xs:text-2xl xl:text-3xl mb-5 md:mb-8 xl:mb-12">
                      {t("thankyou.Thank You")}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mx-5 max-w-screen-xl xl-padded:mx-auto">

          <div className="xl:pt-8 pt-3 2xl:pt-8 pb-8 2xl:pb-12 mx-auto flex max-w-4xl flex-col items-center text-center">
            <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark mb-4 child:h-20 child:w-20">
              <img src="../images/aboutus-header-banner.svg" alt="aboutus-header-banner" />
            </div>
            {/* <p className="mb-4 whitespace-pre-line text-md">What we strive for</p> */}
            <h2 className="whitespace-pre-line break-words text-xl md:text-2xl mb-4">
              {t("thankyou.Thank you for your trust!")}
            </h2>
            <p className="mb-2 whitespace-pre-line text-2md font-normal md:text-lg-paragraph md:font-normal text-20">
              {t("thankyou.You can expect an email with your travel itinerary in the next few days.")}
            </p>
            <p className="mb-2 whitespace-pre-line text-2md font-normal md:text-lg-paragraph md:font-normal text-20">
              {t("thankyou.If you have any questions before then, don't hesitate to contact us!")}
            </p>
            <p className="mb-2 whitespace-pre-line text-2md font-normal md:text-lg-paragraph md:font-normal text-20">
              WhatsApp: +49 163 2937354
            </p>
            {/* <p className="mb-2 whitespace-pre-line text-2md font-normal md:text-lg-paragraph md:font-normal text-20">
            {t("thankyou.Go To")} <Link className="w-full text-center hover:text-primary hover:dark:text-primary-light mb-2 last:mb-0" to={'/'}>{t("thankyou.Home")}</Link>
            </p> */}
          </div>


        </div>

      </div>

      <Footer />
    </>
  );
};

export default Thankyoutripplanner;
