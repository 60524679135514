import React, { useState, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';

import '@splidejs/react-splide/css';
import '@splidejs/react-splide/css/core';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HomeService from "../../Api/services/HomeService";
import { t } from "i18next";
const Vanlife = () => {

  const navigate = useNavigate();
  var imageUrl = process.env.REACT_APP_LARAVEL_BLOG_IMAGE_URL;
  const [blog, setBlog] = useState([]);

  useEffect(() => {
    getBlog();

  }, []);

  const getBlog = async () => {
    try {
      let blogList = await HomeService.index().json();

      setBlog(blogList.data);

    } catch (error) {
      console.log(error);
    }
  };
  var vanlifeslider = {
    // prevArrow: <CustomArrow direction="prev" />,
    // nextArrow: <CustomArrow direction="next" />,
    arrows: true,
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [

      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          dots: true,
          arrows: false,
          infinite: true,
        },
      },

    ],
  };
  const handleBlog = async (id) => {
    try {
      let blogList = await HomeService.index(id).json();

      setBlog(blogList.data);
      navigate(`/bloginner/${id}`);
      window.location.reload();

    } catch (error) {
      console.log(error);
    }
  }
  return (
    <>
      <div className="topeq-slider pt-5 pb-12 blog-slider blogs-slider min-h-623">
        <div className="top-equipped-slider">
          <Slider className="camper-slider" {...vanlifeslider}>
            {blog.map((item, index) => (
              <div className="div h-full">
                {/* <Link to={`/bloginner/${item.id}`} > */}
                <Link to="#" onClick={() => handleBlog(item.id)}>

                  <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent group flex h-full flex-col p-4 hover:shadow-elevated hover:dark:shadow-dark-elevated">
                    <div className="mask-image shrink-0 overflow-hidden rounded-sm 2xs:rounded-md">
                      <picture className="text-0 h-full w-full">
                        <img
                          className="inline-block max-w-full object-cover dark:brightness-90 aspect-square max-h-[270px] w-full group-hover:scale-110 transition-all duration-300"
                          src={imageUrl + item.blog_image}
                          alt="Parental-leave"
                        />
                      </picture>
                    </div>
                    <div className="flex h-full flex-col items-center justify-center px-4 text-center">
                      <p className="whitespace-pre-line text-lg mt-4">
                        {item.title}
                      </p>
                      <p className="mt-3 whitespace-pre-line line-clamp-5">
                        {item.sub_title}
                      </p>
                      <div className="mt-auto">
                        <div className="group/button disabled:opacity-50 border border-transparent text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated p-1 rounded-full mt-4 inline-block px-4">
                          <div className="flex h-full items-center justify-center">
                            <span className="flex">{t("home.Read more")}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}

          </Slider>
        </div>
      </div>




    </>
  );
};
export default Vanlife;
