import React, { useState } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import {  Link} from "react-router-dom";



const  Typo= () => {
  
    return (
        <>
            <Header />
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/><br/>
            



          
          <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12">
            <div className="">
              <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                <picture className="text-0 flex">
                  <img
                    className="inline-block object-cover dark:brightness-90 rounded 2xs:rounded-lg w-full max-w-3xl mx-auto max-h-385"
                    src="images/picture for Camper rent-long.jpg"
                    alt="picture for Camper rent-long"
                  />
                </picture>
              </div>
            </div>

            <div className="">
              <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                <h3 className="my-5 first:mt-0 break-words text-lg md:text-xl f-NolandBold">
                  The Benefits of Camper and Motorhome Rental: Your Key to Limitless Freedom
                </h3>
                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal f-Salsa">
                Discover the Freedom of Camping with Us! Enjoy the flexibility to stay exactly where you like, with pick-up and drop-off times from 9:00 AM to 5:00 PM. We have no service fees, our campers come fully equipped, and you can personalize them to your style.
                </div>
                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                  <Link
                    to={"/campers"}
                    className="group/button disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white button-primary-bg dark:bg-primary-dark p-3 pl-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated inline-block f-Salsa"
                  >
                    <div className="flex h-full items-center justify-between">
                      <span className="flex">Rent your camper now</span>
                      <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g fill="none" fillRule="evenodd">
                            <path
                              d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                              fill="var(--svg-color-secondary, #163435)"
                            ></path>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>


            
          </div>

          <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12">
            <div className="">
              <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                <picture className="text-0 flex">
                  <img
                    className="inline-block object-cover dark:brightness-90 rounded 2xs:rounded-lg w-full max-w-3xl mx-auto max-h-385"
                    src="images/picture for Camper rent-long.jpg"
                    alt="picture for Camper rent-long"
                  />
                </picture>
              </div>
            </div>

            <div className="">
              <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                <h3 className="my-5 first:mt-0 break-words text-lg md:text-xl f-NolandBold">
                The Benefits of Camper and Motorhome Rental: Your Key to Limitless Freedom
                </h3>
                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal f-COMIC">
                  <b>Discover the Freedom of Camping with Us! Enjoy the flexibility to stay exactly where you like, with pick-up and drop-off times from 9:00 AM to 5:00 PM. We have no service fees, our campers come fully equipped, and you can personalize them to your style.</b>
                </div>
                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                  <Link
                    to={"/campers"}
                    className="f-COMIC group/button disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white button-primary-bg dark:bg-primary-dark p-3 pl-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated inline-block"
                  >
                    <div className="flex h-full items-center justify-between">
                      <span className="flex">Rent your camper now</span>
                      <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g fill="none" fillRule="evenodd">
                            <path
                              d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                              fill="var(--svg-color-secondary, #163435)"
                            ></path>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>


            
          </div>


          <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12">
            <div className="">
              <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                <picture className="text-0 flex">
                  <img
                    className="inline-block object-cover dark:brightness-90 rounded 2xs:rounded-lg w-full max-w-3xl mx-auto max-h-385"
                    src="images/picture for Camper rent-long.jpg"
                    alt="picture for Camper rent-long"
                  />
                </picture>
              </div>
            </div>

            <div className="">
              <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                <h3 className="my-5 first:mt-0 break-words text-lg md:text-xl f-NolandBold">
                The Benefits of Camper and Motorhome Rental: Your Key to Limitless Freedom
                </h3>
                <div className="whitespace-pre-line text-2md font-normal md:text-lg-paragraph md:font-normal">
                Discover the Freedom of Camping with Us! Enjoy the flexibility to stay exactly where you like, with pick-up and drop-off times from 9:00 AM to 5:00 PM. We have no service fees, our campers come fully equipped, and you can personalize them to your style.
                </div>
                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                  <Link
                    to={"/campers"}
                    className="group/button disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white button-primary-bg dark:bg-primary-dark p-3 pl-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated inline-block"
                  >
                    <div className="flex h-full items-center justify-between">
                      <span className="flex">Rent your camper now</span>
                      <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g fill="none" fillRule="evenodd">
                            <path
                              d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                              fill="var(--svg-color-secondary, #163435)"
                            ></path>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>


            


            
          </div>


          <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12">
            <div className="">
              <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                <picture className="text-0 flex">
                  <img
                    className="inline-block object-cover dark:brightness-90 rounded 2xs:rounded-lg w-full max-w-3xl mx-auto max-h-385"
                    src="images/picture for Camper rent-long.jpg"
                    alt="picture for Camper rent-long"
                  />
                </picture>
              </div>
            </div>

            <div className="">
              <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                <h3 className="my-5 first:mt-0 break-words text-lg md:text-xl f-NolandBold">
                The Benefits of Camper and Motorhome Rental: Your Key to Limitless Freedom
                </h3>
                <div className="whitespace-pre-line text-2md font-normal  md:font-normal f-Lato">
                Discover the Freedom of Camping with Us! Enjoy the flexibility to stay exactly where you like, with pick-up and drop-off times from 9:00 AM to 5:00 PM. We have no service fees, our campers come fully equipped, and you can personalize them to your style.
                </div>
                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                  <Link
                    to={"/campers"}
                    className="group/button disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white button-primary-bg dark:bg-primary-dark p-3 pl-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated inline-block"
                  >
                    <div className="flex h-full items-center justify-between">
                      <span className="flex">Rent your camper now</span>
                      <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                        <svg
                          width="32"
                          height="32"
                          viewBox="0 0 32 32"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g fill="none" fillRule="evenodd">
                            <path
                              d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                              fill="var(--svg-color-secondary, #163435)"
                            ></path>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>


            


            
          </div>

       


            <Footer />
    </>
  );
};

export default Typo;