import React, { useRef } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import Servicebox from "../../Components/Servicebox/Servicebox";
import Select from 'react-select'
import { Link,useParams,useSearchParams,useLocation,useNavigate,generatePath} from "react-router-dom";
import {useState,useEffect} from 'react';
import CamperService from '../../Api/services/CamperService';
import HomeService from '../../Api/services/HomeService';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { addMonths,getDate,differenceInDays  } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";
const Camperconfiguration = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const pickerRef = useRef(null);
  
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1200);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isMobile && pickerRef.current !== null) {
      pickerRef.current.input.readOnly = true;
    }
  }, [isMobile, pickerRef]);
  
  useEffect(() => {
    var header = document.getElementById("boxesWrp");  
    var btns = header.getElementsByClassName("rotateBtn");  
    for (var i = 0; i < btns.length; i++) {  
      btns[i].addEventListener("click", function() {  
      var current = document.getElementsByClassName("active");  
      if (current.length > 0) {   
        current[0].className = current[0].className.replace(" active", "");  
      }  
      this.parentElement.className += " active";  
      });  
    }  
  });


  var imageUrl = process.env.REACT_APP_LARAVEL_CAMPER_IMAGE_URL;
  const getDetails = useLocation();
  const propsData = getDetails.state;
  // console.log(propsData)
  const params = useParams();
  const variable = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams()
  const [counter, setCounter] = useState(0);
  const [count, setCount] = useState(0);
  const [error, setError] = useState("")
  const [camper, setCamper] = useState([]);
  const [selectPackages, setSelectPackages] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [skills, setSkills] = useState([]);
  const [serviceFee, setServiceFee] = useState('');
  const [location, setLocation] = useState([]);
  const [kilometer, setKilometer] = useState([]);
  const [periodValue, setPeriodValue] = useState([]);
  const [Value, setValue] = useState([]);
  const [period, setPeriod] = useState([]);

  const [checkedValue, setCheckedValue] = useState(true);



  

  let countMonth =searchParams.get('duration')

  useEffect(() => {
    var id = params.id;
    getCamperByID(id);
    getKilometer();
    getLocation();
    getPeriod();
  }, []);

  const addChecked = (itemId, itemTitle, itemPrice) => {
    setSelectPackages([
      ...selectPackages,
      { id: itemId, title: itemTitle, price: itemPrice }
    ]);
  };
  const removeChecked = (itemId) => {
    const toBeRemove = selectPackages.find(
      (item) => item.id === itemId 
    );
    if (toBeRemove) {
      selectPackages.splice(selectPackages.indexOf(toBeRemove), 1);
      setSelectPackages([...selectPackages]);
    }
  };
  const [totalAmount, setTotalAmount] = useState(0)

  function updateTotal(e, amount) {
    setTotalAmount((totalAmount) => {
      if (e.target.checked) {
        totalAmount = totalAmount + amount;
        return totalAmount
      } else {
        totalAmount = totalAmount - amount;
        return totalAmount
      }
    });
  }
  const getCamperByID = async (id) => {
    try {
      let getCamper = await CamperService.show(id).json();
      //  console.log();
      setCamper(getCamper.data);
     let service_fee =getCamper.data.price_additional[0].service_fee
     setServiceFee(service_fee);
    } catch (error) {
        if (error.name === 'HTTPError') {
            const errorJson = await error.response.json();
            setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
          }
    }
  };
  const getKilometer = async () => {
    try {
      let KilometerList = await CamperService.kilometer().json();
      setKilometer(KilometerList.data);
      console.log(KilometerList)
   
    } catch (error) {
        if (error.name === 'HTTPError') {
            const errorJson = await error.response.json();
            setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
          }
    }
  };
  const getLocation = async () => {
    try {
      let locationList = await HomeService.location().json();
      setLocation(locationList.data);
      let getlocation = locationList.data.filter(function (item) {
        return item.id == propsData.location
      });
      // console.log(getdata)
      setSkills(getlocation);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSkills = (e) => {
    setSkills(e)
    let id = e.id
    if (id !== null) {
      if (variable.search == '') {
        navigate(generatePath(`?location=${id}`))
      }
      else if (variable.search !== '') {
        setSearchParams((searchParams) => {
          searchParams.set('location', `${id}`);
          return searchParams;
        });
      }
      else {
        navigate(generatePath(variable.search + `&location=${id}`))
      }
    }

  }
  const queryData = { 
    location: searchParams.get('location'), 
    subscriptionDate:searchParams.get('subscriptionDate'),
    duration:searchParams.get('duration'),
  
};
const getPeriod = async () => {
  try {
    let periodList = await HomeService.period().json();
    setPeriod(periodList.data);
    let getduration = periodList.data.filter(function (item) {
      return item.id == propsData.duration
    });
    setValue(getduration);
    } catch (error) {
      console.log(error);
    }
};
const periodChange = (e) => {

  setPeriodValue(e)
  let id = e.id
 
  if (id !== null) {
    if (variable.search == '') {
      navigate(generatePath(`?duration=${id}`))
    }
    else if (variable.search !== '') {
      setSearchParams((searchParams) => {
        searchParams.set('duration', `${id}`);
        return searchParams;
      });
    }
    else {
      navigate(generatePath(variable.search + `&duration=${id}`))
    }
  }

}
// console.log(periodValue)
const onChange = (date) => {
  // setSubscriptionDate()
  // setStartDate(propsData.subscriptionDate)
   if (variable.search == '') {
      navigate(generatePath(`?subscriptionDate=${moment(date).format('YYYY-MM-DD')}`))
    } else if (variable.search !== '') {
      setSearchParams((searchParams) => {
        searchParams.set('subscriptionDate', `${moment(date).format('YYYY-MM-DD')}`);
        return searchParams;
      });
    } else {
      navigate(generatePath(variable.search + `&subscriptionDate=${moment(date).format('YYYY-MM-DD')}`))
    }
  // navigate(generatePath(`?date=${moment(date).format('YYYY-MM-DD') }`))

}
const detailData = { 
  location: searchParams.get('location'), 
  date:searchParams.get('date'),
  pickup:searchParams.get('pickup'),
  duration:searchParams.get('duration'),
  return:searchParams.get('return'),
  packages:selectPackages,
  serviceFee:serviceFee,
  days:count,
  totalPackages:totalAmount
};
  console.log(detailData)
  const handleCheckout = () =>{
    if(detailData.date === null){
      setError(" Please Select Date! ")
   }
  }
//  const incrementCounter = () => {
//    setCounter(counter + 1);
//  };
        
//  const decrementCounter = () => {
//   if (counter !== 0) {
//       setCounter(counter - 1);
//    }
//  };


  return (
    <>
      <div className="relative flex flex-col min-h-screen font-brand pb-16 xl:pb-0">
        <div className="py-4 h-20 bg-white shadow xl:mb-5">
          <div className="flex items-center h-full xl:mx-auto xl:max-w-7xl">
          
            <Link to="/" className="flex items-center pl-4 w-1/5 h-5">
            <img className="header-camper-logo w-32" src="/images/born-camper-logo.png" alt="camper-logo" />
            </Link>
           
            <div className="flex items-center justify-center w-3/5 xl:items-start">
              <div className="stepper grid-cols4 relative grid gap-y-1.5 grid-flow-col grid-rows-2 justify-items-center w-5/6 md:w-3/4 configuration-channel">
                <button className="step relative w-full">
                  <div className="mx-auto w-6 h-6 text-center text-secondary small-text border-2px fw-500 rounded-full border-primary">
                    <svg className="w-full h-full" width="32" height="32">
                      <svg
                        fill="#163435"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 50 50"
                        width="17px"
                        height="17px"
                      >
                        <path d="M 41.9375 8.625 C 41.273438 8.648438 40.664063 9 40.3125 9.5625 L 21.5 38.34375 L 9.3125 27.8125 C 8.789063 27.269531 8.003906 27.066406 7.28125 27.292969 C 6.5625 27.515625 6.027344 28.125 5.902344 28.867188 C 5.777344 29.613281 6.078125 30.363281 6.6875 30.8125 L 20.625 42.875 C 21.0625 43.246094 21.640625 43.410156 22.207031 43.328125 C 22.777344 43.242188 23.28125 42.917969 23.59375 42.4375 L 43.6875 11.75 C 44.117188 11.121094 44.152344 10.308594 43.78125 9.644531 C 43.410156 8.984375 42.695313 8.589844 41.9375 8.625 Z" />
                      </svg>
                    </svg>
                  </div>
                </button>
                <div className="fw-500 text-center text-secondary hidden xl:block">
                  Select model
                </div>
                <button className="step relative w-full activeStep">
                  <div className="mx-auto w-6 h-6 text-center text-secondary small-text border-2px fw-500 rounded-full border-primary">
                    2
                  </div>
                </button>
                <div className="fw-500 text-center text-secondary col-span-full row-start-2 xl:col-auto">
                  Configuration
                </div>
                <button className="step relative w-full">
                  <div className="mx-auto w-6 h-6 text-center text-secondary small-text border-2px fw-500 rounded-full text-opacity-disabled">
                    3
                  </div>
                </button>
                <div className="text-center text-secondary hidden xl:block xl:text-opacity-disabled">
                  Details
                </div>
                <button className="step relative w-full">
                  <div className="mx-auto w-6 h-6 text-center text-secondary small-text border-2px fw-500 rounded-full text-opacity-disabled">
                    4
                  </div>
                </button>
                <div className="text-center text-secondary hidden xl:block xl:text-opacity-disabled">
                  Payment
                </div>
              </div>
            </div>
            <a
              className="flex flex-row items-center justify-end pr-4 w-1/5"
              href=""
            >
              <svg
                className="w-6 h-6 cssvar-secondary-to-white bg-info rounded-full"
                width="32"
                height="32"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 32 32"
                  fill="none"
                  id="icon-info"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.0951 12.6199C14.3751 12.6199 13.6671 12.6199 12.9543 12.6199C12.7986 12.6213 12.6437 12.6431 12.4935 12.6847C12.3103 12.7229 12.1704 12.8715 12.1432 13.0567C12.0925 13.2579 12.1937 13.4664 12.3831 13.551C12.6138 13.6589 12.8615 13.7255 13.1151 13.7478C13.7823 13.779 14.0511 14.0718 13.9503 14.7582C13.7631 16.0422 13.5111 17.3166 13.2951 18.5982C13.1487 19.4621 13.0263 20.3285 12.8847 21.1925C12.6951 22.3565 13.7799 23.4485 14.6415 23.5925C15.1519 23.664 15.667 23.6969 16.1823 23.6909C17.2866 23.6895 18.3415 23.2328 19.0982 22.4285C19.2357 22.3019 19.3389 22.1425 19.3982 21.9653C19.4272 21.8105 19.3907 21.6506 19.2974 21.5237C19.1847 21.4531 19.0459 21.4381 18.9206 21.4829C18.7238 21.5477 18.5486 21.6821 18.3567 21.7661C18.1323 21.8708 17.8973 21.9513 17.6559 22.0061C17.4611 22.0539 17.2552 22.0138 17.0926 21.8965C16.93 21.7792 16.8271 21.5964 16.8111 21.3965C16.7707 21.1732 16.7634 20.9452 16.7895 20.7197C16.8711 20.1629 16.9863 19.6109 17.0871 19.059C17.3607 17.5534 17.6383 16.047 17.9199 14.5398C17.9967 14.127 18.1263 13.7214 18.0399 13.287C18.0264 13.0941 17.936 12.9146 17.7888 12.7891C17.6416 12.6636 17.4501 12.6026 17.2575 12.6199C16.5375 12.6199 15.8151 12.6199 15.0951 12.6199ZM16.4007 7.28235C15.5158 7.24354 14.6954 7.7442 14.3254 8.54893C13.9553 9.35365 14.1091 10.3023 14.7145 10.9489C15.3199 11.5954 16.2564 11.8112 17.0837 11.4948C17.911 11.1783 18.4645 10.3926 18.4839 9.50711C18.5176 8.32037 17.5872 7.32888 16.4007 7.28715V7.28235Z"
                    fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"
                  ></path>
                </svg>
              </svg>
              <span className="hidden pl-2 md:block">Info</span>
            </a>
          </div>
        </div>
        <main className="flex-grow mb-64">
          <form action="">
            <div className="w-full max-w-7xl xl:flex xl:mx-auto">

              <div className="xl:w-2/3">
                <div className="md:h-[36.5rem] relative w-full overflow-hidden xl:overflow-visible">
                  <div className="absolute bottom-12 left-0 w-full h-48 bg-white rounded-b-1/2 scale-x-125 md:bottom-12 md:h-36 xl:rounded-1/2"></div>
                  <div className="relative grid place-items-center p-5 pb-5 h-full md:p-10 md:pb-10">
                    <img
                      className="w-[522px] h-64 object-contain object-bottom md:h-96"
                      src={imageUrl+camper.image}
                      alt="VW T6.1 California Beach Edition"
                    />
                  </div>
                  <div className="relative flex flex-col items-center pb-20 w-full md:hidden">
                    <div className="text-center">
                      <span className="text-center">VW</span>
                      <h3 className="break-words text-3xl text-center">
                        T6.1 California Beach Edition
                      </h3>
                    </div>
                  </div>
                </div>

                <div className="space-y-16 md:space-y-24" id="boxesWrp">
                  <section>
                    <div className="fw-500 inline-flex justify-center pb-5 px-5 w-full text-3xl md:justify-between md:px-10">
                    <h3>Kilometer</h3>
                      
                    </div>

                    <div className="grid gap-x-6 gap-y-5 grid-cols-1 px-5 md:grid-cols-4 md:px-10 lg:grid-cols-5 xl:grid-cols-4">
                      {kilometer.map((item,index)=>(
                        <div className="div">
                          <div
                            className="preserve-3d relative grid grid-cols-1 grid-rows-1 h-full transition-transform rotateBox"
                            
                          >
                            <div className="backface-hidden col-start-1 row-start-1 w-full h-full">
                          
                              <label className="group flex items-center px-2 py-2 w-full h-full bg-white border-transparent rounded shadow cursor-pointer md:flex-col md:px-4">
                                <div className="flex items-center justify-center md:my-12">
                                  <img
                                    className="w-14 h-14 md:w-20 md:h-20"
                                    src={item.icon ? process.env.REACT_APP_LARAVEL_IMAGE_URL + 'Kilometer/' + item.icon : ''}
                                    alt="usp_quick_pickup"
                                  />
                                </div>
                                <div className="flex flex-grow items-center self-stretch w-full border-secondary border-opacity-disabled md:flex-col md:pt-4 md:border-l-0 md:border-t">
                                  <div className="fw-500 flex flex-col flex-grow items-start pl-4 md:items-center md:p-0 md:text-center">
                                    <div className="text-md">{item.title}</div>
                                    <div className="font-light price-font text-lg">{item.price}&nbsp;€</div>
                                  </div>
                                  <input
                                    hidden=""
                                    name=""
                                    type="checkbox"
                                    className="peer d-none"
                                    data-gtm-form-interact-field-id="3"
                                    onClick={(e) => {
                                      if (e.target.checked) {
                                        addChecked(item.id, item.title, item.price);
                                        // setCheckedValue(false)
                                      } else {
                                        removeChecked(item.id, item.title, item.price);
                                        // setCheckedValue(false)
                                      }
                                    }}
                                    onChange = {
                                      (e) => {
                                        updateTotal(e, item.price)
                                        setCheckedValue(false)
                                      }}
                                      defaultChecked={false}
                                      value={checkedValue}
                                  ></input>
                                  <div className="peer-checked:hidden mx-auto my-2 w-7 h-7 border border-secondary group-hover:border-primary border-opacity-disabled rounded-full"></div>
                                  <svg
                                    className="peer-checked:block hidden mx-auto w-7 h-7 my-2 bg-primary rounded-full cssvar-secondary-to-white p-0.5"
                                    width="32"
                                    height="32"
                                  >
                                    <svg
                                      fill="#FFFFFF"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      width="24px"
                                      height="24px"
                                    >
                                      <path d="M 19.980469 5.9902344 A 1.0001 1.0001 0 0 0 19.292969 6.2929688 L 9 16.585938 L 5.7070312 13.292969 A 1.0001 1.0001 0 1 0 4.2929688 14.707031 L 8.2929688 18.707031 A 1.0001 1.0001 0 0 0 9.7070312 18.707031 L 20.707031 7.7070312 A 1.0001 1.0001 0 0 0 19.980469 5.9902344 z" />
                                    </svg>
                                  </svg>
                                  <div className="absolute left-0 top-0 peer-checked:block hidden w-full h-full border border-primary rounded pointer-events-none"></div>
                                </div>
                              </label>
                          
                            </div>

                            <button
                              type="button"
                              className="backface-hidden absolute right-0 top-0 w-10 h-10 -translate-y-1/2 translate-x-1/2 md:translate-x-0 md:translate-y-0 rotateBtn"
                            >
                              <div className="relative m-auto w-7 h-7">
                                <svg
                                  className="w-7 h-7 hover:cssvar-secondary-to-white hover:bg-info bg-white rounded-full shadow md:shadow-none"
                                  width="32"
                                  height="32"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 32 32"
                                    fill="none"
                                    id="icon-info"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M15.0951 12.6199C14.3751 12.6199 13.6671 12.6199 12.9543 12.6199C12.7986 12.6213 12.6437 12.6431 12.4935 12.6847C12.3103 12.7229 12.1704 12.8715 12.1432 13.0567C12.0925 13.2579 12.1937 13.4664 12.3831 13.551C12.6138 13.6589 12.8615 13.7255 13.1151 13.7478C13.7823 13.779 14.0511 14.0718 13.9503 14.7582C13.7631 16.0422 13.5111 17.3166 13.2951 18.5982C13.1487 19.4621 13.0263 20.3285 12.8847 21.1925C12.6951 22.3565 13.7799 23.4485 14.6415 23.5925C15.1519 23.664 15.667 23.6969 16.1823 23.6909C17.2866 23.6895 18.3415 23.2328 19.0982 22.4285C19.2357 22.3019 19.3389 22.1425 19.3982 21.9653C19.4272 21.8105 19.3907 21.6506 19.2974 21.5237C19.1847 21.4531 19.0459 21.4381 18.9206 21.4829C18.7238 21.5477 18.5486 21.6821 18.3567 21.7661C18.1323 21.8708 17.8973 21.9513 17.6559 22.0061C17.4611 22.0539 17.2552 22.0138 17.0926 21.8965C16.93 21.7792 16.8271 21.5964 16.8111 21.3965C16.7707 21.1732 16.7634 20.9452 16.7895 20.7197C16.8711 20.1629 16.9863 19.6109 17.0871 19.059C17.3607 17.5534 17.6383 16.047 17.9199 14.5398C17.9967 14.127 18.1263 13.7214 18.0399 13.287C18.0264 13.0941 17.936 12.9146 17.7888 12.7891C17.6416 12.6636 17.4501 12.6026 17.2575 12.6199C16.5375 12.6199 15.8151 12.6199 15.0951 12.6199ZM16.4007 7.28235C15.5158 7.24354 14.6954 7.7442 14.3254 8.54893C13.9553 9.35365 14.1091 10.3023 14.7145 10.9489C15.3199 11.5954 16.2564 11.8112 17.0837 11.4948C17.911 11.1783 18.4645 10.3926 18.4839 9.50711C18.5176 8.32037 17.5872 7.32888 16.4007 7.28715V7.28235Z"
                                      fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"
                                    ></path>
                                  </svg>
                                </svg>
                              </div>
                            </button>

                            <div className="backface-hidden col-start-1 row-start-1 rotateBoxshow">
                                      <div className="break-word relative grid grid-cols-1 grid-rows-1 place-items-center py-5 h-full text-center bg-white border border-transparent rounded shadow md:py-10 md:overflow-hidden">
                                          <div className="mx-5 max-w-full break-words md:mx-2">
                                           {item.description}
                                          </div>
                                          <button  type="button" className="absolute right-0 top-0 w-10 h-10 rotateBtn"><svg width="32px" height="32px" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#000000"/></svg></button>
                                      </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </section>

                  <section>
                    <div className="fw-500 inline-flex justify-center pb-5 px-5 w-full text-3xl md:justify-between md:px-10">
                      
                        <h3>Carefree packages</h3>
                     
                      
                    </div>
                    <div className="flex px-5 py-4 overflow-y-auto space-x-6 lg:px-10">
                    {camper.packages && camper.packages.map((packages)=>(
                      <label className="text-center group min-w-[15rem] flex flex-col items-stretch px-4 w-1/3 bg-white border border-transparent rounded shadow divide-secondary divide-opacity-disabled divide-y">
                        <h5 className="fw-500 py-6 text-lg">
                          Carefree package <span className="fw-500 py-6 text-md">"{packages.title}"</span>
                        </h5>
                        <div className="flex flex-col items-stretch justify-between h-100-c">
                        <div>
                        {packages.package_item && packages.package_item.map((package_item)=>(
                          <div className="grid  gap-y-2 content-start items-center py-2 grid-col-2">
                            <img
                              className="w-7 h-7 cssvar-secondary-to-primary"
                              height={32}
                              width={32}
                              src="/images/blue-right-mark.svg"
                              alt="blue-right-mark"
                            />
                          
                            <span className="text-left font-light text-xs">
                            {package_item.title}
                            </span>
                        
                          </div>
                          ))}
                        </div>
                        <div className=" py-6 items-center flex flex-col">
                        <div className='price-font ml-2 text-lg mb-5'>€{packages.price}.00 <span className="text-xs block">per day</span></div>
                          <input
                            type="radio"
                            className="peer d-none"
                            name="camper-packages"
                            id="carefree-id"
                            onClick={(e) => {
                              if (e.target.checked) {
                                addChecked(packages.id, packages.title, packages.price);
                              } else {
                                removeChecked(packages.id, packages.title, packages.price);
                              }
                            }}
                            onChange = {
                              (e) => {
                                updateTotal(e, packages.price)
                              }}
                              
                          />
                          <div className="peer-checked:hidden w-7 h-7 border border-secondary group-hover:border-primary border-opacity-disabled rounded-full"></div>
                          <svg
                            className="peer-checked:block hidden w-7 h-7 bg-primary rounded-full cssvar-secondary-to-white p-0.5"
                            width="32"
                            height="32"
                          >
                            <svg
                              fill="#FFFFFF"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              width="24px"
                              height="24px"
                            >
                              <path d="M 19.980469 5.9902344 A 1.0001 1.0001 0 0 0 19.292969 6.2929688 L 9 16.585938 L 5.7070312 13.292969 A 1.0001 1.0001 0 1 0 4.2929688 14.707031 L 8.2929688 18.707031 A 1.0001 1.0001 0 0 0 9.7070312 18.707031 L 20.707031 7.7070312 A 1.0001 1.0001 0 0 0 19.980469 5.9902344 z" />
                            </svg>
                          </svg>
                          <div className="peer-checked:absolute left-0 top-0 hidden w-full h-full border border-primary rounded pointer-events-none"></div>
                          
                        </div>
                        </div>
                      </label>
                      ))}
                    </div>
                  </section>

                  <section>
                    <div className="fw-500 inline-flex justify-center pb-5 px-5 w-full text-3xl md:justify-between md:px-10">
                    <h3>Services</h3>
                    </div>

                    <div className="grid gap-x-6 gap-y-5 grid-cols-1 px-5 md:grid-cols-4 md:px-10 lg:grid-cols-5 xl:grid-cols-4">
                    {camper.service && camper.service.map((service)=>(
                      <div className="div">
                        <div
                          className="preserve-3d relative grid grid-cols-1 grid-rows-1 h-full transition-transform rotateBox"
                          
                        >
                          <div className="backface-hidden col-start-1 row-start-1 w-full h-full">
                            <label className="group flex items-center px-2 py-2 w-full h-full bg-white border-transparent rounded shadow cursor-pointer md:flex-col md:px-4">
                              <div className="flex items-center justify-center md:my-12">
                                <img
                                  className="w-14 h-14 md:w-20 md:h-20"
                                  src={service.icon ? process.env.REACT_APP_LARAVEL_IMAGE_URL + 'Service/' + service.icon : ''}
                                  alt="usp_quick_pickup"
                                />
                              </div>
                              <div className="flex flex-grow items-center self-stretch w-full border-secondary border-opacity-disabled md:flex-col md:pt-4 md:border-l-0 md:border-t">
                                <div className="fw-500 flex flex-col flex-grow items-start pl-4 md:items-center md:p-0 md:text-center">
                                  <div className="text-lg">{service.title}</div>
                                  <div className="font-light price-font text-lg">{service.price}&nbsp;€</div>
                                </div>
                                <input
                                  hidden=""
                                  name="+products.1257"
                                  type="checkbox"
                                  className="peer d-none"
                                  data-gtm-form-interact-field-id="3"
                                  onClick={(e) => {
                                    if (e.target.checked) {
                                      addChecked(service.id, service.title, service.price);
                                    } else {
                                      removeChecked(service.id, service.title, service.price);
                                    }
                                  }}
                                  onChange = {
                                    (e) => {
                                      updateTotal(e, service.price)
                                    }}
                                ></input>
                                <div className="peer-checked:hidden mx-auto my-2 w-7 h-7 border border-secondary group-hover:border-primary border-opacity-disabled rounded-full"></div>
                                <svg
                                  className="peer-checked:block hidden mx-auto w-7 h-7 my-2 bg-primary rounded-full cssvar-secondary-to-white p-0.5"
                                  width="32"
                                  height="32"
                                >
                                  <svg
                                    fill="#FFFFFF"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="24px"
                                    height="24px"
                                  >
                                    <path d="M 19.980469 5.9902344 A 1.0001 1.0001 0 0 0 19.292969 6.2929688 L 9 16.585938 L 5.7070312 13.292969 A 1.0001 1.0001 0 1 0 4.2929688 14.707031 L 8.2929688 18.707031 A 1.0001 1.0001 0 0 0 9.7070312 18.707031 L 20.707031 7.7070312 A 1.0001 1.0001 0 0 0 19.980469 5.9902344 z" />
                                  </svg>
                                </svg>
                                <div className="absolute left-0 top-0 peer-checked:block hidden w-full h-full border border-primary rounded pointer-events-none"></div>
                              </div>
                            </label>
                          </div>

                          <button
                            type="button"
                            className="backface-hidden absolute right-0 top-0 w-10 h-10 -translate-y-1/2 translate-x-1/2 md:translate-x-0 md:translate-y-0 rotateBtn"
                          >
                            <div className="relative m-auto w-7 h-7">
                              <svg
                                className="w-7 h-7 hover:cssvar-secondary-to-white hover:bg-info bg-white rounded-full shadow md:shadow-none"
                                width="32"
                                height="32"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 32 32"
                                  fill="none"
                                  id="icon-info"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M15.0951 12.6199C14.3751 12.6199 13.6671 12.6199 12.9543 12.6199C12.7986 12.6213 12.6437 12.6431 12.4935 12.6847C12.3103 12.7229 12.1704 12.8715 12.1432 13.0567C12.0925 13.2579 12.1937 13.4664 12.3831 13.551C12.6138 13.6589 12.8615 13.7255 13.1151 13.7478C13.7823 13.779 14.0511 14.0718 13.9503 14.7582C13.7631 16.0422 13.5111 17.3166 13.2951 18.5982C13.1487 19.4621 13.0263 20.3285 12.8847 21.1925C12.6951 22.3565 13.7799 23.4485 14.6415 23.5925C15.1519 23.664 15.667 23.6969 16.1823 23.6909C17.2866 23.6895 18.3415 23.2328 19.0982 22.4285C19.2357 22.3019 19.3389 22.1425 19.3982 21.9653C19.4272 21.8105 19.3907 21.6506 19.2974 21.5237C19.1847 21.4531 19.0459 21.4381 18.9206 21.4829C18.7238 21.5477 18.5486 21.6821 18.3567 21.7661C18.1323 21.8708 17.8973 21.9513 17.6559 22.0061C17.4611 22.0539 17.2552 22.0138 17.0926 21.8965C16.93 21.7792 16.8271 21.5964 16.8111 21.3965C16.7707 21.1732 16.7634 20.9452 16.7895 20.7197C16.8711 20.1629 16.9863 19.6109 17.0871 19.059C17.3607 17.5534 17.6383 16.047 17.9199 14.5398C17.9967 14.127 18.1263 13.7214 18.0399 13.287C18.0264 13.0941 17.936 12.9146 17.7888 12.7891C17.6416 12.6636 17.4501 12.6026 17.2575 12.6199C16.5375 12.6199 15.8151 12.6199 15.0951 12.6199ZM16.4007 7.28235C15.5158 7.24354 14.6954 7.7442 14.3254 8.54893C13.9553 9.35365 14.1091 10.3023 14.7145 10.9489C15.3199 11.5954 16.2564 11.8112 17.0837 11.4948C17.911 11.1783 18.4645 10.3926 18.4839 9.50711C18.5176 8.32037 17.5872 7.32888 16.4007 7.28715V7.28235Z"
                                    fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"
                                  ></path>
                                </svg>
                              </svg>
                            </div>
                          </button>

                          <div className="backface-hidden col-start-1 row-start-1 rotateBoxshow">
                                      <div className="break-word relative grid grid-cols-1 grid-rows-1 place-items-center py-5 h-full text-center bg-white border border-transparent rounded shadow md:py-10 md:overflow-hidden">
                                          <div className="mx-5 max-w-full break-words md:mx-2">
                                           {service.description}
                                          </div>
                                          <button type="button" className="rotateBtn absolute right-0 top-0 w-10 h-10"><svg width="32px" height="32px" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#000000"/></svg></button>
                                      </div>
                            </div>

                            
                        </div>
                      </div>
                    ))}
                    </div>
                  </section>

                  <section>

                    <div className="fw-500 inline-flex justify-center pb-5 px-5 w-full text-3xl md:justify-between md:px-10">
                    <h3>Accessories</h3>
                    </div>

                    <div className="grid gap-x-6 gap-y-5 grid-cols-1 px-5 md:grid-cols-4 md:px-10 lg:grid-cols-5 xl:grid-cols-4">
                      {camper.accessory && camper.accessory.map((accessory)=>(
                        <div className="div">
                            <div
                              className="preserve-3d relative grid grid-cols-1 grid-rows-1 h-full transition-transform"
                              
                            >
                              <div className="backface-hidden col-start-1 row-start-1 w-full h-full">
                                <label className="group flex items-center px-2 py-2 w-full h-full bg-white border-transparent rounded shadow cursor-pointer md:flex-col md:px-4">
                                  <div className="flex items-center justify-center md:my-12">
                                    <img
                                      className="w-14 h-14 md:w-20 md:h-20"
                                      src={accessory.icon ? process.env.REACT_APP_LARAVEL_IMAGE_URL + 'Accessory/' + accessory.icon : ''}
                                      alt="usp_quick_pickup"
                                    />
                                  </div>
                                  <div className="flex flex-grow items-center self-stretch w-full border-secondary border-opacity-disabled md:flex-col md:pt-4 md:border-l-0 md:border-t">
                                    <div className="fw-500 flex flex-col flex-grow items-start pl-4 md:items-center md:p-0 md:text-center">
                                      <div className="text-lg">{accessory.title}</div>
                                      <div className="font-light price-font text-lg">€{accessory.price}</div>
                                    </div>
                                    <input
                                      hidden=""
                                      name="+products.1257"
                                      type="checkbox"
                                      className="peer d-none"
                                      data-gtm-form-interact-field-id="3"
                                      onClick={(e) => {
                                        if (e.target.checked) {
                                          addChecked(accessory.id, accessory.title, accessory.price);
                                        } else {
                                          removeChecked(accessory.id, accessory.title, accessory.price);
                                        }
                                      }}
                                      onChange = {
                                        (e) => {
                                          updateTotal(e, accessory.price)
                                        }}
                                    ></input>
                                    <div className="peer-checked:hidden mx-auto my-2 w-7 h-7 border border-secondary group-hover:border-primary border-opacity-disabled rounded-full"></div>
                                    <svg
                                      className="peer-checked:block hidden mx-auto w-7 h-7 my-2 bg-primary rounded-full cssvar-secondary-to-white p-0.5"
                                      width="32"
                                      height="32"
                                    >
                                      <svg
                                        fill="#FFFFFF"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        width="24px"
                                        height="24px"
                                      >
                                        <path d="M 19.980469 5.9902344 A 1.0001 1.0001 0 0 0 19.292969 6.2929688 L 9 16.585938 L 5.7070312 13.292969 A 1.0001 1.0001 0 1 0 4.2929688 14.707031 L 8.2929688 18.707031 A 1.0001 1.0001 0 0 0 9.7070312 18.707031 L 20.707031 7.7070312 A 1.0001 1.0001 0 0 0 19.980469 5.9902344 z" />
                                      </svg>
                                    </svg>
                                    <div className="absolute left-0 top-0 peer-checked:block hidden w-full h-full border border-primary rounded pointer-events-none"></div>
                                  </div>
                                </label>
                              </div>

                              <button
                                type="button"
                                className="backface-hidden absolute right-0 top-0 w-10 h-10 -translate-y-1/2 translate-x-1/2 md:translate-x-0 md:translate-y-0 rotateBtn"
                              >
                                <div className="relative m-auto w-7 h-7">
                                  <svg
                                    className="w-7 h-7 hover:cssvar-secondary-to-white hover:bg-info bg-white rounded-full shadow md:shadow-none"
                                    width="32"
                                    height="32"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 32 32"
                                      fill="none"
                                      id="icon-info"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M15.0951 12.6199C14.3751 12.6199 13.6671 12.6199 12.9543 12.6199C12.7986 12.6213 12.6437 12.6431 12.4935 12.6847C12.3103 12.7229 12.1704 12.8715 12.1432 13.0567C12.0925 13.2579 12.1937 13.4664 12.3831 13.551C12.6138 13.6589 12.8615 13.7255 13.1151 13.7478C13.7823 13.779 14.0511 14.0718 13.9503 14.7582C13.7631 16.0422 13.5111 17.3166 13.2951 18.5982C13.1487 19.4621 13.0263 20.3285 12.8847 21.1925C12.6951 22.3565 13.7799 23.4485 14.6415 23.5925C15.1519 23.664 15.667 23.6969 16.1823 23.6909C17.2866 23.6895 18.3415 23.2328 19.0982 22.4285C19.2357 22.3019 19.3389 22.1425 19.3982 21.9653C19.4272 21.8105 19.3907 21.6506 19.2974 21.5237C19.1847 21.4531 19.0459 21.4381 18.9206 21.4829C18.7238 21.5477 18.5486 21.6821 18.3567 21.7661C18.1323 21.8708 17.8973 21.9513 17.6559 22.0061C17.4611 22.0539 17.2552 22.0138 17.0926 21.8965C16.93 21.7792 16.8271 21.5964 16.8111 21.3965C16.7707 21.1732 16.7634 20.9452 16.7895 20.7197C16.8711 20.1629 16.9863 19.6109 17.0871 19.059C17.3607 17.5534 17.6383 16.047 17.9199 14.5398C17.9967 14.127 18.1263 13.7214 18.0399 13.287C18.0264 13.0941 17.936 12.9146 17.7888 12.7891C17.6416 12.6636 17.4501 12.6026 17.2575 12.6199C16.5375 12.6199 15.8151 12.6199 15.0951 12.6199ZM16.4007 7.28235C15.5158 7.24354 14.6954 7.7442 14.3254 8.54893C13.9553 9.35365 14.1091 10.3023 14.7145 10.9489C15.3199 11.5954 16.2564 11.8112 17.0837 11.4948C17.911 11.1783 18.4645 10.3926 18.4839 9.50711C18.5176 8.32037 17.5872 7.32888 16.4007 7.28715V7.28235Z"
                                        fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"
                                      ></path>
                                    </svg>
                                  </svg>
                                </div>
                              </button>

                              <div className="backface-hidden col-start-1 row-start-1 rotateBoxshow">
                                      <div className="break-word relative grid grid-cols-1 grid-rows-1 place-items-center py-5 h-full text-center bg-white border border-transparent rounded shadow md:py-10 md:overflow-hidden">
                                          <div className="mx-5 max-w-full break-words md:mx-2">
                                          {accessory.description}
                                          </div>
                                          <button type="button" className="rotateBtn absolute right-0 top-0 w-10 h-10"><svg width="32px" height="32px" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#000000"/></svg></button>
                                      </div>
                            </div>


                            </div>
                        </div>
                      ))}
                    </div>
                  </section>

                  <div className="mb-16 mx-5 bg-white rounded shadow overflow-hidden md:mx-10 xl:mb-0 letter-spacing-1-2">
                    <div className="flex flex-col items-center justify-center my-10 px-4 text-center text-2xl sm:p-6">
                    <svg className="mb-4 w-8 h-8 cssvar-secondary-to-white bg-info rounded-full" width="32" height="32">
                    <svg viewBox="0 0 32 32" fill="none" id="icon-info"><path fillRule="evenodd" clipRule="evenodd" d="M15.0951 12.6199C14.3751 12.6199 13.6671 12.6199 12.9543 12.6199C12.7986 12.6213 12.6437 12.6431 12.4935 12.6847C12.3103 12.7229 12.1704 12.8715 12.1432 13.0567C12.0925 13.2579 12.1937 13.4664 12.3831 13.551C12.6138 13.6589 12.8615 13.7255 13.1151 13.7478C13.7823 13.779 14.0511 14.0718 13.9503 14.7582C13.7631 16.0422 13.5111 17.3166 13.2951 18.5982C13.1487 19.4621 13.0263 20.3285 12.8847 21.1925C12.6951 22.3565 13.7799 23.4485 14.6415 23.5925C15.1519 23.664 15.667 23.6969 16.1823 23.6909C17.2866 23.6895 18.3415 23.2328 19.0982 22.4285C19.2357 22.3019 19.3389 22.1425 19.3982 21.9653C19.4272 21.8105 19.3907 21.6506 19.2974 21.5237C19.1847 21.4531 19.0459 21.4381 18.9206 21.4829C18.7238 21.5477 18.5486 21.6821 18.3567 21.7661C18.1323 21.8708 17.8973 21.9513 17.6559 22.0061C17.4611 22.0539 17.2552 22.0138 17.0926 21.8965C16.93 21.7792 16.8271 21.5964 16.8111 21.3965C16.7707 21.1732 16.7634 20.9452 16.7895 20.7197C16.8711 20.1629 16.9863 19.6109 17.0871 19.059C17.3607 17.5534 17.6383 16.047 17.9199 14.5398C17.9967 14.127 18.1263 13.7214 18.0399 13.287C18.0264 13.0941 17.936 12.9146 17.7888 12.7891C17.6416 12.6636 17.4501 12.6026 17.2575 12.6199C16.5375 12.6199 15.8151 12.6199 15.0951 12.6199ZM16.4007 7.28235C15.5158 7.24354 14.6954 7.7442 14.3254 8.54893C13.9553 9.35365 14.1091 10.3023 14.7145 10.9489C15.3199 11.5954 16.2564 11.8112 17.0837 11.4948C17.911 11.1783 18.4645 10.3926 18.4839 9.50711C18.5176 8.32037 17.5872 7.32888 16.4007 7.28715V7.28235Z" fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"></path></svg>
                    </svg>
                    <div>Questions?</div>
                    <div>We are available almost at any time</div>
                      <div className="hidden xl:block price-font">+49 (0) 8141 396 3000</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative my-10">
                <div className="bg-white rounded-button shadow rounded-none divide-secondary divide-opacity-disabled divide-y overflow-hidden xl:rounded-3xl max-w-[100vw] h-full max-h-screen overflow-y-auto xl:sticky xl:top-10 xl:h-auto xl:overflow-y-visible">
                  <div className="flex flex-col items-center p-6 mx-auto max-w-3xl">
                  <h1 className="break-words mb-2 text-xl xs:mb-6 price-font fw-600 text-center">{camper.title}</h1>
                    {camper.technical && camper.technical.map((technical)=>(
                    <span className="text-center text-lg">{technical.brands.title}</span>))}
                   
                        <div className="relative w-full my-4 custom-select location">
                        {/* <Select
                            autoFocus
                            backspaceRemovesValue={false}
                            hideSelectedOptions={false}
                            tabSelectsValue={false}
                            className="react-select-container"
                            classNamePrefix="react-select"
                            placeholder='Location'
                            options={location}
                            onChange={handleSkills}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) =>
                              option.title
                            }
                            value={skills}
                            />   */}
                        </div>    
                    <div className="relative w-full">
                        <span className="flex h-14 w-full items-center bg-white dark:bg-off-black-desat transition-all child-span:overflow-hidden child-span:text-ellipsis border focus:border-primary focus:outline-none justify-between border-grey hover:shadow dark:border-grey-dark dark:hover:shadow-dark rounded">
                          <div className="inline-block align-top ml-3 mr-1 min-w-fit child:h-7 child:w-7 dark:cssvar-secondary-to-off-white">
                            <svg width="32" height="32" viewBox="0 0 32 32">
                              <g fill="none" fillRule="evenodd">
                                <path
                                  d="M21.06 4.5c.474 0 .884.3 1.01.741l.028.124.01.127-.002 1.327h1.14c1.503 0 2.772 1.005 3.049 2.362l.03.179.019.182.006.183v13.87c0 1.476-1.178 2.7-2.715 2.882l-.194.017-.195.006H9.104c-1.569 0-2.882-1.094-3.079-2.54l-.019-.183L6 23.595V9.725c0-1.477 1.178-2.7 2.715-2.882l.194-.018.195-.005 1.139-.001V5.492c0-.473.347-.864.808-.966l.117-.02.121-.006c.473 0 .883.3 1.01.741l.027.124.01.127-.001 1.327h2.858V5.463c0-.452.316-.828.74-.934l.12-.022.122-.007a.98.98 0 0 1 .941.697l.029.117.012.149-.002 1.356h2.859V5.492c0-.473.348-.864.808-.966l.117-.02.122-.006zm3.197 7.015H8.092v11.989c0 .473.328.877.764.983l.122.021.126.007h14.142c.476 0 .878-.33.983-.764l.021-.122.007-.125V11.515zm-7.883 1.614 1.085 2.516c.031.071.097.12.174.129l2.75.28c.187.02.26.253.118.377l-2.072 1.791a.214.214 0 0 0-.07.21l.613 2.73a.215.215 0 0 1-.321.23l-2.365-1.446a.214.214 0 0 0-.222 0L13.7 21.393a.215.215 0 0 1-.32-.232l.611-2.729a.215.215 0 0 0-.07-.21l-2.071-1.79a.215.215 0 0 1 .118-.377l2.75-.281a.212.212 0 0 0 .174-.129l1.085-2.516a.217.217 0 0 1 .397 0z"
                                  fill="var(--svg-color-secondary, #163435)"
                                ></path>
                              </g>
                            </svg>
                          </div>
                        
                          <span className="text-ellipsis whitespace-nowrap text-md custom-calender" >
                          <DatePicker
                            selected={startDate}
                            onChange={onChange}
                            minDate={new Date()}
                            maxDate={addMonths(new Date(), 5)}
                            monthsShown={isMobile ? 1 : 2}
                            withPortal
                            placeholderText="Whenever"
                            onFocus={e => e.target.blur()}
                            ref={pickerRef}
                            // value={startDate}
                          
                          />
                          </span>
                          
                       
                          
                          <div className="inline-block align-top ml-2 mr-4 min-w-fit transition-all child:h-6 child:w-6 dark:cssvar-secondary-to-off-white">
                          
                            <svg width="32" height="32" viewBox="0 0 32 32">
                              <g fill="none" fillRule="evenodd">
                                <path
                                  d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                  fill="var(--svg-color-secondary, #163435)"
                                ></path>
                                
                              </g>
                            </svg>
                          </div>
                        </span>
                    </div>
                    <div className="relative w-full my-4 custom-select location">
                        <Select
                            autoFocus
                            backspaceRemovesValue={false}
                            hideSelectedOptions={false}
                            tabSelectsValue={false}
                            className="react-select-container"
                            classNamePrefix="react-select"
                            placeholder= 'Duration'
                            options={period}
                            onChange={periodChange}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) =>
                             option.title + ' months'
                            }
                            value={periodValue}
                            />  
                      </div> 
                    
                    <div className="grid gap-4 grid-cols-2 mt-2 w-full">

                        <div>
                          <span className="group outline-none">
                            <div className="relative flex items-center px-2 h-12 border border-secondary border-opacity-disabled rounded block w-full h-12 group-focus:border-primary rounded cursor-default">
                              <div className="flex items-center justify-between w-full opacity-disabled">
                              <svg className="w-7 h-7" width="32" height="32" aria-hidden="true"><svg viewBox="0 0 32 32" fill="none" id="icon-clock-pickup"><path fillRule="evenodd" clipRule="evenodd" d="M16.0004 5C22.0659 5 27 9.93484 27 16.0005C27 22.0662 22.0659 27 16.0004 27C9.93476 27 5 22.0662 5 16.0005C5 9.93484 9.93476 5 16.0004 5ZM16.0003 7.5C11.3151 7.5 7.5 11.3152 7.5 16.0005C7.5 20.6856 11.3149 24.5 16.0003 24.5C20.6856 24.5 24.5 20.6857 24.5 16.0005C24.5 11.315 20.6854 7.5 16.0003 7.5ZM16.2214 8.62618C16.5932 8.62618 16.7491 8.9661 16.7562 9.15491L17.2087 15.5123L20.6275 19.6289C20.75 19.7731 20.8682 20.1356 20.5685 20.436C20.2671 20.7364 19.9044 20.6213 19.7601 20.4947L14.8275 17.0998C14.6903 16.9776 14.629 16.8032 14.6225 16.6184C14.5827 16.5205 14.5559 16.4134 14.5635 16.299L15.6858 9.15491C15.6977 8.9661 15.8488 8.62618 16.2214 8.62618ZM28 0L32 7H24L28 0Z" fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"></path></svg></svg>
                              Pickup
                              <svg className="w-7 h-7" width="32" height="32" aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none" id="icon-chevron-down"><path fillRule="evenodd" clipRule="evenodd" d="M16.0001 22.1256L23.6467 14.479C24.1173 14.009 24.1179 13.2445 23.6479 12.7739L23.2266 12.3532C22.7542 11.8809 21.9927 11.8833 21.5215 12.3544L16.0001 17.8759L10.4781 12.3544C10.0075 11.8833 9.24543 11.8809 8.77364 12.3532L8.35234 12.7739C7.88175 13.2445 7.88296 14.009 8.35354 14.479L16.0001 22.1256Z" fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"></path></svg></svg>
                              </div>
                            </div>
                          </span>
                        </div>

                        <div>
                          <span className="group outline-none">
                            <div className="relative flex items-center px-2 h-12 border border-secondary border-opacity-disabled rounded block w-full h-12 group-focus:border-primary rounded cursor-default">
                              <div className="flex items-center justify-between w-full opacity-disabled">
                              <svg className="w-7 h-7" width="32" height="32" aria-hidden="true"><svg viewBox="0 0 32 32" fill="none" id="icon-clock-pickup"><path fillRule="evenodd" clipRule="evenodd" d="M16.0004 5C22.0659 5 27 9.93484 27 16.0005C27 22.0662 22.0659 27 16.0004 27C9.93476 27 5 22.0662 5 16.0005C5 9.93484 9.93476 5 16.0004 5ZM16.0003 7.5C11.3151 7.5 7.5 11.3152 7.5 16.0005C7.5 20.6856 11.3149 24.5 16.0003 24.5C20.6856 24.5 24.5 20.6857 24.5 16.0005C24.5 11.315 20.6854 7.5 16.0003 7.5ZM16.2214 8.62618C16.5932 8.62618 16.7491 8.9661 16.7562 9.15491L17.2087 15.5123L20.6275 19.6289C20.75 19.7731 20.8682 20.1356 20.5685 20.436C20.2671 20.7364 19.9044 20.6213 19.7601 20.4947L14.8275 17.0998C14.6903 16.9776 14.629 16.8032 14.6225 16.6184C14.5827 16.5205 14.5559 16.4134 14.5635 16.299L15.6858 9.15491C15.6977 8.9661 15.8488 8.62618 16.2214 8.62618ZM28 0L32 7H24L28 0Z" fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"></path></svg></svg>
                              Return
                              <svg className="w-7 h-7" width="32" height="32" aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none" id="icon-chevron-down"><path fillRule="evenodd" clipRule="evenodd" d="M16.0001 22.1256L23.6467 14.479C24.1173 14.009 24.1179 13.2445 23.6479 12.7739L23.2266 12.3532C22.7542 11.8809 21.9927 11.8833 21.5215 12.3544L16.0001 17.8759L10.4781 12.3544C10.0075 11.8833 9.24543 11.8809 8.77364 12.3532L8.35234 12.7739C7.88175 13.2445 7.88296 14.009 8.35354 14.479L16.0001 22.1256Z" fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"></path></svg></svg>
                              </div>
                            </div>
                          </span>
                        </div>

                    </div>
                  </div>

                  <div className="p-6 pl-8 xl:h-48 xl:overflow-y-scroll mx-auto max-w-3xl">
                    <div className="grid gap-y-4 font-light custom-grid">
                    <svg className="w-7 h-7 cssvar-secondary-to-primary" width="32" height="32">
                        <svg viewBox="0 0 32 32" fill="none" id="icon-check-circle"><path fillRule="evenodd" clipRule="evenodd" d="M27 16C27 22.0751 22.0751 27 16 27C9.92487 27 5 22.0751 5 16C5 9.92487 9.92487 5 16 5C22.0751 5 27 9.92487 27 16ZM10.7533 16.2181C11.0708 15.7706 11.6483 15.7117 12.0431 16.0865L14.2653 18.1963L19.6446 11.4402C19.9837 11.0144 20.5638 10.9928 20.9402 11.3921C21.3167 11.7914 21.347 12.4603 21.0079 12.8862L15.0481 20.3715C14.7223 20.7806 14.1708 20.8189 13.7915 20.4588L10.8933 17.7072C10.4984 17.3323 10.4358 16.6656 10.7533 16.2181Z" fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"></path></svg>
                        </svg>
                          <div>Abo-Rate</div>
                          <div className="justify-self-center"></div>
                           {camper.camper_price && camper.camper_price.map((camper_price)=>(
                           
                         <div className="justify-self-end font-normal price-font">€{camper_price.price}.00</div>))}



                        {selectPackages.map((element) => {
                          return(
                            <>
                              <svg className="w-7 h-7 cssvar-secondary-to-primary" width="32" height="32">
                              <svg viewBox="0 0 32 32" fill="none" id="icon-check-circle"><path fillRule="evenodd" clipRule="evenodd" d="M27 16C27 22.0751 22.0751 27 16 27C9.92487 27 5 22.0751 5 16C5 9.92487 9.92487 5 16 5C22.0751 5 27 9.92487 27 16ZM10.7533 16.2181C11.0708 15.7706 11.6483 15.7117 12.0431 16.0865L14.2653 18.1963L19.6446 11.4402C19.9837 11.0144 20.5638 10.9928 20.9402 11.3921C21.3167 11.7914 21.347 12.4603 21.0079 12.8862L15.0481 20.3715C14.7223 20.7806 14.1708 20.8189 13.7915 20.4588L10.8933 17.7072C10.4984 17.3323 10.4358 16.6656 10.7533 16.2181Z" fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"></path></svg>
                              </svg>
                              <div>{element.title}</div>
                              <div className="justify-self-center"></div>
                              <div className="justify-self-end font-normal price-font">€{element.price}.00</div>
                            </>
                          )
                        })}
                          {/* <svg className="w-7 h-7 cssvar-secondary-to-primary" width="32" height="32">
                            <svg viewBox="0 0 32 32" fill="none" id="icon-check-circle"><path fillRule="evenodd" clipRule="evenodd" d="M27 16C27 22.0751 22.0751 27 16 27C9.92487 27 5 22.0751 5 16C5 9.92487 9.92487 5 16 5C22.0751 5 27 9.92487 27 16ZM10.7533 16.2181C11.0708 15.7706 11.6483 15.7117 12.0431 16.0865L14.2653 18.1963L19.6446 11.4402C19.9837 11.0144 20.5638 10.9928 20.9402 11.3921C21.3167 11.7914 21.347 12.4603 21.0079 12.8862L15.0481 20.3715C14.7223 20.7806 14.1708 20.8189 13.7915 20.4588L10.8933 17.7072C10.4984 17.3323 10.4358 16.6656 10.7533 16.2181Z" fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"></path></svg>
                            </svg>
                          <div>Service fee</div>
                          <div className="justify-self-center"></div>
                          <div className="justify-self-end font-normal">€{serviceFee}.00</div> */}
                         
                    </div>
                       
                  </div>

                  <div className="flex flex-col items-center p-6 md:sticky z-10 bottom-0 mx-auto max-w-3xl bg-white xl:relative xl:z-auto">
                    <div className="grid grid-cols-2 items-center w-full font-light relative">
                      <div className="py-4 text-md font-normal">Per month{countMonth ? (countMonth + 'x'):''}</div>
                      {camper.camper_price && camper.camper_price.map((camper_price)=>(
                      <div className="justify-self-end text-md font-normal price-font">€{(camper_price.price)*countMonth}.00</div>))}
                    </div>
                    <div className="grid grid-cols-2 items-center w-full font-light relative">
                      <div className="py-4 text-md font-normal">Service fee</div>
                      <div className="justify-self-end text-md font-normal price-font ">€{serviceFee}.00</div>
                    </div>
                    <div className="grid grid-cols-2 items-center w-full font-light relative mb-2 pb-4">
                      <div className="py-4 text-2xl font-normal">Total</div>
                      {camper.camper_price && camper.camper_price.map((camper_price)=>( 
                      <div className="justify-self-end text-2xl font-normal price-font">€{totalAmount+serviceFee+(camper_price.price)*countMonth}</div>
                       ))}
                      <div></div>
                      <div className="justify-self-end font-normal">VAT (19%) incl.</div>
                      <div className="absolute bottom-0 left-0 flex flex-col w-full space-y-2"></div>
                    </div>
                    <p style={{color:"red"}}>{error}</p>
                    <Link onClick={handleCheckout} state={queryData} className="grid-cols1 inline-grid grid-rows-1 text-white bg-secondary hover:bg-primary pl-5 pr-2 w-full h-12 rounded">
                      <span className="relative flex col-start-1 row-start-1 items-center justify-between w-full h-full transition-opacity duration-300">
                        <span className="transform transition-transform duration-300 translate-x-0">
                          <span className="text-custom-lg-sm font-body font-semibold text-custom-lg-sm">Continue to checkout</span>
                        </span>
                        <svg className="w-7 h-7 cssvar-secondary-to-white pointer-events-none" width="32" height="32"><svg  viewBox="0 0 32 32" fill="none" id="icon-chevron-right"><path fillRule="evenodd" clipRule="evenodd" d="M22.1256 15.9999L14.479 8.35332C14.009 7.88273 13.2445 7.88213 12.7739 8.35211L12.3532 8.77342C11.8809 9.24581 11.8833 10.0073 12.3544 10.4785L17.8759 15.9999L12.3544 21.5219C11.8833 21.9925 11.8809 22.7546 12.3532 23.2264L12.7739 23.6477C13.2445 24.1182 14.009 24.117 14.479 23.6465L22.1256 15.9999Z" fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"></path></svg></svg>
                      </span>
                    </Link>
                  </div>

                </div>
              </div>
            </div>
          </form>
        </main>
      </div>
    </>
  );
};
export default Camperconfiguration;
