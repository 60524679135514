import React, { useEffect } from 'react';

import "./App.css";

import "./i18n";
import { initGA, logPageView } from './utils/analytics';
import history from './history'; // Adjust the path as necessary
import { BrowserRouter, Route, Routes, } from 'react-router-dom';

import Home from './Pages/Home/Home';
import Availabilityinfo from './Pages/Availabilityinfo/Availabilityinfo';
import Camperconfiguration from './Pages/Camperconfiguration/Camperconfiguration';
import Offers from './Pages/Offers/Offers';
import Campers from './Pages/Campers/Campers';
import Campersale from './Pages/Campersale/Campersale';
import Campersubscription from './Pages/Campersubscription/Campersubscription';
import Tripplanner from './Pages/Tripplanner/Tripplanner';
import TripplannerSeperated from './Pages/Tripplanner/TripplannerSeperated';
import Blog from './Pages/Blog/Blog';
import Voucher from './Pages/Voucher/Voucher';
import VoucherDetails from './Pages/Voucher/VoucherDetails';
import Privacy from "./Pages/Privacy/Privacy";


import Bloginner from './Pages/Bloginner/Bloginner';
import Aboutus from './Pages/Aboutus/Aboutus';
import Contactus from './Pages/Contactus/Contactus';
import UserContactUs from './Pages/Contactus/UserContactUs';

import Configurationdetail from './Pages/Configurationdetail/Configurationdetail';
import Payment from './Pages/Payment/Payment';
import VoucherPayment from './Pages/Voucher/Payment';
import TripplanPayment from './Pages/Tripplanner/Payment';

// import Campervan from './Pages/Campervan/Campervan';
import Registration from './Pages/Registration/Registration';
import Login from './Pages/Login/Login';
import Privacystatement from './Pages/Privacystatement/Privacystatement';
import Explanatoryvideo from './Pages/Explanatoryvideo/Explanatoryvideo';
import Explanatoryvideov1 from './Pages/Explanatoryvideo-v1/Explanatoryvideov1';
import Equipment from './Pages/Equipment/Equipment';
import RentConfiguration from './Pages/RentConfiguration/RentConfiguration';
import SaleAvailabilityInfo from './Pages/SaleAvailabilityInfo/SaleAvailabilityInfo';
import Userdashboard from './Pages/Userdashboard/Userdashboard';
import Thankyou from './Pages/Thankyou/Thankyou';
import Thankyoutripplanner from "./Pages/Thankyou/Thankyou-tripplanner";
import Thankyouvoucher from "./Pages/Thankyou/Thankyou-voucher";
import MyOrder from './Pages/MyOrder/MyOrder';
import VideoTutorial from './Pages/VideoTutorial/VideoTutorial';
import ViewDocument from './Pages/ViewDocument/ViewDocument';
import AddDocument from './Pages/ViewDocument/AddDocument';
import AddTripReview from './Pages/TripReview/AddTripReview';
import SubscriptionDetail from './Pages/SubscriptionDetail/SubscriptionDetail';
import Typo from './Pages/Typo/Typo';
import Imprint from './Pages/Imprint/Imprint';
import Vouchercheckout from './Pages/Voucher/VoucherCheckout';
import TripPlannercheckout from './Pages/Tripplanner/TripplannerCheckout';
import Gtc from "./Pages/Gtc/Gtc";
import AvailabilityinfoSubscription from "./Pages/Configurationdetail/AvailabilityinfoSubscription";
import "../src/Assests/responsive.css";
import { useTranslation } from "react-i18next";
function App() {
  const { i18n, t } = useTranslation();
  useEffect(() => {
    initGA('G-G3PDZ6PMQ0'); // Replace with your actual tracking ID
    logPageView();
  }, []);
  console.log( localStorage.getItem("stripesessionvoucher"),"local ")
  return (
    <div className="App">

      <BrowserRouter history={history}>
        <Routes>

          <Route path="/" element={<Home />} />
          <Route path="/availabilityinfo/:id" element={<Availabilityinfo />} />
          <Route path="/camper-subscription/:id" element={<AvailabilityinfoSubscription />} />
          <Route path="/saleavailabilityinfo/:id" element={<SaleAvailabilityInfo />} />
          <Route path="/camperconfiguration/:id" element={<Camperconfiguration />} />
          <Route path="/rentconfiguration/:id" element={<RentConfiguration />} />
          <Route path="/offers" element={<Offers />} />
          <Route path="/campers" element={<Campers />} />
          <Route path="/campersale" element={<Campersale />} />
          <Route path="/campersubscription" element={<Campersubscription />} />
          <Route path="/tripplanner" element={<Tripplanner />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/voucher" element={<Voucher />} />
          <Route path="/voucher-detail/:id" element={<VoucherDetails />} />
          <Route path="/trip-planner-seperated" element={<TripplannerSeperated />} />
          <Route path="/tripplaner-checkout" element={<TripPlannercheckout />} />
          <Route path="/bloginner/:id" element={<Bloginner />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/contactus" element={<Contactus />} />
          <Route path="/voucher-checkout/:id" element={<Vouchercheckout />} />
          <Route path="/configurationdetail/:id" element={<Configurationdetail />} />
          <Route path="/subscriptiondetail/:id" element={<SubscriptionDetail />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/voucher-payment" element={<VoucherPayment />} />
          <Route path="/tripplan-payment" element={<TripplanPayment />} />
          {/* <Route path="/campervan" element={<Campervan/>}/> */}
          <Route path="/registration" element={<Registration />} />
          <Route path="/login" element={<Login />} />
          <Route path="/privacystatement" element={<Privacystatement />} />
          <Route path="/explanatoryvideo" element={<Explanatoryvideo />} />
          <Route path="/equipment" element={<Equipment />} />
          <Route path="/user-dashboard" element={<Userdashboard />} />
          <Route path="/thankyou" element={<Thankyou />} />
          <Route path="/thankyou-tripplanner" element={<Thankyoutripplanner />} />
          <Route path="/thankyou-voucher" element={<Thankyouvoucher />} />
          <Route path="/my_order" element={<MyOrder />} />
          <Route path="/video_tutorial" element={<VideoTutorial />} />
          <Route path="/user-contact-us" element={<UserContactUs />} />
          <Route path="/view_document/:id" element={<ViewDocument />} />
          <Route path="/add_document" element={<AddDocument />} />
          <Route path="/add_trip_review/:id" element={<AddTripReview />} />
          <Route path="/typo" element={<Typo />} />
          <Route path="/imprint" element={<Imprint />} />
          <Route path="/explanatoryvideov1" element={<Explanatoryvideov1 />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/gtc" element={<Gtc />} />

        </Routes>
      </BrowserRouter>



    </div>
  );
}

export default App;
