import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useSearchParams, useParams, useLocation, generatePath } from "react-router-dom";
import CamperService from '../../Api/services/CamperService';

import Select from 'react-select'
import DatePicker, { registerLocale } from "react-datepicker";
import moment from 'moment';
import { addMonths, getDate, differenceInCalendarMonths, add, getMonth } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "react-bootstrap";
import { NumericFormat } from 'react-number-format';
import Loader from "../../Components/Loader/Loader";
import { useTranslation } from "react-i18next";
import de from 'date-fns/locale/de';
registerLocale("de", de);
const SubscriptionDetail = () => {
  const { i18n, t } = useTranslation();
  useEffect(() => {

    window.scrollTo(0, 0);

  }, []);

  useEffect(() => {
    var header = document.getElementById("boxesWrp");
    var btns = header.getElementsByClassName("rotateBtn");
    for (var i = 0; i < btns.length; i++) {
      btns[i].addEventListener("click", function () {
        var current = document.getElementsByClassName("active");
        if (current.length > 0) {
          current[0].className = current[0].className.replace(" active", "");
        }
        this.parentElement.className += " active";
      });
    }
  });
  var imageUrl = process.env.REACT_APP_LARAVEL_CAMPER_IMAGE_URL;
  const params = useParams();
  const variable = useLocation();

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams()
  const [count, setCount] = useState(0);
  const [totalcamperprice, setTotalcamperprice] = useState();

  const [ExcludedateList, setExcludedateList] = useState([]);
  const [loader, setLoder] = useState(true);
  const [error, setError] = useState("")

  const [camper, setCamper] = useState([]);
  const [Inventory, setInventory] = useState([]);
  const [campermonthprice, setCampermonthprice] = useState([]);

  //allpackages
  const [selectPackages, setSelectPackages] = useState([]);
  const [TotalPackagevalue, setTotalPackagevalue] = useState(0);
  const [differenceMonth, setDifferenceMonth] = useState(0);
  //service

  const [ServiceSelected, setServiceSelected] = useState([]);

  const [TotalServicevalue, setTotalServicevalue] = useState(0);

  // Accessories

  const [AccessoriesSelected, setAccessoriesSelected] = useState([]);
  const [TotalAccessoriesvalue, setTotalAccessoriesvalue] = useState(0);

  //

  const [Kilometerselect, setKilometerselect] = useState();

  const [Kilometeradd, setKilometeradd] = useState([]);
  const [TotalKilometeradd, setTotalkilometeradd] = useState(0);
  const [kilometeroption, setKilometeroption] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  const [serviceFee, setServiceFee] = useState('');
  const [pickup, setPickup] = useState('');
  const [monthselect, setMonthselect] = useState('');
  const [returntime, setReturntime] = useState('');


  // Get the current date
  const today = new Date();
  // Set the minimum selectable date to tomorrow
  const minSelectableDate = new Date(today);
  minSelectableDate.setDate(today.getDate() + 1);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);
  const pickerRef = useRef(null);
  const handlePopstate = () => {
    navigate(-1)
  };
  const monthoptions = [
    { value: '2', label: 2 + " " + t("Rent.Months") },
    { value: '3', label: 3 + " " + t("Rent.Months") },
    { value: '4', label: 4 + " " + t("Rent.Months") },
    { value: '5', label: 5 + " " + t("Rent.Months") },
    { value: '6', label: 6 + " " + t("Rent.Months") },
    { value: '7', label: 7 + " " + t("Rent.Months") },
    { value: '8', label: 8 + " " + t("Rent.Months") },
    { value: '9', label: 9 + " " + t("Rent.Months") },
    { value: '10', label: 10 + " " + t("Rent.Months") },
    { value: '11', label: 11 + " " + t("Rent.Months") },
    { value: '12', label: 12 + " " + t("Rent.Months") }
  ]
  useEffect(() => {


    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);


    };
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1200);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isMobile && pickerRef.current !== null) {
      pickerRef.current.input.readOnly = true;
    }
  }, [isMobile, pickerRef]);

  const getCamperByID = async (id) => {
    try {
      let getCamper = await CamperService.show(id).json();

      setCamper(getCamper.data);
      setCampermonthprice(getCamper.data.month_setails)
      if (getCamper.data.kilometer.length != 0) {
        addkilometer(
          getCamper.data.kilometer[0].id,
          getCamper.data.kilometer[0].title,
          getCamper.data.kilometer[0].price,
          "Kilometer"
        );
        var newarraay = [];
        newarraay.push({
          id: getCamper.data.kilometer[0].id,
          title: getCamper.data.kilometer[0].title,
          price: getCamper.data.kilometer[0].price,
        });

        setKilometeroption(getCamper.data.kilometer[0].id)
        setKilometeradd(newarraay);
      }
      else {
        setKilometeroption()
        setKilometeradd([]);
      }
      let service_fee = getCamper.data.price_additional[0].service_fee


      setServiceFee(service_fee);

      let dateRange = searchParams.get('date')


      if (dateRange) {

        setStartDate(new Date(dateRange));

        var now = new Date();
        var current_month = new Date(dateRange).getMonth() + 1;

        const totalDays = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
        const currentDays = new Date(dateRange).getDate();
        var startdatemonthprice = getCamper.data.month_setails.filter((element) => { return element.month_id === current_month })
        //var mp1 = (Math.ceil((startdatemonthprice[0].month_price) / totalDays) * ((totalDays - currentDays) + 1));
        // console.log(Math.round(startdatemonthprice[0].month_price / totalDays), "mp1")
        //console.log(totalDays - currentDays,"totalDays - currentDays");
        //currentDays
        var nd = ((totalDays - currentDays + 1) / totalDays).toFixed(2);
        var mp1 = (nd * startdatemonthprice[0].month_price).toFixed(0)

        var threeMonthsAgo = '';
        if (variable.state === null) {
          threeMonthsAgo = moment(dateRange).add(2, 'months')
          setCount(2)
        }
        else {

          threeMonthsAgo = moment(dateRange).add(variable.state.value, 'months')
          setCount(variable.state.value)
        }


        // var last_month = threeMonthsAgo.toDate().getMonth() + 1
        // const lastcurrentDays = new Date(threeMonthsAgo).getDate();
        // let result = 0;

        // var enddatemonthprice = getCamper.data.month_setails.filter((element) => { return element.month_id === last_month })
        // var mp2 = (Math.ceil((enddatemonthprice[0].month_price) / totalDays) * (lastcurrentDays));
        var last_month = threeMonthsAgo.toDate().getMonth() + 1
        const totalDayslast = new Date(now.getFullYear(), threeMonthsAgo.toDate().getMonth() + 1, 0).getDate();
        const lastcurrentDays = new Date(threeMonthsAgo).getDate();
        var enddatemonthprice = getCamper.data.month_setails.filter((element) => { return element.month_id === last_month })

        // var mp2 = (Math.ceil((enddatemonthprice[0].month_price) / totalDayslast) * (lastcurrentDays));
        //var mp2 = Math.round(enddatemonthprice[0].month_price / totalDayslast * lastcurrentDays)

        var nd2 = (lastcurrentDays / totalDayslast).toFixed(2);

        var mp2 = (nd2 * enddatemonthprice[0].month_price).toFixed(0)


        var rangedate = {};
        if (variable.state === null) {
          var monthdata = monthoptions.map((element) => { return element })
          setMonthselect({ "value": "2", "label": 2 + " " + t("Rent.Months") })
          rangedate = moment(dateRange).add(2, 'months');
        }
        else {
          setMonthselect(variable.state)

          rangedate = moment(dateRange).add(variable.state.value, 'months');

        }
        // var rangedate = moment(dateRange).add(2, 'months');

        setEndDate(rangedate.toDate())
        let result = differenceInCalendarMonths(
          new Date(rangedate),
          new Date(dateRange),


        );
        setDifferenceMonth(result);


        var betweenmonth = [];
        if (current_month >= last_month) {
          for (var i = (current_month + 1); i <= 12; i++) {
            betweenmonth.push(i);
          }
          for (var i = 1; i < last_month; i++) {
            betweenmonth.push(i);
          }
        } else {
          for (var i = (current_month + 1); i < last_month; i++) {
            betweenmonth.push(i);
          }

        }
        const betweenmonthday = getCamper.data.month_setails.filter(col => {
          return betweenmonth.find(selected => selected === col.month_id)
        })

        // var totalNumber = Number(betweenmonthday[0].month_price) + Number(mp1) + Number(mp2);
        var totalNumber = betweenmonthday.reduce((a, b) => a + parseInt(b.month_price), Number(mp1) + Number(mp2))

        setTotalcamperprice(totalNumber)
        setLoder(false)
        // if (searchParams.get('pickup') < 14 || searchParams.get('return') > 11) {
        //   setTotalcamperprice(0.5 + totalNumber)
        // }
        // else {
        //   setTotalcamperprice(totalNumber)
        // }



      }
    } catch (error) {
      if (error.name === 'HTTPError') {
        const errorJson = await error.response.json();
        setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
      }
    }
  };
  const getAccessoriesInventory = async (accessoiresId) => {
    try {
      let dateRange = searchParams.get("date");

      if (dateRange) {
        let startDate1 = dateRange.split("_");
        var rangedate = {};

        rangedate = moment(startDate1[0]).add(2, 'months');
        var userData = {
          id: accessoiresId,
          start_date: moment(startDate1[0]).format("YYYY-MM-DD"),
          end_date: moment(rangedate).format("YYYY-MM-DD")
        }


        let getcheckaccessoires = await CamperService.checkaccessoires(userData).json();

        setInventory(getcheckaccessoires.data)

      }
      else {
        var userData = {
          id: accessoiresId,
          start_date: "",
          end_date: ""
        }

        let getcheckaccessoires = await CamperService.checkaccessoires(userData).json();
        setInventory(getcheckaccessoires.data)
      }

    }
    catch (error) {
      if (error.name === "HTTPError") {
        const errorJson = await error.response.json();
        setError(
          errorJson.message.substr(0, errorJson.message.lastIndexOf("."))
        );
      }

    }

    // try {
    //   var rangedate = {};

    //   rangedate = moment(startDate).add(2, 'months');
    //   console.log(rangedate.toDate())
    //   var userData = {
    //     id: accessoiresId,
    //     start_date: moment(startDate).format("YYYY-MM-DD"),
    //     end_date: moment(rangedate).format("YYYY-MM-DD")
    //   }

    //   let getcheckaccessoires = await CamperService.checkaccessoires(userData).json();
    //   setInventory(getcheckaccessoires.data)


    // }
    // catch (error) {
    //   if (error.name === "HTTPError") {
    //     const errorJson = await error.response.json();
    //     setError(
    //       errorJson.message.substr(0, errorJson.message.lastIndexOf("."))
    //     );
    //   }

    // }
  }
  // const getkilometer = async () => {
  //   try {
  //     let getCamper = await CamperService.kilometerindex().json();

  //     if (getCamper.data[0] === null) {
  //       setLoder(true)
  //     }
  //     else {
  //       setKilometerselect(getCamper.data)

  //       addkilometer(getCamper.data[0].id, getCamper.data[0].title, getCamper.data[0].price, "Kilometer");

  //       setLoder(false)
  //     }

  //   }
  //   catch (error) {
  //     if (error.name === 'HTTPError') {
  //       const errorJson = await error.response.json();
  //       setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
  //     }
  //   }
  // }
  const getExcludedate = async (id) => {
    try {
      let Excludedate = await CamperService.excludedates(id).json();
      if (Excludedate.message === "Inventory not avalable!!!") {
        const daysRange = 365;

        var newinverntory = []
        for (let i = 0; i < daysRange; i++) {

          newinverntory.push(add(new Date(), { days: i }));
        }

        //setExcludedateListStart(newinverntory)
        setExcludedateList(newinverntory)
      }
      else if (Excludedate.message === "Excluded dates listing") {

        setExcludedateList(Excludedate.exclude_date)
      }
      else {
        console.log("")
      }

    }
    catch (error) {
      if (error.name === 'HTTPError') {
        const errorJson = await error.response.json();
        setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
      }
    }
  }
  useEffect(() => {
    var id = params.id;
    // getkilometer();
    getCamperByID(id);

    getAccessoriesInventory(id);
    getExcludedate(id)

    let pickrange = searchParams.get('pickup')
    let returnrange = searchParams.get('return')
    let daterange = searchParams.get('date')
    if (pickrange) {
      setPickup(pickrange)
    }
    if (returnrange) {
      setReturntime(returnrange)
    }
    if (daterange === null) {
      setSearchParams((searchParams) => {
        searchParams.set('date', `${moment(minSelectableDate).format('YYYY-MM-DD')}`);
        return searchParams;
      });
    }


    if (variable.state === null) {
      var monthdata = monthoptions.map((element) => { return element })
      setMonthselect({ "value": "2", "label": 2 + " " + t("Rent.Months") })
      // setMonthselect(monthdata[0])
    }
    else {
      setMonthselect(variable.state)
    }



  }, []);


  const addservice = (itemId, itemTitle, itemPrice, itemType, selection) => {

    if (selection === "service") {
      var newarraay = []
      newarraay.push({ id: itemId, title: itemTitle, price: itemPrice, type: itemType })

      setServiceSelected(current => [...current, ...newarraay])

      setTotalServicevalue(TotalServicevalue + newarraay[0].price)
      setSelectPackages(current => [...current, ...newarraay])
      setTotalPackagevalue(TotalPackagevalue + newarraay[0].price)
    }
    else {
      setServiceSelected([
        ...ServiceSelected,
        { id: itemId, title: itemTitle, price: itemPrice, type: itemType }
      ]);
      setTotalServicevalue(0)
      setSelectPackages([
        ...selectPackages,
        { id: itemId, title: itemTitle, price: itemPrice, type: itemType }
      ])
      setTotalPackagevalue(0)
    }


  };


  const removeservice = (itemId, itemTitle, itemPrice, selection) => {
    if (selection === "service") {
      const toBeRemove = ServiceSelected.find(
        (item) => item.title === itemTitle
      );
      if (toBeRemove) {
        ServiceSelected.splice(ServiceSelected.indexOf(toBeRemove), 1);
        setServiceSelected([...ServiceSelected]);
        if (ServiceSelected === null) {
          setTotalServicevalue(0)
        } else {
          setTotalServicevalue(ServiceSelected.reduce((a, v) => a = a + v.price, 0))
        }
      }

      //total all
      const toRemove = selectPackages.find((item) => item.title === itemTitle)
      if (toRemove) {
        selectPackages.splice(selectPackages.indexOf(toBeRemove), 1);
        setSelectPackages([...selectPackages]);
        if (selectPackages === null) {
          setTotalPackagevalue(0)
        } else {
          setTotalPackagevalue(selectPackages.reduce((a, v) => a = a + v.price, 0))
        }
      }

    }

  };

  const addAccessories = (itemId, itemTitle, itemPrice, itemType, selection) => {

    if (selection === "Accessories") {

      var newarraay = []
      newarraay.push({ id: itemId, title: itemTitle, price: itemPrice, type: itemType })

      setAccessoriesSelected(current => [...current, ...newarraay])

      setTotalAccessoriesvalue(TotalAccessoriesvalue + newarraay[0].price)
      // setSelectPackages(current => [...current, ...newarraay])
      // setTotalPackagevalue(TotalPackagevalue + newarraay[0].price)
    }
    else {

      setAccessoriesSelected([
        ...AccessoriesSelected,
        { id: itemId, title: itemTitle, price: itemPrice, type: itemType }
      ]);
      setTotalAccessoriesvalue(0)
      // setSelectPackages([
      //   ...selectPackages,
      //   { id: itemId, title: itemTitle, price: itemPrice }
      // ])
      // setTotalPackagevalue(0)
    }


  };
  const removeAccessories = (itemId, itemTitle, itemPrice, selection) => {
    const toBeRemove = AccessoriesSelected.find(
      (item) => item.title === itemTitle
    );
    if (toBeRemove) {
      AccessoriesSelected.splice(AccessoriesSelected.indexOf(toBeRemove), 1);
      setAccessoriesSelected([...AccessoriesSelected]);
      if (AccessoriesSelected === null) {
        setTotalAccessoriesvalue(0)
      } else {
        setTotalAccessoriesvalue(AccessoriesSelected.reduce((a, v) => a = a + v.price, 0))
      }
    }
    if (selection === "Accessories") {

      const toBeRemove = AccessoriesSelected.find(
        (item) => item.title === itemTitle
      );
      if (toBeRemove) {
        AccessoriesSelected.splice(AccessoriesSelected.indexOf(toBeRemove), 1);
        setAccessoriesSelected([...AccessoriesSelected]);
        if (AccessoriesSelected === null) {
          setTotalAccessoriesvalue(0)
        } else {
          setTotalAccessoriesvalue(AccessoriesSelected.reduce((a, v) => a = a + v.price, 0))
        }
      }
      ///total
      // const toRemove = selectPackages.find((item) => item.title === itemTitle)
      // if (toRemove) {
      //   // selectPackages.splice(selectPackages.indexOf(toBeRemove), 1);
      //   // setSelectPackages([...selectPackages]);
      //   if (selectPackages === null) {
      //     setTotalPackagevalue(0)
      //   } else {
      //     setTotalPackagevalue(selectPackages.reduce((a, v) => a = a + v.price, 0))
      //   }
      // }

    }
  };

  const addkilometer = (itemId, itemTitle, itemPrice, selection,) => {

    if (selection === "Kilometer") {

      if (kilometeroption !== itemId) {

        setKilometeroption(itemId)
        var newarraay = []
        newarraay.push({ id: itemId, title: itemTitle, price: itemPrice })

        setKilometeradd(newarraay);
        setTotalkilometeradd(newarraay[0].price)


        // setSelectPackages(newarraay)

        // setTotalPackagevalue(TotalPackagevalue +newarraay[0].price)

      }
      else {
        setKilometeroption('')
        const toBeRemove = Kilometeradd.find(
          (item) => item.title === itemTitle
        );
        if (toBeRemove) {
          Kilometeradd.splice(Kilometeradd.indexOf(toBeRemove), 1);
          setKilometeradd([...Kilometeradd]);
          setTotalkilometeradd(0)
        }
        //total

        const toRemove = selectPackages.find((item) => item.title === itemTitle)
        if (toRemove) {
          selectPackages.splice(selectPackages.indexOf(toBeRemove), 1);
          setSelectPackages([...selectPackages]);
          if (selectPackages === null) {
            setTotalPackagevalue(0)
          } else {
            setTotalPackagevalue(selectPackages.reduce((a, v) => a = a + v.price, 0))
          }


        }

      }

    }
    else {
      setKilometeradd([
        ...Kilometeradd,
        { id: itemId, title: itemTitle, price: itemPrice }
      ]);
    }



  };


  const removekilometer = (itemId, itemTitle, selection) => {
    console.log("remocve")
    const toBeRemove = Kilometeradd.find(
      (item) => item.title === itemTitle
    );
    if (toBeRemove) {
      Kilometeradd.splice(Kilometeradd.indexOf(toBeRemove), 1);
      setKilometeradd([...Kilometeradd]);
      if (Kilometeradd === null) {
        setTotalkilometeradd(0)
      } else {
        setTotalkilometeradd(Kilometeradd.reduce((a, v) => a = a + v.price, 0))
      }
    }
    ///total
    const toRemove = selectPackages.find((item) => item.title === itemTitle)
    if (toRemove) {
      selectPackages.splice(selectPackages.indexOf(toBeRemove), 1);
      setSelectPackages([...selectPackages]);
      if (selectPackages === null) {
        setTotalPackagevalue(0)
      } else {
        setTotalPackagevalue(selectPackages.reduce((a, v) => a = a + v.price, 0))
      }
    }

  };
  const [totalAmount, setTotalAmount] = useState(0)


  function updateTotal(e, amount) {


    setTotalAmount((totalAmount) => {
      if (e.target.checked) {

        totalAmount = totalAmount + amount;
        return totalAmount
      } else {
        totalAmount = totalAmount - amount;
        return totalAmount
      }
    })

  }



  const handleMonth = async (e) => {
    setMonthselect(e)


    var now = new Date();
    var current_month = startDate.getMonth() + 1;
    const totalDays = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
    const currentDays = new Date(startDate).getDate();
    var startdatemonthprice = campermonthprice.filter((element) => { return element.month_id === current_month })
    // var mp1 = (Math.ceil((startdatemonthprice[0].month_price) / totalDays) * ((totalDays - currentDays) + 1));
    var nd = ((totalDays - currentDays + 1) / totalDays).toFixed(2);
    var mp1 = (nd * startdatemonthprice[0].month_price).toFixed(0)
    var threeMonthsAgo = moment(startDate).add(e.value, 'months');
    var last_month = threeMonthsAgo.toDate().getMonth() + 1
    const totalDayslast = new Date(now.getFullYear(), threeMonthsAgo.toDate().getMonth() + 1, 0).getDate();
    const lastcurrentDays = new Date(threeMonthsAgo).getDate();
    var enddatemonthprice = campermonthprice.filter((element) => { return element.month_id === last_month })

    // var mp2 = (Math.ceil((enddatemonthprice[0].month_price) / totalDayslast) * (lastcurrentDays));
    // var mp2 = Math.round(enddatemonthprice[0].month_price / totalDayslast * lastcurrentDays)
    // console.log(mp2, "mp2")
    var nd2 = (lastcurrentDays / totalDayslast).toFixed(2);
    console.log(nd2, "nd2")
    var mp2 = (nd2 * enddatemonthprice[0].month_price).toFixed(0)
    var rangedate = moment(startDate).add(e.value, 'months');

    setEndDate(rangedate.toDate())

    let result = 0;
    result = differenceInCalendarMonths(
      new Date(rangedate),
      new Date(startDate)

    );
    setDifferenceMonth(result)
    var betweenmonth = [];
    if (current_month >= last_month) {
      for (var i = (current_month + 1); i <= 12; i++) {
        betweenmonth.push(i);
      }
      for (var i = 1; i < last_month; i++) {
        betweenmonth.push(i);
      }
    } else {
      for (var i = (current_month + 1); i < last_month; i++) {
        betweenmonth.push(i);
      }

    }
    const betweenmonthday = campermonthprice.filter(col => {
      return betweenmonth.find(selected => selected === col.month_id)
    })

    var userData = {
      id: params.id,
      start_date: moment(startDate).format("YYYY-MM-DD"),
      end_date: moment(rangedate).format("YYYY-MM-DD")
    }

    let getcheckaccessoires = await CamperService.checkaccessoires(userData).json();

    setInventory(getcheckaccessoires.data)
    // var totalNumber = Number(betweenmonthday[0].month_price) + Number(mp1) + Number(mp2);
    var totalNumber = betweenmonthday.reduce((a, b) => a + Number(b.month_price), Number(mp1) + Number(mp2))
    setTotalcamperprice(totalNumber)
    setCount(e.value)

    // if (searchParams.get('pickup') < 14 || searchParams.get('return') > 11) {
    //   setTotalcamperprice(0.5 + totalNumber)
    // }
    // else {
    //   setTotalcamperprice(totalNumber)
    // }


  }

  const handleTime = (e) => {
    let time = e.value
    setPickup(time)
    let i = 0;
    if (i == 0) {
      setSearchParams((searchParams) => {
        searchParams.set("pickup", `${time}`);
        return searchParams;
      });
    }
    // if (time !== null) {

    //   setSearchParams((searchParams) => {
    //     searchParams.set('pickup', `${time}`);

    //     return searchParams;
    //   });

    //   if (time < 14) {
    //     sethandlecounter(true)

    //     if (handlecounter === true) {
    //       setTotalcamperprice(totalcamperprice)
    //       setCount(count)
    //     }
    //     else {
    //       setTotalcamperprice(totalcamperprice + 0.5)
    //       setCount(count + 0.5)
    //     }
    //   }
    //   else {
    //     sethandlecounter(false)
    //     if (handlecounter === false) {
    //       setTotalcamperprice(totalcamperprice)
    //       setCount(count)
    //     }
    //     else {
    //       setTotalcamperprice(totalcamperprice - 0.5)
    //       setCount(count - 0.5)
    //     }
    //   }

    //   setPickup(time)
    // }
  }
  const handleReturnTime = (e) => {
    let time = e.value
    setReturntime(time)
    let i = 0;
    if (i == 0) {
      setSearchParams((searchParams) => {
        searchParams.set("return", `${time}`);
        return searchParams;
      });
    }
    // if (time !== null) {

    //   // Check pricing Rule
    //   let i = 0;

    //   if (i == 0) {
    //     setSearchParams((searchParams) => {
    //       searchParams.set('return', `${time}`);
    //       return searchParams;
    //     });
    //     if (time > 11) {
    //       sethandlereturncounter(true)
    //       if (handlereturncounter === true) {
    //         setTotalcamperprice(totalcamperprice)
    //         setCount(count)
    //       }
    //       else {
    //         setTotalcamperprice(totalcamperprice + 0.5)
    //         setCount(count + 0.5)
    //       }
    //     }
    //     else {
    //       sethandlereturncounter(false)
    //       if (handlereturncounter === false) {
    //         setTotalcamperprice(totalcamperprice)
    //         setCount(count)
    //       }
    //       else {
    //         setTotalcamperprice(totalcamperprice - 0.5)
    //         setCount(count - 0.5)
    //       }
    //     }
    //     setReturntime(time)
    //   }
    // }
  }

  const excludeDatesget = [];

  ExcludedateList.map((element) => { return excludeDatesget.push(new Date(element)) });

  const renderDayContents = (day, date) => {
    var filtered = camper.camper_price.filter(element => {
      const date = element.startdate

      return date === moment(date).format('YYYY/MM/DD')

    });
    var getdate = filtered.map((camper_price) => {
      return camper_price.startdate
    });

    var monthprice = campermonthprice.map((element) => {
      return element
    })

    let formetDates = moment(date).format('YYYY/MM/DD').indexOf(getdate) != 0;

    var newdata = monthprice.filter((element) => { return element.month_id === getMonth(date) + 1 })
    var newarray = newdata.map((element) => { return element.month_price })

    return <span>{getDate(date)}<p className="price-font" >{formetDates ? '' : "€" + newarray}</p></span>

  }

  const onChangedate = async (date) => {
    var now = new Date();
    var current_month = date.getMonth() + 1;
    const totalDays = new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
    const currentDays = new Date(date).getDate();
    var startdatemonthprice = campermonthprice.filter((element) => { return element.month_id === current_month })
    // var mp1 = (Math.ceil((startdatemonthprice[0].month_price) / totalDays) * ((totalDays - currentDays) + 1));
    //alert(mp1)
    var nd = ((totalDays - currentDays + 1) / totalDays).toFixed(2);
    var mp1 = (nd * startdatemonthprice[0].month_price).toFixed(0)
    var threeMonthsAgo = moment(date).add(monthselect.value, 'months');

    var last_month = threeMonthsAgo.toDate().getMonth() + 1
    const totalDayslast = new Date(now.getFullYear(), threeMonthsAgo.toDate().getMonth() + 1, 0).getDate();
    const lastcurrentDays = new Date(threeMonthsAgo).getDate();
    var enddatemonthprice = campermonthprice.filter((element) => { return element.month_id === last_month })

    // var mp2 = (Math.ceil((enddatemonthprice[0].month_price) / totalDayslast) * (lastcurrentDays));
    // var mp2 = Math.round(enddatemonthprice[0].month_price / totalDayslast * lastcurrentDays)
    var nd2 = (lastcurrentDays / totalDayslast).toFixed(2);

    var mp2 = (nd2 * enddatemonthprice[0].month_price).toFixed(0)
    //alert(mp2)
    var rangedate = moment(date).add(monthselect.value, 'months');

    setEndDate(rangedate.toDate())
    var betweenmonth = [];
    if (current_month >= last_month) {
      for (var i = (current_month + 1); i <= 12; i++) {
        betweenmonth.push(i);
      }
      for (var i = 1; i < last_month; i++) {
        betweenmonth.push(i);
      }
    } else {
      for (var i = (current_month + 1); i < last_month; i++) {
        betweenmonth.push(i);
      }

    }
    const betweenmonthday = campermonthprice.filter(col => {
      return betweenmonth.find(selected => selected === col.month_id)
    })

    var totalNumber = betweenmonthday.reduce((a, b) => a + parseInt(b.month_price), Number(mp1) + Number(mp2))
    setTotalcamperprice(totalNumber)
    setStartDate(date);
    setCount(monthselect.value)



    if (date !== null) {
      //inventory api
      var userData = {
        id: params.id,
        start_date: moment(date).format("YYYY-MM-DD"),
        end_date: moment(rangedate).format("YYYY-MM-DD")
      }

      let getcheckaccessoires = await CamperService.checkaccessoires(userData).json();
      if (AccessoriesSelected.length != 0) {
        var getData = getcheckaccessoires.data.filter((element) => { return element.status === 0 })
        var newArray = []
        newArray.push(...getData, ...AccessoriesSelected)
        const uniqueArray = newArray.filter((obj, index, self) => index === self.findIndex((t) => (t.id === obj.id)));
        var getData1 = uniqueArray.filter((element) => { return element.status === 1 })
        setAccessoriesSelected(getData1);
        // setSelectPackages([
        //   ...getData1,

        // ])
      }
      setInventory(getcheckaccessoires.data)
      if (variable.search == '') {
        navigate(generatePath(`?date=${moment(date).format('YYYY-MM-DD')}`))
      } else if (variable.search !== '') {
        setSearchParams((searchParams) => {
          searchParams.set('date', `${moment(date).format('YYYY-MM-DD')}`);
          return searchParams;
        });
      } else {
        navigate(generatePath(variable.search + `&date=${moment(date).format('YYYY-MM-DD')}`))
      }
    }

  };
  const detailData = {
    type: 'Subscription',
    location: searchParams.get('location'),
    date: moment(startDate).format('YYYY-MM-DD') + "_" + moment(endDate).format('YYYY-MM-DD'),
    pickup: searchParams.get('pickup'),
    return: searchParams.get('return'),
    packages: [],
    cancelpackages: [],
    servicePackages: ServiceSelected,
    servicePackagesFix: [],
    AccessoriesPackages: AccessoriesSelected,
    AccessoriesPackagesFix: [],
    kilometerpackage: Kilometeradd,
    serviceFee: serviceFee,
    days: count,
    camperprice: totalcamperprice,
    discountcount: '',
    totalPackages: count ? (
      selectPackages.length != 0 ?

        // selectPackages.reduce((total, item) => total + (item.type === 0 ? item.price : count * item.price), Math.round(totalcamperprice) + parseInt(Kilometeradd.length != 0 ? Math.round(count * Kilometeradd[0].price) : 0) + parseInt(serviceFee))
        // selectPackages.reduce((total, item) => total + (item.type === 0 ? item.price : Math.round(count * item.price / parseInt(differenceMonth))), Math.round(totalcamperprice / parseInt(differenceMonth)) + parseInt(Kilometeradd.length != 0 ? Math.round(count * Kilometeradd[0].price / parseInt(differenceMonth)) : 0) + AccessoriesSelected.reduce((acc, curr) => acc + curr.price, 0)+ parseInt(serviceFee))
        selectPackages.reduce((total, item) => total + (item.type === 0 ? item.price : count * item.price), Math.round(totalcamperprice) + AccessoriesSelected.reduce((acc, item) => acc + (item.type === 0 ? item.price : count * item.price), 0) + parseInt(Kilometeradd.length != 0 ? Math.round(count * Kilometeradd[0].price) : 0) + parseInt(serviceFee))
        : totalcamperprice + AccessoriesSelected.reduce((acc, curr) => acc + curr.price, 0) + parseInt(Kilometeradd.length != 0 ? count * Kilometeradd[0].price : 0) + parseInt(serviceFee)

      // selectPackages.reduce((total, item) => total + (item.title === "Interior cleaning" || item.title === "Innenreinigung" || item.title === "Shuttle Service" || item.title === "Abhol-Service" || item.title === "Dog camper" || item.title === "Hunde Camper" ? item.price : count * item.price), Math.round(totalcamperprice) + parseInt(Kilometeradd.length != 0 ? Math.round(count * Kilometeradd[0].price) : 0) + parseInt(serviceFee))
      // : totalcamperprice + parseInt(Kilometeradd.length != 0 ? count * Kilometeradd[0].price : 0) + parseInt(serviceFee)

    ) : (
      selectPackages.length != 0 ?
        selectPackages.reduce((total, item) => total + item.price, totalcamperprice + parseInt(Kilometeradd.length != 0 ? Kilometeradd[0].price : 0) + parseInt(serviceFee))
        : totalcamperprice + parseInt(Kilometeradd.length != 0 ? Kilometeradd[0].price : 0) + parseInt(serviceFee)
    )



  };
  console.log(detailData, "detailData sub")


  const options = [
    // { value: '9', label: '9:00' },
    { value: '10', label: '10:00' },
    { value: '11', label: '11:00' },
    { value: '12', label: '12:00' },
    { value: '13', label: '13:00' },
    { value: '14', label: '14:00' },
    { value: '15', label: '15:00' },
    { value: '16', label: '16:00' },
    // { value: '17', label: '17:00' }
  ]

  const handleCheckout = (id) => {
    if (ExcludedateList.length === 365) {
      setError(t("rentinfo.Inventory is not available"));
    }
    else if (startDate === '') {

      setError(t("Rent.Please Select Date!"));
    }
    else if (monthselect === '') {

      setError(t("Rent.Please Select Month!"))
    }
    else if (pickup === '') {
      setError(t("Rent.Please Select Pickup Time!"));
    }
    else if (returntime === '') {
      setError(t("Rent.Please Select Return Time!"));
    }
    else {
      getCamperCheckDate(id);

      setError("")
    }

  }
  const getCamperCheckDate = async (id) => {

    const oneDate = moment(startDate).format('YYYY-MM-DD')
    const twoDate = moment(endDate).format('YYYY-MM-DD');
    console.log(twoDate);
    try {
      var userData = {
        "id": id,
        "startdate": oneDate,
        "enddate": twoDate
      }

      let responseData = await CamperService.checkdate(userData).json();

      if (responseData.status === false) {
        setError(t("Rent.Camper not available!!!"));
      } else {

        navigate(`/configurationdetail/${id}?location=1&date=${detailData.date}&pickup=${detailData.pickup}&return=${detailData.return}`, { state: detailData });
      }

    } catch (error) {
      if (error.name === 'HTTPError') {
        const errorJson = await error.response.json();
        setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
      }
    }
  };
  const handleBack = () => {
    navigate('/campersubscription')


  }
  return (
    <>

      <div className="relative flex flex-col min-h-screen font-brand md:pb-16 pb-0 xl:pb-0">

        <div className="py-4 h-20 bg-white shadow xl:mb-5 z-10">
          <div className="flex items-center h-full xl:mx-auto xl:max-w-7xl">
            <Link to="/" className="flex items-center pl-4 w-1/5 h-5">
              <img className="header-camper-logo w-32" src="/images/born-camper-logo.png" alt="camper-logo" />
            </Link>
            <div className="flex items-center justify-center w-3/5 xl:items-start step-process">
              <div className="stepper grid-cols4 relative grid gap-y-1.5 grid-flow-col grid-rows-2 justify-items-center w-5/6 md:w-3/4 configuration-channel">
                <button className="step relative w-full">
                  <div className="mx-auto w-6 h-6 text-center text-secondary small-text border-2px fw-500 rounded-full border-primary">
                    <svg className="w-full h-full" width="32" height="32">
                      <svg
                        fill="#163435"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 50 50"
                        width="17px"
                        height="17px"
                      >
                        <path d="M 41.9375 8.625 C 41.273438 8.648438 40.664063 9 40.3125 9.5625 L 21.5 38.34375 L 9.3125 27.8125 C 8.789063 27.269531 8.003906 27.066406 7.28125 27.292969 C 6.5625 27.515625 6.027344 28.125 5.902344 28.867188 C 5.777344 29.613281 6.078125 30.363281 6.6875 30.8125 L 20.625 42.875 C 21.0625 43.246094 21.640625 43.410156 22.207031 43.328125 C 22.777344 43.242188 23.28125 42.917969 23.59375 42.4375 L 43.6875 11.75 C 44.117188 11.121094 44.152344 10.308594 43.78125 9.644531 C 43.410156 8.984375 42.695313 8.589844 41.9375 8.625 Z" />
                      </svg>
                    </svg>
                  </div>
                </button>
                <div className="fw-500 text-center text-secondary hidden xl:block">
                  {t("Rent.Select model")}
                </div>
                <button className="step relative w-full activeStep">
                  <div className="mx-auto w-6 h-6 text-center text-secondary small-text border-2px fw-500 rounded-full border-primary">
                    2
                  </div>
                </button>
                <div className="fw-500 text-center text-secondary col-span-full row-start-2 xl:col-auto">
                  {t("Rent.Configuration")}
                </div>
                <button className="step relative w-full">
                  <div className="mx-auto w-6 h-6 text-center text-secondary small-text border-2px fw-500 rounded-full text-opacity-disabled">
                    3
                  </div>
                </button>
                <div className="text-center text-secondary hidden xl:block xl:text-opacity-disabled">
                  {t("Rent.Details")}
                </div>
                <button className="step relative w-full">
                  <div className="mx-auto w-6 h-6 text-center text-secondary small-text border-2px fw-500 rounded-full text-opacity-disabled">
                    4
                  </div>
                </button>
                <div className="text-center text-secondary hidden xl:block xl:text-opacity-disabled">
                  {t("Rent.Payment")}
                </div>
              </div>
            </div>
            {/* <a
              className="flex flex-row items-center justify-end pr-4 w-1/5"
              href=""
            >
              <svg
                className="w-6 h-6 cssvar-secondary-to-white bg-info rounded-full"
                width="32"
                height="32"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 32 32"
                  fill="none"
                  id="icon-info"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15.0951 12.6199C14.3751 12.6199 13.6671 12.6199 12.9543 12.6199C12.7986 12.6213 12.6437 12.6431 12.4935 12.6847C12.3103 12.7229 12.1704 12.8715 12.1432 13.0567C12.0925 13.2579 12.1937 13.4664 12.3831 13.551C12.6138 13.6589 12.8615 13.7255 13.1151 13.7478C13.7823 13.779 14.0511 14.0718 13.9503 14.7582C13.7631 16.0422 13.5111 17.3166 13.2951 18.5982C13.1487 19.4621 13.0263 20.3285 12.8847 21.1925C12.6951 22.3565 13.7799 23.4485 14.6415 23.5925C15.1519 23.664 15.667 23.6969 16.1823 23.6909C17.2866 23.6895 18.3415 23.2328 19.0982 22.4285C19.2357 22.3019 19.3389 22.1425 19.3982 21.9653C19.4272 21.8105 19.3907 21.6506 19.2974 21.5237C19.1847 21.4531 19.0459 21.4381 18.9206 21.4829C18.7238 21.5477 18.5486 21.6821 18.3567 21.7661C18.1323 21.8708 17.8973 21.9513 17.6559 22.0061C17.4611 22.0539 17.2552 22.0138 17.0926 21.8965C16.93 21.7792 16.8271 21.5964 16.8111 21.3965C16.7707 21.1732 16.7634 20.9452 16.7895 20.7197C16.8711 20.1629 16.9863 19.6109 17.0871 19.059C17.3607 17.5534 17.6383 16.047 17.9199 14.5398C17.9967 14.127 18.1263 13.7214 18.0399 13.287C18.0264 13.0941 17.936 12.9146 17.7888 12.7891C17.6416 12.6636 17.4501 12.6026 17.2575 12.6199C16.5375 12.6199 15.8151 12.6199 15.0951 12.6199ZM16.4007 7.28235C15.5158 7.24354 14.6954 7.7442 14.3254 8.54893C13.9553 9.35365 14.1091 10.3023 14.7145 10.9489C15.3199 11.5954 16.2564 11.8112 17.0837 11.4948C17.911 11.1783 18.4645 10.3926 18.4839 9.50711C18.5176 8.32037 17.5872 7.32888 16.4007 7.28715V7.28235Z"
                    fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"
                  ></path>
                </svg>
              </svg>
              <span className="hidden pl-2 md:block">{t("Rent.Info")}</span>
            </a> */}
          </div>

        </div>
        <div className="absolute left-0 top-0 hidden w-full h-20 pointer-events-none md:block">
          <div className="relative mx-auto max-w-7xl h-full">

          </div>
        </div>
        <img class="hero-img-campers inline-block max-w-full object-cover dark:brightness-90 aspect-square w-full group-hover:scale-110 transition-all duration-300" src={imageUrl + camper.background_image} alt="clara" />
        <main className="flex-grow xl:mb-64">

          <form action="">
            <div className="relative w-full max-w-7xl xl:flex xl:mx-auto">

              <Button onClick={(e) => handleBack()} className="py-4 text-white z-10 pointer-events-auto absolute top-30 left-0 flex items-center px-4"><svg class="h-6 w-6 cssvar-secondary-to-current" width="32" height="32"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none" id="icon-chevron-left"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.87444 15.9999L17.521 8.35332C17.991 7.88273 18.7555 7.88213 19.2261 8.35211L19.6468 8.77342C20.1191 9.24581 20.1167 10.0073 19.6456 10.4785L14.1241 15.9999L19.6456 21.5219C20.1167 21.9925 20.1191 22.7546 19.6468 23.2264L19.2261 23.6477C18.7555 24.1182 17.991 24.117 17.521 23.6465L9.87444 15.9999Z" fill="var(--svg-custom-color, var(--svg-color-secondary, #fff))"></path></svg></svg>{t("Rent.Back")}</Button>
              <div className="xl:w-2/3">
                <div className="md:h-[36.5rem] relative w-full overflow-hidden xl:overflow-visible">

                  <div className="relative grid place-items-center p-5 md:pb-5 pb-0 h-full md:p-10 md:pb-10 mt-5">
                    <img
                      className="object-contain object-bottom top-hero-img"
                      src={imageUrl + camper.image}
                      alt="VW T6.1 California Beach Edition"
                    />
                  </div>

                </div>

                <div className="space-y-16 md:space-y-24" id="boxesWrp">

                  <section className="">

                    <div className="fw-500 inline-flex justify-center pb-5 px-5 w-full text-3xl md:justify-between md:px-10">
                      <h3>{t("subscription.Services")}</h3>
                    </div>

                    <div className="grid gap-x-6 gap-y-5 grid-cols-1 px-5 md:grid-cols-4 md:px-10 lg:grid-cols-5 xl:grid-cols-4">
                      {camper.service && camper.service.map((service) => (
                        <div className="div">
                          <div
                            className="preserve-3d relative grid grid-cols-1 grid-rows-1 h-full transition-transform rotateBox"

                          >
                            <div className="backface-hidden col-start-1 row-start-1 w-full h-full">
                              <label className="group flex items-center px-0  w-full h-full bg-white border-transparent rounded shadow cursor-pointer md:flex-col md:px-0">
                                <div className="service-sm-box flex items-center justify-center  w-full -mx-8 bg-off-white py-2">

                                  <img className="w-14 h-14 md:w-20 md:h-20" src={service.icon ? process.env.REACT_APP_LARAVEL_IMAGE_URL + 'Service/' + service.icon : ''} />
                                </div>
                                <div className="md:px-4  px-2 py-4 flex flex-grow items-center self-stretch w-full border-secondary border-opacity-disabled md:flex-col md:pt-4 md:border-l-0 md:border-t">
                                  <div className="fw-500 flex flex-col flex-grow items-start pl-4 md:items-center md:p-0 md:text-center">
                                    <div className="text-lg-c">{service.title}</div>

                                    <div className="font-light price-font text-lg">{service.per_month}&nbsp;€
                                      {service.type == 1 ? (
                                        <span className="text-xs block">{t("Rent.per Month")} </span>
                                      ) : (
                                        ""
                                      )}
                                      {/* {
                                        service.title == "Interior cleaning" || service.title === "Innenreinigung" || service.title == "Dog camper" || service.title === "Hunde Camper" || service.title == "Shuttle Service" || service.title == "Abhol-Service"
                                          ?
                                          ""
                                          :
                                          <span className="text-xs block">{t("Rent.per Month")} </span>

                                      } */}

                                    </div>
                                  </div>

                                  <input
                                    hidden=""
                                    name="+products.1256"
                                    type="checkbox"
                                    className="peer d-none"
                                    data-gtm-form-interact-field-id="2"
                                    onClick={(e) => {
                                      if (e.target.checked) {
                                        addservice(service.id, service.title, service.per_month, service.type, "service");
                                      } else {
                                        removeservice(service.id, service.title, service.per_month, service.type, "service");
                                      }
                                    }}
                                    onChange={
                                      (e) => {
                                        updateTotal(e, service.per_month)
                                      }}
                                  ></input>


                                  <div className="peer-checked:hidden mx-auto my-2 w-7 h-7 border border-secondary group-hover:border-primary border-opacity-disabled rounded-full"></div>
                                  <svg
                                    className="peer-checked:block hidden mx-auto w-7 h-7 my-2 bg-primary rounded-full cssvar-secondary-to-white p-0.5"
                                    width="32"
                                    height="32"
                                  >
                                    <svg
                                      fill="#FFFFFF"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      width="24px"
                                      height="24px"
                                    >
                                      <path d="M 19.980469 5.9902344 A 1.0001 1.0001 0 0 0 19.292969 6.2929688 L 9 16.585938 L 5.7070312 13.292969 A 1.0001 1.0001 0 1 0 4.2929688 14.707031 L 8.2929688 18.707031 A 1.0001 1.0001 0 0 0 9.7070312 18.707031 L 20.707031 7.7070312 A 1.0001 1.0001 0 0 0 19.980469 5.9902344 z" />
                                    </svg>
                                  </svg>
                                  <div className="absolute left-0 top-0 peer-checked:block hidden w-full h-full border border-primary rounded pointer-events-none"></div>
                                </div>
                              </label>
                            </div>

                            <button
                              type="button"
                              className="flip-btn-icon backface-hidden absolute right-0 top-0 w-10 h-10 -translate-y-1/2 translate-x-1/2 md:translate-x-0 md:translate-y-0 rotateBtn"
                            >
                              <div className="relative m-auto w-7 h-7">
                                <svg
                                  className="w-7 h-7 hover:cssvar-secondary-to-white hover:bg-info bg-white rounded-full shadow md:shadow-none"
                                  width="32"
                                  height="32"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 32 32"
                                    fill="none"
                                    id="icon-info"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M15.0951 12.6199C14.3751 12.6199 13.6671 12.6199 12.9543 12.6199C12.7986 12.6213 12.6437 12.6431 12.4935 12.6847C12.3103 12.7229 12.1704 12.8715 12.1432 13.0567C12.0925 13.2579 12.1937 13.4664 12.3831 13.551C12.6138 13.6589 12.8615 13.7255 13.1151 13.7478C13.7823 13.779 14.0511 14.0718 13.9503 14.7582C13.7631 16.0422 13.5111 17.3166 13.2951 18.5982C13.1487 19.4621 13.0263 20.3285 12.8847 21.1925C12.6951 22.3565 13.7799 23.4485 14.6415 23.5925C15.1519 23.664 15.667 23.6969 16.1823 23.6909C17.2866 23.6895 18.3415 23.2328 19.0982 22.4285C19.2357 22.3019 19.3389 22.1425 19.3982 21.9653C19.4272 21.8105 19.3907 21.6506 19.2974 21.5237C19.1847 21.4531 19.0459 21.4381 18.9206 21.4829C18.7238 21.5477 18.5486 21.6821 18.3567 21.7661C18.1323 21.8708 17.8973 21.9513 17.6559 22.0061C17.4611 22.0539 17.2552 22.0138 17.0926 21.8965C16.93 21.7792 16.8271 21.5964 16.8111 21.3965C16.7707 21.1732 16.7634 20.9452 16.7895 20.7197C16.8711 20.1629 16.9863 19.6109 17.0871 19.059C17.3607 17.5534 17.6383 16.047 17.9199 14.5398C17.9967 14.127 18.1263 13.7214 18.0399 13.287C18.0264 13.0941 17.936 12.9146 17.7888 12.7891C17.6416 12.6636 17.4501 12.6026 17.2575 12.6199C16.5375 12.6199 15.8151 12.6199 15.0951 12.6199ZM16.4007 7.28235C15.5158 7.24354 14.6954 7.7442 14.3254 8.54893C13.9553 9.35365 14.1091 10.3023 14.7145 10.9489C15.3199 11.5954 16.2564 11.8112 17.0837 11.4948C17.911 11.1783 18.4645 10.3926 18.4839 9.50711C18.5176 8.32037 17.5872 7.32888 16.4007 7.28715V7.28235Z"
                                      fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"
                                    ></path>
                                  </svg>
                                </svg>
                              </div>
                            </button>

                            <div className="backface-hidden col-start-1 row-start-1 rotateBoxshow">
                              <div className="break-word relative grid grid-cols-1 grid-rows-1 place-items-center py-5 h-full text-center bg-white border border-transparent rounded shadow md:py-10 md:overflow-hidden">
                                <div className="mx-5 max-w-full break-words md:mx-2 text-md">
                                  <span>{service.description}</span>

                                </div>
                                <button type="button" className="rotateBtn absolute right-0 top-0 w-10 h-10"><svg width="32px" height="32px" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#000000" /></svg></button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </section>
                  <section className="mt-10-imp">

                    <div className="fw-500 inline-flex justify-center pb-5 px-5 w-full text-3xl md:justify-between md:px-10">
                      <h3>{t("Rent.Accessoires")} </h3>
                    </div>

                    <div className="grid gap-x-6 gap-y-5 grid-cols-1 px-5 md:grid-cols-4 md:px-10 lg:grid-cols-5 xl:grid-cols-4">
                      {/* {camper.accessory && camper.accessory.map((accessory) => (
                        <div className="div">
                          <div
                            className="preserve-3d relative grid grid-cols-1 grid-rows-1 h-full transition-transform rotateBox"

                          >
                            <div className="backface-hidden col-start-1 row-start-1 w-full h-full">
                              <label className="group flex items-center px-0  w-full h-full bg-white border-transparent rounded shadow cursor-pointer md:flex-col md:px-0">
                                <div className="service-sm-box flex items-center justify-center  w-full -mx-8 bg-off-white py-2">

                                  <img className="w-14 h-14 md:w-20 md:h-20" src={accessory.icon ? process.env.REACT_APP_LARAVEL_IMAGE_URL + 'Accessory/' + accessory.icon : ''} />
                                </div>
                                <div className="md:px-4  px-2 py-4 flex flex-grow items-center self-stretch w-full border-secondary border-opacity-disabled md:flex-col md:pt-4 md:border-l-0 md:border-t">
                                  <div className="fw-500 flex flex-col flex-grow items-start pl-4 md:items-center md:p-0 md:text-center">
                                    <div className="text-lg-c">{accessory.title}</div>
                                    <div className="font-light price-font text-lg"><NumericFormat
                                      value={accessory.per_month}
                                      thousandSeparator={'.'}
                                      decimalSeparator={','}
                                      displayType={'text'}

                                    />&nbsp;€ <span className="text-xs block">per Month</span></div>
                                  </div>
                                  <input
                                    hidden=""
                                    name="+products.1256"
                                    type="checkbox"
                                    className="peer d-none"
                                    data-gtm-form-interact-field-id="2"
                                    onClick={(e) => {
                                      if (e.target.checked) {
                                        addAccessories(accessory.id, accessory.title, accessory.per_month, "Accessories");
                                      } else {
                                        removeAccessories(accessory.id, accessory.title, accessory.per_month, "Accessories");
                                      }
                                    }}
                                    onChange={
                                      (e) => {
                                        updateTotal(e, accessory.per_month)
                                      }}
                                  ></input>


                                  <div className="peer-checked:hidden mx-auto my-2 w-7 h-7 border border-secondary group-hover:border-primary border-opacity-disabled rounded-full"></div>
                                  <svg
                                    className="peer-checked:block hidden mx-auto w-7 h-7 my-2 bg-primary rounded-full cssvar-secondary-to-white p-0.5"
                                    width="32"
                                    height="32"
                                  >
                                    <svg
                                      fill="#FFFFFF"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      width="24px"
                                      height="24px"
                                    >
                                      <path d="M 19.980469 5.9902344 A 1.0001 1.0001 0 0 0 19.292969 6.2929688 L 9 16.585938 L 5.7070312 13.292969 A 1.0001 1.0001 0 1 0 4.2929688 14.707031 L 8.2929688 18.707031 A 1.0001 1.0001 0 0 0 9.7070312 18.707031 L 20.707031 7.7070312 A 1.0001 1.0001 0 0 0 19.980469 5.9902344 z" />
                                    </svg>
                                  </svg>
                                  <div className="absolute left-0 top-0 peer-checked:block hidden w-full h-full border border-primary rounded pointer-events-none"></div>
                                </div>
                              </label>
                            </div>

                            <button
                              type="button"
                              className="flip-btn-icon backface-hidden absolute right-0 top-0 w-10 h-10 -translate-y-1/2 translate-x-1/2 md:translate-x-0 md:translate-y-0 rotateBtn"
                            >
                              <div className="relative m-auto w-7 h-7">
                                <svg
                                  className="w-7 h-7 hover:cssvar-secondary-to-white hover:bg-info bg-white rounded-full shadow md:shadow-none"
                                  width="32"
                                  height="32"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 32 32"
                                    fill="none"
                                    id="icon-info"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M15.0951 12.6199C14.3751 12.6199 13.6671 12.6199 12.9543 12.6199C12.7986 12.6213 12.6437 12.6431 12.4935 12.6847C12.3103 12.7229 12.1704 12.8715 12.1432 13.0567C12.0925 13.2579 12.1937 13.4664 12.3831 13.551C12.6138 13.6589 12.8615 13.7255 13.1151 13.7478C13.7823 13.779 14.0511 14.0718 13.9503 14.7582C13.7631 16.0422 13.5111 17.3166 13.2951 18.5982C13.1487 19.4621 13.0263 20.3285 12.8847 21.1925C12.6951 22.3565 13.7799 23.4485 14.6415 23.5925C15.1519 23.664 15.667 23.6969 16.1823 23.6909C17.2866 23.6895 18.3415 23.2328 19.0982 22.4285C19.2357 22.3019 19.3389 22.1425 19.3982 21.9653C19.4272 21.8105 19.3907 21.6506 19.2974 21.5237C19.1847 21.4531 19.0459 21.4381 18.9206 21.4829C18.7238 21.5477 18.5486 21.6821 18.3567 21.7661C18.1323 21.8708 17.8973 21.9513 17.6559 22.0061C17.4611 22.0539 17.2552 22.0138 17.0926 21.8965C16.93 21.7792 16.8271 21.5964 16.8111 21.3965C16.7707 21.1732 16.7634 20.9452 16.7895 20.7197C16.8711 20.1629 16.9863 19.6109 17.0871 19.059C17.3607 17.5534 17.6383 16.047 17.9199 14.5398C17.9967 14.127 18.1263 13.7214 18.0399 13.287C18.0264 13.0941 17.936 12.9146 17.7888 12.7891C17.6416 12.6636 17.4501 12.6026 17.2575 12.6199C16.5375 12.6199 15.8151 12.6199 15.0951 12.6199ZM16.4007 7.28235C15.5158 7.24354 14.6954 7.7442 14.3254 8.54893C13.9553 9.35365 14.1091 10.3023 14.7145 10.9489C15.3199 11.5954 16.2564 11.8112 17.0837 11.4948C17.911 11.1783 18.4645 10.3926 18.4839 9.50711C18.5176 8.32037 17.5872 7.32888 16.4007 7.28715V7.28235Z"
                                      fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"
                                    ></path>
                                  </svg>
                                </svg>
                              </div>
                            </button>

                            <div className="backface-hidden col-start-1 row-start-1 rotateBoxshow">
                              <div className="break-word relative grid grid-cols-1 grid-rows-1 place-items-center py-5 h-full text-center bg-white border border-transparent rounded shadow md:py-10 md:overflow-hidden">
                                <div className="mx-5 max-w-full break-words md:mx-2 text-md">
                                  <span>{accessory.description}</span>

                                </div>
                                <button type="button" className="rotateBtn absolute right-0 top-0 w-10 h-10"><svg width="32px" height="32px" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#000000" /></svg></button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))} */}
                      {Inventory.map((accessory) => (
                        <>
                          {
                            accessory.status === 1 ?
                              <div className="div">
                                <div
                                  className="preserve-3d relative grid grid-cols-1 grid-rows-1 h-full transition-transform rotateBox"

                                >
                                  <div className="backface-hidden col-start-1 row-start-1 w-full h-full">
                                    <label className="group flex items-center px-0  w-full h-full bg-white border-transparent rounded shadow cursor-pointer md:flex-col md:px-0">
                                      <div className="service-sm-box flex items-center justify-center  w-full -mx-8 bg-off-white py-2">

                                        <img className="w-14 h-14 md:w-20 md:h-20" src={accessory.icon ? process.env.REACT_APP_LARAVEL_IMAGE_URL + 'Accessory/' + accessory.icon : ''} />
                                      </div>
                                      <div className="md:px-4  px-2 py-4 flex flex-grow items-center self-stretch w-full border-secondary border-opacity-disabled md:flex-col md:pt-4 md:border-l-0 md:border-t">
                                        <div className="fw-500 flex flex-col flex-grow items-start pl-4 md:items-center md:p-0 md:text-center">
                                          <div className="text-lg-c">{accessory.title}</div>
                                          <div className="font-light price-font text-lg"><NumericFormat
                                            value={accessory.per_month}
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                            displayType={'text'}

                                          />&nbsp;€
                                            {accessory.type == 1 ? (
                                              <span className="text-xs block">{t("Rent.per Month")}</span>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                        <input
                                          hidden=""
                                          name="+products.1256"
                                          type="checkbox"
                                          className="peer d-none"
                                          data-gtm-form-interact-field-id="2"
                                          onClick={(e) => {
                                            if (e.target.checked) {
                                              addAccessories(accessory.id, accessory.title, accessory.per_month, accessory.type, "Accessories");
                                            } else {
                                              removeAccessories(accessory.id, accessory.title, accessory.per_month, accessory.type, "Accessories");
                                            }
                                          }}
                                          onChange={
                                            (e) => {
                                              updateTotal(e, accessory.per_month)
                                            }}
                                        ></input>


                                        <div className="peer-checked:hidden mx-auto my-2 w-7 h-7 border border-secondary group-hover:border-primary border-opacity-disabled rounded-full"></div>
                                        <svg
                                          className="peer-checked:block hidden mx-auto w-7 h-7 my-2 bg-primary rounded-full cssvar-secondary-to-white p-0.5"
                                          width="32"
                                          height="32"
                                        >
                                          <svg
                                            fill="#FFFFFF"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            width="24px"
                                            height="24px"
                                          >
                                            <path d="M 19.980469 5.9902344 A 1.0001 1.0001 0 0 0 19.292969 6.2929688 L 9 16.585938 L 5.7070312 13.292969 A 1.0001 1.0001 0 1 0 4.2929688 14.707031 L 8.2929688 18.707031 A 1.0001 1.0001 0 0 0 9.7070312 18.707031 L 20.707031 7.7070312 A 1.0001 1.0001 0 0 0 19.980469 5.9902344 z" />
                                          </svg>
                                        </svg>
                                        <div className="absolute left-0 top-0 peer-checked:block hidden w-full h-full border border-primary rounded pointer-events-none"></div>
                                      </div>
                                    </label>
                                  </div>

                                  <button
                                    type="button"
                                    className="flip-btn-icon backface-hidden absolute right-0 top-0 w-10 h-10 -translate-y-1/2 translate-x-1/2 md:translate-x-0 md:translate-y-0 rotateBtn"
                                  >
                                    <div className="relative m-auto w-7 h-7">
                                      <svg
                                        className="w-7 h-7 hover:cssvar-secondary-to-white hover:bg-info bg-white rounded-full shadow md:shadow-none"
                                        width="32"
                                        height="32"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 32 32"
                                          fill="none"
                                          id="icon-info"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M15.0951 12.6199C14.3751 12.6199 13.6671 12.6199 12.9543 12.6199C12.7986 12.6213 12.6437 12.6431 12.4935 12.6847C12.3103 12.7229 12.1704 12.8715 12.1432 13.0567C12.0925 13.2579 12.1937 13.4664 12.3831 13.551C12.6138 13.6589 12.8615 13.7255 13.1151 13.7478C13.7823 13.779 14.0511 14.0718 13.9503 14.7582C13.7631 16.0422 13.5111 17.3166 13.2951 18.5982C13.1487 19.4621 13.0263 20.3285 12.8847 21.1925C12.6951 22.3565 13.7799 23.4485 14.6415 23.5925C15.1519 23.664 15.667 23.6969 16.1823 23.6909C17.2866 23.6895 18.3415 23.2328 19.0982 22.4285C19.2357 22.3019 19.3389 22.1425 19.3982 21.9653C19.4272 21.8105 19.3907 21.6506 19.2974 21.5237C19.1847 21.4531 19.0459 21.4381 18.9206 21.4829C18.7238 21.5477 18.5486 21.6821 18.3567 21.7661C18.1323 21.8708 17.8973 21.9513 17.6559 22.0061C17.4611 22.0539 17.2552 22.0138 17.0926 21.8965C16.93 21.7792 16.8271 21.5964 16.8111 21.3965C16.7707 21.1732 16.7634 20.9452 16.7895 20.7197C16.8711 20.1629 16.9863 19.6109 17.0871 19.059C17.3607 17.5534 17.6383 16.047 17.9199 14.5398C17.9967 14.127 18.1263 13.7214 18.0399 13.287C18.0264 13.0941 17.936 12.9146 17.7888 12.7891C17.6416 12.6636 17.4501 12.6026 17.2575 12.6199C16.5375 12.6199 15.8151 12.6199 15.0951 12.6199ZM16.4007 7.28235C15.5158 7.24354 14.6954 7.7442 14.3254 8.54893C13.9553 9.35365 14.1091 10.3023 14.7145 10.9489C15.3199 11.5954 16.2564 11.8112 17.0837 11.4948C17.911 11.1783 18.4645 10.3926 18.4839 9.50711C18.5176 8.32037 17.5872 7.32888 16.4007 7.28715V7.28235Z"
                                            fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"
                                          ></path>
                                        </svg>
                                      </svg>
                                    </div>
                                  </button>

                                  <div className="backface-hidden col-start-1 row-start-1 rotateBoxshow">
                                    <div className="break-word relative grid grid-cols-1 grid-rows-1 place-items-center py-5 h-full text-center bg-white border border-transparent rounded shadow md:py-10 md:overflow-hidden">
                                      <div className="mx-5 max-w-full break-words md:mx-2 text-md">
                                        <span>{accessory.description}</span>

                                      </div>
                                      <button type="button" className="rotateBtn absolute right-0 top-0 w-10 h-10"><svg width="32px" height="32px" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#000000" /></svg></button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              :
                              <div className="div  disable-service">
                                <div
                                  className="preserve-3d relative grid grid-cols-1 grid-rows-1 h-full transition-transform rotateBox"

                                >
                                  <div className="backface-hidden col-start-1 row-start-1 w-full h-full">
                                    <label className="group flex items-center px-0  w-full h-full bg-white border-transparent rounded shadow cursor-pointer md:flex-col md:px-0">
                                      <div className="service-sm-box flex items-center justify-center  w-full -mx-8 bg-off-white py-2">

                                        <img className="w-14 h-14 md:w-20 md:h-20" src={accessory.icon ? process.env.REACT_APP_LARAVEL_IMAGE_URL + 'Accessory/' + accessory.icon : ''} />
                                      </div>
                                      <div className="md:px-4  px-2 py-4 flex flex-grow items-center self-stretch w-full border-secondary border-opacity-disabled md:flex-col md:pt-4 md:border-l-0 md:border-t">
                                        <div className="fw-500 flex flex-col flex-grow items-start pl-4 md:items-center md:p-0 md:text-center">
                                          <div className="text-lg-c">{accessory.title}</div>
                                          <div className="font-light price-font text-lg"><NumericFormat
                                            value={accessory.per_month}
                                            thousandSeparator={'.'}
                                            decimalSeparator={','}
                                            displayType={'text'}

                                          />&nbsp;€ <span className="text-xs block">{t("Rent.per Month")}</span></div>
                                        </div>
                                        {/* <input
                                          hidden=""
                                          name="+products.1256"
                                          type="checkbox"
                                          className="peer d-none"
                                          data-gtm-form-interact-field-id="2"
                                          onClick={(e) => {
                                            if (e.target.checked) {
                                              addAccessories(accessory.id, accessory.title, accessory.per_month,accessory.status, "Accessories");
                                            } else {
                                              removeAccessories(accessory.id, accessory.title, accessory.per_month, "Accessories");
                                            }
                                          }}
                                          onChange={
                                            (e) => {
                                              updateTotal(e, accessory.per_month)
                                            }}
                                          disabled
                                        ></input> */}


                                        <div className="peer-checked:hidden mx-auto my-2 w-7 h-7 border border-secondary group-hover:border-primary border-opacity-disabled rounded-full"></div>
                                        <svg
                                          className="peer-checked:block hidden mx-auto w-7 h-7 my-2 bg-primary rounded-full cssvar-secondary-to-white p-0.5"
                                          width="32"
                                          height="32"
                                        >
                                          <svg
                                            fill="#FFFFFF"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 24 24"
                                            width="24px"
                                            height="24px"
                                          >
                                            <path d="M 19.980469 5.9902344 A 1.0001 1.0001 0 0 0 19.292969 6.2929688 L 9 16.585938 L 5.7070312 13.292969 A 1.0001 1.0001 0 1 0 4.2929688 14.707031 L 8.2929688 18.707031 A 1.0001 1.0001 0 0 0 9.7070312 18.707031 L 20.707031 7.7070312 A 1.0001 1.0001 0 0 0 19.980469 5.9902344 z" />
                                          </svg>
                                        </svg>
                                        <div className="absolute left-0 top-0 peer-checked:block hidden w-full h-full border border-primary rounded pointer-events-none"></div>
                                      </div>
                                    </label>
                                  </div>

                                  <button
                                    type="button"
                                    className="flip-btn-icon backface-hidden absolute right-0 top-0 w-10 h-10 -translate-y-1/2 translate-x-1/2 md:translate-x-0 md:translate-y-0 rotateBtn"
                                  >
                                    <div className="relative m-auto w-7 h-7">
                                      <svg
                                        className="w-7 h-7 hover:cssvar-secondary-to-white hover:bg-info bg-white rounded-full shadow md:shadow-none"
                                        width="32"
                                        height="32"
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 32 32"
                                          fill="none"
                                          id="icon-info"
                                        >
                                          <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M15.0951 12.6199C14.3751 12.6199 13.6671 12.6199 12.9543 12.6199C12.7986 12.6213 12.6437 12.6431 12.4935 12.6847C12.3103 12.7229 12.1704 12.8715 12.1432 13.0567C12.0925 13.2579 12.1937 13.4664 12.3831 13.551C12.6138 13.6589 12.8615 13.7255 13.1151 13.7478C13.7823 13.779 14.0511 14.0718 13.9503 14.7582C13.7631 16.0422 13.5111 17.3166 13.2951 18.5982C13.1487 19.4621 13.0263 20.3285 12.8847 21.1925C12.6951 22.3565 13.7799 23.4485 14.6415 23.5925C15.1519 23.664 15.667 23.6969 16.1823 23.6909C17.2866 23.6895 18.3415 23.2328 19.0982 22.4285C19.2357 22.3019 19.3389 22.1425 19.3982 21.9653C19.4272 21.8105 19.3907 21.6506 19.2974 21.5237C19.1847 21.4531 19.0459 21.4381 18.9206 21.4829C18.7238 21.5477 18.5486 21.6821 18.3567 21.7661C18.1323 21.8708 17.8973 21.9513 17.6559 22.0061C17.4611 22.0539 17.2552 22.0138 17.0926 21.8965C16.93 21.7792 16.8271 21.5964 16.8111 21.3965C16.7707 21.1732 16.7634 20.9452 16.7895 20.7197C16.8711 20.1629 16.9863 19.6109 17.0871 19.059C17.3607 17.5534 17.6383 16.047 17.9199 14.5398C17.9967 14.127 18.1263 13.7214 18.0399 13.287C18.0264 13.0941 17.936 12.9146 17.7888 12.7891C17.6416 12.6636 17.4501 12.6026 17.2575 12.6199C16.5375 12.6199 15.8151 12.6199 15.0951 12.6199ZM16.4007 7.28235C15.5158 7.24354 14.6954 7.7442 14.3254 8.54893C13.9553 9.35365 14.1091 10.3023 14.7145 10.9489C15.3199 11.5954 16.2564 11.8112 17.0837 11.4948C17.911 11.1783 18.4645 10.3926 18.4839 9.50711C18.5176 8.32037 17.5872 7.32888 16.4007 7.28715V7.28235Z"
                                            fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"
                                          ></path>
                                        </svg>
                                      </svg>
                                    </div>
                                  </button>

                                  <div className="backface-hidden col-start-1 row-start-1 rotateBoxshow">
                                    <div className="break-word relative grid grid-cols-1 grid-rows-1 place-items-center py-5 h-full text-center bg-white border border-transparent rounded shadow md:py-10 md:overflow-hidden">
                                      <div className="mx-5 max-w-full break-words md:mx-2 text-md">
                                        <span>{accessory.description}</span>

                                      </div>
                                      <button type="button" className="rotateBtn absolute right-0 top-0 w-10 h-10"><svg width="32px" height="32px" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#000000" /></svg></button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                          }
                        </>

                      ))}
                    </div>

                  </section>
                  <section className="mt-10-imp">

                    <div className="fw-500 inline-flex justify-center pb-5 px-5 w-full text-3xl md:justify-between md:px-10">
                      {
                        camper.kilometer && camper.kilometer.length === 0 ?
                          ''
                          :
                          <h3>{t("Rent.Kilometer")}</h3>

                      }


                    </div>

                    <div className="grid gap-x-6 gap-y-5 grid-cols-1 px-5 md:grid-cols-4 md:px-10 lg:grid-cols-5 xl:grid-cols-4">
                      {camper.kilometer && camper.kilometer.map((accessory, index) => (
                        <div className="div">
                          <div
                            className="preserve-3d relative grid grid-cols-1 grid-rows-1 h-full transition-transform rotateBox"

                          >
                            <div className="backface-hidden col-start-1 row-start-1 w-full h-full">
                              <label className="group flex items-center px-0  w-full h-full bg-white border-transparent rounded shadow cursor-pointer md:flex-col md:px-0">
                                <div className="service-sm-box flex items-center justify-center  w-full -mx-8 bg-off-white py-2">

                                  <img className="w-14 h-14 md:w-20 md:h-20" src={accessory.icon ? process.env.REACT_APP_LARAVEL_IMAGE_URL + 'Kilometer/' + accessory.icon : ''} />
                                </div>
                                <div className="md:px-4  px-2 py-4 flex flex-grow items-center self-stretch w-full border-secondary border-opacity-disabled md:flex-col md:pt-4 md:border-l-0 md:border-t">
                                  <div className="fw-500 flex flex-col flex-grow items-start pl-4 md:items-center md:p-0 md:text-center">
                                    <div className="text-lg-c">{accessory.title}</div>
                                    <div className="font-light price-font text-lg">{accessory.price}&nbsp;€<span className="text-xs block">{t("Rent.per Month")}</span></div>
                                  </div>
                                  <input
                                    hidden=""
                                    name="+products.1257"
                                    type="checkbox"
                                    className="peer d-none"
                                    data-gtm-form-interact-field-id="3"
                                    checked={accessory.id === kilometeroption}
                                    onClick={(e) => {
                                      if (e.target.checked) {
                                        addkilometer(accessory.id, accessory.title, accessory.price, "Kilometer");
                                      } else {
                                        removekilometer(accessory.id, accessory.title, accessory.price, "Kilometer");
                                      }
                                    }}
                                    onChange={
                                      (e) => {
                                        updateTotal(e, accessory.price)
                                      }}
                                    onLoad={() => {
                                      if (index == 0) {
                                        addkilometer(accessory.id, accessory.title, accessory.price, "Kilometer");
                                      } else { }
                                    }}
                                  ></input>
                                  <div className="peer-checked:hidden mx-auto my-2 w-7 h-7 border border-secondary group-hover:border-primary border-opacity-disabled rounded-full"></div>
                                  <svg
                                    className="peer-checked:block hidden mx-auto w-7 h-7 my-2 bg-primary rounded-full cssvar-secondary-to-white p-0.5"
                                    width="32"
                                    height="32"
                                  >
                                    <svg
                                      fill="#FFFFFF"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      width="24px"
                                      height="24px"
                                    >
                                      <path d="M 19.980469 5.9902344 A 1.0001 1.0001 0 0 0 19.292969 6.2929688 L 9 16.585938 L 5.7070312 13.292969 A 1.0001 1.0001 0 1 0 4.2929688 14.707031 L 8.2929688 18.707031 A 1.0001 1.0001 0 0 0 9.7070312 18.707031 L 20.707031 7.7070312 A 1.0001 1.0001 0 0 0 19.980469 5.9902344 z" />
                                    </svg>
                                  </svg>
                                  <div className="absolute left-0 top-0 peer-checked:block hidden w-full h-full border border-primary rounded pointer-events-none"></div>
                                </div>
                              </label>
                            </div>

                            <button
                              type="button"
                              className="flip-btn-icon backface-hidden absolute right-0 top-0 w-10 h-10 -translate-y-1/2 translate-x-1/2 md:translate-x-0 md:translate-y-0 rotateBtn"
                            >
                              <div className="relative m-auto w-7 h-7">
                                <svg
                                  className="w-7 h-7 hover:cssvar-secondary-to-white hover:bg-info bg-white rounded-full shadow md:shadow-none"
                                  width="32"
                                  height="32"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 32 32"
                                    fill="none"
                                    id="icon-info"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M15.0951 12.6199C14.3751 12.6199 13.6671 12.6199 12.9543 12.6199C12.7986 12.6213 12.6437 12.6431 12.4935 12.6847C12.3103 12.7229 12.1704 12.8715 12.1432 13.0567C12.0925 13.2579 12.1937 13.4664 12.3831 13.551C12.6138 13.6589 12.8615 13.7255 13.1151 13.7478C13.7823 13.779 14.0511 14.0718 13.9503 14.7582C13.7631 16.0422 13.5111 17.3166 13.2951 18.5982C13.1487 19.4621 13.0263 20.3285 12.8847 21.1925C12.6951 22.3565 13.7799 23.4485 14.6415 23.5925C15.1519 23.664 15.667 23.6969 16.1823 23.6909C17.2866 23.6895 18.3415 23.2328 19.0982 22.4285C19.2357 22.3019 19.3389 22.1425 19.3982 21.9653C19.4272 21.8105 19.3907 21.6506 19.2974 21.5237C19.1847 21.4531 19.0459 21.4381 18.9206 21.4829C18.7238 21.5477 18.5486 21.6821 18.3567 21.7661C18.1323 21.8708 17.8973 21.9513 17.6559 22.0061C17.4611 22.0539 17.2552 22.0138 17.0926 21.8965C16.93 21.7792 16.8271 21.5964 16.8111 21.3965C16.7707 21.1732 16.7634 20.9452 16.7895 20.7197C16.8711 20.1629 16.9863 19.6109 17.0871 19.059C17.3607 17.5534 17.6383 16.047 17.9199 14.5398C17.9967 14.127 18.1263 13.7214 18.0399 13.287C18.0264 13.0941 17.936 12.9146 17.7888 12.7891C17.6416 12.6636 17.4501 12.6026 17.2575 12.6199C16.5375 12.6199 15.8151 12.6199 15.0951 12.6199ZM16.4007 7.28235C15.5158 7.24354 14.6954 7.7442 14.3254 8.54893C13.9553 9.35365 14.1091 10.3023 14.7145 10.9489C15.3199 11.5954 16.2564 11.8112 17.0837 11.4948C17.911 11.1783 18.4645 10.3926 18.4839 9.50711C18.5176 8.32037 17.5872 7.32888 16.4007 7.28715V7.28235Z"
                                      fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"
                                    ></path>
                                  </svg>
                                </svg>
                              </div>
                            </button>

                            <div className="backface-hidden col-start-1 row-start-1 rotateBoxshow">
                              <div className="break-word relative grid grid-cols-1 grid-rows-1 place-items-center py-5 h-full text-center bg-white border border-transparent rounded shadow md:py-10 md:overflow-hidden">
                                <div className="mx-5 max-w-full break-words md:mx-2 text-md">
                                  <span>{accessory.description}</span>

                                </div>
                                <button type="button" className="rotateBtn absolute right-0 top-0 w-10 h-10"><svg width="32px" height="32px" viewBox="0 0 24 24" fill="none"><path fillRule="evenodd" clipRule="evenodd" d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z" fill="#000000" /></svg></button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>

                  </section>


                  <div className="mb-16 mx-5 bg-white rounded shadow overflow-hidden md:mx-10 xl:mb-0 question-box">
                    <div className="flex flex-col items-center justify-center md:my-10 my-5 px-4 text-center text-2xl sm:p-6 letter-spacing-1-2">
                      <svg className="mb-4 w-8 h-8 cssvar-secondary-to-white bg-info rounded-full" width="32" height="32">
                        <svg viewBox="0 0 32 32" fill="none" id="icon-info"><path fillRule="evenodd" clipRule="evenodd" d="M15.0951 12.6199C14.3751 12.6199 13.6671 12.6199 12.9543 12.6199C12.7986 12.6213 12.6437 12.6431 12.4935 12.6847C12.3103 12.7229 12.1704 12.8715 12.1432 13.0567C12.0925 13.2579 12.1937 13.4664 12.3831 13.551C12.6138 13.6589 12.8615 13.7255 13.1151 13.7478C13.7823 13.779 14.0511 14.0718 13.9503 14.7582C13.7631 16.0422 13.5111 17.3166 13.2951 18.5982C13.1487 19.4621 13.0263 20.3285 12.8847 21.1925C12.6951 22.3565 13.7799 23.4485 14.6415 23.5925C15.1519 23.664 15.667 23.6969 16.1823 23.6909C17.2866 23.6895 18.3415 23.2328 19.0982 22.4285C19.2357 22.3019 19.3389 22.1425 19.3982 21.9653C19.4272 21.8105 19.3907 21.6506 19.2974 21.5237C19.1847 21.4531 19.0459 21.4381 18.9206 21.4829C18.7238 21.5477 18.5486 21.6821 18.3567 21.7661C18.1323 21.8708 17.8973 21.9513 17.6559 22.0061C17.4611 22.0539 17.2552 22.0138 17.0926 21.8965C16.93 21.7792 16.8271 21.5964 16.8111 21.3965C16.7707 21.1732 16.7634 20.9452 16.7895 20.7197C16.8711 20.1629 16.9863 19.6109 17.0871 19.059C17.3607 17.5534 17.6383 16.047 17.9199 14.5398C17.9967 14.127 18.1263 13.7214 18.0399 13.287C18.0264 13.0941 17.936 12.9146 17.7888 12.7891C17.6416 12.6636 17.4501 12.6026 17.2575 12.6199C16.5375 12.6199 15.8151 12.6199 15.0951 12.6199ZM16.4007 7.28235C15.5158 7.24354 14.6954 7.7442 14.3254 8.54893C13.9553 9.35365 14.1091 10.3023 14.7145 10.9489C15.3199 11.5954 16.2564 11.8112 17.0837 11.4948C17.911 11.1783 18.4645 10.3926 18.4839 9.50711C18.5176 8.32037 17.5872 7.32888 16.4007 7.28715V7.28235Z" fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"></path></svg>
                      </svg>
                      <div>{t("Voucher.Questions?")}</div>
                      <div>{t("Rent.We are available almost at any time")}</div>
                      <div className="xl:block price-font">
                        <Link to="tel:+49 (0) 163 2937354" class="mt-2 w-full text-center  hover:text-primary hover:dark:text-primary-light lg:block text-lg">+49 (0) 163 2937354</Link>
                      </div>
                    </div>
                  </div>


                </div>

              </div>

              <div className="relative my-10">
                <div className="bg-white rounded-button shadow rounded-none divide-secondary divide-opacity-disabled divide-y overflow-hidden xl:rounded-3xl max-w-[100vw] h-full max-h-screen overflow-y-auto xl:sticky xl:top-10 xl:h-auto xl:overflow-y-visible">
                  <div className="flex flex-col items-center p-6 mx-auto max-w-3xl ">
                    <h1 className="break-words mb-2 text-xl xs:mb-6 price-font fw-600 text-center">{camper.title}</h1>
                    {camper.camper_details && camper.camper_details.map((technical) => (
                      <span className="mb-3">{technical.sub_title}</span>))}
                    <div className="relative w-full">

                      <span className="flex h-14 w-full items-center bg-white dark:bg-off-black-desat transition-all child-span:overflow-hidden child-span:text-ellipsis border focus:border-primary focus:outline-none justify-between border-grey hover:shadow dark:border-grey-dark dark:hover:shadow-dark rounded">
                        <div className="inline-block align-top ml-3 mr-1 min-w-fit child:h-7 child:w-7 dark:cssvar-secondary-to-off-white">
                          <svg width="32" height="32" viewBox="0 0 32 32">
                            <g fill="none" fillRule="evenodd">
                              <path
                                d="M21.06 4.5c.474 0 .884.3 1.01.741l.028.124.01.127-.002 1.327h1.14c1.503 0 2.772 1.005 3.049 2.362l.03.179.019.182.006.183v13.87c0 1.476-1.178 2.7-2.715 2.882l-.194.017-.195.006H9.104c-1.569 0-2.882-1.094-3.079-2.54l-.019-.183L6 23.595V9.725c0-1.477 1.178-2.7 2.715-2.882l.194-.018.195-.005 1.139-.001V5.492c0-.473.347-.864.808-.966l.117-.02.121-.006c.473 0 .883.3 1.01.741l.027.124.01.127-.001 1.327h2.858V5.463c0-.452.316-.828.74-.934l.12-.022.122-.007a.98.98 0 0 1 .941.697l.029.117.012.149-.002 1.356h2.859V5.492c0-.473.348-.864.808-.966l.117-.02.122-.006zm3.197 7.015H8.092v11.989c0 .473.328.877.764.983l.122.021.126.007h14.142c.476 0 .878-.33.983-.764l.021-.122.007-.125V11.515zm-7.883 1.614 1.085 2.516c.031.071.097.12.174.129l2.75.28c.187.02.26.253.118.377l-2.072 1.791a.214.214 0 0 0-.07.21l.613 2.73a.215.215 0 0 1-.321.23l-2.365-1.446a.214.214 0 0 0-.222 0L13.7 21.393a.215.215 0 0 1-.32-.232l.611-2.729a.215.215 0 0 0-.07-.21l-2.071-1.79a.215.215 0 0 1 .118-.377l2.75-.281a.212.212 0 0 0 .174-.129l1.085-2.516a.217.217 0 0 1 .397 0z"
                                fill="var(--svg-color-secondary, #163435)"
                              ></path>
                            </g>
                          </svg>
                        </div>
                        <p>{ }</p>

                        <span className="text-ellipsis whitespace-nowrap text-md custom-calender" >

                          <DatePicker
                            selected={excludeDatesget.length === 365 ? "" : startDate}
                            onChange={onChangedate}
                            excludeDates={excludeDatesget}
                            minDate={minSelectableDate}
                            maxDate={addMonths(new Date(), 12)}
                            renderDayContents={renderDayContents}
                            dateFormat="dd/MM/yyyy"
                            locale={i18n.resolvedLanguage}
                            monthsShown={isMobile ? 1 : 2}
                            withPortal
                            placeholderText={t("Rent.Whenever")}
                            onFocus={e => e.target.blur()}
                            ref={pickerRef}
                            required

                          />

                        </span>


                        <div className="inline-block align-top ml-2 mr-4 min-w-fit transition-all child:h-6 child:w-6 dark:cssvar-secondary-to-off-white">

                          <svg width="32" height="32" viewBox="0 0 32 32">
                            <g fill="none" fillRule="evenodd">
                              <path
                                d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                                fill="var(--svg-color-secondary, #163435)"
                              ></path>

                            </g>
                          </svg>
                        </div>
                      </span>
                    </div>


                    <div className="mt-2 w-full custom-pickup-return custom-months-select">

                      <div>
                        <span className="group outline-none">
                          <div className="relative flex items-center h-12 border-opacity-disabled  block w-full h-14 cursor-default">
                            <div className="flex items-center justify-between w-full opacity-disabled">
                              <svg class="w-7 h-7 watch" width="28px" height="28px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 7V12L14.5 13.5M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                              {/* Pickup */}
                              <Select
                                // autoFocus
                                backspaceRemovesValue={false}
                                hideSelectedOptions={false}
                                tabSelectsValue={false}
                                className="react-select-container w-full"
                                classNamePrefix="react-select"
                                options={monthoptions}
                                onChange={handleMonth}
                                isSearchable={false}
                                value={monthselect}



                              />
                              <svg className="down-arrow w-7 h-7" width="32" height="32" aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none" id="icon-chevron-down"><path fillRule="evenodd" clipRule="evenodd" d="M16.0001 22.1256L23.6467 14.479C24.1173 14.009 24.1179 13.2445 23.6479 12.7739L23.2266 12.3532C22.7542 11.8809 21.9927 11.8833 21.5215 12.3544L16.0001 17.8759L10.4781 12.3544C10.0075 11.8833 9.24543 11.8809 8.77364 12.3532L8.35234 12.7739C7.88175 13.2445 7.88296 14.009 8.35354 14.479L16.0001 22.1256Z" fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"></path></svg></svg>
                            </div>
                          </div>
                        </span>
                      </div>



                    </div>
                    <div className="grid gap-4 grid-cols-2 mt-2 w-full custom-pickup-return">

                      <div>
                        <span className="group outline-none">
                          <div className="relative flex items-center h-12 border-opacity-disabled  block w-full h-12 cursor-default">
                            <div className="flex items-center justify-between w-full opacity-disabled">
                              <svg class="w-7 h-7 watch" width="28px" height="28px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 7V12L14.5 13.5M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                              {/* Pickup */}
                              <Select
                                // autoFocus
                                backspaceRemovesValue={false}
                                hideSelectedOptions={false}
                                tabSelectsValue={false}
                                className="react-select-container w-full"
                                classNamePrefix="react-select"
                                options={options}
                                placeholder="Pickup"
                                isSearchable={false}
                                value={options.filter(function (option) {
                                  return option.value === pickup;
                                })}
                                onChange={handleTime}


                              />
                              <svg className="down-arrow w-7 h-7" width="32" height="32" aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none" id="icon-chevron-down"><path fillRule="evenodd" clipRule="evenodd" d="M16.0001 22.1256L23.6467 14.479C24.1173 14.009 24.1179 13.2445 23.6479 12.7739L23.2266 12.3532C22.7542 11.8809 21.9927 11.8833 21.5215 12.3544L16.0001 17.8759L10.4781 12.3544C10.0075 11.8833 9.24543 11.8809 8.77364 12.3532L8.35234 12.7739C7.88175 13.2445 7.88296 14.009 8.35354 14.479L16.0001 22.1256Z" fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"></path></svg></svg>
                            </div>
                          </div>
                        </span>
                      </div>

                      <div>
                        <span className="group outline-none">
                          <div className="relative flex items-center h-12 block w-full h-12  rounded cursor-default">
                            <div className="flex items-center justify-between w-full opacity-disabled">
                              <svg class="w-7 h-7 watch" width="28px" height="28px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 7V12L14.5 13.5M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                              {/* Return */}
                              <Select
                                // autoFocus
                                backspaceRemovesValue={false}
                                hideSelectedOptions={false}
                                tabSelectsValue={false}
                                className="react-select-container w-full"
                                classNamePrefix="react-select"
                                options={options}
                                isSearchable={false}
                                // value={returntime}
                                value={options.filter(function (option) {
                                  return option.value === returntime;
                                })}
                                placeholder="Return"
                                onChange={handleReturnTime}
                              />
                              <svg className="down-arrow w-7 h-7" width="32" height="32" aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none" id="icon-chevron-down"><path fillRule="evenodd" clipRule="evenodd" d="M16.0001 22.1256L23.6467 14.479C24.1173 14.009 24.1179 13.2445 23.6479 12.7739L23.2266 12.3532C22.7542 11.8809 21.9927 11.8833 21.5215 12.3544L16.0001 17.8759L10.4781 12.3544C10.0075 11.8833 9.24543 11.8809 8.77364 12.3532L8.35234 12.7739C7.88175 13.2445 7.88296 14.009 8.35354 14.479L16.0001 22.1256Z" fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"></path></svg></svg>
                            </div>
                          </div>
                        </span>
                      </div>

                    </div>
                  </div>
                  {loader ? <Loader /> : <div className="p-6 pl-8 xl:overflow-y-scroll mx-auto max-w-3xl bg-off-white">
                    <div className="grid gap-y-4 font-light custom-grid">

                      {ServiceSelected.map((element) => {
                        return (
                          <>
                            <svg className="w-7 h-7 cssvar-secondary-to-primary" width="32" height="32">
                              <svg viewBox="0 0 32 32" fill="none" id="icon-check-circle"><path fillRule="evenodd" clipRule="evenodd" d="M27 16C27 22.0751 22.0751 27 16 27C9.92487 27 5 22.0751 5 16C5 9.92487 9.92487 5 16 5C22.0751 5 27 9.92487 27 16ZM10.7533 16.2181C11.0708 15.7706 11.6483 15.7117 12.0431 16.0865L14.2653 18.1963L19.6446 11.4402C19.9837 11.0144 20.5638 10.9928 20.9402 11.3921C21.3167 11.7914 21.347 12.4603 21.0079 12.8862L15.0481 20.3715C14.7223 20.7806 14.1708 20.8189 13.7915 20.4588L10.8933 17.7072C10.4984 17.3323 10.4358 16.6656 10.7533 16.2181Z" fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"></path></svg>
                            </svg>
                            <div>{element.title} <br /></div>
                            <div className="justify-self-center"> </div>

                            {
                              // element.title == "Interior cleaning" || element.title == "Dog camper" || element.title == "Shuttle Service"
                              // element.title === "Interior cleaning" || element.title === "Innenreinigung" || element.title === "Shuttle Service" || element.title === "Abhol-Service" || element.title === "Dog camper" || element.title === "Hunde Camper"
                              element.type === 0
                                ?
                                <div className="ml-auto justify-self-end font-normal price-font">
                                  <NumericFormat
                                    value={element.price}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    displayType={'text'}

                                  />€</div>
                                :
                                <div className="ml-auto justify-self-end font-normal price-font">
                                  <NumericFormat
                                    value={count * element.price}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    displayType={'text'}

                                  />€</div>

                            }



                          </>
                        )
                      })}

                      {AccessoriesSelected.map((element) => {

                        return (
                          <>
                            <svg className="w-7 h-7 cssvar-secondary-to-primary" width="32" height="32">
                              <svg viewBox="0 0 32 32" fill="none" id="icon-check-circle"><path fillRule="evenodd" clipRule="evenodd" d="M27 16C27 22.0751 22.0751 27 16 27C9.92487 27 5 22.0751 5 16C5 9.92487 9.92487 5 16 5C22.0751 5 27 9.92487 27 16ZM10.7533 16.2181C11.0708 15.7706 11.6483 15.7117 12.0431 16.0865L14.2653 18.1963L19.6446 11.4402C19.9837 11.0144 20.5638 10.9928 20.9402 11.3921C21.3167 11.7914 21.347 12.4603 21.0079 12.8862L15.0481 20.3715C14.7223 20.7806 14.1708 20.8189 13.7915 20.4588L10.8933 17.7072C10.4984 17.3323 10.4358 16.6656 10.7533 16.2181Z" fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"></path></svg>
                            </svg>
                            <div>{element.title}<br /> </div>
                            <div className="justify-self-center"></div>
                            <div className="ml-auto justify-self-end font-normal price-font">
                              {
                                element.type === 0 ?
                                  <NumericFormat
                                    value={element.price}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    displayType={'text'}

                                  />
                                  :
                                  <NumericFormat
                                    value={count * element.price}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    displayType={'text'}

                                  />
                              }

                              €</div>
                          </>
                        )
                      })}

                      {Kilometeradd.map((element) => {

                        return (
                          <>
                            <svg className="w-7 h-7 cssvar-secondary-to-primary" width="32" height="32">
                              <svg viewBox="0 0 32 32" fill="none" id="icon-check-circle"><path fillRule="evenodd" clipRule="evenodd" d="M27 16C27 22.0751 22.0751 27 16 27C9.92487 27 5 22.0751 5 16C5 9.92487 9.92487 5 16 5C22.0751 5 27 9.92487 27 16ZM10.7533 16.2181C11.0708 15.7706 11.6483 15.7117 12.0431 16.0865L14.2653 18.1963L19.6446 11.4402C19.9837 11.0144 20.5638 10.9928 20.9402 11.3921C21.3167 11.7914 21.347 12.4603 21.0079 12.8862L15.0481 20.3715C14.7223 20.7806 14.1708 20.8189 13.7915 20.4588L10.8933 17.7072C10.4984 17.3323 10.4358 16.6656 10.7533 16.2181Z" fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"></path></svg>
                            </svg>
                            <div>{element.title}<br /></div>
                            <div className="justify-self-center"></div>
                            <div className="ml-auto justify-self-end font-normal price-font">
                              <NumericFormat
                                value={count * element.price}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                displayType={'text'}

                              />
                              €</div>
                          </>
                        )
                      })}

                      {
                        serviceFee === "0" ?
                          <></>
                          :
                          <>
                            <svg className="w-7 h-7 cssvar-secondary-to-primary" width="32" height="32">
                              <svg viewBox="0 0 32 32" fill="none" id="icon-check-circle"><path fillRule="evenodd" clipRule="evenodd" d="M27 16C27 22.0751 22.0751 27 16 27C9.92487 27 5 22.0751 5 16C5 9.92487 9.92487 5 16 5C22.0751 5 27 9.92487 27 16ZM10.7533 16.2181C11.0708 15.7706 11.6483 15.7117 12.0431 16.0865L14.2653 18.1963L19.6446 11.4402C19.9837 11.0144 20.5638 10.9928 20.9402 11.3921C21.3167 11.7914 21.347 12.4603 21.0079 12.8862L15.0481 20.3715C14.7223 20.7806 14.1708 20.8189 13.7915 20.4588L10.8933 17.7072C10.4984 17.3323 10.4358 16.6656 10.7533 16.2181Z" fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"></path></svg>
                            </svg>
                            <div>{t("Rent.Service fee")}</div>
                            <div className="justify-self-center"></div>
                            <div className="ml-auto justify-self-end font-normal price-font">{serviceFee} €</div>
                          </>
                      }


                      <svg className="w-7 h-7 cssvar-secondary-to-primary" width="32" height="32">
                        <svg viewBox="0 0 32 32" fill="none" id="icon-check-circle"><path fillRule="evenodd" clipRule="evenodd" d="M27 16C27 22.0751 22.0751 27 16 27C9.92487 27 5 22.0751 5 16C5 9.92487 9.92487 5 16 5C22.0751 5 27 9.92487 27 16ZM10.7533 16.2181C11.0708 15.7706 11.6483 15.7117 12.0431 16.0865L14.2653 18.1963L19.6446 11.4402C19.9837 11.0144 20.5638 10.9928 20.9402 11.3921C21.3167 11.7914 21.347 12.4603 21.0079 12.8862L15.0481 20.3715C14.7223 20.7806 14.1708 20.8189 13.7915 20.4588L10.8933 17.7072C10.4984 17.3323 10.4358 16.6656 10.7533 16.2181Z" fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"></path></svg>
                      </svg>
                      <div>{camper.title}<br />

                      </div>
                      <div className="justify-self-center"></div>
                      <div className="ml-auto">
                        <div className="justify-self-end font-normal price-font">{totalcamperprice ? totalcamperprice : 0} €</div>
                      </div>


                    </div>

                  </div>}


                  <div className="flex flex-col items-center p-6 md:sticky z-10 bottom-0 mx-auto max-w-3xl bg-white xl:relative md:z-auto">
                    <div className="text-center items-center w-full text-2xl font-normal relative mb-2 pb-4">



                      {camper.camper_price && camper.camper_price.map((camper_price) => (
                        <div className="justify-self-end text-4xl font-normal price-font fw-600">

                          <div>
                            {/* <p>{ Math.round(totalcamperprice)}</p> */}
                            {/* {
                           
                                AccessoriesSelected.length != 0 ?
                              
                                AccessoriesSelected.reduce((acc, curr) => acc + curr.price, 0)
                                :""
                            } */}
                            {
                              count ? (
                                selectPackages.length != 0 ?

                                  <NumericFormat
                                    value={selectPackages.reduce((total, item) => total + (item.type === 0 ? item.price : Math.round(count * item.price / parseInt(differenceMonth))), Math.round(totalcamperprice / parseInt(differenceMonth)) + parseInt(Kilometeradd.length != 0 ? Math.round(count * Kilometeradd[0].price / parseInt(differenceMonth)) : 0) + AccessoriesSelected.reduce((acc, curr) => acc + curr.price, 0) + parseInt(serviceFee))}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    displayType={'text'} />
                                  // <NumericFormat
                                  //   value={selectPackages.reduce((total, item) => total + (item.title === "Interior cleaning" || item.title === "Innenreinigung" || item.title === "Shuttle Service" || item.title === "Abhol-Service" || item.title === "Dog camper" || item.title === "Hunde Camper" ? item.price : Math.round(count * item.price / parseInt(differenceMonth))), Math.round(totalcamperprice / parseInt(differenceMonth)) + parseInt(Kilometeradd.length != 0 ? Math.round(count * Kilometeradd[0].price / parseInt(differenceMonth)) : 0) + parseInt(serviceFee))}
                                  //   thousandSeparator={'.'}
                                  //   decimalSeparator={','}
                                  //   displayType={'text'} />
                                  //   element.type === 0
                                  //   ?


                                  :

                                  <NumericFormat
                                    value={Math.round(totalcamperprice / parseInt(differenceMonth) + parseInt(Kilometeradd.length != 0 ? Math.round(count * Kilometeradd[0].price / parseInt(differenceMonth)) : 0)) + AccessoriesSelected.reduce((acc, curr) => acc + curr.price, 0) + parseInt(serviceFee)} thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    displayType={'text'} />
                              ) : (
                                selectPackages.length != 0 ?
                                  <NumericFormat
                                    value={
                                      selectPackages.reduce((total, item) => total + item.price, totalcamperprice + parseInt(Kilometeradd[0].price ? Kilometeradd[0].price : 0) + parseInt(serviceFee))}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    displayType={'text'} />
                                  : <NumericFormat
                                    value={totalcamperprice + parseInt(serviceFee)} thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    displayType={'text'} />
                              )
                            }

                          </div>
                        </div>
                      ))}
                      {/* <div className="text-md font-normal">{t("Rent.Total incl. Vat")}</div> */}
                      <div className="text-md font-normal">{t("Rent.Per Month")}</div>



                    </div>
                    {error ? <p style={{ color: "red" }} className="mb-2 flex w-full animate-wiggle items-center justify-between rounded bg-warning px-4 py-2 text-lg text-white">{error}</p> : ''}
                    {ExcludedateList.length === 365 ? (
                      <>
                        <p
                          style={{ color: "red" }}
                          className="mb-2 flex w-full animate-wiggle items-center justify-between rounded bg-warning px-4 py-2 text-lg text-white"
                        >
                          {t("rentinfo.Inventory is not available")}
                        </p>
                        <Link
                          to="#"
                          className="group/button block disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark p-3 pl-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated w-full"
                        >
                          <div className="flex h-full items-center justify-between">
                            <span className="flex">
                              {t("rentinfo.Configure now")}
                            </span>
                            <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                              <svg
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                                    fill="var(--svg-color-secondary, #fff)"
                                  ></path>
                                </g>
                              </svg>
                            </div>
                          </div>
                        </Link>{" "}

                      </>
                    ) : (
                      <Button onClick={(e) => handleCheckout(camper.id)} className="grid-cols1 inline-grid grid-rows-1 text-white bg-secondary hover:bg-primary pl-5 pr-2 w-full h-12 rounded"> <span className="relative flex col-start-1 row-start-1 items-center justify-between w-full h-full transition-opacity duration-300">
                        <span className="transform transition-transform duration-300 translate-x-0">
                          <span className="text-lg text-custom-lg-sm font-body font-semibold text-custom-lg-sm"> {t("Voucher.Continue to checkout")}</span>
                        </span>
                        <svg className="w-7 h-7 cssvar-secondary-to-white pointer-events-none" width="32" height="32"><svg viewBox="0 0 32 32" fill="none" id="icon-chevron-right"><path fillRule="evenodd" clipRule="evenodd" d="M22.1256 15.9999L14.479 8.35332C14.009 7.88273 13.2445 7.88213 12.7739 8.35211L12.3532 8.77342C11.8809 9.24581 11.8833 10.0073 12.3544 10.4785L17.8759 15.9999L12.3544 21.5219C11.8833 21.9925 11.8809 22.7546 12.3532 23.2264L12.7739 23.6477C13.2445 24.1182 14.009 24.117 14.479 23.6465L22.1256 15.9999Z" fill="var(--svg-custom-color, var(--svg-color-secondary, #163435))"></path></svg></svg>
                      </span></Button>
                    )}



                  </div>

                </div>
              </div>


            </div>
          </form>
        </main>
      </div>
    </>
  );
};
export default SubscriptionDetail;

