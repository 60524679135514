import React, { useState, useEffect } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import Campersrent from "../../Components/Campersrent/Campersrent";
import { Link } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import "@splidejs/react-splide/css/core";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  useLocation,
  useNavigate,
  generatePath,
  createSearchParams,
  useSearchParams,
} from "react-router-dom";
import HomeService from "../../Api/services/HomeService";
import TopEquipped from "../../Components/Topequipped/Top-equipped-about";
import { useTranslation } from "react-i18next";
const Aboutus = () => {
  const [Error, setError] = useState("");
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const [isFormSubmit, setisFormSubmit] = useState(false);
  const onSubmitForm = async (event) => {
    event.preventDefault();
    setError("");
    try {
      var userData = {
        first_name: event.target.first_name.value,
        email: event.target.email.value,
        type: "Team",
        "lng": i18n.resolvedLanguage
      };
      if (event.target.first_name.value === "") {
        setError(t("Login.The first name field is required."))
      }
      else if (event.target.email.value === "") {
        setError(t("Login.The email field is required."))
      }

      else {
        let responseData = await HomeService.store(userData).json();
        if (responseData.status === true) {
          // alert("Newsletters Submitted Successfully!!");
          event.target.reset();
          setisFormSubmit(true);
          // navigate('/');
        }
      }
    } catch (error) {
      console.log(error);
      if (error.name === "HTTPError") {
        const errorJson = await error.response.json();
        setError(
          errorJson.message.substr(0, errorJson.message.lastIndexOf("."))
        );
      }
    }
  };
  var camperslider = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1273,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  var fiverow = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1273,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  const CustomArrow = ({ onClick, direction }) => (
    <div className="custom-btn-group">
      <button className={`custom-arrow ${direction}`} onClick={onClick}>
        {direction === "prev" ? "<" : ">"}
      </button>
    </div>
  );
  var teamslider = {
    dots: false,
    prevArrow: <CustomArrow direction="prev" />,
    nextArrow: <CustomArrow direction="next" />,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    infinite: false,
    responsive: [
      {
        breakpoint: 1380,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          prevArrow: <CustomArrow direction="prev" />,
          nextArrow: <CustomArrow direction="next" />,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          prevArrow: <CustomArrow direction="prev" />,
          nextArrow: <CustomArrow direction="next" />,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
        },
      },
    ],
  };
  return (
    <>
      <Header />

      <div className="min-h-screen bg-off-white">
        <div className="pb-5 text-center">
          <div className="overflow-hidden">
            <div className="-ml-[5%] w-[110%] overflow-hidden rounded-b-[60%20%] px-[5%]">
              <div className="relative">
                <div className="overflow-hidden">
                  <img
                    src="title-images/About-us-Page.jpg"
                    alt="hero-banner"
                    className="absolute max-h-full w-full scale-110 object-cover blur-lg dark:brightness-90 sm:aspect-hero-regular 3xl:aspect-hero-long aspect-square"
                  />
                </div>
                <picture className="flex max-h-[60vh] w-full text-0">
                  <img
                    className="z-0 max-h-full w-full object-cover dark:brightness-90 sm:aspect-hero-regular 3xl:aspect-hero-long aspect-square"
                    src="title-images/About-us-Page.jpg"
                    alt="hero-banner"
                    width="2552"
                    height="1286"
                  />
                </picture>
                <div className="absolute top-0 left-0 flex h-full w-full flex-col items-center justify-end">
                  <div className="pb-12 2xl:pb-14 w-full bg-gradient-to-t from-black-pure/80 to-transparent pt-20 md:pt-14 xl:pt-32">
                    <h1 className="whitespace-pre-line break-words m-auto max-w-4xl px-8 text-xl md:text-2xl text-white drop-shadow-text dark:text-off-white xs:text-2xl xl:text-3xl mb-5 md:mb-8 xl:mb-12">
                      {t("aboutus.The Team & Our Mission")}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark relative mx-auto -mt-12 child:h-24 child:w-24 2xl:-mt-14 2xl:child:h-28 2xl:child:w-28">
            <img
              src="images/aboutus-header-banner.svg"
              alt="aboutus-header-banner"
            />
          </div>
        </div>

        <div className="xl:pt-8 pt-0 pb-8 mx-5">
          <h2 className="whitespace-pre-line break-words text-center text-xl md:text-2xl team-heading">
            {t("aboutus.The Team")}
          </h2>
          <div className="md:mx-5 xl-padded:mx-auto pt-5 pb-0 mx-w-1800">
            <Slider className="camper-slider teamslider" {...teamslider}>
              <div className="team-wrapper rounded p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-8 md:mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-3 2xl:mt-14 2xl:h-[calc(100%-3.5rem)] pt-0 pb-7">
                <div className="mt-4 flex justify-center align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark mb-5 team-img">
                  <img src="images/Wolfi.PNG" alt="team-member" />
                </div>
                <h3 className="mb-3 whitespace-pre-line text-center text-lg text-white">
                  Wolfi
                </h3>
                <p className="whitespace-pre-line text-center text-white">
                  {t("aboutus.Our top-notch handyman in the stable.")}&nbsp;{t("aboutus.A talented craftsman responsible for maintenance and repairs of all kinds.")}&nbsp;
                  {t("aboutus.Occasionally,")} {t("aboutus.Wolfi also handles handovers and impresses with abundant camping experience.")}
                </p>
              </div>
              <div className="team-wrapper rounded p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-3 2xl:mt-14 2xl:h-[calc(100%-3.5rem)] pt-0 pb-7">
                <div className="mt-4 flex justify-center align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark mb-5 team-img">
                  <img src="images/Luca.PNG" alt="team-member" />
                </div>
                <h3 className="mb-3 whitespace-pre-line text-center text-lg text-white">
                  Luca
                </h3>
                <p className="whitespace-pre-line text-center text-white">
                  {t("aboutus.Self-proclaimed boss and organizer of Born To Camp.")}&nbsp;{t("aboutus.If you have questions of any kind or need to discuss organizational details,")}&nbsp;{t("aboutus.he is the right person for it.")}&nbsp;{t("aboutus.Don't hesitate to approach him directly")} {t("aboutus.because personal contact with our customers means a lot to him!")}
                </p>
              </div>
              <div className="team-wrapper rounded p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-3 2xl:mt-14 2xl:h-[calc(100%-3.5rem)] pt-0 pb-7">
                <div className="mt-4 flex justify-center align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark mb-5 team-img">
                  <img src="images/Felxi.PNG" alt="team-member" />
                </div>
                <h3 className="mb-3 whitespace-pre-line text-center text-lg text-white">
                  Felix
                </h3>
                <p className="whitespace-pre-line text-center text-white">
                  {t("aboutus.The IT guy at Born To Camp and the author of these profiles.")}&nbsp;
                  {t("aboutus.Felix's creative mind is behind our website too.")}&nbsp;{t("aboutus.Feel free to bring your feedback,")} {t("aboutus.criticism,")} {t("aboutus.and great ideas in person at any time.")}
                </p>
              </div>
              <div className="team-wrapper rounded p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-3 2xl:mt-14 2xl:h-[calc(100%-3.5rem)] pt-0 pb-7">
                <div className="mt-4 flex justify-center align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark mb-5 team-img">
                  <img src="images/Martin.png" alt="team-member" />
                </div>
                <h3 className="mb-3 whitespace-pre-line text-center text-lg text-white">
                  Martin
                </h3>
                <p className="whitespace-pre-line text-center text-white">
                  {t("aboutus.From technical expertise to thorough cleaning and smooth handover and return of our campers – Martin is a true all-rounder.")}
                </p>
              </div>
              <div className="team-wrapper rounded p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-3 2xl:mt-14 2xl:h-[calc(100%-3.5rem)] pt-0 pb-7">
                <div className="mt-4 flex justify-center align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark mb-5 team-img">
                  <img src="images/Rolf.PNG" alt="team-member" />
                </div>
                <h3 className="mb-3 whitespace-pre-line text-center text-lg text-white">
                  Rolf
                </h3>
                <p className="whitespace-pre-line text-center text-white">
                  {t("aboutus.The man for everything.")} {t("aboutus.Whether it's handovers, cleaning, or advice, Rolf masters it all.")} {t("aboutus.Rolf would be delighted to share all the details about the camper and offer you travel tips!")}
                </p>
              </div>
            </Slider>
          </div>
        </div>

        <div className="mx-5 max-w-screen-xl xl-padded:mx-auto">
          <div className="pt-8 pb-0 mx-auto flex max-w-3xl flex-col items-center text-center">
            <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark mb-4 child:h-20 child:w-20">
              <img src="images/target-icon.svg" alt="target-icon" />
            </div>
            <p className="mb-4 whitespace-pre-line text-md">{t("aboutus.This is us")}</p>
            <h2 className="whitespace-pre-line break-words text-xl md:text-2xl mb-4">
              {t("aboutus.Our Values")}
            </h2>
            <div className="our-value-text">
              <p className="whitespace-pre-line text-2md font-normal md:text-lg-paragraph md:font-normal">
                {t("aboutus.We are dedicated to making your journey unforgettable.")}
              </p>
              <p className="whitespace-pre-line text-2md font-normal md:text-lg-paragraph md:font-normal">
                {t("aboutus.From booking to your return, we're by your side.")}
              </p>
              <p className="whitespace-pre-line text-2md font-normal md:text-lg-paragraph md:font-normal">
                {t("aboutus.Enjoy hassle-free, transparent, and secure travels.")}
              </p>
            </div>
            <div className="our-value-text-mobile">
              <p className="whitespace-pre-line text-2md font-normal md:text-lg-paragraph md:font-normal">
                {t("aboutus.We are dedicated to making your journey unforgettable.")}<br />{t("aboutus.From booking to your return, we're by your side.")} <br />{t("aboutus.Enjoy hassle-free, transparent, and secure travels.")}
              </p>

            </div>
          </div>

          <div className="pt-8 pb-8">
            <div className="md:mx-5 max-w-screen-xl xl-padded:mx-auto md:pt-5 pt-0 pb-0">
              <Slider className="camper-slider" {...fiverow}>
                <div className="rounded bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-6 2xl:mt-14 2xl:h-[calc(100%-3.5rem)] pt-0 pb-7">
                  <div className="flex justify-center align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-12 mb-5 child:h-24 child:w-24 2xl:-mt-14 2xl:child:h-28 2xl:child:w-28">
                    <img
                      src="images/aboutus-slider-img.svg"
                      alt="services-icon"
                    />
                  </div>
                  <h3 className="mb-3 whitespace-pre-line text-center text-lg">
                    {t("aboutus.Personal")}
                  </h3>
                  <p className="whitespace-pre-line text-center">
                    {t("aboutus.Give us a call, come visit us, and see our models in person – we're here to assist you in finding the perfect motorhome with expert advice.")}
                  </p>
                </div>

                <div className="rounded bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-6 2xl:mt-14 2xl:h-[calc(100%-3.5rem)] pt-0 pb-7">
                  <div className="flex justify-center align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-12 mb-5 child:h-24 child:w-24 2xl:-mt-14 2xl:child:h-28 2xl:child:w-28">
                    <img
                      className="rounded-full border-4 img-rounded border-primary bg-white p-5"
                      src="images/safety-supervision-svgrepo-com.svg"
                      alt="services-icon"
                    />
                  </div>
                  <h3 className="mb-3 whitespace-pre-line text-center text-lg">
                    {t("aboutus.Safety")}
                  </h3>
                  <p className="whitespace-pre-line text-center">
                    {t("aboutus.Our vehicles are well-maintained.")} {t("aboutus.You can reduce the deductible in the comprehensive insurance to €300 and benefit from our breakdown assistance.")}
                  </p>
                </div>

                <div className="rounded bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-6 2xl:mt-14 2xl:h-[calc(100%-3.5rem)] pt-0 pb-7">
                  <div className="flex justify-center align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-12 mb-5 child:h-24 child:w-24 2xl:-mt-14 2xl:child:h-28 2xl:child:w-28">
                    <img src="images/Exciting.svg" alt="services-icon" />
                  </div>
                  <h3 className="mb-3 whitespace-pre-line text-center text-lg">
                    {t("aboutus.Flexibility")}
                  </h3>
                  <p className="whitespace-pre-line text-center">
                    {t("aboutus.You set the pick-up and return times according to your own schedule, hasslefree and stress-free.")}
                  </p>
                </div>

                <div className="rounded bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-6 2xl:mt-14 2xl:h-[calc(100%-3.5rem)] pt-0 pb-7">
                  <div className="flex justify-center align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-12 mb-5 child:h-24 child:w-24 2xl:-mt-14 2xl:child:h-28 2xl:child:w-28">
                    <img
                      className=""
                      src="images/Sustainable-icon.svg"
                      alt="services-icon"
                    />
                  </div>
                  <h3 className="mb-3 whitespace-pre-line text-center text-lg">
                    {t("aboutus.Transparency")}
                  </h3>
                  <p className="whitespace-pre-line text-center">
                    {t("aboutus.Clear Prices and Conditions.")} {t("aboutus.Choose your model, date, and accessories, instantly see the total price, and pay conveniently online.")}
                  </p>
                </div>

                <div className="rounded bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mt-12 flex h-[calc(100%-3rem)] flex-col items-center overflow-visible px-6 2xl:mt-14 2xl:h-[calc(100%-3.5rem)] pt-0 pb-7">
                  <div className="flex justify-center align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-12 mb-5 child:h-24 child:w-24 2xl:-mt-14 2xl:child:h-28 2xl:child:w-28">
                    <img src="images/Quality.svg" alt="services-icon" />
                  </div>
                  <h3 className="mb-3 whitespace-pre-line text-center text-lg">
                    {t("aboutus.Quality Matters")}
                  </h3>
                  <p className="whitespace-pre-line text-center">
                    {t("aboutus.We care about your holiday experience and pay attention to the details that make a difference.")} {t("aboutus.Our motto is")}: {t("aboutus.Better once correctly than five times halfway.")}
                  </p>
                </div>
              </Slider>
            </div>
          </div>

          <div className="pb-0 mx-auto flex max-w-3xl flex-col items-center text-center md:pt-0 pt-5 our-mission-content">
            <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark mb-4 child:h-20 child:w-20">
              <img
                src="images/aboutus-header-banner.svg"
                alt="aboutus-header-banner"
              />
            </div>
            <p className="mb-4 whitespace-pre-line text-md">
              {t("aboutus.What we strive for")}
            </p>
            <h2 className="whitespace-pre-line break-words text-xl md:text-2xl">
              {t("aboutus.Our Mission")}
            </h2>
          </div>

          <TopEquipped />

          {/* <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12">
              <div className="">
                <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12">
                  <picture className="text-0 flex">
                    <img className="inline-block object-cover dark:brightness-90 rounded 2xs:rounded-lg w-full max-w-3xl mx-auto" src="images/aboutus-detail-img.png" alt="aboutus-detail-img" />
                  </picture>
                </div>
              </div>
              <div className="">
                <div className="pt-8 2xl:pt-12 pb-8 2xl:pb-12 text-left mx-auto max-w-3xl">
                  <h3 className="my-5 first:mt-0 break-words text-lg md:text-xl">We create memories.</h3>
                  <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">Traveling by camper is for us the definition of adventure, freedom and nature. We want to help you to experience these very special moments, to feel that spark of happiness and to create unforgettable memories.</div>
                </div>
              </div>
            </div> */}

          {/* <div className="pt-5 pb-8 2xl:pb-12 mb-1">
            <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mask-image relative p-0 md:flex md:min-h-[400px] md:rounded-xl md:bg-transparent dark:md:bg-transparent justify-end">
              <Link className="w-full md:flex">
                <div className="md:hidden">
                  <div className="overflow-hidden">
                    <div className="-ml-[5%] w-[110%] overflow-hidden rounded-b-[60%20%] px-[5%]">
                      <div className="relative">
                        <picture className="text-0">
                          <img
                            className="inline-block max-w-full object-cover dark:brightness-90 max-h-[40vh] w-full group-hover/parent:scale-110 transition-all duration-300"
                            src="images/microsoftteams_image.png"
                            alt="microsoftteams_image"
                          />
                        </picture>
                      </div>
                    </div>
                  </div>
                </div>
                <picture className="text-0 absolute top-0 left-0 z-background hidden h-full w-full transition-all duration-300 group-hover/parent:scale-105 md:block md:group-hover/parent:scale-[103%]">
                  <img
                    className="inline-block max-w-full object-cover dark:brightness-90 h-full w-full"
                    src="images/microsoftteams_image.png"
                    alt="microsoftteams_image"
                  />
                </picture>
                <div className="relative -mt-10 mb-4 flex justify-center xs:mb-4 md:hidden">
                  <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark child:h-20 child:w-20">
                    <img
                      src="images/sustainability-icon.svg"
                      alt="sustainability-icon"
                    />
                  </div>
                </div>
                <div className="z-0 m-6 mt-0 flex flex-col text-center xs:mt-0 md:m-5 md:mt-5 md:min-h-[calc(100%-theme(spacing.5)*2)] md:w-full md:max-w-1/2 md:rounded-lg md:bg-white md:p-8 md:text-left md:shadow md:dark:bg-off-black-desat xl:max-w-[45%] 2xl:m-9 2xl:mt-9 2xl:min-h-[calc(100%-theme(spacing.9)*2)]">
                  <div className="mb-6 flex items-center justify-center md:justify-start">
                    <div className="relative mr-4 hidden md:block">
                      <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark child:h-16 child:w-16">
                        <img
                          src="images/sustainability-icon.svg"
                          alt="sustainability-icon"
                        />
                      </div>
                    </div>
                    <p className="text-lg">Sustainability</p>
                  </div>
                  <h2 className="whitespace-pre-line break-words mb-4 text-xl xs:mb-6">
                    Responsibility is our nature.
                  </h2>
                  <div className="mb-4 md:mb-6">
                    <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                      Nature is our friend - And that's how we treat it. Every
                      traveled kilometer has been compensated for since the
                      beginning.
                    </div>
                    <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal"></div>
                    <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal">
                      Together with our partner natureOffice, we are also
                      growing a CamperBoys company forest in Togo West Africa.
                    </div>
                  </div>
                  <div className="group/button block disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark p-3 pl-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated w-full md:mt-auto">
                    <div className="flex h-full items-center justify-between">
                      <span className="flex">Our sustainability strategy</span>
                      <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                        <svg width="32" height="32" viewBox="0 0 32 32">
                          <g fill="none" fillRule="evenodd">
                            <path
                              d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                              fill="var(--svg-color-secondary, #fff)"
                            ></path>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            </div> */}

          {/* <div className="pb-8 2xl:pb-12 mb-1">
              <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg group/parent mask-image relative p-0 md:flex md:min-h-[400px] md:rounded-xl md:bg-transparent dark:md:bg-transparent justify-end">
                <div to={"/contactus"} className="w-full md:flex justify-end">
                  <div className="md:hidden">
                    <div className="overflow-hidden">
                      <div className="-ml-[5%] w-[110%] overflow-hidden rounded-b-[60%20%] px-[5%]">
                        <div className="relative">
                          <picture className="text-0">
                            <img
                              className="inline-block max-w-full object-cover dark:brightness-90 max-h-[40vh] w-full group-hover/parent:scale-110 transition-all duration-300"
                              src="images/Helpccenter.png"
                              alt="portugal_t6_beach_sommers"
                            />
                          </picture>
                        </div>
                      </div>
                    </div>
                  </div>
                  <picture className=" text-0 absolute top-0 left-0 z-background hidden h-full w-full transition-all duration-300 group-hover/parent:scale-105 md:block md:group-hover/parent:scale-[103%]">
                    <img
                      className="inline-block max-w-full object-cover dark:brightness-90 h-full w-full"
                      src="images/Helpccenter-ext.jpg"
                      alt="portugal_t6_beach_sommers"
                    />
                  </picture>
                  <div className="relative -mt-10 mb-4 flex justify-center xs:mb-4 md:hidden">
                    <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark child:h-20 child:w-20">
                      <img src="images/helpcenter.svg" alt="selection-icon" />
                    </div>
                  </div>
                  <div className="justify-center items-center z-0 m-6 mt-0 flex flex-col text-center xs:mt-0 md:m-5 md:mt-5 md:min-h-[calc(100%-theme(spacing.5)*2)] md:w-full md:max-w-1/2 md:rounded-lg md:bg-white md:p-8 md:text-left md:shadow md:dark:bg-off-black-desat xl:max-w-[45%] 2xl:m-9 2xl:mt-9 2xl:min-h-[calc(100%-theme(spacing.9)*2)]">
                  <div className="inline-flex items-center justify-center rounded-full border bg-white p-1 dark:bg-off-white mb-6 orange-border">
                          <div className="inline-block align-top child:h-8 child:w-8 2xl:child:h-10 2xl:child:w-10">
                          <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
                          <title>icon/actions_info</title>
                          <g id="icon/actions_info" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                          <path d="M15.0627781,13.2833484 C14.2292216,13.2833484 13.4095577,13.2833484 12.5843367,13.2833484 C12.4040026,13.2849033 12.2246588,13.3085077 12.0508606,13.3535621 C11.8387141,13.3950229 11.676741,13.5559636 11.6451964,13.7566407 C11.5865132,13.9746677 11.7037133,14.2006319 11.9230486,14.2923451 C12.190053,14.4092135 12.476874,14.4813856 12.7704977,14.5055866 C13.5429267,14.5393932 13.8541211,14.8566551 13.7374232,15.6004 C13.5206985,16.9916712 13.2289538,18.3725403 12.9788868,19.7612109 C12.809397,20.6973934 12.6848928,21.2550492 12.5843367,22.2403035 C12.4530963,23.5262052 13.2109841,24.7401952 14.1368811,25.0131061 C15.0627781,25.286017 15.724825,25.286017 16.3214484,25.2794864 C17.5999796,25.2780159 18.8212793,24.7831633 19.6973522,23.9116198 C19.8564682,23.7743898 19.9759403,23.6017433 20.0446674,23.409722 C20.0782267,23.2419815 20.0359721,23.0687264 19.9279695,22.9312287 C19.7974575,22.8547704 19.6367149,22.8384803 19.4917416,22.8870201 C19.2639028,22.9572338 19.0610707,23.1028622 18.838789,23.1938799 C18.5790019,23.3073326 18.3070186,23.3945099 18.0274607,23.4539306 C17.8020089,23.5057029 17.5636393,23.4622801 17.3753928,23.335146 C17.1871463,23.2080119 17.0679843,23.0099721 17.0494211,22.7934019 C17.0026623,22.551429 16.9942363,22.3043299 17.0244144,22.0600589 C17.1188841,21.4567414 17.2522531,20.8586248 17.3689511,20.2605082 C17.6857025,18.6291236 18.0070849,16.9968722 18.3330981,15.3637539 C18.4220108,14.9164667 18.5720509,14.4769811 18.4720242,14.0062893 C18.4564927,13.79721 18.3517318,13.6027725 18.1813424,13.4667803 C18.010953,13.3307881 17.7892668,13.2646798 17.5662261,13.2833484 C16.7326696,13.2833484 15.8963346,13.2833484 15.0627781,13.2833484 Z" id="Path" fill="var(--svg-color-secondary, #163435)"></path>
                          <path d="M16.5742939,6.28268183 C15.5498224,6.23775316 14.6000794,6.81738803 14.1716262,7.74904688 C13.743173,8.68070573 13.9212618,9.77901022 14.6221567,10.5275466 C15.3230516,11.276083 16.4072909,11.52591 17.3650741,11.159561 C18.3228574,10.793212 18.9636082,9.88358197 18.9860507,8.85837141 C19.0250803,7.48444098 17.9479291,6.33655519 16.5742939,6.28823887 L16.5742939,6.28268183 Z" id="Path" fill="var(--svg-color-secondary, #163435)"></path>
                          </g>
                          </svg>                  
                          </div>
                    </div>
                    <p className="mb-4 whitespace-pre-line text-center text-lg md:text-xl">Questions? We have answers!</p>
                    <Link to={"/contactus"} className="w-full group/button block border border-transparent rounded text-md text-white dark:text-off-white button-primary-bg dark:bg-primary-dark p-3 pl-5 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated col-span-2 disabled:opacity-100 sm:col-span-1 text-lg text-center">
                      <div className="flex h-full items-center justify-between">
                          <div className="inline-block align-top mr-2 rounded-full bg-white p-1 child:h-7 child:w-7 dark:bg-off-white">
                          <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
                          <title>icon/actions_question</title>
                          <g id="icon/actions_question" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                          <path d="M16.5609275,17.3580413 L16.3692127,19.2467266 C15.73983,19.3562778 14.562691,19.3562778 13.8779956,19.3015022 C13.6320422,19.027624 13.6862809,15.1407023 13.6862809,15.1407023 C16.2596615,15.1407023 18.2305102,14.2369044 18.2305102,12.1838923 C18.2305102,11.1442293 17.4915762,9.66582429 15.3837884,9.66582429 C13.4951032,9.66582429 12.1536372,10.6512486 12.1536372,10.6512486 C12.1536372,10.6512486 10.9222596,8.70778776 11.003886,8.05048019 C11.6338057,7.33947102 13.3033885,6.6 15.8767691,6.6 C19.0242198,6.6 21.6523761,8.43390961 21.6523761,11.8831633 C21.6523761,15.1680901 19.2707101,17.0019997 16.5609275,17.3580413 M16.5732788,25.0115932 C15.8048089,25.7891924 14.5471175,25.7972476 13.7695183,25.0287777 C12.9913822,24.2608449 12.9833269,23.0031534 13.7517968,22.2250173 C14.5197297,21.4468811 15.7779581,21.4393629 16.5555573,22.2072957 C17.3336935,22.9757656 17.3412117,24.2334571 16.5732788,25.0115932" id="Fill-1" fill="var(--svg-color-secondary, #163435)"></path>
                          </g>
                          </svg>
                            </div>  
                            <span className="flex">
                            To the helpcenter
                            </span>
                            <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                            <svg width="32" height="32" viewBox="0 0 32 32">
                              <g fill="none" fillRule="evenodd">
                                  <path d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z" fill="var(--svg-color-secondary, #163435)"></path>
                              </g>
                            </svg>
                              </div>                
                      </div>
                    </Link>
                    
                    
                  </div>
                </div>
              </div>
            </div> */}

          <div className="pb-3 md:pb-12 2xl:pb-12 mb-1 md:pt-0 pt-2">
            <div className="overflow-hidden rounded bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg mx-auto flex max-w-3xl flex-col items-center md:p-8 2xl:p-16">
              <div className="inline-flex items-center justify-center rounded-full primary-borber border bg-white p-1 dark:bg-off-white mb-6 border-info dark:border-info">
                <div className="inline-block align-top child:h-8 child:w-8 2xl:child:h-10 2xl:child:w-10">
                  <svg
                    width="32px"
                    height="32px"
                    viewBox="0 0 32 32"
                    version="1.1"
                  >
                    <title>icon/actions_info</title>
                    <g
                      id="icon/actions_info"
                      stroke="none"
                      strokeWidth="1"
                      fill="EE7214"
                      fillRule="evenodd"
                    >
                      <path
                        d="M15.0627781,13.2833484 C14.2292216,13.2833484 13.4095577,13.2833484 12.5843367,13.2833484 C12.4040026,13.2849033 12.2246588,13.3085077 12.0508606,13.3535621 C11.8387141,13.3950229 11.676741,13.5559636 11.6451964,13.7566407 C11.5865132,13.9746677 11.7037133,14.2006319 11.9230486,14.2923451 C12.190053,14.4092135 12.476874,14.4813856 12.7704977,14.5055866 C13.5429267,14.5393932 13.8541211,14.8566551 13.7374232,15.6004 C13.5206985,16.9916712 13.2289538,18.3725403 12.9788868,19.7612109 C12.809397,20.6973934 12.6848928,21.2550492 12.5843367,22.2403035 C12.4530963,23.5262052 13.2109841,24.7401952 14.1368811,25.0131061 C15.0627781,25.286017 15.724825,25.286017 16.3214484,25.2794864 C17.5999796,25.2780159 18.8212793,24.7831633 19.6973522,23.9116198 C19.8564682,23.7743898 19.9759403,23.6017433 20.0446674,23.409722 C20.0782267,23.2419815 20.0359721,23.0687264 19.9279695,22.9312287 C19.7974575,22.8547704 19.6367149,22.8384803 19.4917416,22.8870201 C19.2639028,22.9572338 19.0610707,23.1028622 18.838789,23.1938799 C18.5790019,23.3073326 18.3070186,23.3945099 18.0274607,23.4539306 C17.8020089,23.5057029 17.5636393,23.4622801 17.3753928,23.335146 C17.1871463,23.2080119 17.0679843,23.0099721 17.0494211,22.7934019 C17.0026623,22.551429 16.9942363,22.3043299 17.0244144,22.0600589 C17.1188841,21.4567414 17.2522531,20.8586248 17.3689511,20.2605082 C17.6857025,18.6291236 18.0070849,16.9968722 18.3330981,15.3637539 C18.4220108,14.9164667 18.5720509,14.4769811 18.4720242,14.0062893 C18.4564927,13.79721 18.3517318,13.6027725 18.1813424,13.4667803 C18.010953,13.3307881 17.7892668,13.2646798 17.5662261,13.2833484 C16.7326696,13.2833484 15.8963346,13.2833484 15.0627781,13.2833484 Z"
                        id="Path"
                        fill="var(--svg-color-secondary, #EE7214)"
                      ></path>
                      <path
                        d="M16.5742939,6.28268183 C15.5498224,6.23775316 14.6000794,6.81738803 14.1716262,7.74904688 C13.743173,8.68070573 13.9212618,9.77901022 14.6221567,10.5275466 C15.3230516,11.276083 16.4072909,11.52591 17.3650741,11.159561 C18.3228574,10.793212 18.9636082,9.88358197 18.9860507,8.85837141 C19.0250803,7.48444098 17.9479291,6.33655519 16.5742939,6.28823887 L16.5742939,6.28268183 Z"
                        id="Path"
                        fill="var(--svg-color-secondary, #EE7214)"
                      ></path>
                    </g>
                  </svg>
                </div>
              </div>
              <p className="whitespace-pre-line text-center text-lg md:text-xl">
                {t("aboutus.Questions? We have answers!")}
              </p>
              <Link
                to={"/contactus"}
                className="w-100 group/button disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white bg-primary dark:bg-primary-dark p-3 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated pl-3 py-2 inline-block mt-4"
                href="https://cms.camperboys.com/pdf-files/equipment_vw_t6_1_california_ocean_editionen-1.pdf"
              >
                <div className="flex h-full items-center justify-between">
                  <div className="inline-block align-top mr-2 rounded-full bg-white p-1 child:h-7 child:w-7 dark:bg-off-white">
                    <svg
                      width="32px"
                      height="32px"
                      viewBox="0 0 32 32"
                      version="1.1"
                    >
                      <title>icon/actions_question</title>
                      <g
                        id="icon/actions_question"
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <path
                          d="M16.5609275,17.3580413 L16.3692127,19.2467266 C15.73983,19.3562778 14.562691,19.3562778 13.8779956,19.3015022 C13.6320422,19.027624 13.6862809,15.1407023 13.6862809,15.1407023 C16.2596615,15.1407023 18.2305102,14.2369044 18.2305102,12.1838923 C18.2305102,11.1442293 17.4915762,9.66582429 15.3837884,9.66582429 C13.4951032,9.66582429 12.1536372,10.6512486 12.1536372,10.6512486 C12.1536372,10.6512486 10.9222596,8.70778776 11.003886,8.05048019 C11.6338057,7.33947102 13.3033885,6.6 15.8767691,6.6 C19.0242198,6.6 21.6523761,8.43390961 21.6523761,11.8831633 C21.6523761,15.1680901 19.2707101,17.0019997 16.5609275,17.3580413 M16.5732788,25.0115932 C15.8048089,25.7891924 14.5471175,25.7972476 13.7695183,25.0287777 C12.9913822,24.2608449 12.9833269,23.0031534 13.7517968,22.2250173 C14.5197297,21.4468811 15.7779581,21.4393629 16.5555573,22.2072957 C17.3336935,22.9757656 17.3412117,24.2334571 16.5732788,25.0115932"
                          id="Fill-1"
                          fill="var(--svg-color-secondary, #163435)"
                        ></path>
                      </g>
                    </svg>
                  </div>
                  <span className="flex">{t("aboutus.To the helpcenter")}</span>
                  <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                    <svg width="32" height="32" viewBox="0 0 32 32">
                      <g fill="none" fillRule="evenodd">
                        <path
                          d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                          fill="var(--svg-color-secondary, #163435)"
                        ></path>
                      </g>
                    </svg>
                  </div>
                </div>
              </Link>
            </div>
          </div>

          <div className="md:mx-5 max-w-screen-xl xl-padded:mx-auto pt-8 pb-8">
            <div className=" pb-8 mb-1">
              <div className="newsletter-wrapper rounded bg-white p-6 shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg flex flex-col items-center overflow-visible from-secondary/90 to-secondary/50 text-center text-white dark:to-primary-dark 2xl:p-4 mt-10 pt-0 2xl:mt-12 2xl:pt-0">
                <div className="inline-block align-top dark:cssvar-white-to-off-white dark:cssvar-secondary-to-secondary dark:cssvar-primary-to-primary-dark -mt-10 mb-4 child:h-20 child:w-20 md:child:h-24 md:child:w-24 2xl:-mt-12">
                  <img src="images/mail-icon.svg" alt="mail-icon" />
                </div>
                <h2 className="whitespace-pre-line break-words mb-4 max-w-2xl text-xl md:text-2xl text-orange">
                  {t("home.Sign Up for Our Newsletter and Save Up to €50")}
                </h2>
                <p className="mb-0 max-w-lg">
                  {t("home.Receive free travel recommendations, exclusive offers, and travel suggestions directly in  your inbox. Secure a €50 voucher for your first booking")}<br />
                  {t("aboutus.Act quickly")}: {t("home.Please note that the discount  from the newsletter cannot be combined with other marketing discounts and is valid for  first-time customers only")}.
                  <br />
                  <p className="mb-8 max-w-lg">{t("aboutus.Your next adventure awaits! Sign up now!")}<br />{t("aboutus.Sign up now!")}</p>
                </p>
                {isFormSubmit ? (
                  <>
                    <div className="opacity-100 scale-100 success-msg">
                      <div className="inline-flex items-center justify-center rounded-full orange-border bg-white p-1 dark:bg-off-white">
                        <div className="inline-block align-top">
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g fill="none" fill-rule="evenodd">
                              <path
                                d="m21.906 9.488-8.547 9.56-3.45-3.096a1.757 1.757 0 0 0-2.563.209c-.64.792-.547 1.98.21 2.659l4.764 4.274c.735.66 1.833.602 2.496-.13l9.73-10.869a1.954 1.954 0 0 0-.069-2.665 1.758 1.758 0 0 0-2.571.058z"
                                fill="var(--svg-color-secondary, #163435)"
                              ></path>
                            </g>
                          </svg>
                        </div>
                      </div>
                      <h2 className="mt-4 max-w-lg text-lg-paragraph">
                        {t("aboutus.Thank you for signing up!")}
                      </h2>
                    </div>
                  </>
                ) : (
                  <form
                    className="flex w-full max-w-3xl flex-col lg:grid lg:grid-cols-3 lg:gap-4 position-relative"
                    onSubmit={onSubmitForm}
                  >
                    <div className="transition-all mb-4">
                      <div className="relative z-foreground mb-4 flex h-[60px] items-center rounded border bg-white text-black transition-all focus-within:border-primary focus:border-primary dark:focus-within:border-primary-dark border-transparent shadow dark:shadow-dark dark:bg-off-white dark:text-black">
                        <div className="inline-block align-top absolute pl-3 child:h-7 child:w-7 dark:cssvar-secondary-to-off-white dark:cssvar-secondary-to-secondary">
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M16 14.6527C13.6105 14.6527 11.6736 12.7158 11.6736 10.3264C11.6736 7.93688 13.6105 6 16 6C18.3895 6 20.3264 7.93688 20.3264 10.3264C20.3264 12.7158 18.3895 14.6527 16 14.6527Z"
                              fill="var(--svg-color-secondary, #163435)"
                            ></path>
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M16.1565 15.9742C12.2188 15.8883 9 19.0545 9 22.9726C9 23.4621 9.05258 23.9383 9.15042 24.3979C9.26224 24.9247 9.72616 25.3021 10.2643 25.3021H21.7361C22.2693 25.3021 22.7352 24.9324 22.847 24.4112C23.0091 23.6528 23.05 22.8501 22.9329 22.0181C22.4596 18.6482 19.5587 16.0487 16.1565 15.9742Z"
                              fill="var(--svg-color-secondary, #163435)"
                            ></path>
                          </svg>
                        </div>
                        <div className="peer flex w-full flex-col">
                          <input
                            type="text"
                            className="peer w-full rounded bg-transparent pt-2 pb-1 pl-12 text-md outline-none focus:pr-6 pr-6"
                            placeholder={t("sale.First Name")}
                            name="first_name"
                          />
                        </div>
                      </div>
                      <div className="relative bottom-4 overflow-hidden bg-warning text-center text-xs font-medium text-black transition-all -mt-4 rounded-b max-h-0"></div>
                    </div>
                    <div className="transition-all mb-4">
                      <div className="relative z-foreground mb-4 flex h-[60px] items-center rounded border bg-white text-black transition-all focus-within:border-primary focus:border-primary dark:focus-within:border-primary-dark border-transparent shadow dark:shadow-dark dark:bg-off-white dark:text-black">
                        <div className="inline-block align-top absolute pl-3 child:h-7 child:w-7 dark:cssvar-secondary-to-off-white dark:cssvar-secondary-to-secondary">
                          <svg
                            width="32px"
                            height="32px"
                            viewBox="0 0 32 32"
                            version="1.1"
                          >
                            <title>icon_contact_mail</title>
                            <g
                              id="icon_contact_mail"
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <g
                                id="Group"
                                transform="translate(5.000000, 7.700000)"
                                fill="var(--svg-color-secondary, #163435)"
                              >
                                <path
                                  d="M17.1541722,0 C18.5243618,0 19.2165846,0.133674373 19.9368674,0.518885858 C20.6000248,0.873546296 21.1264537,1.39997522 21.4811141,2.06313259 C21.8663256,2.78341544 22,3.47563815 22,4.8458278 L21.9981828,11.9648343 C21.9810588,13.1622375 21.8406449,13.810058 21.4811141,14.482322 C21.1264537,15.1454793 20.6000248,15.6719082 19.9368674,16.0265687 C19.2165846,16.4117802 18.5243618,16.5454545 17.1541722,16.5454545 L4.8458278,16.5454545 C3.47563815,16.5454545 2.78341544,16.4117802 2.06313259,16.0265687 C1.39997522,15.6719082 0.873546296,15.1454793 0.518885858,14.482322 C0.133674373,13.7620391 0,13.0698164 0,11.6996267 L0,4.8458278 C0,3.47563815 0.133674373,2.78341544 0.518885858,2.06313259 C0.873546296,1.39997522 1.39997522,0.873546296 2.06313259,0.518885858 C2.78341544,0.133674373 3.47563815,0 4.8458278,0 Z M20,5.563 L11,12.1092247 L2,5.564 L2,11.6996267 L2.00467769,12.1173697 C2.02375106,12.8767631 2.10330814,13.2040417 2.28251225,13.5391242 C2.45077309,13.8537447 2.69170989,14.0946815 3.0063303,14.2629423 C3.31348932,14.4272127 3.61409085,14.507752 4.24698433,14.5347258 L4.6271902,14.5443078 L17.3728098,14.5443078 C17.4423488,14.5435357 17.5086323,14.5423663 17.5719151,14.5407769 L17.9176389,14.5260186 C18.4406182,14.4924165 18.7144342,14.4122791 18.9936697,14.2629423 C19.3082901,14.0946815 19.5492269,13.8537447 19.7174877,13.5391242 C19.9265592,13.1481946 20,12.767887 20,11.6996267 L20,5.563 Z M17.1541722,2 L4.8458278,2 L4.42808487,2.00467769 C3.66869148,2.02375106 3.34141286,2.10330814 3.0063303,2.28251225 C2.69170989,2.45077309 2.45077309,2.69170989 2.28251225,3.0063303 C2.24438729,3.07761778 2.21077244,3.14855205 2.1812906,3.22336911 L10.999,9.63500008 L19.8187094,3.22336911 C19.7892276,3.14855205 19.7556127,3.07761778 19.7174877,3.0063303 C19.5492269,2.69170989 19.3082901,2.45077309 18.9936697,2.28251225 C18.6585871,2.10330814 18.3313085,2.02375106 17.5719151,2.00467769 L17.1541722,2 Z"
                                  id="Combined-Shape"
                                ></path>
                              </g>
                            </g>
                          </svg>
                        </div>
                        <div className="peer flex w-full flex-col">
                          <input
                            type="email"
                            className="peer w-full rounded bg-transparent pt-2 pb-1 pl-12 text-md outline-none focus:pr-6 pr-6"
                            placeholder={t("sale.Email")}
                            name="email"
                          />
                        </div>
                      </div>
                      <div className="relative bottom-4 overflow-hidden bg-warning text-center text-xs font-medium text-black transition-all -mt-4 rounded-b max-h-0"></div>
                    </div>

                    <button
                      type="submit"
                      className="group/button disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white button-primary-bg dark:bg-primary-dark p-3 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated pl-3 py-2 inline-block lg:h-[60px]"
                    >
                      <div className="flex h-full items-center justify-between">
                        <span className="flex">{t("aboutus.Receive Voucher")}</span>
                        <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g fill="#000000" fillRule="evenodd">
                              <path
                                d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                                fill="#fff"
                              ></path>
                            </g>
                          </svg>
                        </div>
                      </div>
                    </button>
                    <div className="text-danger fs-12">
                      {" "}
                      {Error ? (
                        <p
                          style={{ color: "red" }}
                          className="mb-2 flex w-full animate-wiggle items-center justify-between rounded bg-warning px-4 py-2 text-lg text-white"
                        >
                          {Error}
                        </p>
                      ) : (
                        ""
                      )}
                    </div>
                  </form>
                )}
              </div>
            </div>
            <div className="flex justify-center pt-0 pb-4 2xl:pb-8">
              <Link
                to={"/campers"}
                className="group/button disabled:opacity-50 border border-transparent rounded text-md text-white dark:text-off-white button-primary-bg dark:bg-primary-dark p-3 hover:bg-secondary group-hover/parent:bg-secondary dark:hover:text-off-black dark:group-hover/parent:text-off-black dark:hover:bg-off-white dark:group-hover/parent:bg-off-white transition-all shadow dark:shadow-dark hover:shadow-elevated dark:hover:shadow-dark-elevated pl-3 py-2 inline-block"
              >
                <div className="flex h-full items-center justify-between">
                  <div className="inline-block align-top mr-2 rounded-full bg-white p-1 child:h-7 child:w-7 dark:bg-off-white">
                    <img src="images/camper-icon.svg" alt="camper-icon" />
                  </div>
                  <span className="flex">{t("aboutus.View Campers")}</span>
                  <div className="inline-block align-top min-w-max child:h-6 child:w-6 dark:cssvar-secondary-to-off-white ml-2 cssvar-secondary-to-white dark:group-hover/button:cssvar-secondary-to-off-black dark:group-hover/parent:cssvar-secondary-to-off-black">
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path
                          d="m22.126 16-7.647-7.647a1.207 1.207 0 0 0-1.705 0l-.42.42a1.204 1.204 0 0 0 0 1.705L17.876 16l-5.522 5.522a1.203 1.203 0 0 0 0 1.704l.42.422c.47.47 1.235.469 1.705-.002L22.126 16z"
                          fill="var(--svg-color-secondary, #163435)"
                        ></path>
                      </g>
                    </svg>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Aboutus;
