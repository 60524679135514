import clientAuth from "../clientAuth";
import client from "../client";
let lng = localStorage.getItem('lng') ? localStorage.getItem('lng'):'de';
export default {
 
 store: (data) => client.post('payment/store', { json: data }),
 checkvoucher: (data) => client.post('payment/checkvoucher', { json: data }),
 order_store: (userdata) => client.post('orderDetail/store', { json: userdata }),
 stripCheckout: (stripedata) => client.post(`payment/stripcheckout?lng=`+lng, { json: stripedata }),
 updatepaymentStatus: (stripedata) => client.post('payment/updatepaymentStatus', { json: stripedata }),
 checkstripepayment: (id) => client.get(`payment/checkstripepayment/${id}`),

 index: () => clientAuth.get(`dashboard/index?lng=`+lng),
 tutorials: () => clientAuth.get(`dashboard/tutorials?lng=`+lng),
 cancle: (userData) => clientAuth.post('dashboard/ordercancle', { json: userData }),

refund: (userData) => clientAuth.post('dashboard/orderrefund', { json: userData }),

 
};