import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
// import swal from "sweetalert";
import Userdashboard from '../../Pages/Userdashboard/Userdashboard'
import PaymentService from '../../Api/services/PaymentService';
import moment from 'moment';
import { useTranslation } from "react-i18next";
const AccountingList = () => {
    const { i18n, t } = useTranslation();
    const [Error, setError] = useState('');
    const [buttonText, setButtonText] = useState('Cancel');
    const [refundText, setRefundText] = useState('Refund');
    const [AccountList, setAccountList] = useState([]);
    const [packages, setpackages] = useState([]);
    const [cancaletions, setcancaletions] = useState([]);
    const [services, setservices] = useState([]);
    const [accessories, setaccessories] = useState([]);
    const [packageAmnt, setpackageAmnt] = useState([]);
    const [kilometer, setkilometer] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const recordsPage = 5000000;
    const lastIndex = currentPage * recordsPage;
    const firstIndex = lastIndex - recordsPage;
    const records = AccountList.slice(firstIndex, lastIndex);
    const npage = Math.ceil(AccountList.length / recordsPage)
    const number = [...Array(npage + 1).keys()].slice(1)
    const navigate = useNavigate();
    function prePage() {
        if (currentPage !== 1) {
            setCurrentPage(currentPage - 1)
        }
    }
    function changeCPage(id) {
        setCurrentPage(id);
    }
    function nextPage() {
        if (currentPage !== npage) {
            setCurrentPage(currentPage + 1)
        }
    }


    const getAccountList = async () => {

        try {
           
            let responseData = await PaymentService.index().json()
            setAccountList(responseData.data)
            setpackages(responseData.packages)
            setkilometer(responseData.kilometer)
            setcancaletions(responseData.cancaletions)
            setservices(responseData.services)
            setaccessories(responseData.accessories)
            setpackageAmnt(responseData.packageamount)
        } catch (error) {
            if (error.name === 'HTTPError') {
                const errorJson = await error.response.json();

                setError(errorJson.message)
            }
        }
    }
    useEffect(() => {
        if (localStorage.getItem('usertoken')) {

            getAccountList();

        }
        else {
            navigate('/');
        }

    }, [])
   
    const handleCancle = async(e) =>
    {
        e.preventDefault();
       let order_id=AccountList[0].order_id
       console.log(order_id)
      try {
        var userData = {
          "orderid":order_id,
       }
          let responseData = await PaymentService.cancle(userData).json()
          if (responseData.status === true) {
            setButtonText('Cancelled')
            alert(responseData.msg)
          }else{
            setButtonText('Cancel')
            alert("your Order is Safe")
        }
        getAccountList();
        } catch (error) {
          if (error.name === 'HTTPError') {
          const errorJson = await error.response.json();
          setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
        }
        }  
    }
    const onSubmitForm = async (event) => {
        /*event.preventDefault();
        setError("");
        try {
          console.log("test");
          var userData = {
            firstName: event.target.firstName.value,
            camper_id: event.target.camper_id.value,
            email: event.target.email.value,
            phone: event.target.phone.value,
            reasonRequest: event.target.reasonRequest.value,
            order_id: inputBox
              ? event.target.order_id.value
                ? event.target.order_id.value
                : ""
              : "",
            message: event.target.message.value,
          };
    
          let responseData = await ContactService.store(userData).json();
          if (responseData.status === true) {
            // console.log(responseData)
            // alert("Form Submitted Successfully!!");
            event.target.reset();
            // navigate("/contactus");
            setisFormSubmit(true);
          }
        } catch (error) {
          console.log(error);
          if (error.name === "HTTPError") {
            const errorJson = await error.response.json();
            setError(
              errorJson.message.substr(0, errorJson.message.lastIndexOf("."))
            );
          }
        }*/
      };
    const handleRefund = async(e) =>
    {
        e.preventDefault();
       let payment_id=AccountList[0].payment_id

       
      try {
        var userData = {
          "paymentid":payment_id,
       }
          let responseData = await PaymentService.refund(userData).json()
          console.log(responseData)
          if (responseData.status === true) {
            setRefundText('Refunded')
            alert(responseData.msg)
          }else{
                setButtonText('Refund')
                alert("your Order is Safe")
            }
            getAccountList();
        } catch (error) {
          if (error.name === 'HTTPError') {
          const errorJson = await error.response.json();
          setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
        }
        }  
    }
    return (
        <>
            <Userdashboard/>
            <div className='userdashboard-content mt-5'>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <div className="card-body p-0">
                                    <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting">
                                        <div className="tbl-caption d-flex justify-content-between text-wrap align-items-center">
                                            <h2 className="heading mb-0 text-lg">{t("Login.Booking Overview")}</h2>

                                        </div>
                                        <div className="mt-12 mb-12 relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md h-full w-full overflow-auto">
                                            <table id="empoloyees-tblwrapper" className="table ItemsCheckboxSec dataTable no-footer mb-0 table-auto w-full min-w-max table-auto text-left">
                                                <thead>
                                                    <tr style={{ textAlign: 'center' }}>
                                                        <th  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                                            <strong>{t("Login.No")}.</strong>
                                                        </th>
                                                        <th  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                                            <strong>{t("Login.Booking Number")}</strong>
                                                        </th>
                                                        <th  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                                            <strong>{t("Login.Camper Model")}</strong>
                                                        </th>
                                                        <th  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                                            <strong>{t("Login.Main Renter")}</strong>
                                                        </th>
                                                        <th  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                                            <strong>{t("Login.Services")}</strong>
                                                        </th>
                                                        <th  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                                            <strong>{t("Login.Accessories")}</strong>
                                                        </th>
                                                        <th  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                                            <strong>{t("Rent.Kilometer")}</strong>
                                                        </th>
                                                        <th  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                                            <strong>{t("Login.Deposit")}</strong>
                                                        </th>
                                                        <th  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                                            <strong>{t("Login.Price")}</strong>
                                                        </th>
                                                        <th  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                                            <strong>{t("Login.Packages")}</strong>
                                                        </th>
                                                        <th  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                                            <strong>{t("Login.Cancellation Polices")}</strong>
                                                        </th>
                                                       
                                                        <th  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                                            <strong>{t("Login.Pick-Up")}</strong>
                                                        </th>
                                                        <th  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                                            <strong>{t("Login.Return")}</strong>
                                                        </th>
                                                        <th  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4">
                                                            <strong>{t("Login.Payment status")}</strong>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {
                                                        records.map((element, index) => {

                                                            return (
                                                                <tr style={{ textAlign: 'center' }}>
                                                                    <td className='p-4 border-b border-blue-gray-50'>
                                                                        {index + 1}
                                                                    </td>
                                                                    <td  className='p-4 border-b border-blue-gray-50'> {element.payment_order_id}</td>
                                                                    <td  className='p-4 border-b border-blue-gray-50'>{element.camper_name}</td>
                                                                    <td  className='p-4 border-b border-blue-gray-50'>{element.full_name}</td>
                                                                    <td  className='p-4 border-b border-blue-gray-50'>{services[index]}</td>
                                                                    <td  className='p-4 border-b border-blue-gray-50'>{accessories[index]}</td>
                                                                    {/* Kilometer */}
                                                                    <td  className='p-4 border-b border-blue-gray-50'>{kilometer[index]}</td>
                                                                    <td  className='p-4 border-b border-blue-gray-50'>{packageAmnt[index]}</td>
                                                                    <td  className='p-4 border-b border-blue-gray-50'>{element.billing_amount+"€"}</td>
                                                                    <td  className='p-4 border-b border-blue-gray-50'>{packages[index]}</td>
                                                                    <td  className='p-4 border-b border-blue-gray-50'>{cancaletions[index]}</td>
                                                                    
                                                                    <td  className='p-4 border-b border-blue-gray-50'>{moment(element.start_date).format('DD.MM.YYYY - HH:mm')}</td>
                                                                    <td  className='p-4 border-b border-blue-gray-50'>{moment(element.end_date).format('DD.MM.YYYY - HH:mm')}</td>
                                                                    <td  className='p-4 border-b border-blue-gray-50'>{element.is_paid==1?t("Rent.Paid"):t("Rent.Not Paid")}</td>
                                                                </tr>
                                                            )
                                                        })
                                                    }

                                                </tbody>

                                            </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
                                                      

        </>
    );
};

export default AccountingList;
