import React, { useState, useEffect } from "react";
import CamperService from '../../Api/services/CamperService';
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Camperdetails = () => {

  const { i18n, t } = useTranslation();
  const params = useParams();
  const [error, setError] = useState("")
  const [camper, setCamper] = useState([]);
  useEffect(() => {
    var id = params.id;

    getCamperByID(id);
  }, []);

  const getCamperByID = async (id) => {
    try {
      let camper = await CamperService.show(id).json();

      setCamper(camper.data);
    } catch (error) {
      if (error.name === 'HTTPError') {
        const errorJson = await error.response.json();
        setError(errorJson.message.substr(0, errorJson.message.lastIndexOf(".")))
      }
    }
  };
  return (
    <>
      {camper.driving && camper.driving.length > 0 ?
        <div className="pb-0 xl:pb-4 2xl:pb-0">
        <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg sm:rounded-lg md:rounded-lg p-0">
          <details className="group" open>
            <summary className="flex cursor-pointer select-none items-center justify-center md:justify-between p-4 md:p-8">
            <div className="desktop-hidden left-arrow-mobile inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6">
                <svg width="32" height="32" viewBox="0 0 32 32">
                  <g fill="none" fillRule="evenodd">
                    <path
                      d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                      fill="var(--svg-color-secondary, #163435)"
                    ></path>
                  </g>
                </svg>
              </div>
              <h3 className="overflow-hidden text-ellipsis text-lg">
                {t("Rent.Driving")}
              </h3>
              <div className="mobile-none inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6">
                <svg width="32" height="32" viewBox="0 0 32 32">
                  <g fill="none" fillRule="evenodd">
                    <path
                      d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                      fill="var(--svg-color-secondary, #163435)"
                    ></path>
                  </g>
                </svg>
              </div>
            </summary>
            <div className="mb-5 px-5 md:mb-9 md:px-9">
            <hr className="my-5 h-[3px] rounded border-none bg-grey dark:bg-grey-dark mt-0" />

              <div className="my-0 md:my-8 grid gap-x-5 sm:mx-8 sm:grid-cols-2 lg:mx-0 xl:mx-8">
                {camper.driving && camper.driving.map((driving) => (
                  <div className="mb-4 flex">
                    <div className="">
                      <div className="min-w-46 inline-flex items-center justify-center rounded-full border bg-white p-1 dark:bg-off-white mr-3 border-grey dark:border-grey-dark">
                        <div className="inline-block align-top child:h-7 child:w-7">

                          <img width="32" height="32" src={driving.icon ? process.env.REACT_APP_LARAVEL_IMAGE_URL + 'Driving/' + driving.icon : ''} />

                        </div>
                      </div>
                    </div>
                    <p className="mt-2 whitespace-pre-line">
                      {driving.title}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </details>
        </div>
        </div>
        : ''}
      {camper.bathroom && camper.bathroom.length > 0 ?
        <div className="pt-8 2xl:pt-12 pb-0 xl:pb-4 2xl:pb-0">
          <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg sm:rounded-lg md:rounded-lg p-0">
            <details className="group" open>
              <summary className="flex cursor-pointer select-none items-center justify-center md:justify-between p-4 md:p-8">
              <div className="desktop-hidden left-arrow-mobile inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6">
                <svg width="32" height="32" viewBox="0 0 32 32">
                  <g fill="none" fillRule="evenodd">
                    <path
                      d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                      fill="var(--svg-color-secondary, #163435)"
                    ></path>
                  </g>
                </svg>
              </div>

                <h3 className="overflow-hidden text-ellipsis text-lg">
                  {t("Rent.Bathroom")}
                </h3>
                <div className="mobile-none inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6">
                  <svg width="32" height="32" viewBox="0 0 32 32">
                    <g fill="none" fillRule="evenodd">
                      <path
                        d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                        fill="var(--svg-color-secondary, #163435)"
                      ></path>
                    </g>
                  </svg>
                </div>
              </summary>
              <div className="mb-5 px-5 md:mb-9 md:px-9">
                <hr className="my-5 h-[3px] rounded border-none bg-grey dark:bg-grey-dark mt-0" />
                <div className="my-0 md:my-8 grid gap-x-5 sm:mx-8 sm:grid-cols-2 lg:mx-0 xl:mx-8">
                  {camper.bathroom && camper.bathroom.map((bathroom) => (
                    <div className="mb-4 flex">
                      <div className="">
                        <div className="min-w-46 inline-flex items-center justify-center rounded-full border bg-white p-1 dark:bg-off-white mr-3 border-grey dark:border-grey-dark">
                          <div className="inline-block align-top child:h-7 child:w-7">
                            <img width="32" height="32" src={bathroom.icon ? process.env.REACT_APP_LARAVEL_IMAGE_URL + 'Bathroom/' + bathroom.icon : ''} />
                          </div>
                        </div>
                      </div>
                      <p className="mt-2 whitespace-pre-line">
                        {bathroom.title}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </details>
          </div>
        </div>
        : ''}

      {camper.vehicle && camper.vehicle.length > 0 ?
        <div className="pt-8 2xl:pt-12 pb-0 xl:pb-4 2xl:pb-0">
          <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg sm:rounded-lg md:rounded-lg p-0">
            <details className="group" open>
              <summary className="flex cursor-pointer select-none items-center justify-center md:justify-between p-4 md:p-8">
              <div className="desktop-hidden left-arrow-mobile inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6">
                <svg width="32" height="32" viewBox="0 0 32 32">
                  <g fill="none" fillRule="evenodd">
                    <path
                      d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                      fill="var(--svg-color-secondary, #163435)"
                    ></path>
                  </g>
                </svg>
              </div>

                <h3 className="overflow-hidden text-ellipsis text-lg">
                  {t("Rent.Vehicle")}
                </h3>
                <div className="mobile-none inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6">
                  <svg width="32" height="32" viewBox="0 0 32 32">
                    <g fill="none" fillRule="evenodd">
                      <path
                        d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                        fill="var(--svg-color-secondary, #163435)"
                      ></path>
                    </g>
                  </svg>
                </div>
              </summary>
              <div className="mb-5 px-5 md:mb-9 md:px-9">
                <hr className="my-5 h-[3px] rounded border-none bg-grey dark:bg-grey-dark mt-0" />
                <div className="my-0 md:my-8 grid gap-x-5 sm:mx-8 sm:grid-cols-2 lg:mx-0 xl:mx-8">
                  {camper.vehicle && camper.vehicle.map((vehicle) => (
                    <div className="mb-4 flex">
                      <div className="">
                        <div className="min-w-46 inline-flex items-center justify-center rounded-full border bg-white p-1 dark:bg-off-white mr-3 border-grey dark:border-grey-dark">
                          <div className="inline-block align-top child:h-7 child:w-7">
                            <img width="32" height="32" src={vehicle.icon ? process.env.REACT_APP_LARAVEL_IMAGE_URL + 'Vehicle/' + vehicle.icon : ''} />
                          </div>
                        </div>
                      </div>
                      <p className="mt-2 whitespace-pre-line">
                        {vehicle.title}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </details>
          </div>
        </div>
        : ''}
      {camper.living && camper.living.length > 0 ?
        <div className="pt-8 2xl:pt-12 pb-0 xl:pb-4 2xl:pb-0">
          <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg sm:rounded-lg md:rounded-lg p-0">
            <details className="group" open>
              <summary className="flex cursor-pointer select-none items-center justify-center md:justify-between p-4 md:p-8">
              <div className="desktop-hidden left-arrow-mobile inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6">
                <svg width="32" height="32" viewBox="0 0 32 32">
                  <g fill="none" fillRule="evenodd">
                    <path
                      d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                      fill="var(--svg-color-secondary, #163435)"
                    ></path>
                  </g>
                </svg>
              </div>

                <h3 className="overflow-hidden text-ellipsis text-lg">
                  {t("Rent.Living & Sleeping")}
                </h3>
                <div className="mobile-none inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6">
                  <svg width="32" height="32" viewBox="0 0 32 32">
                    <g fill="none" fillRule="evenodd">
                      <path
                        d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                        fill="var(--svg-color-secondary, #163435)"
                      ></path>
                    </g>
                  </svg>
                </div>
              </summary>
              <div className="mb-5 px-5 md:mb-9 md:px-9">
              <hr className="my-5 h-[3px] rounded border-none bg-grey dark:bg-grey-dark mt-0" />

                <div className="my-0 md:my-8 grid gap-x-5 sm:mx-8 sm:grid-cols-2 lg:mx-0 xl:mx-8">
                  {camper.living && camper.living.map((living) => (
                    <div className="mb-4 flex">
                      <div className="">
                        <div className="min-w-46 inline-flex items-center justify-center rounded-full border bg-white p-1 dark:bg-off-white mr-3 border-grey dark:border-grey-dark">
                          <div className="inline-block align-top child:h-7 child:w-7">

                            <img width="32" height="32" src={living.icon ? process.env.REACT_APP_LARAVEL_IMAGE_URL + 'Livings/' + living.icon : ''} />
                          </div>
                        </div>
                      </div>
                      <p className="mt-2 whitespace-pre-line">
                        {living.title}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </details>
          </div>
        </div>
        : ''}

      {camper.kitchen && camper.kitchen.length > 0 ?
        <div className="pt-8 2xl:pt-12 pb-0 xl:pb-4 2xl:pb-0">
          <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg sm:rounded-lg md:rounded-lg p-0">
            <details className="group" open>
              <summary className="flex cursor-pointer select-none items-center justify-center md:justify-between p-4 md:p-8">
              <div className="desktop-hidden left-arrow-mobile inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6">
                <svg width="32" height="32" viewBox="0 0 32 32">
                  <g fill="none" fillRule="evenodd">
                    <path
                      d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                      fill="var(--svg-color-secondary, #163435)"
                    ></path>
                  </g>
                </svg>
              </div>

                <h3 className="overflow-hidden text-ellipsis text-lg">
                  {t("Rent.Kitchen")}
                </h3>
                <div className="mobile-none inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6">
                  <svg width="32" height="32" viewBox="0 0 32 32">
                    <g fill="none" fillRule="evenodd">
                      <path
                        d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                        fill="var(--svg-color-secondary, #163435)"
                      ></path>
                    </g>
                  </svg>
                </div>
              </summary>
              <div className="mb-5 px-5 md:mb-9 md:px-9">
              <hr className="my-5 h-[3px] rounded border-none bg-grey dark:bg-grey-dark mt-0" />

                <div className="my-0 md:my-8 grid gap-x-5 sm:mx-8 sm:grid-cols-2 lg:mx-0 xl:mx-8">
                  {camper.kitchen && camper.kitchen.map((kitchen) => (
                    <div className="mb-4 flex">
                      <div className="">
                        <div className="min-w-46 inline-flex items-center justify-center rounded-full border bg-white p-1 dark:bg-off-white mr-3 border-grey dark:border-grey-dark">
                          <div className="inline-block align-top child:h-7 child:w-7">

                            <img width="32" height="32" src={kitchen.icon ? process.env.REACT_APP_LARAVEL_IMAGE_URL + 'kitchens/' + kitchen.icon : ''} />
                          </div>
                        </div>
                      </div>
                      <p className="mt-2 whitespace-pre-line">
                        {kitchen.title}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </details>
          </div>
        </div>
        : ''}
      {/* {camper.accessory && camper.accessory.length > 0 ?
        <div className="pt-8 2xl:pt-12 pb-4 2xl:pb-8">
          <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg sm:rounded-lg md:rounded-lg p-0">
            <details className="group" open>
              <summary className="flex cursor-pointer select-none items-center justify-between py-6 px-5 md:p-8">

                <h3 className="overflow-hidden text-ellipsis text-lg">
                  Accessory
                </h3>
                <div className="inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6">
                  <svg width="32" height="32" viewBox="0 0 32 32">
                    <g fill="none" fillRule="evenodd">
                      <path
                        d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                        fill="var(--svg-color-secondary, #163435)"
                      ></path>
                    </g>
                  </svg>
                </div>
              </summary>
              <div className="mb-5 px-5 md:mb-9 md:px-9">

                <div className="my-8 grid gap-x-5 sm:mx-8 sm:grid-cols-2 lg:mx-0 xl:mx-8">
                  {camper.accessory && camper.accessory.map((accessory) => (
                    <div className="mb-4 flex">
                      <div className="">
                        <div className="min-w-46 inline-flex items-center justify-center rounded-full border bg-white p-1 dark:bg-off-white mr-3 border-grey dark:border-grey-dark">
                          <div className="inline-block align-top child:h-7 child:w-7">

                            <img width="32" height="32" src={accessory.icon ? process.env.REACT_APP_LARAVEL_IMAGE_URL + 'Accessory/' + accessory.icon : ''} />
                          </div>
                        </div>
                      </div>
                      <p className="mt-2 whitespace-pre-line">
                        {accessory.title}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </details>
          </div>
        </div>
        : ''} */}
      {camper.outdoor && camper.outdoor.length > 0 ?
        <div className="pt-8 2xl:pt-12 pb-0 xl:pb-4 2xl:pb-0">
          <div className="overflow-hidden rounded bg-white shadow dark:bg-off-black-desat dark:shadow-dark 2xs:rounded-lg sm:rounded-lg md:rounded-lg p-0">
            <details className="group" open>
              <summary className="flex cursor-pointer select-none items-center justify-center md:justify-between p-4 md:p-8">
              <div className="desktop-hidden left-arrow-mobile inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6">
                <svg width="32" height="32" viewBox="0 0 32 32">
                  <g fill="none" fillRule="evenodd">
                    <path
                      d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                      fill="var(--svg-color-secondary, #163435)"
                    ></path>
                  </g>
                </svg>
              </div>

                <h3 className="overflow-hidden text-ellipsis text-lg">
                  {t("Rent.Outdoor area")}
                </h3>
                <div className="mobile-none inline-block align-top pl-4 child:h-8 child:w-8 child:transition-all group-open:child:rotate-180 dark:cssvar-secondary-to-off-white md:pl-6">
                  <svg width="32" height="32" viewBox="0 0 32 32">
                    <g fill="none" fillRule="evenodd">
                      <path
                        d="m16 22.126 7.647-7.647c.47-.47.47-1.234 0-1.705l-.42-.42a1.204 1.204 0 0 0-1.705 0L16 17.876l-5.522-5.522a1.203 1.203 0 0 0-1.704 0l-.422.42c-.47.47-.469 1.235.002 1.705L16 22.126z"
                        fill="var(--svg-color-secondary, #163435)"
                      ></path>
                    </g>
                  </svg>
                </div>
              </summary>
              <div className="mb-5 px-5 md:mb-9 md:px-9">
              <hr className="my-5 h-[3px] rounded border-none bg-grey dark:bg-grey-dark mt-0" />
                <div className="my-0 md:my-8 grid gap-x-5 sm:mx-8 sm:grid-cols-2 lg:mx-0 xl:mx-8">
                  {camper.outdoor && camper.outdoor.map((outdoor) => (
                    <div className="mb-4 flex">
                      <div className="">
                        <div className="min-w-46 inline-flex items-center justify-center rounded-full border bg-white p-1 dark:bg-off-white mr-3 border-grey dark:border-grey-dark">
                          <div className="inline-block align-top child:h-7 child:w-7">

                            <img width="32" height="32" src={outdoor.icon ? process.env.REACT_APP_LARAVEL_IMAGE_URL + 'Outdoor/' + outdoor.icon : ''} />
                          </div>
                        </div>
                      </div>
                      <p className="mt-2 whitespace-pre-line">
                        {outdoor.title}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </details>
          </div>
        </div>
        : ''}

    </>
  );
};

export default Camperdetails;
