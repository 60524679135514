import React, { useState, useEffect } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-tabs/style/react-tabs.css";
const Privacy = () => {
  return (
    <>
      <Header />

      <div className="min-h-screen bg-off-white">
        <div className="pb-5 text-center"></div>

       Privacy
      </div>

      <Footer />
    </>
  );
};

export default Privacy;
