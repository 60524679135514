import React from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
export default function SimpleSlider() {
  const { i18n, t } = useTranslation();
  const CustomArrow = ({ onClick, direction }) => (
    <div className="custom-btn-group">
      <button className={`custom-arrow ${direction}`} onClick={onClick}>
        {direction === 'prev' ? '<' : '>'}
      </button>
    </div>
  );
  var settings = {
    prevArrow: <CustomArrow direction="prev" />,
    nextArrow: <CustomArrow direction="next" />,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    draggable: true,
    touchMove: true,
    responsive: [
      {
        breakpoint: 1273,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          prevArrow: <CustomArrow direction="prev" />,
          nextArrow: <CustomArrow direction="next" />,
          slidesToShow: 1,
          slidesToScroll: 1,

        },
      },
      {
        breakpoint: 480,
        settings: {
          prevArrow: <CustomArrow direction="prev" />,
          nextArrow: <CustomArrow direction="next" />,
          slidesToShow: 1,
          slidesToScroll: 1,
          prevArrow: <CustomArrow direction="prev" />,
          nextArrow: <CustomArrow direction="next" />,
          dots: true,
          arrow: false,
        },
      },
    ],

  };
  const [showResults, setShowResults] = React.useState(false)
  const onClick = () => setShowResults(true)
  const onClose = () => setShowResults(false)

  const [showResults1, setShowResults1] = React.useState(false)
  const onClick1 = () => setShowResults1(true)
  const onClose1 = () => setShowResults1(false)




  return (
    <>
      <Slider {...settings} className="top-equipped-slider equipped-wrapper md:mx-5 mx-1 detail-slider ">
        <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12 h-full">
          <div className="picture-bg h-full">
            <div className="pt-8 2xl:pt-12 pb-12 2xl:pb-12 h-full">
              <span className="font-semibold text-center my-5 first:mt-0 break-words top-equipped-title about-title">
                {t("aboutus.Our Goal")}
              </span>
              <h2 className="text-center my-5 first:mt-0 break-words text-lg md:text-xl top-equipped-title about-title-bottom big-text">{t("aboutus.Creating Unforgettable Moments")}</h2>
              <div className="vertical-img small-box">
                <picture className="text-0 flex h-full">
                  <img
                    className="inline-block object-cover dark:brightness-90 rounded 2xs:rounded-lg w-full mx-auto div-img"
                    src={'images/0.gif'}
                    style={{
                      backgroundImage: `url(images/about-model-1.jpg)`
                    }}
                    alt="picture for Camper rent-long"
                  />
                </picture>
                <div className="plus-icon" onClick={onClick}>+</div>
                <div className="picture-bg-overlay rounded 2xs:rounded-lg"></div>
              </div>

            </div>

          </div>



        </div>

        <div className="grid grid-cols-1 gap-y-0 md:grid-cols-2 gap-x-8 2xl:gap-x-12 h-full">
          <div className="picture-bg h-full" >
            <div className="pt-8 2xl:pt-12 pb-12 2xl:pb-12 h-full">
              <span className="font-semibold text-center my-5 first:mt-0 break-words top-equipped-title about-title">
                {t("aboutus.What We Offer")}
              </span>
              <h2 className="text-center my-5 first:mt-0 break-words text-lg md:text-xl top-equipped-title about-title-bottom big-text">{t("aboutus.Adventure with Comfort")}</h2>
              <div className="vertical-img small-box">
                <picture className="text-0 flex h-full">
                  <img

                    className="scale-img inline-block object-cover dark:brightness-90 rounded 2xs:rounded-lg w-full mx-auto div-img"
                    src={'images/0.gif'}
                    style={{
                      backgroundImage: `url(images/about-model-2.jpg)`
                    }}

                    alt="service-img"

                  />
                </picture>
                <div className="plus-icon" onClick={onClick1}>+</div>
                <div className="picture-bg-overlay rounded 2xs:rounded-lg"></div>
              </div>

            </div>
          </div>


        </div>





      </Slider>

      {showResults ?
        <div className="top-equipped-modal big-model">
          <div className="top-equipped-modal-body">
            <div className="text-right">
              <button onClick={onClose} className="close-top-eq-mdal">X</button>
            </div>
            <div className="pt-0 md:pt-4 pb-8 px-5 text-left mx-auto max-w-3xl">
              <span className="font-semibold">{t("aboutus.Our Goal")}</span>
              <h3 className="my-3 mt-3 first:mt-0 break-words heading text-lg md:text-xl">
                {t("aboutus.Crafting lifelong memories, personally, seamlessly, and securely!")}
              </h3>
              <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal f-15">
                {t("aboutus.Good Stories captivate all of us, don't they? How about we embark on an unforgettable adventure together, one you can share with your grandchildren someday? For us, traveling in a camper embodies real adventure, the beauty of nature, and the feeling of boundless freedom.")} {t("aboutus.Our goal is to assist you in experiencing these unique moments as effortlessly, safely, and fairly as possible!")}
              </div>
              <Link
                to={"/campers"}
                className="font-semibold mt-5 block text-orange underline hover:text-off-black dark:text-primary-light hover:dark:text-off-white"
              >
                {t("aboutus.View Campers")}
              </Link>

            </div>
          </div>
        </div>
        : null}

      {showResults1 ?
        <div className="top-equipped-modal big-model">
          <div className="top-equipped-modal-body">
            <div className="text-right">
              <button onClick={onClose1} className="close-top-eq-mdal">X</button>
            </div>
            <div className="pt-0 md:pt-4 pb-8 px-5 text-left mx-auto max-w-3xl">
              <span className="font-semibold">{t("aboutus.What We Offer")}</span>
              <h3 className="my-3 mt-3 first:mt-0 break-words heading text-lg md:text-xl">
                {t("aboutus.We bring adventure into your life, and that with as much comfort as you want.")}
              </h3>
              <div className="min-h-[30px] break-words text-sm 2xl:text-2md 2xl:font-normal f-15">
                {t("aboutus.We offer you everything, from small campers to large motorhomes.")}&nbsp;{t("aboutus.Experience your adventure just the way you want it.")}&nbsp;{t("aboutus.Our priorities lie in the quality of our vehicles, the smooth handling of pick-up and return, securing your journey, and providing personal service.")}&nbsp;{t("aboutus.And if you're ever unsure where your journey should lead, we're happy to take care of the travel planning for you.")}&nbsp;{t("aboutus.No stress, no worries.")}&nbsp;{t("aboutus.This adventure is yours.")}
              </div>
              <Link
                to={"/campers"}
                className="font-semibold mt-5 block text-orange underline hover:text-off-black dark:text-primary-light hover:dark:text-off-white"
              >
                {t("aboutus.View Campers")}
              </Link>

            </div>
          </div>
        </div>

        : null}

    </>

  );
}